import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../styles'
import { Grid } from '@material-ui/core'
import { useStateValue } from "../state";
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: COLORS.tertiary,
        borderRadius: '7px',
        fontSize: '16px',
        padding: '0.5rem',
        marginBottom: '7px'
    },
    title: {
        fontSize: '19px',
    },
    content: {
        fontSize: '17px',
    }

}))

const InfoBar = ({ title, message, grid, allUsers, xs, sm, md, lg, children, style }) => {
    const [{ currentUser }] = useStateValue();
    const classes = useStyles();
    const newUser = currentUser.numberOfInvoices < 5

    const FormatMessage = () => {
        const rows = message.split(';')
        return (
            <>
                {rows.map((row, i) => {
                    return (
                      <div style={rows.length - 1 > i ? { padding: '0 0 0.5em 0' } : null} key={i}>{
                        row.includes('</Link>')
                          ? row.split('</Link>').map((r1, i2) => {
                              let r2 = r1.split('<Link to="')
                              if (r2.length > 1) {
                                let r3 = r2[1].split('">')

                                return (<Trans key={i2}>{r2[0]}<a href={r3[0]} target="_blank" rel="noopener noreferrer">{r3[1]}</a></Trans>)
                              } else {
                                if (r2[0].length > 0) {
                                  let r3 = r2[0].split('">')

                                  return (<Trans><a href={r3[0]} target="_blank" rel="noopener noreferrer">{r3[1]}</a></Trans>)
                                }
                              }

                              return null
                            })
                          : row
                      }</div>
                    )
                  })}
            </>
        )
    }

    //cLog('CURRENT USER', currentUser, newUser)

    if (newUser || allUsers) {
        if (grid) {
            return (
                <Grid
                    item
                    xs={xs || 12}
                    sm={sm || 12}
                    md={md || 12}
                    lg={lg || 12}
                >
                    <div className={classes.root}>
                        {title && <div className={classes.title}>{title}</div>}
                        <div className={classes.content}>{children || <FormatMessage />}</div>
                    </div>
                </Grid>
            )
        } else {
            return (
                <div className={classes.root} style={style}>
                    {title && <div className={classes.title}>{title}</div>}
                    <div className={classes.content}>{children || <FormatMessage />}</div>
                </div>
            )
        }
    } else {
        return null
    }


}

export default InfoBar
