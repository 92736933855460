import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom"
import { getQuery, getUser, deleteUser } from '../../service'
import Button from '../../components/Button'
import { useStateValue, setUsersIndex, setSortKeyUsers, setSortOrderUsers } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from '../../components/LayoutContainer'
import Header from "../../components/Header"
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { COUNTRIES } from '../../utils/countries'
import { LANGUAGES } from '../../utils/languages'
import { INDUSTRIES } from '../../utils/invoiceUtils'
import '../../styles/styles.css'

let selectedColumns = []

const INIT_FILTER = {
  id: '',
  firstName: '',
  lastName: '',
  ssn: '',
  selfAuthenticated: '',
  name: '',
  totalAddress: '',
  country: '',
  email: '',
  phone: '',
  language: '',
  citizenship: '',
  bankAccount: '',
  bic: '',
  marketingName: '',
  industry: [],
  customIndustry: '',
  commission: '',
  role: 'LASKUTTAJA',
  odealAccount: '',
  notAccounted: '',
  yel: [],
  currentSignInAt: '',
  distraint: '',
  distraintNotes: '',
  noInstantSalary: '',
  noInstantSalaryNotes: '',
  createdAt: '',
  updatedAt: '',
  actions: '',
  columnsOptions: selectedColumns
}

const Users = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  let history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const [{ usersIndex, sortKeyUsers, sortOrderUsers }, dispatch] = useStateValue()
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })
  const allColumns = ['id', 'firstName', 'lastName', 'ssn', 'selfAuthenticated', 'totalAddress', 'email', 'phone', 'language', 'citizenship', 'bankAccount', 'bic', 'marketingName', 'industry', 'customIndustry', 'commission', 'role', 'odealAccount', 'notAccounted', 'yel', 'currentSignInAt', 'distraint', 'distraintNotes', 'noInstantSalary', 'noInstantSalaryNotes', 'createdAt', 'updatedAt', 'actions']
  selectedColumns = JSON.parse(window.localStorage.getItem('usersSelectedColumns')) || allColumns
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('user.'+option),
      value: option
    }
    if (['totalAddress'].includes(option)) {
      obj.totalAddress = true
    }
    if (['commission', 'odealAccount', 'notAccounted'].includes(option)) {
      obj.numeric = true
      obj.decimals = 2
    }
    if (['currentSignInAt', 'createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (id) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                window.open('/kayttajat/muokkaa/' + id)
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getUser(id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenConfirmDeleteModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyUsers, sortOrderUsers, filter, selectedColumns, 'users')
      response.then(data => {
        const dataActions = data.map(row => {
          const uid = row.id
          row.actions = createActions(uid)
          if (selectedColumns.includes('totalAddress')) {
            let totalAddress = row.address1 || ''
            if (row.postalCode || row.city) {
              if (totalAddress) {
                totalAddress += '\n'
              }
              totalAddress += ((row.postalCode || '') + ' ' + (row.city || '')).trim()
            }
            if (row.country && row.country !== 'FINLAND') {
              if (totalAddress) {
                totalAddress += '\n'
              }
              totalAddress += t('countries.'+row.country)
            }
            row.totalAddress = totalAddress
          }
          if (selectedColumns.includes('language')) {
            row.language = t('user.'+row.language)
          }
          if (selectedColumns.includes('citizenship')) {
            row.citizenship = t('user.'+row.citizenship)
          }
          if (selectedColumns.includes('role')) {
            row.role = t('user.'+row.role)
          }
          if (selectedColumns.includes('yel') && row.yel) {
            row.yel = t('user.'+row.yel)
          }
          if (selectedColumns.includes('id')) {
            row.id = <Link to={`/kayttajat/${row.id}`} target='_blank'>{row.id}</Link>
          }
          return row
        })
        dispatch(setUsersIndex(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setUsersIndex([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyUsers, sortOrderUsers, filter, pagination, t, enqueueSnackbar])
  
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.users') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteUser(selectedRow.id)
      cLog(`USER ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('users.user') + selectedRow.firstName + ' ' + selectedRow.lastName + t('users.deleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE USER ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('user.problemsDeletinguser'),
        { variant: 'error' }
      )
    }
  }
  
  const countryOptions = Object.keys(COUNTRIES).map(country => ({ label: t('countries.'+COUNTRIES[country]), value: COUNTRIES[country] }))
  const languageOptions = Object.keys(LANGUAGES).filter(key => ['Suomi', 'Englanti'].includes(key)).map(lang => ({ label: t('customers.'+lang), value: LANGUAGES[lang] }))
  const nationalities = [
    'Espanja',
    'Norja',
    'Ruotsi',
    'Saksa',
    'Suomi',
    'Venäjä',
    'Viro',
    'Muu'
  ]
  const citizenshipOptions = nationalities.map(nationality => ({ label: t('user.'+nationality), value: nationality }))
  const industryOptions =  Array.from(INDUSTRIES).map(([key]) => ({ label: t('user.'+key), value: key }))
  const roleOptions = ['LASKUTTAJA', 'ADMIN', 'PASSIIVINEN'].map(role => ({ label: t('user.'+role), value: role }))
  const yelOptions = ['EI_VELVOLLINEN', 'VELVOLLINEN'].map(yel => ({ label: t('user.'+yel), value: yel }))
  const distraintOptions = ['true', 'false'].map(option => ({ label: t('taxcard.'+option), value: option }))

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.users')}
        actions={<Button variant="contained" color="primary" onClick={() => history.push('/kayttajat/uusi')}>{t('users.addNewUser')}</Button>}
      />
      <DatatableFilters
        inputs={[
          { field: 'id', label: t('user.id'), type: 'text' },
          { field: 'firstName', label: t('user.firstName'), type: 'text' },
          { field: 'lastName', label: t('user.lastName'), type: 'text' },
          { field: 'ssn', label: t('user.ssn'), type: 'text' },
          { field: 'selfAuthenticated', label: t('user.selfAuthenticated'), type: 'select', options: distraintOptions },
          { field: 'totalAddress', label: t('user.totalAddress'), type: 'text' },
          { field: 'country', label: t('user.country'), type: 'select', options: countryOptions },
          { field: 'email', label: t('user.email'), type: 'text' },
          { field: 'phone', label: t('user.phone'), type: 'text' },
          { field: 'language', label: t('user.language'), type: 'select', options: languageOptions },
          { field: 'citizenship', label: t('user.citizenship'), type: 'select', options: citizenshipOptions },
          { field: 'bankAccount', label: t('user.bankAccount'), type: 'text' },
          { field: 'bic', label: t('user.bic'), type: 'text' },
          { field: 'marketingName', label: t('user.marketingName'), type: 'text' },
          { field: 'industry', label: t('user.industry'), type: 'select', options: industryOptions },
          { field: 'customIndustry', label: t('user.customIndustry'), type: 'text' },
          { field: 'commission', label: t('user.commission'), type: 'text' },
          { field: 'role', label: t('user.role'), type: 'select', options: roleOptions },
          { field: 'odealAccount', label: t('user.odealAccount'), type: 'text' },
          { field: 'notAccounted', label: t('user.notAccounted'), type: 'text' },
          { field: 'yel', label: t('user.yel'), type: 'select', options: yelOptions },
          { field: 'currentSignInAt', label: t('user.currentSignInAt'), type: 'date' },
          { field: 'distraint', label: t('user.distraint'), type: 'select', options: distraintOptions },
          { field: 'distraintNotes', label: t('user.distraintNotes'), type: 'text' },
          { field: 'noInstantSalary', label: t('user.noInstantSalary'), type: 'select', options: distraintOptions },
          { field: 'noInstantSalaryNotes', label: t('user.noInstantSalaryNotes'), type: 'text' },
          { field: 'createdAt', label: t('userNotes.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('userNotes.updatedAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'usersSelectedColumns'}
        sortKey={sortKeyUsers}
        setSortKey={setSortKeyUsers}
        sortOrder={sortOrderUsers}
        setSortOrder={setSortOrderUsers}
        isAdmin={true}
        downloadType={'users'}
      />
      <Datatable
        data={usersIndex}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyUsers}
        setSortKey={setSortKeyUsers}
        sortOrder={sortOrderUsers}
        setSortOrder={setSortOrderUsers}
        isAdmin={true}
      />
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('users.confirmDeletionTitle') + JSON.stringify(selectedRow ? selectedRow.firstName + ' ' + selectedRow.lastName : '') + '?', body: t('users.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </LayoutContainer>
  )
}

export default withSnackbar(Users)
