import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import ClientForm from './ClientForm'
import { getClient } from '../../service'
import { CLIENT_FORM_TEMPLATE } from '../../utils/invoiceUtils'
import { useHistory, useParams } from 'react-router-dom'
import { cLog } from '../../utils'
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'

const Client = ({ edit }) => {
  let { id } = useParams()
  const { t } = useTranslation();
  const [client, setClient] = useState(null)
  let history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      if (edit) {
        try {
          const thisClient = await getClient(id)
          setClient(thisClient)
        } catch (error) {
          cLog('ONGELMIA ASIAKKAAN LATAAMISESSA', error)
          history.push('/')
        }
      }
    }

    fetchData()
  }, [edit, id, history])

  //cLog('ASIAKKAAT ', clients)
  //cLog('ID', id)
  //cLog('TÄMÄ ASIAKAS', clients.find(client => client.id === id))
  return (edit && client) || !edit ? (
    <LayoutContainer>
      <Header
        title={edit ? t('customers.modifyCustomer') : t('customers.addNewCustomer')}
      />
      <Formik
        initialValues={edit ? client : CLIENT_FORM_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
        component={props =>
          <ClientForm
              {...props}
              isFromClient
              editClientForm={edit}
          //setEditClientForm={setEditClientForm}
          //ref={clientRef}
          />}
      />
    </LayoutContainer>
  ) : null
}

export default Client
