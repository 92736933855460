import React from 'react'
import Button from '../../components/Button'
import { useStateValue } from "../../state"
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { updateUser } from '../../service'
import { withSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import TitledCard from '../../components/TitledCard';
import KeyValueList from '../../components/KeyValueList';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    '& > *': {
      margin: theme.spacing(2),
      flexGrow: 1,
    },
  },
  titledCardFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}))

const SummaryBox = ({ user, setUser, fetchDataAll, enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [{ currentUser }] = useStateValue()
  const usersCommission = user ? user.commission : currentUser.commission
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const thisUser = user || currentUser
  const classes = useStyles()

  const { openInvoicesCount, openInvoicesSum, unpaidInvoicesCount, unpaidInvoicesSum, immediatelyInvoicesCount, paidImmediatelyInvoicesCount, immediatelyInvoicesSum, paidImmediatelyInvoicesSum, totalBilled, odealAccount, notAccounted } = thisUser
  //cLog('USER AVOIMET LASKUT ' + openInvoicesCount + ' KPL ' + openInvoicesSum)
  //cLog('USER TILITTÄMÄTTÖMÄT HETIPALKKALASKUT ' + immediatelyInvoicesCount + ' KPL ' + immediatelyInvoicesSum)
  //cLog('USER TILITETYT HETIPALKKALASKUT ' + paidImmediatelyInvoicesCount + ' KPL ' + paidImmediatelyInvoicesSum)
  //cLog('USER MUUT TILITTÄMÄTTÖMÄT LASKUT ' + unpaidInvoicesSum + ' KPL ' + unpaidInvoicesCount)
  //cLog('USER KOKONAISLASKUTUS', totalBilled)

  const handLowerCommission = async () => {
    const request = updateUser({
      id: user.id,
      commission: Math.max(user.commission - 0.5, 2)
    })
    request.then(response => {
      cLog('USER UPDATED', response)
      setUser(response)
      fetchDataAll()
    }).catch(() => {
      enqueueSnackbar(
        t('user.userProfileErrors'),
        { variant: 'error' }
      )
    })
  }

  const listItems = [
    {
      key: t('frontPage.summaryInvoicing'),
      value: `${parseFloat(totalBilled).toFixed(2)} €`,
    },
    {
      key: `${t('frontPage.summaryOpenInvoices')} ${openInvoicesCount} ${t('frontPage.pieces')}`,
      value: `${parseFloat(openInvoicesSum).toFixed(2)} €`,
    },
    {
      isVisible: immediatelyInvoicesCount > 0 && !isAdmin,
      key: `${t('frontPage.summaryUnaccountedInstant')} ${immediatelyInvoicesCount} ${t('frontPage.pieces')}`,
      value: `${parseFloat(immediatelyInvoicesSum).toFixed(2)} €`,
    },
    {
      isVisible: paidImmediatelyInvoicesCount > 0 && isAdmin,
      key: `${t('frontPage.summaryAccountedInstant')} ${paidImmediatelyInvoicesCount} ${t('frontPage.pieces')}`,
      value: `${parseFloat(paidImmediatelyInvoicesSum).toFixed(2)} €`,
    },
    {
      key: `${immediatelyInvoicesCount > 0 ? t('frontPage.otherUnaccounted') : t('frontPage.unAccounted')} ${t('frontPage.invoices')} ${unpaidInvoicesCount} ${t('frontPage.pieces')}`,
      value: `${parseFloat(unpaidInvoicesSum).toFixed(2)} €`,
    },
    {
      key: t('frontPage.summaryDiscount'),
      value: `${parseFloat(odealAccount).toFixed(2)} €`,
      info: `${t('frontPage.inviteMiddle')};;${t('frontPage.inviteHeti')};;${t('frontPage.inviteEnd')}`,
    },
    {
      key: t('frontPage.summaryServiceCharge'),
      value: `${usersCommission} %`,
    },
    {
      isVisible: isAdmin || notAccounted > 0,
      key: t('user.notAccounted'),
      value: `${parseFloat(notAccounted).toFixed(2)} €`,
      info: t('user.notAccountedTip'),
    },
  ];

  return (
    <TitledCard variant="outlined" color="primary" title={t('frontPage.summaryTitle')} fillWidth fillHeight className={classes.titledCardFlexColumn}>
      <KeyValueList id="invoices-summary-list" items={listItems}/>
      {isAdmin && user.id !== currentUser.id && user.commission > 2 &&
        <div className={classes.row}><Button variant="outlined" color="primary" fullWidth onClick={() => handLowerCommission()}>{t('user.lowerCommission')}</Button></div>}
    </TitledCard>
  )
}

export default withSnackbar(SummaryBox)
