import request from './request'

export const getAnnouncements = async (query) => {
  const { data: announcements } = await request('GET', '/announcements' + (query ? query : ''))
  return announcements
}

export const getAnnouncement = async (id) => {
  const { data: announcement } = await request('GET', '/announcements/' + id)
  return announcement
}

export const saveAnnouncement = async (data) => {
  delete data.updateHistory
  const { data: newAnnouncement } = await request('POST', '/announcements', data)
  return newAnnouncement
}

export const updateAnnouncement = async (data) => {
  delete data.updateHistory
  const { data: updatedAnnouncement } = await request('PUT', `/announcements/${data.id}`, data)
  return updatedAnnouncement
}

export const deleteAnnouncement = async (id) => await request('DELETE', `/announcements/${id}`)
