import request from './request'
import { deserializeInvoices, deserializeInvoice } from '../utils/deserializer'
import { serializeInvoice } from '../utils/serializer'
import { cLog } from '../utils'

export const getInvoices = async (query) => {
  const { data: invoices } = await request('GET', '/invoices' + (query || ''))
  //cLog('QUERY GET INVOICES', query)
  //cLog('KAIKKI INVOICET RAAKANA', invoices)  
  return deserializeInvoices(invoices)
}

export const getInvoice = async (id) => {
  const { data: invoice } = await request('GET', '/invoices/' + id)
  //cLog('RAAKA INVOICE', invoice)
  const deserializedInvoice = deserializeInvoice(invoice)
  //cLog('PARSITTU INVOICE', deserializedInvoice)
  return deserializedInvoice
}

export const saveInvoice = async (data) => {
  delete data.updateHistory
  const { data: newInvoice } = await request('POST', '/invoices', serializeInvoice(data))
  return deserializeInvoice(newInvoice)
}

export const updateInvoice = async (data) => {
  delete data.updateHistory
  const { data: updatedInvoice } = await request('PUT', `/invoices/${data.id}`, serializeInvoice(data))
  return deserializeInvoice(updatedInvoice)
}

export const sendCreateConsolidatedInvoiceRequest = async (data) => {
  delete data.updateHistory
  const response = await request('POST', '/invoices/consolidated_invoice', { "invoices": data })
  return response
}

export const sendCreateCreditNoteRequest = async (id) => {
  const response = await request('PUT', `/invoices/${id}/credit_note`)
  return response
}

export const sendCreateExpenseRequest = async (id) => {
  const response = await request('PUT', `/invoices/${id}/expence`)
  return response
}

export const sendSalaryRequest = async (id, salaryType) => {
  const response = await request('PUT', `/invoices/${id}/salary_request?type=${salaryType}`)
  return response
}

export const deleteInvoice = async (data) => await request('DELETE', `/invoices/${data.id}`)

export const printInvoicePdf = async (data) => {
  delete data.updateHistory
  cLog('PRINT PDF DATA', data)
  const response = await request('PUT', `/invoices/${data.id}/print_pdf`, data)
  return response
}

export const sendInvoiceRequest = async (data, reminder) => {
  delete data.updateHistory
  const { data: updatedInvoice } = await request('PUT', `/invoices/${data.id}/send_invoice`, { "reminder": reminder })
  return deserializeInvoice(updatedInvoice)
}

export const getCurrencyRates = async (date) => {
  const { data: currencyRates } = await request('GET', '/currency_rates/' + date)
  cLog('CURRENCY RATES', currencyRates)
  return currencyRates
}

export const sendInvoiceToNav = async (data) => {
  delete data.updateHistory
  const { data: updatedInvoice } = await request('PUT', `/invoices/${data.id}/send_invoice_to_nav`)
  return deserializeInvoice(updatedInvoice)
}

export const setInvoiceAccounted = async (id) => {
  const response = await request('PUT', `/invoices/${id}/set_accounted`)
  return response
}
