import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { sendNewPassword } from '../service';
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import Button from './Button'

const PasswordDialogOffice = ({ open, setOpenPasswordDialog, enqueueSnackbar, user }) => {
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handleGeneratePassWord = async () => {
    cLog('GENERATING PASSWORD')

    try {
      setLoading(true)
      const response = await sendNewPassword(user.id)
      cLog('SALASANA LUOTU', response)
      enqueueSnackbar(
        'Salasana on vaihdettu',
        { variant: 'success' }
      ) 
      setTimeout(() => {
        setLoading(false)
        setOpenPasswordDialog(false)
      }, 1500)
    } catch (error) {
      setLoading(false)
      cLog('GENGERATE PASSWORD ERROR', error)
      enqueueSnackbar(
        'Ongelmia salasanan luomisessa',
        { variant: 'error' }
      )
    }
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setOpenPasswordDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="password-dialog-office-title"
      aria-describedby="password-dialog-office-description"
    >
      <DialogTitle id="password-dialog-office-title">{t('user.changePasswordAdminTitle')}</DialogTitle>
      <DialogContent dividers={true} id="password-dialog-office-description">
        {t('user.changePasswordAdminContent')}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpenPasswordDialog(false)
          }}
          color="secondary"
          disabled={isLoading}
        >
          {t('user.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleGeneratePassWord}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('auth.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(PasswordDialogOffice)