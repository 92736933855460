export const COUNTRIES = { 
Afganistan: 'AFGHANISTAN',
Ahvenanmaa: 'ALAND_ISLANDS',
Alankomaat: 'NETHERLANDS',
Albania: 'ALBANIA',
Algeria: 'ALGERIA',
'Amerikan Samoa': 'AMERICAN_SAMOA',
Andorra: 'ANDORRA',
Angola: 'ANGOLA',
Anguilla: 'ANGUILLA',
Antarktis: 'ANTARCTICA',
'Antigua ja Barbuda': 'ANTIGUA_AND_BARBUDA',
Argentiina: 'ARGENTINA',
Armenia: 'ARMENIA',
Aruba: 'ARUBA',
Australia: 'AUSTRALIA',
Azerbaidžan: 'AZERBAIJAN',
Bahama: 'BAHAMAS',
Bahrain: 'BAHRAIN',
Bangladesh: 'BANGLADESH',
Barbados: 'BARBADOS',
Belgia: 'BELGIUM',
Belize: 'BELIZE',
Benin: 'BENIN',
Bermuda: 'BERMUDA',
Bhutan: 'BHUTAN',
Bolivia: 'PLURINATIONAL_STATE_OF_BOLIVIA',
'Bosnia-Hertsegovina': 'BOSNIA_AND_HERZEGOVINA',
Botswana: 'BOTSWANA',
'Bouvet’nsaari': 'BOUVET_ISLAND',
Brasilia: 'BRAZIL',
'Brittiläinen Intian valtameren alue': 'BRITISH_INDIAN_OCEAN_TERRITORY',
'Brittiläiset Neitsytsaaret': 'BRITISH_VIRGIN_ISLANDS',
Brunei: 'BRUNEI_DARUSSALAM',
Bulgaria: 'BULGARIA',
'Burkina Faso': 'BURKINA_FASO',
'Burma (Myanmar)': 'MYANMAR',
Burundi: 'BURUNDI',
Caymansaaret: 'CAYMAN_ISLANDS',
Chile: 'CHILE',
'Cook-saaret': 'COOK_ISLANDS',
'Costa Rica': 'COSTA_RICA',
'Curaçao': 'CURACAO',
Djibouti: 'DJIBOUTI',
Dominica: 'DOMINICA',
'Dominikaaninen tasavalta': 'DOMINICAN_REPUBLIC',
Ecuador: 'ECUADOR',
Egypti: 'EGYPT',
'El Salvador': 'EL_SALVADOR',
Eritrea: 'ERITREA',
Espanja: 'SPAIN',
Eswatini: 'ESWATINI',
'Etelä-Afrikka': 'SOUTH_AFRICA',
'Etelä-Georgia ja Eteläiset Sandwichsaaret': 'SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS',
'Etelä-Korea': 'REPUBLIC_OF_KOREA',
'Etelä-Sudan': 'SOUTH_SUDAN',
Etiopia: 'ETHIOPIA',
Falklandinsaaret: 'FALKLAND_ISLANDS_MALVINAS',
'Fidži': 'FIJI',
Filippiinit: 'PHILIPPINES',
Färsaaret: 'FAROE_ISLANDS',
Gabon: 'GABON',
Gambia: 'GAMBIA',
Georgia: 'GEORGIA',
Ghana: 'GHANA',
Gibraltar: 'GIBRALTAR',
Grenada: 'GRENADA',
Grönlanti: 'GREENLAND',
Guadeloupe: 'GUADELOUPE',
Guam: 'GUAM',
Guatemala: 'GUATEMALA',
Guernsey: 'GUERNSEY',
Guinea: 'GUINEA',
'Guinea-Bissau': 'GUINEA_BISSAU',
Guyana: 'GUYANA',
Haiti: 'HAITI',
'Heard ja McDonaldinsaaret': 'HEARD_ISLAND_AND_MCDONALD_ISLANDS',
Honduras: 'HONDURAS',
'Hong Kong': 'HONG_KONG',
Huippuvuoret: 'SVALBARD_AND_JAN_MAYEN',
Indonesia: 'INDONESIA',
Intia: 'INDIA',
Irak: 'IRAQ',
Iran: 'ISLAMIC_REPUBLIC_OF_IRAN',
Irlanti: 'IRELAND',
Islanti: 'ICELAND',
'Iso-Britannia': 'UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND',
Israel: 'ISRAEL',
Italia: 'ITALY',
'Itä-Timor': 'TIMOR_LESTE',
Itävalta: 'AUSTRIA',
Jamaika: 'JAMAICA',
Japani: 'JAPAN',
Jemen: 'YEMEN',
Jersey: 'JERSEY',
Jordania: 'JORDAN',
Joulusaari: 'CHRISTMAS_ISLAND',
'Kambodža': 'CAMBODIA',
Kamerun: 'CAMEROON',
Kanada: 'CANADA',
'Kap Verde': 'CABO_VERDE',
'Karibian Alankomaat': 'SINT_EUSTATIUS_AND_SABA_BONAIRE',
Kazakstan: 'KAZAKHSTAN',
Kenia: 'KENYA',
'Keski-Afrikan tasavalta': 'CENTRAL_AFRICAN_REPUBLIC',
Kiina: 'CHINA',
Kirgisia: 'KYRGYZSTAN',
Kiribati: 'KIRIBATI',
Kolumbia: 'COLOMBIA',
Komorit: 'COMOROS',
'Kongon demokraattinen tasavalta': 'THE_DEMOCRATIC_REPUBLIC_OF_THE_CONGO',
'Kongon tasavalta': 'CONGO',
Kookossaaret: 'COCOS_KEELING_ISLANDS',
Kosovo: 'KOSOVO_TEMPORARY',
Kreikka: 'GREECE',
Kroatia: 'CROATIA',
Kuuba: 'CUBA',
Kuwait: 'KUWAIT',
Kypros: 'CYPRUS',
Laos: 'LAO_PEOPLE_S_DEMOCRATIC_REPUBLIC',
Latvia: 'LATVIA',
Lesotho: 'LESOTHO',
Libanon: 'LEBANON',
Liberia: 'LIBERIA',
Libya: 'LIBYA',
Liechtenstein: 'LIECHTENSTEIN',
Liettua: 'LITHUANIA',
Luxemburg: 'LUXEMBOURG',
'Länsi-Sahara': 'WESTERN_SAHARA',
Macao: 'MACAO',
Madagaskar: 'MADAGASCAR',
Malawi: 'MALAWI',
Malediivit: 'MALDIVES',
Malesia: 'MALAYSIA',
Mali: 'MALI',
Malta: 'MALTA',
Mansaari: 'ISLE_OF_MAN',
Marokko: 'MOROCCO',
Marshallinsaaret: 'MARSHALL_ISLANDS',
Martinique: 'MARTINIQUE',
Mauritania: 'MAURITANIA',
Mauritius: 'MAURITIUS',
Mayotte: 'MAYOTTE',
Meksiko: 'MEXICO',
Mikronesia: 'FEDERATED_STATES_OF_MICRONESIA',
Moldova: 'REPUBLIC_OF_MOLDOVA',
Monaco: 'MONACO',
Mongolia: 'MONGOLIA',
Montenegro: 'MONTENEGRO',
Montserrat: 'MONTSERRAT',
Mosambik: 'MOZAMBIQUE',
Namibia: 'NAMIBIA',
Nauru: 'NAURU',
Nepal: 'NEPAL',
Nicaragua: 'NICARAGUA',
Niger: 'NIGER',
Nigeria: 'NIGERIA',
Niue: 'NIUE',
Norfolkinsaari: 'NORFOLK_ISLAND',
Norja: 'NORWAY',
Norsunluurannikko: 'COTE_D_IVOIRE',
Oman: 'OMAN',
Pakistan: 'PAKISTAN',
Palau: 'PALAU',
Palestiina: 'STATE_OF_PALESTINE',
Panama: 'PANAMA',
'Papua-Uusi-Guinea': 'PAPUA_NEW_GUINEA',
Paraguay: 'PARAGUAY',
Peru: 'PERU',
Pitcairnsaaret: 'PITCAIRN',
'Pohjois-Korea': 'DEMOCRATIC_PEOPLE_S_REPUBLIC_OF_KOREA',
 'Pohjois-Makedonia': 'NORTH_MACEDONIA',
'Pohjois-Mariaanit': 'NORTHERN_MARIANA_ISLANDS',
Portugali: 'PORTUGAL',
'Puerto Rico': 'PUERTO_RICO',
Puola: 'POLAND',
'Päiväntasaajan Guinea': 'EQUATORIAL_GUINEA',
Qatar: 'QATAR',
Ranska: 'FRANCE',
'Ranskan eteläiset ja antarktiset alueet': 'FRENCH_SOUTHERN_TERRITORIES',
'Ranskan Guayana': 'FRENCH_GUIANA',
'Ranskan Polynesia': 'FRENCH_POLYNESIA',
'Réunion': 'REUNION',
Romania: 'ROMANIA',
Ruanda: 'RWANDA',
Ruotsi: 'SWEDEN',
'Saint Helena': 'ASCENSION_AND_TRISTAN_DA_CUNHA_SAINT_HELENA',
'Saint Kitts ja Nevis': 'SAINT_KITTS_AND_NEVIS',
'Saint Lucia': 'SAINT_LUCIA',
'Saint Vincent ja Grenadiinit': 'SAINT_VINCENT_AND_THE_GRENADINES',
'Saint-Barthélemy': 'SAINT_BARTHELEMY',
'Saint-Martin': 'SAINT_MARTIN_FRENCH_PART',
'Saint-Pierre ja Miquelon': 'SAINT_PIERRE_AND_MIQUELON',
Saksa: 'GERMANY',
Salomonsaaret: 'SOLOMON_ISLANDS',
Sambia: 'ZAMBIA',
Samoa: 'SAMOA',
'San Marino': 'SAN_MARINO',
'Saudi-Arabia': 'SAUDI_ARABIA',
Senegal: 'SENEGAL',
Serbia: 'SERBIA',
Seychellit: 'SEYCHELLES',
'Sierra Leone': 'SIERRA_LEONE',
Singapore: 'SINGAPORE',
'Sint Maarten': 'SINT_MAARTEN_DUTCH_PART',
Slovakia: 'SLOVAKIA',
Slovenia: 'SLOVENIA',
Somalia: 'SOMALIA',
'Sri Lanka': 'SRI_LANKA',
Sudan: 'SUDAN',
Suomi: 'FINLAND',
Suriname: 'SURINAME',
Sveitsi: 'SWITZERLAND',
Syyria: 'SYRIAN_ARAB_REPUBLIC',
'São Tomé ja Príncipe': 'SAO_TOME_AND_PRINCIPE',
'Tadžikistan': 'TAJIKISTAN',
Taiwan: 'PROVINCE_OF_CHINA_TAIWAN',
Tansania: 'UNITED_REPUBLIC_OF_TANZANIA',
Tanska: 'DENMARK',
Thaimaa: 'THAILAND',
Togo: 'TOGO',
Tokelau: 'TOKELAU',
Tonga: 'TONGA',
'Trinidad ja Tobago': 'TRINIDAD_AND_TOBAGO',
Tunisia: 'TUNISIA',
Turkki: 'TURKEY',
Turkmenistan: 'TURKMENISTAN',
'Turks- ja Caicossaaret': 'TURKS_AND_CAICOS_ISLANDS',
Tuvalu: 'TUVALU',
'Tšad': 'CHAD',
'Tšekki': 'CZECH_REPUBLIC',
Uganda: 'UGANDA',
Ukraina: 'UKRAINE',
Unkari: 'HUNGARY',
Uruguay: 'URUGUAY',
'Uusi-Kaledonia': 'NEW_CALEDONIA',
'Uusi-Seelanti': 'NEW_ZEALAND',
Uzbekistan: 'UZBEKISTAN',
'Valko-Venäjä': 'BELARUS',
Vanuatu: 'VANUATU',
Vatikaani: 'HOLY_SEE_VATICAN_CITY_STATE',
Venezuela: 'BOLIVARIAN_REPUBLIC_OF_VENEZUELA',
Venäjä: 'RUSSIAN_FEDERATION',
Vietnam: 'VIET_NAM',
Viro: 'ESTONIA',
'Wallis ja Futuna': 'WALLIS_AND_FUTUNA',
'Yhdistyneet arabiemiirikunnat': 'UNITED_ARAB_EMIRATES',
Yhdysvallat: 'UNITED_STATES_OF_AMERICA',
'Yhdysvaltain erillissaaret': 'UNITED_STATES_MINOR_OUTLYING_ISLANDS',
'Yhdysvaltain Neitsytsaaret': 'U_S_VIRGIN_ISLANDS',
Zimbabwe: 'ZIMBABWE'
}