import request from './request'
//import { cLog } from '../utils'

export const getMessages = async () => {
  const { data: messages } = await request('GET', '/messages')
  return messages
}
 
export const saveMessage = async (data) => {
  delete data.updateHistory
  const { data: newMessage } = await request('POST', '/messages', data) 
  return newMessage
}

export const updateMessage = async (data) => {
  delete data.updateHistory
  const { data: updatedMessage } = await request('PUT', `/messages/${data.id}`, data) 
  return updatedMessage
}
 
export const deleteMessage = async (data) => await request('DELETE', `/messages/${data.id}`)
