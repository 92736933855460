import styled from 'styled-components'
import { COLORS } from '../styles'

const StatusCard = styled.button`
background: ${props => props.error ? 'red' : 'white'};
color: ${props => props.error ? 'white' : COLORS.primary};
max-width: ${props => props.small ? "55px" : '170px'};
width: 100%;
font-size:  ${props => props.small ? "0.7rem" : "0.8rem"};
font-weight: 700;
padding: ${props => props.small ? "0.35em 0.8em" : ".55em .8em"};
border: none;
border-radius: 7px;
pointer-events: none;
`;

export default StatusCard;
