import React from 'react'
import Select from 'react-select'
import InfoPop from './InfoPop'
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next'
import { REACT_SELECT_STYLES_CONTROL, REACT_SELECT_STYLES_OPTION } from '../styles'

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    width: '100%',
    textAlign: 'left',
  },
  customInputLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(1),
  },
}));

const DropDown = (props) => {
const { t } = useTranslation();
const theme = useTheme();
const classes = useStyles();

let value = (props.field && props.options && props.options.length > 0 && props.options.find(option => option.value === props.field.value)) || props.value
if (props.field && props.field.name === 'client') {
  value = props.field.value
    ? props.options.find(option => option.label === props.field.value.name)
    : null
}

return (
  <div className={classes.customInputContainer} style={props.style}>
    {props.label &&
      <label htmlFor={props.id} className={classes.customInputLabel}>
      <span>{props.label} {props.required && "*"}</span>
        {props.info && <InfoPop info={props.info}/>}
      </label>
    }
    <Select
      {...props}
      ref={props.innerRef}
      isMulti={props.isMulti}
      value={value}
      noOptionsMessage={() => t('invoice.noOptionsMessage')}
      menuPortalTarget={document.body}
      styles={{
        option: (provided, state) => REACT_SELECT_STYLES_OPTION(provided, state, theme, props),
        control: (provided, state) => REACT_SELECT_STYLES_CONTROL(provided, state, theme, props),
        menuPortal: base => ({ ...base, zIndex: 9999 })
      }}
    />
  </div>
)
}

export default DropDown;
