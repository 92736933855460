import React from 'react'
// https://github.com/Hacker0x01/react-datepicker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { format, setMonth, getMonth, getYear, getDate } from 'date-fns'
import range from "lodash/range"
import { registerLocale } from  "react-datepicker"
import { enGB, fi } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { makeStyles, alpha } from '@material-ui/core/styles'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import InfoPop from './InfoPop'
import "../styles/datepicker.css"

registerLocale('en', enGB)
registerLocale('fi', fi)

const useStyles = (props) => makeStyles((theme) => ({
  customInputContainer: {
    width: '100%',
  },
  customInputLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(1),
  },
  customDatePickerInput: {
    position: 'relative',
    background: theme.palette.white.main,
    color: theme.palette.white.contrastText,
    border: theme.borders.thin(props.error ? theme.palette.error.main : theme.palette.grey.dark),
    borderRadius: theme.borders.radius.small,
    width: '100%',
    outline: 'none',
    padding: theme.spacing(2, 3),
    fontSize: "1rem",
    minHeight: "46px",
    boxShadow: 'none',
    transition: 'border-color 0.125s, box-shadow 0.125s',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      boxShadow: `${alpha(props.error ? theme.palette.error.main : theme.palette.primary.main, 0.9)} 0 0 0 1px`,
      borderColor: props.error ? theme.palette.error.main : theme.palette.primary.main,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
}))();

const locales = {
  fi,
  enGB
}

let month
let year

const customHeader = ({ locale, date, changeMonth, changeYear, prevMonthButtonDisabled, nextMonthButtonDisabled, maxDate }) => {
  const years = range(2002, getYear(maxDate) + 1, 1).reverse()
  const months = new Array(12).fill(null).map((_, i) => ({
    value: i,
    label: format(setMonth(new Date(), i), 'LLLL', {
      locale: locales[locale]
    }),
  }))

  const handleYearChange = ({ target: { value } }) => changeYear(value)
  const handleMonthChange = ({ target: { value } }) => changeMonth(value)

  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <button onClick={(e) => {
          // otherwise re renders whole page???
          e.preventDefault()
          if (month === 0) {
            changeYear(year - 1)
            changeMonth(11)
          } else {
            changeMonth(month - 1)
          }
        }}
        disabled={prevMonthButtonDisabled}
      >
        <ArrowLeftIcon />
      </button>

      <select onChange={handleMonthChange} value={getMonth(date)} style={{ backgroundColor: '#fff' }}>
        {months.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>

      <select onChange={handleYearChange} value={getYear(date)} style={{ backgroundColor: '#fff' }}>
        {years.map(year => (
          <option value={year} key={year}>
            {year}
          </option>
        ))}
      </select>

      <button onClick={(e) => {
          e.preventDefault()
          if (month === 11) {
            changeYear(year + 1)
            changeMonth(0)
          } else {
            changeMonth(month + 1)
          }
        }}
        disabled={nextMonthButtonDisabled}
      >
        {<ArrowRightIcon />}
      </button>
    </div>
  )
}

const renderDayContents = (day, date) => {
  return <span>{getMonth(date) === month ? getDate(date) : ''}</span>
}

const DPicker = (props, ref) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles({ error: props.error })
  const maxDate = props.maxDate || new Date(getYear(new Date()) + 1, 11, 31)

  return (
    <div className={classes.customInputContainer}>
      <label htmlFor={props.id} className={classes.customInputLabel}>
        <span>{props.label} {props.required && "*"}</span>
        {props.info && <InfoPop info={props.info}/>}
      </label>
      <DatePicker
        {...props}
        renderCustomHeader={props => customHeader({ ...props, locale: i18n.language, maxDate: maxDate })}
        locale={i18n.language}
        dateFormat="P"
        showWeekNumbers
        className={classes.customDatePickerInput}
        showPopperArrow={false}
        onCalendarOpen={(date) => {
          month = props.selected ? getMonth(props.selected) : getMonth(new Date())
          year = props.selected ? getYear(props.selected) : getYear(new Date())
        }}
        disabledKeyboardNavigation
        onMonthChange={(date) => month = getMonth(date)}
        onYearChange={(date) => year = getYear(date)}
        renderDayContents={renderDayContents}
        minDate={new Date(2002, 0, 1)}
        maxDate={maxDate}
        placeholderText={t('datepicker.placeholderText')}
      />
    </div>
  )
}

export default DPicker
