import request from './request'
import { deserializeClients, deserializeClient } from '../utils/deserializer'
//import { cLog } from '../utils'

export const getClients = async (query) => {
  const { data: clients } = await request('GET', '/clients' + (query ? query : ''))
  return deserializeClients(clients)
}

export const getClientNames = async (query) => {
  const { data: clients } = await request('GET', '/client_names' + (query || ''))
  return clients
}

export const getClient = async (id) => {
  const { data: client } = await request('GET', '/clients/' + id)
  return deserializeClient(client)
}

export const saveClient = async (data) => {
  let d = { ...data }
  delete d.updateHistory
  const { data: newClient } = await request('POST', '/clients', d)
  return deserializeClient(newClient)
}

export const updateClient = async (data) => {
  let d = { ...data }
  delete d.updateHistory
  const { data: updatedClient } = await request('PUT', `/clients/${data.id}`, d)
  return deserializeClient(updatedClient)
}

export const deleteClient = async (id) => await request('DELETE', `/clients/${id}`)
