import request from './request'
import { deserializeUsers, deserializeUser, deserializeUserNotes, deserializeUserNote } from '../utils/deserializer'
import { serializeUser } from '../utils/serializer'
import { cLog } from '../utils'
import axios from 'axios'

export const getUsers = async (query) => {
  const { data: users } = await request('GET', '/users' + (query || ''))
  return deserializeUsers(users)
}

export const getUser = async (id) => {
  const { data: user } = await request('GET', '/users/' + id) 
  return deserializeUser(user)
}

export const getUserView = async (id, history = false) => {
  const { data: user } = await request('GET', '/users/' + id + '/view') 
  return deserializeUser(user)
}

export const saveUser = async (data) => {
  delete data.updateHistory
  const { data: newUser } = await request('POST', '/users', data)
  return newUser
} 

export const updateUser = async (data) => {
  delete data.updateHistory
  const { data: updatedUser } = await request('PUT', `/users/${data.id}`, serializeUser(data))
  return deserializeUser(updatedUser)
}

export const updateCurrentUser = async (data) => {
  delete data.updateHistory
  //cLog('USER SERVICE UPDATED CURRENT USER BEFORE', data)
  const { data: updatedCurrentUser } = await request('PUT', `/users/${data.id}`, data)
  //cLog('USER SERVICE UPDATED CURRENT USER AFTER', updatedCurrentUser)
  return updatedCurrentUser
}

export const deleteUser = async (id) => await request('DELETE', `/users/${id}`)

export const inviteUser = async (email) => await request('POST', '/users/invite_user', email)

export const getAuthData = async (code) => {
  const { data: authData } = await request('POST', `/users/auth_data`, {code: code})
  return authData
}

export const postConfirmAccount = async (code, userId) => {
  const { data: authData } = await request('POST', `/users/${userId}/confirm_account`, {code: code})
  return authData
}

export const sendSalaryCalculation = async (data) => {
  const {data: salaryResponse } = await request('POST', `/salaries`, data)
  return salaryResponse
}

export const getUserNotes = async (query) => {
  const { data: userNotes } = await request('GET', '/user_notes' + (query || ''))
  return deserializeUserNotes(userNotes)
}

export const getUserNote = async (id) => {
  const { data: userNote } = await request('GET', '/user_notes/' + id) 
  return deserializeUserNote(userNote)
}

export const sendNewNote = async (data) => {
  const {data: newNoteResponse } = await request('POST', `/user_notes`, data)
  return newNoteResponse
}

export const updateUserNote = async (data) => {
  delete data.updateHistory
  const {data: updatedNoteResponse } = await request('PUT', `/user_notes/${data.id}`, data)
  return updatedNoteResponse
}

export const deleteUserNote = async (id) => await request('DELETE', `/user_notes/${id}`)

export const moveUserData = async (data) => await request('PUT', `/users/${data.id}/move_profile`, data)

export const getUserLocation = async () => {
  return await axios({
      method: 'POST',
      url: 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      data: JSON.stringify({ "considerIp": "true" }),
      timeout: 0,
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(response => {
      const lat = response.data.location.lat
      const lng = response.data.location.lng
      window.localStorage.setItem('userLocationLat', lat)
      window.localStorage.setItem('userLocationLng', lng)
      return { lat, lng }
    })
    .catch((error) => {
      cLog('GOOGLE GEOLOCATION ERROR', error)
      return false
    })
}
