import React, { useState } from 'react'
import { moveUserData, getBackgroundJob } from '../service'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import SelectAsync from './SelectAsync'
import { useHistory } from 'react-router-dom'
import { Field } from 'formik'

const MoveUserDataDialog = React.forwardRef(
  (props, ref) => {
    const {
      resetForm,
      values,
      setFieldValue,
      validateForm,
      errors,
      enqueueSnackbar,
      open, 
      setOpenMoveUserDataDialog,
      user
    } = props

    const { t } = useTranslation()
    const [isLoading, setLoading] = useState(false)
    let history = useHistory()

    const validateNewId = (value) => {
      if (!String(value).trim()) {
        return { key: t('user.moveUserDataNoId') }
      }
      if (value === user.id) {
        return { key: t('user.moveUserDataSameId') }
      }
    }

    const notifyErrors = (erroris) => {
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))
      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const handleMoveUserData = async () => {
      setLoading(true)
      const errori = await validateForm()
      if (Object.keys(errori).length > 0) {
        notifyErrors(errori)
        setLoading(false)
      } else {
        const request = moveUserData({ ...values, id: user.id })
        request.then(response => {
          const interval = setInterval(function() {
            let request = getBackgroundJob(response.data.jobId)
            request.then(response => {
              if (response.data.done === 1) {
                enqueueSnackbar(
                  t('user.moveUserDataSuccess', { name: `${user.firstName || 'N.'} ${user.lastName || 'N.'}` }),
                  { variant: 'success' }
                )
                clearInterval(interval)
                setLoading(false)
                setOpenMoveUserDataDialog(false)
                resetForm()
                history.push('/')
              } else if (response.data.done === -2) {
                cLog('MOVE USER DATA ERROR1')
                enqueueSnackbar(
                  t('user.problemsDeletingUser'),
                  { variant: 'error' }
                )
                clearInterval(interval)
                setLoading(false)
                setOpenMoveUserDataDialog(false)
                resetForm()
              } else if (response.data.total === -1) {
                cLog('MOVE USER DATA ERROR2')
                enqueueSnackbar(
                  t('user.moveUserDataError'),
                  { variant: 'error' }
                )
                clearInterval(interval)
                setLoading(false)
                setOpenMoveUserDataDialog(false)
                resetForm()
              }
            }, error => {
              cLog('MOVE USER DATA ERROR3', error.response.data.errors)
              enqueueSnackbar(
                t('user.moveUserDataError'),
                { variant: 'error' }
              )
              clearInterval(interval)
              setLoading(false)
              setOpenMoveUserDataDialog(false)
              resetForm()
            })
          }, 1000)
        }, error => {
          cLog('MOVE USER DATA ERROR4', error.response.data.errors)
          enqueueSnackbar(
            t('user.moveUserDataError'),
            { variant: 'error' }
          )
          setLoading(false)
          setOpenMoveUserDataDialog(false)
          resetForm()
        })
      }
    }
      
    return (
      <Dialog
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setLoading(false)
            setOpenMoveUserDataDialog(false)
            resetForm()
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="move-user-data-dialog-title"
        aria-describedby="move-user-data-dialog-description"
      >
        <DialogTitle id="move-user-data-dialog-title">{t('user.moveUserData')}</DialogTitle>
        <DialogContent dividers={true} id="move-user-data-dialog-description">
          <form>
            <Field
              name="newId"
              id='newId'
              label={t('user.moveUserDataContent')}
              component={SelectAsync}
              placeholder={t('invoice.typeToSearch')}
              onChange={(e) => { setFieldValue('newId', e.value) }}
              validate={() => validateNewId(values.newId)}
              error={errors && errors.newId && validateNewId(values.newId)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLoading(false)
              setOpenMoveUserDataDialog(false)
              resetForm()
            }}
            color="secondary"
            disabled={isLoading}
          >
            {t('frontPage.cancelButton')}
          </Button>
          <Button
            onClick={handleMoveUserData}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : t('user.move')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default withSnackbar(MoveUserDataDialog)
