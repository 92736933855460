import React, { useState, useEffect } from 'react'
import { useStateValue, setAnnouncements } from "../../state"
import { getAnnouncements } from '../../service'
import Boxes from './Boxes'
import InvoiceFilterTable from '../Invoice/InvoiceFilterTable'
import SalaryCalculatorDialog from '../../components/SalaryCalculatorDialog'
import YelDialog from '../../components/YelDialog'
import Footer from '../../screens/Footer'
import { cLog } from '../../utils'
import InviteUserDialog from '../../components/InviteUserDialog'
import PromoDialog from '../../components/PromoDialog'
import LayoutContainer from '../../components/LayoutContainer'
import { withSnackbar } from 'notistack'
import LandingScreenHeader from "./LandingScreenHeader"
import LandingShortcuts from "./LandingShortcuts"
import Valtticards from '../Valtticards/Valtticards'
import References from "../References/References"

const LandingScreen = ({ enqueueSnackbar }) => {
  const [{ currentUser }, dispatch] = useStateValue()
  const [openSalaryCalculatorDialog, setOpenSalaryCalculatorDialog] = useState(false)
  const [openYelDialog, setOpenYelDialog] = useState(false)
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false)
  const [openPromoDialog, setOpenPromoDialog] = useState(false)

  // remove unfinished sign up data
  window.localStorage.removeItem('signUpEmail')
  window.localStorage.removeItem('signUpPhone')
  window.localStorage.removeItem('signUpPassword')
  window.localStorage.removeItem('signUpPasswordConfirmation')
  window.localStorage.removeItem('signUpDiscountCode')
  window.localStorage.removeItem('signUpDiscountExplanation')

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allAnnouncements = await getAnnouncements()
        //cLog('ALL INFOS', allAnnouncements)
        dispatch(setAnnouncements(allAnnouncements.announcements))
      } catch (error) {
        cLog('FETCHING INITAL DATA ERROR', error)
      }
    }

    if (!isAdmin) {
      fetchData()
    }
  }, [dispatch, isAdmin])

  const currentUserName = currentUser && (currentUser.firstName || 'Uusi') + ' ' + (currentUser.lastName || 'käyttäjä')

  return (
    <LayoutContainer>
      {!isAdmin
        ?
        <>
          <LandingScreenHeader currentUserName={currentUserName}/>
          <LandingShortcuts
            currentUser={currentUser}
            setOpenInviteUserDialog={setOpenInviteUserDialog}
            setOpenPromoDialog={setOpenPromoDialog}
          />
          <Boxes
            setOpenSalaryCalculatorDialog={setOpenSalaryCalculatorDialog}
            setOpenYelDialog={setOpenYelDialog}
          />
        </>
        :
        <>
          <Valtticards landingScreen={true} />
          <References landingScreen={true} />
        </>
      }
      <InvoiceFilterTable />
      {!isAdmin &&
        <>
          <SalaryCalculatorDialog open={openSalaryCalculatorDialog} setOpenSalaryCalculatorDialog={setOpenSalaryCalculatorDialog} />
          <YelDialog open={openYelDialog} setOpenYelDialog={setOpenYelDialog} />
          <InviteUserDialog open={openInviteUserDialog} setOpenInviteUserDialog={setOpenInviteUserDialog} />
          <PromoDialog open={openPromoDialog} setOpenPromoDialog={setOpenPromoDialog} isAdmin={false} />
          <Footer landingScreen />
        </>}
    </LayoutContainer>
  )
}

export default withSnackbar(LandingScreen)
