import React, { useState } from 'react'
import { Grid, CardContent, List, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import YelIcon from '@material-ui/icons/Security'
import InfoIcon from '@material-ui/icons/Info'
import TaxCardIcon from '@material-ui/icons/InsertDriveFile'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import LaunchIcon from "@material-ui/icons/Launch"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import { useStateValue } from "../../state"
import { getAnnouncement } from '../../service'
import SummaryBox from '../User/SummaryBox'
import { cLog } from '../../utils'
import { withSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import InfoPopper from '../../components/InfoPopper'
import ShortcutButton from '../../components/ShortcutButton'
import TitledCard from '../../components/TitledCard'
import Alert from '../../components/Alert'
import parse from 'html-react-parser'

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    width: '2rem !important',
    height: '2rem !important',
    color: theme.palette.error.main,

    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.error.light,
    },
  },
}))

const Boxes = ({ setOpenSalaryCalculatorDialog, setOpenYelDialog, enqueueSnackbar }) => {
  const { t, i18n } = useTranslation()
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [announcement, setAnnouncement] = useState(null)
  const [{ currentUser, announcements }] = useStateValue()
  let history = useHistory()
  let language = i18n.language
  const [anchorYel, setAnchorYel] = useState(null)
  const [anchorTax, setAnchorTax] = useState(null)
  const validTaxCard = currentUser.taxcards[0] && currentUser.taxcards[0].dateStart.substring(0, 4) === new Date().toISOString().split('T')[0].substring(0, 4)
  const classes = useStyles()

  const saveYelState = (event) => {
    localStorage.setItem('yelCleared', true)
    setAnchorYel(null)
  }

  const yelText = <div>{t('frontPage.yelInfoStart')}<a href='/info?panel=5b'>{t('frontPage.yelInfoEnd')}</a><Button style={{display: 'block', margin: 'auto'}} onClick={saveYelState}>OK</Button></div>

  const taxText = <div>{t('frontPage.taxCardInfoStart')}<a href={'/verokortti/'+currentUser.id}>{t('frontPage.taxCardInfoEnd')}</a></div>

  const handleYelOpen = (event) => {
    event.stopPropagation()
    setAnchorTax(null)
    setAnchorYel(anchorYel ? null : event.currentTarget);
  }

  const handleTaxOpen = (event) => {
    event.stopPropagation()
    setAnchorYel(null)
    setAnchorTax(anchorTax ? null : event.currentTarget);
  }

  const handleTaxClose = () => {
    setAnchorTax(null)
  }

  const handleYelClose = () => {
    setAnchorYel(null)
  }

  const handleShowAnnouncement = async (id) => {
    try {
      const thisAnnouncement = await getAnnouncement(id)
      setAnnouncement(thisAnnouncement)
      setOpenInfoDialog(true)
    } catch (error) {
      cLog('GET INFO BY ID ERROR', error.response.data.errors)
    }
  }

  const handleGoToTaxcard = () => {
    history.push('/verokortti/' + currentUser.id)
  }

  const sizes = { xs: 12, sm: 12,  md: 26, lg: 26, xl: 26 };

  return (
    <div>
      <Grid container spacing={4}>

        <Grid item xs={12} md={7}>
          <TitledCard variant="contained" color="primary" title={t('frontPage.announcementsTitle')} fillWidth fillHeight>
            <List>
              {announcements && announcements.filter(announcement => announcement.isPublished).map((announcement, idx) => (
                  <ListItem key={idx} button>
                    <ListItemText onClick={() => handleShowAnnouncement(announcement.id)}>{language === 'en' && announcement.titleEn ? announcement.titleEn : announcement.titleFi} <LaunchIcon style={{ fontSize: '0.8rem' }}/></ListItemText>
                  </ListItem>
                )
              )}
            </List>
          </TitledCard>
        </Grid>

        <Grid item xs={12} md={5}>
          <SummaryBox/>
        </Grid>

        <Grid item xs={12}>
          <TitledCard variant="contained" color="primary" title={t('frontPage.shortcutsTitle')} fillWidth fillHeight>
            <InfoPopper info={yelText} anchorEl={anchorYel} handleClose={handleYelClose}/>
            <InfoPopper info={taxText} anchorEl={anchorTax} handleClose={handleTaxClose}/>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={6} sm={3}>
                  <ShortcutButton
                    color="white"
                    variant="outlined"
                    size={sizes}
                    fillWidth
                    fillHeight
                    onClick={() => setOpenYelDialog(true)}
                  >
                    {!localStorage.getItem('yelCleared') && <ErrorOutlineIcon onClick={handleYelOpen} className={classes.errorIcon}/>}
                    <YelIcon />
                    <span>{t('frontPage.insuranceShortcut')}</span>
                  </ShortcutButton>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ShortcutButton
                    color="white"
                    variant="outlined"
                    size={sizes}
                    fillWidth
                    fillHeight
                    onClick={() => history.push('/info')}
                  >
                    <InfoIcon />
                    <span>{t('frontPage.infoShortcut')}</span>
                  </ShortcutButton>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ShortcutButton
                    color="white"
                    variant="outlined"
                    size={sizes}
                    fillWidth
                    fillHeight
                    onClick={handleGoToTaxcard}
                  >
                    {(currentUser.taxcards.length === 0 || !validTaxCard) && <ErrorOutlineIcon onClick={handleTaxOpen} className={classes.errorIcon}/>}
                    <TaxCardIcon />
                    <span>{t('frontPage.taxcardShortcut')}</span>
                  </ShortcutButton>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ShortcutButton
                    color="white"
                    variant="outlined"
                    size={sizes}
                    fillWidth
                    fillHeight
                    onClick={() => setOpenSalaryCalculatorDialog(true)}
                  >
                    <KeyboardIcon />
                    <span>{t('frontPage.salaryShortcut')}</span>
                  </ShortcutButton>
                </Grid>
              </Grid>
            </CardContent>
          </TitledCard>
        </Grid>
      </Grid>
      <Alert
        open={openInfoDialog}
        setOpen={setOpenInfoDialog}
        handleClose={() => setOpenInfoDialog(false)}
        alertMessage={{ title: announcement ? (language === 'en' && announcement.titleEn ? announcement.titleEn : announcement.titleFi) : '', body: announcement ? parse(language === 'en' && announcement.htmlEn ? announcement.htmlEn : announcement.htmlFi) : '' }}
        hideClose={true}
        handleAgree={() => setOpenInfoDialog(false)}
      />
    </div>
  )
}

export default withSnackbar(Boxes)
