import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { parseVat } from '../utils/invoiceUtils'
import Input from './Input'
import Button from './Button'
import CheckBox from './CheckBox'
import DropDown from './DropDown'
import { useTranslation } from 'react-i18next'
//import { cLog } from '../utils'

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%'
  },
  justifyEnd: {
    textAlign: 'right',
    float: 'right',
  },
}))

const INIT_FORM = {
  invoice: '',
  vat: '24',
  withVat: false,
  commission: 5,
  taxPercentage: '',
  km: '',
  dailyAllowances: '',
  partTimeDailyAllowances: '',
  mealExpences: '',
  materialExpences: '',
  otherExpences: ''
}

/////TODO : selvitä kuinka paljon saa olla kuluja. Css:t pikseleistä prosenteiksi?

export default function SalaryCalculatorDialog({ open, setOpenSalaryCalculatorDialog }) {
  const { t } = useTranslation();
  const [form, setForm] = useState(INIT_FORM);
  const [result, setResult] = useState({})
  const [error, setError] = useState('')
  const classes = useStyles()

  useEffect(() => {
    const calculate = () => {
      const invoiceTotal = form.invoice / (form.withVat ? (1 + parseVat(form.vat) / 100) : 1)
      //cLog('----------------------')
      //cLog('KOKO LASKUTUSSUMMA', invoiceTotal)
      const travelCosts = form.km * 0.42
      const dailyAllowances = form.dailyAllowances * 42
      const partTimeDailyAllowances = form.partTimeDailyAllowances * 20
      const mealExpences = form.mealExpences * 10.75
      const allExpences = travelCosts + dailyAllowances + partTimeDailyAllowances + mealExpences + Number(form.materialExpences) + Number(form.otherExpences)
      //cLog('KAIKKI KULUT', allExpences)
      const bruttoSalary = invoiceTotal - allExpences  //  -commission  - ei vielä tästä komissiota
      //cLog('BRUTTO', bruttoSalary)
      const taxes = bruttoSalary * form.taxPercentage / 100
      //cLog('ENNAKOPIDÄTYS', taxes)
      const sotuPayment = 0.0134 * bruttoSalary // vanha 0.01066 uusi 0.0134
      //cLog('SOTU', sotuPayment)
      const accidentInsurance = 0.0372 * bruttoSalary
      //cLog('TAPATURMAVAKUUTUS', accidentInsurance)
      const commission = form.commission * 1 / 100 * invoiceTotal
      //cLog('KOMISSIO', commission)
      const nettoSalary = bruttoSalary - taxes - sotuPayment - accidentInsurance - commission
      //cLog('NETTO', nettoSalary)
      const nettoSalaryPlusExpences = nettoSalary + allExpences
      //cLog('KÄTEEN', nettoSalaryPlusExpences)
      //cLog('----------------------')
      if (allExpences / invoiceTotal > 0.487) {
        setError(t('invoice.tooManyExpenses'))
      } else {
        setError('')
        setResult({ invoiceTotal, bruttoSalary, allExpences, commission, taxes, sotuPayment, accidentInsurance, nettoSalary, nettoSalaryPlusExpences })
      }
    }

    calculate()
  }, [form, t])

  const vatOptions = React.useMemo(() => {
    return ['24', '14', '10', '0', 'käännetty alv']
      .map(vat => {
        const label = (vat === 'käännetty alv' ? t('invoice.reverseVat') : vat + '%')
        return ({ label, value: vat })
      })
  }, [t])

  const commissionOptions = React.useMemo(() => {
    return [5, 4, 3]
      .map(commission => {
        const label = commission + '%'
        return ({ label, value: commission })
      })
  }, [])

  //cLog('CALCULATOR FORM', form)

  const handleChange = (e) => {
    const id = e.target.id
    const value = e.target.value
    if (value < 0) {
      return
    }
    if (id === 'taxPercentage') {
      if (value > 60) {
        return
      }
    }
    setForm({ ...form, [e.target.id]: e.target.value })
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setForm(INIT_FORM)
          setOpenSalaryCalculatorDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="salary-calculator-dialog-title"
      aria-describedby="salary-calculator-dialog-description"
    >
      <DialogTitle id="salary-calculator-dialog-title">{ t('invoice.salaryCalculator') }</DialogTitle>
      <DialogContent dividers={true} id="salary-calculator-dialog-description">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Input
              id='invoice'
              type='number'
              label={t('invoice.invoiceAmount')}
              value={form.invoice}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <DropDown
              id='vat'
              label={t('invoice.vat')}
              value={vatOptions.find(option => option.value === form.vat)}
              options={vatOptions}
              onChange={(e) => {
                setForm({ ...form, vat: e.value })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <CheckBox
              id='withVat'
              label={t('invoice.sumWithVat')}
              value={form.withVat}
              onChange={() => setForm({ ...form, withVat: !form.withVat })}
            />
          </Grid>

          <Grid item xs={12} sm={12}><h4>{t('invoice.basicInformation')}</h4> </Grid>

          <Grid item xs={12} sm={6}>
            <DropDown
              id='commission'
              label={t('invoice.commission')}
              value={commissionOptions.find(option => option.value === form.commission)}
              options={commissionOptions}
              onChange={(e) => {
                setForm({ ...form, commission: e.value })
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              id='taxPercentage'
              type='number'
              label={t('invoice.taxPercentage')}
              value={form.taxPercentage}
              max='60'
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12}><h4>{t('invoice.travelAndExpenses')}</h4> </Grid>

          <Grid item xs={12} sm={4}>
            <Input
              id='km'
              type='number'
              label={t('invoice.km')}
              placeholder='km'
              value={form.km}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              id='dailyAllowances'
              type='number'
              label={t('invoice.dailyAllowances')}
              placeholder={t('invoice.PIECE')}
              value={form.dailyAllowances}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              id='partTimeDailyAllowances'
              type='number'
              label={t('invoice.partTimeDailyAllowances')}
              placeholder={t('invoice.PIECE')}
              value={form.partTimeDailyAllowances}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              id='mealExpences'
              type='number'
              label={t('invoice.mealExpenses')}
              placeholder={t('invoice.PIECE')}
              value={form.mealExpences}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              id='materialExpences'
              type='number'
              label={t('invoice.materialExpenses')}
              placeholder={t('invoice.eurTotal')}
              value={form.materialExpences}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              id='otherExpences'
              type='number'
              label={t('invoice.otherExpenses')}
              placeholder={t('invoice.eurTotal')}
              value={form.otherExpences}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        {result.invoiceTotal > 0 &&
          <div>
            <h4>{!error ? <span>{t('invoice.amountLeft')} <strong>{result.nettoSalaryPlusExpences.toFixed(2)}€</strong></span> : <strong>{error}</strong>}</h4>
            <table className={classes.table} >
              <tbody>
                {result.invoiceTotal > 0 && <tr>
                  <td>{t('invoice.invoiceAmount')}</td>
                  <td className={classes.justifyEnd}>{result.invoiceTotal.toFixed(2)} €</td>
                </tr>}
                {result.bruttoSalary && <tr>
                  <td>{t('invoice.bruttoSalary')}</td>
                  <td className={classes.justifyEnd}>{result.bruttoSalary.toFixed(2)} €</td>
                </tr>}
                {result.allExpences > 0 && <tr>
                  <td>{t('invoice.travelAndExpenses')}</td>
                  <td className={classes.justifyEnd}>{result.allExpences.toFixed(2)} €</td>
                </tr>}
                {result.invoiceTotal > 0 && <tr>
                  <td>{t('invoice.commission')}</td>
                  <td className={classes.justifyEnd}>{result.commission.toFixed(2)} €</td>
                </tr>}
                {result.taxes > 0 && <tr>
                  <td>{t('invoice.taxAmount')}</td>
                  <td className={classes.justifyEnd}>{result.taxes.toFixed(2)} €</td>
                </tr>}
                {result.invoiceTotal > 0 && <tr>
                  <td>{t('invoice.socialSecurityAmount')}</td>
                  <td className={classes.justifyEnd}>{result.sotuPayment.toFixed(2)} €</td>
                </tr>}
                {result.invoiceTotal > 0 && <tr>
                  <td>{t('invoice.insuranceAmount')}</td>
                  <td className={classes.justifyEnd}>n. {result.accidentInsurance.toFixed(2)} €</td>
                </tr>}
                {result.invoiceTotal > 0 && <tr>
                  <td>{t('invoice.nettoSalary')}</td>
                  <td className={classes.justifyEnd}>{result.nettoSalary.toFixed(2)} €</td>
                </tr>}
                {result.invoiceTotal > 0 && result.allExpences > 0 && <tr>
                  <td>{t('invoice.nettoSalaryAndReimbursements')}</td>
                  <td className={classes.justifyEnd}><strong>{result.nettoSalaryPlusExpences.toFixed(2)}€</strong></td>
                </tr>}
              </tbody>
            </table>
          </div>}
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            setForm(INIT_FORM)
            setOpenSalaryCalculatorDialog(false)
          }}
        >
          {t('invoice.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={() => setForm(INIT_FORM)}
        >
          {t('invoice.empty')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
