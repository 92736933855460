import React from 'react'
import { Link, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    padding: '10px'
  }
}))

const AuthForm = ({onSubmit, title, children}) => {
  const { i18n } = useTranslation()
  const classes = useStyles()

  return (
    <form noValidate onSubmit={onSubmit}>
      {/* Auth Form Title */}
      <Typography component="h3" variant="h5">
        {title}
      </Typography>

      {children}

      {/* Auth Form Footer */}
      <Box mt={8}>
        <Link style={{padding: '10px'}} className={classes.link} onClick={() => i18n.changeLanguage("fi")} variant="body2">
          <img src={'/images/flag-finland.png'} alt={'FI'}/>
        </Link>
        <Link style={{padding: '10px'}} className={classes.link} onClick={() => i18n.changeLanguage("en")} variant="body2">
          <img src={'/images/flag-uk.png'} alt={'EN'}/>
        </Link>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://odeal.fi/">Odeal Oy</Link>
          {' '}{new Date().getFullYear()}{'.'}
        </Typography>
      </Box>
    </form>
  )
}

export default AuthForm
