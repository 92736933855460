import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Link } from "react-router-dom"
import { getQuery, getClient, deleteClient, getInvoices } from '../../service'
import { useStateValue, setClients, setSortKeyClients, setSortOrderClients } from "../../state"
import Button from '../../components/Button'
import { Tooltip } from '@material-ui/core'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { COUNTRIES } from '../../utils/countries'
import { LANGUAGES } from '../../utils/languages'
import { EINVOICE_OPERATORS, PAYMENT_TERMS_OPTIONS } from '../../utils/invoiceUtils'
import '../../styles/styles.css'

let selectedColumns  = []

const INIT_FILTER = {
  userId: [],
  name: '',
  totalAddress: '',
  country: '',
  email: '',
  phone: '',
  isPrivate: '',
  businessId: '',
  contactPerson: '',
  deliveryMethod: [],
  reminderHandling: [],
  language: [],
  createdAt: '',
  updatedAt: '',
  eInvoiceOperator: [],
  eInvoiceAddress: '',
  paymentTerms: '',
  actions: '',
  columnsOptions: []
}

const Clients = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  let history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const [{ clients, currentUser, sortKeyClients, sortOrderClients }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: (isAdmin ? 100 : 20),
    totalPages: 0,
    totalRows: 0
  })
  let allColumns = []
  if (isAdmin) {
    allColumns = ['userId', 'name', 'totalAddress', 'email', 'phone', 'isPrivate', 'businessId', 'contactPerson', 'deliveryMethod', 'reminderHandling', 'language', 'createdAt', 'updatedAt', 'einvoiceOperator', 'einvoiceAddress', 'paymentTerms', 'actions']
    selectedColumns = JSON.parse(window.localStorage.getItem('clientsSelectedColumns')) || allColumns
  } else {
    allColumns = ['name', 'totalAddress', 'email', 'phone', 'isPrivate', 'businessId', 'contactPerson', 'deliveryMethod', 'paymentTerms', 'actions']
    selectedColumns = allColumns
  }
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('customers.'+option),
      value: option
    }
    if (['totalAddress'].includes(option)) {
      obj.totalAddress = true
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row, userId) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                if (isAdmin) {
                  window.open('/asiakkaat/muokkaa/' + row.id)
                } else {
                  history.push('/asiakkaat/muokkaa/' + row.id)
                }
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.createInvoice')}>
            <AccountBalanceOutlinedIcon
              className="actionIcon"
              onClick={() => {
                if (isAdmin) {
                  window.open(`/laskut/uusi/${userId}/${row.id}`)
                } else {
                  history.push(`/laskut/uusi/${userId}/${row.id}`)
                }
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const invoicesByClient = getInvoices('?client_id=' + row.id)
                invoicesByClient.then(data => {
                  cLog('LASKUT PER ASIAKAS', data.invoices)
                  if (data.invoices && data.invoices.length > 0) {
                    cLog('CLIENT HAS INVOICES - CANNOT DELETE CLIENT')
                    enqueueSnackbar(
                     t('customers.customerHas')+JSON.stringify(data.invoices.length)+t('customers.cannotBeDeleted'),
                      { variant: 'error' }
                    )
                    setLoading(false)
                  } else {
                    const response = getClient(row.id)
                    response.then(data => {
                      setSelectedRow(data)
                      setOpenConfirmDeleteModal(true)
                      setLoading(false)
                    }, error => {
                      cLog('FETCHING DATA ERROR', error)
                      enqueueSnackbar(
                        t('auth.problemsLoadingData'),
                        { variant: 'error' }
                      )
                      setLoading(false)
                    })
                  }
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyClients, sortOrderClients, filter, selectedColumns, 'clients')
      response.then(data => {
        const dataActions = data.map(row => {
          const userId = row.userId
          row.actions = createActions(row, userId)
          if (selectedColumns.includes('totalAddress')) {
            let totalAddress = row.address1 || ''
            if (row.address2) {
              if (totalAddress) {
                totalAddress += '\n'
              }
              totalAddress += row.address2
            }
            if (row.postalCode || row.city) {
              if (totalAddress) {
                totalAddress += '\n'
              }
              totalAddress += ((row.postalCode || '') + ' ' + (row.city || '')).trim()
            }
            if (row.country && row.country !== 'FINLAND') {
              if (totalAddress) {
                totalAddress += '\n'
              }
              totalAddress += t('countries.'+row.country)
            }
            row.totalAddress = totalAddress
          }
          if (selectedColumns.includes('deliveryMethod')) {
            row.deliveryMethod = t('invoice.'+row.deliveryMethod)
          }
          if (selectedColumns.includes('reminderHandling')) {
            row.reminderHandling = t('invoice.'+row.reminderHandling)
          }
          if (selectedColumns.includes('language')) {
            row.language = t('user.'+row.language)
          }
          if (selectedColumns.includes('userId')) {
            const userName = `${row.userFirstName || 'N.'} ${row.userLastName || 'N.'}`
            row.userId = <Link to={`/kayttajat/${row.userId}`} target='_blank'>{userName}</Link>
          }
          return row
        })
        dispatch(setClients(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setClients([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyClients, sortOrderClients, filter, pagination, t, enqueueSnackbar, history, isAdmin])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    if (isAdmin) {
      document.addEventListener('keyup', handleKeyPress)
    }

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination, isAdmin])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.customers') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteClient(selectedRow.id)
      cLog(`CLIENT ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('customers.client')+`${selectedRow.name}`+t('customers.deleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE CLIENT ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('customers.problemsDeletingClient'),
        { variant: 'error' }
      )
    }
  }

  const countryOptions = Object.keys(COUNTRIES).map(country => ({ label: t('countries.'+COUNTRIES[country]), value: COUNTRIES[country] }))
  const isPrivateOptions = ['true', 'false'].map(option => ({ label: t('taxcard.'+option), value: option }))
  const deliveryMethodOptions = ['POSTI', 'SÄHKÖPOSTI', 'VERKKOLASKU', 'EI_LÄHETETÄ'].map(option => ({ label: t('invoice.'+option), value: option }))
  const reminderHandlingOptions = ['AUTOMAATTINEN', 'EI_MUISTUTETA'].map(option => ({ label: t('invoice.'+option.replace(/_/g, ' ')), value: option }))
  const languageOptions = Object.keys(LANGUAGES).map(lang => ({ label: t('customers.'+lang), value: LANGUAGES[lang] }))
  const eInvoiceOptions = EINVOICE_OPERATORS.map(operator => ({ label: operator, value: operator }))
  const paymentTermsOptions = PAYMENT_TERMS_OPTIONS.map(option => ({ label: option, value: option }))

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.customers')}
        actions={<Button variant="contained" color="primary" onClick={() => history.push('/asiakkaat/uusi')}>{t('customers.addNewCustomer')}</Button>}
      />
      {isAdmin && <DatatableFilters
        inputs={[
          { field: 'userId', label: t('invoice.billers'), type: 'userId' },
          { field: 'name', label: t('customers.name'), type: 'text' },
          { field: 'totalAddress', label: t('customers.totalAddress'), type: 'text' },
          { field: 'country', label: t('customers.country'), type: 'select', options: countryOptions },
          { field: 'email', label: t('customers.email'), type: 'text' },
          { field: 'phone', label: t('customers.phone'), type: 'text' },
          { field: 'isPrivate', label: t('customers.isPrivate'), type: 'select', options: isPrivateOptions },
          { field: 'businessId', label: t('customers.businessId'), type: 'text' },
          { field: 'contactPerson', label: t('customers.contactPerson'), type: 'text' },
          { field: 'deliveryMethod', label: t('customers.deliveryMethod'), type: 'select', options: deliveryMethodOptions },
          { field: 'reminderHandling', label: t('customers.reminderHandling'), type: 'select', options: reminderHandlingOptions },
          { field: 'language', label: t('customers.language'), type: 'select', options: languageOptions },
          { field: 'eInvoiceOperator', label: t('customers.eInvoiceOperator'), type: 'select', options: eInvoiceOptions },
          { field: 'eInvoiceAddress', label: t('customers.eInvoiceAddress'), type: 'text' },
          { field: 'paymentTerms', label: t('customers.paymentTerms'), type: 'select', options: paymentTermsOptions },
          { field: 'createdAt', label: t('customers.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('customers.updatedAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />}
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'clientsSelectedColumns'}
        sortKey={sortKeyClients}
        setSortKey={setSortKeyClients}
        sortOrder={sortOrderClients}
        setSortOrder={setSortOrderClients}
        isAdmin={isAdmin}
      />
      <Datatable
        data={clients}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyClients}
        setSortKey={setSortKeyClients}
        sortOrder={sortOrderClients}
        setSortOrder={setSortOrderClients}
        isAdmin={isAdmin}
      />
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('customers.confirmDeletionTitle') + (selectedRow ? selectedRow.name : '') + '?', body: t('customers.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </LayoutContainer>
  )
}

export default withSnackbar(Clients)
