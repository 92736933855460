import React from 'react'
import { Link } from "react-router-dom"
import { /*cLog, */camelToSnakeCase } from '../utils'
import { getAnnouncements, getClients, getDiscountCodes, getPromoCodes, getReferences, getSalaries, getTaxcards, getUsers, getUserNotes, getValtticards, getWorklists } from './index'

export const getQuery = async (pagination, setPagination, sortKey, sortOrder, filter, selectedColumns, indexPage, extraParams = null) => {
  let query = '?page=' + (pagination.page - 1)
  query += '&rows=' + pagination.rowsPerPage
  query += '&sort_key=' + sortKey
  query += '&sort_order=' + sortOrder
  if (extraParams) {
    query += extraParams
  }
  Object.keys(filter).forEach(key => {
    if (!['columnsOptions'].includes(key)) {
      if (filter[key].length > 0) {
        if (Array.isArray(filter[key])) {
          query += `&${camelToSnakeCase(key)}=${filter[key].join(',')}`
        } else {
          query += `&${camelToSnakeCase(key)}=${filter[key]}`
        }
      }
      if (typeof filter[key] == 'boolean' && filter[key]) {
        query += `&${camelToSnakeCase(key)}=${filter[key]}`
      }
    }
  })

  let columns = camelToSnakeCase(selectedColumns.toString())
  columns = columns.replace(',actions', '')
  query += '&columns=' + columns

  //cLog('QUERY STRING', query)

  let data = []
  let response = null
  if (indexPage === 'announcements') {
    response = await getAnnouncements(query)
    data = response.announcements
  } else if (indexPage === 'clients') {
    response = await getClients(query)
    data = response.clients
  } else if (indexPage === 'discountCodes') {
    response = await getDiscountCodes(query)
    data = response.discountCodes
  } else if (indexPage === 'promoCodes') {
    response = await getPromoCodes(query)
    data = response.promoCodes
  } else if (indexPage === 'references') {
    response = await getReferences(query)
    data = response.references
  } else if (indexPage === 'salaries') {
    response = await getSalaries(query)
    data = response.salaries
  } else if (indexPage === 'taxcards') {
    response = await getTaxcards(query)
    data = response.taxcards
  } else if (indexPage === 'users') {
    response = await getUsers(query)
    data = response.users
  } else if (indexPage === 'userNotes') {
    response = await getUserNotes(query)
    data = response.userNotes
  } else if (indexPage === 'valtticards') {
    response = await getValtticards(query)
    data = response.valtticards
  } else if (indexPage === 'worklists') {
    response = await getWorklists(query)
    data = response.worklists
  }

  if (response !== null) {
    const { totalRows, totalPages, currentPage } = response
    setPagination({ ...pagination, totalRows, totalPages, currentPage })    
  }
  if (data && data.length > 0 && selectedColumns.includes('userId') && indexPage !== 'clients') {
    data = data.map(row => {
      const userName = `${row.userFirstName || 'N.'} ${row.userLastName || 'N.'}`
      row.userId = <Link to={`/kayttajat/${row.userId}`} target='_blank'>{userName}</Link>

      return row
    })
  }

  //cLog('QUERY DATA', data)

  return data
}
