import React, { useState } from 'react'
import { useStateValue } from "../state"
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { newPaginationStyles } from '../styles'
import { CircularProgress, useMediaQuery } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import DropdownChecbox from './DropdownChecbox'
import { useTranslation } from 'react-i18next'
import { camelToSnakeCase } from '../utils'
import DownloadDialog from './DownloadDialog'

const useStyles = makeStyles((theme) => ({
  paginationRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    fontSize: '16px',
    ...newPaginationStyles,
  },
  pagination: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  }
}))

const DatatablePagination = ({ pagination, setPagination, fetchData, isLoading, selectedColumns, columnsOptions, filter, setFilter, localStorage, sortKey, setSortKey, sortOrder, setSortOrder, isAdmin, allowSelectColumns = true, downloadType }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)

  const sortOrderLabel = {
    'DESC': t('invoice.desc'),
    'ASC': t('invoice.asc'),
  }

  const handleChange = (event, value) => {
    setPagination({ ...pagination, page: value })
    fetchData()
  }

  let rowsPerPageArray = [10, 20, 50, 75, 100]
  if (isAdmin) {
    rowsPerPageArray.push(200, 300, 400, 500, 1000)
  }

  const from = pagination.currentPage * pagination.rowsPerPage
  const to = pagination.currentPage === pagination.totalPages - 1
    ? from + pagination.totalRows % pagination.rowsPerPage
    : from + pagination.rowsPerPage
  let fromTo = ''
  if (pagination.totalRows) {
    fromTo = `${from + 1} - ${to} / ${pagination.totalRows}`
  } else {
    fromTo = `0 - 0 / 0`
  }

  const mapSortValue = (sortKey) => {
    if (sortKey === 'worker') {
      return 'user_id'
    }

    return sortKey
  }

  return (
    <div className={classes.paginationRow}>
      <div className={classes.pagination}> 
        <Pagination count={pagination.totalPages} page={pagination.page} onChange={handleChange} variant="outlined" shape="rounded"/>
      </div>
      <span>{fromTo}</span>
      {!isMobile &&
        <span>
          <span>{t('invoice.rowsInPage')}</span>
          <select
            value={pagination.rowsPerPage}
            onChange={(e) => {
            setPagination({ ...pagination, page: 1, rowsPerPage: parseInt(e.target.value) })
              fetchData()
            }}
            disabled={isLoading}
          >
            {rowsPerPageArray.map((row, i) => <option key={i}>{row}</option>)}
          </select>
        </span>}
      <span>
        {!isMobile && <span>{t('invoice.sort')}</span>}
        <select
          value={mapSortValue(sortKey)}
          onChange={(e) => {
            dispatch(setSortKey(e.target.value))
            fetchData()
          }}
          disabled={isLoading}
        >
          {columnsOptions.filter(row => selectedColumns.includes(row.value) && row.value !== 'actions').map((row, i) => <option value={camelToSnakeCase(row.value)} key={i}>{row.label}</option>)}
        </select>
        <select
          value={sortOrder}
          onChange={(e) => {
            dispatch(setSortOrder(e.target.value))
            fetchData()
          }}
          disabled={isLoading}
        >
          {['DESC', 'ASC'].map((row, i) => <option value={row} key={i}>{sortOrderLabel[row]}</option>)}
        </select>
      </span>
      {isAdmin && allowSelectColumns && 
        <span>
          <span>{t('taxcard.columns')}</span>
          <DropdownChecbox
            placeholder={t('invoice.pickOrType')}
            options={columnsOptions}
            onChange={(selectedOptions) => {
              setFilter({ ...filter, columnsOptions: selectedOptions ? selectedOptions.map(option => option.value) : [] })
              selectedColumns = []
              if (selectedOptions != null) {
                selectedOptions.forEach((o) => {
                  selectedColumns.push(o.value)
                })
              }
              window.localStorage.setItem(localStorage, JSON.stringify(selectedColumns))
            }}
            value={columnsOptions.filter(row => selectedColumns.includes(row.value))}
          />
        </span>}
      {isLoading
        ? <CircularProgress size={25} />
        : <button type='button' onClick={() => fetchData()} disabled={isLoading}>{t('taxcard.fetch')}</button>}
      {downloadType && !isLoading && pagination.totalRows > 0 &&
        <>
          <button type='button' onClick={() => setOpenDownloadDialog(true)} disabled={isLoading}>{t('taxcard.download')}</button>
          <DownloadDialog open={openDownloadDialog} setOpenDownloadDialog={setOpenDownloadDialog} type={downloadType} filter={filter} sortKey={sortKey} sortOrder={sortOrder} />
        </>
      }
    </div>
  )
}

export default DatatablePagination
