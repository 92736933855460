import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { reducer, StateProvider } from "./state";
import { SnackbarProvider } from 'notistack';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { fiFI } from '@material-ui/core/locale';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import initWorkboxRefresh from '@loopmode/cra-workbox-refresh';
//import './i18n';
//import i18next from './i18n'
import { muiTheme } from "./styles";

const theme = responsiveFontSizes(createTheme(muiTheme, fiFI));

ReactDOM.render(
  <StateProvider reducer={reducer}>

    <SnackbarProvider
      autoHideDuration={6000}
      maxSnack={8}
    >
      <ThemeProvider theme={theme}>
        <Suspense fallback="">
          <App />
        </Suspense>
      </ThemeProvider>
    </SnackbarProvider>

  </StateProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
/*serviceWorkerRegistration.register({
  onUpdate: registration => {
    initWorkboxRefresh(registration, {
      textContent: i18next.t('frontPage.updatesAvailable')
    })
  }
});*/
// packaje.json
//"build": "react-scripts build && inject-workbox-refresh",
//"build:staging": "env-cmd -f .env.staging react-scripts build && inject-workbox-refresh",
serviceWorkerRegistration.register()
