import React from 'react'
import { Field, FieldArray } from 'formik'
import { cleanFloat, parseVat } from '../../utils/invoiceUtils'
import { roundNumber, UNITS } from '../../utils/index'
import { checkInvoiceRowErrors, validateTextArea, validateFloat, validateFloatGreaterThan } from './validationSchemas'
import Input from '../../components/Input'
import DropDown from '../../components/DropDown'
import { Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
//import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'

const InvoiceRow = ({
  invoiceRows,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow,
  values,
  isAdmin,
  checkMaterialRowsVats,
  vat24
}) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = React.useState({})
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let allVats = ['25,5', '24', '14', '10', '0']
  if (vat24) {
    allVats = ['24', '14', '10', '0']
  } else {
    allVats = ['25,5', '14', '10', '0']
  }
  const vatOptions = allVats.map(vat => {
      return ({ label: vat + ' %', value: vat })
    })

  const unitOptions = Object.keys(UNITS).map(unit => ({ label: t('invoice.'+unit), value: unit }))

  const reactToQuantity = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const priceTotal = roundNumber(cleanFloat(changedValue) * cleanFloat(row.price), 2)
      const total = roundNumber(priceTotal * (1 + parseVat(row.vat) / 100), 2)
      setFieldValue(`invoiceRows.${i}.tax`, roundNumber(total - priceTotal, 2))
      setFieldValue(`invoiceRows.${i}.total`, total)
    } else {
      setFieldValue(`invoiceRows.${i}.total`, '0.00')
      setFieldValue(`invoiceRows.${i}.tax`, '0.00')
    }
  }
  const reactToPrice = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const priceTotal = roundNumber(cleanFloat(row.quantity) * cleanFloat(changedValue), 2)
      const total = roundNumber(priceTotal * (1 + parseVat(row.vat) / 100), 2)
      setFieldValue(`invoiceRows.${i}.priceWithTax`, roundNumber(cleanFloat(changedValue) * (1 + parseVat(row.vat) / 100), 2))
      setFieldValue(`invoiceRows.${i}.tax`, roundNumber(total - priceTotal, 2))
      setFieldValue(`invoiceRows.${i}.total`, total)
    } else {
      setFieldValue(`invoiceRows.${i}.priceWithTax`, '0.00')
      setFieldValue(`invoiceRows.${i}.tax`, '0.00')
      setFieldValue(`invoiceRows.${i}.total`, '0.00')
    }
  }
  const reactToAlv = (row, i, changedValue) => {
    const priceTotal = roundNumber(cleanFloat(row.quantity) * cleanFloat(row.price), 2)
    const total = roundNumber(priceTotal * (1 + parseVat(changedValue) / 100), 2)
    setFieldValue(`invoiceRows.${i}.priceWithTax`, roundNumber(cleanFloat(row.price) * (1 + parseVat(changedValue) / 100), 2))
    setFieldValue(`invoiceRows.${i}.tax`, roundNumber(total - priceTotal, 2))
    setFieldValue(`invoiceRows.${i}.total`, total)
  }
  const reactToPriceWithALV = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const price = roundNumber(cleanFloat(changedValue) / (1 + parseVat(row.vat) / 100), 2)
      const priceTotal = roundNumber(cleanFloat(row.quantity) * price, 2)
      const total = roundNumber(cleanFloat(row.quantity) * cleanFloat(changedValue), 2)
      setFieldValue(`invoiceRows.${i}.price`, price)
      setFieldValue(`invoiceRows.${i}.tax`, roundNumber(total - priceTotal, 2))
      setFieldValue(`invoiceRows.${i}.total`, total)
    } else {
      setFieldValue(`invoiceRows.${i}.price`, '0.00')
      setFieldValue(`invoiceRows.${i}.total`, '0.00')
      setFieldValue(`invoiceRows.${i}.tax`, '0.00')
    }
  }

  return (
    <FieldArray
      name="invoiceRows"
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        return (
          <>
            {invoiceRows.map((row, i) => {
              const errors = arrayHelpers.form.errors.invoiceRows ? arrayHelpers.form.errors.invoiceRows[i] : {}
              let invoiceRowHasErrors = false
              if (errors) {
                invoiceRowHasErrors = Object.keys(errors).length > 0
              }

              return (
                <div id={`iRow${i}`} key={i}>
                  <Hanuri>
                    <HanuriTitle
                      expanded={expanded === `invoiceRows.${i}`}
                      id={`invoiceRows.${i}`}
                      open={handleChangeExpansion(`invoiceRows.${i}`)}
                      remove={() => {
                        removeRow('invoiceRows', i)
                        arrayHelpers.remove(i)
                      }}
                      label={t('invoice.invoiceRow') + ' ' + (i+1)}
                      done={checkInvoiceRowErrors(row, isAdmin)}
                      error={invoiceRowHasErrors && !checkInvoiceRowErrors(row, isAdmin)}
                    />
                    <HanuriBody expanded={expanded === `invoiceRows.${i}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                          <Field
                            name={`invoiceRows.${i}.content`}
                            id={`invoiceRows.${i}.content`}
                            label={t('invoice.content') + ' *'}
                            info={t('invoice.contentInfo')}
                            type="text"
                            component={Input}
                            value={invoiceRows[i].content}
                            onChange={(e) => {
                              setFieldValue(`invoiceRows.${i}.content`, e.target.value)
                            }}
                            validate={() => validateTextArea(row.content, t('invoice.content'), 460, true)}
                            error={errors && errors.content && validateTextArea(row.content, t('invoice.content'), 460, true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`invoiceRows.${i}.quantity`}
                            id={`invoiceRows.${i}.quantity`}
                            label={t('invoice.quantity') + ' *'}
                            type='text'
                            component={Input}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], quantity: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            value={invoiceRows[i].quantity}
                            onChange={(e) => {
                              setFieldValue(`invoiceRows.${i}.quantity`, e.target.value)
                              if (isFocused[i].quantity) {
                                reactToQuantity(row, i, e.target.value)
                              }
                            }}
                            validate={() => (!isAdmin ? validateFloatGreaterThan(row.quantity, 0, 9999999.99, t('invoice.quantity'), 2) : validateFloat(row.quantity, t('invoice.quantity'), false))}
                            error={errors && errors.quantity && (!isAdmin ? validateFloatGreaterThan(row.quantity, 0, 9999999.99, t('invoice.quantity'), 2) : validateFloat(row.quantity, t('invoice.quantity'), false))}
                         />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`invoiceRows.${i}.unit`}
                            id={`invoiceRows.${i}.unit`}
                            label={t('invoice.unit')}
                            options={unitOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            onChange={(e) => setFieldValue(`invoiceRows.${i}.unit`, e.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`invoiceRows.${i}.vat`}
                            id={`invoiceRows.${i}.vat`}
                            label={t('invoice.vat')}
                            options={vatOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            defaultValue={vatOptions[0]}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], vat: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`invoiceRows.${i}.vat`, e.value)
                              reactToAlv(row, i, e.value)
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`invoiceRows.${i}.price`}
                            id={`invoiceRows.${i}.price`}
                            label={t('invoice.price') + ' *'}
                            info={t('invoice.priceNoVatInfo')}
                            type='text'
                            component={Input}
                            value={row.price}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], price: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`invoiceRows.${i}.price`, e.target.value)
                              if (isFocused[i].price) {
                                reactToPrice(row, i, e.target.value)
                              }
                            }}
                            validate={() => (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false))}
                            error={errors && errors.price && (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false))}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`invoiceRows.${i}.priceWithTax`}
                            id={`invoiceRows.${i}.priceWithTax`}
                            label={t('invoice.priceWithTax')}
                            info={t('invoice.priceWithTaxInfo')}
                            type='text'
                            component={Input}
                            value={row.priceWithTax}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], priceWithTax: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`invoiceRows.${i}.priceWithTax`, e.target.value)
                              if (isFocused[i].priceWithTax) {
                                reactToPriceWithALV(row, i, e.target.value)
                              }
                            }}
                            validate={() => (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false))}
                            error={errors && errors.priceWithTax && (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false))}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`invoiceRows.${i}.tax`}
                            id={`invoiceRows.${i}.tax`}
                            label={t('invoice.taxInput')}
                            type='text'
                            component={Input}
                            value={row.tax}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`invoiceRows.${i}.total`}
                            id={`invoiceRows.${i}.total`}
                            label={t('invoice.total')}
                            type='text'
                            component={Input}
                            value={row.total}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </HanuriBody>
                  </Hanuri>
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}

export default InvoiceRow
