import React, { useState } from 'react'
import { Box, Grid, Accordion, AccordionDetails, AccordionSummary, FormControlLabel } from '@material-ui/core'
import Button from '../components/Button'
import { useTranslation } from 'react-i18next'
import DPicker from './DPicker'
import DropDown from './DropDown'
import Input from './Input'
import SelectAsync from './SelectAsync'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import '../styles/styles.css'
import { formatDate } from '../utils'
import { OdealCheckbox as MaterialUiCheckBox } from './OdealCheckbox'

const DatatableFilters = ({ inputs, filter, setFilter, pagination, setPagination, fetchData, initFilter, isLoading }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState('filter')

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion id='filter' expanded={expanded === 'filter'} onChange={handleChangeExpansion('filter')} style={{ borderRadius: '5px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filterbh-content"
        id="filter-header"
      >
        {t('invoice.filterTitle')}
      </AccordionSummary>
      <AccordionDetails style={{display: "block"}}>
        <Grid container spacing={4} className="gridContainer">
          {inputs.map((input, i) => {
            if (input.type === 'date') {
              return (
                <Grid item xs={12} md={2} key={`input${i}`}>
                  <DPicker
                    name={input.field}
                    id={input.field}
                    label={input.label}
                    selected={filter[input.field] ? new Date(filter[input.field]) : ''}
                    onChange={(date) => {
                      filter[input.field] = date ? formatDate(date) : ''
                      setFilter({ ...filter })
                    }}
                    isClearable
                  />
                </Grid>
              )
            } else if (input.type === 'includeDestroyed') {
              return (
                <Grid item xs={12} md={2} key={`input${i}`} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "flex-end" }}>
                  <FormControlLabel
                    control={<MaterialUiCheckBox
                      checked={filter.includeDestroyed}
                      onChange={() => {
                        setFilter({ ...filter, includeDestroyed: !filter.includeDestroyed })
                        fetchData()
                      }}
                      name="includeDestroyed"
                    />}
                    label={t('invoice.includeDestroyed')}
                    disabled={isLoading}
                  />
                </Grid>
              )
            } else if (input.type === 'select') {
              return (
                <Grid item xs={12} md={2} key={`input${i}`}>
                  <DropDown
                    label={input.label}
                    placeholder={t('invoice.pickOrType')}
                    isClearable={true}
                    options={input['options']}
                    value={input['options'].filter(option => filter[input.field].includes(option.value))}
                    onChange={(e) => {
                      filter[input.field] = e ? e.value : ''
                      setFilter({ ...filter })
                    }}
                  />
                </Grid>
              )
            } else if (input.type === 'text') {
              return (
                <Grid item xs={12} md={2} key={`input${i}`}>
                  <Input
                    type='text'
                    label={input.label}
                    value={filter[input.field]}
                    onChange={(e) => {
                      filter[input.field] = e.target.value
                      setFilter({ ...filter })
                    }}
                  />
                </Grid>
              )
            } else if (input.type === 'userId') {
              return (
                <Grid item xs={12} md={2} key={`input${i}`}>
                  <SelectAsync
                    name='userId'
                    label={t('invoice.billers')}
                    placeholder={t('invoice.typeToSearch')}
                    isMulti
                    onChange={(selectedOptions) => {
                      setFilter({ ...filter, userId: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                    }}
                  />
                </Grid>
              )
            } else {
              return (
                <>
                </>
              )
            }
          })}
        </Grid>
        <Box mt={4}>
          <Button
            variant="contained"
            color="primary"
            style={{marginRight: '1rem'}}
            disabled={isLoading}
            onClick={() => {
              setPagination({ ...pagination, page: 1 })
              fetchData()
            }}
          >
            {t('taxcard.fetch')}
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              setPagination({ ...pagination, page: 1 })
              setFilter({ ...initFilter })
              fetchData()
            }}
          >
            {t('invoice.emptyEverything')}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatatableFilters
