import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import Button from '../components/Button'
import Tip from './Tip'
import AddIcon from '@material-ui/icons/Add'
import { cLog } from '../utils'
import { withSnackbar } from 'notistack'
import { acceptedFileTypes } from '../utils/acceptedFileTypes'
import { useTranslation } from 'react-i18next'

const InvoiceDropzone = ({ values, setFieldValue, variant, color, style, iconStyles, exp, field, enqueueSnackbar }) => {
  const { t } = useTranslation()

  const handleUploadFile = (files) => {
    let attachments = values.attachments
    files.forEach(file => {
      if (file && !acceptedFileTypes.includes(file.type)) {
        enqueueSnackbar(
          t('invoice.invalidFileError'),
          { variant: 'error' }
        )
        return
      }
      if (file && file.size > 10000000) {
        enqueueSnackbar(
          t('invoice.fileTooLargeError'),
          { variant: 'error' }
        )
        return
      }

      const { name, type } = file
      const newFile = {
        file,
        filename: name,
        url: URL.createObjectURL(file),
        contentType: type
      }
      if (exp) {
        newFile.sendToClient = true
      }
      cLog('UPLOADING NEW FILE', newFile)
      attachments = attachments.concat(newFile)
    })

    if (exp) {
      setFieldValue(field, attachments)
    } else {
      setFieldValue(`attachments`, attachments)
    }
  }

  /*const onDrop = React.useCallback(acceptedFiles => {
    handleUploadFile(acceptedFiles)
  }, [values.attachments, handleUploadFile])*/
  const onDrop = (acceptedFiles => handleUploadFile(acceptedFiles))

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Tip title={exp ? t('invoice.expenseFileTip') : t('invoice.invoiceFileTip')} placement="bottom">
      <Button variant={variant} color={color} style={{ ...style, backgroundColor: isDragActive && 'hotpink' }} id='uploadedFile' {...getRootProps()}>
        <input {...getInputProps()} />
        <AddIcon style={iconStyles} />{t('invoice.addAttachment')}
      </Button>
    </Tip>
  )
}

export default withSnackbar(InvoiceDropzone)
