import React from 'react'
import { Label } from './Label'
import { MaterialUiCheckBox } from './MaterialUiCheckBox'

const CheckBox = (props) => {
    if (props.compact) {
        return ( <div style={{ minWidth: '40px' }}>
            <MaterialUiCheckBox {...props} name={props.id} ref={props.innerRef} />
            {props.label}
        </div>
        )
    } else {
        return ( <div style={{ minWidth: '40px' }}>
        <Label>
            <MaterialUiCheckBox {...props} name={props.id} ref={props.innerRef} />
            {props.label}
        </Label>
        </div>
        )
    }
}

export default CheckBox
