import React from 'react';
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import { REACT_SELECT_STYLES_OPTION, REACT_SELECT_STYLES_CONTROL } from '../styles'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoPop from './InfoPop'

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    width: '100%',
    textAlign: 'left',
  },
  customInputLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(1),
  },
}))

const BusinessSelect = ({
  setFieldValue,
  values,
  clientNameOption,
  setClientNameOption,
  setAddressOptions,
  setFullAddresses,
  ...props
}) => {
  const [foundBiz, setFoundBiz] = React.useState([])
  const classes = useStyles();
  const theme = useTheme();

  //cLog('BUSINESS SELECT PROPS', props)

  const getAddress = async bizInfo => {
    if (!bizInfo.detailsUri && !bizInfo.addresses) {
      //cLog('NO DETAILS AVAILABLE')
      setAddressOptions([{ value: '', label: '' }])
      setFieldValue('address1', '')
      setFieldValue('address2', '')
      setFieldValue('postalCode', '')
      setFieldValue('city', '')
      setFullAddresses([])
    } else {
      let bizAddresses
      if (bizInfo.detailsUri) {
        const uriEnding = bizInfo.detailsUri.split('//')[1]
        //cLog('HAETAAN OSOITTEITA', bizInfo.detailsUri)
        const response = await axios.get('https://' + uriEnding)
        //cLog('DETAILS URI DETAILS', response.data.results[0])
        bizAddresses = response.data.results[0].addresses
      } else {
        //cLog('BIZ INFO ADDRESSES', bizInfo.addresses)
        bizAddresses = bizInfo.addresses
      }
      bizAddresses = bizAddresses
        .filter(address => address.type === 2)
        .sort((a, b) => new Date(b.registrationDate) - new Date(a.registrationDate))
      //cLog('BIZ ADDRESSES', bizAddresses)
      const streetAddresses = Array.from(new Set(bizAddresses.map(address => address.street)))
      //cLog('STREET ADDRESSES', streetAddresses)
      const wholeAddresses = streetAddresses.map(street => {
        const { postCode, city } = bizAddresses.find(address => address.street === street)
        return { street, postCode, city, name: bizInfo.name }
      })
      //cLog('OSOITTEET', wholeAddresses)
      //const optionalAddresses = wholeAddresses.map(address => ({ value: address.street, label: address.street })) ///// EI OLE KAIKILLA

      let optionalAddresses = wholeAddresses.reduce((optionalAddresses, address) => {
        if (address.street !== '') {
          optionalAddresses.push({ value: address.street, label: address.street })
        }
        return optionalAddresses;
      }, []);
      setAddressOptions(optionalAddresses)
      setFullAddresses(wholeAddresses)
      //cLog('OPTIONA NOLLA', optionalAddresses[0])
      setFieldValue('address1', optionalAddresses[0] ? optionalAddresses[0].value : '')
    }
  }

  const getOptions = async inputValue => {
    if (inputValue) {
      try {
        const url = "https://avoindata.prh.fi/bis/v1?totalResults=false&maxResults=10&resultsFrom=0&name=" + inputValue
        //cLog('YTJ URL', url)
        const idSearch = await axios(url)
        //cLog('YTJ IDSEARCH', idSearch)
        let options
        if (idSearch) {
          options = idSearch.data.results.map(result => ({
            value: result.name,
            label: result.name
        }))
          setFoundBiz(idSearch.data.results)
        }
        //cLog('OPTIOITA', options)
        return options
      } catch (e) {
        //cLog(e)
        //return inputValue ? [{ value: inputValue, label: inputValue }] : []
      }
    }
  }

  return (
    <div className={classes.customInputContainer} style={props.style}>
      {props.label &&
        <label htmlFor={props.id} className={classes.customInputLabel}>
        <span>{props.label} {props.required && "*"}</span>
          {props.info && <InfoPop info={props.info}/>}
        </label>
      }
      <AsyncSelect
        {...props}
        defaultOptions
        loadOptions={getOptions}
        value={clientNameOption}
        onChange={chosenOption => {
          const bizInfo = foundBiz.find(biz => biz.name === chosenOption.value)
          if (bizInfo) {
            //cLog('CLIENT NAME SELECT ON CHANGE', chosenOption, bizInfo)
            setFieldValue('name', bizInfo.name)
            setClientNameOption({ value: bizInfo.name, label: bizInfo.name })
            setFieldValue('businessId', bizInfo.businessId)
            getAddress(bizInfo)
          }
        }}
        styles={{
          option: (provided, state) => REACT_SELECT_STYLES_OPTION(provided, state, theme, props),
          control: (provided, state) => REACT_SELECT_STYLES_CONTROL(provided, state, theme, props),
        }}
      />
    </div>
  )
}

export default BusinessSelect
