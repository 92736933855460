import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, CssBaseline, Typography, Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    textAlign: 'center',
    [theme.breakpoints.down("xs")]: {
      background: theme.palette.white.main,
      padding: theme.spacing(8, 4),
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(8, 4),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
    background: theme.palette.white.main,
    borderRadius: theme.borders.radius.rounded,
    maxWidth: "600px",
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
  },
  avatar: {
    fontSize: "24px",
    margin: theme.spacing(4, 0, 6, 0),
    backgroundColor: theme.palette.primary.main,
    color: COLORS.tertiary,

    '& > *': {
      fontSize: "inherit",
    }
  },
}))

const AuthLayoutContainer = ({children}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Typography component="h1" variant="h4">
        {t('auth.titleStart')}
      </Typography>
      <Typography component="h2" variant="h5">
        {t('auth.titleFinish')}
      </Typography>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon color='inherit' />
      </Avatar>
      <div className={classes.paper}>
        {children}
      </div>
    </Container>
  )
}

export default AuthLayoutContainer
