import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery, getDiscountCode, deleteDiscountCode } from '../../service'
import Button from '../../components/Button'
import { useStateValue, setDiscountCodes, setSortKeyDiscountCodes, setSortOrderDiscountCodes } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import DiscountCodeDialog from './DiscountCodeDialog'
import '../../styles/styles.css'

let selectedColumns  = []

const INIT_FILTER = {
  inviterType: '',
  code: '',
  startDate: '',
  endDate: '',
  businessId: '',
  companyName: '',
  companyNotes: '',
  commission: '',
  insurance: '',
  signUpBonus: '',
  discountsUsed: '',
  activated: '',
  createdAt: '',
  updatedAt: '',
  includeDestroyed: false,
  columnsOptions: []
}

const INIT_FORM = {
  id: '',
  inviterType: 'YRITYS',
  code: '',
  startDate: '',
  endDate: '',
  businessId: '',
  companyName: '',
  companyNotes: '',
  commission: '',
  insurance: '',
  signUpBonus: '',
  activated: true,
  updateHistory: []
}

const DiscountCodes = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ discountCodes, sortKeyDiscountCodes, sortOrderDiscountCodes }, dispatch] = useStateValue()
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })
  const allColumns = ['inviterType', 'code', 'startDate', 'endDate', 'businessId', 'companyName', 'companyNotes', 'commission', 'insurance', 'signUpBonus', 'discountsUsed', 'activated', 'createdAt', 'updatedAt', 'actions']
  selectedColumns = JSON.parse(window.localStorage.getItem('discountCodesSelectedColumns')) || allColumns
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('discountCode.'+option),
      value: option
    }
    if (['commission', 'insurance', 'signUpBonus'].includes(option)) {
      obj.numeric = true
      obj.decimals = 2
    }
    if (['discountsUsed'].includes(option)) {
      obj.numeric = true
      obj.decimals = 0
    }
    if (['businessId'].includes(option)) {
      obj.style = { whiteSpace: 'nowrap' }
    }
    if (['startDate', 'endDate'].includes(option)) {
      obj.date = true
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({ ...INIT_FILTER, columnsOptions: selectedColumns })

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getDiscountCode(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getDiscountCode(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenConfirmDeleteModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyDiscountCodes, sortOrderDiscountCodes, filter, selectedColumns, 'discountCodes')
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          return row
        })
        dispatch(setDiscountCodes(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setDiscountCodes([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyDiscountCodes, sortOrderDiscountCodes, filter, pagination, t, enqueueSnackbar])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.discountCodes') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteDiscountCode(selectedRow.id)
      cLog(`DISCOUNT CODE ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('discountCode.discountCode') + ' ' + JSON.stringify(`${selectedRow.code || (selectedRow.businessId + ' ' + selectedRow.companyName)}`) + t('customers.deleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE DISCOUNT CODE ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('discountCode.problemsDeletingDiscountCode'),
        { variant: 'error' }
      )
    }
  }

  const inviterTypeOptions = ['YRITYS', 'KAMPANJA'].map(option => ({ label: t('discountCode.'+option.replace(/_/g, ' ')), value: option }))
  const activatedOptions = ['true', 'false'].map(option => ({ label: t('taxcard.'+option), value: option }))

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.discountCodes')}
        actions={
          <Button
            variant="contained"
            color="primary"
            onClick={() => { setOpenModal(true) }}
          >
            {t('discountCode.addNewdiscountCode')}
          </Button>
        }
      />
      <DatatableFilters
        inputs={[
          { field: 'inviterType', label: t('discountCode.inviterType'), type: 'select', options: inviterTypeOptions },
          { field: 'code', label: t('discountCode.code'), type: 'text' },
          { field: 'startDate', label: t('discountCode.startDate'), type: 'date' },
          { field: 'endDate', label: t('discountCode.endDate'), type: 'date' },
          { field: 'businessId', label: t('discountCode.businessId'), type: 'text' },
          { field: 'companyName', label: t('discountCode.companyName'), type: 'text' },
          { field: 'companyNotes', label: t('discountCode.companyNotes'), type: 'text' },
          { field: 'commission', label: t('discountCode.commission'), type: 'text' },
          { field: 'insurance', label: t('discountCode.insurance'), type: 'text' },
          { field: 'signUpBonus', label: t('discountCode.signUpBonus'), type: 'text' },
          { field: 'discountsUsed', label: t('discountCode.discountsUsed'), type: 'text' },
          { field: 'activated', label: t('discountCode.activated'), type: 'select', options: activatedOptions },
          { field: 'createdAt', label: t('userNotes.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('userNotes.updatedAt'), type: 'date' },
          { type: 'includeDestroyed' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'discountCodesSelectedColumns'}
        sortKey={sortKeyDiscountCodes}
        setSortKey={setSortKeyDiscountCodes}
        sortOrder={sortOrderDiscountCodes}
        setSortOrder={setSortOrderDiscountCodes}
        isAdmin={true}
      />
      <Datatable
        data={discountCodes}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyDiscountCodes}
        setSortKey={setSortKeyDiscountCodes}
        sortOrder={sortOrderDiscountCodes}
        setSortOrder={setSortOrderDiscountCodes}
        isAdmin={true}
      />
      <Formik
        enableReinitialize
        initialValues={INIT_FORM}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <DiscountCodeDialog open={openModal} setOpen={setOpenModal} edit={selectedRow} setEdit={setSelectedRow} fetchData={() => setForceFetchData(true)} {...formikProps} />}
      </Formik>
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('discountCode.confirmDeletionTitle') + JSON.stringify(`${selectedRow ? (selectedRow.code || (selectedRow.businessId + ' ' + selectedRow.companyName)) : ''}`) + '?', body: t('customers.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </LayoutContainer>
  )
}

export default withSnackbar(DiscountCodes)
