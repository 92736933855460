import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  invoiceKeyValuePairContainer: {
    display: "block",
    padding: 0,
    margin: 0,

    "& > li": {
      display: "flex",
      flexWrap: "wrap",
      margin: theme.spacing(1, 0),

      "& > *:nth-child(odd)": {
        width: "50%",
        textAlign: "left",
      },
      "& > *:nth-child(even)": {
        width: "50%",
        textAlign: "right",
      },
    },

    "&.dense": {
      "& > li": {
        margin: 0,
      },
    },

    "&.alignLeft": {
      "& > li": {
        "& > *:nth-child(even)": {
          textAlign: "left",
        },
      },
    }
  },
}));

const InvoiceKeyValuePairs = (props) => {
  const classes = useStyles();
  const id = props.id || "invoice-keyvaluepairs";
  //cLog({alignLeft: props.alignLeft});

  return (
    <ul className={`${classes.invoiceKeyValuePairContainer}${props.dense ? " dense" : ""}${props.alignLeft ? " alignLeft" : ""}`}>
    {
      props.items.map((it, idx) => {
        if (!it) return null;
        const itemId = `${id}-item-${idx}`;

        return (
        <li id={itemId} key={itemId}>
          <span id={`${itemId}-key`}>
            {it[0]}
          </span>
          {it[1] != null &&
            <span id={`${itemId}-value`}>
              {it[1]}
            </span>
          }
        </li>
      )})
    }
    </ul>
  );
};

export default InvoiceKeyValuePairs;
