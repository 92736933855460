import React from 'react'
import { Field, FieldArray } from 'formik'
import { checkAccountRowErrors, validateDate, validateTextArea, validateFloatGreaterThan } from './validationSchemas'
import Input from '../../components/Input'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
import { useTheme } from '@material-ui/core/styles'
//import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import TextArea from '../../components/TextArea'
import DropDown from '../../components/DropDown'

const AccountRow = ({
  accounts,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow,
  values,
  exp
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const accountTypeOptions =  ['KULU', 'MATKA'].map(option => ({ label: t('invoice.'+option), value: option }))

  return (
    <FieldArray
      name="accounts"
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        return (
          <>
            {accounts.map((row, i) => {
              const errors = arrayHelpers.form.errors.accounts ? arrayHelpers.form.errors.accounts[i] : {}
              const accountRowHasErrors = errors ? Object.keys(errors).length > 0 : false

              //cLog('ACCOUNT ROW ERRORS', errors)

              return (
                <div id={`aRows${i}`} key={i}>
                  <Hanuri>
                    <HanuriTitle
                      expanded={expanded === `accounts.${i}`}
                      id={`accounts.${i}`}
                      open={handleChangeExpansion(`accounts.${i}`)}
                      remove={() => {
                        removeRow('accounts', i)
                        arrayHelpers.remove(i)
                      }}
                      label={t('invoice.accountRow')+' '+(i+1)}
                      done={checkAccountRowErrors(row, exp)}
                      error={accountRowHasErrors && !checkAccountRowErrors(row, exp)}
                    />
                    <HanuriBody expanded={expanded === `accounts.${i}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={exp ? 4 : 6}>
                          <Field
                            name={`accounts.${i}.date`}
                            id={`accounts.${i}.date`}
                            label={t('invoice.date')+' *'}
                            component={DPicker}
                            selected={row.date ? new Date(row.date) : ''}
                            onChange={(date) => setFieldValue(`accounts.${i}.date`, date ? formatDate(date) : '')}
                            validate={() => validateDate(row.date, t('invoice.date'), true)}
                            error={errors && errors.date && validateDate(row.date, t('invoice.date'), true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={exp ? 4 : 6}>
                          <Field
                            name={`accounts.${i}.amount`}
                            id={`accounts.${i}.amount`}
                            label={t('invoice.sum')+' *'}
                            type="text"
                            component={Input}
                            value={row.amount}
                            onChange={(e) => { setFieldValue(`accounts.${i}.amount`, e.target.value) }}
                            validate={() => validateFloatGreaterThan(row.amount, 0, 9999999.99, t('invoice.sum'), 2)}
                            error={errors && errors.amount && validateFloatGreaterThan(row.amount, 0, 9999999.99, t('invoice.sum'), 2)}
                          />
                        </Grid>
                        {exp && <Grid item xs={12} sm={4}>
                          <Field
                            name={`accounts.${i}.accountType`}
                            id={`accounts.${i}.accountType`}
                            label={t('invoice.accountType')+' *'}
                            options={accountTypeOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            placeholder={t('invoice.pick')}
                            onChange={(e) => setFieldValue(`accounts.${i}.accountType`, e.value) }
                            validate={() => (!row.accountType && { key: t('invoice.accountTypeMissing') })}
                            error={errors && errors.accountType && !row.accountType}
                          />
                        </Grid>}
                        <Grid item xs={12} sm={12}>
                          <Field
                            name={`accounts.${i}.notes`}
                            id={`accounts.${i}.notes`}
                            label={t('invoice.notes')}
                            type="text"
                            component={TextArea}
                            value={row.notes || ''}
                            onChange={(e) => {
                              setFieldValue(`accounts.${i}.notes`, e.target.value)
                            }}
                            validate={() => validateTextArea(row.notes, t('invoice.notes'), 10000, false)}
                            error={errors && row.notes && errors.notes && validateTextArea(row.notes, t('invoice.notes'), 10000, false)}
                          />
                        </Grid>
                      </Grid>
                    </HanuriBody>
                  </Hanuri>
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}

export default AccountRow
