import React, { useState, useEffect } from 'react'
import { useStateValue } from "../../state"
import { Accordion, AccordionSummary, AccordionDetails, useMediaQuery } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import pdf from './Elo_mikä_ihmeen_yel_141222.pdf'
import pdf_en from './Elo_mikä_ihmeen_yel_151222_englanti.pdf'
//import { cLog } from '../../utils'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"
import Footer from '../../screens/Footer'

const useStyles = makeStyles((theme) => ({
    panelContainer: {
        flexDirection: 'column'
    },
    expansionChild: {
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5em'
        },
    }
}))

const Info = () => {
    let panel = new URLSearchParams(window.location.search).get('panel');
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    //cLog('INFO IS DESKTOP', isDesktop)
    const [expandedRoot, setExpandedRoot] = useState(!isDesktop ? false : 'panel1');
    const [expandedPanel1, setExpandedPanel1] = useState(false);
    const [expandedPanel2, setExpandedPanel2] = useState(false);
    const [expandedPanel3, setExpandedPanel3] = useState(false);
    const [expandedPanel4, setExpandedPanel4] = useState(false);
    const classes = useStyles();
    const [{ currentUser }] = useStateValue()
    const isAdmin = currentUser && currentUser.role === 'ADMIN'

    useEffect(() => {
        if (panel === '4a') {
            setExpandedRoot('panel4')
            setExpandedPanel4('panel4a')
            window.scroll({
                top: 800,
                behavior: 'smooth'
            })
        } else if (panel === '4b') {
            setExpandedRoot('panel4')
            setExpandedPanel4('panel4b')
            window.scroll({
                top: 900,
                behavior: 'smooth'
            });
        } else if (isDesktop) {
            setExpandedRoot('panel1')
            setExpandedPanel1('panel1a')
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }, [panel, isDesktop])

    const handleChangeRoot = (panel) => (event, isExpanded) => {
        //cLog('CHANGE ROOT', isExpanded, panel)
        if (!isExpanded) {
            if (panel === 'panel1') {
                setExpandedPanel1(false)
            } else if (panel === 'panel2') {
                setExpandedPanel2(false)
            } else if (panel === 'panel3') {
                setExpandedPanel3(false)
            } else if (panel === 'panel4') {
                setExpandedPanel4(false)
            }
        } else {
            if (panel === 'panel1') {
                setExpandedPanel1('panel1a')
                setExpandedPanel2(false)
                setExpandedPanel3(false)
                setExpandedPanel4(false)
            } else if (panel === 'panel2') {
                setExpandedPanel2('panel2a')
                setExpandedPanel1(false)
                setExpandedPanel3(false)
                setExpandedPanel4(false)
            } else if (panel === 'panel3') {
                setExpandedPanel3('panel3a')
                setExpandedPanel1(false)
                setExpandedPanel2(false)
                setExpandedPanel4(false)
            } else if (panel === 'panel4') {
                setExpandedPanel4('panel4a')
                setExpandedPanel1(false)
                setExpandedPanel2(false)
                setExpandedPanel3(false)
            }
        }
        setExpandedRoot(isExpanded ? panel : false);
    };

    const handleChangePanel1 = (panel) => (event, isExpanded) => {
        setExpandedPanel1(isExpanded ? panel : false);
    };
    const handleChangePanel2 = (panel) => (event, isExpanded) => {
        setExpandedPanel2(isExpanded ? panel : false);
    };
    const handleChangePanel3 = (panel) => (event, isExpanded) => {
        setExpandedPanel3(isExpanded ? panel : false);
    };
    const handleChangePanel4 = (panel) => (event, isExpanded) => {
        setExpandedPanel4(isExpanded ? panel : false);
    };

    return <LayoutContainer>
        <Header
            title={t('frontPage.infoShortcut')}
        />
        <Accordion expanded={expandedRoot === 'panel1'} onChange={handleChangeRoot('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <h2>{t('info.panel1.title')}</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.panelContainer}  >

                <Accordion expanded={expandedPanel1 === 'panel1a'} onChange={handleChangePanel1('panel1a')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3>{t('info.panel1.aTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel1.aBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

            </AccordionDetails>
        </Accordion>

        <Accordion expanded={expandedRoot === 'panel2'} onChange={handleChangeRoot('panel2')} className={classes.AccordionRoot}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <h2>{t('info.panel2.title')}</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.panelContainer}  >

                <Accordion expanded={expandedPanel2 === 'panel2a'} onChange={handleChangePanel2('panel2a')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <h3>{t('info.panel2.aTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel2.aBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel2 === 'panel2b'} onChange={handleChangePanel2('panel2b')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2b-content"
                        id="panel2b-header"
                    >
                        <h3>{t('info.panel2.bTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel2.bBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel2 === 'panel2c'} onChange={handleChangePanel2('panel2c')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2c-content"
                        id="panel2c-header"
                    >
                        <h3>{t('info.panel2.cTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel2.cBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel2 === 'panel2d'} onChange={handleChangePanel2('panel2d')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <h3>{t('info.panel2.dTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel2.dBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

            </AccordionDetails>
        </Accordion>


        <Accordion expanded={expandedRoot === 'panel3'} onChange={handleChangeRoot('panel3')} className={classes.AccordionRoot}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
            >
                <h2>{t('info.panel3.title')}</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.panelContainer}  >

                <Accordion expanded={expandedPanel3 === 'panel3a'} onChange={handleChangePanel3('panel3a')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <h3>{t('info.panel3.aTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel3.aBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel3 === 'panel3b'} onChange={handleChangePanel3('panel3b')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3b-content"
                        id="panel3b-header"
                    >
                        <h3>{t('info.panel3.bTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel3.bBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel3 === 'panel3c'} onChange={handleChangePanel3('panel3c')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3c-content"
                        id="panel3c-header"
                    >
                        <h3>{t('info.panel3.cTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel3.cBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel3 === 'panel3d'} onChange={handleChangePanel3('panel3d')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <h3>{t('info.panel3.dTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel3.dBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                        <p><b>{t('info.panel3.dBodyTitle')}</b></p>
                        <table>
                            <tbody>
                                <tr>
                                    <td><b>1.</b></td>
                                    <td><b>{t('info.panel3.dBody1Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody1Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>2.</b></td>
                                    <td><b>{t('info.panel3.dBody2Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody2Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>3.</b></td>
                                    <td><b>{t('info.panel3.dBody3Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody3Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>4.</b></td>
                                    <td><b>{t('info.panel3.dBody4Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody4Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>5.</b></td>
                                    <td><b>{t('info.panel3.dBody5Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody5Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>6.</b></td>
                                    <td><b>{t('info.panel3.dBody6Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody6Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>7.</b></td>
                                    <td><b>{t('info.panel3.dBody7Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody7Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>8.</b></td>
                                    <td><b>{t('info.panel3.dBody8Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody8Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>9.</b></td>
                                    <td><b>{t('info.panel3.dBody9Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody9Body')}</td>
                                </tr>
                                <tr>
                                    <td><b>10.</b></td>
                                    <td><b>{t('info.panel3.dBody10Title')}</b></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>{t('info.panel3.dBody10Body')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel3 === 'panel3e'} onChange={handleChangePanel3('panel3e')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3e-content"
                        id="panel3e-header"
                    >
                        <h3>{t('info.panel3.eTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel3.eBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel3 === 'panel3f'} onChange={handleChangePanel3('panel3f')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3f-content"
                        id="panel3f-header"
                    >
                        <h3>{t('info.panel3.fTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel3.fBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

            </AccordionDetails>
        </Accordion>

        <Accordion expanded={expandedRoot === 'panel4'} onChange={handleChangeRoot('panel4')} className={classes.AccordionRoot}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
            >
                <h2>{t('info.panel4.title')}</h2>
            </AccordionSummary>
            <AccordionDetails className={classes.panelContainer}  >

                <Accordion expanded={expandedPanel4 === 'panel4a'} onChange={handleChangePanel4('panel4a')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                    >
                        <h3>{t('info.panel4.aTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel4.aBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel4 === 'panel4b'} onChange={handleChangePanel4('panel4b')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4b-content"
                        id="panel4b-header"
                    >
                        <h3>{t('info.panel4.bTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        <ul>{t('info.panel4.bBodyStart').split(';').map((row, i) => <li key={i}>{row}</li>)}</ul>
                        {t('info.panel4.bBodyEnd').split(';').map((row, i) => <p key={i}>{row}</p>)}
                        <div>{t('info.panel4.bBodyLink')}<a href={i18n.language === 'fi' ? pdf : pdf_en} target="_blank" rel="noopener noreferrer">{t('info.panel4.here')}</a></div>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel4 === 'panel4c'} onChange={handleChangePanel4('panel4c')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4c-content"
                        id="panel4c-header"
                    >
                        <h3>{t('info.panel4.cTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel4.cBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                        <a href='https://www.elo.fi/fi-fi/elomedia/2023/yrittaja_nain-yel-vakuutus-turvaa' target="_blank" rel="noopener noreferrer">https://www.elo.fi/fi-fi/elomedia/2023/yrittaja_nain-yel-vakuutus-turvaa</a>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel4 === 'panel4d'} onChange={handleChangePanel4('panel4d')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                    >
                        <h3>{t('info.panel4.dTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel4.dBody').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expandedPanel4 === 'panel4e'} onChange={handleChangePanel4('panel4e')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4e-content"
                        id="panel4e-header"
                    >
                        <h3>{t('info.panel4.eTitle')}</h3>
                    </AccordionSummary>
                    <AccordionDetails className={classes.expansionChild}>
                        {t('info.panel4.eBodyStart').split(';').map((row, i) => <p key={i}>{row}</p>)}
                        <a href='https://www.elo.fi/fi-fi/yrittaja/kevytyrittaja' target="_blank" rel="noopener noreferrer">https://www.elo.fi/fi-fi/yrittaja/kevytyrittaja</a>
                        {t('info.panel4.eBodyEnd').split(';').map((row, i) => <p key={i}>{row}</p>)}
                    </AccordionDetails>
                </Accordion>

            </AccordionDetails>
        </Accordion>
        {!isAdmin && <Footer />}
    </LayoutContainer>
}

export default Info
