export const oidcSettings = {    
  authority: 'https://tunnistus.telia.fi/uas/oauth2',
  authority_dev: 'https://tunnistus-pp.telia.fi/uas/oauth2',
  client_id: 'dc97235c-4966-429f-a79f-17f492fae8b2',
  client_id_dev: 'd8318721-124f-4f88-8dce-d2c6dfd228f2',
  redirect_uri: 'https://laskuta.odeal.fi/',
  redirect_uri_dev: 'https://dev-laskuta.odeal.fi/',
  response_type: 'code',
  scope: 'openid',
  state: 'authCode',
  state_sign_up: 'signUp',
  state_confirm_ssn: 'confirmAccount',
};