import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { cLog } from '../../utils'
import { Field } from "formik"
import { withSnackbar } from 'notistack'
import { saveValtticard, updateValtticard, deleteValtticard } from '../../service'
import DPicker from '../../components/DPicker'
import { formatDate, formatDatetime, formatTimeFI } from '../../utils'
import { validateTextArea, validateDate } from '../Invoice/validationSchemas'
import TextArea from '../../components/TextArea'
import Alert from '../../components/Alert'
import DropDown from '../../components/DropDown'
import InputLink from '../../components/InputLink'
import { APIdomain } from '../../service'
import { getYear } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
  thumbnail: {
    maxWidth: '100%',
  },
}))

const ValtticardDialog = ({
  resetForm,
  handleChange,
  values,
  setFieldValue,
  errors,
  validateForm,
  enqueueSnackbar,
  open,
  setOpen,
  edit,
  setValues,
  setEdit,
  fetchData
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [openConfirmDeleteValtticardDialog, setOpenConfirmDeleteValtticardDialog] = useState(false)
  const [userOptions, setUserOptions] = useState([])

  const statusOptions = ['TARKASTETTAVANA', 'TILATTU', 'HYLÄTTY', 'POISTETTU'].map(status => ({ label: t('valtticard.'+status), value: status }))

  useEffect(() => {
    if (edit) {
      setShowHistory(false)
      setValues({ ...edit, lastInvoice: edit.invoiceData && edit.invoiceData.length > 0 && edit.invoiceData[0].date ? formatTimeFI(edit.invoiceData[0].date) : '' })
      setUserOptions([{ label: `${edit.userFirstName} ${edit.userLastName} (${edit.userEmail})`.trim(), value: edit.userId }])
    }
  }, [edit, setValues])

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    //cLog('ERRORI TYYPIT', errorTypes)
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        //cLog('INVOICE FORM ERROR!!!', type)
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    /*if (!errorTypes[0]) {
      enqueueSnackbar(
        t('invoice.deficientRows'),
        { variant: 'error' }
      )
    }*/
  }

  var validate = async () => {
    const erroris = await validateForm()
    cLog('ERRORIS', erroris)
    if (Object.keys(erroris).length === 0) {
      return true
    } else {
      //cLog('ERROREITA PIISAA', erroris)
      notifyErrors(erroris)
      return false
    }
  }

  const handleSubmitForm = async () => {
    let val = validate()
    val.then(result => {
      if (result) {
        saveForm()
      }
    }, error => {
      cLog('SAVE FORM ERROR', error)
    })
  }

  const saveForm = async () => {
    try {
      setLoading(true)
      let sendResult
      if (values && values.id) {
        sendResult = await updateValtticard(values)
      } else {
        sendResult = await saveValtticard(values)
      }
      cLog('VALTTIKORTTI VASTAUS', sendResult)

      resetForm(sendResult)
      handleClose()
      enqueueSnackbar(
        t('valtticard.valtticardSaved'),
        { variant: 'success' }
      )
      fetchData()
    } catch (error) {
      setLoading(false)
      cLog('VALTTIKORTTI ERROR', error)
      enqueueSnackbar(
        t('valtticard.valtticardSavedError'),
        { variant: 'error' }
      )
    }
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setLoading(false)
      setOpenConfirmDeleteValtticardDialog(false)
      setOpen(false)
      setEdit(null)
      resetForm()
    }
  }

  const handleAgreeDeleteValtticardDialog = async () => {
    setLoading(true)
    const request = deleteValtticard(values.id)
    request.then(response => {
      cLog('VALTTICARD DELETED', response)
      enqueueSnackbar(
        t('valtticard.valtticardDeleted'),
        { variant: 'success' }
      )
      handleClose()
      fetchData()
    }, error => {
      setLoading(false)
      setOpenConfirmDeleteValtticardDialog(false)
      cLog('VALTTICARD DELETE ERROR', error)
      enqueueSnackbar(
        t('valtticard.problemsDeletingValtticard'),
        { variant: 'error' }
      )
    })
  }

  const History = () => {
    let collection = [];
    values.updateHistory.map((row, i) => {
      let name, filtered, joined;
      let changes = [];

      for (const [key, value] of Object.entries(row.changes)) {
        if (key !== 'id') {
          let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
          let value0 = value[0]
          let value1 = value[1]

          if (['orderDate', 'validUntil', 'confirm'].includes(k)) {
            if (value0) {
              value0 = formatTimeFI(value0)
            }
            if (value1) {
              value1 = formatTimeFI(value1)
            }
          }

          if (value[0] || value[1]) {
            if (value0 === 'true' || value0 === true) {
              value0 = t('taxcard.true')
            } else if (value0 === 'false'|| value0 === false) {
              value0 = t('taxcard.false')
            } else if (!value0) {
              value0 = t('user.noValue')
            }

            if (value1 === 'true' || value1 === true) {
              value1 = t('taxcard.true')
            } else if (value1 === 'false' || value1 === false) {
              value1 = t('taxcard.false')
            } else if (!value1) {
              value1 = t('user.noValue')
            }

            if (row.event === 'create') {
              changes.push(t('valtticard.'+k) + ': ' + value1)
            } else if (row.event === 'destroy') {
              changes.push(t('valtticard.'+k) + ': ' + value0)
            } else {
              changes.push(t('valtticard.'+k) + ': ' + value0 + ' -> ' + value1)
            }
          }
        }
      }

      if (changes.length) {
        if (row.firstName && row.lastName) {
          name = row.firstName + ' ' + row.lastName;
        } else if (row.firstName && !row.lastName) {
          name = row.firstName;
        } else if (!row.firstName && row.lastName) {
          name = row.lastName
        } else {
          name = 'Odealin järjestelmä'
        }

        filtered = changes.filter(function (f) {
          return f != null;
        });
        joined = filtered.join(', ');

        let rowText = ''
        if (row.event === 'create') {
          rowText = t('valtticard.valtticard') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
        }

        collection.push(<TableRow key={i}>
          <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{rowText + joined}</TableCell>
        </TableRow>)
      }

      return null
    })

    return (<div style={{ paddingBottom: '20px' }}>
      <br /><br />
      {t('valtticard.historyTitle')}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('customers.date')}</TableCell>
            <TableCell>{t('customers.user')}</TableCell>
            <TableCell>{t('customers.events')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection}
        </TableBody>
      </Table>
    </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby="promo-dialog-title"
      aria-describedby="promo-dialog-description"
    >
      <DialogTitle id="promo-dialog-title">{edit ? t('valtticard.editTitle') : t('valtticard.addNewValtticard')}</DialogTitle>
      <DialogContent dividers={true} id="promo-dialog-description">
        <form>
          <Grid container spacing={4} className={classes.gridContainer}>
            <Grid item xs={12} sm={6}>
              <Field
                name="userId"
                id='userId'
                label={values.userId ? t('invoice.biller') : t('invoice.chooseBiller')}
                component={DropDown}
                placeholder={t('invoice.typeToSearch')}
                options={userOptions}
                defaultValue={values.userId}
                isDisabled={edit}
                onChange={(e => setFieldValue('userId', e.value))}
                validate={() => !values.userId && ({ key: t('invoice.billerMissing') })}
                error={errors && errors.userId && !values.userId}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="lastInvoice"
                id='lastInvoice'
                label={t('valtticard.lastInvoice')}
                to={values.invoiceData && values.invoiceData.length > 0 ? `/laskut/esikatsele/${values.invoiceData[0].id}` : ''}
                value={values.lastInvoice || ''}
                target={'_blank'}
                component={InputLink}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="status"
                id='status'
                label={t('valtticard.status')}
                value={values.status}
                placeholder={t('invoice.pick')}
                onChange={(e) => setFieldValue('status', e.value)}
                options={statusOptions}
                component={DropDown}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="orderDate"
                id="orderDate"
                label={t('valtticard.orderDate')}
                component={DPicker}
                selected={values.orderDate ? new Date(values.orderDate) : ''}
                onChange={(date) => setFieldValue('orderDate', date ? formatDate(date) : '')}
                validate={() => validateDate(values.orderDate, t('valtticard.orderDate'), false, null, true)}
                error={errors && errors.orderDate && validateDate(values.orderDate, t('valtticard.orderDate'), false, null, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="validUntil"
                id="validUntil"
                label={t('valtticard.validUntil')}
                component={DPicker}
                maxDate={new Date(getYear(new Date()) + 4, 11, 31)}
                selected={values.validUntil ? new Date(values.validUntil) : ''}
                onChange={(date) => setFieldValue('validUntil', date ? formatDate(date) : '')}
                validate={() => validateDate(values.validUntil, t('valtticard.validUntil'), false, null, true)}
                error={errors && errors.validUntil && validateDate(values.validUntil, t('valtticard.validUntil'), false, null, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="confirm"
                id="confirm"
                label={t('valtticard.confirm')}
                component={DPicker}
                maxDate={new Date(getYear(new Date()) + 4, 11, 31)}
                selected={values.confirm ? new Date(values.confirm) : ''}
                onChange={(date) => setFieldValue('confirm', date ? formatDate(date) : '')}
                validate={() => validateDate(values.confirm, t('valtticard.confirm'), false, null, true)}
                error={errors && errors.confirm && validateDate(values.confirm, t('valtticard.confirm'), false, null, true)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Field
                id="notes"
                name="notes"
                type="text"
                component={TextArea}
                label={t('valtticard.notes')}
                placeholder={t('valtticard.notes')}
                onChange={handleChange}
                value={values.notes || ''}
                validate={() => validateTextArea(values.notes, t('valtticard.notes'), 10000, false)}
                error={errors && errors.notes && validateTextArea(values.notes, t('valtticard.notes'), 10000, false)}
              />
            </Grid>
            {values.attachments && values.attachments.length > 0 && <Grid item xs={12} sm={12}>
              <Tooltip title={t('taxcard.download')}>
                <a href={APIdomain + values.attachments[0].url} target="_blank" rel="noopener noreferrer">
                {['jpg', 'jpeg', 'JPG', 'tif', 'tiff', 'png', 'bmp', 'gif',].includes(values.attachments[0].filename.split('.')[1])
                  ? <img src={APIdomain + values.attachments[0].url} className={classes.thumbnail} alt="KUVA"/>
                  : values.attachments[0].filename
                }
                </a>
              </Tooltip>
            </Grid>}
          </Grid>
        </form>
        {showHistory && edit && <Grid container spacing={0}>
          <Grid item xs={12}>
            <History />
          </Grid>
        </Grid>}
      </DialogContent>
      <DialogActions>
        {edit && <Button
          variant="text"
          onClick={() => setShowHistory(!showHistory)}
          color="secondary"
          disabled={isLoading}
        >
          {t('discountCode.history')}
        </Button>}
        {edit && <Button
          variant="text"
          onClick={() => { setOpenConfirmDeleteValtticardDialog(true) }}
          color="error"
          disabled={isLoading}
        >
          {t('customers.remove')}
        </Button>}
        <Button
          variant="text"
          onClick={handleClose}
          color="secondary"
          disabled={isLoading}
        >
          {t('promocodes.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleSubmitForm}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('promocodes.send')}
        </Button>
      </DialogActions>
      <Alert
        open={openConfirmDeleteValtticardDialog}
        setOpen={setOpenConfirmDeleteValtticardDialog}
        handleAgree={handleAgreeDeleteValtticardDialog}
        handleClose={() => { setOpenConfirmDeleteValtticardDialog(false) }}
        alertMessage={{ title: t('valtticard.confirmDeletionTitle'), body: t('user.userNoteDeleteWarningBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
        isLoading={isLoading}
      />
    </Dialog>
  )
}

export default withSnackbar(ValtticardDialog)
