import React, { useEffect } from 'react'
import { getMessages } from '../../service'
import { useStateValue, setMessages } from '../../state'
import { withSnackbar } from 'notistack';
import LayoutContainer from '../../components/LayoutContainer'

const Messages = (/* { enqueueSnackbar } */) => {

    const [{ users, messages }, dispatch] = useStateValue();
    const unreadMessages = messages.filter(message => !message.isRead && message.senderId === message.userId)
    const messageWriters = [...new Set(messages.map(message => message.userId))]
    //cLog('VIESTIEN KIRJOITTAJAT', messageWriters)
    const oldMessagesByUsers = {}
    messageWriters
        .forEach(user => oldMessagesByUsers[user] = messages
            .filter(message => {
                const isFromOffice = message.userId !== message.senderId
                if (isFromOffice) {
                    return message.userId === user
                } else {
                    return message.userId === user && message.isRead
                }
            }))
    //cLog('KÄYTTÄJIEN VIESTIT', oldMessagesByUsers)
    /* let allMessages = []                                         TODO: KAIKKI VANHAT VIESTIT MÄÄRÄ
    Object.keys(oldMessagesByUsers).forEach(user => {
        const array = oldMessagesByUsers[user]
        if (array) {
            allMessages.push([...array])
        }
    })

    cLog('ALL MESSAGES', allMessages) */

    useEffect(() => {
        const fetchData = async () => {
            //dispatch(setReferences(await getReferences()))
            const allMessages = await getMessages()
            dispatch(setMessages(allMessages.array))
        }

        fetchData()
    }, [dispatch])

    //cLog('MESSAGES', messages)

    /* const handleSaveReference = async (values) => {
        cLog('SAVING REFERENCE', values)
        try {
            dispatch(addReference(await saveReference(values)))
            cLog('TODISTUSPYYNTÖ LÄHETETTY ONNISTUNEESTI')
            enqueueSnackbar(
                'Todistuspyyntö lähetetty ',
                { variant: 'success' }
            )
        } catch (error) {
            cLog('VAIKEUKSIA TODISTUSPYYNNÖN LÄHETTÄMISESSÄ', error)
            enqueueSnackbar(
                'Vaikeuksia todistuspyynnnön lähettämisessä',
                { variant: 'error' }
            )
        }
    } */

    /* const handleDeleteReference = async (reference) => {
        cLog('DELETING REFERENCE', reference.id)
        try {
            await deleteReference(reference)
            dispatch(removeReference(reference))
            cLog('TODISTUSPYYNTÖ POISTETTU ONNISTUNEESTI')
            enqueueSnackbar(
                'Todistuspyyntö poistettu ',
                { variant: 'success' }
            )
        } catch (error) {
            cLog('VAIKEUKSIA TODISTUSPYYNNÖN POISTAMISESSA', error)
            enqueueSnackbar(
                'Vaikeuksia todistuspyynnnön poistamisessa',
                { variant: 'error' }
            )
        }
    } */
    const senderName = (id) => {
        const { firstName, lastName, email } = users.find(user => id === user.id)
        return `${(firstName || 'N. ') + ' ' + (lastName || 'N. ') + '  ' + email}`
    }


    return (
        <LayoutContainer>

            <h5>Lukemattomat viestit</h5>
            <ul>
                {unreadMessages.map((message, i) => {
                    return <li key={i}>
                        <span>{message.title && message.title.toUpperCase()}</span>{' '}<span>{senderName(message.userId)}</span>

                    </li>
                })}
            </ul>

            <h5>Vanhat viestit</h5>
            <ul>
                {Object.keys(oldMessagesByUsers).map((user, i) => oldMessagesByUsers[user].length > 0 &&
                    <li key={i}>
                        {senderName(user)}
                    </li>
                )}
            </ul>



            {/* <Formik
                initialValues={{
                    date: getDateNow(),
                    isWorkReference: false,
                    isSalaryReference: false,
                    sendByEmail: true,
                    customPeriod: false,
                    dateStartWork: null,
                    dateEndWork: null,
                    explanations: '',
                    status: 'LUONNOS' ///// TAI 'TARKASTETTAVANA' TAI 'LÄHETETTY'
                }}
                validateOnChange={false}
                validateOnBlur={false}
                component={props =>
                    {  <ReferenceForm
                        {...props}
                        handleSaveReference={handleSaveReference}
                    /> }}
            /> */}
            <br />
            {/* references.length > 0
                && <ReferenceTable
                    rows={references}
                    handleDeleteReference={handleDeleteReference} /> */}
        </LayoutContainer>
    )
}

export default withSnackbar(Messages)
