import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getQuery, getWorklist, deleteWorklist } from '../../service'
import Button from '../../components/Button'
import { useStateValue, setWorklists, setSortKeyWorklists, setSortOrderWorklists } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import '../../styles/styles.css'

let selectedColumns  = []

const INIT_FILTER = {
  userId: [],
  clientName: '',
  name: '',
  status: '',
  totalHours: '',
  createdAt: '',
  updatedAt: '',
  actions: '',
  columnsOptions: []
}

const Worklists = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  let history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const [{ worklists, currentUser, sortKeyWorklists, sortOrderWorklists }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: (isAdmin ? 100 : 20),
    totalPages: 0,
    totalRows: 0
  })
  let allColumns = []
  if (isAdmin) {
    allColumns = ['userId', 'clientName', 'name', 'status', 'totalHours', 'createdAt', 'updatedAt', 'actions']
    selectedColumns = JSON.parse(window.localStorage.getItem('worklistsSelectedColumns')) || allColumns
  } else {
    allColumns = ['clientName', 'name', 'status', 'totalHours', 'createdAt', 'updatedAt', 'actions']
    selectedColumns = allColumns
  }
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('worklist.'+option),
      value: option
    }
    if (['totalHours'].includes(option)) {
      obj.numeric = true
      obj.decimals = 1
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
        {row.status === 'LUONNOS' &&
          <>
            <Tooltip title={t('customers.edit')}>
              <EditIcon
                className="actionIcon"
                onClick={() => {
                  if (isAdmin) {
                    window.open('/tyolistat/muokkaa/' + row.id)
                  } else {
                    history.push('/tyolistat/muokkaa/' + row.id)
                  }
                }}
              />
            </Tooltip>
            <Tooltip title={t('worklist.createInvoice')}>
              <AccountBalanceOutlinedIcon
                className="actionIcon"
                onClick={() => {
                  if (isAdmin) {
                    window.open('/laskut/uusi/' + row.id)
                  } else {
                    history.push('/laskut/uusi/' + row.id)
                  }
                }}
              />
            </Tooltip>
            <Tooltip title={t('customers.remove')}>
              <DeleteOutlineIcon
                className="actionIcon"
                onClick={() => {
                  if (isAdmin) {
                    setLoading(true)
                    const response = getWorklist(row.id)
                    response.then(data => {
                      setSelectedRow(data)
                      setOpenConfirmDeleteModal(true)
                      setLoading(false)
                    }, error => {
                      cLog('FETCHING DATA ERROR', error)
                      enqueueSnackbar(
                        t('auth.problemsLoadingData'),
                        { variant: 'error' }
                      )
                      setLoading(false)
                    })
                  } else {
                    setSelectedRow(row)
                    setOpenConfirmDeleteModal(true)
                  }
                }}
              />
            </Tooltip>
          </>}
          {row.status !== 'LUONNOS' &&
            <Tooltip title={t('worklist.view')}>
              <VisibilityOutlinedIcon
                className="actionIcon"
                onClick={() => {
                  if (isAdmin) {
                    window.open('/tyolistat/muokkaa/' + row.id)
                  } else {
                    history.push('/tyolistat/muokkaa/' + row.id)
                  }
                }}
              />
            </Tooltip>
          }
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyWorklists, sortOrderWorklists, filter, selectedColumns, 'worklists')
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          if (selectedColumns.includes('status')) {
            row.status = t('worklist.'+row.status)
          }

          return row
        })
        dispatch(setWorklists(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setWorklists([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyWorklists, sortOrderWorklists, filter, pagination, t, enqueueSnackbar, history, isAdmin])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    if (isAdmin) {
      document.addEventListener('keyup', handleKeyPress)
    }

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination, isAdmin])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('worklist.worklists') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteWorklist(selectedRow.id)
      cLog(`WORKLIST ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('worklist.worklistDeleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE WORKLIST ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('worklist.problemsDeletingWorklist'),
        { variant: 'error' }
      )
    }
  }

  let statuses = ['LUONNOS', 'VALMIS']
  if (isAdmin) {
    statuses.push('POISTETTU')
  }
  const statusOptions = statuses.map(option => ({ label: t('worklist.'+option), value: option }))

  return (
    <LayoutContainer>
      <Header
        title={t('worklist.worklists')}
        actions={<Button variant="contained" color="primary" onClick={() => history.push('/tyolistat/uusi')}>{t('worklist.addNewWorklist')}</Button>}
      />
      {isAdmin && <DatatableFilters
        inputs={[
          { field: 'userId', label: t('invoice.billers'), type: 'userId' },
          { field: 'clientName', label: t('worklist.clientName'), type: 'text' },
          { field: 'name', label: t('worklist.name'), type: 'text' },
          { field: 'status', label: t('worklist.status'), type: 'select', options: statusOptions },
          { field: 'totalHours', label: t('worklist.totalHours'), type: 'text' },
          { field: 'createdAt', label: t('worklist.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('worklist.updatedAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />}
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'worklistsSelectedColumns'}
        sortKey={sortKeyWorklists}
        setSortKey={setSortKeyWorklists}
        sortOrder={sortOrderWorklists}
        setSortOrder={setSortOrderWorklists}
        isAdmin={isAdmin}
      />
      <Datatable
        data={worklists}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyWorklists}
        setSortKey={setSortKeyWorklists}
        sortOrder={sortOrderWorklists}
        setSortOrder={setSortOrderWorklists}
        isAdmin={isAdmin}
      />
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('worklist.confirmDeletionTitle', { name: selectedRow ? selectedRow.name : '' }), body: t('worklist.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </LayoutContainer>
  )
}

export default withSnackbar(Worklists)
