import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import Button from './Button'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Tip from './Tip'
import { withSnackbar } from 'notistack';
//import { cLog } from '../utils'
import { useTranslation } from 'react-i18next';
import { acceptedCsvTypes } from '../utils/acceptedCsvTypes'

const CsvDropzone = ({ setForm, form, style, enqueueSnackbar }) => {
  const { t } = useTranslation();
  const handleUploadFile = (files) => {
    const file = files[0]

    if (file && !acceptedCsvTypes.includes(file.type)) {
      enqueueSnackbar(
        t('user.wrongFile'),
        { variant: 'error' }
      )
      return
    }

    if (file && file.size > 10000000) {
      enqueueSnackbar(
        t('user.fileTooBig'),
        { variant: 'error' }
      )
      return
    }

    const { name, type } = file
    const newFile = {
      file,
      filename: name,
      url: URL.createObjectURL(file),
      contentType: type
    }

    setForm({...form, file: newFile})
  }

  const onDrop = (acceptedFiles => handleUploadFile(acceptedFiles))
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, })
  return (
    <Tip title={t('user.csvDropzoneText')} placement="bottom">
      <Button aria-label={t('user.csvDropzoneText')} style={{ ...style, backgroundColor: isDragActive && 'hotpink' }} variant="outlined" color="primary" className="customInput" id='uploadedFile' {...getRootProps()}>
          <input {...getInputProps()} />
          <AttachFileIcon />
      </Button>
    </Tip>
  )
}

export default withSnackbar(CsvDropzone)
