import { formatTimeFI, getDateNow, getDateIn2weeks, cLog, roundNumber } from './index'

export const scrollTo = (id, block = 'center') => document.getElementById(id).scrollIntoView({ behavior: 'smooth', block })

export const hasInvoiceRows = (invoice) => invoice.invoiceRows && invoice.invoiceRows.length > 0
export const hasTravelRows = (invoice) => invoice.travellingExpenceRows && invoice.travellingExpenceRows.length > 0
export const hasMaterialRows = (invoice) => invoice.materialExpenceRows && invoice.materialExpenceRows.length > 0
export const hasTravelOrMaterialRows = (invoice) => hasTravelRows(invoice) || hasMaterialRows(invoice)
export const hasAnyRows = (invoice) => hasInvoiceRows(invoice) || hasTravelOrMaterialRows(invoice)
export const hasPayments = (invoice) => invoice.payments && invoice.payments.length > 0

export const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`

export const vat24ChangeDate = () => new Date('2024-09-01')
export const useVat24 = (dateEndWork) => new Date() < vat24ChangeDate() || (dateEndWork && new Date(dateEndWork) < vat24ChangeDate())

export function discountText(discountCode) {
  const percentage = discountCode.discountPercentage
  const sum = discountCode.discountSum
  let text
  if (percentage > 0) {
    text = discountCode.discountCode + ', -' + percentage + '%'
  } else if (sum > 0) {
    text = discountCode.discountCode + ', -'+ sum + '€'
  } else {
    text = ''
  }
  return text
}

export function stringToBoolean(value) {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value
 }

export function timeRange(start, edge, step) {
  // If only 1 number passed make it the edge and 0 the start 
  if (arguments.length === 1) {
    edge = start
    start = 0
  }
  // Validate edge/start 
  edge = edge || 0;
  step = step || 1;
  // Create array of numbers, stopping before the edge
  let arr = [];
  for (arr; (edge - start) * step > 0; start += step) {
    arr.push(start < 10 ? '0' + start : start.toString())
  }
  return arr
}

//export const isYtunnus = (value) => /\d{7}[-]\d{1}$/.test(value)

export function isYtunnus(input) {
  let companyId = input

   // Some old company id's have only 6 digits. They should be prefixed with 0.
  if (/^[0-9]{6}-[0-9]{1}/.test(String(companyId))) {
    companyId = '0' + input
  }

  // Ensure that the company ID is entered in correct format.
  if (!/^[0-9]{7}-[0-9]{1}/.test(String(companyId))) {
    return false
  }

  let values = companyId.split("-");
  let keys = ["id", "checksum"]
  let final = {};
  for(var i = 0; i < values.length; i++) {
    final[keys[i]] = values[i];
  }

  final.checksum = Number(final.checksum)

  let totalCount = 0;
  let multipliers = [7, 9, 10, 5, 8, 4, 2];
  multipliers.forEach(function callback(value, index) {
    totalCount += value * final.id[index]
  })

  let remainder = totalCount % 11;

  // Remainder 1 is not valid.
  if (remainder === 1) {
    return false
  }

  // Remainder 0 leads into checksum 0.
  if (remainder === 0) {
    return final.checksum === remainder
  }

  // If remainder is not 0, the checksum should be remainder deducted from 11.
  return final.checksum === 11 - remainder
}

//export const isEmail = (value) => /^[^@\s]+@[^@\s]+$/.test(value) //// JUHAN REGEX

export const isEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*$/;
  return re.test(String(email).toLowerCase())
}

export const parseVat = (vat) => {
  const vatTable = {
    "25,5": 25.5,
    "24": 24,
    "14": 14,
    "10": 10,
    "0": 0,
    "käännetty alv": 0
  }
  return vatTable[vat]
}

export const cleanFloat = value => {
  if (value) {
    if (value === '-') {
      return 0
    }
    if (value.toString()[0] === '-') {
      return value &&
      parseFloat('-0' + value.toString().substring(1)
        .replace(",", ".")
        .replace(/\s+/g, ''))
    } else {
      return value &&
      parseFloat('0' + value.toString()
        .replace(",", ".")
        .replace(/\s+/g, ''))
    }
  }

  return value
}

export const formatFloat = value => {
  return value.toString().replace('.', ',')
}

const isNumber = value => /[0-9]/.test(value)

export const validateInt = value => {
  if (String(value).trim().length > 1) {
    const lastChar = value.substr(String(value).trim().length - 1, 1)
    return isNumber(lastChar)
  } else {
    if (value === '') {
      return true
    }
    return isNumber(value)
  }
}

export const validateAllFloats = (value) => {
  const minusses = value.match(/-/g)
  // cLog('MINUSSES KPL', minusses && minusses.length)
  if (minusses && minusses.length > 1) {
    return false
  }
  const firstChar = value.charAt(0)
  if (firstChar === '-' || isNumber(firstChar) || firstChar === '') {
    return validateFloat(value.slice(1))
  }
}

export const validateFloat = value => {
  const isCommaOrDot = str => ['.', ','].includes(str)
  const lastChar = value.slice(-1) || ''
  const secondLastChar = value.slice(-2, -1) || ''
  if (String(value).trim().length > 1) {
    if (lastChar && !isNumber(lastChar) && !isCommaOrDot(lastChar)) {
      //cLog('NOT A NUMBER')
      return false
    }
    if (isCommaOrDot(lastChar) && isCommaOrDot(secondLastChar)) {
      //cLog('2 COMMAS IN ROW ERROR')
      return false
    }
    const commasAndDots = value.match(/[.,]/g)
    //cLog('MONTAKO PISTETTÄ TAI PILKKUA', commasAndDots.length)
    if (commasAndDots && commasAndDots.length > 1) {
      //cLog('2 COMMAS ERROR')
      return false
    }

    if (commasAndDots && commasAndDots.length === 1) {
      if ((value.split(',')[1] && value.split(',')[1].length > 2)
        || (value.split('.')[1] && value.split('.')[1].length > 2)) {
        //cLog('2+ DECIMALS ERROR')
        return false
      }
      return true
    }
  } else {
    if (lastChar && String(value).trim().length > 1 && (!isNumber(lastChar) || isCommaOrDot(lastChar))) {
      cLog('NOT A NUMBER')
      return false
    }
  }
  return true
}

export const validatePhoneNumber = value => {
  const lastChar = value.slice(-1) || ''
  const twoLastChar = String(value).trim().length > 1 ? value.slice(-2) : ''
  const plussas = value.match(/[+]/g)
  if (plussas && lastChar === '+') {
    if (value[0] !== '+' || plussas.length > 1) {
      return false
    } else {
      return true
    }
  }
  const minusses = value.match(/[-]/g)
  if (minusses && lastChar === '-') {
    if (value[0] === '-' || twoLastChar === '--') {
      return false
    } else {
      return true
    }
  }

  return value === '' || isNumber(lastChar)
}

const totalInvoiceRow = row => {
  if (row.quantity && row.price) {
    return roundNumber(cleanFloat(row.quantity) * cleanFloat(row.price), 2)
  } else {
    return 0
  }
}

const taxesInvoiceRow = row => {
  if (row.tax) {
    return cleanFloat(row.tax)
  } else {
    return 0
  }
}

const totalInvoiceRowWithTax = row => {
  if (row.total) {
    return cleanFloat(row.total)
  } else {
    return 0
  }
}

export const totalTravellingExpenceRowKm = row => {
  let totalKm = 0

  if (row.travelRowDestinations && row.travelRowDestinations.length >= 2) {
    const totalKmArray = row.travelRowDestinations.map(dest => cleanFloat(dest.km || 0)) || []
    totalKm = (totalKmArray.length > 0 && roundNumber(cleanFloat(totalKmArray.reduce((a, b) => roundNumber(cleanFloat(a), 2) + roundNumber(cleanFloat(b), 2))), 2)) || 0
  } else {
    totalKm = row.km
  }

  return roundNumber(totalKm, 0)
}

export const totalTravellingExpenceRow = row => {
  let travel = null, dailyAllowances = null, partTimeDailyAllowances = null, mealExpences = null, totalKm = null

  if (row.allowances) {
    totalKm = totalTravellingExpenceRowKm(row)

    travel = row.mileage ? roundNumber(cleanFloat(totalKm) * cleanFloat(row.mileage) / 100, 2) : roundNumber(cleanFloat(totalKm) * cleanFloat(row.allowances.vehicle[row.vehicle]) / 100, 2)
    dailyAllowances = row.daAmount ? roundNumber(cleanFloat(row.dailyAllowances) * cleanFloat(row.daAmount), 2) : roundNumber(cleanFloat(row.dailyAllowances) * cleanFloat(row.allowances.dailyAllowance[row.country]), 2)
    if (row.country === 'FINLAND') {
      partTimeDailyAllowances = row.ptdaAmount ? roundNumber(cleanFloat(row.partTimeDailyAllowances) * cleanFloat(row.ptdaAmount), 2) : roundNumber(cleanFloat(row.partTimeDailyAllowances) * cleanFloat(row.allowances.partTimeDailyAllowance), 2)
    } else {
      partTimeDailyAllowances = row.ptdaAmount ? roundNumber(cleanFloat(row.partTimeDailyAllowances) * cleanFloat(row.ptdaAmount), 2) : roundNumber(cleanFloat(row.partTimeDailyAllowances) * (cleanFloat(row.allowances.dailyAllowance[row.country]) / 2), 2)
    }
    mealExpences = row.meAmount ? roundNumber(cleanFloat(row.mealExpences) * cleanFloat(row.meAmount), 2) : roundNumber(cleanFloat(row.mealExpences) * cleanFloat(row.allowances.mealExpence), 2)
  }

  return roundNumber(travel + dailyAllowances + partTimeDailyAllowances + mealExpences, 2)
}

export const totalTravellingExpenceRowWithTax = row => {
  return roundNumber(totalTravellingExpenceRow(row) * (1 + parseVat(row.vat) / 100), 2)
}

export const taxesTravellingExpenceRow = row => {
  return roundNumber(totalTravellingExpenceRow(row) * (parseVat(row.vat) / 100), 2)
}

export const taxesMaterialExpenceRow = row => {
  return roundNumber(row.tax, 2)
}

const totalAccountingRow = row => {
  return roundNumber(cleanFloat(row.amount), 2)
}

const totalCorrectionsRow = row => {
  if (row.quantity && row.amount) {
    return roundNumber(cleanFloat(row.quantity) * cleanFloat(row.amount), 2)
  } else {
    return 0
  }
}

const totalCorrectionsRowVat = row => {
  //return cleanFloat(row.price) * (parseVat(row.vat) / 100) * cleanFloat(row.quantity)
  if (row.tax) {
    return roundNumber(cleanFloat(row.tax), 2)
  } else {
    return 0
  }
}

const totalCorrectionsRowWithVat = row => {
  //return cleanFloat(row.quantity) * cleanFloat(row.price) * (1 + parseVat(row.vat) / 100)
  if (row.total) {
    return roundNumber(cleanFloat(row.total), 2)
  } else {
    return 0
  }
}

export const invoiceCalculations = (values) => {
  const taxTypes = (values.invoiceRows && values.invoiceRows.length > 0 && Array.from(new Set(values.invoiceRows
      .map(row => parseVat(row.vat))
      .filter(type => type !== 0)
      .sort((a, b) => a - b))
  )) || []

  const highestTaxType = taxTypes.sort((a, b) => b - a)[0] || 0
  //cLog('TAX TYPES', taxTypes, highestTaxType)
  let hasNonZeroVats
  if (!values.exp) {
    const invoiceRowsNonZeroVats = values.invoiceRows.find(row => row.vat !== '0')
    //cLog('LASKURIVIEN EI-NOLLAVATIT', invoiceRowsNonZeroVats)
    const travellingRowsNonZeroVats = values.travellingExpenceRows.find(row => row.vat !== '0')
    //cLog('MATKARIVIEN EI-NOLLAVATIT', travellingRowsNonZeroVats)
    hasNonZeroVats = Boolean(invoiceRowsNonZeroVats) || Boolean(travellingRowsNonZeroVats)
    //cLog('HAS NON ZERO VATS', hasNonZeroVats)
  }

  const invoiceRowsTotalArray = (values.invoiceRows && values.invoiceRows    ///// LASKURIVIT ILMAN ALV
    .map(row => totalInvoiceRow(row))) || []
  const invoiceRowsTotal = (invoiceRowsTotalArray.length > 0 &&
    roundNumber(cleanFloat(invoiceRowsTotalArray.reduce((a, b) => a + b)), 2))
    || 0

  const invoiceRowsTotalWithTaxArray = (values.invoiceRows && values.invoiceRows  ///// LASKURIVIT ALVIN KANSSA  
    .map(row => totalInvoiceRowWithTax(row))) || []
  const invoiceRowsTotalWithTax = (invoiceRowsTotalWithTaxArray.length > 0 &&
    roundNumber(cleanFloat(invoiceRowsTotalWithTaxArray.reduce((a, b) => a + b)), 2))
    || 0

  const travellingExpenceRowsArray = (values.travellingExpenceRows && values.travellingExpenceRows ////MATKARIVIT ILMAN ALV
    .map(row => totalTravellingExpenceRow(row))) || []
  const travellingExpenceRowsTotal = (travellingExpenceRowsArray.length > 0 &&
    roundNumber(cleanFloat(travellingExpenceRowsArray.reduce((a, b) => a + b)), 2))
    || 0

  const travellingExpenceRowsWithTaxArray = (values.travellingExpenceRows && values.travellingExpenceRows ////MATKARIVIT ALVIN KANSSA
    .map(row => totalTravellingExpenceRowWithTax(row))) || []
  const travellingExpenceRowsTotalWithTax = (travellingExpenceRowsWithTaxArray.length > 0 &&
    roundNumber(cleanFloat(travellingExpenceRowsWithTaxArray.reduce((a, b) => a + b)), 2))
    || 0

  const materialExpenceRowsArray = (values.materialExpenceRows && values.materialExpenceRows //// KULURIVIT ILMAN ALV
    .map(row => cleanFloat(values.oldInvoiceTravelType1 ? row.sum : (values.exp ? row.priceWithTax : row.price)))) || []
  const materialExpenceRowsTotal = (materialExpenceRowsArray.length > 0 &&
    roundNumber(cleanFloat(materialExpenceRowsArray.reduce((a, b) => a + b)), 2))
    || 0

  const materialExpenceRowsWithTaxArray = (values.materialExpenceRows && values.materialExpenceRows ////KULURIVIT ALVIN KANSSA
    .map(row => cleanFloat(values.oldInvoiceTravelType1 ? row.sum : row.priceWithTax))) || []
  const materialExpenceRowsTotalWithTax = (materialExpenceRowsWithTaxArray.length > 0 &&
    roundNumber(cleanFloat(materialExpenceRowsWithTaxArray.reduce((a, b) => a + b)), 2))
    || 0

  const totalAccountingArray = (values.accounts && values.accounts ////TILITYSRIVIT ILMAN ALV
    .map(row => totalAccountingRow(row))) || []
  const totalAccounting = (totalAccountingArray.length > 0 &&
    roundNumber(cleanFloat(totalAccountingArray.reduce((a, b) => a + b)), 2))
    || 0

  const totalCorrectionsArray = (values.correctionRows && values.correctionRows ////MATKARIVIT ILMAN ALV
    .map(row => totalCorrectionsRow(row))) || []
  const totalCorrections = (totalCorrectionsArray.length > 0 &&
    roundNumber(cleanFloat(totalCorrectionsArray.reduce((a, b) => a + b)), 2))
    || 0

  const totalCorrectionsVatArray = (values.correctionRows && values.correctionRows ////KORJAUSRIVIT ILMAN ALV
    .map(row => totalCorrectionsRowVat(row))) || []
  const totalCorrectionsVat = (totalCorrectionsVatArray.length > 0 &&
    roundNumber(cleanFloat(totalCorrectionsVatArray.reduce((a, b) => a + b)), 2))
    || 0

  const totalCorrectionsWithVatArray = (values.correctionRows && values.correctionRows ////MATKARIVIT ILMAN ALV
    .map(row => totalCorrectionsRowWithVat(row))) || []
  const totalCorrectionsWithVat = (totalCorrectionsWithVatArray.length > 0 &&
    roundNumber(cleanFloat(totalCorrectionsWithVatArray.reduce((a, b) => a + b)), 2))
    || 0

  const invoiceRowsTaxTotalArray = (values.invoiceRows && values.invoiceRows    ///// LASKURIVIEN ALVIT YHTEENSÄ
    .map(row => taxesInvoiceRow(row))) || []
  const totalTaxesInvoiceRows = (invoiceRowsTaxTotalArray.length > 0 &&
    invoiceRowsTaxTotalArray.reduce((a, b) => a + b))
    || 0

  const travellingExpenceRowsTaxTotalArray = (values.travellingExpenceRows && values.travellingExpenceRows    ///// MATKARIVIEN ALVIT YHTEENSÄ
    .map(row => taxesTravellingExpenceRow(row))) || []
  const totalTaxesTravellingExpenceRows = (travellingExpenceRowsTaxTotalArray.length > 0 &&
    travellingExpenceRowsTaxTotalArray.reduce((a, b) => a + b))
    || 0

  const materialExpenceRowsTaxTotalArray = (values.materialExpenceRows && values.materialExpenceRows    ///// KULURIVIEN ALVIT YHTEENSÄ
    .map(row => taxesMaterialExpenceRow(row))) || []
  const totalTaxesMaterialExpenceRows = (materialExpenceRowsTaxTotalArray.length > 0 &&
    materialExpenceRowsTaxTotalArray.reduce((a, b) => a + b))
    || 0

  const tax = roundNumber(totalTaxesInvoiceRows + totalTaxesTravellingExpenceRows + totalTaxesMaterialExpenceRows, 2)      //// LASKURIVIEN JA MATKARIVIEN ALVIT YHTEENSÄ

  let origTotalVatExcluded = 0
  let origTotalVatExcludedEur = 0
  let taxEur = 0
  let totalWithTaxEur = 0
  let correctedInvoiceEur = 0

  if (!values.exp && values.currency !== 'EUR') {
    let exchangeRate = values.exchangeRate ? values.exchangeRate : 1

    origTotalVatExcluded = roundNumber(invoiceRowsTotal + travellingExpenceRowsTotal, 2)
    origTotalVatExcludedEur = roundNumber(origTotalVatExcluded / exchangeRate, 2)
    taxEur = roundNumber(tax / exchangeRate, 2)
    totalWithTaxEur = roundNumber((invoiceRowsTotalWithTax + travellingExpenceRowsTotalWithTax) / exchangeRate, 2)
    correctedInvoiceEur = roundNumber((invoiceRowsTotal / exchangeRate) + totalCorrections, 2)
  } else {
    origTotalVatExcluded = roundNumber(invoiceRowsTotal + travellingExpenceRowsTotal + materialExpenceRowsTotal, 2)
    origTotalVatExcludedEur = roundNumber(invoiceRowsTotal + travellingExpenceRowsTotal + materialExpenceRowsTotal, 2)
    taxEur = roundNumber(tax, 2)
    totalWithTaxEur = roundNumber(invoiceRowsTotalWithTax + travellingExpenceRowsTotalWithTax + materialExpenceRowsTotalWithTax, 2)
    correctedInvoiceEur = roundNumber(invoiceRowsTotal + totalCorrections, 2)
  }
  const totalCorrectedEur = roundNumber(origTotalVatExcludedEur + totalCorrections, 2)
  const totalCorrectedVatEur = roundNumber(taxEur + totalCorrectionsVat, 2)
  const totalCorrectedWithVatEur = roundNumber(totalWithTaxEur + totalCorrectionsWithVat, 2)
  let salariesPayableAmount = roundNumber(totalCorrectedEur - totalAccounting, 2)
  if (salariesPayableAmount < 0) {
    salariesPayableAmount = 0
  }

  const totalWithTax = roundNumber(invoiceRowsTotalWithTax + travellingExpenceRowsTotalWithTax + materialExpenceRowsTotalWithTax, 2)

  /* const vatArray = values.invoiceRows && values.invoiceRows
      .map(row => parseVat(row.vat)) || []
  const vatMean = vatArray.length > 0 &&
      vatArray.reduce((a, b) => a + b) / vatArray.length
      || 0 */
  const openBalance = roundNumber(totalCorrectedWithVatEur - (values.totalPaymentsWithTax ? values.totalPaymentsWithTax : 0), 2)

  return { invoiceRowsTotal, invoiceRowsTotalWithTax, travellingExpenceRowsTotal, materialExpenceRowsTotal, origTotalVatExcludedEur, highestTaxType, taxTypes, hasNonZeroVats, totalCorrectedEur, totalCorrectedVatEur, totalCorrectedWithVatEur, correctedInvoiceEur, salariesPayableAmount, tax, totalWithTax, totalAccounting, totalCorrections, totalCorrectionsVat, totalCorrectionsWithVat, origTotalVatExcluded, taxEur, totalWithTaxEur, openBalance }
}

export const INDUSTRIES = new Map([
  ["Asentaja", 4.2],
  ["Asiakaspalvelija", 2.0],
  ["Asiantuntija", 2.0],
  ["Audiovisuaaliset työt", 2.2],
  ["DJ", 2.0],
  ["Eläintenhoitotyöt", 3.5],
  ["Esiintyvä artisti", 2.0],
  ["Floristi", 2.4],
  ["Graafikko", 2.2],
  ["Hieroja", 2.4],
  ["Huoltotyöntekijä", 4.2],
  ["Insinööri", 2.2],
  ["IT-ala", 2.0],
  ["Kauneudenhoitoala", 3.5],
  ["Kengittäjä", 4.2],
  ["Kiinteistönhoitaja", 3.5],
  ["Koiratrimmaaja", 3.5],
  ["Konsultti", 2.0],
  ["Kouluttaja", 2.0],
  ["Kuljettaja", 5.0],
  ["Käsityöntekijä", 2.4],
  ["Kääntäjä", 2.0],
  ["Liikunnanohjaaja", 3.5],
  ["LVI-asentaja", 5.0],
  ["Maalari", 5.2],
  ["Maanmittaus", 2.7],
  ["Maatalouslomittaja", 4.2],
  ["Markkinoija", 2.0],
  ["Metsätyöt", 6.9],
  ["Muu", 4.0],
  ["Muurari", 6.9],
  ["Myyntiedustaja", 2.0],
  ["Nuohooja", 4.2],
  ["Opas", 2.0],
  ["Opettaja", 2.0],
  ["Promoottori", 2.0],
  ["Puuseppä", 5.0],
  ["Puutarhanhoitaja", 3.5],
  ["Rakennusalan työntekijä", 6.9],
  ["Rakennuskoneenkuljettaja", 6.9],
  ["Rakennussiivooja", 4.2],
  ["Ravintola-/elintarviketyöntekijä", 2.7],
  ["Siivousala", 3.5],
  ["Suunnittelija", 2.0],
  ["Sähköasentaja", 4.2],
  ["Taiteilija", 2.0],
  ["Tapahtumatyöntekijä", 2.0],
  ["Toimistotyöntekijä", 2.0],
  ["Toimittaja", 2.0],
  ["Tutkija", 2.0],
  ["Vaikuttaja", 2.0],
  ["Video-/valokuvaaja", 2.2]
])

export const EINVOICE_OPERATORS = ['Aktia (HELSFIHH)', 'Apix Messaging Oy (003723327487)', 'BasWare Einvoices Oy (BAWCFI22)', 'BasWare Oyj (003705925424)', 'Danske Bank Oyj (DABAFIHH)', 'InExchange Factorum AB (INEXCHANGE)', 'Maventa Services (003721291126)', 'Netbox Finland Oy (003726044706)', 'Nordea (NDEAFIHH)', 'Notebeat Oy (003717203971)', 'Open Text Oy (003708599126)', 'OP-Pohjola-ryhmä (OKOYFIHH)', 'OpusCapita Solutions Oy (E204503)', 'Pagero (003723609900)', 'Pagero (PAGERO)', 'PEPPOL (PEPPOL)', 'POP Pankit (POPFFI22)', 'Posti Messaging Oy (FI28768767)', 'PostNord Strålfors Oy (003701150617)', 'Ropo Capital Oy (ROPO)', 'Ropo Capital Oy (Enfo Oyj) (003714377140)', 'S-Pankki Oy (SBANFIHH)', 'Sampo Pankki Oyj (PSPBFIHH)', 'Svea rahoitus (SVEA)', 'Svenska Handelsbanken AB (HANDFIHH)', 'Säästöpankit (ITELFIHH)', 'Tapiola Pankki (TAPIFI22)', 'Telia / CGI (003703575029)', 'Tieto Oyj (003701011385)', 'Ålandsbanken Abp (AABAFI22)']

export const VATSTATUSES = ['KOTIMAA', 'EU_YHTEISÖMYYNTI_(TAVARA)', 'EU_YHTEISÖMYYNTI_(PALVELU)', '0_VEROKANNAN_ALAINEN_LIIKEVAIHTO', 'EU_KOLMIKANTAKAUPPA', 'EU:N_ULKOPUOLELLA', 'MUU_VEROTON_MYYNTI', 'MUU_VEROTON_MYYNTI_58', 'EI_ALV_KÄSITTELYÄ', 'RAKENNUSALAN_KÄÄNTEINEN_ALV_24%']

export const PAYMENT_TERMS_OPTIONS = [2, 7, 14, 30, 45]

export const CLIENT_FORM_TEMPLATE = {
  name: '',
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  country: 'FINLAND',
  email: '',
  contactPerson: '',
  phone: '',
  businessId: '',
  language: 'FINNISH',
  isPrivate: false,
  deliveryMethod: 'VERKKOLASKU',
  einvoiceOperator: '',
  einvoiceAddress: '',
  reminderHandling: 'AUTOMAATTINEN',
  hasCustomBusinessId: false,
  paymentTerms: 14,
  userId: ''
}

export const INVOICE_ROW_TEMPLATE = dateEndWork => {
  return ({
    content: '',
    vat: (useVat24(dateEndWork) ? '24' : '25,5'),
    quantity: 1,
    unit: 'PIECE',
    price: '',
    priceWithTax: '',
    tax: '',
    total: ''
  })
}

export const INVOICE_PAYMENT_TEMPLATE = invoice => {
  return ({
    txAmt: '',
    acctSvcrRef: '',
    valDt: new Date().toISOString().split('T')[0],
    notes: '',
    noAcctSvcrRef: false
  })
}

export const INVOICE_CORRECTION_ROW_TEMPLATE = invoice => {
  return ({
    notes: '',
    vat: (useVat24(invoice.dateEndWork) ? '24' : '25,5'),
    quantity: 1,
    unit: 'PIECE',
    amount: '',
    amountWithTax: '',
    tax: '',
    total: '',
    date: new Date().toISOString().split('T')[0]
  })
}

export const INVOICE_ACCOUNT_TEMPLATE = invoice => {
  return ({
    amount: '',
    date: new Date().toISOString().split('T')[0],
    notes: ''
  })
}

export const MATERIAL_EXPENCES_ROW_TEMPLATE = invoice => {
  return ({
    purpose: 'EXPENSE',
    explanation: '',
    sum: '',
    attachments: [],
    price: '',
    tax: '',
    priceWithTax: '',
    expenceVat: (useVat24(invoice.dateEndWork) ? '24' : '25,5')
  })
}

export const TRAVELLING_EXPENCES_ROW_TEMPLATE = invoice => {
  return ({
    route: '',
    departureDate: '',
    departureTime: '',
    returnDate: '',
    returnTime: '',
    km: '',
    dailyAllowances: '',
    partTimeDailyAllowances: '',
    mealExpences: '',
    vehicle: 'auto',
    mileage: '',
    country: 'FINLAND',
    registerNumber: '',
    total: '',
    allowances: {
      vehicle: {},
      dailyAllowance: {},
      partTimeDailyAllowance: {},
      mealExpence: {}
    },
    daAmount: '',
    ptdaAmount: '',
    meAmount: '',
    purpose: 'WORK',
    purposeNotes: '',
    vat: (useVat24(invoice.dateEndWork) ? '24' : '25,5')
  })
}

export const TRAVEL_ROW_DESTINATIONS_TEMPLATE = {
  destination: '',
  km: 0,
  kmGoogle: 0,
  verified: false
}

export const TRAVELLING_EXPENCES_ROW_GOOGLE_MAPS_TEMPLATE = invoice => {
  return ({
    route: '',
    departureDate: '',
    departureTime: '',
    returnDate: '',
    returnTime: '',
    km: '',
    dailyAllowances: '',
    partTimeDailyAllowances: '',
    mealExpences: '',
    vehicle: 'auto',
    mileage: '',
    country: 'FINLAND',
    registerNumber: '',
    total: '',
    allowances: {
      vehicle: {},
      dailyAllowance: {},
      partTimeDailyAllowance: {},
      mealExpence: {}
    },
    daAmount: '',
    ptdaAmount: '',
    meAmount: '',
    purpose: 'WORK',
    purposeNotes: '',
    vat: (useVat24(invoice.dateEndWork) ? '24' : '25,5'),
    travelType: '',
    travelRowDestinations: [{ ...TRAVEL_ROW_DESTINATIONS_TEMPLATE, order: 0 }, { ...TRAVEL_ROW_DESTINATIONS_TEMPLATE, order: 1 }]
  })
}

export const INITIAL_VALUES = exp => {
  const vat24 = useVat24(null)

  if (!exp) {
    return (
      {
        date: getDateNow(),
        dateDue: getDateIn2weeks(),
        dateStartWork: '',
        dateEndWork: '',
        refText: '',
        currency: 'EUR',
        payDay: 'KUN_MAKSETTU',
        userId: undefined,
        client: null,
        travellingExpenceRows: [],
        invoiceRows: [],
        materialExpenceRows: [],
        attachments: [],
        payments: [],
        correctionRows: [],
        status: 'LUONNOS',
        invoiceTotal: 0,
        travelTotal: 0,
        expenceTotal: 0,
        tax: 0,
        totalWithTax: 0,
        internalNotes: '',
        industry: '',
        customIndustry: '',
        info: '',
        worklists: [],
        deliveryMethod: 'VERKKOLASKU',
        einvoiceOperator: '',
        einvoiceAddress: '',
        reminderHandling: 'AUTOMAATTINEN',
        vatStatus: 'KOTIMAA',
        additionalInformation: '',
        hasCustomTravelVats: false,
        hasTravelRows: false,
        hasMaterialRows: false,
        marketingName: '',
        adminInfo: '',
        discountCode: '',
        accountingStatus: 'TILITTÄMÄTÖN',
        yel: undefined,
        userFirstName: '',
        userLastName: '',
        oldInvoiceTravelType1: false,
        oldInvoiceTravelType2: false,
        oldInvoiceGoogleMaps: false,
        vat24: vat24
      }
    )
  } else {
    return (
      {
        date: getDateNow(),
        userId: undefined,
        travellingExpenceRows: [],
        materialExpenceRows: [],
        attachments: [],
        status: 'LUONNOS',
        exp: true,
        travelTotal: 0,
        expenceTotal: 0,
        totalWithTax: 0,
        tax: 0,
        adminInfo: '',
        info: '',
        userFirstName: '',
        userLastName: '',
        oldInvoiceTravelType1: false,
        oldInvoiceTravelType2: false,
        oldInvoiceGoogleMaps: false,
        vat24: vat24
      }
    )
  }
}

export const worklistToString = (worklist) => {
  let list = 'TYÖLISTA: ' + worklist.name + '\n'
  //list += 'ASIAKAS: ' + (client && client.name || 'Asiakas poistettu') + '\n'
  list += 'TYÖLISTAN RIVIT\n'
  list += 'Päiväys Tehtävä Tunnit\n'
  worklist.worklistRows.forEach(row => list += formatTimeFI(row.date) + ' ' + row.task + ' ' + row.hours + '\n')
  list += 'YHTEENSÄ: ' + worklist.totalHours + ' tuntia\n'

  cLog('WORK LIST TO STRING LENGTH', list.length)
  return list
}

export function formatRefCode(value) {
  if (value && typeof value === "string") {
    var parts = value.split("").reverse().join("").match(/.{1,5}/g)
    var new_value = parts.join(" ")
    return new_value.split("").reverse().join("")
  }

  return ''
}
