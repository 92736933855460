import React from 'react'
import { Field, FieldArray } from 'formik'
//import { cLog } from '../../utils'
import Input from '../../components/Input'
import DropDown from '../../components/DropDown'
import { Grid } from '@material-ui/core'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
import { useTranslation } from 'react-i18next'
import { useStateValue } from "../../state"
import { APIdomain } from '../../service'
import DownloadOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import { Label } from '../../components/Label'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import { validateFloatBetween } from '../Invoice/validationSchemas'

const TaxcardRow = ({
  taxcards,
  setFieldValue,
  //reactive,
  expanded,
  handleChangeExpansion,
  removeRow,
  dirty
}) => {
  const { t } = useTranslation()
  const [{ currentUser }] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [isDirty, setIsDirty] = React.useState({})

  function convertDate(dateString) {
    var p = dateString.split(/\D/g)
    return [p[2],p[1],p[0] ].join(".")
  }

  const validateDateStart = (value, i) => {
    if (isDirty[i] && (!value || String(value).length === 0)) {
      return { key: t('user.dateStartMissing') }
    }
  }
  
  const validateComments = (value, i) => {
    if (isDirty[i] && value && String(value).trim().length > 80) {
      return {key: t('user.commentTooLong')}
    }
  }

  const validateCardType = (value, i) => {
    if (isDirty[i] && !value) {
      return {key: t('user.noCardType')}
    }
  } 
  
  const checkTaxcardRowErrors = (row, i) => {
    if (validateDateStart(row.dateStart, i)
      || validateFloatBetween(row.base, 0, 99.99, t('user.base'), isDirty[i], 2)
      || validateFloatBetween(row.additional, 0, 99.99, t('user.additional'), isDirty[i], 2)
      || validateCardType(row.cardType, i)
      || validateFloatBetween(row.annualIncomeLimit, 0, 9999999.99, t('user.annualIncomeLimit'), isDirty[i], 2)
      || validateComments(row.comments, i)
    ) {
      return false
    }
    return true
  }

  const cardTypeOptions = ['Yksi tuloraja', 'Lähdeverokortti'].map(type => ({ label: t('user.'+type), value: type }))

  const revisedOptions = ['Kyllä', 'Ei'].map(item => ({ label: t('user.'+item), value: item === 'Kyllä' ? true : false }))

  return (
    <div>
      <FieldArray
        name="taxcards"
        validateOnChange={false}
        validateOnBlur={false}
        render={arrayHelpers => {
          return (
            <div>
              {taxcards.map((row, i) => {
                const errors = arrayHelpers.form.errors.taxcards ? arrayHelpers.form.errors.taxcards[i] : {}
                const taxcardRowHasErrors = errors ? Object.keys(errors).length > 0 : false

                return (
                  <div id={`tRow${i}`} key={i}>
                    <Hanuri>
                      <HanuriTitle
                        expanded={expanded === `taxcards.${i}`}
                        id={`taxcards.${i}`}
                        open={handleChangeExpansion(`taxcards.${i}`)}
                        // remove={() => {
                        //     removeRow('taxcards', i)
                        //     arrayHelpers.remove(i)
                        // }}
                        label={t('user.taxcardRow') + ' ' + (i+1) + ' ' + (i === 0 ? t('user.newestTaxcard') + ' (' + convertDate(taxcards[i].dateStart) + ')' : ' (' + convertDate(taxcards[i].dateStart) + ')')}
                        done={checkTaxcardRowErrors(row, i)}
                        error={taxcardRowHasErrors && !checkTaxcardRowErrors(row, i)}
                      />

                      <HanuriBody expanded={expanded === `taxcards.${i}`}>
                        {isAdmin && taxcards && <div>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].dateStart`}
                                id={`taxcards[${i}].dateStart`}
                                label={t('taxcard.validFrom')+' *'}
                                component={DPicker}
                                selected={taxcards[i].dateStart ? new Date(taxcards[i].dateStart) : ''}
                                onChange={(date) => {
                                  setFieldValue(`taxcards[${i}].dateStart`, date ? formatDate(date) : '')
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                validate={() => validateDateStart(row.dateStart, i)}
                                error={errors && errors.dateStart && validateDateStart(row.dateStart, i)}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].base`}
                                id={`taxcards[${i}].base`}
                                label={t('user.base')}
                                type='text'
                                component={Input}
                                value={taxcards[i].base || '0'}
                                onChange={(e) => {
                                  setFieldValue(`taxcards[${i}].base`, e.target.value)
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                validate={() => validateFloatBetween(row.base, 0, 99.99, t('user.base'), isDirty[i], 2)}
                                error={errors && errors.base && validateFloatBetween(row.base, 0, 99.99, t('user.base'), isDirty[i], 2)}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].additional`}
                                id={`taxcards[${i}].additional`}
                                label={t('user.additional')}
                                type='text'
                                component={Input}
                                value={taxcards[i].additional || '0'}
                                onChange={(e) => {
                                  setFieldValue(`taxcards[${i}].additional`, e.target.value)
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                validate={() => validateFloatBetween(row.additional, 0, 99.99, t('user.additional'), isDirty[i], 2)}
                                error={errors && errors.additional && validateFloatBetween(row.additional, 0, 99.99, t('user.additional'), isDirty[i], 2)}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].cardType`}
                                id={`taxcards[${i}].cardType`}
                                label={t('user.cardType')}
                                component={DropDown}
                                placeholder={t('invoice.pick')}
                                defaultValue={cardTypeOptions.find(option => option.value === taxcards[i].cardType)}
                                onChange={(e) => {
                                  setFieldValue(`taxcards[${i}].cardType`, e.value)
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                options={cardTypeOptions}
                                validate={() => validateCardType(row.cardType, i)}
                                error={errors && errors.cardType && validateCardType(row.cardType, i)}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].revisedCard`}
                                id={`taxcards[${i}].revisedCard`}
                                label={t('user.revisedCard')}
                                component={DropDown}
                                defaultValue={revisedOptions.find(option => option.value === taxcards[i].revisedCard)}
                                onChange={(e) => {
                                  setFieldValue(`taxcards[${i}].revisedCard`, e.value)
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                options={revisedOptions}
                              />
                            </Grid>
                    
                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].annualIncomeLimit`}
                                id={`taxcards[${i}].annualIncomeLimit`}
                                label={t('user.annualIncomeLimit')}
                                type='text'
                                component={Input}
                                value={taxcards[i].annualIncomeLimit || '0'}
                                onChange={(e) => {
                                  setFieldValue(`taxcards[${i}].annualIncomeLimit`, e.target.value)
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                validate={() => validateFloatBetween(row.annualIncomeLimit, 0, 9999999.99, t('user.annualIncomeLimit'), isDirty[i], 2)}
                                error={errors && errors.annualIncomeLimit && validateFloatBetween(row.annualIncomeLimit, 0, 9999999.99, t('user.annualIncomeLimit'), isDirty[i], 2)}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`taxcards[${i}].comments`}
                                id={`taxcards[${i}].comments`}
                                label={t('user.comments')}
                                type='text'
                                component={Input}
                                value={taxcards[i].comments || ''}
                                onChange={(e) => {
                                  setFieldValue(`taxcards[${i}].comments`, e.target.value)
                                  setIsDirty({ ...isDirty, [i]: true })
                                }}
                                validate={() => validateComments(row.comments, i)}
                                error={errors && errors.comments && validateComments(row.comments, i)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Label>
                                <div style={{ display: "flex", "justifyContent": "space-between" }}>{t('invoice.attachments')}</div>
                                {row.attachments.map((attachment, a) => {
                                  const attachmentUrl = attachment.id ? APIdomain + attachment.url : attachment.url
                                  return <div style={{ "paddingTop": "1rem" }} key={a}><a href={attachmentUrl} target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}><DownloadOutlinedIcon fontSize='large' /></a></div>
                                })}
                              </Label>
                            </Grid>
                          </Grid>
                        </div>}
                      </HanuriBody>
                    </Hanuri>
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    </div>
  )
}

export default TaxcardRow
