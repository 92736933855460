import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Button from './Button'

function ConfirmTravelExpensesDialog({ open, setOpen, saveForm, setLoading }) {
  const { t } = useTranslation()
  const [isLoadingModal, setLoadingModal] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setLoadingModal(false)
          setOpen(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="confirm-travel-expenses-dialog-title"
      aria-describedby="confirm-travel-expenses-dialog-description"
    >
      <DialogTitle id="confirm-travel-expenses-dialog-title">{t('invoice.confirmTravelExpensesDialogTitle')}</DialogTitle>
      <DialogContent dividers={true} id="confirm-travel-expenses-dialog-description">
        <span style={{display: 'block', paddingTop: '15px', paddingBottom: '15px'}}>{t('invoice.confirmTravelExpensesDialogBody')}</span>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false)
            setLoading(false)
            setLoadingModal(false)
          }}
          color="secondary"
          disabled={isLoadingModal}
        >
          {t('frontPage.cancelButton')}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            setLoadingModal(true)
            setOpen(false)
            saveForm(true, true)
          }}
          color="primary"
          disabled={isLoadingModal}
        >
          {isLoadingModal ? <CircularProgress size={20} /> : t('auth.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmTravelExpensesDialog
