import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import { APIdomain } from '../service';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { formatTimeFI } from '../utils';

var cardStyle = {
  transitionDuration: '0.3s',
  height: '100%',
  display: "flex",
  width: '100%',
  justifyContent: "start", 
  flexDirection: "column", 
  flexWrap: "wrap"
}

const handleClick = (url) => {
  window.open(url, '_blank');
}

export default function PromoCard({ item }) {

  const { t, i18n } = useTranslation();
  let language = i18n.language

  return (
    <Card style={cardStyle}>
      <CardMedia
        style={{backgroundColor: 'rgb(129,36,227)', objectFit: 'contain'}}
        component="img"
        height="40%"
        image={APIdomain+item.attachments[0].url}
        alt="promo picture"
      />
      <CardContent style={{overflow: 'auto', height: '40%'}}>

        <Typography style={{whiteSpace: 'pre-line'}}>
          {language === 'fi' ? item.finDescription : item.enDescription} 
        </Typography>

        {item.code && <Typography>
        {t('promocodes.withCode')+item.code}
        </Typography>}

        {item.expires && <Typography>
        {t('promocodes.expires')+': '+formatTimeFI(item.expires)}
        </Typography>}

      </CardContent>
      {item.url && <CardActions style={{margin: "auto 0 0", height: '20%'}}>
        <Button onClick={() => handleClick(item.url)}>{t('promocodes.claimPromotion')}</Button>
      </CardActions>}
    </Card>
  );
}