import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Link } from '@material-ui/core'
import { useStateValue, setCurrentUser, setClients, setInvoices, setExpences, setReferences, setWorklists, setTaxcards } from "../state"
import { withSnackbar } from 'notistack'
import { requestNewEmailConfirmation, confirmUserEmail, setUserData } from '../service'
import Input from './Input'
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import { logOut } from '../service'
import Button from './Button'

const INIT_FORM = {
  auth_code: ''
}

function ConfirmEmailDialog({ open, setOpenConfirmEmailDialog, enqueueSnackbar }) {
  const { t } = useTranslation()
  const [form, setForm] = useState(INIT_FORM)
  const [isLoading, setLoading] = useState(false)
  const [{ currentUser }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  const clearState = () => {
    dispatch(setClients([]))
    dispatch(setInvoices([]))
    dispatch(setExpences([]))
    dispatch(setReferences([]))
    dispatch(setWorklists([]))
    if (isAdmin) {
      dispatch(setTaxcards([]))
    }
  }

  const handleConfirmEmail = async () => {
    if (validateCode(form.auth_code)) {
      try {
        setLoading(true)
        const response = await confirmUserEmail(form, currentUser.id)
        cLog('EMAIL VAHVISTETTU', response)
        setUserData(response.data)
        dispatch(setCurrentUser(response.data))
        setOpenConfirmEmailDialog(false)
        setLoading(false)
        enqueueSnackbar(
          t('auth.emailConfirmed'),
          { variant: 'success' }
        )
      } catch (error) {
        setLoading(false)
        cLog('EMAIL CONFIRM ERROR', error)
        if (error.response.data.errors[0] === "Confirmation code is not valid") {
          enqueueSnackbar(
            t('auth.confirmationCodeNotValid'),
            { variant: 'error' }
          )
        } else {
          enqueueSnackbar(
            t('auth.emailConfirmedError'),
            { variant: 'error' }
          )
        }
      }
    }
  }

  const handleCloseAndLogout = async () => {
    cLog('LOGGING OUT!')
    clearState()
    setOpenConfirmEmailDialog(false)
    dispatch(setCurrentUser(null))
    logOut()
  }

  const requestNewConfirmation = async () => {
    try {
      setLoading(true)
      const response = await requestNewEmailConfirmation(currentUser.id)
      cLog('EMAIL VAHVISTUSVIESTI LÄHETETTY', response)
      setLoading(false)
      enqueueSnackbar(
        t('auth.confirmationSent'),
        { variant: 'success' }
      )
    } catch (error) {
      setLoading(false)
      cLog('EMAIL VAHVISTUSVIESTI ERROR', error)
      enqueueSnackbar(
        t('auth.confirmationSentError'),
        { variant: 'error' }
      )
    }
  };

  const validateCode = value => {
    //test for any value
    if (!String(value).trim()) {
      enqueueSnackbar(
        t('auth.noCode'),
        { variant: 'error' }
      )
      return
    }

    //test if numbers only
    if (!/^\d+$/.test(value)) {
      enqueueSnackbar(
        t('auth.wrongCode'),
        { variant: 'error' }
      )
      return
    }
    //test if value is exactly 6
    if (String(value).trim().length !== 6) {
      enqueueSnackbar(
        t('auth.codeLengthError'),
        { variant: 'error' }
      )
      return 
    }

    return true
  }

  return (
    <Dialog
      scroll='paper'
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setForm(INIT_FORM)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="confirm-email-dialog-title"
      aria-describedby="confirm-email-dialog-description"
    >
      <DialogTitle id="confirm-email-dialog-title">{t('auth.confirmEmailTitle')}</DialogTitle>
      <DialogContent dividers={true} id="confirm-email-dialog-description">
        <Input
          value={form.auth_token}
          onChange={(e) => setForm({ ...form, auth_code: e.target.value })}
          label={t('auth.confirmEmailLabel')}
          placeholder={t('auth.confirmationCode')} />
        <Link style={{color: 'blue', cursor: 'pointer', marginTop: '15px', display: 'block'}} onClick={requestNewConfirmation} variant="body2">
          {t('auth.noConfirmation')}
        </Link>
        <span style={{display: 'block', paddingTop: '5px'}}>{t('auth.activeCode')}</span>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleCloseAndLogout}
          color="secondary"
          disabled={isLoading}
        >
          {t('auth.logOut')}
        </Button>
        <Button
          variant="text"
          onClick={handleConfirmEmail}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('auth.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(ConfirmEmailDialog)
