import React from 'react'
import { Field, FieldArray } from 'formik';
import { checkCorrectionRowErrors, validateFloat, validateDate, validateTextArea } from './validationSchemas'
import { cleanFloat, parseVat } from '../../utils/invoiceUtils'
import Input from '../../components/Input'
import { Grid, useMediaQuery } from '@material-ui/core'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
//import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import DropDown from '../../components/DropDown'
import { useTheme } from '@material-ui/core/styles'
import { roundNumber, UNITS } from '../../utils/index'

const CorrectionRow = ({
  correctionRows,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow,
  vat24
}) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = React.useState({})
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let allVats = ['25,5', '24', '14', '10', '0']
  if (vat24) {
    allVats = ['24', '14', '10', '0']
  } else {
    allVats = ['25,5', '14', '10', '0']
  }
  const vatOptions = allVats.map(vat => {
      return ({ label: vat + ' %', value: vat })
    })

  const unitOptions = Object.keys(UNITS).map(unit => ({ label: t('invoice.'+unit), value: unit }))

  const reactToQuantity = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const amountTotal = roundNumber(cleanFloat(changedValue) * cleanFloat(row.amount), 2)
      const total = roundNumber(amountTotal * (1 + parseVat(row.vat) / 100), 2)
      setFieldValue(`correctionRows.${i}.tax`, roundNumber(total - amountTotal, 2))
      setFieldValue(`correctionRows.${i}.total`, total)
    } else {
      setFieldValue(`correctionRows.${i}.total`, '0.00')
      setFieldValue(`correctionRows.${i}.tax`, '0.00')
    }
  }
  const reactToamount = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const amountTotal = roundNumber(cleanFloat(row.quantity) * cleanFloat(changedValue), 2)
      const total = roundNumber(amountTotal * (1 + parseVat(row.vat) / 100), 2)
      setFieldValue(`correctionRows.${i}.amountWithTax`, roundNumber(cleanFloat(changedValue) * (1 + parseVat(row.vat) / 100), 2))
      setFieldValue(`correctionRows.${i}.tax`, roundNumber(total - amountTotal, 2))
      setFieldValue(`correctionRows.${i}.total`, total)
    } else {
      setFieldValue(`correctionRows.${i}.amountWithTax`, '0.00')
      setFieldValue(`correctionRows.${i}.tax`, '0.00')
      setFieldValue(`correctionRows.${i}.total`, '0.00')
    }
  }
  const reactToAlv = (row, i, changedValue) => {
    const amountTotal = roundNumber(cleanFloat(row.quantity) * cleanFloat(row.amount), 2)
    const total = roundNumber(amountTotal * (1 + parseVat(changedValue) / 100), 2)
    setFieldValue(`correctionRows.${i}.amountWithTax`, roundNumber(cleanFloat(row.amount) * (1 + parseVat(changedValue) / 100), 2))
    setFieldValue(`correctionRows.${i}.tax`, roundNumber(total - amountTotal, 2))
    setFieldValue(`correctionRows.${i}.total`, total)
  }
  const reactToamountWithALV = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const amount = roundNumber(cleanFloat(changedValue) / (1 + parseVat(row.vat) / 100), 2)
      const amountTotal = roundNumber(cleanFloat(row.quantity) * amount, 2)
      const total = roundNumber(cleanFloat(row.quantity) * cleanFloat(changedValue), 2)
      setFieldValue(`correctionRows.${i}.amount`, amount)
      setFieldValue(`correctionRows.${i}.tax`, roundNumber(total - amountTotal, 2))
      setFieldValue(`correctionRows.${i}.total`, total)
    } else {
      setFieldValue(`correctionRows.${i}.amount`, '0.00')
      setFieldValue(`correctionRows.${i}.total`, '0.00')
      setFieldValue(`correctionRows.${i}.tax`, '0.00')
    }
  }
  
  return (
    <FieldArray
      name="correctionRows"
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        return (
          <>
            {correctionRows.map((row, i) => {
              const errors = arrayHelpers.form.errors.correctionRows ? arrayHelpers.form.errors.correctionRows[i] : {}
              const correctionRowHasErrors = errors ? Object.keys(errors).length > 0 : false

              //cLog('CORRECTION ROW ERRORS', errors)

              return (
                <div id={`cRows${i}`} key={i}>
                  <Hanuri>
                    <HanuriTitle
                    expanded={expanded === `correctionRows.${i}`}
                      id={`correctionRows.${i}`}
                      open={handleChangeExpansion(`correctionRows.${i}`)}
                      remove={() => {
                        removeRow('correctionRows', i)
                        arrayHelpers.remove(i)
                      }}
                      label={t('invoice.correctionRow')+' '+(i+1)}
                      done={checkCorrectionRowErrors(row)}
                      error={correctionRowHasErrors && !checkCorrectionRowErrors(row)}
                    />
                    <HanuriBody expanded={expanded === `correctionRows.${i}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={8}>
                          <Field
                            name={`correctionRows.${i}.notes`}
                            id={`correctionRows.${i}.notes`}
                            label={t('invoice.notes')+' *'}
                            type="text"
                            component={Input}
                            value={correctionRows[i].notes || ''}
                            onChange={(e) => {
                              setFieldValue(`correctionRows.${i}.notes`, e.target.value)
                            }}
                            validate={() => validateTextArea(row.notes, t('invoice.notes'), 10000, true)}
                            error={errors && errors.notes && validateTextArea(row.notes, t('invoice.notes'), 10000, true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`correctionRows.${i}.date`}
                            id={`correctionRows.${i}.date`}
                            label={t('invoice.date')+' *'}
                            component={DPicker}
                            selected={correctionRows[i].date ? new Date(correctionRows[i].date) : ''}
                            onChange={(date) => setFieldValue(`correctionRows.${i}.date`, date ? formatDate(date) : '')}
                            validate={() => validateDate(row.date, t('invoice.date'), true)}
                            error={errors && errors.date && validateDate(row.date, t('invoice.date'), true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`correctionRows.${i}.quantity`}
                            id={`correctionRows.${i}.quantity`}
                            label={t('invoice.quantity') + ' *'}
                            type='text'
                            component={Input}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], quantity: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            value={correctionRows[i].quantity}
                            onChange={(e) => {
                              setFieldValue(`correctionRows.${i}.quantity`, e.target.value)
                              if (isFocused[i].quantity) {
                                reactToQuantity(row, i, e.target.value)
                              }
                            }}
                            validate={() => validateFloat(row.quantity, t('invoice.quantity'), false, 2)}
                            error={errors && errors.quantity && validateFloat(row.quantity, t('invoice.quantity'), false, 2)}
                         />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`correctionRows.${i}.unit`}
                            id={`correctionRows.${i}.unit`}
                            label={t('invoice.unit')}
                            options={unitOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            onChange={(e) => setFieldValue(`correctionRows.${i}.unit`, e.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`correctionRows.${i}.vat`}
                            id={`correctionRows.${i}.vat`}
                            label={t('invoice.vat')}
                            options={vatOptions}
                            component={DropDown}
                            onChange={(e) => {
                              setFieldValue(`correctionRows.${i}.vat`, e.value)
                              reactToAlv(row, i, e.value)
                            }}
                            defaultValue={vatOptions[0]}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], vat: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`correctionRows.${i}.amount`}
                            id={`correctionRows.${i}.amount`}
                            label={t('invoice.sum')+' *'}
                            type="text"
                            component={Input}
                            value={row.amount}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], amount: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`correctionRows.${i}.amount`, e.target.value)
                              if (isFocused[i].amount) {
                                reactToamount(row, i, e.target.value)
                              }
                            }}
                            validate={() => validateFloat(row.amount, t('invoice.sum'), true)}
                            error={errors && errors.amount && validateFloat(row.amount, t('invoice.sum'), true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`correctionRows.${i}.amountWithTax`}
                            id={`correctionRows.${i}.amountWithTax`}
                            label={t('invoice.amountWithTax')+' *'}
                            type="text"
                            component={Input}
                            value={row.amountWithTax}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], amountWithTax: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`correctionRows.${i}.amountWithTax`, e.target.value)
                              if (isFocused[i].amountWithTax) {
                                reactToamountWithALV(row, i, e.target.value)
                              }
                            }}
                            validate={() => validateFloat(row.amountWithTax, t('invoice.amountWithTax'), true)}
                            error={errors && errors.amountWithTax && validateFloat(row.amountWithTax, t('invoice.amountWithTax'), true)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`correctionRows.${i}.tax`}
                            id={`correctionRows.${i}.tax`}
                            label={t('invoice.taxInput')}
                            type='text'
                            component={Input}
                            value={row.tax}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Field
                            name={`correctionRows.${i}.total`}
                            id={`correctionRows.${i}.total`}
                            label={t('invoice.total')}
                            type='text'
                            component={Input}
                            value={row.total}
                            disabled={true}
                          />
                        </Grid>
                      </Grid>
                    </HanuriBody>
                  </Hanuri>
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}

export default CorrectionRow
