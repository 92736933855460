import axios from 'axios'
import { apiBaseUrlAPI, frontVersion } from './apiBaseUrl'
import { token } from './authService'
import { cLog } from '../utils'
import request from './request'

export const saveAttachment = async ({ recordType, recordId, file, dateStart, dateEnd, taxcardType }) => {
  cLog('RECORD TYPE', recordType, 'RECORD ID', recordId)

  const formData = new FormData();
  formData.append('record_type', recordType);
  formData.append('record_id', recordId);
  formData.append('files[]', file);  

  if (recordType === 'Taxcard') {
    formData.append('date_start', dateStart);
  }

  const response = await axios({
    method: 'POST',
    url: apiBaseUrlAPI + '/attachments',
    data: formData,
    processData: false,
    contentType: false,
    headers: {
      'Authorization': token,
      'Client-Version': frontVersion
    }
  })
  cLog('SAVE ATTACHMENT RESPONSE IN FILE SERVICE', response)
  return response
}

export const deleteAttachment = async (id) => {
  const response = await axios({
    method: 'DELETE',
    url: apiBaseUrlAPI + '/attachments/' + id,
    data: id,
    headers: {
      'Authorization': token,
      'Client-Version': frontVersion
    }
})
  return response
}

export const getBackgroundJob = async (id) => {
  const response = await request('GET', '/background_jobs/' + id)
  return response
}

export const csvUpload = async (data) => {
  var formData = new FormData();
  formData.append("file", data.file.file);
  formData.append("file_type", data.fileType);

  const { data: csvResponse } = await request('POST', `/csv_import`, formData)
  return csvResponse
}
