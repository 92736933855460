import React from 'react'
import { Field, FieldArray } from 'formik'
import Input from '../../components/Input'
import { Grid } from '@material-ui/core'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import { validateDate, validateTask, validateHours, checkWorklistRowErrors } from './worklistValidations'

export const WORKLIST_ROW_TEMPLATE = {
  date: formatDate(new Date()),
  task: '',
  hours: ''
}

const WorklistRow = ({
  worklistRows,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow,
  values
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <FieldArray
        name="worklistRows"
        validateOnChange={false}
        validateOnBlur={false}
        render={arrayHelpers => {
          return (
            <div>
              {worklistRows.map((row, i) => {
                const errors = arrayHelpers.form.errors.worklistRows ? arrayHelpers.form.errors.worklistRows[i] : {}
                let worklistRowHasErrors = false
                if (errors) {
                  worklistRowHasErrors = Object.keys(errors).length > 0
                }

                return (
                  <div id={`wRow${i}`} key={i}>
                    <Hanuri>
                      <HanuriTitle
                        expanded={expanded === `worklistRows.${i}`}
                        id={`worklistRows.${i}`}
                        open={handleChangeExpansion(`worklistRows.${i}`)}
                        remove={() => {
                          removeRow('worklistRows', i)
                          arrayHelpers.remove(i)
                        }}
                        showRemove={values.status === 'LUONNOS'}
                        label={t('worklist.worklistRow') + ' ' + (i+1)}
                        done={checkWorklistRowErrors(row)}
                        error={worklistRowHasErrors && !checkWorklistRowErrors(row)}
                      />

                      <HanuriBody expanded={expanded === `worklistRows.${i}`}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={4}>
                            <Field
                              name={`worklistRows[${i}].date`}
                              id={`worklistRows[${i}].date`}
                              label={t('worklist.date')}
                              required
                              component={DPicker}
                              disabled={values.status !== 'LUONNOS'}
                              validate={() => validateDate(worklistRows[i].date)}
                              error={errors && validateDate(worklistRows[i].date)}
                              selected={worklistRows[i].date ? new Date(worklistRows[i].date) : ''}
                              onChange={(date) => setFieldValue(`worklistRows[${i}].date`, date ? formatDate(date) : '') }
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              name={`worklistRows[${i}].task`}
                              id={`worklistRows[${i}].task`}
                              label={t('worklist.task')}
                              required
                              value={worklistRows[i].task || ''}
                              onChange={(e) => { setFieldValue(`worklistRows[${i}].task`, e.target.value)}}
                              component={Input}
                              disabled={values.status !== 'LUONNOS'}
                              validate={() => validateTask(worklistRows[i].task)}
                              error={errors && errors.task && validateTask(worklistRows[i].task)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Field
                              name={`worklistRows[${i}].hours`}
                              id={`worklistRows[${i}].hours`}
                              label={t('worklist.hours')}
                              required
                              value={worklistRows[i].hours || ''}
                              onChange={(e) => { setFieldValue(`worklistRows[${i}].hours`, e.target.value) }}
                              component={Input}
                              disabled={values.status !== 'LUONNOS'}
                              validate={() => validateHours(worklistRows[i].hours)}
                              error={errors && errors.hours && validateHours(worklistRows[i].hours)}
                            />
                          </Grid>
                        </Grid>
                      </HanuriBody>
                    </Hanuri>
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    </div>
  )
}

export default WorklistRow
