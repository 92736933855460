

export const LANGUAGES = {
    Englanti: 'ENGLISH',
    Suomi: 'FINNISH',
    Ruotsi: 'SWEDISH',
}





export const LANGUAGES1 = {
    ENGLISH: 'Englanti',
    FINNISH: 'Suomi',
    SWEDISH: 'Ruotsi',
    ESTONIAN: 'Eesti',
    NORWEGIAN: 'Norja',
    DANISH: 'Tanska'
} 