import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { CircularProgress } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useStateValue, setCurrentUser } from "../../state"
import { useHistory } from 'react-router-dom'
import { signIn, getUser, setUserData } from '../../service'
import { COLORS } from '../../styles'
import { withSnackbar } from 'notistack'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import AuthLayoutContainer from './AuthLayoutContainer'
import AuthForm from './AuthForm'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.primary
  },
  link: {
    cursor: 'pointer',
    padding: '10px'
  }
}))

const SignIn = ({ enqueueSnackbar }) => {
  const [{ currentUser }, dispatch] = useStateValue();
  const [isLoading, setLoading] = useState(false)
  const classes = useStyles()
  let history = useHistory()
  const { t } = useTranslation()
  //const paramsEmail = new URLSearchParams(window.location.search).get('email');

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    if (currentUser) {
      history.push('/')
    }
  }, [currentUser, history])

  const handleSubmit = async (e) => {
    setLoading(true)
    const email = e.target.email.value
    const password = e.target.password.value
    e.preventDefault()
    const userId = signIn({ email, password })
    cLog('KIRJAUTUMINEN ALOITETTU', userId)
    userId.then(userId => {
      const loggedCurrentUser = getUser(userId)
      loggedCurrentUser.then(userData => {
        setUserData(userData)
        dispatch(setCurrentUser(userData))
        cLog('LOGGED CURRENT USER', userData)
        //setLoading(false)
        history.push('/')
      }, error => {
        cLog('LOGIN ERROR', error)
        enqueueSnackbar(
          t('auth.signInError'),
          { variant: 'error' }
        )
        setLoading(false)
      })
    }, error => {
      setLoading(false)
      const err = error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0] ? error.response.data.errors[0] : error
      cLog('LOGIN ERROR', err)
      if (err === 'invalidRole') {
        enqueueSnackbar(
          t('auth.invalidRole'),
          { variant: 'error' }
        )
      } else {
        enqueueSnackbar(
          t('auth.signInError'),
          { variant: 'error' }
        )
      }
    });
  }

  return (
    <AuthLayoutContainer>
      <AuthForm
        title={t('auth.loginText')}
        onSubmit={handleSubmit}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('auth.email')}
          name="email"
          autoComplete="email"
          //defaultValue={paramsEmail || ''}
          //autoFocus={!paramsEmail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('auth.password')}
          type="password"
          id="password"
          autoComplete="current-password"
          //autofocus={true/* Boolean(paramsEmail) */}
        />
        <Button
          id='submitButton'
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {isLoading ? <CircularProgress size={28} color='inherit' /> : t('auth.loginButton')}
        </Button>
        <Box mt={2}>
          <Grid container>
            <Grid item md={6}>
              <Link className={classes.link} onClick={() => history.push('/forgottenPassword')} variant="body2">
                {t('auth.forgotPassword')}
              </Link>
            </Grid>
            <Grid item md={6}>
              <Link className={classes.link} onClick={() => history.push('/signUp')} variant="body2">
                {t('auth.registerNew')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </AuthForm>
    </AuthLayoutContainer>
  );
}

export default withSnackbar(SignIn)
