import React from "react";
import { makeStyles, alpha } from "@material-ui/core/styles";
import InfoPop from './InfoPop';

const useStyles = (props) => makeStyles((theme) => ({
  keyValueList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    flexGrow: 1,

    '& > li': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(4),
      background: 'transparent',
      color: theme.palette[props.color].contrastText,
      borderTop: theme.borders.thin(alpha(theme.palette[props.color].contrastText, 0.12)),
      verticalAlign: 'middle',

      '& > .key-value-list-key': {
        fontWeight: 500,
        textAlign: 'left',
      },
      '& > .key-value-list-value': {
        fontWeight: 'normal',
        textAlign: 'right',
      },
    },
    '& > li:nth-child(odd)': {
      background: alpha(theme.palette[props.color].contrastText, 0.03),
    },
    '& > li:nth-child(even) + li:last-child': {
      borderBottom: theme.borders.thin(alpha(theme.palette[props.color].contrastText, 0.12)),
    },
  }
}))();

const renderItems = (id, items) => {
  let idx = 0;
  let result = [];

  for (let it of items) {
    if (it.isVisible !== false) {
      const itemId = `${id}-item-${idx}`;
      result.push(
        <li id={itemId} key={itemId}>
          {it.component || (<>
            <span id={`${itemId}-key`} className="key-value-list-key">{it.key} {it.info && <InfoPop info={it.info}/>}</span>
            <span id={`${itemId}-value`} className="key-value-list-value">{it.value} {it.links && it.links.length > 0 ? '(' : ''}{it.links && it.links.length > 0 ? it.links : ''}{it.links && it.links.length > 0 ? ')' : ''}</span>
          </>)
          }
        </li>
      );
      idx++;
    }
  }

  return result;
}

const KeyValueList = (props) => {
  const styleProps = {
    color: props.color || "white"
  };
  const id = props.id || "key-value-list";
  const classes = useStyles(styleProps);

  return (
    <ul id={id} className={classes.keyValueList}>
      {renderItems(id, props.items)}
    </ul>
  );
};

export default KeyValueList;
