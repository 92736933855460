import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { COLORS } from '../../styles'
import { invoiceCalculations, cleanFloat, hasTravelOrMaterialRows, hasAnyRows, parseVat, hasPayments } from '../../utils/invoiceUtils'
import { totalTravellingExpenceRow, formatRefCode } from '../../utils/invoiceUtils'
import { LANGUAGES1 } from '../../utils/languages'
import Button from '../../components/Button'
import Tip from '../../components/Tip'
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery, CircularProgress } from '@material-ui/core'
import { formatTimeFI, formatDatetime, scrollTo, roundNumber, notifyOffice } from '../../utils'
import { useHistory, Link } from "react-router-dom";
import { useStateValue } from "../../state"
import { printInvoicePdf, printExpencePdf, sendInvoiceRequest, sendExpenseRequest, sendCreateCreditNoteRequest, sendInvoiceToNav, printSalaryPdf, sendCreateExpenseRequest, APIdomain, deleteAttachment, getUser } from '../../service'
import { withSnackbar } from 'notistack'
import { cLog } from '../../utils'
import StatusCard from '../../components/StatusCard'
import InvoiceKeyValuePairs from './InvoiceKeyValuePairs'
import { useTranslation } from 'react-i18next'
import Alert from '../../components/Alert'
import moment from 'moment'
import InfoBar from '../../components/InfoBar'
import IconButton from "../../components/IconButton"
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import { addDays } from './validationSchemas'

const useStyles = makeStyles((theme) => ({
  invoiceViewContainer: {
    // Used only on non-laskuttaja side
    // maxWidth: theme.breakpoints.values.max,
    // margin: '0 auto',
  },
  invoiceContentTableContainer: {
    margin: 0,
    overflowX: 'auto',
  },
  invoiceBodyContainer: {
    marginBottom: theme.spacing(16),
  },
  invoiceCodaContainer: {
    marginTop: theme.spacing(16),
  },
  invoiceExtraInfoContainer: {
    margin: theme.spacing(4, 0),
  },
  invoiceExtraInfoContentContainer: {
    margin: theme.spacing(8, 0, 4, 0),
    '& > div': {
      padding: theme.spacing(8, 0),
      borderBottom: `2px solid rgba(0, 0, 0, 0.125)`,
      overflowX: 'auto',

      '& > h3': {
        marginTop: 0,
      },
      '& > p': {
        whiteSpace: 'pre-line',
        marginBottom: '0'
      }
    },
    '& > div:first-child': {
      borderTop: `2px solid rgba(0, 0, 0, 0.125)`,
    }
  },
  invoiceExtraInfoKeyValuePairsContainer: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  saveButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    '& > *': {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(4),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  cursorFinger: {
    cursor: 'pointer'
  },
  spanLink: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  travelCell: {
    verticalAlign: 'top',
    whiteSpace: 'pre-line'
  },
  userLink: {
    fontSize: '1.5rem',
    color: 'blue'
  }
}));

const InvoiceView = ({ view, edit, recycle, create, invoice, setFieldValue, handleSaveForm, handleBackButton, setExpanded, enqueueSnackbar, validate, isLoading, setLoading, exp, setValidateSendExpense, oldInvoiceTravelType1, fetchClientsByUserId, userData, setUserData, validateAllFields, resetForm, setOldInvoice, setFieldError }) => {
  const { t, i18n } = useTranslation();
  const [{ currentUser }] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [showTravelAndExpenceRows, setShowTravelAndExpenceRows] = useState(isAdmin || view)
  const [showPayments, setShowPayments] = useState(isAdmin || view)
  const [showHistory, setShowHistory] = useState(isAdmin || view)
  //const [showHistory, setShowHistory] = useState(false)
  const classes = useStyles()
  const [openConfirmSendInvoiceModal, setOpenConfirmSendInvoiceModal] = useState(false)
  const [openConfirmSendExpenceModal, setOpenConfirmSendExpenceModal] = useState(false)
  let history = useHistory()
  let reminder = false

  const handleChangeExpansion = async (panel) => {
    if (edit) {
      let val = validate()
      val.then(result => {
        if (result) {
          if (isAdmin || result) {
            setExpanded(panel)
            scrollTo(panel)
          }
        }
      }, error => {
        cLog('CHANGE EXPANSION ERROR', error)
      })
    }
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const { tax, totalWithTax, totalCorrectedEur, totalCorrectedVatEur, totalCorrectedWithVatEur, correctedInvoiceEur, openBalance } = invoiceCalculations(invoice)

  const { id, invoiceNr, expenceNr, client, date, dateDue, language, refCode, refText, invoiceTotal, expenceTotal, userFirstName, userLastName, marketingName, attachments, travelTotal, dateStartWork, dateEndWork, deliveryMethod, currency, travellingExpenceRows, materialExpenceRows, worklists, additionalInformation, internalNotes, adminInfo, info, origTotalVatExcludedEur } = invoice

  const pdf = ({
    id,
    title: `${exp ? t('invoice.expense') + ' ' + (expenceNr || '') : (invoice.creditNote ? (t('invoice.creditNote') + ' ' + (invoiceNr || '')) : (t('invoice.invoice') + ' ' + (invoiceNr || '')))}`,
    client: client && {
      name: client.name,
      address1: client.address1,
      address2: client.address2,
      postalCode: client.postalCode,
      city: client.city,
      businessId: client.businessId,
      deliveryMethod,
      email: deliveryMethod === 'SÄHKÖPOSTI' ? client.email : ''
    },
    user: {
      userFirstName,
      userLastName,
      marketingName: invoice.creditNote ? (t('invoice.originalInvoiceNotes') + ' ' + (invoice.originalInvoiceData ? invoice.originalInvoiceData.invoiceNr : 'xxx' )) : marketingName
    },
    date: date ? formatTimeFI(date) : '',
    dateDue: dateDue ? formatTimeFI(dateDue) : '',
    language: LANGUAGES1[language],
    locale: i18n.language,
    refCode: formatRefCode(refCode),
    refText,
    currency,
    invoiceTotal: parseFloat(((invoiceTotal && cleanFloat(invoiceTotal)) || 0)).toFixed(2),
    travelTotal: parseFloat(((travelTotal && cleanFloat(travelTotal)) || 0)).toFixed(2),
    expenceTotal: parseFloat(((expenceTotal && cleanFloat(expenceTotal)) || 0)).toFixed(2),
    correctedInvoiceEur: parseFloat(((correctedInvoiceEur && cleanFloat(correctedInvoiceEur)) || 0)).toFixed(2),
    tax: parseFloat(((tax && cleanFloat(tax)) || 0)).toFixed(2),
    totalWithTax: parseFloat(((totalWithTax && cleanFloat(totalWithTax)) || 0)).toFixed(2),
    totalCorrectedEur: parseFloat(((totalCorrectedEur && cleanFloat(totalCorrectedEur)) || 0)).toFixed(2),
    totalCorrectedVatEur: parseFloat(((totalCorrectedVatEur && cleanFloat(totalCorrectedVatEur)) || 0)).toFixed(2),
    totalCorrectedWithVatEur: parseFloat(((totalCorrectedWithVatEur && cleanFloat(totalCorrectedWithVatEur)) || 0)).toFixed(2),
    rows: [],
    correctionRows: [],
    travellingExpenceRows: travellingExpenceRows || [],
    materialExpenceRows: materialExpenceRows || [],
    worklists,
    attachments,
    additionalInformation,
    internalNotes,
    adminInfo,
    info,
    exp,
    travel_texts: ((invoice.travellingExpenceRows && invoice.travellingExpenceRows.length > 0) || (invoice.materialExpenceRows && invoice.materialExpenceRows.filter(row => row.purpose === 'TRAVEL').length > 0)) ? 'true' : 'false'
  })

  const createCreditNote = async () => {
    setLoading(true)
    const request = sendCreateCreditNoteRequest(invoice.id)
    request.then(response => {
        cLog('CREATE CREDIT NOTE REQUEST SENT', response)
        enqueueSnackbar(
          t('invoice.createCreditNoteCreated'),
          { variant: 'success' }
        )
        setFieldValue('creditNoteInvoicesData', response.data)
        setLoading(false)
        window.open(`/laskut/muokkaa/${response.data.id}`, '_blank').focus()
      },
      error => {
        setLoading(false)
        cLog('CREATE CREDIT NOTE REQUEST ERROR', error)
        enqueueSnackbar(
          t('invoice.createCreditNoteError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, true)
      }
    )
  }

  const createExpense = async () => {
    setLoading(true)
    const request = sendCreateExpenseRequest(invoice.id)
    request.then(response => {
        cLog('CREATE EXPENSE REQUEST SENT', response)
        enqueueSnackbar(
          t('invoice.createExpenseCreated'),
          { variant: 'success' }
        )
        setFieldValue('expenceData', response.data.expenceData)
        setLoading(false)
        window.open(`/kulut/muokkaa/${response.data.expenceData[0].id}`, '_blank').focus()
      },
      error => {
        setLoading(false)
        cLog('CREATE EXPENSE REQUEST ERROR', error)
        enqueueSnackbar(
          t('invoice.createExpenseError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, true)
      }
    )
  }

  const recycleInvoice = () => {
    window.open(`/${exp ? 'kulut' : 'laskut'}/kierrata/${invoice.id}`, '_blank').focus()
  }

  const recycleExpense = () => {
    window.open(`/kulut/kierrata_kulu/${invoice.id}`, '_blank').focus()
  }

  const handlePrintPdf = async (invoice) => {
    cLog('PRINTING PDF', pdf)
    try {
      setLoading(true)
      if (!exp) {
        const data = printInvoicePdf(pdf)
        data.then(response => {
          const blob = new Blob([response.data], {type: "application/pdf"})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = (invoice.creditNote ? t('invoice.creditNote') : t('invoice.invoice'))+(invoice.invoiceNr ?  + ' ' + invoice.invoiceNr : ' id'+invoice.id)
          document.body.append(link)
          link.click()
          link.remove()
          setTimeout(() => URL.revokeObjectURL(link.href), 7000)
        })
      } else {
        const data = printExpencePdf(pdf)
        data.then(response => {
          const blob = new Blob([response.data], {type: "application/pdf"})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = t('invoice.expense')+' '+invoice.expenceNr
          document.body.append(link)
          link.click()
          link.remove()
          setTimeout(() => URL.revokeObjectURL(link.href), 7000)
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      cLog('SEND INVOICE / EXPENCE PDF ERROR', error)
      enqueueSnackbar(
        t('invoice.pdfProblem'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  const handlePrintSalaryPdf = async (id) => {
    setLoading(true)
    const data = printSalaryPdf(id)
    data.then(response => {
      const blob = new Blob([response.data], {type: "application/pdf"})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = t('salary.salary')+' '+id
      document.body.append(link)
      link.click()
      link.remove()
      setTimeout(() => URL.revokeObjectURL(link.href), 7000)
      setLoading(false)
    }, error => {
      cLog('SENDING EXPENSE ERROR', error)
      enqueueSnackbar(
        t('invoice.pdfProblem'),
        { variant: 'error' }
      )
      setLoading(false)
    })
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const editOldInvoice = async () => {
    if (isAdmin) {
      if (!userData) {
        const thisUser = await getUser(invoice.userId)
        setUserData(thisUser)
      }

      if (!exp) {
        fetchClientsByUserId(invoice.userId)
      }
    }
    history.push(invoice.exp ? '/kulut/muokkaa/' + invoice.id : '/laskut/muokkaa/' + invoice.id)
    window.scroll({
      top: 0,
      left: 100,
      behavior: 'smooth'
    })
  }

  const handleDeleteAttachment = async (attachment, i, field) => {
    if (attachment.id) {
      try {
        const response = await deleteAttachment(attachment.id)
        cLog('ATTACHMENT DELETED', response)
        if (!field) {
          setFieldValue(`attachments`, attachments.filter((att, a) => att !== attachment))
        } else {
          setFieldValue(field, invoice.materialExpenceRows[i].attachments.filter((att, a) => att !== attachment))
        }
      } catch (error) {
        cLog('DELETE ATTACHMENT ERROR', error)
        enqueueSnackbar(
          t('invoice.attachmentDeleteError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, false)
      }
    } else {
      if (!field) {
        setFieldValue(`attachments`, attachments.filter((att, a) => att !== attachment))
      } else {
        setFieldValue(field, invoice.materialExpenceRows[i].attachments.filter((att, a) => att !== attachment))
      }
    }
  }

  const handleSendInvoice = (rem = false) => {
    reminder = rem
    if (!reminder && invoice.statusLahetettyDate) {
      setOpenConfirmSendInvoiceModal(true)
    } else if (reminder && invoice.statusMuistutettuDate) {
      setOpenConfirmSendInvoiceModal(true)
    } else {
      sendInvoice()
    }
  }

  const sendInvoice = async () => {
    const onFulfilled = (response) => {
      if (invoice.deliveryMethod !== 'EI_LÄHETETÄ') {
        enqueueSnackbar(
          t('invoice.invoiceSent'),
          { variant: 'success' }
        )
      } else {
        enqueueSnackbar(
          t('invoice.invoiceSentToNav'),
          { variant: 'success' }
        )
      }
      setOpenConfirmSendInvoiceModal(false)
      setLoading(false)
      resetForm(response)
      setOldInvoice(response)
      if (response.status === 'HYVITYSLASKU') {
        setFieldValue('marketingName', t('invoice.originalInvoiceNotes') + ' ' + invoice.originalInvoiceData.invoiceNr)
      }
    }

    const onRejected = (response) => {
      enqueueSnackbar(
        <div>{t('invoice.'+response.response.data.errors[0])}<br />{response.response.data.errors[1]}</div>,
        { variant: 'error' }
      )
      setOpenConfirmSendInvoiceModal(false)
      setLoading(false)
    }

    try {
      let val = validate()
      val.then(result => {
        if (result && validateAllFields()) {
          setLoading(true)
          const response = sendInvoiceRequest(invoice, reminder)
          cLog('SENDING INVOICE', response)
          response.then(onFulfilled, onRejected)
        } else {
          setOpenConfirmSendInvoiceModal(false)
          setLoading(false)
        }
      }, error => {
        cLog('SENDING INVOICE ERROR', error)
      })
    } catch (error) {
      enqueueSnackbar(
        t('invoice.invoiceSentError'),
        { variant: 'error' }
      )
      setOpenConfirmSendInvoiceModal(false)
      setLoading(false)
    }
  }

  const handleAgreeSendInvoiceModal = () => {
    sendInvoice()
  }

  const handleCloseSendInvoiceModal = () => {
    setOpenConfirmSendInvoiceModal(false)
  }

  const handleSendInvoiceToNav = async () => {
    const onFulfilled = (response) => {
      enqueueSnackbar(
        t('invoice.invoiceSentToNav'),
        { variant: 'success' }
      )
      setOpenConfirmSendInvoiceModal(false)
      setLoading(false)
      resetForm(response)
      setOldInvoice(response)
    }

    const onRejected = (response) => {
      enqueueSnackbar(
        <div>{t('invoice.'+response.response.data.errors[0])}<br />{response.response.data.errors[1]}</div>,
        { variant: 'error' }
      )
      setLoading(false)
    }

    try {
      let val = validate()
      val.then(result => {
        if (result && validateAllFields()) {
          setLoading(true)
          const response = sendInvoiceToNav(invoice)
          cLog('SENDING INVOICE TO NAV', response)
          response.then(onFulfilled, onRejected)
        } else {
          setOpenConfirmSendInvoiceModal(false)
          setLoading(false)
        }
      }, error => {
        cLog('SENDING INVOICE TO NAV ERROR', error)
      })
    } catch (error) {
      enqueueSnackbar(
        t('invoice.invoiceSentErrorNav'),
        { variant: 'error' }
      )
      setOpenConfirmSendInvoiceModal(false)
      setLoading(false)
    }
  }

  const handleSendExpence = () => {
    if (invoice.sentDate !== null) {
      setOpenConfirmSendExpenceModal(true)
    } else {
      sendExpence()
    }
  }

  const sendExpence = async () => {
    const onFulfilled = (response) => {
      enqueueSnackbar(
        t('invoice.expenseSent'),
        { variant: 'success' }
      )
      setOpenConfirmSendExpenceModal(false)
      setLoading(false)
      setValidateSendExpense(false)
      resetForm(response)
      setOldInvoice(response)
    }

    const onRejected = (response) => {
      enqueueSnackbar(
        <div>{t('invoice.'+response.response.data.errors[0])}<br />{response.response.data.errors[1]}</div>,
        { variant: 'error' }
      )
      setOpenConfirmSendExpenceModal(false)
      setLoading(false)
      setValidateSendExpense(false)
    }

    setLoading(true)
    setValidateSendExpense(true)
    let val = await validate()
    if (val && validateAllFields(true)) {
      try {
        const response = sendExpenseRequest(invoice)
        cLog('SENDING EXPENSE', response)
        response.then(onFulfilled, onRejected)
      } catch (error) {
        enqueueSnackbar(
          t('invoice.expenseSentError'),
          { variant: 'error' }
        )
        setOpenConfirmSendExpenceModal(false)
        setLoading(false)
        setValidateSendExpense(false)
      }
    } else {
      const zeroAccountRows = invoice.materialExpenceRows.find(row => row.account === null)
      if (zeroAccountRows) {
        enqueueSnackbar(
          t('invoice.accountMissing'),
          { variant: 'error' }
        )
      }
      const zeroVatRows = invoice.materialExpenceRows.find(row => row.vat === null)
      if (zeroVatRows) {
        enqueueSnackbar(
          t('invoice.vatMissing'),
          { variant: 'error' }
        )
      }
      setOpenConfirmSendExpenceModal(false)
      setLoading(false)
    }
  }

  const handleAgreeSendExpenceModal = () => {
    sendExpence()
  }

  const handleCloseSendExpenceModal = () => {
    setOpenConfirmSendExpenceModal(false)
  }

  const InvoiceRows = () => {
    const travelTable = {}
    const travelFields = ['km', 'dailyAllowances', 'partTimeDailyAllowances', 'mealExpences']

    for (let field of travelFields) {
      travelTable[field] = invoice.travellingExpenceRows
        .filter(row => row[field])
        .map(row => ({
          value: cleanFloat(row[field]),
          country: row.country,
          vat: row.vat ? row.vat : '0', ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...
          vehicle: row.vehicle,
          partTime: (row.allowances && row.partTimeDailyAllowances ? row.allowances.partTimeDailyAllowance : 0),
          meal: (row.allowances && row.mealExpences ? row.allowances.mealExpence : 0),
          daily: (row.allowances && row.dailyAllowances ? row.allowances.dailyAllowance[row.country] || row.allowances.dailyAllowance[row.country] : 0),
          kilometers: (row.allowances && row.km ? row.allowances.vehicle[row.vehicle] : 0),
          mileage: row.mileage
        }))
    }

    const travelVatTable = {}
    for (let field of Object.keys(travelTable)) {
      const fieldVats = [...new Set(travelTable[field].map(row => row.vat))]
      const fieldObject = {}
      for (let vat of fieldVats) {
        fieldObject[vat] = invoice.travellingExpenceRows
          .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...
          .filter(row => row.vat === vat)
          .map(row => ({
            value: (row[field] > 0 ? cleanFloat(row[field]) : 0),
            country: row.country,
            vehicle: row.vehicle,
            partTime: (row.allowances && row.partTimeDailyAllowances ? row.allowances.partTimeDailyAllowance : 0),
            meal: (row.allowances && row.mealExpences ? row.allowances.mealExpence : 0),
            daily: (row.allowances && row.dailyAllowances ? row.allowances.dailyAllowance[row.country] || row.allowances.dailyAllowance[row.country] : 0),
            kilometers: (row.allowances && row.km ? row.allowances.vehicle[row.vehicle] : 0),
            mileage: (row.allowances && row.km ? row.allowances.vehicle[row.vehicle] : row.mileage),
            vat: row.vat ? parseVat(row.vat) : 0
          }))
      }
      travelVatTable[field] = fieldObject
    }
    ///// TÄMÄ UTILSISTA ?????
    const expenceArray = (invoice.materialExpenceRows && invoice.materialExpenceRows.length > 0 && invoice.materialExpenceRows.map(row => cleanFloat(oldInvoiceTravelType1 ? row.sum : row.priceWithTax))) || []
    const expenceTotal = (invoice.materialExpenceRows.length > 0 && expenceArray.reduce((a, b) => a + b)) || 0
    const expences = () => {
      pdf.rows = pdf.rows.concat({
        content: t('invoice.expenceTotal'),
        quantity: `${expenceArray.length} kpl`,
        total: parseFloat(expenceTotal).toFixed(2)
      })

      return (
        <TableRow hover>
          <TableCell>{t('invoice.expenceTotal')}</TableCell>
          <TableCell>{expenceArray.length} {t('invoice.pieces')}</TableCell>
          <TableCell align='right'> {cleanFloat(expenceTotal).toFixed(2)}</TableCell>
        </TableRow>
      )
    }

    const vatStatus = () => {
      if (invoice.vatStatus === "MUU_VEROTON_MYYNTI") {
        pdf.rows = pdf.rows.concat({
          content: t('invoice.vatZero'),
          total: 'VAT_TEXT'
        })
        return (<TableRow hover>
          <TableCell colSpan="6">{t('invoice.vatZero')}</TableCell>
        </TableRow>)
      } else if (invoice.vatStatus === "MUU_VEROTON_MYYNTI_58") {
        pdf.rows = pdf.rows.concat({
          content: t('invoice.vatZero2'),
          total: 'VAT_TEXT'
        })
        return (<TableRow hover>
          <TableCell colSpan="6">{t('invoice.vatZero2')}</TableCell>
        </TableRow>)
      } else if (invoice.vatStatus === "RAKENNUSALAN_KÄÄNTEINEN_ALV_24%") {
        pdf.rows = pdf.rows.concat({
          content: t('invoice.constructionReverseVat'),
          total: 'VAT_TEXT'
        })
        return (<TableRow>
          <TableCell colSpan="6">{t('invoice.constructionReverseVat')}</TableCell>
        </TableRow>)
      } else if (invoice.vatStatus === "EU_YHTEISÖMYYNTI_(PALVELU)") {
        pdf.rows = pdf.rows.concat({
          content: t('invoice.vatIntraCommunity'),
          total: 'VAT_TEXT'
        })
        return (<TableRow>
          <TableCell colSpan="6">{t('invoice.vatIntraCommunity')}</TableCell>
        </TableRow>)
      } else if (invoice.vatStatus === "EU_YHTEISÖMYYNTI_(TAVARA)") {
        pdf.rows = pdf.rows.concat({
          content: t('invoice.vatIntraGoods'),
          total: 'VAT_TEXT'
        })
        return (<TableRow>
          <TableCell colSpan="6">{t('invoice.vatIntraGoods')}</TableCell>
        </TableRow>)
      }

      return null
    }

    let vehiclesWithCustomMileages = [...new Set(invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
      .filter(row => row.km && cleanFloat(row.km) !== 0)
      .map(row => row.vehicle + ';' + (row.mileage ? row.mileage : cleanFloat(row.allowances.vehicle[row.vehicle]))))]
    let dailyAllowanceCountries = [...new Set(invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
      .filter(row => row.dailyAllowances && cleanFloat(row.dailyAllowances) !== 0)
      .map(row => row.country + ';' + (row.daAmount ? cleanFloat(row.daAmount) : cleanFloat(row.allowances.dailyAllowance[row.country]))))]
    let partTimeDailyAllowances = [...new Set(invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
      .filter(row => row.partTimeDailyAllowances && cleanFloat(row.partTimeDailyAllowances) !== 0)
      .map(row => row.country + ';' + (row.country === 'FINLAND' ? (row.ptdaAmount ? row.ptdaAmount : cleanFloat(row.allowances.partTimeDailyAllowance)) : (row.ptdaAmount ? row.ptdaAmount : cleanFloat(row.allowances.dailyAllowance[row.country])))))]
    let mealExpences = [...new Set(invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
      .filter(row => row.mealExpences && cleanFloat(row.mealExpences) !== 0)
      .map(row => row.country + ';' + (row.meAmount ? cleanFloat(row.meAmount) : cleanFloat(row.allowances.mealExpence))))]
    return (
      <div className={classes.invoiceContentTableContainer}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>{exp ? t('invoice.expenseContent') : t('invoice.content')}</TableCell>
              <TableCell>{t('invoice.quantity')}</TableCell>
              {(!exp && <TableCell align='right'>{t('invoice.unitPrice')}</TableCell>) || null}
              {(!exp && <TableCell align='right'>{t('invoice.priceNoVat')}</TableCell>) || null}
              {(!exp && <TableCell align='right'>{t('invoice.vatShort')}</TableCell>) || null}
              <TableCell align='right'>{t('invoice.total')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!exp && invoice.invoiceRows && invoice.invoiceRows.map((row, i) => {
              const { content, quantity, unit, price, vat, total } = row
              const thisContent = (invoice.worklistId && i === 0)
                ? content.split('\n')[0]
                : content//.slice(0, 40)
              const quantityWithUnit = `${(quantity && cleanFloat(quantity)) || 0} ` + t('invoice.'+unit)
              const p = roundNumber((price && cleanFloat(price)) || 0, 2)
              const tot = roundNumber((total && cleanFloat(total)) || 0, 2)
              const totalTaxFree = roundNumber(((price && cleanFloat(price)) || 0) * ((quantity && cleanFloat(quantity)) || 0), 2)

              pdf.rows = pdf.rows.concat({
                content: thisContent,
                quantity: quantityWithUnit,
                price: parseFloat(p).toFixed(2),
                totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                vat,
                total: parseFloat(tot).toFixed(2)
              })

              let highlightRow = false
              if (isAdmin && ['Asentaja', 'LVI-asentaja', 'Maalari', 'Muurari', 'Rakennusalan työntekijä', 'Sähköasentaja'].includes(invoice.industry) && unit === 'HOUR' && cleanFloat(price) < 20) {
                highlightRow = true
              }

              return (
                <TableRow hover key={i} onClick={async () => { await handleChangeExpansion(`invoiceRows.${i}`) }} className={(!view && classes.cursorFinger) || ''}>
                  <TableCell style={{ backgroundColor: highlightRow && '#ff0000', color: highlightRow && '#ffffff' }}>{thisContent}</TableCell>
                  <TableCell style={{ backgroundColor: highlightRow && '#ff0000', color: highlightRow && '#ffffff' }}>{quantityWithUnit}</TableCell>
                  {(!exp && <TableCell align='right' style={{ backgroundColor: highlightRow && '#ff0000', color: highlightRow && '#ffffff' }}>{parseFloat(p).toFixed(2)}</TableCell>) || null}
                  {(!exp && <TableCell align='right' style={{ backgroundColor: highlightRow && '#ff0000', color: highlightRow && '#ffffff' }}>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                  {(!exp && <TableCell align='right' style={{ backgroundColor: highlightRow && '#ff0000', color: highlightRow && '#ffffff' }}>{vat}</TableCell>) || <TableCell>sda</TableCell>}
                  <TableCell align='right' style={{ backgroundColor: highlightRow && '#ff0000', color: highlightRow && '#ffffff' }}>{parseFloat(tot).toFixed(2)}</TableCell>
                </TableRow>
              )
            })}

            {/* KM */}
            {/* sorttaus järjestys:
              - km : vehicle asc, mileage asc, alv asc
              - päivärähat: country asc, summa asc, alv asc
              - osapäivärahat ja ateria: summa asc, alv asc*/}

            {vehiclesWithCustomMileages.map(vehicle => {
              const vehicleRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                .filter(row => row.km && cleanFloat(row.km) >= 0)
                .filter(row => row.mileage ? (row.vehicle + ';' + row.mileage) === vehicle : ((row.vehicle + ';' + row.allowances.vehicle[row.vehicle])) === vehicle)
                .map(row => ({ ...row, vat: exp ? '0' : row.vat }))
              const vatList = [...new Set(vehicleRows.map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))
              return vatList.map((vat, i) => {
                const content = `${t('invoice.kilometerExpenses')} ${t('invoice.'+vehicle.split(';')[0]).toLowerCase()} ${vehicle.split(';')[1]} ${t('invoice.mileage').toLowerCase()}`
                const quantityArray = vehicleRows
                  .filter(row => row.vat === vat)
                  .map(row => cleanFloat(row.km))

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} `+t('invoice.PIECE')
                  const price = (cleanFloat(vehicle.split(';')[1]) || 0) / 100
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{parseFloat(price).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {vehiclesWithCustomMileages.map(vehicle => {
              const vehicleRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                .filter(row => row.km && cleanFloat(row.km) < 0)
                .filter(row => row.mileage ? (row.vehicle + ';' + row.mileage) === vehicle : ((row.vehicle + ';' + row.allowances.vehicle[row.vehicle])) === vehicle)
                .map(row => ({ ...row, vat: exp ? '0' : row.vat }))
              const vatList = [...new Set(vehicleRows.map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))
              return vatList.map((vat, i) => {
                const content = `${t('invoice.kilometerExpenses')} ${t('invoice.'+vehicle.split(';')[0]).toLowerCase()} ${vehicle.split(';')[1]} ${t('invoice.mileage').toLowerCase()}`
                const quantityArray = vehicleRows
                  .filter(row => row.vat === vat)
                  .map(row => cleanFloat(row.km))

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} `+t('invoice.PIECE')
                  const price = (cleanFloat(vehicle.split(';')[1]) || 0) / 100
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{parseFloat(price).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {/* PÄIVÄRAHAT */}

            {dailyAllowanceCountries.map(country => {
              const countryRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                .filter(row => row.dailyAllowances && cleanFloat(row.dailyAllowances) >= 0)
                .filter(row => row.country + ';' + (row.daAmount ? cleanFloat(row.daAmount) : cleanFloat(row.allowances.dailyAllowance[row.country])) === country)
                .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...
              const vatList = [...new Set(countryRows
                .filter(row => row.dailyAllowances && row.dailyAllowances !== 0)
                .map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))

              return vatList.map((vat, i) => {
                const content = t('invoice.dailyAllowances')+' '+t('countries.'+country.split(';')[0])
                const quantityArray = countryRows
                  .filter(row => row.vat === vat)
                  .map(row => {
                    return cleanFloat(row.dailyAllowances) || 0
                  })

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} ` + t('invoice.pieces')
                  const price = cleanFloat(country.split(';')[1]) || 0
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{parseFloat(price).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {dailyAllowanceCountries.map(country => {
              const countryRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                .filter(row => row.dailyAllowances && cleanFloat(row.dailyAllowances) < 0)
                .filter(row => row.country + ';' + (row.daAmount ? cleanFloat(row.daAmount) : cleanFloat(row.allowances.dailyAllowance[row.country])) === country)
                .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...
              const vatList = [...new Set(countryRows
                .filter(row => row.dailyAllowances && row.dailyAllowances !== 0)
                .map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))

              return vatList.map((vat, i) => {
                const content = t('invoice.dailyAllowances')+' '+t('countries.'+country.split(';')[0])
                const quantityArray = countryRows
                  .filter(row => row.vat === vat)
                  .map(row => {
                    return cleanFloat(row.dailyAllowances) || 0
                  })

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} ` + t('invoice.pieces')
                  const price = cleanFloat(country.split(';')[1]) || 0
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{parseFloat(price).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {/* OSAPÄIVÄRAHAT */}

            {partTimeDailyAllowances.map(country => {
              let countryRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                  .filter(row => row.partTimeDailyAllowances && cleanFloat(row.partTimeDailyAllowances) >= 0)
                  .filter(row => row.country + ';' + (row.country === 'FINLAND' ? (row.ptdaAmount ? row.ptdaAmount : cleanFloat(row.allowances.partTimeDailyAllowance)) : (row.ptdaAmount ? row.ptdaAmount : cleanFloat(row.allowances.dailyAllowance[row.country]))) === country )
                  .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...

              const vatList = [...new Set(countryRows
                .filter(row => row.partTimeDailyAllowances && row.partTimeDailyAllowances !== 0)
                .map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))

              return vatList.map((vat, i) => {
                const content = t('invoice.partTimeDailyAllowances')+' '+t('countries.'+country.split(';')[0])
                const quantityArray = countryRows
                  .filter(row => row.vat === vat)
                  .map(row => {
                    return cleanFloat(row.partTimeDailyAllowances) || 0
                  })

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} ` + t('invoice.pieces')
                  const price = cleanFloat(country.split(';')[1]) || 0
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{parseFloat(price).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(parseFloat(total).toFixed(2)).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {partTimeDailyAllowances.map(country => {
              let countryRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                  .filter(row => row.partTimeDailyAllowances && cleanFloat(row.partTimeDailyAllowances) < 0)
                  .filter(row => row.country + ';' + (row.country === 'FINLAND' ? (row.ptdaAmount ? row.ptdaAmount : cleanFloat(row.allowances.partTimeDailyAllowance)) : (row.ptdaAmount ? row.ptdaAmount : cleanFloat(row.allowances.dailyAllowance[row.country]))) === country )
                  .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...

              const vatList = [...new Set(countryRows
                .filter(row => row.partTimeDailyAllowances && row.partTimeDailyAllowances !== 0)
                .map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))

              return vatList.map((vat, i) => {
                const content = t('invoice.partTimeDailyAllowances')+' '+t('countries.'+country.split(';')[0])
                const quantityArray = countryRows
                  .filter(row => row.vat === vat)
                  .map(row => {
                    return cleanFloat(row.partTimeDailyAllowances) || 0
                  })

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} ` + t('invoice.pieces')
                  const price = cleanFloat(country.split(';')[1]) || 0
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{parseFloat(price).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(parseFloat(total).toFixed(2)).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {/* ATERIAKORVAUKSET */}

            {mealExpences.map(country => {
              const countryRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                .filter(row => row.mealExpences && cleanFloat(row.mealExpences) >= 0)
                .filter(row => row.country + ';' + (row.meAmount ? cleanFloat(row.meAmount) : cleanFloat(row.allowances.mealExpence)) === country)
                .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...
              const vatList = [...new Set(countryRows
                .filter(row => row.mealExpences && row.mealExpences !== 0)
                .map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))

              return vatList.map((vat, i) => {
                const content = t('invoice.mealExpenses')
                const quantityArray = countryRows
                  .filter(row => row.vat === vat)
                  .map(row => {
                    return cleanFloat(row.mealExpences) || 0
                  })

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} ` + t('invoice.pieces')
                  const price = cleanFloat(country.split(';')[1]) || 0
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{price.toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {mealExpences.map(country => {
              const countryRows = invoice.travellingExpenceRows.filter(row => row.allowances !== null && row.allowances !== undefined && row.allowances !== '')
                .filter(row => row.mealExpences && cleanFloat(row.mealExpences) < 0)
                .filter(row => row.country + ';' + (row.meAmount ? cleanFloat(row.meAmount) : cleanFloat(row.allowances.mealExpence)) === country)
                .map(row => ({ ...row, vat: exp ? '0' : row.vat }))  ///// TARVITAANKO ? JOS KULULASKUISSA ALVEJA...
              const vatList = [...new Set(countryRows
                .filter(row => row.mealExpences && row.mealExpences !== 0)
                .map(row => row.vat))]
              vatList.sort((a, b) => parseVat(a) - parseVat(b))

              return vatList.map((vat, i) => {
                const content = t('invoice.mealExpenses')
                const quantityArray = countryRows
                  .filter(row => row.vat === vat)
                  .map(row => {
                    return cleanFloat(row.mealExpences) || 0
                  })

                const quantity = quantityArray.reduce((a, b) => a + b)
                if (quantity !== 0) {
                  const quantityWithUnit = `${quantity} ` + t('invoice.pieces')
                  const price = cleanFloat(country.split(';')[1]) || 0
                  const totalTaxFree = roundNumber(quantity * price, 2)
                  const total = roundNumber(totalTaxFree * (1 + parseVat(vat) / 100), 2)

                  pdf.rows = pdf.rows.concat({
                    content,
                    quantity: quantityWithUnit,
                    price: parseFloat(price).toFixed(2),
                    totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                    vat,
                    total: parseFloat(total).toFixed(2)
                  })

                  return (
                    <TableRow hover key={i}>
                      <TableCell>{content}</TableCell>
                      <TableCell>{quantityWithUnit}</TableCell>
                      {(!exp && <TableCell align='right'>{price.toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>) || null}
                      {(!exp && <TableCell align='right'>{vat}</TableCell>) || null}
                      <TableCell align='right'>{parseFloat(total).toFixed(2)}</TableCell>
                    </TableRow>
                  )
                }

                return null
              })
            })}

            {/* KULUT */}

            {(exp && invoice.materialExpenceRows && invoice.materialExpenceRows.length > 0 && expences()) || null}

            {!exp && invoice.materialExpenceRows.map((row, i) => {
              const { explanation, price, expenceVat, priceWithTax } = row
              const quantityWithUnit = '1 ' + t('invoice.PIECE')
              const p = roundNumber((price && cleanFloat(price)) || 0, 2)
              const tot = roundNumber((priceWithTax && cleanFloat(priceWithTax)) || 0, 2)
              const totalTaxFree = p

              pdf.rows = pdf.rows.concat({
                content: explanation,
                quantity: quantityWithUnit,
                price: parseFloat(p).toFixed(2),
                totalTaxFree: parseFloat(totalTaxFree).toFixed(2),
                vat: expenceVat,
                total: parseFloat(tot).toFixed(2)
              })

              return (
                <TableRow hover key={i} onClick={async () => { await handleChangeExpansion(`materialRows.${i}`) }} className={(!view && classes.cursorFinger) || ''}>
                  <TableCell>{explanation}</TableCell>
                  <TableCell>{quantityWithUnit}</TableCell>
                  <TableCell align='right'>{parseFloat(p).toFixed(2)}</TableCell>
                  <TableCell align='right'>{parseFloat(totalTaxFree).toFixed(2)}</TableCell>
                  <TableCell align='right'>{expenceVat}</TableCell>
                  <TableCell align='right'>{parseFloat(tot).toFixed(2)}</TableCell>
                </TableRow>
              )
            })}

            {/* ALV STATUS */}

            {vatStatus()}

            {invoice.correctionRows && invoice.correctionRows.length > 0 && <TableRow>
              <TableCell className='MuiTableCell-head' style={{ paddingTop: '1.5rem' }}>{t('invoice.corrections')}</TableCell>
              <TableCell className='MuiTableCell-head' style={{ paddingTop: '1.5rem' }}>{t('invoice.amount')}</TableCell>
              <TableCell className='MuiTableCell-head' style={{ paddingTop: '1.5rem' }} align='right'>{t('invoice.unitPrice')}</TableCell>
              <TableCell className='MuiTableCell-head' style={{ paddingTop: '1.5rem' }} align='right'>{t('invoice.priceNoVat')}</TableCell>
              <TableCell className='MuiTableCell-head' style={{ paddingTop: '1.5rem' }} align='right'>{t('invoice.vatShort')}</TableCell>
              <TableCell className='MuiTableCell-head' style={{ paddingTop: '1.5rem' }} align='right'>{t('invoice.total')}</TableCell>
            </TableRow>}
            {invoice.correctionRows && invoice.correctionRows.map((row, i) => {
              const { amount, vat, notes, unit, quantity, total } = row
              const thisContent = (row.date ? formatTimeFI(row.date) + " " : '') + notes
              const quantityWithUnit = `${(quantity && cleanFloat(quantity)) || 0} ` + t('invoice.'+unit)
              pdf.correctionRows = pdf.correctionRows.concat({
                content: thisContent,
                quantity: quantityWithUnit,
                price: parseFloat(((amount && cleanFloat(amount)) || 0)).toFixed(2),
                totalTaxFree: parseFloat(((amount && quantity && (cleanFloat(amount) * cleanFloat(quantity))) || 0)).toFixed(2),
                vat: vat,
                total: parseFloat(((total && cleanFloat(total)) || 0)).toFixed(2)
              })
              return (
                <TableRow hover key={i} onClick={async () => { await handleChangeExpansion(`correctionRows.${i}`) }} className={(!view && classes.cursorFinger) || ''}>
                  <TableCell>{thisContent}</TableCell>
                  <TableCell>{quantityWithUnit}</TableCell>
                  <TableCell align='right'>{parseFloat(((amount && cleanFloat(amount)) || 0)).toFixed(2)}</TableCell>
                  <TableCell align='right'>{parseFloat(((amount && quantity && (cleanFloat(amount) * cleanFloat(quantity))) || 0)).toFixed(2)}</TableCell>
                  <TableCell align='right'>{vat}</TableCell>
                  <TableCell align='right'>{parseFloat(((total && cleanFloat(total)) || 0)).toFixed(2)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>)
  }

  const TravelRows = () => {
    const showKm = invoice.travellingExpenceRows.filter(row => cleanFloat(row.km) !== 0).length > 0
    const showDa = invoice.travellingExpenceRows.filter(row => cleanFloat(row.dailyAllowances) !== 0).length > 0
    const showPtda = invoice.travellingExpenceRows.filter(row => cleanFloat(row.partTimeDailyAllowances) !== 0).length > 0
    const showMe = invoice.travellingExpenceRows.filter(row => cleanFloat(row.mealExpences) !== 0).length > 0
    const showCountry = invoice.travellingExpenceRows.filter(row => row.country !== 'FINLAND').length > 0

    return (
      <div style={view ? null : { overflowX: 'scroll' }}>
        <Table style={view ? { tableLayout: "auto" } : { width: "1200px", tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('invoice.route')}</TableCell>
              {isAdmin && <TableCell>{t('invoice.travelType')}</TableCell>}
              {isAdmin && <TableCell>{t('invoice.purpose')}</TableCell>}
              <TableCell>{t('invoice.travelTitleStart')}</TableCell>
              <TableCell>{t('invoice.travelTitleEnd')}</TableCell>
              <TableCell>{t('invoice.travelDuration')}</TableCell>
              {showKm && <TableCell>{t('invoice.travelTitlekm')}</TableCell>}
              {showDa && <TableCell style={{whiteSpace: 'pre-line'}}>{t('invoice.travelTitleDailyAllowances')}</TableCell>}
              {showPtda && <TableCell style={{whiteSpace: 'pre-line'}}>{t('invoice.travelTitlePartTimeDailyAllowances')}</TableCell>}
              {showMe && <TableCell style={{whiteSpace: 'pre-line'}}>{t('invoice.travelTitleMealExpenses')}</TableCell>}
              <TableCell>{t('invoice.vehicle')}</TableCell>
              {showCountry && <TableCell>{t('invoice.country')}</TableCell>}
              <TableCell align='right'>{t('invoice.total')}</TableCell>
              {isAdmin && <TableCell align='right'>{t('invoice.overlap')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.travellingExpenceRows.map((row, i) => {
              const { route, departureDate, returnDate, km, departureTime, returnTime,
                dailyAllowances, partTimeDailyAllowances, mealExpences, vehicle, mileage,
                registerNumber, total, country, overlapData, travelType, specialIndustryOvernight, specialIndustryPrimaryPlace, specialIndustryDining, mobileWorkPrimaryPlace, purpose, purposeNotes } = row
              row.total = totalTravellingExpenceRow(row)

              let routeText = route
              if (row.travelRowDestinations && row.travelRowDestinations.length > 0) {
                routeText = ''
                if (row.origRowUsername) {
                  routeText = row.origRowUsername + ' '
                }
                routeText += row.travelRowDestinations.map(item => item.destination).join(' - ')
              }

              let duration = ''
              if (departureDate && returnDate && departureTime && returnTime) {
                let start = moment(departureDate + ' ' + departureTime)
                let end = moment(returnDate + ' ' + returnTime)
                let dur = moment.duration(end.diff(start))

                //Get Days and subtract from duration
                let days = Math.floor(dur.asDays())
                dur.subtract(moment.duration(days,'days'))

                //Get hours and subtract from duration
                let hours = dur.hours()
                dur.subtract(moment.duration(hours,'hours'))

                //Get Minutes and subtract from duration
                let minutes = dur.minutes()
                dur.subtract(moment.duration(minutes,'minutes'))

                //Get seconds
                //let seconds = dur.seconds()

                if (days > 0) {
                  duration += days + 'd'
                }
                if (hours > 0) {
                  if (duration) {
                    duration += ' '
                  }
                  duration += hours + 'h'
                }
                if (minutes > 0) {
                  if (duration) {
                    duration += ' '
                  }
                  duration += minutes + 'min'
                }
              }

              let kmGoogleText = ''
              if (row.km && cleanFloat(row.km) !== 0) {
                const kmNotEqual = (row.travelRowDestinations && row.travelRowDestinations.length > 0 && row.travelRowDestinations.filter(dest => dest.order > 0 && cleanFloat(dest.km) !== cleanFloat(dest.kmGoogle))) || []
                kmNotEqual.forEach(function (km) {
                  kmGoogleText += ', ' + t('invoice.destination').toLowerCase() + ' ' + km.order + ' (' + km.km + ' vs ' +  km.kmGoogle + ')'
                })
              }

              let tType = ''
              if (travelType === 'YLEINEN') {
                tType = t('invoice.travelTypeGeneralShort')
              } else if (travelType === 'ERITYISALA') {
                tType = t('invoice.travelTypeSpecialIndustryShort')
                tType += '\n' + t('invoice.specialIndustryOvernightShort') + ' ' + (specialIndustryOvernight === 'true' ? t('taxcard.true') : t('taxcard.false'))
                tType += '\n' + t('invoice.specialIndustryPrimaryPlaceShort') + ' ' + (specialIndustryPrimaryPlace === 'true' ? t('taxcard.true') : t('taxcard.false'))
                tType += '\n' + t('invoice.specialIndustryDiningShort') + ' ' + (specialIndustryDining === 'true' ? t('taxcard.true') : t('taxcard.false'))
              } else if (travelType === 'LIIKKUVA_TYO') {
                tType = t('invoice.travelTypeMobileWorkShort')
                tType += '\n' + t('invoice.specialIndustryPrimaryPlaceShort') + ' ' + (mobileWorkPrimaryPlace === 'true' ? t('taxcard.true') : t('taxcard.false'))
              }

              let purposeText = t('invoice.purposeWork')
              if (purpose !== 'WORK') {
                purposeText = purposeNotes
              }

              let daText = '0'
              if (dailyAllowances && cleanFloat(dailyAllowances) !== 0) {
                daText = cleanFloat(dailyAllowances) + '\n'
                let amount = row.daAmount ? cleanFloat(row.daAmount) : cleanFloat(row.allowances.dailyAllowance[row.country])
                daText += (Number.isInteger(amount) ? amount : amount.toFixed(2)) + ' ' + t('invoice.euroPiece')
              }
              let ptdaText = '0'
              if (partTimeDailyAllowances && cleanFloat(partTimeDailyAllowances) !== 0) {
                ptdaText = cleanFloat(partTimeDailyAllowances) + '\n'
                let amount = row.ptdaAmount ? cleanFloat(row.ptdaAmount) : (row.country === 'FINLAND' ? cleanFloat(row.allowances.partTimeDailyAllowance) : cleanFloat(row.allowances.dailyAllowance[row.country]))
                ptdaText += (Number.isInteger(amount) ? amount : amount.toFixed(2)) + ' ' + t('invoice.euroPiece')
              }
              let meText = '0'
              if (mealExpences && cleanFloat(mealExpences) !== 0) {
                meText = cleanFloat(mealExpences) + '\n'
                let amount = row.meAmount ? cleanFloat(row.meAmount) : cleanFloat(row.allowances.mealExpence)
                meText += (Number.isInteger(amount) ? amount : amount.toFixed(2)) + ' ' + t('invoice.euroPiece')
              }

              let vehicleText = t('invoice.'+vehicle)
              if (mileage) {
                vehicleText += '\n' + mileage + ' ' + t('invoice.mileage').toLowerCase()
              } else if (row.allowances && row.allowances.vehicle && row.allowances.vehicle[vehicle]) {
                vehicleText += '\n' + row.allowances.vehicle[vehicle] + ' ' + t('invoice.mileage').toLowerCase()
              }
              if (registerNumber) {
                vehicleText += '\n' + registerNumber
              }

              return (
                <TableRow key={i} hover onClick={async () => { await handleChangeExpansion(`travellingRows.${i}`) }} className={(!view && classes.cursorFinger) || ''}>
                  <TableCell className={classes.travelCell}>
                    {routeText}
                    {isAdmin && kmGoogleText && <InfoBar allUsers message={t('invoice.kmNotMatch') + kmGoogleText} style={{ marginTop: '0.5rem' }}/>}
                  </TableCell>
                  {isAdmin && <TableCell className={classes.travelCell}>{tType}</TableCell>}
                  {isAdmin && <TableCell className={classes.travelCell}>{purposeText}</TableCell>}
                  <TableCell className={classes.travelCell}><b>{departureDate && formatTimeFI(departureDate)}</b><br/>{departureTime}</TableCell>
                  <TableCell className={classes.travelCell}><b>{returnDate && formatTimeFI(returnDate)}</b><br/>{returnTime}</TableCell>
                  <TableCell className={classes.travelCell}>{duration}</TableCell>
                  {showKm && <TableCell className={classes.travelCell}>{km ? cleanFloat(km) : 0}</TableCell>}
                  {showDa && <TableCell className={classes.travelCell}>{daText}</TableCell>}
                  {showPtda && <TableCell className={classes.travelCell}>{ptdaText}</TableCell>}
                  {showMe && <TableCell className={classes.travelCell}>{meText}</TableCell>}
                  <TableCell className={classes.travelCell}>{vehicleText}</TableCell>
                  {showCountry && <TableCell className={classes.travelCell}>{t('countries.'+country)}</TableCell>}
                  <TableCell align='right' className={classes.travelCell}>{parseFloat(((total && cleanFloat(total)) || 0)).toFixed(2)}</TableCell>
                  {isAdmin && <TableCell align='right' className={classes.travelCell}>{overlapData && overlapData.map((overlap, i) => {
                    return <div key={i}>{overlap.invoiceId ? <Link to={`/laskut/esikatsele/${overlap.invoiceId}`} target='_blank'>{overlap.invoiceNr || 'id:' + overlap.invoiceId}</Link> : <Link to={`/kulut/esikatsele/${overlap.expenceId}`} target='_blank'>{overlap.expenceNr}</Link>}</div>})}</TableCell>}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  const MaterialRows = () => {
    return (
      <div>
        <Table style={view ? { tableLayout: "auto" } : { tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('invoice.explanation')}</TableCell>
              <TableCell>{t('invoice.attachment')}</TableCell>
              <TableCell>{t('invoice.buyLocation')}</TableCell>
              <TableCell>{t('invoice.buyDate')}</TableCell>
              <TableCell align='right'>{t('invoice.sum')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.materialExpenceRows.map((row, i) => {
              const { explanation, sum, attachments, buyLocation, buyDate, price, priceWithTax } = row
              let amount
              if (exp) {
                amount = oldInvoiceTravelType1 ? sum : priceWithTax
              } else {
                amount = price
              }
              oldInvoiceTravelType1 ? ((sum && cleanFloat(sum)) || 0).toFixed(2) : ((price && cleanFloat(price)) || 0).toFixed(2)

              return (
                <TableRow hover key={i} onClick={async () => { await handleChangeExpansion(`materialRows.${i}`) }} className={(!view && classes.cursorFinger) || ''}>
                  <TableCell>{explanation}</TableCell>
                  <TableCell>{attachments && attachments.map((attachment, a) => {
                    const attachmentUrl = attachment.id ? APIdomain + attachment.url : attachment.url
                    return (
                      <div key={a}>
                        {isAdmin && attachment.attachmentDetailId && `id:${attachment.attachmentDetailId} `}<a href={attachmentUrl} target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}>{attachment.filename}</a>
                        {attachment.sendToClient && <IconButton style={{ background: 'none', paddingRight: '0', paddingLeft: '0' }}><DoneIcon/></IconButton>}
                        {((invoice.status === 'LUONNOS' || isAdmin) && edit)
                          ? <IconButton onClick={() => handleDeleteAttachment(attachment, i, `materialExpenceRows.${i}.attachments`)}>
                              <DeleteIcon/>
                            </IconButton>
                          : null}
                      </div>
                    )
                  })}</TableCell>
                  <TableCell>{buyLocation}</TableCell>
                  <TableCell>{buyDate ? formatTimeFI(buyDate) : ''}</TableCell>
                  <TableCell align='right'>{((amount && cleanFloat(amount)) || 0).toFixed(2)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }

  const Body = () => {
    const { client } = invoice

    if (!exp) {
      return (
        <Grid container spacing={0} className={classes.invoiceBodyContainer}>
          <Grid item xs={12} sm={6} lg={7}>  {/* COL 1 */}
            <div>{(client && client.name) || ''}</div>
            <div>{(client && client.address1) || ''}</div>
            {client && client.address2 && <div>{client.address2}</div>}
            <div>{(client && client.postalCode) || ''} {(invoice.client && invoice.client.city) || ''}</div>
            {client && client.country !== "FINLAND" && <div>{t('countries.'+client.country)}</div>}
            <div>{(client && client.businessId) || ''}</div>
            <div>{invoice.deliveryMethod ? t('invoice.'+invoice.deliveryMethod) : ''}</div>
            {invoice.deliveryMethod === 'SÄHKÖPOSTI' && <div>{((invoice.client && invoice.client.email) || '')}</div>}
            <div style={{ marginTop: '15px' }}></div> {/* TYHJÄ RIVI */}
            {invoice.creditNote
              ? <div style={{ maxWidth: '90%' }}>{(t('invoice.originalInvoiceNotes') + ' ' + (invoice.originalInvoiceData ? invoice.originalInvoiceData.invoiceNr : 'xxx' ))}</div>
              : (isAdmin && <div style={{ maxWidth: '90%' }}>
                  {invoice.marketingName || (invoice.userFirstName + ' ' + invoice.userLastName)}
                </div>
                )
            }
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>  {/* COL 2 */}
            <InvoiceKeyValuePairs
              dense
              items={[
                [t('invoice.invoiceDate'), (date && formatTimeFI(date)) || '-'],
                [t('invoice.dateDue'), (dateDue && formatTimeFI(dateDue)) || '-'],
                [t('invoice.language'), client && LANGUAGES1[client.language]],
                refCode &&
                  [t('invoice.refCode'), formatRefCode(refCode)],
                [t('invoice.refText'), refText || '-'],
                [t('invoice.currency'), invoice.currency || '-'],
              ]}
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} lg={7}>  {/* COL 1 */}
            {isAdmin && <div>
              {invoice.userFirstName} {invoice.userLastName}
            </div>}
          </Grid>
          <Grid item xs={12} sm={6} lg={5}>  {/* COL 2 */}
            <InvoiceKeyValuePairs
              dense
              items={[
                [t('invoice.expenceDate'), (date && formatTimeFI(date)) || '-'],
                refCode &&
                  [t('invoice.refCode'), formatRefCode(refCode)],
              ]}
            />
          </Grid>
        </Grid>
      )
    }
  }

  const Coda = () => {
    //cLog('TOTAL TAX FREE', totalCorrectedEur)
    //cLog('TOTAL WITH TAX', totalWithTax)
    //cLog('TAX', tax)

    const CodaGridItemRow = ({ label, value0, value1 }) => (
      <>
        {!value0 && <Grid item xs={3}>
        </Grid>}
        <Grid item xs={6}>
          {label}
        </Grid>
        {value0 &&
          <Grid item xs={3} style={{textAlign: 'right'}}>
            {value0}
          </Grid>}
        <Grid item xs={3} style={{textAlign: 'right'}}>
          {value1}
        </Grid>
      </>
    );

    return (
      <Grid container spacing={0} className={classes.invoiceCodaContainer}>
        <Grid item sm={4} lg={5}>
          {/* COL1 */}
          {isAdmin && cleanFloat(origTotalVatExcludedEur) > 0 && (roundNumber(0.6 * cleanFloat(origTotalVatExcludedEur), 2) <= cleanFloat(travelTotal) + cleanFloat(expenceTotal)) &&
            <InfoBar allUsers message={t('invoice.tooMuchExpensesTip')} />
          }
          {isAdmin && invoice.deliveryMethod === 'VERKKOLASKU' && (invoice.einvoiceOperator === 'Aktia (HELSFIHH)' || invoice.einvoiceOperator === 'OP-Pohjola-ryhmä (OKOYFIHH)') && new Date(invoice.dateDue).withoutTime() <= addDays(new Date().withoutTime(), 2) && 
            <InfoBar allUsers message={t('invoice.opDeliveryTip')} />
          }
        </Grid>
        <Grid item xs={12} sm={8} lg={7}>
        {!exp ?
          <Grid container spacing={0}>
            <CodaGridItemRow
              label={t('invoice.invoiceTotalView')}
              value0={currency !== 'EUR' && `${invoiceTotal ? invoiceTotal.toFixed(2) : '0.00'} ${currency}`}
              value1={`${correctedInvoiceEur.toFixed(2)}${currency !== 'EUR' ? ' EUR' : ''}`}
            />
            {cleanFloat(travelTotal) !== 0 &&
              <CodaGridItemRow
                label={t('invoice.travelTotal')}
                value1={travelTotal.toFixed(2)}
              />}
            {cleanFloat(expenceTotal) !== 0 &&
              <CodaGridItemRow
                label={t('invoice.expenceTotal')}
                value1={expenceTotal.toFixed(2)}
              />}
            <CodaGridItemRow
              label={t('invoice.totalWithoutVat')}
              value0={currency !== 'EUR' && `${invoiceTotal ? invoiceTotal.toFixed(2) : '0.00'} ${currency}`}
              value1={`${totalCorrectedEur ? totalCorrectedEur.toFixed(2) : '0.00'}${currency !== 'EUR' ? ' EUR' : ''}`}
            />
            <CodaGridItemRow
              label={t('invoice.tax')}
              value0={currency !== 'EUR' && `${tax ? tax.toFixed(2) : '0.00'} ${currency}`}
              value1={`${totalCorrectedVatEur ? totalCorrectedVatEur.toFixed(2) : '0.00'}${currency !== 'EUR' ? ' EUR' : ''}`}
            />
            <CodaGridItemRow
              label={t('invoice.total')}
              value0={currency !== 'EUR' && `${totalWithTax ? totalWithTax.toFixed(2) : '0.00'} ${currency}`}
              value1={`${totalCorrectedWithVatEur ? totalCorrectedWithVatEur.toFixed(2) : '0.00'}${currency !== 'EUR' ? ' EUR' : ''}`}
            />
          </Grid>
        :
          <Grid container spacing={0}>
            {travelTotal > 0 &&
              <CodaGridItemRow
                label={t('invoice.travelTotal')}
                value1={travelTotal ? travelTotal.toFixed(2) : '0.00'}
              />}
            {parseInt(expenceTotal) !== 0 &&
              <CodaGridItemRow
                label={t('invoice.expenceTotal')}
                value1={expenceTotal ? expenceTotal.toFixed(2) : '0.00'}
              />}
            <CodaGridItemRow
              label={t('invoice.total')}
              value1={totalCorrectedEur ? totalCorrectedEur.toFixed(2) : '0.00'}
            />
          </Grid>
        }
        </Grid>

        {!exp && <Grid item xs={12} sm={12} style={{ borderTop: '1px solid' }}>
          <div>
            <Grid item xs={12} sm={4}>ODEAL OY </Grid>
            <Grid item xs={12} sm={4}>Niittyläntie 5 </Grid>
            <Grid item xs={12} sm={4}>00620 Helsinki </Grid>
          </div>
        </Grid>}
      </Grid>
    )
  }

  const Buttons = () => {
    return (
      <div className={classes.saveButtons}>

        {/* PRIMARY ACTIONS */}

        {((!isMobile && !view) || recycle) &&
          <Tip title={(invoice.status === 'LUONNOS' && !invoice.id) ? t('invoice.saveAsDraft') : t('invoice.saveChanges')} placement="top">
            <span>
              <Button disabled={isLoading} variant="contained" color="primary" onClick={() => handleSaveForm(false)}>
                {invoice.status === 'LUONNOS' && !invoice.id ? t('invoice.saveAsDraft') : t('invoice.save')}
              </Button>
            </span>
          </Tip>}
        {!isMobile && invoice.status === 'LUONNOS' &&
          <Tip title={!exp ? t('invoice.sendForReviewTip') : t('invoice.setAsCompleteTip')} placement="top">
            <span>
              <Button disabled={isLoading} variant="contained" color="primary" onClick={() => handleSaveForm(true)}>
                {!exp ? t('invoice.sendForReview') : t('invoice.setAsComplete')}
              </Button>
            </span>
          </Tip>}
        {((!isAdmin && invoice.status === 'LUONNOS' && view) || (isAdmin && !edit && !recycle && !create && invoice.id)) && !isMobile &&
          <Tip title={t('invoice.modify')} placement="top">
            <span>
              <Button variant="contained" onClick={editOldInvoice}>
                {t('invoice.modify')}
              </Button>
            </span>
          </Tip>}
        {!exp && isAdmin && invoice.id && (deliveryMethod !== 'EI_LÄHETETÄ' || !invoice.refCode) &&
          <Tip title={invoice.deliveryMethod !== 'EI_LÄHETETÄ' ? t('invoice.sendInvoiceTip') : t('invoice.sendInvoiceToNavTip')} placement="top">
            <span>
              <Button variant="contained" disabled={isLoading} onClick={() => handleSendInvoice(false)}>
                {invoice.deliveryMethod !== 'EI_LÄHETETÄ' ? t('invoice.sendInvoice') : t('invoice.sendInvoiceToNav')}
              </Button>
            </span>
          </Tip>}
        {!exp && isAdmin && !invoice.creditNote && invoice.status === 'LÄHETETTY' && new Date(invoice.dateDue).withoutTime() < new Date().withoutTime() && currentUser.id === parseInt(process.env.REACT_APP_DEV_USER_ID) &&
          <Tip title={t('invoice.sendReminderTip')} placement="top">
            <span>
              <Button variant="contained" disabled={isLoading} onClick={() => handleSendInvoice(true)}>
                {t('invoice.sendReminder')}
              </Button>
            </span>
          </Tip>}
        {!exp && isAdmin && invoice.refCode && !['LUONNOS', 'TARKASTETTAVANA', 'POISTETTU'].includes(invoice.status) &&
          <Tip title={t('invoice.sendInvoiceToNavTip')} placement="top">
            <span>
              <Button variant="contained" disabled={isLoading} onClick={handleSendInvoiceToNav}>
                {t('invoice.sendInvoiceToNav')}
              </Button>
            </span>
          </Tip>}
        {exp && isAdmin && invoice.id && ['VALMIS', 'OSITTAIN_MAKSETTU', 'MAKSETTU'].includes(invoice.status) &&
          <Tip title={t('invoice.sendInvoiceTip')} placement="top">
            <span>
              <Button variant="contained" disabled={isLoading} onClick={handleSendExpence}>
                {t('invoice.sendInvoice')}
              </Button>
            </span>
          </Tip>}

        {/* SECONDARY ACTIONS */}

        {!isMobile && invoice.id &&
          <Tip title={edit && (isAdmin || invoice.status === 'LUONNOS') ? t('invoice.printAsPdfTip') : t('invoice.printAsPdf')} placement="top">
            <span>
              <Button variant="outlined" color="primary" onClick={() => handlePrintPdf(invoice)} disabled={isLoading}>
                {t('invoice.printAsPdf')}
              </Button>
            </span>
          </Tip>}
        {exp && isAdmin && invoice.id && !invoice.originalExpenceId &&
          <Tip title={t('invoice.copyAsBaseTip')} placement="top">
            <span>
              <Button disabled={isLoading} variant="outlined" onClick={recycleInvoice}>
                {t('invoice.copyAsBase')}
              </Button>
            </span>
          </Tip>}
        {!exp && invoice.id &&
          <Tip title={t('invoice.copyAsBaseTip')} placement="top">
            <span>
              <Button disabled={isLoading} variant="outlined" onClick={recycleInvoice}>
                {t('invoice.copyAsBase')}
              </Button>
            </span>
          </Tip>}
        {exp && isAdmin && invoice.id && !invoice.originalExpenceId && invoice.materialExpenceRows.length > 0 && ['LUONNOS', 'VALMIS', 'OSITTAIN_MAKSETTU'].includes(invoice.status) &&
          <Tip title={t('invoice.copyAsExpenseTip')} placement="top">
            <span>
              <Button disabled={isLoading} variant="outlined" onClick={recycleExpense}>
                {t('invoice.copyAsExpense')}
              </Button>
            </span>
          </Tip>}
        {!exp && isAdmin && invoice.id && ['LÄHETETTY', 'OSITTAIN_MAKSETTU'].includes(invoice.status) && !invoice.creditNote && ((invoice.totalCorrectedWithVatEur || 0) - (invoice.totalPaymentsWithTax || 0) > 0) &&
          <Tip title={t('invoice.createCreditNoteTip')} placement="top">
            <span>
              <Button disabled={isLoading} variant="outlined" onClick={createCreditNote}>
                {t('invoice.createCreditNote')}
              </Button>
            </span>
          </Tip>}
        {!exp && isAdmin && invoice.id && (invoice.status === 'MAKSETTU' || (invoice.payDay === 'HETI' && invoice.status === 'LÄHETETTY')) && invoice.materialExpenceRows && invoice.materialExpenceRows.length > 0 &&
          <Tip title={t('invoice.createExpenseTip')} placement="top">
            <span>
              <Button disabled={isLoading} variant="outlined" onClick={createExpense}>
                {t('invoice.createExpense')}
              </Button>
            </span>
          </Tip>}

        {/* CANCELLATION ACTIONS */}

        {!isMobile &&
          <Tip title={(invoice.client && !view && !invoice.id) ? t('invoice.emptyForm') : t('invoice.cancelAndReturn')} placement="top">
            <span>
              <Button variant="contained" color="secondary" onClick={handleBackButton}>
                {t('invoice.cancel')}
              </Button>
            </span>
          </Tip>}
        {!isMobile && isLoading && <CircularProgress size={30} style={{ margin: '1.5% 0% 0% 1%' }} />}
      </div>
    )
  }

  const History = () => {
    let collection = [];
    invoice.updateHistory.map((row, i) => {
      let name, filtered, joined;
      let changes = [];

      for (const [key, value] of Object.entries(row.changes)) {
        if ((row.itemType !== 'Payment' && !['invoice_id', 'inst_amt_ccy', 'allowances', 'expence_id', 'eur_amount', 'consolidated_invoice_row_id', 'discount_commission_id', 'discount_code_id', 'updater', 'used_discount', 'use_discount', 'inviter_discount_given'].includes(key)) || (row.itemType === 'Payment' && ['acct_svcr_ref', 'notes', 'tx_amt', 'val_dt'].includes(key))) {
          let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
          let value0 = value[0]
          let value1 = value[1]
          let translated = false

          if (['accountingStatusOsittainTilitettyDate', 'accountingStatusTilitettyDate', 'date', 'dateDue', 'dateEndWork', 'dateStartWork', 'statusHyvitettyDate', 'statusHyvityslaskuDate', 'statusLahetettyDate', 'statusLuottotappioDate', 'statusMaksettuDate', 'statusMaksettuLiikaaDate', 'statusMuistutettuDate', 'statusOsittainMaksettuDate', 'statusPerinnassaDate', 'statusPoistettuDate', 'statusTarkastettavanaDate', 'statusUlosotossaDate', 'statusValmisDate', 'statusHylattyDate', 'bookgDt', 'valDt', 'departureDate', 'returnDate', 'buyDate', 'sentDate', 'reminderDate'].includes(k)) {
            if (value0) {
              value0 = formatTimeFI(value0)
            }
            if (value1) {
              value1 = formatTimeFI(value1)
            }
          } else if (['departureTime', 'returnTime'].includes(k)) {
            if (value0 === value1) {
              value0 = null
              value1 = null
            }
          } else if (k === 'country') {
            if (value0) {
              value0 = t('countries.'+value0)
            }
            if (value1) {
              value1 = t('countries.'+value1)
            }
          } else if (k === 'language') {
            if (value0) {
              value0 = t('user.'+value0)
            }
            if (value1) {
              value1 = t('user.'+value1)
            }
          } else if (['vatStatus', 'payDay'].includes(k)) {
            if (value0) {
              value0 = t('invoice.'+value0.replace(/_/g, ' '))
            }
            if (value1) {
              value1 = t('invoice.'+value1.replace(/_/g, ' '))
            }
          } else if (['deliveryMethod', 'accountingStatus', 'status', 'unit'].includes(k)) {
            if (value0) {
              value0 = t('invoice.'+value0)
            }
            if (value1) {
              value1 = t('invoice.'+value1)
            }
            translated = true
          } else if (k === 'purpose') {
            if (value0) {
              if (value0 === 'TRAVEL') {
                value0 = t('invoice.purposeWork')
              } else {
                let txt = value0.charAt(0).toUpperCase() + value0.substr(1).toLowerCase()
                value0 = t('invoice.purpose'+txt)
              }
            }
            if (value1) {
              if (value1 === 'TRAVEL') {
                value1 = t('invoice.purposeWork')
              } else {
                let txt = value1.charAt(0).toUpperCase() + value1.substr(1).toLowerCase()
                value1 = t('invoice.purpose'+txt)
              }
            }
            translated = true
          } else if (k === 'expenceVat') {
            k = 'vat'
          } else if (k === 'vat' && row.itemType === "MaterialExpenceRow") {
            k = 'accountingVat'
          } else if (k === 'refCode') {
            if (value0) {
              value0 = formatRefCode(value0)
            }
            if (value1) {
              value1 = formatRefCode(value1)
            }
          }

          if (!((value0 === null || value0 === '') && (value1 === null || value1 === ''))) {
            if (row.itemType !== "TravellingExpenceRow" || !(['dailyAllowances', 'partTimeDailyAllowances', 'mealExpences'].includes(k) && value0 === null && value1 === 0)) {
              if (value0 === 'true' || value0 === true) {
                value0 = t('taxcard.true')
              } else if (value0 === 'false'|| value0 === false) {
                value0 = t('taxcard.false')
              } else if (value0 === null) {
                translated = true
                value0 = t('user.noValue')
              } else if (value0 === 'YLEINEN') {
                value0 = t('invoice.travelTypeGeneral')
              } else if (value0 === 'ERITYISALA') {
                value0 = t('invoice.travelTypeSpecialIndustry')
              } else if (value0 === 'LIIKKUVA_TYO') {
                value0 = t('invoice.travelTypeMobileWork')
              }

              if (value1 === 'true' || value1 === true) {
                value1 = t('taxcard.true')
              } else if (value1 === 'false' || value1 === false) {
                value1 = t('taxcard.false')
              } else if (value1 === null) {
                value1 = t('user.noValue')
              } else if (value1 === 'YLEINEN') {
                value1 = t('invoice.travelTypeGeneral')
              } else if (value1 === 'ERITYISALA') {
                value1 = t('invoice.travelTypeSpecialIndustry')
              } else if (value1 === 'LIIKKUVA_TYO') {
                value1 = t('invoice.travelTypeMobileWork')
              }

              if (['address1', 'address2', 'businessId', 'city', 'contactPerson', 'deliveryMethod', 'einvoiceAddress', 'einvoiceOperator', 'email', 'hasCustomBusinessId', 'isPrivate', 'language', 'name', 'phone', 'postalCode', 'reminderHandling'].includes(k)) {
                if (row.event === 'create') {
                  changes.push(t('customers.'+k) + ': ' + value1)
                } else if (row.event === 'destroy') {
                  changes.push(t('customers.'+k) + ': ' + value0)
                } else {
                  changes.push(t('customers.'+k) + ': ' + value0 + ' -> ' + value1)
                }
              } else if (k === 'unit') {
                if (translated) {
                  if (row.event === 'create') {
                    changes.push(t('invoice.'+k) + ': ' + value1)
                  } else if (row.event === 'destroy') {
                    changes.push(t('invoice.'+k) + ': ' + value0)
                  } else {
                    const val1 = i18n.exists('invoice.'+value1) ? t('invoice.'+value1) : value1
                    changes.push(t('invoice.'+k) + ': ' + value0 + ' -> ' + val1)
                  }
                } else {
                  if (row.event === 'create') {
                    changes.push(t('invoice.'+k) + ': ' + t('invoice.'+value1))
                  } else if (row.event === 'destroy') {
                    changes.push(t('invoice.'+k) + ': ' + t('invoice.'+value0))
                  } else {
                    changes.push(t('invoice.'+k) + ': ' + t('invoice.'+value0) + ' -> ' + t('invoice.'+value1))
                  }
                }
              } else if (['mobileWorkPrimaryPlace', 'specialIndustryPrimaryPlace'].includes(k)) {
                if (row.event === 'create') {
                  changes.push(t('invoice.'+k+'History') + ': ' + value1)
                } else if (row.event === 'destroy') {
                  changes.push(t('invoice.'+k+'History') + ': ' + value0)
                } else {
                  changes.push(t('invoice.'+k+'History') + ': ' + value0 + ' -> ' + value1)
                }
              } else if (k === 'purpose' && row.itemType === "MaterialExpenceRow") {
                if (row.event === 'create') {
                  changes.push(t('invoice.purposeExpenseTitle') + ': ' + value1)
                } else if (row.event === 'destroy') {
                  changes.push(t('invoice.purposeExpenseTitle') + ': ' + value0)
                } else {
                  changes.push(t('invoice.purposeExpenseTitle') + ': ' + value0 + ' -> ' + value1)
                }              
              } else {
                if (row.event === 'create') {
                  changes.push(t('invoice.'+k) + ': ' + value1)
                } else if (row.event === 'destroy') {
                  changes.push(t('invoice.'+k) + ': ' + value0)
                } else {
                  changes.push(t('invoice.'+k) + ': ' + value0 + ' -> ' + value1)
                }
              }
            }
          }
        }
      }

      if (changes.length) {
        if (row.firstName && row.lastName) {
          name = row.firstName + ' ' + row.lastName;
        } else if (row.firstName && !row.lastName) {
          name = row.firstName;
        } else if (!row.firstName && row.lastName) {
          name = row.lastName
        } else {
          name = 'Odealin järjestelmä'
        }

        let rowText = ''
        if (row.itemType !== "ActiveStorage::Attachment") {
          filtered = changes.filter(function (f) {
            return f != null;
          });
          joined = filtered.join(', ');

          if (row.itemType === "Account") {
            rowText = t('invoice.accountRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "Payment") {
            rowText = t('invoice.paymentRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "CorrectionRow") {
            rowText = t('invoice.correctionRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "InvoiceRow") {
            rowText = t('invoice.invoiceRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "TravellingExpenceRow") {
            rowText = t('invoice.travelInvoiceRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "MaterialExpenceRow") {
            rowText = t('invoice.expenceRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "AttachmentDetail") {
            rowText = t('invoice.attachmentDetail') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
          } else if (row.itemType === "Invoice" && row.event === 'create') {
            rowText = t('invoice.invoice') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
          } else if (row.itemType === "Expence" && row.event === 'create') {
            rowText = t('invoice.expense') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
          }
        } else {
          rowText = t('invoice.attachment') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event)
          joined = ''
        }

        collection.push(<TableRow key={i}>
          <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{rowText + joined}</TableCell>
        </TableRow>)
      }

      return null
    })

    return (
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('customers.date')}</TableCell>
              <TableCell>{t('customers.user')}</TableCell>
              <TableCell>{t('customers.events')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collection}
          </TableBody>
        </Table>
      </div>
    )
  }

  const Payments = () => {
    let collection = [];
    try {
      if (hasPayments(invoice)) {
        invoice.payments.map((row, i) => {
          collection.push(<TableRow key={i}>
            <TableCell>{row.valDt ? formatTimeFI(row.valDt) : ''}</TableCell>
            <TableCell>{row.notes ? row.notes : null}</TableCell>
            <TableCell>{row.txAmt}</TableCell>
            <TableCell>{row.acctSvcrRef ? row.acctSvcrRef : null}</TableCell>
          </TableRow>)

          return null
        })
      }
    } catch (error) {
      cLog('PAYMENTS ERROR', error)
    }

    return (
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('invoice.valDt')}</TableCell>
              <TableCell>{t('invoice.notes')}</TableCell>
              <TableCell>{t('invoice.sum')}</TableCell>
              <TableCell>{t('invoice.acctSvcrRef')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collection}
          </TableBody>
        </Table>
      </div>
    )
  }

  const Attachments = () => {
    return (attachments && attachments.length > 0 &&
      <div><h5>{t('invoice.attachments')}</h5>
      <table>
        <tbody>
          {attachments.map((attachment, i) => {
            const attachmentUrl = attachment.id ? APIdomain + attachment.url : attachment.url
            //cLog('ATTACHMENT', attachment)
            //cLog('ATTACHMENT URL', attachmentUrl)
            return <tr key={i}>
              <td>{isAdmin && attachment.id && `id:${attachment.id} `}{!isMobile || (isMobile && attachment.id)
                ? <a href={attachmentUrl} target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}>{attachment.filename}</a>
                : attachment.filename}</td>
              {invoice.status === 'LUONNOS' && !isAdmin && edit && <td>
                <IconButton onClick={() => handleDeleteAttachment(attachment, i)}>
                  <DeleteIcon/>
                </IconButton>
              </td>}
              {isAdmin && edit && <td>
                <IconButton onClick={() => handleDeleteAttachment(attachment, i)}>
                  <DeleteIcon/>
                </IconButton>
              </td>}
            </tr>
          })}
        </tbody>
      </table>
    </div>)
  }

  function findSmallestNonNegativeInteger(...integers) {
    let smallestNonNegative = Infinity;

    for (let i = 0; i < integers.length; i++) {
        if (integers[i] >= 0 && integers[i] < smallestNonNegative) {
            smallestNonNegative = integers[i];
        }
    }

    if (smallestNonNegative === Infinity) {
        return -1; // If no non-negative integer found
    } else {
        return smallestNonNegative;
    }
  }

   const FormatMessage2 = (tag, index, message) => {
    const end = message.indexOf('</' + tag + '>')
    if (end === -1) {
      return message
    } else {
      const m = message.substring(index + 3, end)
      let color = ''
      switch (tag) {
        case 'p':
          color = 'red'
          break
        case 'o':
          color = 'orange'
          break
        default:
          color = 'green'
          break
      }

      return (
        <>
          {FormatMessage(message.substring(0, index))}
          {tag === 'b'
            ? <b>{FormatMessage(m)}</b>
            : <span style={{ color: color }}>{FormatMessage(m)}</span>
          }
          {FormatMessage(message.substring(end + 4))}
        </>
      )
    }
   }

  const FormatMessage = (message) => {
    const idxB = message.indexOf('<b>')
    const idxP = message.indexOf('<p>')
    const idxO = message.indexOf('<o>')
    const idxV = message.indexOf('<v>')
    const smallest = findSmallestNonNegativeInteger(idxB, idxP, idxO, idxV)

    if (smallest === -1) {
      return message
    } else if (smallest === idxB) {
      return FormatMessage2('b', idxB, message)
    } else if (smallest === idxP) {
      return FormatMessage2('p', idxP, message)
    } else if (smallest === idxO) {
      return FormatMessage2('o', idxO, message)
    } else {
      return FormatMessage2('v', idxV, message)
    }
  }

  const Info = () => {
    const extraInfoKeyValuePairs = [
      [
        exp ? t('invoice.expenseStatus') : t('invoice.status'),
        <StatusCard style={{ margin: '0', color: 'white', backgroundColor: COLORS.STATUS_COLORS[invoice.status] }}>
          {t('invoice.'+invoice.status) || ''}{invoice.accountingStatus === "TILITETTY" && t('invoice.andAccounted')}{invoice.accountingStatus === "OSITTAIN_TILITETTY" && t('invoice.andPartiallyAccounted')}
        </StatusCard>
      ],

      !exp &&
        [t('invoice.payDay'), invoice.payDay && t('invoice.'+invoice.payDay.replace(/_/g, ' '))],

      !exp &&
        [t('invoice.workPeriod'), `${invoice.dateStartWork ? formatTimeFI(dateStartWork) : ''} - ${dateEndWork ? formatTimeFI(dateEndWork) : ''}`],

      !exp &&
        [t('invoice.industry'), invoice.industry ? (invoice.industry !== 'Muu' ? t('user.'+invoice.industry) : invoice.customIndustry) : '-'],

      !exp && isAdmin &&
        [t('invoice.openBalance'), openBalance ? openBalance.toFixed(2) + ' €' : '-'],

      !exp &&
        [t('invoice.yelObligation'), invoice.yel ? t('invoice.yes') : t('invoice.no')],

      !exp && isAdmin && invoice.salaryData && invoice.salaryData.length > 0 &&
        [t('invoice.salaryData'), invoice.salaryData.map((salary, i) => <span key={i}><span className={classes.spanLink} onClick={() => handlePrintSalaryPdf(salary.id)}>{salary.id}</span>{i === invoice.salaryData.length - 1 ? '' : ', '}</span>)],

      !exp && isAdmin && invoice.consolidatedInvoice &&
      [
        t('invoice.consolidatedInvoice'),
        <>
          {t('invoice.yes') + ' '}
          {invoice.ciData.map((inv, i) => <span key={i}><a href={`/laskut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.invoiceNr || 'id:'+inv.id}</a>{i === invoice.ciData.length - 1 ? '' : ', '}</span>)}
        </>
      ],

      !exp && isAdmin && invoice.consolidatedInvoiceData &&
      [
        t('invoice.belongsToConsolidatedInvoice'),
        <a href={`/laskut/esikatsele/${invoice.consolidatedInvoiceData.id}`} target='_blank' rel="noopener noreferrer">{invoice.consolidatedInvoiceData.invoiceNr || 'id:'+invoice.consolidatedInvoiceData.id}</a>
      ],

      !exp && isAdmin && invoice.creditNote &&
      [
        t('invoice.creditNote'),
        <>
          {t('invoice.yes') + ' '}
          {invoice.originalInvoiceData && <a href={`/laskut/esikatsele/${invoice.originalInvoiceData.id}`} target='_blank' rel="noopener noreferrer">{invoice.originalInvoiceData.invoiceNr || 'id:'+invoice.originalInvoiceData.id}</a>}
        </>
      ],

      !exp && isAdmin && invoice.creditNoteInvoicesData && invoice.creditNoteInvoicesData.length > 0 &&
      [
        t('invoice.belongsToCreditNote'),
        <>
          {t('invoice.yes') + ' '}
          {invoice.creditNoteInvoicesData.map((inv, i) => <span key={i}><a href={`/laskut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.invoiceNr || 'id:'+inv.id}</a>{i === invoice.creditNoteInvoicesData.length - 1 ? '' : ', '}</span>)}
        </>
      ],

      !exp && isAdmin && invoice.expenceData && invoice.expenceData.length > 0 &&
      [
        t('invoice.expense'),
        invoice.expenceData.map((inv, i) => <span key={i}><a href={`/kulut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.expenceNr || 'id:'+inv.id}</a>{i === invoice.expenceData.length - 1 ? '' : ', '}</span>)
      ],

      exp && isAdmin && invoice.invoiceData &&
      [
        t('invoice.invoice'),
        <a href={`/laskut/esikatsele/${invoice.invoiceData.id}`} target='_blank' rel="noopener noreferrer">{invoice.invoiceData.invoiceNr || 'id:'+invoice.invoiceData.id}</a>
      ],

      exp && isAdmin && invoice.originalExpenceData &&
      [
        t('invoice.isCopy'),
        <>
          {t('invoice.yes') + ' '}
          {invoice.originalExpenceData && <a href={`/kulut/esikatsele/${invoice.originalExpenceData.id}`} target='_blank' rel="noopener noreferrer">{invoice.originalExpenceData.expenceNr || 'id:'+invoice.originalExpenceData.id}</a>}
        </>
      ],

      exp && isAdmin && invoice.copyExpencesData &&
      [
        t('invoice.copyExpences'),
        invoice.copyExpencesData.map((inv, i) => <span key={i}><a href={`/kulut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.expenceNr || 'id:'+inv.id}</a>{i === invoice.copyExpencesData.length - 1 ? '' : ', '}</span>)
      ],

      // Date fields
      !exp && isAdmin && invoice.statusTarkastettavanaDate &&
        [t('invoice.statusTarkastettavanaDate'), formatTimeFI(invoice.statusTarkastettavanaDate)],
      !exp && isAdmin && invoice.statusLahetettyDate &&
        [t('invoice.statusLahetettyDate'), formatTimeFI(invoice.statusLahetettyDate)],
      exp && isAdmin && invoice.statusValmisDate &&
        [t('invoice.statusValmisDate'), formatTimeFI(invoice.statusValmisDate)],
      isAdmin && invoice.statusOsittainMaksettuDate &&
        [t('invoice.statusOsittainMaksettuDate'), formatTimeFI(invoice.statusOsittainMaksettuDate)],
      isAdmin && invoice.statusMaksettuDate &&
        [t('invoice.statusMaksettuDate'), formatTimeFI(invoice.statusMaksettuDate)],
      !exp && isAdmin && invoice.statusMaksettuLiikaaDate &&
        [t('invoice.statusMaksettuLiikaaDate'), formatTimeFI(invoice.statusMaksettuLiikaaDate)],
      exp && isAdmin && invoice.statusHylattyDate &&
        [t('invoice.statusHylattyDate'), formatTimeFI(invoice.statusHylattyDate)],
      isAdmin && invoice.statusPoistettuDate &&
        [t('invoice.statusPoistettuDate'), formatTimeFI(invoice.statusPoistettuDate)],
      exp && isAdmin && invoice.sentDate &&
        [t('invoice.sentDate'), formatTimeFI(invoice.sentDate)],
      !exp && isAdmin && invoice.statusPerinnassaDate &&
        [t('invoice.statusPerinnassaDate'), formatTimeFI(invoice.statusPerinnassaDate)],
      !exp && isAdmin && invoice.statusUlosotossaDate &&
        [t('invoice.statusUlosotossaDate'), formatTimeFI(invoice.statusUlosotossaDate)],
      !exp && isAdmin && invoice.statusLuottotappioDate &&
        [t('invoice.statusLuottotappioDate'), formatTimeFI(invoice.statusLuottotappioDate)],
      !exp && isAdmin && invoice.statusMuistutettuDate &&
        [t('invoice.statusMuistutettuDate'), formatTimeFI(invoice.statusMuistutettuDate)],
      !exp && isAdmin && invoice.statusHyvitettyDate &&
        [t('invoice.statusHyvitettyDate'), formatTimeFI(invoice.statusHyvitettyDate)],
      !exp && isAdmin && invoice.statusHyvityslaskuDate &&
        [t('invoice.statusHyvityslaskuDate'), formatTimeFI(invoice.statusHyvityslaskuDate)],
      !exp && isAdmin && invoice.accountingStatusOsittainTilitettyDate &&
        [t('invoice.accountingStatusOsittainTilitettyDate'), formatTimeFI(invoice.accountingStatusOsittainTilitettyDate)],
      !exp && isAdmin && invoice.accountingStatusTilitettyDate &&
        [t('invoice.accountingStatusTilitettyDate'), formatTimeFI(invoice.accountingStatusTilitettyDate)],
    ];

    return (
    <div className={classes.invoiceExtraInfoContentContainer}>
      <div>
        <h3>{exp ? t('invoice.expenseExtraInfo') : t('invoice.invoiceExtraInfo')} {!exp && t('invoice.notShownToCustomer')}</h3>
        {view ?
          <div className={classes.invoiceExtraInfoKeyValuePairsContainer}>
            <InvoiceKeyValuePairs
              items={extraInfoKeyValuePairs}
              alignLeft
            />
          </div>
        :
          <InvoiceKeyValuePairs
            items={extraInfoKeyValuePairs}
            alignLeft
          />
        }
      </div>

      {invoice.info &&
        <div>
          <h3>{t('invoice.officeInfo')}</h3>
          <p>{invoice.info}</p>
        </div>}

      {invoice.adminInfo &&
        <div>
          <h3>{t('invoice.officeMessage')}</h3>
          <p>{invoice.adminInfo}</p>
        </div>}

      {invoice.internalNotes && isAdmin &&
        <div>
          <h3>{t('invoice.admin')}</h3>
          <p>{FormatMessage(invoice.internalNotes)}</p>
        </div>}

      {hasTravelOrMaterialRows(invoice) &&
        <div>
          {!view &&
            <Button onClick={() => setShowTravelAndExpenceRows(!showTravelAndExpenceRows)}>
                {showTravelAndExpenceRows ? t('invoice.hideTravel') : t('invoice.showTravel')}
            </Button>}

          {showTravelAndExpenceRows &&
            <>
                {invoice.travellingExpenceRows && invoice.travellingExpenceRows.length > 0 &&
              <>
                <h3 style={{ paddingTop: !view ? '2rem' : '' }}>{t('invoice.travelRows')}</h3>
                <TravelRows />
              </>}
                {invoice.materialExpenceRows && invoice.materialExpenceRows.length > 0 &&
              <>
                <h3 style={{ paddingTop: !view || (invoice.travellingExpenceRows && invoice.travellingExpenceRows.length > 0) ? '2rem' : '' }}>{t('invoice.expenseRows')}</h3>
                <MaterialRows />
              </>}
            </>}
        </div>}

      {attachments && attachments.length > 0 &&
        <div>
          <Attachments />
        </div>}

      {hasPayments(invoice) && isAdmin &&
        <div>
          {!view ?
            <Button onClick={() => setShowPayments(!showPayments)}>
                {showPayments ? t('invoice.hidePayments') : t('invoice.showPayments')}
            </Button>
            :
            <h3>{t('invoice.invoicePayments')}</h3>}
          {showPayments && <Payments />}
        </div>}

      {invoice && invoice.id && invoice.updateHistory && isAdmin &&
        <div>
          {!view ?
            <Button onClick={() => setShowHistory(!showHistory)}>
                {showHistory ? t('invoice.hideHistory') : t('invoice.showHistory')}
            </Button>
            :
            <h3>{t('invoice.historyTitle')}</h3>}
          {showHistory && <History />}
        </div>}

      {invoice.additionalInformation &&
        <div>
          {invoice.additionalInformation.split('\n').map((row, i) => <div key={i}>{row}</div>)}
        </div>}

      {invoice.worklists && invoice.worklists.length > 0 &&
        <div>
          <div>{t('invoice.worklistAttachments')}</div>
          {invoice.worklists.map((attachment, i) => <a href={APIdomain + attachment.url} target='_blank' rel="noopener noreferrer" key={i}>{attachment.filename}</a>)}
        </div>}
    </div>
  )}

  return (
    <>
      <div className={isAdmin && view ? classes.invoiceViewContainer : undefined}>
        <h1>{`${exp ? t('invoice.expense') + ' ' + (expenceNr || '') : (invoice.creditNote ? (t('invoice.creditNote') + ' ' + (invoiceNr || '')) : (t('invoice.invoice') + ' ' + (invoiceNr || '')))}`} {isAdmin && invoice.userId ? <a href={'/kayttajat/' + invoice.userId} target='_blank' rel="noopener noreferrer" className={classes.userLink}>{invoice.userId} {invoice.userFirstName} {invoice.userLastName}</a> : ''}</h1>
        <Body />
        {hasAnyRows(invoice) && <InvoiceRows />}
        <Coda />
        <Info />
        <Buttons />
      </div>
      <Alert
        open={openConfirmSendInvoiceModal}
        setOpen={setOpenConfirmSendInvoiceModal}
        handleAgree={handleAgreeSendInvoiceModal}
        handleClose={handleCloseSendInvoiceModal}
        alertMessage={{ title: t('invoice.confirmSendInvoiceTitle'), body: t('invoice.confirmSendInvoiceBody') + ' ' + (reminder ? (invoice.statusMuistutettuDate ? formatTimeFI(invoice.statusMuistutettuDate) : '') : (invoice.statusLahetettyDate ? formatTimeFI(invoice.statusLahetettyDate) : '')) }}
      />
      <Alert
        open={openConfirmSendExpenceModal}
        setOpen={setOpenConfirmSendExpenceModal}
        handleAgree={handleAgreeSendExpenceModal}
        handleClose={handleCloseSendExpenceModal}
        alertMessage={{ title: t('invoice.confirmSendInvoiceTitle'), body: t('invoice.confirmSendExpenceBody') + ' ' + (invoice.statusLahetettyDate ? formatTimeFI(invoice.statusLahetettyDate) : '') }}
      />
    </>
  )
}

export default withSnackbar(InvoiceView)
