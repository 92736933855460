import React, { useState, useEffect } from 'react'
import { useStateValue, setInvoices, setExpences, setSelected1, setSortKeyInvoices, setSortOrderInvoices } from "../../state"
import { getInvoices, getExpences, updateFrequencyMs } from '../../service'
import InvoiceTable from './InvoiceTable'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Grid, Accordion, AccordionDetails, AccordionSummary, FormGroup, FormControlLabel, CircularProgress, useMediaQuery } from '@material-ui/core'
import SelectAsync from '../../components/SelectAsync'
import DropDown from '../../components/DropDown'
import Button from '../../components/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { scrollTo, camelToSnakeCase } from '../../utils'
import { useInterval } from '../../utils/hooks'
import { OdealCheckbox as MaterialUiCheckBox } from '../../components/OdealCheckbox'
import { cLog } from '../../utils'
import Input from '../../components/Input'
import { validateInt, validateFloat } from '../../utils/invoiceUtils'
import Pagination from '../../components/Pagination'
import { useTranslation } from 'react-i18next'
import DropdownChecbox from '../../components/DropdownChecbox'
import { COUNTRIES } from '../../utils/countries'
import { LANGUAGES } from '../../utils/languages'
import { EINVOICE_OPERATORS, INDUSTRIES, VATSTATUSES } from '../../utils/invoiceUtils'
import DPicker from '../../components/DPicker'
import { formatDate, notifyOffice } from '../../utils'
import { withSnackbar } from 'notistack'
import DownloadDialog from '../../components/DownloadDialog'
import { newPaginationStyles } from '../../styles'

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    margin: theme.spacing(4, 0),
  },
  dashBoard: {
    fontSize: '20px'
  },
  checkBoxes: {
    display: 'flex'
  },
  newPaginationRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    fontSize: '16px',
    ...newPaginationStyles,
  },
  newPaginationInfo: {
  },
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
}))

let selectedColumns  = []

const INIT_FILTER = {
  invoiceNr: '',
  createdAt: '',
  updatedAt: '',
  from: '',
  to: '',
  dateDue: '',
  dateDueFrom: '',
  dateDueTo: '',
  name: '',
  totalAddress: '',
  country: '',
  email: '',
  phone: '',
  isPrivate: '',
  businessId: '',
  contactPerson: '',
  deliveryMethod: [],
  reminderHandling: [],
  language: [],
  eInvoiceOperator: [],
  eInvoiceAddress: '',
  userId: [],
  yel: [],
  invoiceTotal: '',
  travelTotal: '',
  origTotalVatExcluded: '',
  tax: '',
  totalWithTax: '',
  totalCorrections: '',
  totalCorrectionsVat: '',
  totalCorrectionsWithVat: '',
  origTotalVatExcludedEur: '',
  taxEur: '',
  totalWithTaxEur: '',
  totalCorrectedEur: '',
  totalCorrectedVatEur: '',
  totalCorrectedWithVatEur: '',
  salariesPayableAmount: '',
  currency: [],
  refText: '',
  refCode: '',
  vatStatus: [],
  totalPaymentsWithTax: '',
  payDay: [],
  status: [],
  totalAccounting: '',
  accountingStatus: [],
  info: '',
  adminInfo: '',
  internalNotes: '',
  industry: [],
  customIndustry: '',
  expenceNr: '',
  expenceTotal: '',
  type: 'LASKUT',
  paymentStatus: [],
  columnsOptions: [],
  consolidatedInvoice: '',
  belongsToConsolidatedInvoice: '',
  creditNote: '',
  belongsToCreditNote: '',
  isCopy: '',
  statusTarkastettavanaDate: '',
  statusLahetettyDate: '',
  statusOsittainMaksettuDate: '',
  statusMaksettuDate: '',
  statusMaksettuLiikaaDate: '',
  statusPoistettuDate: '',
  statusPerinnassaDate: '',
  statusUlosotossaDate: '',
  statusLuottotappioDate: '',
  statusMuistutettuDate: '',
  statusHyvitettyDate: '',
  statusHyvityslaskuDate: '',
  statusValmisDate: '',
  statusHylattyDate: '',
  sentDate: '',
  accountingStatusOsittainTilitettyDateFrom: '',
  accountingStatusOsittainTilitettyDateTo: '',
  accountingStatusTilitettyDateFrom: '',
  accountingStatusTilitettyDateTo: '',
  statusMaksettuDateFrom: '',
  statusMaksettuDateTo: '',
  salaryId: '',
  includeDestroyed: false,
  reminderNotes: '',
  reminderDateFrom: '',
  reminderDateTo: ''
}

const InvoiceFilterTable = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [{ currentUser, invoices, expences, selected1, sortKeyInvoices, sortOrderInvoices }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [expanded, setExpanded] = useState(!isAdmin ? 'filter' : 'menuCheckBoxes')
  const [checked, setChecked] = useState('KAIKKI_LASKUT')
  const [forceFetchData, setForceFetchData] = useState(true)
  const [invoicePagination, setInvoicePagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: (isAdmin ? 100 : 20),
    totalPages: 0,
    totalRows: 0
  })
  if (isAdmin) {
    if (checked !== 'KULULASKUT') {
      selectedColumns = JSON.parse(window.localStorage.getItem('landingScreenInvoiceSelectedColumns')) || ['invoiceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'dateDue', 'worker', 'workPeriod', 'totalCorrectedEur', 'payDay', 'status', 'accountingStatus', 'reminderHandling', 'actions']
    } else {
      selectedColumns = JSON.parse(window.localStorage.getItem('landingScreenExpenceSelectedColumns')) || ['expenceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'worker', 'expenceType', 'timeFrame', 'totalWithTax', 'status', 'actions']
    }
  } else {
    if (checked !== 'KULULASKUT') {
      selectedColumns = ['invoiceNr', 'date', 'dateDue', 'customer', 'totalCorrectedEur', 'payDay', 'status', 'accountingStatus', 'actions']
    } else {
      selectedColumns = ['expenceNr', 'date', 'totalWithTax', 'status', 'actions']
    }
  }

  const [invoiceFilter, setInvoiceFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})
  const [isLoading, setLoading] = useState(false)
  const landingPage = true
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [boksi, setBoksi] = useState(false)
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)

  const getData = () => {
    setForceFetchData(true)
  }

  useEffect(() => {
    if (!boksi && checked === 'BOKSI_MAKSULISTA') {
      setChecked('KAIKKI_LASKUT')
      setForceFetchData(true)
    }
  }, [boksi, checked])

  useInterval(() => {
    if (isAdmin && process.env.NODE_ENV !== 'development') {
      cLog('TÄMÄ ON SÄÄNNÖLLINEN PÄIVITYS ' + updateFrequencyMs / 1000 + ' SEKUNNIN VÄLEIN -----------------')
      getData()
    }
  }, updateFrequencyMs)

  useEffect(() => {
    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)

      let sKey = sortKeyInvoices

      if (!isAdmin) {
        if (checked !== 'KULULASKUT') {
          if (!['invoice_nr', 'date', 'date_due', 'customer', 'total_corrected_eur', 'pay_day', 'status', 'actions'].includes(sortKeyInvoices)) {
            dispatch(setSortKeyInvoices('invoice_nr'))
            dispatch(setSortOrderInvoices('DESC'))
            sKey = 'invoice_nr'
          }
        } else {
          if (!['expence_nr', 'date', 'total_with_tax', 'status', 'actions'].includes(sortKeyInvoices)) {
            dispatch(setSortKeyInvoices('expence_nr'))
            dispatch(setSortOrderInvoices('DESC'))
            sKey = 'expence_nr'
          }
        }
      } else {
        if (checked !== 'KULULASKUT') {
          if (['expence_nr', 'expence_type', 'expence_total', 'is_copy', 'status_valmis_date', 'status_hylatty_date', 'sent_date'].includes(sortKeyInvoices)) {
            dispatch(setSortKeyInvoices('invoice_nr'))
            dispatch(setSortOrderInvoices('DESC'))
            sKey = 'invoice_nr'
          }
        } else {
          if (['invoice_nr', 'date_due', 'customer', 'total_address', 'email', 'phone', 'is_private', 'business_id', 'contact_person', 'delivery_method', 'reminder_notes', 'reminder_date_from', 'reminder_date_to', 'reminder_handling', 'language', 'einvoice_operator', 'einvoice_address', 'work_period', 'commission', 'yel', 'invoice_total', 'orig_total_vat_excluded', 'tax', 'total_corrections', 'total_corrections_vat', 'total_corrections_with_vat', 'orig_total_vat_excluded_eur', 'tax_eur', 'total_with_tax_eur', 'total_corrected_eur', 'total_corrected_vat_eur', 'total_corrected_with_vat_eur', 'currency', 'ref_text', 'vat_status', 'total_payments_with_tax', 'pay_day', 'accounting_status', 'industry', 'custom_industry', 'consolidated_invoice', 'belongs_to_consolidated_invoice', 'credit_note', 'belongs_to_credit_note', 'salary_data', 'status_tarkastettavana_date', 'status_lahetetty_date', 'status_maksettu_liikaa_date', 'status_perinnassa_date', 'status_ulosotossa_date', 'status_luottotappio_date', 'status_muistutettu_date', 'status_hyvitetty_date', 'status_hyvityslasku_date', 'accounting_status_osittain_tilitetty_date', 'accounting_status_tilitetty_date'].includes(sortKeyInvoices)) {
            dispatch(setSortKeyInvoices('expence_nr'))
            dispatch(setSortOrderInvoices('DESC'))
            sKey = 'expence_nr'
          }
        }
      }

      if (sortKeyInvoices === 'customer') {
        sKey = 'name'
      } else if (sortKeyInvoices === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyInvoices === 'belongs_to_consolidated_invoice') {
        sKey = 'consolidated_invoice_id'
      } else if (sortKeyInvoices === 'is_copy') {
        sKey = 'original_expence_id'
      }

      let query = '?page=' + (invoicePagination.page - 1)
      query += '&rows=' + invoicePagination.rowsPerPage
      if (!['work_period', 'time_frame', 'expence_type', 'commission', 'salary_data'].includes(sKey)) {
        query += '&sort_key=' + sKey
      }
      query += '&sort_order=' + sortOrderInvoices
      Object.keys(invoiceFilter).forEach(key => {
        if (!['type', 'columnsOptions'].includes(key) && invoiceFilter[key].length > 0) {
          if (Array.isArray(invoiceFilter[key])) {
            query += `&${camelToSnakeCase(key)}=${invoiceFilter[key].join(',')}`
          } else {
            query += `&${camelToSnakeCase(key)}=${invoiceFilter[key]}`
          }
        }
      })

      if (isAdmin) {
        let columns = camelToSnakeCase(selectedColumns.toString())

        if (invoiceFilter.type === 'LASKUT' || checked === 'LASKUT') {
          if (boksi) {
            query += '&boksi=true'
          }
          if (invoiceFilter.includeDestroyed) {
            query += '&include_destroyed=true'
          }

          columns = columns.replace('created_at', 'invoices.created_at')
          columns = columns.replace('updated_at', 'invoices.updated_at')
          columns = columns.replace('customer', 'name')
          columns = columns.replace('worker', 'invoices.user_id')
          columns = columns.replace('total_address', 'invoices.address1,invoices.address2,invoices.postal_code,invoices.city,invoices.country')
          columns = columns.replace('email', 'invoices.email')
          columns = columns.replace('phone', 'invoices.phone')
          columns = columns.replace('business_id', 'invoices.business_id')
          columns = columns.replace('language', 'invoices.language')
          columns = columns.replace('work_period', 'date_start_work,date_end_work')
          columns = columns.replace('yel', 'invoices.yel')
          columns = columns.replace('industry', 'invoices.industry')
          columns = columns.replace('custom_industry', 'invoices.custom_industry')
          columns = columns.replace(',actions', '')
          query += '&columns=invoices.id,' + columns
        } else {
          columns = columns.replace('created_at', 'expences.created_at')
          columns = columns.replace('updated_at', 'expences.updated_at')
          columns = columns.replace('worker', 'expences.user_id')
          columns = columns.replace(',actions', '')
          query += '&columns=expences.id,' + columns
        }
      }

      if (invoiceFilter.type === 'LASKUT' || checked === 'LASKUT') {
        const allInvoices = getInvoices(query)
        allInvoices.then(response => {
          cLog('KAIKKI LASKUT LANDING SCREEN', response)
          const { totalRows, totalPages, currentPage } = response
          setInvoicePagination({ ...invoicePagination, totalRows, totalPages, currentPage })
          dispatch(setInvoices(response.invoices))
          setLoading(false)
        }, error => {
          cLog('FETCHING DATA ERROR', error)
          notifyOffice(isAdmin, enqueueSnackbar, error, false)
          setInvoicePagination({ ...invoicePagination, currentPage: 0, page: 1, totalPages: 0, totalRows: 0 })
          dispatch(setInvoices([]))
          setLoading(false)
        })
      } else {
        const allExpences = getExpences(query)
        allExpences.then(response => {
          cLog('KAIKKI KULULASKUT LANDING SCREEN', response)
          const { totalRows, totalPages, currentPage } = response
          setInvoicePagination({ ...invoicePagination, totalRows, totalPages, currentPage })
          dispatch(setExpences(response.expences))
          setLoading(false)
        }, error => {
          cLog('FETCHING DATA ERROR', error)
          notifyOffice(isAdmin, enqueueSnackbar, error, false)
          setInvoicePagination({ ...invoicePagination, currentPage: 0, page: 1, totalPages: 0, totalRows: 0 })
          dispatch(setExpences([]))
          setLoading(false)
        })
      }
    }
  }, [dispatch, forceFetchData, invoiceFilter, invoicePagination, checked, boksi, isAdmin, sortKeyInvoices, sortOrderInvoices, enqueueSnackbar])

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setInvoicePagination({ ...invoicePagination, page: 1 })
        getData()
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [invoicePagination])

  useEffect(() => {
    if (isMobile && !isAdmin) {
      setExpanded('')
    }
  }, [isMobile, isAdmin])

  const columns = []
  const statuses = !isAdmin ? [] : ["POISTETTU"]
  if (invoiceFilter.type === 'LASKUT' || checked === 'LASKUT') {
    columns.push('invoiceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'dateDue', 'customer', 'totalAddress', 'email', 'phone', 'isPrivate', 'businessId', 'contactPerson', 'deliveryMethod', 'reminderDate', 'reminderNotes', 'reminderHandling', 'language', 'einvoiceOperator', 'einvoiceAddress', 'worker', 'workPeriod', 'timeFrame', 'yel', 'invoiceTotal', 'travelTotal', 'origTotalVatExcluded', 'tax', 'totalWithTax', 'totalCorrections', 'totalCorrectionsVat', 'totalCorrectionsWithVat', 'origTotalVatExcludedEur', 'taxEur', 'totalWithTaxEur', 'totalCorrectedEur', 'totalCorrectedVatEur', 'totalCorrectedWithVatEur', 'salariesPayableAmount', 'currency', 'refText', 'refCode', 'vatStatus', 'totalPaymentsWithTax', 'payDay', 'status', 'totalAccounting', 'accountingStatus', 'info', 'adminInfo', 'internalNotes', 'industry', 'customIndustry', 'consolidatedInvoice', 'belongsToConsolidatedInvoice', 'creditNote', 'belongsToCreditNote', 'salaryData', 'statusTarkastettavanaDate', 'statusLahetettyDate', 'statusOsittainMaksettuDate', 'statusMaksettuDate', /*'statusMaksettuLiikaaDate', */'statusPoistettuDate', /*'statusPerinnassaDate', 'statusUlosotossaDate', */'statusLuottotappioDate', /*'statusMuistutettuDate', */'statusHyvitettyDate', 'statusHyvityslaskuDate', 'accountingStatusOsittainTilitettyDate', 'accountingStatusTilitettyDate', 'actions')
    statuses.push("LUONNOS", "TARKASTETTAVANA", "LÄHETETTY", "OSITTAIN_MAKSETTU", "MAKSETTU", /*"PERINNÄSSÄ", "MUISTUTETTU", */"HYVITETTY")
    if (isAdmin) {
      statuses.push(/*"MAKSETTU_LIIKAA", "ULOSOTOSSA", */"LUOTTOTAPPIO", 'HYVITYSLASKU')
    }
  } else {
    columns.push('expenceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'worker', 'expenceType', 'timeFrame', 'expenceTotal', 'travelTotal', 'totalWithTax', 'status', 'info', 'adminInfo', 'internalNotes', 'refCode', 'isCopy', 'totalAccounting', 'salariesPayableAmount', 'statusValmisDate', 'statusOsittainMaksettuDate', 'statusMaksettuDate', 'statusHylattyDate', 'statusPoistettuDate', 'sentDate', 'actions')
    if (isAdmin) {
      statuses.push("LUONNOS", "VALMIS", "OSITTAIN_MAKSETTU", "MAKSETTU", "HYLÄTTY")
    } else {
      statuses.push("VALMIS", "OSITTAIN_MAKSETTU", "MAKSETTU", "HYLÄTTY")
    }
  }
  const columnsOptions = columns.map(option => ({ label: t('invoice.'+option), value: option }))
  const countryOptions = Object.keys(COUNTRIES).map(country => ({ label: t('countries.'+COUNTRIES[country]), value: COUNTRIES[country] }))
  const isPrivateOptions = ['true', 'false'].map(option => ({ label: t('taxcard.'+option), value: option }))
  const deliveryMethodOptions = ['POSTI', 'SÄHKÖPOSTI', 'VERKKOLASKU', 'EI_LÄHETETÄ'].map(option => ({ label: t('invoice.'+option), value: option }))
  const reminderHandlingOptions = ['AUTOMAATTINEN', 'EI_MUISTUTETA'].map(option => ({ label: t('invoice.'+option.replace(/_/g, ' ')), value: option }))
  const languageOptions = Object.keys(LANGUAGES).map(lang => ({ label: t('customers.'+lang), value: LANGUAGES[lang] }))
  const eInvoiceOptions = EINVOICE_OPERATORS.map(operator => ({ label: operator, value: operator }))
  const currencyOptions = ['EUR', 'USD', 'SEK', 'GBP', 'NOK'].map(currency => ({ label: currency, value: currency }))
  const yelOptions = ['true', 'false'].map(option => ({ label: t('taxcard.'+option), value: option }))
  const statusOptions = statuses.map(status => ({ label: t('invoice.'+status), value: status }))
  const vatStatusOptions = VATSTATUSES.map(status => ({ label: t('invoice.'+status.replace(/_/g, ' '), {keySeparator: '.', nsSeparator: false}), value: status }))
  const payDayOptions = ["HETI", "PIKAMAKSU", "KUN_MAKSETTU", "NOSTETAAN_MYOHEMMIN"]
    .map(option => ({ label: t('invoice.'+option.replace('_', ' ')), value: option }))
  const accountingOptions = [ "TILITTÄMÄTÖN", "OSITTAIN_TILITETTY", "TILITETTY" ]
    .map(option => ({ label: t('invoice.'+option), value: option }))
  const industryOptions =  Array.from(INDUSTRIES).map(([key]) => ({ label: t('user.'+key), value: key }))

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const changePaginationDropdownToExpence = () => {
    if (isAdmin) {
      selectedColumns = JSON.parse(window.localStorage.getItem('landingScreenExpenceSelectedColumns')) || ['expenceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'worker', 'expenceType', 'timeFrame', 'totalWithTax', 'status', 'actions']
      dispatch(setSortKeyInvoices('updated_at'))
    } else {
      selectedColumns = ['expenceNr', 'date', 'totalWithTax', 'status', 'actions']
      dispatch(setSortKeyInvoices('expence_nr'))
    }
    setInvoiceFilter({ ...INIT_FILTER, columnsOptions: selectedColumns, type: 'KULULASKUT' })
    dispatch(setSortOrderInvoices('DESC'))
  }

  const changePaginationDropdownToInvoice = () => {
    if (isAdmin) {
      selectedColumns = JSON.parse(window.localStorage.getItem('landingScreenInvoiceSelectedColumns')) || ['invoiceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'dateDue', 'worker', 'workPeriod', 'totalCorrectedEur', 'payDay', 'status', 'accountingStatus', 'reminderHandling', 'actions']
      dispatch(setSortKeyInvoices('updated_at'))
    } else {
      selectedColumns = ['invoiceNr', 'date', 'dateDue', 'customer', 'totalCorrectedEur', 'payDay', 'status', 'actions']
      dispatch(setSortKeyInvoices('invoice_nr'))
    }
    dispatch(setSortOrderInvoices('DESC'))
    setInvoiceFilter({ ...INIT_FILTER, columnsOptions: selectedColumns, type: 'LASKUT' })
  }

  const handleChange = async (event) => {
    dispatch(setSelected1([]))
    setInvoicePagination({ ...invoicePagination, page: 1 })
    const name = event.target.name

    if (name === 'KULULASKUT') {
      changePaginationDropdownToExpence()
    } else {
      changePaginationDropdownToInvoice()
    }

    if (name === 'KAIKKI_LASKUT') {
      setInvoiceFilter({ ...INIT_FILTER, columnsOptions: selectedColumns, type: 'LASKUT' })
    } else if (name === 'KÄSITTELYSSÄ') {
      setInvoiceFilter({ ...INIT_FILTER, status: ['TARKASTETTAVANA'], from: '2020-01-01' })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('ASC'))
    } else if (name === 'SUORITUKSET') {
      setInvoiceFilter({ ...INIT_FILTER, status: ['LÄHETETTY', 'OSITTAIN_MAKSETTU'] })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('DESC'))
    } else if (name === 'MAKSETTU_LIIKAA') {
      setInvoiceFilter({ ...INIT_FILTER, paymentStatus: ['MAKSETTU_LIIKAA'] })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('DESC'))
    } else if (name === 'TILITTÄMÄTTÖMÄT') {
      setInvoiceFilter({ ...INIT_FILTER, status: ['MAKSETTU'], accountingStatus: ["TILITTÄMÄTÖN", "OSITTAIN_TILITETTY"] })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('DESC'))
    } else if (name === 'MAKSULISTA') {
      setInvoiceFilter({ ...INIT_FILTER, status: ['MAKSETTU'], payDay: ['PIKAMAKSU', 'KUN_MAKSETTU'], accountingStatus: ["TILITTÄMÄTÖN", "OSITTAIN_TILITETTY"] })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('ASC'))
    } else if (name === 'BOKSI_MAKSULISTA') {
      setInvoiceFilter({ ...INIT_FILTER, accountingStatus: ["TILITTÄMÄTÖN", "OSITTAIN_TILITETTY"] })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('ASC'))
    } else if (name === 'HETIPALKKAPYYNNÖT') {
      setInvoiceFilter({ ...INIT_FILTER, status: ["LÄHETETTY", "OSITTAIN_MAKSETTU"], payDay: ['HETI'], accountingStatus: ["TILITTÄMÄTÖN"] })
      dispatch(setSortKeyInvoices('updated_at'))
      dispatch(setSortOrderInvoices('ASC'))
    } else if (name === 'HETIPALKAT') {
      setInvoiceFilter({ ...INIT_FILTER, status: ['LÄHETETTY', "OSITTAIN_MAKSETTU"], payDay: ['HETI'], accountingStatus: ["TILITETTY", "OSITTAIN_TILITETTY"] })
      dispatch(setSortKeyInvoices('date_due'))
      dispatch(setSortOrderInvoices('ASC'))
    }

    setChecked(name);
    scrollTo('filter')
    getData()
  }

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + invoicePagination.totalRows + t('invoice.pieces')) : ' '
    if (isAdmin) {
      if (checked === 'KÄSITTELYSSÄ') {
        return t('invoice.beingHandled') + coda
      } else if (checked === 'SUORITUKSET') {
        return t('invoice.completed') + coda
      } else if (checked === 'TILITTÄMÄTTÖMÄT') {
        return t('invoice.unaccounted') + coda
      } else if (checked === 'MAKSULISTA' || checked === 'BOKSI_MAKSULISTA') {
        return t('invoice.paymentList') + coda
      } else if (checked === 'HETIPALKKAPYYNNÖT') {
        return t('invoice.immediatePayRequests') + coda
      } else if (checked === 'HETIPALKAT') {
        return t('invoice.immediatePay') + coda
      } else if (checked === 'KAIKKI_LASKUT') {
        return t('invoice.allInvoices') + coda
      } else if (checked === 'MAKSETTU_LIIKAA') {
        return t('invoice.overPaid') + coda
      } else if (checked === 'KULULASKUT') {
        return t('invoice.expences') + coda
      } else {
        return t('invoice.chosenInvoices') + coda
      }
    } else {
      if (invoiceFilter.type === 'LASKUT' || checked === 'LASKUT') {
        return t('invoice.invoices') + ' ' + coda
      } else {
        return t('invoice.expences') + ' ' + coda
      }
    }
  }

  const from = invoicePagination.currentPage * invoicePagination.rowsPerPage
  const to = invoicePagination.currentPage === invoicePagination.totalPages - 1
    ? from + invoicePagination.totalRows % invoicePagination.rowsPerPage
    : from + invoicePagination.rowsPerPage
  let fromTo = ''
  if (invoicePagination.totalRows) {
    fromTo = `${from + 1} - ${to} / ${invoicePagination.totalRows}`
  } else {
    fromTo = `0 - 0 / 0`
  }

  const mapSortValue = (sortKey) => {
    if (sortKey === 'customer') {
      return 'name'
    } else if (sortKey === 'worker') {
      return 'user_id'
    } else if (sortKey === 'belongs_to_consolidated_invoice') {
      return 'consolidated_invoice_id'
    }

    return sortKey
  }

  const NewPagination = () => {
    let sortByLabel = {}

    if (isAdmin) {
      if (invoiceFilter.type === 'LASKUT') {
        if (selectedColumns.includes('invoiceNr')) {
          sortByLabel['invoice_nr'] = t('invoice.invoiceNr')
        }
        if (selectedColumns.includes('createdAt')) {
          sortByLabel['created_at'] = t('invoice.createdAt')
        }
        if (selectedColumns.includes('updatedAt')) {
          sortByLabel['updated_at'] = t('invoice.updatedAt')
        }
        if (selectedColumns.includes('updater')) {
          sortByLabel['updater'] = t('invoice.updater')
        }
        if (selectedColumns.includes('date')) {
          sortByLabel['date'] = t('invoice.date')
        }
        if (selectedColumns.includes('dateDue')) {
          sortByLabel['date_due'] = t('invoice.dateDue')
        }
        if (selectedColumns.includes('customer')) {
          sortByLabel['name'] = t('invoice.customer')
        }
        if (selectedColumns.includes('totalAddress')) {
          sortByLabel['total_address'] = t('invoice.totalAddress')
        }
        if (selectedColumns.includes('email')) {
          sortByLabel['email'] = t('invoice.email')
        }
        if (selectedColumns.includes('isPrivate')) {
          sortByLabel['is_private'] = t('invoice.isPrivate')
        }
        if (selectedColumns.includes('businessId')) {
          sortByLabel['business_id'] = t('invoice.businessId')
        }
        if (selectedColumns.includes('contactPerson')) {
          sortByLabel['contact_person'] = t('invoice.contactPerson')
        }
        if (selectedColumns.includes('deliveryMethod')) {
          sortByLabel['delivery_method'] = t('invoice.deliveryMethod')
        }
        if (selectedColumns.includes('reminderDate')) {
          sortByLabel['reminder_date'] = t('invoice.reminderDate')
        }
        if (selectedColumns.includes('reminderNotes')) {
          sortByLabel['reminder_notes'] = t('invoice.reminderNotes')
        }
        if (selectedColumns.includes('reminderHandling')) {
          sortByLabel['reminder_handling'] = t('invoice.reminder')
        }
        if (selectedColumns.includes('language')) {
          sortByLabel['language'] = t('invoice.language')
        }
        if (selectedColumns.includes('einvoiceOperator')) {
          sortByLabel['einvoice_operator'] = t('invoice.einvoiceOperator')
        }
        if (selectedColumns.includes('einvoiceAddress')) {
          sortByLabel['einvoice_address'] = t('invoice.einvoiceAddress')
        }
        if (selectedColumns.includes('worker')) {
          sortByLabel['user_id'] = t('invoice.worker')
        }
        if (selectedColumns.includes('yel')) {
          sortByLabel['yel'] = t('invoice.yel')
        }
        if (selectedColumns.includes('invoiceTotal')) {
          sortByLabel['invoice_total'] = t('invoice.invoiceTotal')
        }
        if (selectedColumns.includes('travelTotal')) {
          sortByLabel['travel_total'] = t('invoice.travelTotal')
        }
        if (selectedColumns.includes('origTotalVatExcluded')) {
          sortByLabel['orig_total_vat_excluded'] = t('invoice.origTotalVatExcluded')
        }
        if (selectedColumns.includes('tax')) {
          sortByLabel['tax'] = t('invoice.tax')
        }
        if (selectedColumns.includes('totalWithTax')) {
          sortByLabel['total_with_tax'] = t('invoice.totalWithTax')
        }
        if (selectedColumns.includes('totalCorrections')) {
          sortByLabel['total_corrections'] = t('invoice.totalCorrections')
        }
        if (selectedColumns.includes('totalCorrectionsVat')) {
          sortByLabel['total_corrections_vat'] = t('invoice.totalCorrectionsVat')
        }
        if (selectedColumns.includes('totalCorrectionsWithVat')) {
          sortByLabel['total_corrections_with_vat'] = t('invoice.totalCorrectionsWithVat')
        }
        if (selectedColumns.includes('origTotalVatExcludedEur')) {
          sortByLabel['orig_total_vat_excluded_eur'] = t('invoice.origTotalVatExcludedEur')
        }
        if (selectedColumns.includes('taxEur')) {
          sortByLabel['tax_eur'] = t('invoice.taxEur')
        }
        if (selectedColumns.includes('totalWithTaxEur')) {
          sortByLabel['total_with_tax_eur'] = t('invoice.totalWithTaxEur')
        }
        if (selectedColumns.includes('totalCorrectedEur')) {
          sortByLabel['total_corrected_eur'] = t('invoice.totalCorrectedEur')
        }
        if (selectedColumns.includes('totalCorrectedVatEur')) {
          sortByLabel['total_corrected_vat_eur'] = t('invoice.totalCorrectedVatEur')
        }
        if (selectedColumns.includes('totalCorrectedWithVatEur')) {
          sortByLabel['total_corrected_with_vat_eur'] = t('invoice.totalCorrectedWithVatEur')
        }
        if (selectedColumns.includes('salariesPayableAmount')) {
          sortByLabel['salaries_payable_amount'] = t('invoice.salariesPayableAmount')
        }
        if (selectedColumns.includes('currency')) {
          sortByLabel['currency'] = t('invoice.currency')
        }
        if (selectedColumns.includes('refText')) {
          sortByLabel['ref_text'] = t('invoice.refText')
        }
        if (selectedColumns.includes('refCode')) {
          sortByLabel['ref_code'] = t('invoice.refCode')
        }
        if (selectedColumns.includes('vatStatus')) {
          sortByLabel['vat_status'] = t('invoice.vatStatus')
        }
        if (selectedColumns.includes('totalPaymentsWithTax')) {
          sortByLabel['total_payments_with_tax'] = t('invoice.totalPaymentsWithTax')
        }
        if (selectedColumns.includes('payDay')) {
          sortByLabel['pay_day'] = t('invoice.payDay')
        }
        if (selectedColumns.includes('status')) {
          sortByLabel['status'] = t('invoice.status')
        }
        if (selectedColumns.includes('totalAccounting')) {
          sortByLabel['total_accounting'] = t('invoice.totalAccounting')
        }
        if (selectedColumns.includes('accountingStatus')) {
          sortByLabel['accounting_status'] = t('invoice.isPaid')
        }
        if (selectedColumns.includes('info')) {
          sortByLabel['info'] = t('invoice.info')
        }
        if (selectedColumns.includes('adminInfo')) {
          sortByLabel['admin_info'] = t('invoice.adminInfo')
        }
        if (selectedColumns.includes('internalNotes')) {
          sortByLabel['internal_notes'] = t('invoice.internalNotes')
        }
        if (selectedColumns.includes('industry')) {
          sortByLabel['industry'] = t('invoice.industry')
        }
        if (selectedColumns.includes('customIndustry')) {
          sortByLabel['custom_industry'] = t('invoice.customIndustry')
        }
        if (selectedColumns.includes('consolidatedInvoice')) {
          sortByLabel['consolidated_invoice'] = t('invoice.consolidatedInvoice')
        }
        if (selectedColumns.includes('belongsToConsolidatedInvoice')) {
          sortByLabel['consolidated_invoice_id'] = t('invoice.belongsToConsolidatedInvoice')
        }
        if (selectedColumns.includes('creditNote')) {
          sortByLabel['credit_note'] = t('invoice.creditNote')
        }
        /*if (selectedColumns.includes('belongsToCreditNote')) {
          sortByLabel['credit_note_id'] = t('invoice.belongsToCreditNote')
        }*/
        if (selectedColumns.includes('statusTarkastettavanaDate')) {
          sortByLabel['status_tarkastettavana_date'] = t('invoice.statusTarkastettavanaDate')
        }
        if (selectedColumns.includes('statusLahetettyDate')) {
          sortByLabel['status_lahetetty_date'] = t('invoice.statusLahetettyDate')
        }
        if (selectedColumns.includes('statusOsittainMaksettuDate')) {
          sortByLabel['status_osittain_maksettu_date'] = t('invoice.statusOsittainMaksettuDate')
        }
        if (selectedColumns.includes('statusMaksettuDate')) {
          sortByLabel['status_maksettu_date'] = t('invoice.statusMaksettuDate')
        }
        /*if (selectedColumns.includes('statusMaksettuLiikaaDate')) {
          sortByLabel['status_maksettu_liikaa_date'] = t('invoice.statusMaksettuLiikaaDate')
        }*/
        if (selectedColumns.includes('statusPoistettuDate')) {
          sortByLabel['status_poistettu_date'] = t('invoice.statusPoistettuDate')
        }
        /*if (selectedColumns.includes('statusPerinnassaDate')) {
          sortByLabel['status_perinnassa_date'] = t('invoice.statusPerinnassaDate')
        }
        if (selectedColumns.includes('statusUlosotossaDate')) {
          sortByLabel['status_ulosotossa_date'] = t('invoice.statusUlosotossaDate')
        }*/
        if (selectedColumns.includes('statusLuottotappioDate')) {
          sortByLabel['status_luottotappio_date'] = t('invoice.statusLuottotappioDate')
        }
        /*if (selectedColumns.includes('statusMuistutettuDate')) {
          sortByLabel['status_muistutettu_date'] = t('invoice.statusMuistutettuDate')
        }*/
        if (selectedColumns.includes('statusHyvitettyDate')) {
          sortByLabel['status_hyvitetty_date'] = t('invoice.statusHyvitettyDate')
        }
        if (selectedColumns.includes('statusHyvityslaskuDate')) {
          sortByLabel['status_hyvityslasku_date'] = t('invoice.statusHyvityslaskuDate')
        }
        if (selectedColumns.includes('accountingStatusOsittainTilitettyDate')) {
          sortByLabel['accounting_status_osittain_tilitetty_date'] = t('invoice.accountingStatusOsittainTilitettyDate')
        }
        if (selectedColumns.includes('accountingStatusTilitettyDate')) {
          sortByLabel['accounting_status_tilitetty_date'] = t('invoice.accountingStatusTilitettyDate')
        }
      } else {
        if (selectedColumns.includes('expenceNr')) {
          sortByLabel['expence_nr'] = t('invoice.expenceNr')
        }
        if (selectedColumns.includes('createdAt')) {
          sortByLabel['created_at'] = t('invoice.createdAt')
        }
        if (selectedColumns.includes('updatedAt')) {
          sortByLabel['updated_at'] = t('invoice.updatedAt')
        }
        if (selectedColumns.includes('date')) {
          sortByLabel['date'] = t('invoice.date')
        }
        if (selectedColumns.includes('worker')) {
          sortByLabel['user_id'] = t('invoice.worker')
        }
        if (selectedColumns.includes('expenceTotal')) {
          sortByLabel['expence_total'] = t('invoice.expenceTotal')
        }
        if (selectedColumns.includes('travelTotal')) {
          sortByLabel['travel_total'] = t('invoice.travelTotal')
        }
        if (selectedColumns.includes('totalWithTax')) {
          sortByLabel['total_with_tax'] = t('invoice.totalWithTax')
        }
        if (selectedColumns.includes('status')) {
          sortByLabel['status'] = t('invoice.expenseStatus')
        }
        if (selectedColumns.includes('info')) {
          sortByLabel['info'] = t('invoice.info')
        }
        if (selectedColumns.includes('adminInfo')) {
          sortByLabel['admin_info'] = t('invoice.adminInfo')
        }
        if (selectedColumns.includes('internalNotes')) {
          sortByLabel['internal_notes'] = t('invoice.internalNotes')
        }
        if (selectedColumns.includes('refCode')) {
          sortByLabel['ref_code'] = t('invoice.refCode')
        }
        if (selectedColumns.includes('isCopy')) {
          sortByLabel['original_expence_id'] = t('invoice.isCopy')
        }
        if (selectedColumns.includes('totalAccounting')) {
          sortByLabel['total_accounting'] = t('invoice.totalAccounting')
        }
        if (selectedColumns.includes('salariesPayableAmount')) {
          sortByLabel['salaries_payable_amount'] = t('invoice.salariesPayableAmount')
        }
        if (selectedColumns.includes('statusValmisDate')) {
          sortByLabel['status_valmis_date'] = t('invoice.statusValmisDate')
        }
        if (selectedColumns.includes('statusOsittainMaksettuDate')) {
          sortByLabel['status_osittain_maksettu_date'] = t('invoice.statusOsittainMaksettuDate')
        }
        if (selectedColumns.includes('statusMaksettuDate')) {
          sortByLabel['status_maksettu_date'] = t('invoice.statusMaksettuDate')
        }
        if (selectedColumns.includes('statusHylattyDate')) {
          sortByLabel['status_hylatty_date'] = t('invoice.statusHylattyDate')
        }
        if (selectedColumns.includes('statusPoistettuDate')) {
          sortByLabel['status_poistettu_date'] = t('invoice.statusPoistettuDate')
        }
        if (selectedColumns.includes('sentDate')) {
          sortByLabel['status_hylatty_date'] = t('invoice.sentDate')
        }
      }
    } else {
      if (invoiceFilter.type === 'LASKUT') {
        sortByLabel['invoice_nr'] = t('invoice.invoiceNr')
        sortByLabel['date'] = t('invoice.date')
        sortByLabel['date_due'] = t('invoice.dateDue')
        sortByLabel['name'] = t('invoice.customer')
        sortByLabel['total_corrected_eur'] = t('invoice.totalWithoutVat')
        sortByLabel['pay_day'] = t('invoice.payDay')
        sortByLabel['status'] = t('invoice.status')
        sortByLabel['accounting_status'] = t('invoice.isPaid')
      } else {
        sortByLabel['expence_nr'] = t('invoice.expenceNr')
        sortByLabel['date'] = t('invoice.date')
        sortByLabel['total_with_tax'] = t('invoice.totalWithTax')
        sortByLabel['status'] = t('invoice.expenseStatus')
      }
    }

    const sortOrderLabel = {
      'DESC': t('invoice.desc'),
      'ASC': t('invoice.asc')
    }

    const rowsPerPageArray = [10, 20, 50, 75, 100]
    if (isAdmin) {
      rowsPerPageArray.push(200, 300, 400, 500, 1000)
    }

    return (
      <div className={classes.newPaginationRow}>
        <Pagination pagination={invoicePagination} setPagination={setInvoicePagination} getData={getData} />
        <span className={classes.newPaginationInfo}>{fromTo}</span>
        {!isMobile &&
          <span>
            <span className={classes.newPaginationInfo}>{t('invoice.rowsInPage')}</span>
            <select
              value={invoicePagination.rowsPerPage}
              onChange={(e) => {
              setInvoicePagination({ ...invoicePagination, page: 1, rowsPerPage: parseInt(e.target.value) })
                getData()
              }}
              disabled={isLoading}
            >
              {rowsPerPageArray.map((row, i) => <option key={i}>{row}</option>)}
            </select>
          </span>}
        <span>
          {!isMobile && <span className={classes.newPaginationInfo}>{t('invoice.sort')}</span> }
          <select
            value={mapSortValue(sortKeyInvoices)}
            onChange={(e) => {
              dispatch(setSortKeyInvoices(e.target.value))
              getData()
            }}
            disabled={isLoading}
          >
            {Object.keys(sortByLabel).map((row, i) => <option value={row} key={i}>{sortByLabel[row]}</option>)}
          </select>
          <select
            value={sortOrderInvoices}
            onChange={(e) => {
              dispatch(setSortOrderInvoices(e.target.value))
              getData()
            }}
            disabled={isLoading}
          >
            {['DESC', 'ASC'].map((row, i) => <option value={row} key={i}>{sortOrderLabel[row]}</option>)}
          </select>
        </span>
        {isAdmin &&
          <span>
            <span className={classes.newPaginationInfo}>{t('taxcard.columns')}</span>
            <DropdownChecbox
              placeholder={t('invoice.pickOrType')}
              options={columnsOptions}
              onChange={(selectedOptions) => {
                selectedColumns = selectedOptions ? selectedOptions.map(option => option.value) : []
                if (invoiceFilter.type === 'LASKUT' || checked === 'LASKUT') {
                  window.localStorage.setItem('landingScreenInvoiceSelectedColumns', JSON.stringify(selectedColumns))
                } else {
                  window.localStorage.setItem('landingScreenExpenceSelectedColumns', JSON.stringify(selectedColumns))
                }
                setInvoiceFilter({ ...invoiceFilter, columnsOptions: selectedColumns })
              }}
              value={columnsOptions.filter(option => selectedColumns.includes(option.value))}
            />
          </span>}
        <span>
        {isLoading
          ? <CircularProgress size={25} />
          : <button type='button' onClick={() => getData()} disabled={isLoading}>{t('taxcard.fetch')}</button>}
        {isAdmin && !isLoading && invoicePagination.totalRows > 0 &&
          <>
            <button type='button' onClick={() => setOpenDownloadDialog(true)} disabled={isLoading}>{t('taxcard.download')}</button>
            <DownloadDialog open={openDownloadDialog} setOpenDownloadDialog={setOpenDownloadDialog} type={invoiceFilter.type === 'LASKUT' || checked === 'LASKUT' ? 'invoices' : 'expences'} filter={invoiceFilter} sortKey={sortKeyInvoices} sortOrder={sortOrderInvoices} />
          </>
        }
        </span>
      </div>
    )
  }

  return (
    <>
      <div className={classes.filtersContainer} id='filter'>
        <Accordion expanded style={{ borderRadius: '5px' }}>
          <AccordionDetails style={{display: "block"}}>
            <Grid container spacing={1}>
              {checked !== 'KULULASKUT' && (
                <>
                  {isAdmin && <Grid item xs={12} md={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'KÄSITTELYSSÄ'} onChange={handleChange} name="KÄSITTELYSSÄ" />}
                        label={t('invoice.beingHandled')}
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'SUORITUKSET'} onChange={handleChange} name="SUORITUKSET" />}
                        label={t('invoice.completed')}
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={boksi === true} onChange={() => { setBoksi(!boksi); getData(); }} name="BOKSI" />}
                        label={`Boksi`}
                        disabled={isLoading}
                      />
                    </FormGroup>
                  </Grid>}
                  {isAdmin && <Grid item xs={12} md={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'TILITTÄMÄTTÖMÄT'} onChange={handleChange} name="TILITTÄMÄTTÖMÄT" />}
                        label={t('invoice.unaccounted')}
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'MAKSULISTA'} onChange={handleChange} name="MAKSULISTA" />}
                        label={t('invoice.paymentList')}
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'BOKSI_MAKSULISTA'} onChange={handleChange} name='BOKSI_MAKSULISTA' />}
                        label={`Boksi maksulista`}
                        disabled={isLoading || !boksi}
                      />
                    </FormGroup>
                  </Grid>}
                  {isAdmin && <Grid item xs={12} md={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'HETIPALKKAPYYNNÖT'} onChange={handleChange} name="HETIPALKKAPYYNNÖT" />}
                        label={t('invoice.immediatePayRequests')}
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'HETIPALKAT'} onChange={handleChange} name="HETIPALKAT" />}
                        label={t('invoice.immediatePay')}
                        disabled={isLoading}
                      />
                      <FormControlLabel
                        control={<MaterialUiCheckBox checked={checked === 'MAKSETTU_LIIKAA'} onChange={handleChange} name="MAKSETTU_LIIKAA" />}
                        label={t('invoice.overPaid')}
                        disabled={isLoading}
                      />
                    </FormGroup>
                  </Grid>}
                </>
              )}
              <Grid item xs={12} md={2}>
                <FormGroup>
                  <FormControlLabel
                    control={<MaterialUiCheckBox checked={checked === 'KULULASKUT'} onChange={handleChange} name="KULULASKUT" />}
                    label={t('invoice.expences')}
                    disabled={isLoading}
                  />
                  <FormControlLabel
                    control={<MaterialUiCheckBox checked={checked === 'KAIKKI_LASKUT'} onChange={handleChange} name="KAIKKI_LASKUT" />}
                    label={t('invoice.allInvoices')}
                    disabled={isLoading}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'filter'} onChange={handleChangeExpansion('filter')} style={{ borderRadius: '5px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="filterbh-content"
            id="filter-header"
          >
            {t('invoice.filterTitle')}
          </AccordionSummary>
          <AccordionDetails style={{display: "block"}}>
              <Grid container spacing={4} className={classes.gridContainer}>
              {checked !== 'KULULASKUT' && (
                <>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.invoiceNr')}
                          value={invoiceFilter.invoiceNr}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, invoiceNr: e.target.value.replace(/[^\d,]/g, '') })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="createdAt"
                          id="createdAt"
                          label={t('invoice.createdAt')}
                          selected={invoiceFilter.createdAt ? new Date(invoiceFilter.createdAt) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, createdAt: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="updatedAt"
                          id="updatedAt"
                          label={t('invoice.updatedAt')}
                          selected={invoiceFilter.updatedAt ? new Date(invoiceFilter.updatedAt) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, updatedAt: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <DPicker
                      name="from"
                      id="from"
                      label={t('invoice.dateFrom')}
                      selected={invoiceFilter.from ? new Date(invoiceFilter.from) : ''}
                      onChange={(date) => {
                        setInvoiceFilter({ ...invoiceFilter, from: date ? formatDate(date) : '' })
                      }}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <DPicker
                      name="to"
                      id="to"
                      label={t('invoice.dateTo')}
                      selected={invoiceFilter.to ? new Date(invoiceFilter.to) : ''}
                      onChange={(date) => {
                        setInvoiceFilter({ ...invoiceFilter, to: date ? formatDate(date) : '' })
                      }}
                      isClearable
                    />
                  </Grid>
                  {!isAdmin && <Grid item xs={12} md={2}>
                    <DPicker
                      name="dateDue"
                      id="dateDue"
                      label={t('invoice.dateDue')}
                      selected={invoiceFilter.dateDue ? new Date(invoiceFilter.dateDue) : ''}
                      onChange={(date) => {
                        setInvoiceFilter({ ...invoiceFilter, dateDue: date ? formatDate(date) : '' })
                      }}
                      isClearable
                    />
                  </Grid>}
                  {isAdmin &&
                    <>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="dateDueFrom"
                          id="dateDueFrom"
                          label={t('invoice.dateDueFrom')}
                          selected={invoiceFilter.dateDueFrom ? new Date(invoiceFilter.dateDueFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, dateDueFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="dateDueTo"
                          id="dateDueTo"
                          label={t('invoice.dateDueTo')}
                          selected={invoiceFilter.dateDueTo ? new Date(invoiceFilter.dateDueTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, dateDueTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                    </>
                  }
                  <Grid item xs={12} md={2}>
                    <Input
                      type='text'
                      label={t('invoice.customer')}
                      value={invoiceFilter.name}
                      onChange={(e) => {
                        setInvoiceFilter({ ...invoiceFilter, name: e.target.value })
                      }}
                    />
                  </Grid>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalAddress')}
                          value={invoiceFilter.totalAddress}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, totalAddress: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='country'
                          label={t('invoice.country')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={countryOptions}
                          value={countryOptions.filter(option => invoiceFilter.country.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, country: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.email')}
                          value={invoiceFilter.email}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, email: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.phone')}
                          value={invoiceFilter.phone}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, phone: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='isPrivate'
                          label={t('invoice.isPrivate')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={isPrivateOptions}
                          value={isPrivateOptions.filter(option => invoiceFilter.isPrivate.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, isPrivate: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.businessId')}
                          value={invoiceFilter.businessId}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, businessId: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.contactPerson')}
                          value={invoiceFilter.contactPerson}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, contactPerson: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='deliveryMethod'
                          label={t('invoice.deliveryMethod')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={deliveryMethodOptions}
                          value={deliveryMethodOptions.filter(option => invoiceFilter.deliveryMethod.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, deliveryMethod: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="reminderDateFrom"
                          id="reminderDateFrom"
                          label={t('invoice.reminderDateFrom')}
                          selected={invoiceFilter.reminderDateFrom ? new Date(invoiceFilter.reminderDateFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, reminderDateFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="reminderDateTo"
                          id="reminderDateTo"
                          label={t('invoice.reminderDateTo')}
                          selected={invoiceFilter.reminderDateTo ? new Date(invoiceFilter.reminderDateTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, reminderDateTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.reminderNotes')}
                          value={invoiceFilter.reminderNotes}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, reminderNotes: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='reminderHandling'
                          label={t('invoice.reminderHandling')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={reminderHandlingOptions}
                          value={reminderHandlingOptions.filter(option => invoiceFilter.reminderHandling.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, reminderHandling: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='language'
                          label={t('invoice.language')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={languageOptions}
                          value={languageOptions.filter(option => invoiceFilter.language.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, language: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='eInvoiceOperator'
                          label={t('invoice.einvoiceOperator')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={eInvoiceOptions}
                          value={eInvoiceOptions.filter(option => invoiceFilter.eInvoiceOperator.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, eInvoiceOperator: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.einvoiceAddress')}
                          value={invoiceFilter.eInvoiceAddress}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, eInvoiceAddress: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <SelectAsync
                          name='userId'
                          label={t('invoice.billers')}
                          placeholder={t('invoice.typeToSearch')}
                          isMulti
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, userId: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='yel'
                          label={t('user.yel')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={yelOptions}
                          value={yelOptions.filter(option => invoiceFilter.yel.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, yel: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.invoiceTotal')}
                          value={invoiceFilter.invoiceTotal}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.invoiceTotal)) {
                              setInvoiceFilter({ ...invoiceFilter, invoiceTotal: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.travelTotal')}
                          value={invoiceFilter.travelTotal}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.travelTotal)) {
                              setInvoiceFilter({ ...invoiceFilter, travelTotal: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.origTotalVatExcluded')}
                          value={invoiceFilter.origTotalVatExcluded}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.origTotalVatExcluded)) {
                              setInvoiceFilter({ ...invoiceFilter, origTotalVatExcluded: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.tax')}
                          value={invoiceFilter.tax}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.tax)) {
                              setInvoiceFilter({ ...invoiceFilter, tax: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalWithTax')}
                          value={invoiceFilter.totalWithTax}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalWithTax)) {
                              setInvoiceFilter({ ...invoiceFilter, totalWithTax: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalCorrections')}
                          value={invoiceFilter.totalCorrections}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalCorrections)) {
                              setInvoiceFilter({ ...invoiceFilter, totalCorrections: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalCorrectionsVat')}
                          value={invoiceFilter.totalCorrectionsVat}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalCorrectionsVat)) {
                              setInvoiceFilter({ ...invoiceFilter, totalCorrectionsVat: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalCorrectionsWithVat')}
                          value={invoiceFilter.totalCorrectionsWithVat}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalCorrectionsWithVat)) {
                              setInvoiceFilter({ ...invoiceFilter, totalCorrectionsWithVat: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.origTotalVatExcludedEur')}
                          value={invoiceFilter.origTotalVatExcludedEur}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.origTotalVatExcludedEur)) {
                              setInvoiceFilter({ ...invoiceFilter, origTotalVatExcludedEur: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.taxEur')}
                          value={invoiceFilter.taxEur}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.taxEur)) {
                              setInvoiceFilter({ ...invoiceFilter, taxEur: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalWithTaxEur')}
                          value={invoiceFilter.totalWithTaxEur}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalWithTaxEur)) {
                              setInvoiceFilter({ ...invoiceFilter, totalWithTaxEur: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <Input
                      type='text'
                      label={isAdmin ? t('invoice.totalCorrectedEur') : t('invoice.totalWithoutVat')}
                      value={invoiceFilter.totalCorrectedEur}
                      onChange={(e) => {
                        if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalCorrectedEur)) {
                          setInvoiceFilter({ ...invoiceFilter, totalCorrectedEur: e.target.value })
                        }
                      }}
                    />
                  </Grid>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalCorrectedVatEur')}
                          value={invoiceFilter.totalCorrectedVatEur}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalCorrectedVatEur)) {
                              setInvoiceFilter({ ...invoiceFilter, totalCorrectedVatEur: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalCorrectedWithVatEur')}
                          value={invoiceFilter.totalCorrectedWithVatEur}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalCorrectedWithVatEur)) {
                              setInvoiceFilter({ ...invoiceFilter, totalCorrectedWithVatEur: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.salariesPayableAmount')}
                          value={invoiceFilter.salariesPayableAmount}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.salariesPayableAmount)) {
                              setInvoiceFilter({ ...invoiceFilter, salariesPayableAmount: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='currency'
                          label={t('invoice.currency')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={currencyOptions}
                          value={currencyOptions.filter(option => invoiceFilter.currency.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, currency: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.refText')}
                          value={invoiceFilter.refText}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, refText: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.refCode')}
                          value={invoiceFilter.refCode}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, refCode: e.target.value.replace(/[^\d,]/g, '') })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='vatStatus'
                          label={t('invoice.vatStatus')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={vatStatusOptions}
                          value={vatStatusOptions.filter(option => invoiceFilter.vatStatus.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, vatStatus: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalPaymentsWithTax')}
                          value={invoiceFilter.totalPaymentsWithTax}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalPaymentsWithTax)) {
                              setInvoiceFilter({ ...invoiceFilter, totalPaymentsWithTax: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <DropDown
                      name='payDay'
                      label={t('invoice.payDay')}
                      placeholder={t('invoice.pickOrType')}
                      isMulti
                      options={payDayOptions}
                      value={payDayOptions.filter(option => invoiceFilter.payDay.includes(option.value))}
                      onChange={(selectedOptions) => {
                        setChecked('')
                        setInvoiceFilter({ ...invoiceFilter, payDay: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <DropDown
                      name='status'
                      label={t('invoice.status')}
                      placeholder={t('invoice.pickOrType')}
                      isMulti
                      options={statusOptions}
                      value={statusOptions.filter(option => invoiceFilter.status.includes(option.value))}
                      onChange={(selectedOptions) => {
                        setInvoiceFilter({ ...invoiceFilter, status: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                      }}
                    />
                  </Grid>
                  {isAdmin &&
                    <Grid item xs={12} md={2}>
                      <Input
                        type='text'
                        label={t('invoice.totalAccounting')}
                        value={invoiceFilter.totalAccounting}
                        onChange={(e) => {
                          if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalAccounting)) {
                            setInvoiceFilter({ ...invoiceFilter, totalAccounting: e.target.value })
                          }
                        }}
                      />
                    </Grid>
                  }
                  <Grid item xs={12} md={2}>
                    <DropDown
                      name='accountingStatus'
                      label={t('invoice.accountingStatus')}
                      placeholder={t('invoice.pickOrType')}
                      isMulti
                      options={accountingOptions}
                      value={accountingOptions.filter(option => invoiceFilter.accountingStatus.includes(option.value))}
                      onChange={(selectedOptions) => {
                        setInvoiceFilter({ ...invoiceFilter, accountingStatus: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                      }}
                    />
                  </Grid>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.info')}
                          value={invoiceFilter.info}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, info: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.adminInfo')}
                          value={invoiceFilter.adminInfo}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, adminInfo: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.internalNotes')}
                          value={invoiceFilter.internalNotes}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, internalNotes: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='industry'
                          label={t('invoice.industry')}
                          placeholder={t('invoice.pickOrType')}
                          isMulti
                          options={industryOptions}
                          value={industryOptions.filter(option => invoiceFilter.industry.includes(option.value))}
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, industry: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.customIndustry')}
                          value={invoiceFilter.customIndustry}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, customIndustry: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='consolidatedInvoice'
                          label={t('invoice.consolidatedInvoice')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={isPrivateOptions}
                          value={isPrivateOptions.filter(option => invoiceFilter.consolidatedInvoice.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, consolidatedInvoice: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='belongsToConsolidatedInvoice'
                          label={t('invoice.belongsToConsolidatedInvoice')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={isPrivateOptions}
                          value={isPrivateOptions.filter(option => invoiceFilter.belongsToConsolidatedInvoice.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, belongsToConsolidatedInvoice: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='creditNote'
                          label={t('invoice.creditNote')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={isPrivateOptions}
                          value={isPrivateOptions.filter(option => invoiceFilter.creditNote.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, creditNote: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='belongsToCreditNote'
                          label={t('invoice.belongsToCreditNote')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={isPrivateOptions}
                          value={isPrivateOptions.filter(option => invoiceFilter.belongsToCreditNote.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, belongsToCreditNote: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.salaryData')}
                          value={invoiceFilter.salaryId}
                          onChange={(e) => {
                            if (validateInt(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.salaryId)) {
                              setInvoiceFilter({ ...invoiceFilter, salaryId: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusTarkastettavanaDate"
                          id="statusTarkastettavanaDate"
                          label={t('invoice.statusTarkastettavanaDate')}
                          selected={invoiceFilter.statusTarkastettavanaDate ? new Date(invoiceFilter.statusTarkastettavanaDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusTarkastettavanaDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusLahetettyDate"
                          id="statusLahetettyDate"
                          label={t('invoice.statusLahetettyDate')}
                          selected={invoiceFilter.statusLahetettyDate ? new Date(invoiceFilter.statusLahetettyDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusLahetettyDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusMaksettuDateFrom"
                          id="statusMaksettuDateFrom"
                          label={t('invoice.statusMaksettuDateFrom')}
                          selected={invoiceFilter.statusMaksettuDateFrom ? new Date(invoiceFilter.statusMaksettuDateFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusMaksettuDateFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusMaksettuDateTo"
                          id="statusMaksettuDateTo"
                          label={t('invoice.statusMaksettuDateTo')}
                          selected={invoiceFilter.statusMaksettuDateTo ? new Date(invoiceFilter.statusMaksettuDateTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusMaksettuDateTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      {/*<Grid item xs={12} md={2}>
                        <DPicker
                          name="statusMaksettuLiikaaDate"
                          id="statusMaksettuLiikaaDate"
                          label={t('invoice.statusMaksettuLiikaaDate')}
                          selected={invoiceFilter.statusMaksettuLiikaaDate ? new Date(invoiceFilter.statusMaksettuLiikaaDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusMaksettuLiikaaDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>*/}
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusPoistettuDate"
                          id="statusPoistettuDate"
                          label={t('invoice.statusPoistettuDate')}
                          selected={invoiceFilter.statusPoistettuDate ? new Date(invoiceFilter.statusPoistettuDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusPoistettuDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      {/*<Grid item xs={12} md={2}>
                        <DPicker
                          name="statusPerinnassaDate"
                          id="statusPerinnassaDate"
                          label={t('invoice.statusPerinnassaDate')}
                          selected={invoiceFilter.statusPerinnassaDate ? new Date(invoiceFilter.statusPerinnassaDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusPerinnassaDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusUlosotossaDate"
                          id="statusUlosotossaDate"
                          label={t('invoice.statusUlosotossaDate')}
                          selected={invoiceFilter.statusUlosotossaDate ? new Date(invoiceFilter.statusUlosotossaDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusUlosotossaDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>*/}
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusLuottotappioDate"
                          id="statusLuottotappioDate"
                          label={t('invoice.statusLuottotappioDate')}
                          selected={invoiceFilter.statusLuottotappioDate ? new Date(invoiceFilter.statusLuottotappioDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusLuottotappioDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      {/*<Grid item xs={12} md={2}>
                        <DPicker
                          name="statusMuistutettuDate"
                          id="statusMuistutettuDate"
                          label={t('invoice.statusMuistutettuDate')}
                          selected={invoiceFilter.statusMuistutettuDate ? new Date(invoiceFilter.statusMuistutettuDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusMuistutettuDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>*/}
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusHyvitettyDate"
                          id="statusHyvitettyDate"
                          label={t('invoice.statusHyvitettyDate')}
                          selected={invoiceFilter.statusHyvitettyDate ? new Date(invoiceFilter.statusHyvitettyDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusHyvitettyDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusHyvityslaskuDate"
                          id="statusHyvityslaskuDate"
                          label={t('invoice.statusHyvityslaskuDate')}
                          selected={invoiceFilter.statusHyvityslaskuDate ? new Date(invoiceFilter.statusHyvityslaskuDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusHyvityslaskuDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="accountingStatusOsittainTilitettyDateFrom"
                          id="accountingStatusOsittainTilitettyDateFrom"
                          label={t('invoice.accountingStatusOsittainTilitettyDateFrom')}
                          selected={invoiceFilter.accountingStatusOsittainTilitettyDateFrom ? new Date(invoiceFilter.accountingStatusOsittainTilitettyDateFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, accountingStatusOsittainTilitettyDateFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="accountingStatusOsittainTilitettyDateTo"
                          id="accountingStatusOsittainTilitettyDateTo"
                          label={t('invoice.accountingStatusOsittainTilitettyDateTo')}
                          selected={invoiceFilter.accountingStatusOsittainTilitettyDateTo ? new Date(invoiceFilter.accountingStatusOsittainTilitettyDateTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, accountingStatusOsittainTilitettyDateTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="accountingStatusTilitettyDateFrom"
                          id="accountingStatusTilitettyDateFrom"
                          label={t('invoice.accountingStatusTilitettyDateFrom')}
                          selected={invoiceFilter.accountingStatusTilitettyDateFrom ? new Date(invoiceFilter.accountingStatusTilitettyDateFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, accountingStatusTilitettyDateFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="accountingStatusTilitettyDateTo"
                          id="accountingStatusTilitettyDateTo"
                          label={t('invoice.accountingStatusTilitettyDateTo')}
                          selected={invoiceFilter.accountingStatusTilitettyDateTo ? new Date(invoiceFilter.accountingStatusTilitettyDateTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, accountingStatusTilitettyDateTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: "flex-end" }}>
                        <FormControlLabel
                          control={<MaterialUiCheckBox
                            checked={invoiceFilter.includeDestroyed}
                            onChange={() => {
                              setInvoiceFilter({ ...invoiceFilter, includeDestroyed: !invoiceFilter.includeDestroyed })
                              getData()
                            }}
                            name="includeDestroyed"
                          />}
                          label={t('invoice.includeDestroyed')}
                          disabled={isLoading}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              {checked === 'KULULASKUT' && (
                <>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.expenceNr')}
                          value={invoiceFilter.expenceNr}
                          onChange={(e) => {
                            if (validateInt(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.expenceNr)) {
                              setInvoiceFilter({ ...invoiceFilter, expenceNr: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="createdAt"
                          id="createdAt"
                          label={t('invoice.createdAt')}
                          selected={invoiceFilter.createdAt ? new Date(invoiceFilter.createdAt) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, createdAt: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="updatedAt"
                          id="updatedAt"
                          label={t('invoice.updatedAt')}
                          selected={invoiceFilter.updatedAt ? new Date(invoiceFilter.updatedAt) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, updatedAt: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <DPicker
                      name="from"
                      id="from"
                      label={t('invoice.dateFrom')}
                      selected={invoiceFilter.from ? new Date(invoiceFilter.from) : ''}
                      onChange={(date) => {
                        setInvoiceFilter({ ...invoiceFilter, from: date ? formatDate(date) : '' })
                      }}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <DPicker
                      name="to"
                      id="to"
                      label={t('invoice.dateTo')}
                      selected={invoiceFilter.to ? new Date(invoiceFilter.to) : ''}
                      onChange={(date) => {
                        setInvoiceFilter({ ...invoiceFilter, to: date ? formatDate(date) : '' })
                      }}
                      isClearable
                    />
                  </Grid>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <SelectAsync
                          name='userId'
                          label={t('invoice.billers')}
                          placeholder={t('invoice.typeToSearch')}
                          isMulti
                          onChange={(selectedOptions) => {
                            setInvoiceFilter({ ...invoiceFilter, userId: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.expenceTotal')}
                          value={invoiceFilter.expenceTotal}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.expenceTotal)) {
                              setInvoiceFilter({ ...invoiceFilter, expenceTotal: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.travelTotal')}
                          value={invoiceFilter.travelTotal}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.travelTotal)) {
                              setInvoiceFilter({ ...invoiceFilter, travelTotal: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={2}>
                    <Input
                      type='text'
                      label={t('invoice.totalWithTax')}
                      value={invoiceFilter.totalWithTax}
                      onChange={(e) => {
                        if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalWithTax)) {
                          setInvoiceFilter({ ...invoiceFilter, totalWithTax: e.target.value })
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <DropDown
                      name='status'
                      label={t('invoice.expenseStatus')}
                      placeholder={t('invoice.pickOrType')}
                      isMulti
                      options={statusOptions}
                      value={statusOptions.filter(option => invoiceFilter.status.includes(option.value))}
                      onChange={(selectedOptions) => {
                        setInvoiceFilter({ ...invoiceFilter, status: selectedOptions ? selectedOptions.map(option => option.value) : [] })
                      }}
                    />
                  </Grid>
                  {isAdmin && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.info')}
                          value={invoiceFilter.info}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, info: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.adminInfo')}
                          value={invoiceFilter.adminInfo}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, adminInfo: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.internalNotes')}
                          value={invoiceFilter.internalNotes}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, internalNotes: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.refCode')}
                          value={invoiceFilter.refCode}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, refCode: e.target.value })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DropDown
                          name='isCopy'
                          label={t('invoice.isCopy')}
                          placeholder={t('invoice.pickOrType')}
                          isClearable={true}
                          options={isPrivateOptions}
                          value={isPrivateOptions.filter(option => invoiceFilter.isCopy.includes(option.value))}
                          onChange={(e) => {
                            setInvoiceFilter({ ...invoiceFilter, isCopy: e ? e.value : '' })
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.totalAccounting')}
                          value={invoiceFilter.totalAccounting}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.totalAccounting)) {
                              setInvoiceFilter({ ...invoiceFilter, totalAccounting: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type='text'
                          label={t('invoice.salariesPayableAmount')}
                          value={invoiceFilter.salariesPayableAmount}
                          onChange={(e) => {
                            if (validateFloat(e.target.value) || (e.target.value && String(e.target.value).trim().length < invoiceFilter.salariesPayableAmount)) {
                              setInvoiceFilter({ ...invoiceFilter, salariesPayableAmount: e.target.value })
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusValmisDate"
                          id="statusValmisDate"
                          label={t('invoice.statusValmisDate')}
                          selected={invoiceFilter.statusValmisDate ? new Date(invoiceFilter.statusValmisDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusValmisDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusOsittainMaksettuDateFrom"
                          id="statusOsittainMaksettuDateFrom"
                          label={t('invoice.statusOsittainMaksettuDateFrom')}
                          selected={invoiceFilter.statusOsittainMaksettuDateFrom ? new Date(invoiceFilter.statusOsittainMaksettuDateFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusOsittainMaksettuDateFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusOsittainMaksettuDateTo"
                          id="statusOsittainMaksettuDateTo"
                          label={t('invoice.statusOsittainMaksettuDateTo')}
                          selected={invoiceFilter.statusOsittainMaksettuDateTo ? new Date(invoiceFilter.statusOsittainMaksettuDateTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusOsittainMaksettuDateTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusMaksettuDateFrom"
                          id="statusMaksettuDateFrom"
                          label={t('invoice.statusMaksettuDateFrom')}
                          selected={invoiceFilter.statusMaksettuDateFrom ? new Date(invoiceFilter.statusMaksettuDateFrom) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusMaksettuDateFrom: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusMaksettuDateTo"
                          id="statusMaksettuDateTo"
                          label={t('invoice.statusMaksettuDateTo')}
                          selected={invoiceFilter.statusMaksettuDateTo ? new Date(invoiceFilter.statusMaksettuDateTo) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusMaksettuDateTo: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusHylattyDate"
                          id="statusHylattyDate"
                          label={t('invoice.statusHylattyDate')}
                          selected={invoiceFilter.statusHylattyDate ? new Date(invoiceFilter.statusHylattyDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusHylattyDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="statusPoistettuDate"
                          id="statusPoistettuDate"
                          label={t('invoice.statusPoistettuDate')}
                          selected={invoiceFilter.statusPoistettuDate ? new Date(invoiceFilter.statusPoistettuDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, statusPoistettuDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <DPicker
                          name="sentDate"
                          id="sentDate"
                          label={t('invoice.sentDate')}
                          selected={invoiceFilter.sentDate ? new Date(invoiceFilter.sentDate) : ''}
                          onChange={(date) => {
                            setInvoiceFilter({ ...invoiceFilter, sentDate: date ? formatDate(date) : '' })
                          }}
                          isClearable
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                style={{marginRight: '1rem'}}
                onClick={() => {
                  setInvoicePagination({ ...invoicePagination, page: 1 })
                  getData()
                }}
              >
                {t('taxcard.fetch')}
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  setInvoicePagination({ ...invoicePagination, page: 1 })
                  if (checked === 'KULULASKUT') {
                    setInvoiceFilter({ ...INIT_FILTER, columnsOptions: selectedColumns, type: 'KULULASKUT' })
                  } else {
                    setInvoiceFilter({ ...INIT_FILTER, columnsOptions: selectedColumns, type: 'LASKUT' })
                    setChecked('KAIKKI_LASKUT')
                  }
                  getData()
                }}
              >
                {t('invoice.emptyEverything')}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
      <NewPagination />
      <div style={{ backgroundColor: 'white' }}>
        {(invoiceFilter.type === 'LASKUT' || checked === 'LASKUT') && <InvoiceTable
          invoices={invoices}
          fetchData={getData}
          tableTitle={tableTitle()}
          landingPage={landingPage}
          selectedColumns={selectedColumns}
          checked={checked}
          selected={selected1}
          setSelected={setSelected1}
          sortKey={sortKeyInvoices}
          setSortKey={setSortKeyInvoices}
          sortOrder={sortOrderInvoices}
          setSortOrder={setSortOrderInvoices} />
        }
        {invoiceFilter.type === 'KULULASKUT' && <InvoiceTable
          isExp
          invoices={expences}
          fetchData={getData}
          tableTitle={tableTitle()}
          landingPage={landingPage}
          selectedColumns={selectedColumns}
          selected={selected1}
          setSelected={setSelected1}
          sortKey={sortKeyInvoices}
          setSortKey={setSortKeyInvoices}
          sortOrder={sortOrderInvoices}
          setSortOrder={setSortOrderInvoices} />
        }
      </div>
    </>
  )
}

export default withSnackbar(InvoiceFilterTable)
