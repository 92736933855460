import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { inviteUser } from '../service'
import Input from './Input'
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import { isEmail } from './../utils/invoiceUtils'
import Button from './Button'

const INIT_FORM = {
  email: ''
}

function InviteUserDialog({ open, setOpenInviteUserDialog, enqueueSnackbar }) {
  const { t } = useTranslation()
  const [form, setForm] = useState(INIT_FORM)
  const [isLoading, setLoading] = useState(false)

  const handleInviteUser = async () => {
    if (!form.email) {
      enqueueSnackbar(
        t('auth.noEmail'),
        { variant: 'error' }
      )
      return
    }

    if (form.email && !isEmail(form.email)) {
      enqueueSnackbar(
        t('auth.wrongEmail'),
        { variant: 'error' }
      )
      return
    }

    try {
      setLoading(true)
      const request = inviteUser(form)
      request.then(response => {
        cLog('KÄYTTÄJÄ KUTSUTTU', response)
        setForm(INIT_FORM)
        setLoading(false)
        setOpenInviteUserDialog(false)
        enqueueSnackbar(
          t('frontPage.inviteSuccess'),
          { variant: 'success' }
        )
      }, error => {
        setLoading(false)
        cLog('INVITE USER ERROR', error)

        if (error.response.data.errors[0] === "Email has already been taken") {
          enqueueSnackbar(
            t('auth.emailTaken'),
            { variant: 'error' }
          )
        } else {
          enqueueSnackbar(
            t('frontPage.inviteError'),
            { variant: 'error' }
          )
        }
      })
    } catch (error) {
      setLoading(false)
      cLog('INVITE USER ERROR', error)
      if (error.response.data.errors[0] === "Email has already been taken") {
        enqueueSnackbar(
          t('auth.emailTaken'),
          { variant: 'error' }
        )
      } else {
        enqueueSnackbar(
          t('frontPage.inviteError'),
          { variant: 'error' }
        )
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setForm(INIT_FORM)
          setOpenInviteUserDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="invite-user-dialog-title"
      aria-describedby="invite-user-dialog-description"
    >
      <DialogTitle id="invite-user-dialog-title">{t('frontPage.inviteNewUser')}</DialogTitle>
      <DialogContent dividers={true} id="invite-user-dialog-description">
        <Input
          type='email'
          value={form.email}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          label={t('frontPage.inviteStart')}
          placeholder={t('frontPage.email')} />
        <span style={{display: 'block', paddingTop: '15px', paddingBottom: '15px'}}>{t('frontPage.inviteMiddle')}</span>
        <span style={{display: 'block', paddingBottom: '15px'}}>{t('frontPage.inviteHeti')}</span>
        <span style={{display: 'block'}}>{t('frontPage.inviteEnd')}</span>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setForm(INIT_FORM)
            setOpenInviteUserDialog(false)
          }}
          color="secondary"
          disabled={isLoading}
        >
          {t('frontPage.cancelButton')}
        </Button>
        <Button
          variant="text"
          onClick={handleInviteUser}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('frontPage.inviteButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(InviteUserDialog)
