import { isEmail, isYtunnus } from './../../utils/invoiceUtils'
import { t } from "../../i18n"
//import { cLog } from '../../utils'

export const validateName = (value, select = false) => {
  if (!String(value).trim()) {
    return ({ key: t('customers.customerName')+t('user.missing') })
  }
  if (value && String(value).trim().length > 60) {
    if (select) {
      return ({ key: t('customers.customerNameTooLongSelect') })
    } else {
      return ({ key: t('customers.customerNameTooLong') })
    }
  }
}

export const validateAddress1 = (value, name) => {
  if (!name || (name && !name.toLowerCase().includes('zettle') && !name.toLowerCase().includes('käteismyynti'))) {
    if (!String(value).trim()) {
      return ({ key: t('customers.address')+t('user.missing') })
    }
    if (value && String(value).trim().length < 2) {
      return ({ key: t('invoice.tooShort', { field: t('customers.address'), min: 2 })})
    }
    if (value && String(value).trim().length > 50) {
      return ({ key: t('customers.addressTooLong') })
    }
  }
}

export const validateAddress2 = (value) => {
  if (value && String(value).trim().length < 2) {
    return ({ key: t('invoice.tooShort', { field: t('customers.address2'), min: 2 })})
  }
  if (value && String(value).trim().length > 50) {
    return ({ key: t('customers.address2TooLong') })
  }
}

export const validatePostalCode = (value, name) => {
  if (!name || (name && !name.toLowerCase().includes('zettle') && !name.toLowerCase().includes('käteismyynti'))) {
    if (!String(value).trim()) {
      return ({ key: t('customers.postalCode')+t('user.missing') })
    }
    if (value && String(value).trim().length < 2) {
      return ({ key: t('invoice.tooShort', { field: t('customers.postalCode'), min: 2 })})
    }
    if (value && String(value).trim().length > 10) {
      return ({ key: t('customers.postalCodeTooLong') })
    }
  }
}

export const validateCity = (value, name) => {
  if (!name || (name && !name.toLowerCase().includes('zettle') && !name.toLowerCase().includes('käteismyynti'))) {
    if (!String(value).trim()) {
      return ({ key: t('customers.city')+t('user.missing') })
    }
    if (value && String(value).trim().length < 2) {
      return ({ key: t('invoice.tooShort', { field: t('customers.city'), min: 2 })})
    }
    if (value && String(value).trim().length > 30) {
      return ({ key: t('customers.cityTooLong') })
    }
  }
}

export const validateEmail = (value, deliveryMethod) => {
  if (deliveryMethod === 'SÄHKÖPOSTI' && (!value || (typeof value === 'string' && !value.trim()))) {
    return ({ key: t('customers.email')+t('user.missing') })
  }
  if (value && !isEmail(value)) {
    return { key: t('user.wrongEmail') }
  }
  if (value && String(value).trim().length > 70) {
    return ({ key: t('customers.emailTooLong') })
  }
}

export const validatePhone = (value) => {
  if (value) {
    if (String(value).trim().length > 26) {
      return ({ key: t('customers.phoneNumberTooLong') })
    }
    if (String(value).trim().length < 6) {
      return ({ key: t('customers.phoneNumberTooShort') })
    }
    if ((value.charAt(0) !== '+') || isNaN(value.slice(1)) || (parseInt(value[1]) === 0)) {
      return ({ key: t('user.wrongPhone') })
    }
  }
}

export const validateEinvoiceOperator = (value, deliveryMethod, isAdmin) => {
  if (deliveryMethod === 'VERKKOLASKU' && isAdmin) {
    if (!value || String(value).length === 0) {
      return ({ key: t('customers.eInvoiceOperator')+t('user.missing') })
    }
  }
}

export const validateEinvoiceAddress = (value, deliveryMethod, isAdmin) => {
  if (deliveryMethod === 'VERKKOLASKU' && isAdmin) {
    if (!value || String(value).trim().length === 0) {
      return ({ key: t('customers.eInvoiceAddress')+t('user.missing') })
    }
    if (value && String(value).trim().length > 18) {
      return ({ key: t('customers.eInvoiceAddressTooLong') })
    }
    if (value && String(value).trim().length < 12) {
      return ({ key: t('customers.eInvoiceAddressTooShort') })
    }
  }
}

export const validateBusinessId = (value, isFinland) => {
  if (!String(value).trim()) {
    return ({ key: t('customers.businessId')+t('user.missing') })
  }
  if (value && !isFinland && String(value).trim().length > 18) {
    return ({ key: t('customers.businessIdTooLong') })
  }
  if (value && isFinland && (!isYtunnus(value) || String(value).trim().length > 9)) {
    return ({ key: t('customers.wrongBusinessId') })
  }
}

export const validateContactPerson = (value) => {
  if (value && String(value).trim().length > 28) {
    return ({ key: t('customers.contactPersonTooLong') })
  }
}

export const validateInternalNotes = (value) => {
  if (value && String(value).trim().length > 10000) {
    return { key: t('invoice.tooLong', { field: t('invoice.internalNotes'), max: 10000 }) }
  }
}

export const validateUser = (value) => {
  if (!String(value).trim()) {
    return ({ key: t('customers.biller')+t('user.missing') })
  }
}

export const checkClient = (values, isAdmin) => {
  const { userId, name, address1, address2, postalCode, city, country, email, phone, einvoiceOperator, einvoiceAddress, businessId, isPrivate, contactPerson, deliveryMethod } = values

  if (validateName(name)
    || validateAddress1(address1, name)
    || validateAddress2(address2)
    || validatePostalCode(postalCode, name)
    || validateCity(city, name)
    || validateEmail(email, deliveryMethod)
    || validatePhone(phone)
    || validateEinvoiceOperator(einvoiceOperator, deliveryMethod, isAdmin)
    || validateEinvoiceAddress(einvoiceAddress, deliveryMethod, isAdmin)
    || (!isPrivate && validateBusinessId(businessId, (country === 'FINLAND')))
    || validateContactPerson(contactPerson)
    || (isAdmin && validateUser(userId))
  ) {
    //cLog('CLIENTISSÄ VIKAA')
    return false
  }
  //cLog('CLIENT OK')
  return true
}
