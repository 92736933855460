import request from './request'
//import { cLog } from '../utils'
import { deserializeWorklists, deserializeWorklist } from '../utils/deserializer'
import { serializeWorklist } from '../utils/serializer'

export const getWorklists = async (query) => {
  const { data: worklists } = await request('GET', '/worklists' + (query || '')) 
  return deserializeWorklists(worklists)
}

export const getWorklist = async (id) => {
  const { data: worklist } = await request('GET', '/worklists/' + id) 
  return deserializeWorklist(worklist)
}

export const saveWorklist = async (data) => {
  delete data.updateHistory
  const { data: newWorklist } = await request('POST', '/worklists', serializeWorklist(data)) 
  return deserializeWorklist(newWorklist)
}

export const updateWorklist = async (data) => {
  delete data.updateHistory
  const { data: updatedWorklist } = await request('PUT', `/worklists/${data.id}`, serializeWorklist(data)) 
  return deserializeWorklist(updatedWorklist)
}

export const deleteWorklist = async (id) => await request('DELETE', `/worklists/${id}`)
