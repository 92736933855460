import React from 'react'
import { makeStyles, alpha } from "@material-ui/core/styles";
import InfoPop from './InfoPop'

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    width: '100%',
  },
  customInputLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(1),
  },
  customInput: {
    position: 'relative',
    background: theme.palette.white.main,
    color: theme.palette.white.contrastText,
    border: theme.borders.thin(theme.palette.grey.dark),
    borderRadius: theme.borders.radius.small,
    width: '100%',
    outline: 'none',
    padding: theme.spacing(2, 3),
    fontSize: "1rem",
    minHeight: "46px",
    boxShadow: 'none',
    transition: 'border-color 0.125s, box-shadow 0.125s',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.9)} 0 0 0 1px`,
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
    },

    '&.error': {
      borderColor: theme.palette.error.main,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.error.main, 0.9)} 0 0 0 1px`,
      },
    },

    '&.with-icon-right': {
      paddingRight: theme.spacing(11),
    },
    '&.with-icon-left': {
      paddingLeft: theme.spacing(11),
    },
  },
}));

const TextArea = (props) => {
  const classes = useStyles();
  const className = `${classes.customInput}${props.error ? " error" : ""}`;

  return (
    <div className={classes.customInputContainer}>
      {props.label &&
        <label htmlFor={props.id} className={classes.customInputLabel}>
        <span>{props.label} {props.required && "*"}</span>
          {props.info && <InfoPop info={props.info}/>}
        </label>
      }
     <textarea {...props} className={className} id={props.id} name={props.id} ref={props.innerRef} rows={props.rows || '5'} cols={props.cols || '100'} />
    </div>
  );
}

export default TextArea
