import {useState, useEffect, useRef} from 'react'

export const useScreenWidth = () => {
    const [isMobile, setMobile] = useState()
    const [isPad, setPad] = useState()

    const checkMobile = (mobile) => {
        if (mobile.matches !== isMobile) {
            //cLog('Näyttö on', mobile.matches ? 'kapeampi' : 'leveämpi', `kuin 450px.`)
            setMobile(mobile.matches)
        }
    }
    const checkPad = (pad) => {
        if (pad.matches !== isPad) {
            //cLog('Näyttö on', pad.matches ? 'kapeampi' : 'leveämpi', `kuin 720px.`)
            setPad(pad.matches)
        }
    }
    const mobile = window.matchMedia(`(max-width: 450px)`)
    const pad = window.matchMedia(`(max-width: 720px)`)
    checkMobile(mobile)
    checkPad(pad)
    mobile.addListener(checkMobile)
    pad.addListener(checkPad)

    return [isMobile, isPad]
}


///// VANHA RUNKO
/*  
const [isMobile, setMobile] = React.useState()
const checkScreenWidth = (width, callback) => {
    const checkMedia = (x) => {
        callback(x.matches)
        cLog('Näyttö on', !x.matches ? 'leveämpi' : 'kapeampi', `kuin ${width}px.`)
    }
    let x = window.matchMedia(`(max-width: ${width}px)`)
    checkMedia(x)
    x.addListener(checkMedia)
} 
  React.useEffect(() => {
        checkScreenWidth(450, (x) => setMobile(x))
    }, [])
*/

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
