//import { cLog } from '../../utils'
import { isEmail } from '../../utils/invoiceUtils'
import { t } from "../../i18n"

export const validateFirstName = (value) => {
  if (!String(value).trim()) {
   return ({ key: t('user.firstName')+t('user.missing') })
  }
  if (value && String(value).trim().length > 34) {
    return { key: t('user.firstNameTooLong')}
  }
}

export const validateLastName = (value) => { 
  if (!String(value).trim()) {
    return { key: t('user.lastName')+t('user.missing') }
  }
  if (value && String(value).trim().length > 34) {
    return { key: t('user.lastNameTooLong') }
  }
}

export const validateSsn = (value) => {
  if (value) {
    if (String(value).trim().length !== 11) {
      return { key: t('user.wrongSsn') }
    }
    const re = /\d{6}[A+-]\d{3}[0-9A-FHJ-NPR-Ya-fhj-npr-y]/
    if (!re.test(String(value))) {
      return { key: t('user.wrongSsn') }
    }
    const bday = value.substring(0, 6) + value.substring(7, 10)
    const reminder = parseInt(bday) % 31
    const checkmarks = '0123456789ABCDEFHJKLMNPRSTUVWXY'
    if (value.charAt(10).toUpperCase() !== checkmarks.charAt(reminder)) {
      return { key: t('user.wrongSsn') }
    }
  }
}

export const validateAddress1 = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key:  t('user.address')+t('user.missing') }
  }
  if (value && String(value).trim().length < 2) {
    return ({ key: t('invoice.tooShort', { field: t('user.address'), min: 2 })})
  }
  if (value && String(value).trim().length > 50) {
    return { key: t('user.addressTooLong') }
  }
}

export const validatePostalCode = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.postalCode')+t('user.missing') }
  }
  if (value && String(value).trim().length < 2) {
    return ({ key: t('invoice.tooShort', { field: t('user.postalCode'), min: 2 })})
  }
  if (value && String(value).trim().length > 10) {
    return { key: t('customer.postalCodeTooLong') }
  }
}

export const validateCity = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.city')+t('user.missing') }
  }
  if (value && String(value).trim().length < 2) {
    return ({ key: t('invoice.tooShort', { field: t('user.city'), min: 2 })})
  }
  if (value && String(value).trim().length > 30) {
    return { key: t('customers.cityTooLong') }
  }
}

export const validateEmail = (value) => { 
  if (!String(value).trim()) {
    return { key: t('user.email')+t('user.missing') } 
  }
  if (value && !isEmail(value)) {
    return { key: t('user.wrongEmail') }
  }
  if (value && String(value).trim().length > 70) {
    return { key: t('customers.emailTooLong') }
  }
} 

export const validatePhone = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.phone')+t('user.missing') }
  }
  if (value && String(value).trim().length < 6) {
    return { key: t('customers.phoneNumberTooShort') }
  }
  if (value && String(value).trim().length > 26) {
    return { key: t('customers.phoneNumberTooLong') }
  }
  if (value && ((value.charAt(0) !== '+') || isNaN(value.slice(1)) || (value[1] && value[1] === 0))) {
    return { key: t('user.wrongPhone') }
  }
}

export const validateBankaccount = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.bankAccount')+t('user.missing') }
  } 
  if (value && String(value).trim().length > 40) {
    return { key: t('user.bankAccountTooLong') }
  }
  if (value && value.trim().slice(0, 2).toUpperCase() === 'FI') {
    let iban = value.trim().replaceAll(' ', '').toUpperCase()
    if (iban.length !== 18) {
      return { key: t('user.bankAccountWrongLength') }
    }
    if (!/^\d+$/.test(iban.slice(2))) {
      return { key: t('user.bankAccountWrongType') } 
    }
    
    iban = iban.slice(4)+iban.slice(0, 4)
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    alphabet.split("").forEach((element, index) => {
      iban = iban.replaceAll(element, index + 10)
    })
    if (mod97(iban) !== 1) {
      return { key: t('user.bankAccountNotValid') } 
    }
  }
}

// Modulo 97 for huge numbers given as digit strings.
const mod97 = (digit_string) => {
  var m = 0;
  for (var i = 0; i < digit_string.length; ++i)
    m = (m * 10 + parseInt(digit_string.charAt(i))) % 97;
  return m;
}

export const validateBIC = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return {
      key: t('user.bic')+t('user.missing')
    }
  }
  if (value && (String(value).trim().length < 8 || String(value).trim().length > 11)) {
    return {
      key: t('user.bicWrongLength')
    }
  }
}

export const validateMarketingName = (value) => {
  if (value && String(value).trim().length > 70) {
    return { key: t('invoice.marketingNameTooLong') }
  }
}

export const validateCommission = (value) => {
  if (value && (String(value).trim().length > 5 || parseFloat(value) > 5)) {
    return { key: t('user.commissionTooBig') }
  }
}

export const validateIndustry = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.industry')+t('user.missing') }
  }
}
 
export const validateCustomIndustry = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.customIndustry')+t('user.missing') }
  }
  if (value && String(value).trim().length > 20) {
    return { key: t('user.customIndustryTooLong') } 
  }
}

export const validateYel = (value, isAdmin) => {
  if (!isAdmin && !String(value).trim()) {
    return { key: t('user.yel')+t('user.missing') }
  }
}

export const validateDistraintNotes = (value) => {
  if (value && String(value).trim().length > 10000) {
    return { key: t('user.distraintNotesTooLong') }
  }
}

export const validateNoInstantSalaryNotes = (value) => {
  if (value && String(value).trim().length > 10000) {
    return { key: t('user.noInstantSalaryNotesTooLong') }
  }
}

export const validateOdealAccount = (value, field, allowZero, decimals = 2) => {
  const val = String(value).replace(',','.')
  const numberRegEx = new RegExp('^\\-?\\d*\\.?\\d{1,' + decimals + '}$')
  if (!numberRegEx.test(val) || val === '-') {
    return { key: t('invoice.notNumber', { field: field, decimals: decimals }) }
  }
  const float = parseFloat(val)
  if (float < 0) {
    return { key: t('invoice.tooLow', { field: field, min: 0 }) }
  }
  if (!allowZero && float === 0) {
    return { key: t('invoice.isZero', { field: field }) }
  }
  if (float > 99999.99) {
    return { key: t('invoice.tooBig', { field: field, max: 99999.99 }) }
  }
}

export const checkUser = (values, isAdmin) => {
  const { firstName, lastName, ssn, address1, postalCode, city, phone, bankAccount, bic, industry, customIndustry, commission, yel, distraintNotes, noInstantSalaryNotes } = values

  if (validateFirstName(firstName)
    || validateLastName(lastName)
    || (isAdmin && validateSsn(ssn))
    || validateAddress1(address1, isAdmin)
    || validatePostalCode(postalCode, isAdmin)
    || validateCity(city, isAdmin)
    || validatePhone(phone, isAdmin)
    || validateBankaccount(bankAccount, isAdmin)
    || ((!bankAccount || bankAccount.slice(0, 2).toUpperCase()) !== 'FI' && validateBIC(bic, isAdmin))
    || validateIndustry(industry, isAdmin)
    || (industry === 'Muu' && validateCustomIndustry(customIndustry, isAdmin))
    || (isAdmin && validateCommission(commission))
    || validateYel(yel, isAdmin)
    || (isAdmin && validateDistraintNotes(distraintNotes))
    || (isAdmin && validateNoInstantSalaryNotes(noInstantSalaryNotes))
    || (isAdmin && validateOdealAccount(values.odealAccount, t('user.odealAccount'), true, 2))
    || (isAdmin && validateOdealAccount(values.notAccounted, t('user.notAccounted'), true, 2))
  ) {
    //cLog('USERISSA VIKAA')
    return false
  }
  //cLog('USER OK')
  return true
}
