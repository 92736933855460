import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery, getUserNote, deleteUserNote, updateUserNote } from '../../service'
import { useStateValue, setUserNotes, setSortKeyUserNotes, setSortOrderUserNotes } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import UserNoteDialog from '../../components/UserNoteDialog'
import { USER_NOTE_TEMPLATE } from '../User/User'
import '../../styles/styles.css'

let selectedColumns  = []

const INIT_FILTER = {
  userId: [],
  status: 'AVOIN',
  dateFrom: '',
  dateTo: '',
  notes: '',
  createdAt: '',
  updatedAt: '',
  columnsOptions: []
}

const UserNotes = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ userNotes, sortKeyUserNotes, sortOrderUserNotes }, dispatch] = useStateValue()
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openConfirmUpdateModal, setOpenConfirmUpdateModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })
  const allColumns = ['userId', 'status', 'date', 'notes', 'createdAt', 'updatedAt', 'actions']
  selectedColumns = JSON.parse(window.localStorage.getItem('userNotesSelectedColumns')) || allColumns
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('userNotes.'+option),
      value: option
    }
    if (['date'].includes(option)) {
      obj.date = true
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({ ...INIT_FILTER, columnsOptions: selectedColumns })

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getUserNote(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
          {row.status !== 'HOIDETTU' && <Tooltip title={t('user.setClosed')}>
            <DoneIcon
              className="actionIcon"
              onClick={() => {
                setSelectedRow(row.id)
                setOpenConfirmUpdateModal(true)
              }}
            />
          </Tooltip>}
          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setSelectedRow(row)
                setOpenConfirmDeleteModal(true)
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyUserNotes, sortOrderUserNotes, filter, selectedColumns, 'userNotes')
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          return row
        })
        dispatch(setUserNotes(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setUserNotes([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyUserNotes, sortOrderUserNotes, filter, pagination, t, enqueueSnackbar])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.userNotes') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteUserNote(selectedRow.id)
      cLog(`USER NOTE ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('userNotes.userNoteDeleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE USER NOTE ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('userNotes.problemsDeletingUserNote'),
        { variant: 'error' }
      )
    }
  }

  const handleAgreeUpdateModal = async () => {
    setLoading(true)
    const request = updateUserNote({ id: selectedRow, status: 'HOIDETTU' })
    request.then(response => {
      cLog(`USER NOTE ${selectedRow.id} UPDATED`, response)
      setSelectedRow(null)
      enqueueSnackbar(
        t('user.noteSent'),
        { variant: 'success' }
      )
      setLoading(false)
      setOpenConfirmUpdateModal(false)
      setForceFetchData(true)
    }, error => {
      setSelectedRow(null)
      setLoading(false)
      setOpenConfirmUpdateModal(false)
      cLog('UPDATE USER NOTE ERROR', error)
      enqueueSnackbar(
        t('user.noteError'),
        { variant: 'error' }
      )
    })
  }

  const statusOptions = ['AVOIN', 'HOIDETTU'].map(status => ({ label: t('user.'+status), value: status }))

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.userNotes')}
      />
      <DatatableFilters
        inputs={[
          { field: 'userId', label: t('invoice.billers'), type: 'userId' },
          { field: 'status', label: t('userNotes.status'), type: 'select', options: statusOptions },
          { field: 'notes', label: t('userNotes.notes'), type: 'text' },
          { field: 'dateFrom', label: t('userNotes.dateFrom'), type: 'date' },
          { field: 'dateTo', label: t('userNotes.dateTo'), type: 'date' },
          { field: 'createdAt', label: t('userNotes.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('userNotes.updatedAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'userNotesSelectedColumns'}
        sortKey={sortKeyUserNotes}
        setSortKey={setSortKeyUserNotes}
        sortOrder={sortOrderUserNotes}
        setSortOrder={setSortOrderUserNotes}
        isAdmin={true}
        downloadType={'user_notes'}
      />
      <Datatable
        data={userNotes}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyUserNotes}
        setSortKey={setSortKeyUserNotes}
        sortOrder={sortOrderUserNotes}
        setSortOrder={setSortOrderUserNotes}
        isAdmin={true}
      />
      <Formik
        enableReinitialize
        initialValues={USER_NOTE_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <UserNoteDialog open={openModal} setOpenUserNoteDialog={setOpenModal} editUserNote={selectedRow} fetchData={() => setForceFetchData(true)} {...formikProps} />}
      </Formik>
      <Alert
        open={openConfirmUpdateModal}
        setOpen={setOpenConfirmUpdateModal}
        handleAgree={handleAgreeUpdateModal}
        handleClose={() => setOpenConfirmUpdateModal(false)}
        alertMessage={{ title: t('user.userNoteClosedWarningTitle') }}
      />
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('userNotes.confirmDeletionTitle'), body: t('customers.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </LayoutContainer>
  )
}

export default withSnackbar(UserNotes)
