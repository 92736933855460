import { addSalary } from "../state"
import request from './request'
import { cLog } from '../utils'

export const getSalaries = async (query) => {
  try {
    const { data: salaries } = await request('GET', '/salaries' + (query || ''))
    //cLog('QUERY GET SALARIES', query)
    return salaries
  } catch (e) {
    cLog(e)
  }
}

// TODO tarpeeton, oikeasti userista?
export const saveSalary = async (dispatch, data) => {
  delete data.updateHistory
  try {
    const { data: newSalary } = await request('POST', '/salaries', data)
    dispatch(addSalary(newSalary))
    cLog('A NEW SALARY ADDED', newSalary)
  } catch (e) {
    cLog(e)
  }
}

export const printSalaryPdf = async (id) => { 
  cLog('PRINT PDF ID', id)
  const response = await request('GET', `/salaries/${id}/print_pdf`)
  return response
}

export const getSalariesPayableAmount = async (data) => {
  delete data.updateHistory
  cLog('QUERY GET GETSALARIESPAYABLEAMOUNT', data)
  const response = await request('POST', '/salaries/salaries_payable_amount', data)
  return response
}
