import request from './request'

export const getReferences = async (query) => {
  const { data: references } = await request('GET', '/references' + (query || ''))
  return references
}

export const saveReference = async (data) => {
  delete data.updateHistory
  const { data: newReference } = await request('POST', '/references', data)
  return newReference
}

export const updateReference = async (data) => {
  delete data.updateHistory
  const { data: updatedReference } = await request('PUT', `/references/${data.id}`, data)
  return updatedReference
}

export const deleteReference = async (id) => await request('DELETE', `/references/${id}`)
