import React from "react";
import { IconButton as MaterialIconButton } from "@material-ui/core";
import { makeStyles, darken, alpha } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
  customIconButton: {
    // By default, we style the 'text' variant
    border: 0,
    transition: "background 0.225s",
    background: "transparent",

    "& > .MuiIconButton-label > svg": {
      color: theme.palette[props.color].main,
    },
    "&:hover": {
      background: alpha(theme.palette[props.color].main, 0.1),
    },

    // Other variants:
    "&.outlined": {
      border: theme.borders.base(theme.palette[props.color].main),
      background: "transparent",

      "& > .MuiIconButton-label > svg": {
        color: theme.palette[props.color].main,
      },
      "&:hover": {
        background: alpha(theme.palette[props.color].main, 0.1),
      },
    },
    "&.contained": {
      background: theme.palette[props.color].main,

      "& > .MuiIconButton-label > svg": {
        color: theme.palette[props.color].contrastText,
      },
      "&:hover": {
        background: darken(theme.palette[props.color].main, 0.1),
      },
    },
  },
}))();

const IconButton = React.forwardRef(
  (props, ref) => {
    const styleProps = {
      color: props.color || "grey"
    };
    const classes = useStyles(styleProps);
    const className = `${classes.customIconButton} ${props.variant || "contained"}${props.className ? ` ${props.className}` : ""}`;
    return <MaterialIconButton { ...props } color={undefined} className={className} />;
  }
)

export default IconButton;
