import React from 'react'
import { useStateValue } from "../state"
import { makeStyles } from '@material-ui/core/styles'
import IndeterminateCheckBoxOutlined from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CheckBoxOutlineBlankOutlined from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = (props) => makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white.main,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(4),
    boxShadow: theme.shadows[1],
    borderRadius: theme.borders.radius.rounded,
    border: props?.color ? '3px solid rgb(129,36,227)' : '1px solid rgba(0, 0, 0, 0.1)',

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 4, 0),
      boxShadow: 'none',
      border: 0,
      borderRadius: 0,
      borderBottom: '2px solid rgba(0, 0, 0, 0.1)',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1rem',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    cursor: 'pointer',

    '& > *': {
      marginRight: theme.spacing(4),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  rightSide: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    margin: '0px 10px 0px 5px',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white'
    }
  },
  IndeterminateCheckBoxOutlined: {
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  body: {
    marginTop: theme.spacing(4),
  },
}))()

export const Hanuri = ({ id, key, className, children, color }) => {
  const classes = useStyles({color})
  return (
    <div id={id} key={key} className={`${classes.root} ${className || ""}`}>
      {children}
    </div>
  )
}

export const HanuriTitle = ({ id, open, remove, done, error, label, expanded, showRemove = true }) => {
  const [{ currentUser }] = useStateValue()
  const classes = useStyles({open})
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  return (
    <div id={id} className={classes.title}>
      <div className={classes.leftSide}>
        {done
          ? <DoneIcon onClick={open} style={{ color: 'green' }} />
          : (isAdmin
            ? <WarningIcon onClick={open} style={{ color: 'red' }} />
            : null)}
        <div style={{ color: error && 'red' }} onClick={open}>{label}</div>
      </div>
      <div className={classes.rightSide}>
        {id !== 'invoiceBody' && remove && showRemove && <CloseIcon onFocus={(event) => event.stopPropagation()} className={classes.closeIcon} fontSize='medium' onClick={remove} />}
        {expanded ? <IndeterminateCheckBoxOutlined onClick={open} className={classes.IndeterminateCheckBoxOutlined} /> : <CheckBoxOutlineBlankOutlined onClick={open} className={classes.IndeterminateCheckBoxOutlined} />}
      </div>
    </div>
  )
}

export const HanuriBody = ({ expanded, children }) => {
  const classes = useStyles()
  return expanded && <div className={classes.body}>
    {children}
  </div>
}
