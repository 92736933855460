import React, { useEffect } from 'react'
import Footer from '../../screens/Footer'
import { useTranslation } from 'react-i18next';
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'

const GDPR = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <LayoutContainer>
            <Header title={t('register.register')}/>
            <div>
                <h4><strong>1. {t('register.1.title')}</strong></h4>
                {t('register.1.content').split(';').map((row, i) => <div key={i}>{row}</div>)}
                <br/>
                {t('register.1.content_2').split(';').map((row, i) => <div key={i}>{row}</div>)}

                <h4><strong>2. {t('register.2.title')}</strong></h4>
                {t('register.2.subTitle')}
                <ul>
                {t('register.2.content').split(';').map((row, i) => <li key={i}>{row}</li>)}
                </ul>
                <p>
                {t('register.2.footer')}
                </p>

                <h4><strong>3. {t('register.3.title')}</strong></h4>
                <div>{t('register.3.subTitle')}</div>
                <ul>
                {t('register.3.content').split(';').map((row, i) => <li key={i}>{row}</li>)}
                </ul>

                <h4><strong>4. {t('register.4.title')}</strong></h4>
                <p>
                {t('register.4.content')}
                </p>

                <h4><strong>5. {t('register.5.title')}</strong></h4>
                {t('register.5.content').split(';').map((row, i) => <div key={i}>{row}</div>)}

                <h4><strong>6. {t('register.6.title')}</strong></h4>
                <div>
                {t('register.6.content')}
                </div>

                <h4><strong>7. {t('register.7.title')}</strong></h4>
                <div>
                {t('register.7.content')}
                </div>

                <h4><strong>8. {t('register.8.title')}</strong></h4>
                <div>
                {t('register.8.content')}
                </div>

                <h4><strong>9. {t('register.9.title')}</strong></h4>
                <div>
                {t('register.9.content')}
                </div>
            </div>
            <Footer />
        </LayoutContainer>
    )
}

export default GDPR



