import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { cLog } from "../utils"
import { postConfirmAccount, setUserData } from '../service'
import ConfirmEmailDialog from './ConfirmEmailDialog'
import ConfirmAccountDialog from './ConfirmAccountDialog'
import { useTranslation } from 'react-i18next'
import { useStateValue, setCurrentUser } from "../state"
import Alert from './Alert'
import parse from 'html-react-parser'

export default function PathChange({ enqueueSnackbar }) {
  const { t } = useTranslation()
  const [{ currentUser }, dispatch] = useStateValue()
  const [openConfirmEmailDialog, setOpenConfirmEmailDialog] = useState(false)
  const [openConfirmAccountDialog, setOpenConfirmAccountDialog] = useState(false)
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const location = useLocation()
  let history = useHistory()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  const code = new URLSearchParams(window.location.search).get('code');
  const state = new URLSearchParams(window.location.search).get('state')
  useEffect(() => {
    if (currentUser && (!currentUser.confirmedAt || currentUser.hasUnconfirmedSsn)) {
      if (!code && !currentUser.confirmedAt) {
        setOpenConfirmEmailDialog(true)
      } else if (!code && currentUser.hasUnconfirmedSsn && currentUser.role !== 'ADMIN') {
        setOpenConfirmAccountDialog(true)
      } else if (code && state === 'confirmAccount') {
        try {
          if (code && state && state === 'confirmAccount') {
            const promise = postConfirmAccount(code, currentUser.id)
            promise.then(data => {
              cLog('AUTH DATA VASTAUS', data)
              enqueueSnackbar(
                t('auth.accountConfirmed'),
                { variant: 'success' }
              )
              setUserData({ ...currentUser, hasUnconfirmedSsn: false })
              dispatch(setCurrentUser({ ...currentUser, hasUnconfirmedSsn: false }))
            }, error => {
              cLog('AUTH DATA ERROR', error)
              if (error.response.data.errors[0] === "Ssn is already in use") {
                enqueueSnackbar(
                  t('auth.ssnAuthError'),
                  { variant: 'error' }
                )
              } else {
                enqueueSnackbar(
                  t('auth.authError'),
                  { variant: 'error' }
                )
              }
              setOpenConfirmAccountDialog(true)
            })
          }
        } catch (error) {
          cLog('AUTH DATA ERROR', error)
          enqueueSnackbar(
            t('auth.authError'),
            { variant: 'error' }
          )
          setOpenConfirmAccountDialog(true)
        }
      }
    } else if (currentUser && !isAdmin && window.localStorage.getItem('vatInfo') !== 'true') {
      setOpenInfoDialog(true)
    }
  }, [code, enqueueSnackbar, state, t, location.key, currentUser, isAdmin, dispatch])

  useEffect(() => {
    history.listen(() => {
      if (window.localStorage.getItem('swUpdateReady') === true) {
        window.localStorage.setItem('swUpdateReady', false)
        window.stop()
        window.location.reload()
      }
    })
  }, [history])

  return (
    <div>
      <ConfirmEmailDialog open={openConfirmEmailDialog} setOpenConfirmEmailDialog={setOpenConfirmEmailDialog} />
      <ConfirmAccountDialog open={openConfirmAccountDialog} setOpenConfirmAccountDialog={setOpenConfirmAccountDialog} />
      <Alert
        open={openInfoDialog}
        setOpen={setOpenInfoDialog}
        handleAgree={() => {
          window.localStorage.setItem('vatInfo', 'true')
          setOpenInfoDialog(false)
        }}
        alertMessage={{ title: t('frontPage.vatInfoTextTitle'), body: parse(t('frontPage.vatInfoTextBody')) }}
        hideClose={true}
      />
    </div>
  )
}