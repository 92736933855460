import React, { useState, useEffect } from 'react'
import { getLoggedUser, logOut, setAuthToken, setDiscountAndEmail, authToken, getExpirationTimeLeft, setExternalFields, setAuthCode } from './service'
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import { useStateValue, setCurrentUser, setClients, setInvoices, setExpences, setReferences, setWorklists, setTaxcards, setValtticards } from "./state"
import { COLORS, FONTS, backGroundImage } from './styles'
import odealLogo from './styles/odeal-logo/Odeal_valkoinen_600.png'
import LandingScreen from './screens/LandingScreen'
import References from './screens/References'
import Messages from './screens/Messages'
import Invoice from './screens/Invoice'
import Clients from './screens/Clients'
import Client from './screens/Clients/Client'
import SignIn from './screens/Auth/SignIn'
import SignUp from './screens/Auth/SignUp'
import ForgottenPassword from './screens/Auth/ForgottenPassword'
import SetNewPassword from './screens/Auth/SetNewPassword'
import ConfirmNewEmail from './screens/Auth/ConfirmNewEmail'
import User from './screens/User'
import Users from './screens/User/Users'
import Salaries from './screens/Salary'
import Worklist from './screens/Worklist/Worklist'
import Worklists from './screens/Worklist/Worklists'
import Info from './screens/Info'
import ContactInfo from './screens/Info/ContactInfo'
import Terms from './screens/Info/Terms'
import GDPR from './screens/Info/GDPR'
import Taxcard from './screens/User/Taxcard'
import Taxcards from './screens/Taxcards'
import PromoCodes from './screens/PromoCodes'
import { withSnackbar } from 'notistack'
import { AppBar, CssBaseline, Drawer, Hidden, IconButton, List, ListItem, ListItemText, Toolbar, Menu, MenuItem } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { ExitToApp } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useInterval } from './utils/hooks'
import { cLog } from './utils'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import ProtectedRoute from './components/ProtectedRoute'
import PathChange from './components/PathChange'
import Notifications from './components/Notifications'
import Announcements from './screens/Announcements'
import DiscountCodes from './screens/DiscountCodes'
import Valtticards from './screens/Valtticards'
import UserNotes from './screens/UserNotes'

const tagManagerArgs = {
  gtmId: 'GTM-NC92P8B'
}

const drawerWidth = 240
let code, state;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    minHeight: '100vh',
    //height: '100vh',
    //height: '100%',
    ...backGroundImage,

    // When mobile, Webkit and Chrome seem to really not like vh-units, so we're doing this to work around it
    [theme.breakpoints.down("xs")]: {
      height: '100%',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 0,//drawerWidth,      ///// VASEN MARGINAALI VAPAAKSI
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
  },
  appToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    color: 'white',
    marginTop: theme.spacing(4),
    fontSize: '1rem',
  },
  appBarRightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarLinks: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

    '& > *': {
      margin: theme.spacing(0, 2),
    },
  },
  link: {
    color: 'white',
    fontFamily: FONTS.FONT_FAMILY1,
    '&:hover': {
      color: COLORS.tertiary,
    },
    fontSize: '1rem',
    lineHeight: '2.166666666666667em',
    textTransform: 'uppercase',
    fontWeight: '600',
    // letterSpacing: '.5px',
    textDecoration: 'none',
  },
  menuButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 2),
    },
  },
  accountCircle: {
    '&:hover': {
      color: COLORS.tertiary,
      cursor: 'pointer'
    },
  },
  appBarBtn: {
    margin: 0,
    color: 'inherit',
    '&:hover': {
      color: COLORS.tertiary,
      cursor: 'pointer'
    },
  },
  burgerMenu: {
    zIndex: 2001,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  mode: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    color: 'white',
    height: '64px',
    fontSize: '20px',
    padding: theme.spacing(4),
  },
  languageLink: {
    marginTop: '4px',
    cursor: 'pointer',
    '& > img': {
      width: "24px",
    },
  }
}));

const App = (props) => {
  const { t, i18n } = useTranslation()
  const window1 = props.window
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [{ currentUser }, dispatch] = useStateValue()
  const [anchorElAccount, setAnchorElAccount] = useState(null)
  const [anchorElForms, setAnchorElForms] = useState(null)
  const openForms = Boolean(anchorElForms)
  const [init, setInit] = useState(false)
  const openAccount = Boolean(anchorElAccount)
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const isProductionMode = process.env.REACT_APP_CUSTOM_NODE_ENV === 'PRODUCTION'

  if (isProductionMode) {
    TagManager.initialize(tagManagerArgs)
  }

  code = new URLSearchParams(window.location.search).get('code')

  state = new URLSearchParams(window.location.search).get('state')
  const token = new URLSearchParams(window.location.search).get('authToken')

  const externalEmail = new URLSearchParams(window.location.search).get('email')
  const externalFirstName = new URLSearchParams(window.location.search).get('firstName')
  const externalLastName = new URLSearchParams(window.location.search).get('lastName')

  if (externalEmail && externalFirstName && externalLastName) {
    setExternalFields(externalEmail, externalFirstName, externalLastName)
  }

  //cLog('WINDOW LOCATION SEARCH', window.location.search)
  const path = new URLSearchParams(window.location.search).get('path')
  if (path) {
    const discount = new URLSearchParams(window.location.search).get('discountCode')
    const email = new URLSearchParams(window.location.search).get('email')
    if (token) {
      setAuthToken(token)
    } else if (discount && email) {
      setDiscountAndEmail(discount, email)
    }
  }

  if (code && state && state === 'signUp') {
    setAuthCode(code)
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = new URLSearchParams(window.location.search).get('authToken')
      const loggedUser = getLoggedUser()

      if (loggedUser && !token) {
        dispatch(setCurrentUser(loggedUser));
        cLog('LOCAL STORAGE LOGGED USER', loggedUser)
      } else if (loggedUser && token) {
        handleCloseAndLogout()
      } else {
        cLog('NO LOGGED USER IN LOCAL STORAGE')
      }
      setInit(true)
    }

    fetchData()
  }, [])

  const clearState = () => {
    // remember to make changes also to ConfirmAccountDialog
    dispatch(setClients([]))
    dispatch(setInvoices([]))
    dispatch(setExpences([]))
    dispatch(setReferences([]))
    dispatch(setWorklists([]))
    if (isAdmin) {
      dispatch(setTaxcards([]))
      dispatch(setValtticards([]))
    }
  }

  useInterval(() => {
    if (currentUser && !isAdmin) {
      const secondsLeft = Math.round(getExpirationTimeLeft() / 1000)
      //cLog('EXPIRATION TIME LEFT', secondsLeft + ' s')
      if (secondsLeft === 120) {
        props.enqueueSnackbar(
          t('frontPage.loginExpireSoon'),
          { variant: 'warning' }
        )
      }
      if (secondsLeft === 2) {
        props.enqueueSnackbar(
          t('frontPage.loginExpired'),
          { variant: 'error' }
        )
        setTimeout(() => {
          dispatch(setCurrentUser(null))
          clearState()
        }, 2000)
      }
      if (secondsLeft <= 0) {
        dispatch(setCurrentUser(null))
        clearState()
      }
    }
  }, 1000)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuAccount = (event) => {
    setAnchorElAccount(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };

  const handleCloseForms = () => {
    setAnchorElForms(null);
  };

  const handleMenuForms = (event) => {
    setAnchorElForms(event.currentTarget)
  };

  const handleCloseAndLogout = async () => {
    // remember to make changes also to ConfirmAccountDialog
    cLog('LOGGING OUT!')
    setAnchorElAccount(null);
    clearState()
    dispatch(setCurrentUser(null));
    logOut()
  };

  let links = []
  if (currentUser) {
    links = [
      { label: t('frontPage.frontPage'), path: '/' },
      { label: t('frontPage.newInvoice'), path: '/laskut/uusi' }
    ]
    if (isAdmin) {
      links.push({ label: t('frontPage.newExpense'), path: '/kulut/uusi' })
    }
    links.push({ label: t('frontPage.customers'), path: '/asiakkaat' })
    if (isAdmin) {
      links.push({ label: t('frontPage.users'), path: '/kayttajat' })
      links.push({ label: t('frontPage.userNotes'), path: '/muistiinpanot' })
      links.push({ label: t('frontPage.salaries'), path: '/palkkalaskelmat' })
      links.push({ label: t('frontPage.taxcards'), path: '/verokortit' })
    }
    links.push({ label: t('frontPage.workLists'), path: '/tyolistat' })
    links.push({ label: t('frontPage.certificates'), path: '/todistukset' })
    links.push({ label: t('frontPage.valtticards'), path: '/valttikortit' })
    links.push({ label: t('frontPage.guides'), path: '/info' })
    if (isAdmin) {
      links.push({ label: t('frontPage.announcementsTitle'), path: '/tiedotteet' })
    }
    links.push({ label: t('frontPage.myInfo'), path: '/kayttajat/' + currentUser.id })
    if (isAdmin) {
      links.push({ label: t('frontPage.promoCodes'), path: '/promokoodit' })
      links.push({ label: t('frontPage.discountCodes'), path: '/alennuskoodit' })
    }
  }

  const drawer = (
    <div>
      <List>
        {links.map((link, index) => (
          <ListItem button key={index} to={link.path} component={Link}>
            <ListItemText primaryTypographyProps={{ style: { color: COLORS.primary } }} primary={link.label} onClick={() => setMobileOpen(false)} />
          </ListItem>
        ))}
      </List>
    </div >
  );

  const container = window1 !== undefined ? () => window1().document.body : undefined;  ///// KIINNOSTAVA RIVI ?????

  return init && (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <PathChange enqueueSnackbar={props.enqueueSnackbar} />
        {currentUser && <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appToolBar}>

          {process.env.REACT_APP_CUSTOM_NODE_ENV === 'PRODUCTION'
            ? <Link to={'/'}>
              <div className={classes.logo}>
                <img src={odealLogo} alt='odealLogo' style={{ width: '60px' }} />
                <div>{t('auth.invoicingService')}</div>
              </div>
            </Link>
            : <div className={classes.mode} >{process.env.REACT_APP_CUSTOM_NODE_ENV}</div>}

          <div className={classes.appBarRightSide}>

            <div className={classes.appBarLinks}>
              {links.map((link, i) => (!isAdmin ? ![t('frontPage.workLists'), t('frontPage.certificates'), t('frontPage.valtticards'), t('frontPage.guides'), t('frontPage.userInfo')].includes(link.label) : ![t('frontPage.workLists'), t('frontPage.certificates'), t('frontPage.valtticards'), t('frontPage.guides'), t('frontPage.userInfo'), t('frontPage.userNotes'), t('frontPage.salaries'), t('frontPage.taxcards'), t('frontPage.announcementsTitle'), t('frontPage.promoCodes'), t('frontPage.discountCodes')].includes(link.label)) &&
                <Link className={classes.link} key={i} to={link.path}>
                  {link.label}
                </Link>
              )}
            </div>

            <div className={classes.menuButtonContainer}>
              <Hidden xsDown>
                <Link
                  to={`/kayttajat/${currentUser.id}`}
                  className={classes.link}
                >
                  {currentUser.firstName ? currentUser.firstName.toUpperCase() : ''}{currentUser.lastName ? ` ${currentUser.lastName.toUpperCase().slice(0, 20)}` : ''}
                </Link>
              </Hidden>
              <Link className={classes.languageLink} onClick={() => i18n.changeLanguage("fi")} variant="body2" to={'#'}>
                <img src={'/images/flag-finland.png'} alt={'FI'}/>
              </Link>
              <Link className={classes.languageLink} onClick={() => i18n.changeLanguage("en")} variant="body2" to={'#'}>
                <img src={'/images/flag-uk.png'} alt={'EN'}/>
              </Link>

              <IconButton
                className={classes.appBarBtn}
                aria-owns={anchorElAccount ? 'simple-menu' : undefined}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuAccount}
                color="inherit"
              >
                <ExitToApp fontSize='large'/>
              </IconButton>
              <Menu
                style={{ zIndex: 2001 }}
                id="menu-appbar"
                anchorEl={anchorElAccount}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={openAccount}
                onClose={handleCloseAccount}
              >
                <MenuItem style={{ color: COLORS.primary }} onClick={handleCloseAndLogout}>
                  {t('auth.logOut')}
                </MenuItem>
              </Menu>

              <Hidden mdUp>
                <IconButton
                  className={classes.appBarBtn}
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon fontSize='large'/>
                </IconButton>
              </Hidden>
              <Hidden smDown>
                <IconButton
                  className={classes.appBarBtn}
                  color="inherit"
                  aria-owns={anchorElForms ? 'simple-menu' : undefined}
                  aria-label="forms"
                  aria-controls="menu-forms"
                  aria-haspopup="true"
                  onClick={handleMenuForms}
                >
                  <MenuIcon fontSize='large'/>
                </IconButton>
                <Menu
                  id="menu-forms"
                  anchorEl={anchorElForms}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openForms}
                  onClose={handleCloseForms}
                  className={classes.burgerMenu}
                >
                  <Link to={`/laskut/uusi`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.newInvoice')}</MenuItem></Link>
                  {isAdmin && <Link to={`/kulut/uusi`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.newExpense')}</MenuItem></Link>}
                  <Link to={`/asiakkaat`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.customers')}</MenuItem></Link>
                  {isAdmin && <Link to={`/kayttajat`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.users')}</MenuItem></Link>}
                  {isAdmin && <Link to={`/muistiinpanot`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.userNotes')}</MenuItem></Link>}
                  {isAdmin && <Link to={`/palkkalaskelmat`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.salaries')}</MenuItem></Link>}
                  {isAdmin && <Link to={`/verokortit`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.taxcards')}</MenuItem></Link>}
                  <Link to={`/tyolistat`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.workLists')}</MenuItem></Link>
                  <Link to={`/todistukset`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.certificates')}</MenuItem></Link>
                  {<Link to={`/valttikortit`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.valtticards')}</MenuItem></Link>}
                  <Link to={`/info`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.guides')}</MenuItem></Link>
                  {isAdmin && <Link to={`/tiedotteet`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.announcementsTitle')}</MenuItem></Link>}
                  <Link to={`/kayttajat/` + currentUser.id} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.myInfo')}</MenuItem></Link>
                  {isAdmin && <Link to={`/promokoodit`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.promoCodes')}</MenuItem></Link>}
                  {isAdmin && <Link to={`/alennuskoodit`} style={{ textDecoration: 'none' }}><MenuItem style={{ color: COLORS.primary }} onClick={handleCloseForms}>{t('frontPage.discountCodes')}</MenuItem></Link>}
                </Menu>
              </Hidden>
            </div>
          </div>
          </Toolbar>
          {/* {currentUser ? <div style={{"height": "100%", "backgroundColor": "red", "textAlign": "center"}}> */}
          {/*   <h3>{t('frontPage.maintenance')}</h3> */}
          {/* </div> : null} */}
        </AppBar>}

        {currentUser && <nav className={classes.drawer} aria-label="navigation">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden xsUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>}

        {currentUser && <div className={classes.toolbar} />}
        <Notifications enqueueSnackbar={props.enqueueSnackbar} />
        <Switch>
          <Route exact path="/">
            {(currentUser && path)
              ? <Redirect to={'/' + path} />
              : (currentUser && !path)
                ? <LandingScreen />
                : (!currentUser && authToken && path && path === 'setNewPassword')
                  ? <Redirect to={`/setNewPassword?authToken=${authToken}`} />
                  : (!currentUser && path && path === 'signUp')
                    ? <Redirect to="/signUp" />
                    : (!currentUser && authToken && path && path === 'confirmNewEmail')
                      ? <Redirect to={`/confirmNewEmail?authToken=${authToken}`} />
                      : (!currentUser && code && state && state === 'signUp')
                        ? <Redirect to={`/signUp`} />
                        : <Redirect to="/signIn" />}
          </Route>

          <Route path="/signIn" render={() => !currentUser ? <SignIn /> : <Redirect to="/" />} />

          <Route path="/signUp" render={() => !currentUser ? <SignUp /> : <Redirect to="/" />} />

          <Route path="/setNewPassword" render={() => token ? <SetNewPassword /> : <Redirect to="/" />} />

          <Route path="/forgottenPassword" render={() => !currentUser ? <ForgottenPassword /> : <Redirect to="/" />} />

          <Route path="/confirmNewEmail" render={() => token ? <ConfirmNewEmail /> : <Redirect to="/" />} />

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/promokoodit" component={PromoCodes}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/alennuskoodit" component={DiscountCodes}/>}

          <ProtectedRoute currentUser={currentUser} exact path="/laskut/uusi/:userId/:clientId" create component={Invoice}/>

          <ProtectedRoute currentUser={currentUser} exact path="/laskut/uusi/:worklistId" worklist component={Invoice}/>

          <ProtectedRoute currentUser={currentUser} exact path="/laskut/muokkaa/:id" edit component={Invoice}/>

          <ProtectedRoute currentUser={currentUser} exact path="/laskut/kierrata/:id" recycle component={Invoice}/>

          <ProtectedRoute currentUser={currentUser} exact path="/laskut/esikatsele/:id" view component={Invoice}/>

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/kulut/muokkaa/:id" exp edit component={Invoice}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/kulut/kierrata/:id" exp recycle component={Invoice}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/kulut/kierrata_kulu/:id" exp expense recycle component={Invoice}/>}

          <ProtectedRoute currentUser={currentUser} exact path="/kulut/esikatsele/:id" exp view component={Invoice}/>

          {isAdmin && <ProtectedRoute currentUser={currentUser} path="/kulut/uusi" exp component={Invoice}/>}

          <ProtectedRoute currentUser={currentUser} path="/laskut/uusi" component={Invoice}/>

          <ProtectedRoute currentUser={currentUser} exact path="/todistukset" component={References}/>

          {<ProtectedRoute currentUser={currentUser} exact path="/valttikortit" component={Valtticards}/>}

          <ProtectedRoute currentUser={currentUser} exact path="/viestit" component={Messages}/>

          <ProtectedRoute currentUser={currentUser} exact path="/asiakkaat" component={Clients}/>

          <ProtectedRoute currentUser={currentUser} exact path="/asiakkaat/uusi" component={Client}/>

          <ProtectedRoute currentUser={currentUser} exact path="/asiakkaat/muokkaa/:id" edit component={Client}/>

          <ProtectedRoute currentUser={currentUser} exact path="/kayttajat/muokkaa/:id" edit component={User}/>

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/kayttajat/uusi" newUser component={User}/>}

          <ProtectedRoute currentUser={currentUser} exact path="/kayttajat/:id" component={User}/>

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/kayttajat/maksulista/:id" payList component={User}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/kayttajat" component={Users}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/muistiinpanot" component={UserNotes}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/palkkalaskelmat" component={Salaries}/>}

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/verokortit" component={Taxcards}/>}

          <ProtectedRoute currentUser={currentUser} exact path="/verokortti/:id" component={Taxcard}/>

          <ProtectedRoute currentUser={currentUser} exact path="/tyolistat" component={Worklists}/>

          <ProtectedRoute currentUser={currentUser} exact path="/tyolistat/uusi" component={Worklist}/>

          <ProtectedRoute currentUser={currentUser} exact path="/tyolistat/muokkaa/:id" edit component={Worklist}/>

          <ProtectedRoute currentUser={currentUser} exact path="/info/:id" component={Info}/>

          <ProtectedRoute currentUser={currentUser} exact path="/info" component={Info}/>

          {isAdmin && <ProtectedRoute currentUser={currentUser} exact path="/tiedotteet" component={Announcements}/>}

          <ProtectedRoute currentUser={currentUser} exact path="/contact-info" component={ContactInfo}/>

          <ProtectedRoute currentUser={currentUser} exact path="/terms" component={Terms}/>

          <ProtectedRoute currentUser={currentUser} exact path="/tietosuoja-ja-rekisteriselosteet" component={GDPR}/>

          <Route path='*' exact>{(currentUser) ? <Redirect to="/" /> : <Redirect to="/signIn" />}</Route>

        </Switch>

      </Router>
    </div>
  );
}

/* ResponsiveDrawer.propTypes = { */
/**
 * Injected by the documentation to work in an iframe.
 * You won't need it on your project.
 */
/*   window: PropTypes.func,
}; */

export default withSnackbar(App);

