import React, { useState, useEffect } from 'react'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import { withSnackbar } from 'notistack'
import { sendNewNote, updateUserNote, deleteUserNote } from '../service'
import DropDown from './DropDown'
import { cLog, formatDate, formatDatetime } from '../utils'
import { useTranslation } from 'react-i18next'
import DPicker from './DPicker'
import { Field } from 'formik'
import TextArea from './TextArea'
import Button from './Button'
import Alert from './Alert'

const useStyles = makeStyles({
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
})

const UserNoteDialog = ({
  resetForm,
  values,
  setFieldValue,
  validateForm,
  errors,
  enqueueSnackbar,
  open,
  setOpenUserNoteDialog,
  user,
  setUser,
  editUserNote,
  fetchData
}) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [openConfirmDeleteUserNoteDialog, setOpenConfirmDeleteUserNoteDialog] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (editUserNote) {
      setFieldValue('id', editUserNote.id)
      setFieldValue('userId', editUserNote.userId)
      setFieldValue('date', editUserNote.date)
      setFieldValue('status', editUserNote.status)
      setFieldValue('notes', editUserNote.notes)
      setShowHistory(false)
    }
  }, [editUserNote, setFieldValue])

  const statusOptions = ['AVOIN', 'HOIDETTU'].map(status => ({ label: t('user.'+status), value: status }))

  const validateDate = (value) => {
    if (!String(value).trim()) {
      return { key: t('user.dateMissing') }
    }
    if (new Date(value).withoutTime() > new Date().withoutTime()) {
      return { key: t('user.wrongDate') }
    }
  }

  const validateNotes = (value) => {
    if (!String(value).trim()) {
      return { key: t('user.noNote') }
    }
    if (value && String(value).trim().length > 10000) {
      return { key: t('user.noteTooLong') }
    }
  }

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    if (!errorTypes[0]) {
      enqueueSnackbar(
        t('auth.fillMissingFields'),
        { variant: 'error' }
      )
    }
  }

  const handleSaveNote = async () => {
    setLoading(true)
    const errori = await validateForm()
    if (Object.keys(errori).length > 0) {
      notifyErrors(errori)
      setLoading(false)
    } else {
      let request
      if (editUserNote) {
        request = updateUserNote({ ...values, userId: (user && user.id) || values.userId })
      } else {
        request = sendNewNote({ ...values, userId: (user && user.id) || values.userId })
      }
      request.then(response => {
        cLog('MUISTIINPANO LÄHETETTY', response)
        setOpenUserNoteDialog(false)
        enqueueSnackbar(
          t('user.noteSent'),
          { variant: 'success' }
        )
        if (setUser) {
          setUser(response)
        }
        setLoading(false)
        resetForm()
        if (fetchData) {
          fetchData()
        }
      }, error => {
        setLoading(false)
        cLog('MUISTIINPANO POST ERROR', error)
        enqueueSnackbar(
          t('user.noteError'),
          { variant: 'error' }
        )
      })
    }
  }

  const handleAgreeDeleteUserNoteDialog = async () => {
    setLoading(true)
    const request = deleteUserNote(values.id)
    request.then(response => {
      cLog('MUISTIINPANO POISTETTU', response)
      enqueueSnackbar(
        t('user.userNoteDeleted'),
        { variant: 'success' }
      )
      setLoading(false)
      if (setUser) {
        setUser(response.data)
      }
      setOpenConfirmDeleteUserNoteDialog(false)
      setOpenUserNoteDialog(false)
      if (fetchData) {
        fetchData()
      }
    }, error => {
      setLoading(false)
      setOpenConfirmDeleteUserNoteDialog(false)
      cLog('MUISTIINPANO DELETE ERROR', error)
      enqueueSnackbar(
        t('user.userNoteDeletedError'),
        { variant: 'error' }
      )
    })
  }

  const History = () => {
    let collection = [];
    editUserNote.updateHistory.map((row, i) => {
      let name, filtered, joined;
      let changes = [];

      for (const [key, value] of Object.entries(row.changes)) {
        if (key !== 'user_id') {
          let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
          let value0 = value[0]
          let value1 = value[1]

          if (value[0] || value[1]) {
            if (value0 === 'true' || value0 === true) {
              value0 = t('taxcard.true')
            } else if (value0 === 'false'|| value0 === false) {
              value0 = t('taxcard.false')
            } else if (!value0) {
              value0 = t('user.noValue')
            }

            if (value1 === 'true' || value1 === true) {
              value1 = t('taxcard.true')
            } else if (value1 === 'false' || value1 === false) {
              value1 = t('taxcard.false')
            } else if (!value1) {
              value1 = t('user.noValue')
            }

            changes.push(t('user.'+k) + ': ' + value0 + ' -> ' + value1)
          }
        }
      }

      if (changes.length) {
        if (row.firstName && row.lastName) {
          name = row.firstName + ' ' + row.lastName;
        } else if (row.firstName && !row.lastName) {
          name = row.firstName;
        } else if (!row.firstName && row.lastName) {
          name = row.lastName
        } else {
          name = 'Odealin järjestelmä'
        }

        if (row.itemType !== "ActiveStorage::Attachment") {
          filtered = changes.filter(function (f) {
            return f != null;
          });
          joined = filtered.join(', ');
        }

        collection.push(<TableRow key={i}>
          <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{joined}</TableCell>
        </TableRow>)
      }

      return null
    })

    return (<div style={{ paddingBottom: '20px' }}>
      <br /><br />
      {t('user.historyTitleNotes')}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('customers.date')}</TableCell>
            <TableCell>{t('customers.user')}</TableCell>
            <TableCell>{t('customers.events')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection}
        </TableBody>
      </Table>
    </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setOpenUserNoteDialog(false)
          resetForm()
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="user-note-dialog-title"
      aria-describedby="user-note-dialog-description"
    >
      <DialogTitle id="user-note-dialog-title">{editUserNote ? t('user.editNote') : t('user.createNewNote')}</DialogTitle>
      <DialogContent dividers={true} id="user-note-dialog-description">
        <form>
          <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} sm={6}>
              <Field
                name="date"
                id="date"
                label={t('user.date')+' *'}
                component={DPicker}
                selected={values.date ? new Date(values.date) : ''}
                onChange={(date) => setFieldValue('date', date ? formatDate(date) : '')}
                validate={() => validateDate(values.date)}
                error={errors && errors.date && validateDate(values.date)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="status"
                id='status'
                label={t('user.status')}
                component={DropDown}
                options={statusOptions}
                value={statusOptions.find(option => option.value === values.status)}
                onChange={(e) => { setFieldValue('status', e.value) }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Field
                name="notes"
                id='notes'
                label={t('user.notes')+' *'}
                type='text'
                component={TextArea}
                value={values.notes}
                onChange={(e) => { setFieldValue('notes', e.target.value) }}
                validate={() => validateNotes(values.notes)}
                error={errors && errors.notes && validateNotes(values.notes)}
              />
            </Grid>
          </Grid>
        </form>
        {showHistory && editUserNote && <Grid container spacing={0}>
          <Grid item xs={12}>
            <History />
          </Grid>
        </Grid>}
      </DialogContent>
      <DialogActions>
        {editUserNote && <Button
          variant="text"
          onClick={() => setShowHistory(!showHistory)}
          color="secondary"
          disabled={isLoading}
        >
          {t('discountCode.history')}
        </Button>}
        {editUserNote && <Button
          variant="text"
          onClick={() => { setOpenConfirmDeleteUserNoteDialog(true) }}
          color="error"
          disabled={isLoading}
        >
          {t('customers.remove')}
        </Button>}
        <Button
          variant="text"
          onClick={() => {
            setLoading(false)
            setOpenUserNoteDialog(false)
            resetForm()
          }}
          color="secondary"
          disabled={isLoading}
        >
          {t('user.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleSaveNote}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('invoice.save')}
        </Button>
      </DialogActions>
      <Alert
        open={openConfirmDeleteUserNoteDialog}
        setOpen={setOpenConfirmDeleteUserNoteDialog}
        handleAgree={handleAgreeDeleteUserNoteDialog}
        handleClose={() => { setOpenConfirmDeleteUserNoteDialog(false) }}
        alertMessage={{ title: t('user.userNoteDeleteWarningTitle'), body: t('user.userNoteDeleteWarningBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
        isLoading={isLoading}
      />
    </Dialog>
  )
}

export default withSnackbar(UserNoteDialog)
