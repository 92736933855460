import React, { useEffect, useState } from 'react'
import { useStateValue } from "../../state"
import { Formik } from 'formik'
import UserForm from './UserForm'
import { useHistory, useParams } from 'react-router-dom'
import UserView from './UserView'
import { getUserView } from '../../service'
import { cLog } from '../../utils'
import LayoutContainer from '../../components/LayoutContainer'

const USER_FORM_TEMPLATE = {
  firstName: '',
  lastName: '',
  ssn: '',
  email: '',
  phone: '',
  address1: '',
  postalCode: '',
  city: '',
  country: 'FINLAND',
  language: 'FINNISH',
  marketingName: '',
  bankAccount: '',
  bic: '',
  commission: 5,
  industry: '',
  customIndustry: '',
  role: 'LASKUTTAJA',
  citizenship: 'Suomi',
  taxcards: {},
  odealAccount: 0,
  notAccounted: 0
}

export const USER_NOTE_TEMPLATE = {
  userId: '',
  status: 'AVOIN',
  date: new Date().toISOString().split('T')[0],
  notes: ''
}

const User = ({ edit, payList, newUser }) => {
  const [user, setUser] = useState(null)
  let { id } = useParams()
  let history = useHistory()
  const [{ currentUser }] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  useEffect(() => {
    const fetchData = async () => {
      if (!newUser) {
        //cLog('HAETAAN USERILLE DATAA-----------------------')
        try {
          const thisUser = await getUserView(id)
          setUser(thisUser)
          cLog('THIS USER', thisUser)
        } catch (error) {
          cLog('ONGELMIA USERIN KÄYTTÄJÄSIVUN LATAAMISESSA', error)
          history.push('/')
        }
      }
    }

    fetchData()
  }, [id, newUser, history, isAdmin])

  //cLog('USERFORMILLE USER', user)

  return (user || newUser) ? (
    <LayoutContainer>
      {(edit || newUser) ? <Formik
        initialValues={edit ? user : USER_FORM_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
        component={props =>
          <UserForm
            {...props}
            setUser={setUser}
            edit={edit}
            id={id}
          />}
        />
      :
        <UserView
          user={user}
          setUser={setUser}
          payList={payList}
        />}
    </LayoutContainer>
  ) : null
}

export default User
