import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  customCheckbox: {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  errorCheckbox: {
    color: theme.palette.error.main,
    "&.Mui-checked": {
      color: theme.palette.error.main,
    },
  },
}));

export const OdealCheckbox = (props) => {
  const classes = useStyles();
  return (
    <Checkbox className={props.error ? classes.errorCheckbox : classes.customCheckbox} {...props} />
  )
};

export default OdealCheckbox;
