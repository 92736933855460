import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { updatePassword } from '../service'
import { useStateValue } from "../state"
import Input from './Input'
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import PasswordStrengthBar from 'react-password-strength-bar'
import InfoPop from './InfoPop'
import styled from 'styled-components'
import Button from './Button'

const INIT_FORM = {
  password: '',
  passwordConfirmation: ''
}

const ResponsiveDiv = styled.div`
  width: 100%;
  margin-top: 7px;
  @media only screen and (max-width: 720px) {
    width: 100%;
}`

const PasswordDialog = ({ open, setOpenPasswordDialog, enqueueSnackbar }) => {
  const [form, setForm] = useState(INIT_FORM)
  const [isLoading, setLoading] = useState(false)
  const [{ currentUser }] = useStateValue()
  const { t } = useTranslation()
  const [errors, setErrors] = useState({})

  //cLog('CURRENT USER PASSWORD DIALOG', currentUser)

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    if (!errorTypes[0]) {
      enqueueSnackbar(
        t('auth.fillMissingFields'),
        { variant: 'error' }
      )
    }
  }

  const handleChangePassWord = async (e) => {
    e.preventDefault()

    let err = {}

    //cLog('AUTH TOKEN', authToken)

    if (!form.password || form.password.length < 6) {
      err.password = { key: t('auth.shortPassword') }
    }

    if (!form.passwordConfirmation) {
      err.passwordConfirmation = { key: t('auth.shortPassword') }
    } else if (form.password && form.password !== form.passwordConfirmation) {
      err.passwordConfirmation = { key: t('auth.passwordMismatch') }
    }

    if (Object.keys(err).length !== 0) {
      cLog('FORMISSA VIKAA')
      notifyErrors(err)
      setErrors(err)

      return false
    }

    setErrors(err)

    try {
      setLoading(true)
      const response = updatePassword(form, currentUser.id)
      response.then(data => {
        cLog('SET NEW PASSWORD RESPONSE', response)
        enqueueSnackbar(
          t('user.passwordChanged'),
          { variant: 'success' }
        )
        setForm(INIT_FORM)
        setLoading(false)
        setOpenPasswordDialog(false)
        setLoading(false)
      }, error => {
        cLog('SET NEW PASSWORD ERROR', error)
        enqueueSnackbar(
          t('user.passwordChangedError'),
          { variant: 'error' }
        )
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      cLog('SET NEW PASSWORD ERROR', error)
      enqueueSnackbar(
        t('user.passwordChangedError'),
        { variant: 'error' }
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xs'
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setForm(INIT_FORM)
          setOpenPasswordDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="password-dialog-title"
      aria-describedby="password-dialog-description"
    >
      <DialogTitle id="password-dialog-title">{t('user.changePassword')}</DialogTitle>
      <DialogContent dividers={true} id="password-dialog-description">
        <Input
          type='password'
          value={form.password}
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          label={t('auth.password')+' *'}
          placeholder={t('auth.password')}
          error={errors && Object.keys(errors).includes('password') ? 'passwordError' : undefined}
        />
        <ResponsiveDiv>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PasswordStrengthBar
              style={{ width: '90%' }}
              password={form.password}
              minLength={6}
              shortScoreWord={t('auth.tooShort')}
              scoreWords={[t('auth.bad'),t('auth.weak'),t('auth.average'),t('auth.good'),t('auth.strong')]}
            />
            <InfoPop info={t('auth.passwordTip')} />
          </div>
        </ResponsiveDiv>
        <Input
          type='password'
          value={form.passwordConfirmation}
          onChange={(e) => setForm({ ...form, passwordConfirmation: e.target.value })}
          label={t('auth.confirmPassword')+' *'}
          placeholder={t('auth.confirmPassword')}
          error={errors && Object.keys(errors).includes('passwordConfirmation') ? 'passwordConfirmationError' : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="secondary"
          disabled={isLoading}
          onClick={() => {
            setForm(INIT_FORM)
            setErrors({})
            setOpenPasswordDialog(false)
          }}
        >
          {t('user.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleChangePassWord}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('user.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(PasswordDialog)
