import React, { useState } from 'react'
import { Typography, CircularProgress, Button, TextField, Link, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { resetPassword } from '../../service'
import { COLORS } from '../../styles'
import { withSnackbar } from 'notistack'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { isEmail } from '../../utils/invoiceUtils'
import { useHistory } from 'react-router-dom'
import AuthLayoutContainer from './AuthLayoutContainer'
import AuthForm from './AuthForm'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.primary
  },
  link: {
    cursor: 'pointer',
    padding: '10px'
  }
}))

const ForgottenPassword = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isSent, setSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const classes = useStyles()
  let history = useHistory()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const email = e.target.email.value

    if (!email) {
      enqueueSnackbar(
        t('auth.noEmail'),
        { variant: 'error' }
      )
      return
    } else if (!isEmail(email)) {
      enqueueSnackbar(
        t('auth.wrongEmail'),
        { variant: 'error' }
      )
      return
    }

    setLoading(true)
    const response = resetPassword({ email })
    response.then(data => {
      cLog('RESET PASSWORD RESPONSE', response)
      setLoading(false)
      enqueueSnackbar(
        t('auth.emailSuccess'),
        { variant: 'success' }
      )
      setSent(true)
    }, error => {
      setLoading(false)
      cLog('RESET PASSWORD ERROR', error)
      enqueueSnackbar(
        t('auth.resetPasswordError'),
        { variant: 'error' }
      )
    })
  }

  return (
    <AuthLayoutContainer>
      <AuthForm title={t('auth.returnPassword')} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('auth.email')}
          name="email"
          autoComplete="email"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={28} /> : t('auth.send')}
        </Button>
        <Box mt={2}>
          <Grid container justifyContent="center">
            <Grid item>
              {!isSent
                ? <Typography> {t('auth.passwordLink')}</Typography>
                : <Typography>{t('auth.emailCheck')}</Typography>}
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Grid container justifyContent="flex-end">
            <Grid item>
                <Link className={classes.link} onClick={() => history.push('/signIn')} variant="body2">
                  {t('auth.registeredAlready')}
                 </Link>
            </Grid>
          </Grid>
        </Box>
      </AuthForm>
    </AuthLayoutContainer>
  )
}

export default withSnackbar(ForgottenPassword)
