import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery, updateReference, deleteReference } from '../../service'
import { useStateValue, setReferences, setSortKeyReferences, setSortOrderReferences } from "../../state"
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core'
import LayoutContainer from '../../components/LayoutContainer'
import Header from "../../components/Header"
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import DoneIcon from '@material-ui/icons/Done'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import ReferenceForm from './ReferenceForm'
import { formatTimeFI, getDateNow } from '../../utils'

let selectedColumns  = []

const INIT_FILTER = {
  userId: [],
  referenceType: [],
  deliveryMethod: '',
  explanations: '',
  status: '',
  createdAt: '',
  updatedAt: '',
  actions: '',
  columnsOptions: []
}

const INIT_FORM = {
  date: getDateNow(),
  isWorkReference: true,
  isSalaryReference: false,
  sendByEmail: true,
  customPeriod: false,
  dateStartWork: '',
  dateEndWork: '',
  explanations: '',
  status: 'LUONNOS'
}

const References = ({ enqueueSnackbar, userId, landingScreen, forceFetchDataUserView, setForceFetchDataUserView }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ references, currentUser, sortKeyReferences, sortOrderReferences }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openConfirmUpdateModal, setOpenConfirmUpdateModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: (isAdmin ? 100 : 20),
    totalPages: 0,
    totalRows: 0
  })
  let allColumns = []
  if (userId) {
    allColumns = ['referenceType', 'deliveryMethod', 'selectedPeriod', 'explanations', 'status', 'createdAt', 'updatedAt', 'actions']
      selectedColumns = allColumns
  } else {
    if (isAdmin) {
      allColumns = ['userId', 'referenceType', 'deliveryMethod', 'selectedPeriod', 'explanations', 'status', 'createdAt', 'updatedAt', 'actions']
      if (landingScreen) {
        selectedColumns = allColumns
      } else {
        selectedColumns = JSON.parse(window.localStorage.getItem('referencesSelectedColumns')) || allColumns
      }
    } else {
      allColumns = ['referenceType', 'deliveryMethod', 'selectedPeriod', 'explanations', 'status', 'createdAt']
      selectedColumns = allColumns
    }
  }
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('reference.'+option),
      value: option
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('reference.setAsFinished')}>
            <DoneIcon
              className="actionIcon"
              onClick={() => {
                setSelectedRow(row.id)
                setOpenConfirmUpdateModal(true)
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setSelectedRow(row)
                setOpenConfirmDeleteModal(true)
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      let query = ''
      if (userId) {
        query += '&user_id=' + userId
      }
      if (landingScreen) {
        query += '&status=TARKASTETTAVANA'
      }
      const response = getQuery(pagination, setPagination, sortKeyReferences, sortOrderReferences, filter, selectedColumns, 'references', query)
      response.then(data => {
        const dataActions = data.map(row => {
          if (row.status === 'TARKASTETTAVANA') {
            row.actions = createActions(row)
          }
          if (selectedColumns.includes('referenceType')) {
            row.referenceType = row.isWorkReference && row.isSalaryReference ? t('reference.bothReports') : (row.isWorkReference ? t('reference.workReport') : t('reference.salaryReport'))
          }
          if (selectedColumns.includes('deliveryMethod')) {
            row.deliveryMethod = row.sendByEmail ? t('reference.byEmail') : t('reference.byMail')
          }
          if (selectedColumns.includes('selectedPeriod')) {
            row.selectedPeriod = !row.customPeriod ? t('reference.lastSalaryPeriod') : `${row.dateStartWork && formatTimeFI(row.dateStartWork)} - ${row.dateEndWork && formatTimeFI(row.dateEndWork)}`
          }
          if (selectedColumns.includes('status')) {
            row.status = t('reference.'+row.status)
          }
          return row
        })
        dispatch(setReferences(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setReferences([]))
        setLoading(false)
      })
    }

    if (forceFetchData || forceFetchDataUserView) {
      setLoading(true)
      setForceFetchData(false)
      if (setForceFetchDataUserView) {
        setForceFetchDataUserView(false)
      }
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyReferences, sortOrderReferences, filter, pagination, t, enqueueSnackbar, userId, landingScreen, forceFetchDataUserView, setForceFetchDataUserView])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    if (isAdmin) {
      document.addEventListener('keyup', handleKeyPress)
    }

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination, isAdmin])

  const tableTitle = () => {
    if (landingScreen) {
      return `${t('reference.references')}${pagination.totalRows > 0 ? ' - ' + pagination.totalRows + t('reference.new')  : ''}`
    } else if (userId) {
      return t('frontPage.certificates')
    } else {
      const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
      return t('frontPage.certificates') + coda
    }
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteReference(selectedRow.id)
      cLog(`REFERENCE ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('reference.referenceRemoved'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE REFERENCE ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('reference.referenceRemoveError'),
        { variant: 'error' }
      )
    }
  }

  const handleAgreeUpdateModal = async () => {
    setLoading(true)
    const request = updateReference({ id: selectedRow, status: 'VALMIS' })
    request.then(response => {
      cLog(`REFERENCE ${selectedRow.id} UPDATED`, response)
      setSelectedRow(null)
      enqueueSnackbar(
        t('user.noteSent'),
        { variant: 'success' }
      )
      setLoading(false)
      setOpenConfirmUpdateModal(false)
      setForceFetchData(true)
    }, error => {
      setSelectedRow(null)
      setLoading(false)
      setOpenConfirmUpdateModal(false)
      cLog('UPDATE REFERENCE ERROR', error)
      enqueueSnackbar(
        t('user.noteError'),
        { variant: 'error' }
      )
    })
  }

  const referenceOptions = ['workReport', 'salaryReport'].map(option => ({ label: t('reference.'+option), value: option }))
  const deliveryMethodOptions = ['byEmail', 'byMail'].map(option => ({ label: t('reference.'+option), value: option }))
  const statusOptions = ["TARKASTETTAVANA", "VALMIS"].map(status => ({ label: t('invoice.'+status), value: status }))

  return (
    <>
      {landingScreen &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>{tableTitle()}</h3>
          </AccordionSummary>
          <AccordionDetails>
            {references.length > 0
            ? <Datatable
                data={references}
                fetchData={() => setForceFetchData(true)}
                tableTitle={''}
                selectedColumns={selectedColumns}
                columnsOptions={columnsOptions}
                sortKey={sortKeyReferences}
                setSortKey={setSortKeyReferences}
                sortOrder={sortOrderReferences}
                setSortOrder={setSortOrderReferences}
                isAdmin={true}
              />
            : t('reference.noReferences')
          }
          </AccordionDetails>
        </Accordion>
      }
      {!landingScreen &&
        <LayoutContainer noPadding={userId}>
          {!userId && <Header
            title={isAdmin ? t('frontPage.certificates') : t('reference.newReportForm')}
          />}
          {!userId &&
            <>
              {!isAdmin &&
                <Formik
                  enableReinitialize
                  initialValues={INIT_FORM}
                  validateOnChange={false}
                  validateOnBlur={false}
                  component={props =>
                    <ReferenceForm
                      fetchData={() => setForceFetchData(true)}
                      {...props}
                    />
                  }
                />
              }
              {isAdmin && <DatatableFilters
                inputs={[
                  { field: 'userId', label: t('invoice.billers'), type: 'userId' },
                  { field: 'referenceType', label: t('reference.referenceType'), type: 'select', options: referenceOptions },
                  { field: 'deliveryMethod', label: t('reference.deliveryMethod'), type: 'select', options: deliveryMethodOptions },
                  { field: 'explanations', label: t('reference.explanations'), type: 'date' },
                  { field: 'status', label: t('reference.status'), type: 'select', options: statusOptions },
                  { field: 'createdAt', label: t('userNotes.createdAt'), type: 'date' },
                  { field: 'updatedAt', label: t('userNotes.updatedAt'), type: 'date' }
                ]}
                filter={filter}
                setFilter={setFilter}
                pagination={pagination}
                setPagination={setPagination}
                fetchData={() => setForceFetchData(true)}
                initFilter={INIT_FILTER}
                isLoading={isLoading}
              />}
            </>
          }
          <DatatablePagination
            pagination={pagination}
            setPagination={setPagination}
            fetchData={() => setForceFetchData(true)}
            isLoading={isLoading}
            selectedColumns={selectedColumns}
            columnsOptions={columnsOptions}
            filter={filter}
            setFilter={setFilter}
            localStorage={'referencesSelectedColumns'}
            sortKey={sortKeyReferences}
            setSortKey={setSortKeyReferences}
            sortOrder={sortOrderReferences}
            setSortOrder={setSortOrderReferences}
            isAdmin={isAdmin}
            allowSelectColumns={userId || !isAdmin ? false : true}
          />
          <Datatable
            data={references}
            fetchData={() => setForceFetchData(true)}
            tableTitle={tableTitle()}
            selectedColumns={selectedColumns}
            columnsOptions={columnsOptions}
            sortKey={sortKeyReferences}
            setSortKey={setSortKeyReferences}
            sortOrder={sortOrderReferences}
            setSortOrder={setSortOrderReferences}
            isAdmin={isAdmin}
          />
        </LayoutContainer>
      }
      <Alert
        open={openConfirmUpdateModal}
        setOpen={setOpenConfirmUpdateModal}
        handleAgree={handleAgreeUpdateModal}
        handleClose={() => setOpenConfirmUpdateModal(false)}
        alertMessage={{ title: t('reference.confirmUpdateTitle') }}
      />
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('reference.confirmDeletionTitle'), body: t('reference.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </>
  )
}

export default withSnackbar(References)
