import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery, getPromoCode, deletePromoCode } from '../../service'
import Button from '../../components/Button'
import { useStateValue, setPromoCodes, setSortKeyPromoCodes, setSortOrderPromoCodes } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import PromoDialogOffice from './PromoDialogOffice'
import PromoDialog from '../../components/PromoDialog'
import '../../styles/styles.css'

let selectedColumns  = []

const INIT_FILTER = {
  code: '',
  companyName: '',
  expires: '',
  expired: '',
  discount: '',
  finDescription: '',
  enDescription: '',
  url: '',
  createdAt: '',
  updatedAt: '',
  columnsOptions: []
}

const INIT_FORM = {
  id: '',
  code: '',
  companyName: '',
  expires: '',
  discount: '',
  finDescription: '',
  enDescription: '',
  url: '',
  updateHistory: []
}

const PromoCodes = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ promoCodes, sortKeyPromoCodes, sortOrderPromoCodes }, dispatch] = useStateValue()
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openPromoDialogOffice, setOpenPromoDialogOffice] = useState(false)
  const [openPromoDialog, setOpenPromoDialog] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })
  const allColumns = ['code', 'companyName', 'expires', 'discount', 'finDescription', 'enDescription', 'url', 'createdAt', 'updatedAt', 'actions']
  selectedColumns = JSON.parse(window.localStorage.getItem('promoCodesSelectedColumns')) || allColumns
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('promocodes.'+option),
      value: option
    }
    if (['code'].includes(option)) {
      obj.style = { whiteSpace: 'nowrap' }
    }
    if (['expires'].includes(option)) {
      obj.date = true
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getPromoCode(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenPromoDialogOffice(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getPromoCode(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenConfirmDeleteModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyPromoCodes, sortOrderPromoCodes, filter, selectedColumns, 'promoCodes')
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          return row
        })
        dispatch(setPromoCodes(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setPromoCodes([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyPromoCodes, sortOrderPromoCodes, filter, pagination, t, enqueueSnackbar])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.promoCodes') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deletePromoCode(selectedRow)
      cLog(`PROMO CODE ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('promocodes.promoCode') + ' ' + JSON.stringify(`${selectedRow.companyName}`) + t('customers.deleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
        setForceFetchData(true)
    } catch (error) {
      cLog('DELETE PROMO CODE ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('promocodes.problemsDeletingPromoCode'),
        { variant: 'error' }
      )
    }
  }

  const expiredOptions = [{ label: t('taxcard.true'), value: 'false' }, { label: t('taxcard.false'), value: 'true' }]

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.promoCodes')}
        actions={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { setOpenPromoDialog(true) }}
            >
              {t('promocodes.show')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => { setOpenPromoDialogOffice(true) }}
            >
              {t('promocodes.addNewPromoCode')}
            </Button>
          </>
        }
      />
      <DatatableFilters
        inputs={[
          { field: 'code', label: t('promocodes.code'), type: 'text' },
          { field: 'companyName', label: t('promocodes.companyName'), type: 'text' },
          { field: 'expires', label: t('promocodes.expires'), type: 'date' },
          { field: 'expired', label: t('promocodes.expired'), type: 'select', options: expiredOptions },
          { field: 'discount', label: t('promocodes.discount'), type: 'text' },
          { field: 'finDescription', label: t('promocodes.finDescription'), type: 'text' },
          { field: 'enDescription', label: t('promocodes.enDescription'), type: 'text' },
          { field: 'url', label: t('promocodes.url'), type: 'text' },
          { field: 'createdAt', label: t('userNotes.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('userNotes.updatedAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'promoCodesSelectedColumns'}
        sortKey={sortKeyPromoCodes}
        setSortKey={setSortKeyPromoCodes}
        sortOrder={sortOrderPromoCodes}
        setSortOrder={setSortOrderPromoCodes}
        isAdmin={true}
      />
      <Datatable
        data={promoCodes}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyPromoCodes}
        setSortKey={setSortKeyPromoCodes}
        sortOrder={sortOrderPromoCodes}
        setSortOrder={setSortOrderPromoCodes}
        isAdmin={true}
      />
      <Formik
        enableReinitialize
        initialValues={INIT_FORM}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <PromoDialogOffice open={openPromoDialogOffice} setOpen={setOpenPromoDialogOffice} edit={selectedRow} setEdit={setSelectedRow} fetchData={() => setForceFetchData(true)} {...formikProps} />}
      </Formik>
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('promocodes.confirmDeletionTitle') + JSON.stringify(`${selectedRow ? selectedRow.companyName : ''}`) + '?', body: t('customers.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
      <PromoDialog open={openPromoDialog} setOpenPromoDialog={setOpenPromoDialog} isAdmin={true} />
    </LayoutContainer>
  )
}

export default withSnackbar(PromoCodes)
