import request from './request'
import { deserializeDiscountCodes, deserializeDiscountCode } from '../utils/deserializer'
import { serializeDiscountCode } from '../utils/serializer'
//import { cLog } from '../utils'

export const getDiscountCodes = async (query) => {
  const { data: discountCodes } = await request('GET', '/discount_codes' + (query ? query : ''))
  return deserializeDiscountCodes(discountCodes)
}

export const getDiscountCode = async (id) => {
  const { data: discountCode } = await request('GET', '/discount_codes/' + id)
  return deserializeDiscountCode(discountCode)
}

export const saveDiscountCode = async (data) => {
  const { data: newCode } = await request('POST', '/discount_codes', serializeDiscountCode(data))
  return deserializeDiscountCode(newCode)
}

export const updateDiscountCode = async (data) => {
  delete data.updateHistory
  const { data: updatedCode } = await request('PUT', `/discount_codes/${data.id}`, serializeDiscountCode(data))
  return deserializeDiscountCode(updatedCode)
}

export const deleteDiscountCode = async (id) => await request('DELETE', `/discount_codes/${id}`)
