//import { cLog } from '.'

const deserializeInvoiceRow = (row) => ({
  ...row,
  content: row.content || '',
  quantity: row.quantity || '0',
  price: row.price || '0',
  priceWithTax: row.priceWithTax || '0',
  tax: row.tax || '0',
  total: row.total || '0'
})

const deserializeCorrectionRow = (row) => ({
  ...row,
  notes: row.notes || '',
  quantity: row.quantity || '0',
  amount: row.amount || '0',
  amountWithTax: row.amountWithTax || '0',
  tax: row.tax || '0',
  total: row.total || '0'
})

const deserializeAccountRow = (row) => ({
  ...row,
  amount: row.amount || '0'
})

const deserializePaymentRow = (row) => ({
  ...row,
  txAmt: row.txAmt || '0',
  acctSvcrRef: row.acctSvcrRef || '',
  noAcctSvcrRef: !(row.acctSvcrRef && row.acctSvcrRef.length > 0)
})

const deserializeTravelDestination = (destination) => ({
  ...destination,
  verified: true
})

const deserializeTravellingExpenceRow = (row) => {
  //cLog('RAAKA MATKARIVI', row)

  const { id, route, departureDate, departureTime, returnDate, returnTime, km, dailyAllowances, partTimeDailyAllowances, mealExpences, vehicle, mileage, country, registerNumber, total, vat, travelType, specialIndustryOvernight, specialIndustryPrimaryPlace, specialIndustryDining, mobileWorkPrimaryPlace, daAmount, ptdaAmount, meAmount, purpose, purposeNotes, travelRowDestinations } = row

  return ({
    ...row,
    id,
    route: route || '',
    departureDate: departureDate || '',
    departureTime: departureTime || '',
    returnDate: returnDate || '',
    returnTime: returnTime || '',
    km: km || '0',
    dailyAllowances: dailyAllowances || '0',
    partTimeDailyAllowances: partTimeDailyAllowances || '0',
    mealExpences: mealExpences || '0',
    vehicle: vehicle || 'auto',
    mileage: mileage || '0',
    country: country || 'FINLAND',
    registerNumber: registerNumber || '',
    total: total || '0',
    vat: vat || '0',
    travelType: travelType || '',
    specialIndustryOvernight: specialIndustryOvernight !== null ? (Boolean(specialIndustryOvernight) ? 'true' : 'false') : null,
    specialIndustryPrimaryPlace: specialIndustryPrimaryPlace !== null ? (Boolean(specialIndustryPrimaryPlace) ? 'true' : 'false') : null,
    specialIndustryDining: specialIndustryDining !== null ? (Boolean(specialIndustryDining) ? 'true' : 'false') : null,
    mobileWorkPrimaryPlace: mobileWorkPrimaryPlace !== null ? (Boolean(mobileWorkPrimaryPlace) ? 'true' : 'false') : null,
    daAmount: daAmount || '0',
    ptdaAmount: ptdaAmount || '0',
    meAmount: meAmount || '0',
    purpose: purpose || 'WORK',
    purposeNotes: purposeNotes || '',
    travelRowDestinations: row.travelRowDestinations && travelRowDestinations.length > 0
      ? row.travelRowDestinations.map(destination => deserializeTravelDestination(destination))
      : null,
  })
}

const deserializeMaterialExpenceRow = (row) => {
  const { explanation, sum } = row

  //cLog('RAAKA KULURIVI', row)

  return ({
    ...row,
    explanation: explanation || '',
    sum: sum || '0'
  })
}

export const deserializeInvoice = (invoice) => {
  //cLog('RAAKA INVOICE', invoice)
  /*const { id, createdAt, updatedAt, status, invoiceNr, date, dateDue, dateStartWork, dateEndWork, refText, currency, additionalInformation, info, payDay, accountingStatus, industry, customIndustry, internalNotes, adminInfo, worklistId, invoiceRows, travellingExpenceRows, materialExpenceRows, attachments, worklists, lastUpdate, deliveryMethod, einvoiceOperator, einvoiceAddress, reminderHandling, vatStatus, hasCustomTravelVats, marketingName, clientId, userId, name, address1, address2, postalCode, city, country, email, phone, isPrivate, contactPerson, businessId, language, hasCustomBusinessId, discountCode, accounts, payments, correctionRows, yel, refCode, exchangeRate, hasTravelRows, hasMaterialRows
  } = invoice*/

  const deserializedInvoice = {
    ...invoice,
    /*id, userId, createdAt, updatedAt, status, invoiceNr, date, dateDue, dateStartWork, dateEndWork,
    refText: refText || '',
    refCode: refCode || '',
    currency,
    additionalInformation: additionalInformation || '',
    info: info || '',
    payDay, accountingStatus,
    industry,
    customIndustry,
    internalNotes: internalNotes || '',
    adminInfo: adminInfo || '',
    worklistId,*/
    accounts: invoice.accounts
      ? invoice.accounts.map(row => deserializeAccountRow(row))
      : [],
    payments: invoice.payments
      ? invoice.payments.map(row => deserializePaymentRow(row))
      : [],
    correctionRows: invoice.correctionRows
      ? invoice.correctionRows.map(row => deserializeCorrectionRow(row))
      : [],
    invoiceRows: invoice.invoiceRows
      ? invoice.invoiceRows.map(row => deserializeInvoiceRow(row))
      : [],
    travellingExpenceRows: invoice.travellingExpenceRows
      ? invoice.travellingExpenceRows.map(row => deserializeTravellingExpenceRow(row))
      : [],
    materialExpenceRows: invoice.materialExpenceRows
      ? invoice.materialExpenceRows.map(row => deserializeMaterialExpenceRow(row))
      : [],
    /*attachments,
    worklists,
    lastUpdate,
    deliveryMethod: deliveryMethod || 'VERKKOLASKU',
    einvoiceOperator: einvoiceOperator || '',
    einvoiceAddress: einvoiceAddress || '',
    discountCode,
    reminderHandling: payDay === 'HETI' ? 'AUTOMAATTINEN' : (reminderHandling || 'AUTOMAATTINEN'),
    vatStatus: vatStatus || 'KOTIMAA',
    hasCustomTravelVats: Boolean(hasCustomTravelVats),*/
    hasTravelRows: Boolean(invoice.travellingExpenceRows && invoice.travellingExpenceRows.length > 0),
    hasMaterialRows: Boolean(invoice.materialExpenceRows && invoice.materialExpenceRows.length > 0),
    /*marketingName,
    yel: Boolean(yel),
    exchangeRate: exchangeRate || '',*/
    vatStatus: invoice.vatStatus || 'KOTIMAA',
    client: {
      id: invoice.clientId,
      userId: invoice.userId,
      name: invoice.name,
      address1: invoice.address1,
      address2: invoice.address2,
      postalCode: invoice.postalCode,
      city: invoice.city,
      country: invoice.country,
      /*email: email || '',
      phone: phone || '',
      isPrivate: Boolean(isPrivate),
      contactPerson: contactPerson || '',
      businessId: businessId || '',
      language: language || 'FINNISH',
      deliveryMethod: deliveryMethod || 'VERKKOLASKU',
      einvoiceOperator: einvoiceOperator || '',
      einvoiceAddress: einvoiceAddress || '',
      reminderHandling: payDay === 'HETI' ? 'AUTOMAATTINEN' : (reminderHandling || 'AUTOMAATTINEN'),
      hasCustomBusinessId: Boolean(hasCustomBusinessId)*/
      email: invoice.email,
      phone: invoice.phone,
      isPrivate: Boolean(invoice.isPrivate),
      contactPerson: invoice.contactPerson,
      businessId: invoice.businessId,
      language: invoice.language || 'FINNISH',
      deliveryMethod: invoice.deliveryMethod || 'VERKKOLASKU',
      einvoiceOperator: invoice.einvoiceOperator,
      einvoiceAddress: invoice.einvoiceAddress,
      reminderHandling: invoice.payDay === 'HETI' ? 'AUTOMAATTINEN' : (invoice.reminderHandling || 'AUTOMAATTINEN'),
      hasCustomBusinessId: Boolean(invoice.hasCustomBusinessId)
    }
  }

  //cLog('PARSITTU INVOICE', deserializedInvoice)
  return deserializedInvoice
}

export const deserializeInvoices = (invoices) => {
  //cLog('TULEVAT INVOICET', invoices)
  return {
    ...invoices,
    invoices: invoices.invoices.map(invoice => deserializeInvoice(invoice))
  }
}

export const deserializeExpence = (expence) => {
  //cLog('RAAKA EXPENCE', expence)
  const deserializedExpence = {
    ...expence,
    adminInfo: expence.adminInfo || '',
    travellingExpenceRows: expence.travellingExpenceRows
      ? expence.travellingExpenceRows.map(row => deserializeTravellingExpenceRow(row))
      : [],
    materialExpenceRows: expence.materialExpenceRows
      ? expence.materialExpenceRows.map(row => deserializeMaterialExpenceRow(row))
      : [],
    accounts: expence.accounts
      ? expence.accounts.map(row => deserializeAccountRow(row))
      : [],
    info: expence.info || '',
    exp: true
  }

  //cLog('PARSITTU EXPENCE', deserializedExpence)
  return deserializedExpence
}

export const deserializeExpences = (expences) => {
  return {
    ...expences,
    expences: expences.expences.map(expence => deserializeExpence(expence))
  }
}

export const deserializeClient = (client) => {
  //cLog('RAAKA CLIENT', client)

  const { id, userId, name, address1, address2, postalCode, city, country, email, phone, isPrivate, contactPerson, businessId, language, deliveryMethod, einvoiceOperator, einvoiceAddress, reminderHandling, hasCustomBusinessId } = client

  const deserializedClient = {
    ...client,
    id,
    userId,
    name: name || '',
    address1: address1 || '',
    address2: address2 || '',
    postalCode: postalCode || '',
    city: city || '',
    country: country || 'FINLAND',
    email: email || '',
    phone: phone || '',
    isPrivate: Boolean(isPrivate),
    contactPerson: contactPerson || '',
    businessId: businessId || '',
    language: language || 'FINNISH',
    deliveryMethod: deliveryMethod || 'VERKKOLASKU',
    einvoiceOperator: einvoiceOperator || '',
    einvoiceAddress: einvoiceAddress || '',
    reminderHandling: reminderHandling || 'AUTOMAATTINEN',
    hasCustomBusinessId: Boolean(hasCustomBusinessId)
  }

  //cLog('PARSITTU CLIENT', deserializedClient)
  return deserializedClient
}

export const deserializeClients = (clients) => {
  return {
    ...clients,
    clients: clients.clients.map(client => deserializeClient(client))
  }
}

export const deserializeTaxcard = (taxcard) => {
  const { userFirstName, userLastName, dateStart, cardType, revisedCard, base, additional, annualIncomeLimit, limitUsed, comments } = taxcard

  const deserializedTaxcard = {
    ...taxcard,
    userFirstName: userFirstName || '',
    userLastName: userLastName || '',
    dateStart: dateStart || '',
    cardType: cardType || '',
    revisedCard: Boolean(revisedCard),
    base: base || '0',
    additional: additional || '0',
    annualIncomeLimit: annualIncomeLimit || '0',
    limitUsed: limitUsed || '0',
    comments: comments || ''
  }

  return deserializedTaxcard
}

export const deserializeUser = (user) => {
  //cLog('RAAKA USER', user)
  const { id, firstName, lastName, ssn, selfAuthenticated, email, phone, address1, address2, postalCode, city, country, language, marketingName, bankAccount, bic, commission, industry, customIndustry, role, attachments, citizenship, distraint, noInstantSalary, notAccounted, odealAccount } = user

  const deserializedUser = {
    ...user,
    id,
    firstName: firstName || '',
    lastName: lastName || '',
    ssn: ssn || '',
    selfAuthenticated: Boolean(selfAuthenticated),
    email: email || '',
    phone: phone || '',
    address1: address1 || '',
    address2: address2 || '',
    postalCode: postalCode || '',
    city: city || '',
    country: country || 'FINLAND',
    language: (['ENGLISH', 'FINNISH'].includes(language) && language) || 'FINNISH',
    marketingName: marketingName || '',
    bankAccount: bankAccount || '',
    bic: bic || '',
    commission: commission || 5,
    industry: industry || '',
    customIndustry: customIndustry || '',
    role: role || '',
    attachments: attachments || [],
    citizenship: citizenship || 'Suomi',
    distraint: Boolean(distraint),
    noInstantSalary: Boolean(noInstantSalary),
    notAccounted: notAccounted || 0,
    odealAccount: odealAccount || 0
  }

  //cLog('PARSITTU USER', deserializedUser)
  return deserializedUser
}

export const deserializeUsers = (users) => {
  return {
    ...users,
    users: users.users.map(user => deserializeUser(user))
  }
}

const deserializeWorklistRow = (row) => ({
  ...row,
  hours: row.hours || '0'
})

export const deserializeWorklist = (worklist) => {
  //cLog('RAAKA WORKLIST', worklist)
  const deserializedWorklist = {
    ...worklist,
    name: worklist.name || '',
    totalHours: worklist.totalHours || '0',
    worklistRows: worklist.worklistRows
      ? worklist.worklistRows.map(row => deserializeWorklistRow(row))
      : []
  }

  //cLog('PARSITTU WORKLIST', deserializedWorklist)
  return deserializedWorklist
}

export const deserializeWorklists = (worklists) => {
  return {
    ...worklists,
    worklists: worklists.worklists.map(worklist => deserializeWorklist(worklist))
  }
}

export const deserializeDiscountCode = (discountCode) => {
  const { code, inviterType, startDate, endDate, businessId, companyName, companyNotes, commission, insurance, signUpBonus, activated } = discountCode

  const deserializedDiscountCode = {
    ...discountCode,
    code: code || '',
    inviterType: inviterType || '',
    startDate: startDate || '',
    endDate: endDate || '',
    businessId: businessId || '',
    companyName: companyName || '',
    companyNotes: companyNotes || '',
    commission: commission || '',
    insurance: insurance || '',
    signUpBonus: signUpBonus || '',
    activated: activated === 'POISTETTU' ? 'POISTETTU' : Boolean(activated)
  }

  return deserializedDiscountCode
}

export const deserializeDiscountCodes = (discountCodes) => {
  return {
    ...discountCodes,
    discountCodes: discountCodes.discountCodes.map(discountCode => deserializeDiscountCode(discountCode))
  }
}

export const deserializePromoCode = (promoCode) => {
  const deserializedPromoCode = {
    ...promoCode
  }

  return deserializedPromoCode
}

export const deserializePromoCodes = (promoCodes) => {
  return {
    ...promoCodes,
    promoCodes: promoCodes.promoCodes.map(promoCode => deserializePromoCode(promoCode))
  }
}

export const deserializeValtticard = (valtticard) => {
  const deserializedValtticard = {
    ...valtticard
  }

  return deserializedValtticard
}

export const deserializeValtticards = (valtticards) => {
  return {
    ...valtticards,
    valtticards: valtticards.valtticards.map(valtticard => deserializeValtticard(valtticard))
  }
}

export const deserializeUserNote = (userNote) => {
  const deserializedUserNote = {
    ...userNote
  }

  return deserializedUserNote
}

export const deserializeUserNotes = (userNotes) => {
  return {
    ...userNotes,
    userNotes: userNotes.userNotes.map(userNote => deserializeUserNote(userNote))
  }
}
