import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery, getValtticard, deleteValtticard, saveAttachment } from '../../service'
import Button from '../../components/Button'
import { useStateValue, setValtticards, setSortKeyValtticards, setSortOrderValtticards } from "../../state"
import { Grid, Accordion, AccordionDetails, AccordionSummary, Tooltip, CircularProgress, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import { Formik } from 'formik'
import ValtticardDialog from './ValtticardDialog'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { VALTTICARD_FORM_TEMPLATE } from '../../utils'
import DownloadOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import Dropzone from '../../components/Dropzone'
import { APIdomain } from '../../service'
import '../../styles/styles.css'

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '640px',

    '& > *': {
      marginBottom: theme.spacing(6),
      '& > p': {
        margin: 0,
        paddingBottom: theme.spacing(1),
      },
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  buttonContainer: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  thumbnail: {
    maxWidth: '100%',
  },
  divider: {
    margin: theme.spacing(8, 0, 7, 0),
  },
}))

let selectedColumns  = []

const INIT_FILTER = {
  userId: [],
  status: '',
  orderDate: '',
  validUntil: '',
  confirm: '',
  notes: '',
  createdAt: '',
  updatedAt: '',
  lastInvoice: '',
  columnsOptions: []
}

const Valtticards = ({ enqueueSnackbar, userId, landingScreen, forceFetchDataUserView, setForceFetchDataUserView }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ valtticards, currentUser, sortKeyValtticards, sortOrderValtticards }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [forceFetchData, setForceFetchData] = useState(true)
  const classes = useStyles()
  const [selectedRow, setSelectedRow] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })
  let isImage = false
  const [valtticardFile, setValtticardFile] = useState(null)
  if (valtticardFile && valtticardFile.filename) {
    //cLog('PÄÄTE', valtticardFile.filename.split('.')[1])
    isImage = ['jpg', 'jpeg', 'JPG', 'tif', 'tiff', 'png', 'bmp', 'gif',].includes(valtticardFile.filename.split('.')[1])
  }
  let allColumns = []
  if (userId) {
    allColumns = ['status', 'orderDate', 'validUntil', 'confirm', 'notes', 'createdAt', 'updatedAt', 'lastInvoice', 'actions']
    selectedColumns = allColumns
  } else {
    if (isAdmin) {
      allColumns = ['userId', 'status', 'orderDate', 'validUntil', 'confirm', 'notes', 'createdAt', 'updatedAt', 'lastInvoice', 'actions']
      if (landingScreen) {
        selectedColumns = allColumns
      } else {
        selectedColumns = JSON.parse(window.localStorage.getItem('valtticardsSelectedColumns')) || allColumns
      }
    } else {
      allColumns = ['status', 'orderDate']
      selectedColumns = allColumns
    }
  }
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('valtticard.'+option),
      value: option
    }
    if (['orderDate', 'validUntil', 'confirm'].includes(option)) {
      obj.date = true
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({ ...INIT_FILTER, columnsOptions: selectedColumns })

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      const attachmentUrl = row.attachments && row.attachments.length > 0 ? APIdomain + row.attachments[0].url : ''

      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getValtticard(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>

          {row.status !== 'POISTETTU' &&
            <>
              {attachmentUrl && <Tooltip title={t('taxcard.download')}>
                <DownloadOutlinedIcon
                  className="actionIcon"
                  onClick={() => {
                    window.open(attachmentUrl, '_blank', 'noopener,noreferrer')
                  }}
                />
              </Tooltip>}

              <Tooltip title={t('customers.remove')}>
                <DeleteOutlineIcon
                  className="actionIcon"
                  onClick={() => {
                    setSelectedRow(row)
                    setOpenConfirmDeleteModal(true)
                  }}
                />
              </Tooltip>
            </>
          }
        </span>
      )
    }

    const fetchData = async () => {
      let query = ''
      if (userId) {
        query += '&user_id=' + userId
      }
      if (landingScreen) {
        query += '&status=TARKASTETTAVANA'
      }
      const response = getQuery(pagination, setPagination, sortKeyValtticards, sortOrderValtticards, filter, selectedColumns, 'valtticards', query)
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          if (selectedColumns.includes('status')) {
            row.status = t('valtticard.'+row.status)
          }
          return row
        })
        dispatch(setValtticards(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setValtticards([]))
        setLoading(false)
      })
    }

    if (forceFetchData || forceFetchDataUserView) {
      setLoading(true)
      setForceFetchData(false)
      if (setForceFetchDataUserView) {
        setForceFetchDataUserView(false)
      }
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyValtticards, sortOrderValtticards, filter, pagination, t, enqueueSnackbar, userId, landingScreen, forceFetchDataUserView, setForceFetchDataUserView])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])

  const tableTitle = () => {
    if (landingScreen) {
      return `${t('frontPage.valtticards')}${pagination.totalRows > 0 ? ' - ' + pagination.totalRows + t('reference.new')  : ''}`
    } else if (userId) {
      return t('frontPage.valtticards')
    } else {
      const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ''
      return t('frontPage.valtticards') + (!userId ? coda : '')
    }
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteValtticard(selectedRow.id)
      cLog(`VALTTICARD ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('valtticard.valtticardDeleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE VALTTICARD ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('valtticard.problemsDeletingValtticard'),
        { variant: 'error' }
      )
    }
  }

  const handleSaveValtticard = async () => {
    cLog('HANDLE SAVE VALTTI CARD', valtticardFile)

    if (!valtticardFile) {
      enqueueSnackbar(
        t('taxcard.fileIsMissing'),
        { variant: 'error' }
      )
      return
    }

    sendValtticard()
  }

  const sendValtticard = async () => {
    try {
      setLoading(true)

      const newValttiCard = {
        recordType: 'Valtticard',
        recordId: currentUser.id,
        file: valtticardFile.file
      }

      cLog('SAVE ATTACHMENT', newValttiCard)
      await saveAttachment(newValttiCard)

      setLoading(false)
      enqueueSnackbar(
        t('valtticard.sent'),
        { variant: 'success' }
      )
      setValtticardFile(null)
      setForceFetchData(true)
    } catch (error) {
      setLoading(false)
      cLog('SEND VALTTI CARD ERROR', error)
      enqueueSnackbar(
        t('valtticard.problemsSending'),
        { variant: 'error' }
      )
    }
  }

  const statusOptions = ['TARKASTETTAVANA', 'TILATTU', 'HYLÄTTY', 'POISTETTU'].map(status => ({ label: t('valtticard.'+status), value: status }))

  return (
    <>
      {landingScreen &&
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>{tableTitle()}</h3>
          </AccordionSummary>
          <AccordionDetails>
            {valtticards.length > 0
            ? <Datatable
                data={valtticards}
                fetchData={() => setForceFetchData(true)}
                tableTitle={''}
                selectedColumns={selectedColumns}
                columnsOptions={columnsOptions}
                sortKey={sortKeyValtticards}
                setSortKey={setSortKeyValtticards}
                sortOrder={sortOrderValtticards}
                setSortOrder={setSortOrderValtticards}
                isAdmin={true}
              />
            : t('valtticard.noValtticards')
          }
          </AccordionDetails>
        </Accordion>
      }
      {!landingScreen &&
        <LayoutContainer noPadding={userId}>
          {!userId && <Header
            title={isAdmin ? t('frontPage.valtticards') : t('valtticard.loadValtticardTitle')}
          />}
          {!userId &&
            <>
              {!isAdmin &&
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6}>
                      <p><b>{t('valtticard.loadValtticardText1')}</b></p>
                      {t('valtticard.loadValtticardText2').split(';').map((row, i) => { return (<p key={i}>{row}</p>) })}
                      <div className={classes.rowContainer}>

                        <div>
                          <p>{t('taxcard.uploadText2')}</p>
                          <Dropzone
                            setFile={setValtticardFile}
                            style={{ width: '100%'/* , height: '100px'  */ }}
                            tip="taxcard"
                          />
                        </div>

                        {valtticardFile &&
                        <div>
                          <a target="_blank" href={valtticardFile.url} rel="noopener noreferrer">
                            {isImage
                              ? <img src={valtticardFile.url} className={classes.thumbnail} alt="VALTTIKORTTI"/>
                              : <h5>{valtticardFile.filename}</h5>
                            }
                          </a>
                        </div>}
                        <div className={classes.buttonContainer}>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isLoading}
                            onClick={handleSaveValtticard}
                          >
                            {isLoading ? <CircularProgress size={28} color='inherit' /> : t('valtticard.send')}
                          </Button>
                        </div>

                      </div>
                    </Grid>
                  </Grid>
                  <Divider variant="fullWidth" className={classes.divider} />
                </>
              }
              {isAdmin && <DatatableFilters
                inputs={[
                  { field: 'userId', label: t('invoice.billers'), type: 'userId' },
                  { field: 'status', label: t('valtticard.status'), type: 'select', options: statusOptions },
                  { field: 'orderDate', label: t('valtticard.orderDate'), type: 'date' },
                  { field: 'validUntil', label: t('valtticard.validUntil'), type: 'date' },
                  { field: 'confirm', label: t('valtticard.confirm'), type: 'date' },
                  { field: 'notes', label: t('valtticard.notes'), type: 'text' },
                  { field: 'createdAt', label: t('valtticard.createdAt'), type: 'date' },
                  { field: 'updatedAt', label: t('valtticard.updatedAt'), type: 'date' }
                ]}
                filter={filter}
                setFilter={setFilter}
                pagination={pagination}
                setPagination={setPagination}
                fetchData={() => setForceFetchData(true)}
                initFilter={INIT_FILTER}
                isLoading={isLoading}
              />}
            </>
          }
          <DatatablePagination
            pagination={pagination}
            setPagination={setPagination}
            fetchData={() => setForceFetchData(true)}
            isLoading={isLoading}
            selectedColumns={selectedColumns}
            columnsOptions={columnsOptions}
            filter={filter}
            setFilter={setFilter}
            localStorage={'valtticardsSelectedColumns'}
            sortKey={sortKeyValtticards}
            setSortKey={setSortKeyValtticards}
            sortOrder={sortOrderValtticards}
            setSortOrder={setSortOrderValtticards}
            isAdmin={isAdmin}
            allowSelectColumns={userId || !isAdmin ? false : true}
          />
          <Datatable
            data={valtticards}
            fetchData={() => setForceFetchData(true)}
            tableTitle={tableTitle()}
            selectedColumns={selectedColumns}
            columnsOptions={columnsOptions}
            sortKey={sortKeyValtticards}
            setSortKey={setSortKeyValtticards}
            sortOrder={sortOrderValtticards}
            setSortOrder={setSortOrderValtticards}
            isAdmin={isAdmin}
          />
        </LayoutContainer>
      }
      <Formik
        enableReinitialize
        initialValues={VALTTICARD_FORM_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <ValtticardDialog fetchData={() => setForceFetchData(true)} edit={selectedRow} setEdit={setSelectedRow} open={openModal} setOpen={setOpenModal} {...formikProps} />}
      </Formik>
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('valtticard.confirmDeletionTitle'), body: t('valtticard.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
    </>
  )
}

export default withSnackbar(Valtticards)
