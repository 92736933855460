import React from 'react'
import { Field, FieldArray } from 'formik'
import { checkMaterialRowErrors, checkMaterialRowSendErrors, validateTextArea, validateFloat, validateFloatGreaterThan, validateDate } from './validationSchemas'
import Input from '../../components/Input'
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery, FormControlLabel } from '@material-ui/core'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
import InvoiceDropzone from '../../components/InvoiceDropzone'
import { APIdomain, deleteAttachment } from '../../service'
import { useTheme } from '@material-ui/core/styles'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import DropDown from '../../components/DropDown'
import { cleanFloat } from '../../utils/invoiceUtils'
import { roundNumber } from '../../utils/index'
import OdealCheckbox from '../../components/OdealCheckbox'
import IconButton from "../../components/IconButton"
import DeleteIcon from '@material-ui/icons/Delete'
import { withSnackbar } from 'notistack'

const MaterialExpenceRow = ({
  materialExpenceRows,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow,
  isAdmin,
  validateSendExpense,
  oldInvoiceTravelType1,
  oldInvoiceTravelType2,
  vat24,
  exp,
  enqueueSnackbar
}) => {
  //cLog('KULURIVIT', materialExpenceRows)
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isFocused, setIsFocused] = React.useState({})

  let allVats = ['25,5', '24', '14', '10', '0']
  if (!exp) {
    if (vat24) {
      allVats = ['24', '14', '10', '0']
    } else {
      allVats = ['25,5', '14', '10', '0']
    }
  }
  const expenceVatOptions = allVats.map(vat => {
      return ({ label: vat + ' %', value: vat })
    })

  const vatOptions =  ['0 %', '10 %', '100 %', '14 %', '24 %', 'EI ALV:tä', '10 % käänteinen', '14 % käänteinen', '24 % käänteinen', 'EU Palvelut 0 %', 'EU Palvelut 10 %', 'EU Palvelut 14 %', 'EU Palvelut 24 %'].map(option => ({ label: option, value: option }))

  const accountOptions =  ['4000 Ostot', '4001 Laskuttajien toimitilakulut', '4002 Laskuttajien ajoneuvokulut', '4003 Laskuttajien muut kulut', '4004 Laskuttajien matkakulut', '4005 Laskuttajien työpalvelut', '4090 Tavaraostot Ahvenanmaalta', '4110 Yhteisöhankinnat', '4115 EU palveluostot', '4130 Tuontiostot yhteisön ulkopuolelta'].map(option => ({ label: option, value: option }))

  const reactToPrice = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const price = cleanFloat(changedValue)
      const vat = cleanFloat(row.expenceVat)
      const tax = roundNumber(price * (vat / 100), 2)
      setFieldValue(`materialExpenceRows.${i}.priceWithTax`, roundNumber(price + tax, 2))
      setFieldValue(`materialExpenceRows.${i}.tax`, tax)
    } else {
      setFieldValue(`materialExpenceRows.${i}.priceWithTax`, '0.00')
      setFieldValue(`materialExpenceRows.${i}.tax`, '0.00')
    }
  }
  const reactToPriceWithALV = (row, i, changedValue) => {
    if (changedValue && cleanFloat(changedValue)) {
      const priceWithTax = cleanFloat(changedValue)
      const vat = cleanFloat(row.expenceVat)
      const price = roundNumber(priceWithTax / (1 + vat / 100), 2)
      setFieldValue(`materialExpenceRows.${i}.price`, price)
      setFieldValue(`materialExpenceRows.${i}.tax`, roundNumber(priceWithTax - price, 2))
    } else {
      setFieldValue(`materialExpenceRows.${i}.price`, '0.00')
      setFieldValue(`materialExpenceRows.${i}.tax`, '0.00')
    }
  }
  const reactToAlv = (row, i, changedValue) => {
    const price = cleanFloat(row.price)
    const vat = cleanFloat(changedValue)
    const priceWithTax = roundNumber(price * (1 + vat / 100), 2)
    setFieldValue(`materialExpenceRows.${i}.priceWithTax`, priceWithTax)
    setFieldValue(`materialExpenceRows.${i}.tax`, roundNumber(priceWithTax - price, 2))
  }

  return (
    <FieldArray
      name="materialExpenceRows"
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        return (
          <>
            {materialExpenceRows.map((row, i) => {
              const handleDeleteAttachment = async (attachment, x) => {
                if (attachment.id) {
                  try {
                    const response = await deleteAttachment(attachment.id)
                    cLog('ATTACHMENT DELETED', response)
                    setFieldValue(`materialExpenceRows.${i}.attachments`, materialExpenceRows[i].attachments.filter((att, a) => a !== x))
                  } catch (error) {
                    cLog('DELETE ATTACHMENT ERROR', error.response.data.errors)
                    enqueueSnackbar(
                      t('invoice.attachmentDeleteError'),
                      { variant: 'error' }
                    )
                  }
                } else {
                  setFieldValue(`materialExpenceRows.${i}.attachments`, materialExpenceRows[i].attachments.filter((att, a) => a !== x))
                }
              }

              const purposeOptions = [{ label: t('invoice.purposeExpense'), value: 'EXPENSE' },{ label: t('invoice.purposeWork'), value: 'TRAVEL' }, { label: t('invoice.purposeOther'), value: 'OTHER' }]

              const errors = arrayHelpers.form.errors.materialExpenceRows ? arrayHelpers.form.errors.materialExpenceRows[i] : {}
              const materialRowHasErrors = errors ? Object.keys(errors).length > 0 : false

              //cLog('MATERIAL EXPENCES ROW ERRORS', errors)

              return (
                <div id={`mRow${i}`} key={i}>
                  <Hanuri>
                    <HanuriTitle
                      expanded={expanded === `materialRows.${i}`}
                      id={`materialRows.${i}`}
                      open={handleChangeExpansion(`materialRows.${i}`)}
                      remove={() => {
                        removeRow('materialRows', i)
                        arrayHelpers.remove(i)
                      }}
                      label={t('invoice.expenceRow')+' '+(i+1)}
                      done={validateSendExpense ? checkMaterialRowSendErrors(row, isAdmin, oldInvoiceTravelType1) : checkMaterialRowErrors(row, isAdmin, oldInvoiceTravelType1)}
                      error={materialRowHasErrors && (validateSendExpense ? !checkMaterialRowSendErrors(row, isAdmin, oldInvoiceTravelType1) : !checkMaterialRowErrors(row, isAdmin, oldInvoiceTravelType1))}
                    />
                    <HanuriBody expanded={expanded === `materialRows.${i}`}>
                      <Grid container spacing={1}>
                        {!oldInvoiceTravelType2 && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.purpose`}
                            id={`materialExpenceRows.${i}.purpose`}
                            label={t('invoice.purposeExpenseTitle')}
                            type='text'
                            options={purposeOptions}
                            isSearchable={!isMobile}
                            defaultValue={purposeOptions ? purposeOptions[0] : ''}
                            component={DropDown}
                            placeholder={t('invoice.pick')}
                            onChange={(e) => {
                              setFieldValue(`materialExpenceRows.${i}.purpose`, e.value)
                            }}
                          />
                        </Grid>}
                        <Grid item xs={12} sm={8}>
                        </Grid>
                        <Grid item xs={12} sm={oldInvoiceTravelType1 || isAdmin ? 8 : 12}>
                          <Field
                            name={`materialExpenceRows.${i}.explanation`}
                            id={`materialExpenceRows.${i}.explanation`}
                            type="text"
                            component={Input}
                            value={materialExpenceRows[i].explanation || ''}
                            onChange={(e) => {
                              setFieldValue(`materialExpenceRows.${i}.explanation`, e.target.value)
                            }}
                            label={t('invoice.explanation')+' *'}
                            validate={() => validateTextArea(row.explanation, t('invoice.explanation'), 460, true)}
                            error={errors && errors.explanation && validateTextArea(row.explanation, t('invoice.explanation'), 460, true)}
                          />
                        </Grid>
                        {oldInvoiceTravelType1 && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.sum`}
                            id={`materialExpenceRows.${i}.sum`}
                            type="text"
                            component={Input}
                            value={materialExpenceRows[i].sum || ''}
                            onChange={(e) => { setFieldValue(`materialExpenceRows.${i}.sum`, e.target.value) }}
                            label={t('invoice.sumWithVat')+' *'}
                            validate={() => !isAdmin ? validateFloatGreaterThan(row.sum, 0, 9999999.99, t('invoice.sumWithVat'), 2) : validateFloat(row.sum, t('invoice.sumWithVat'), false)}
                            error={errors && errors.sum && (!isAdmin ? validateFloatGreaterThan(row.sum, 0, 9999999.99, t('invoice.sumWithVat'), 2) : validateFloat(row.sum, t('invoice.sumWithVat'), false))}
                          />
                        </Grid>}
                        {!oldInvoiceTravelType1 && isAdmin && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.expenceVat`}
                            id={`materialExpenceRows.${i}.expenceVat`}
                            label={t('invoice.vat')}
                            options={expenceVatOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            defaultValue={expenceVatOptions[0]}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], expenceVat: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`materialExpenceRows.${i}.expenceVat`, e.value)
                              reactToAlv(row, i, e.value)
                            }}
                          />
                        </Grid>}
                        {!oldInvoiceTravelType1 && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.price`}
                            id={`materialExpenceRows.${i}.price`}
                            label={t('invoice.price') + ' *'}
                            info={t('invoice.priceNoVatInfoExpense')}
                            type='text'
                            component={Input}
                            value={row.price || ''}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], price: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`materialExpenceRows.${i}.price`, e.target.value)
                              if (isFocused[i].price) {
                                reactToPrice(row, i, e.target.value)
                              }
                            }}
                            validate={() => (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false))}
                            error={errors && errors.price && (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false))}
                          />
                        </Grid>}
                        {!oldInvoiceTravelType1 && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.priceWithTax`}
                            id={`materialExpenceRows.${i}.priceWithTax`}
                            label={t('invoice.priceWithTax')}
                            info={t('invoice.priceWithTaxInfoExpense')}
                            type='text'
                            component={Input}
                            value={row.priceWithTax || ''}
                            onFocus={() => {
                              const rowFocus = { ...isFocused[i], priceWithTax: true }
                              setIsFocused({ ...isFocused, [i]: rowFocus })
                            }}
                            onChange={(e) => {
                              setFieldValue(`materialExpenceRows.${i}.priceWithTax`, e.target.value)
                              if (isFocused[i].priceWithTax) {
                                reactToPriceWithALV(row, i, e.target.value)
                              }
                            }}
                            validate={() => (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false))}
                            error={errors && errors.priceWithTax && (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false))}
                          />
                        </Grid>}
                        {!oldInvoiceTravelType1 && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.tax`}
                            id={`materialExpenceRows.${i}.tax`}
                            label={t('invoice.taxInput')}
                            type='text'
                            component={Input}
                            value={row.tax || ''}
                            disabled={true}
                          />
                        </Grid>}
                        <Grid item xs={12} sm={8}>
                          <Field
                            name={`materialExpenceRows.${i}.buyLocation`}
                            id={`materialExpenceRows.${i}.buyLocation`}
                            type="text"
                            component={Input}
                            value={materialExpenceRows[i].buyLocation || ''}
                            onChange={(e) => {
                              setFieldValue(`materialExpenceRows.${i}.buyLocation`, e.target.value)
                            }}
                            label={t('invoice.buyLocation')}
                            validate={() => validateTextArea(row.buyLocation, t('invoice.buyLocation'), 50, false)}
                            error={errors && errors.buyLocation && validateTextArea(row.buyLocation, t('invoice.buyLocation'), 50, false)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name="buyDate"
                            id="buyDate"
                            label={t('invoice.buyDate')}
                            component={DPicker}
                            selected={materialExpenceRows[i].buyDate ? new Date(materialExpenceRows[i].buyDate) : ''}
                            onChange={(date) => setFieldValue(`materialExpenceRows.${i}.buyDate`, date ? formatDate(date) : '')}
                            validate={() => validateDate(row.buyDate, t('invoice.buyDate'), false)}
                            error={errors && errors.buyDate && validateDate(row.buyDate, t('invoice.buyDate'), false)}
                          />
                        </Grid>
                        {exp && isAdmin && <Grid item xs={12} sm={8}>
                          <Field
                            name={`materialExpenceRows.${i}.account`}
                            id={`materialExpenceRows.${i}.account`}
                            label={t('invoice.account')+' *'}
                            options={accountOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            placeholder={t('invoice.pick')}
                            onChange={(e) => setFieldValue(`materialExpenceRows.${i}.account`, e.value) }
                            validate={() => (validateSendExpense && !materialExpenceRows[i].account && { key: t('invoice.accountMissing') })}
                            error={errors && validateSendExpense && !materialExpenceRows[i].account}
                          />
                        </Grid>}
                        {exp && isAdmin && <Grid item xs={12} sm={4}>
                          <Field
                            name={`materialExpenceRows.${i}.vat`}
                            id={`materialExpenceRows.${i}.vat`}
                            label={t('invoice.accountingVat')+' *'}
                            options={vatOptions}
                            component={DropDown}
                            isSearchable={!isMobile}
                            placeholder={t('invoice.pick')}
                            onChange={(e) => setFieldValue(`materialExpenceRows.${i}.vat`, e.value) }
                            validate={() => (validateSendExpense && !materialExpenceRows[i].vat && { key: t('invoice.vatMissing') })}
                            error={errors && validateSendExpense && !materialExpenceRows[i].vat}
                          />
                        </Grid>}
                        <Grid item xs={12} sm={12}>
                          <InvoiceDropzone
                            iconStyles={{ width: '24px', height: '24px', marginRight: '0.5rem' }}
                            exp
                            field={`materialExpenceRows.${i}.attachments`}
                            values={materialExpenceRows[i]}
                            setFieldValue={setFieldValue}
                            variant="contained"
                            color="grey"
                          />
                        </Grid>
                        {materialExpenceRows[i].attachments && materialExpenceRows[i].attachments.length > 0 && <Grid item xs={12} sm={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {isAdmin && <TableCell>{t('invoice.id')}</TableCell>}
                                  <TableCell>{t('invoice.attachment')}</TableCell>
                                  <TableCell>{t('invoice.sendToClient')}</TableCell>
                                  <TableCell>{t('invoice.remove')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {materialExpenceRows[i].attachments.map((attachment, x) => {
                                  const attachmentUrl = attachment.id ? APIdomain + attachment.url : attachment.url
                                  //cLog('ATTACHMENT URL', attachmentUrl)
                                  return (
                                    <TableRow key={x}>
                                      {isAdmin && <TableCell>{attachment.attachmentDetailId}</TableCell>}
                                      <TableCell>{!isMobile || (isMobile && attachment.id)
                                        ? <a href={attachmentUrl} target="_blank" rel="noopener noreferrer" style={{ marginRight: '20px' }}>{attachment.filename}</a>
                                        : attachment.filename}</TableCell>
                                      <TableCell>
                                        <FormControlLabel
                                          control={<OdealCheckbox
                                            checked={attachment.sendToClient}
                                            onChange={() => {
                                              setFieldValue(`materialExpenceRows.${i}.attachments.${x}.sendToClient`, !materialExpenceRows[i].attachments[x].sendToClient)
                                            }}
                                            name="hasTravelRows" />}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <IconButton onClick={() => handleDeleteAttachment(attachment, i)}>
                                          <DeleteIcon/>
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                        </Grid>}
                      </Grid>
                    </HanuriBody>
                  </Hanuri>
                </div>)
            })}
          </>
        )
      }}
    />
  )
}

export default withSnackbar(MaterialExpenceRow)
