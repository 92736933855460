import React, { useState, useEffect } from 'react'
import { useStateValue, setClients, removeClient } from "../../state"
import { getInvoices, saveInvoice, deleteClient, updateInvoice, getClients, saveExpence, updateExpence, getUser, saveAttachment, getClient, getCurrencyRates } from '../../service'
import { useHistory } from "react-router-dom"
import { Field } from 'formik'
import { invoiceCalculations, INVOICE_ROW_TEMPLATE, TRAVELLING_EXPENCES_ROW_TEMPLATE, TRAVELLING_EXPENCES_ROW_GOOGLE_MAPS_TEMPLATE, MATERIAL_EXPENCES_ROW_TEMPLATE, INDUSTRIES, VATSTATUSES, INVOICE_PAYMENT_TEMPLATE, INVOICE_CORRECTION_ROW_TEMPLATE, INVOICE_ACCOUNT_TEMPLATE, cleanFloat, vat24ChangeDate, formatFloat } from '../../utils/invoiceUtils'
import { checkInvoiceBodyErrors, validateDateDue, validateDateStartWork, validateDateEndWork, validateInvoiceDate, validateTextArea, validateCustomIndustry, validateFloatGreaterThan, validateReminderHandling, validateAll, validateAll2, validateDate } from './validationSchemas'
import { checkClient } from '../Clients/clientValidations'
import { Box } from "@material-ui/core"
import InvoiceView from './InvoiceView'
import InvoiceRow from './InvoiceRow'
import AccountRow from './AccountRow'
import TravellingExpenceRow from './TravellingExpenceRow'
import MaterialExpenceRow from './MaterialExpenceRow'
import PaymentRow from './PaymentRow'
import CorrectionRow from './CorrectionRow'
import Input from '../../components/Input'
import TextArea from '../../components/TextArea'
import SelectAsync from '../../components/SelectAsync'
import DropDown from '../../components/DropDown'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid, FormControlLabel, useMediaQuery, RadioGroup } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import ButtonRow from './ButtonRow'
import BottomNavigation from './BottomNavigation'
import Alert from '../../components/Alert'
import OdealCheckbox from '../../components/OdealCheckbox'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
import InfoBar from '../../components/InfoBar'
import Tip from '../../components/Tip'
import { useTranslation } from 'react-i18next'
import OdealRadio from '../../components/OdealRadio'
import LabelWithInfo from '../../components/LabelWithInfo'
import DPicker from '../../components/DPicker'
import IconButton from "../../components/IconButton"
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { formatDate, roundNumber, cLog, scrollTo, notifyOffice } from '../../utils/index'
import ConfirmTravelExpensesDialog from '../../components/ConfirmTravelExpensesDialog'
import { useJsApiLoader } from "@react-google-maps/api"
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import RouterPrompt from '../../components/RouterPrompt'

const useStyles = makeStyles(() => ({
  root: {
  },
  rootSm: {
    paddingBottom: '56px', // For BottomNavigation
  },
}))

const payDayLabels = {
  'HETI': 'HETI',
  'PIKAMAKSU': 'PIKAMAKSU',
  'KUN_MAKSETTU': 'KUN MAKSETTU',
  'NOSTETAAN_MYOHEMMIN': 'NOSTETAAN MYOHEMMIN'
}
const payDayLabelsNoHeti = {
  'PIKAMAKSU': 'PIKAMAKSU',
  'KUN_MAKSETTU': 'KUN MAKSETTU',
  'NOSTETAAN_MYOHEMMIN': 'NOSTETAAN MYOHEMMIN'
}

const InvoiceForm = React.forwardRef(
  (props, ref) => {
    const {
      exp,
      edit,
      view,
      recycle,
      create,
      worklist,
      setFieldValue,
      values,
      setEditClientForm,
      clientRef,
      resetForm,
      dirty,
      errors,
      setFieldError,
      validateForm,
      touched,
      setFieldTouched,
      setOpenClientModal,
      enqueueSnackbar,
      oldInvoice,
      setOldInvoice,
      userData,
      setUserData,
      placesLibrary,
      initValidate,
      setInitValidate
    } = props

    const { t } = useTranslation()
    const [expanded, setExpanded] = useState('invoiceBody')
    const [showInfoTextArea, setShowInfoTextArea] = useState(values.info)
    const [showMobileSummary, setShowMobileSummary] = useState(false)
    const [numberOfChanges, setNumberOfChanges] = useState(0)
    const [openConfirmDeleteClientModal, setOpenConfirmDeleteClientModal] = useState(false)
    const [isLoading, setLoading] = useState(false)
    let history = useHistory()
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [{ clients, currentUser }, dispatch] = useStateValue()
    const isAdmin = currentUser && currentUser.role === 'ADMIN'
    const invoiceBodyHasErrors = (errors && Object.keys(errors).length > 0) || false
    const isNewInvoice = !values.id || values.status === 'LUONNOS'
    const userOptions = userData ? [{ label: `${userData.firstName} ${userData.lastName} (${userData.email})`.trim(), value: userData.id }] : []
    const [validateSendExpense, setValidateSendExpense] = useState(false)
    const [oldStatus, setOldStatus] = useState(values.status)
    const [openConfirmTravelExpensesDialog, setOpenConfirmTravelExpensesDialog] = useState(false)
    const [panelInitValidate, setPanelInitValidate] = useState(false)
    const [showKmInfoDialog, setShowKmInfoDialog] = useState(false)

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      libraries: placesLibrary,
      language: 'fi'
    })

    const { invoiceRowsTotal, travellingExpenceRowsTotal, materialExpenceRowsTotal, origTotalVatExcludedEur, highestTaxType, hasNonZeroVats, totalCorrectedEur, totalCorrectedVatEur, totalCorrectedWithVatEur, salariesPayableAmount, tax, totalWithTax, totalAccounting, totalCorrections, totalCorrectionsVat, totalCorrectionsWithVat, origTotalVatExcluded, taxEur, totalWithTaxEur, openBalance } = invoiceCalculations(values)
    const [hTaxType, setHTaxType] = useState(highestTaxType)
    cLog('INVOICE FORM VALUES', values)
    //cLog('TAX TYPES', highestTaxType)

    let payDayOptions = []
    if (userData && ['LUONNOS', 'TARKASTETTAVANA'].includes(values.status) && userData.noInstantSalary) {
      payDayOptions = Object.keys(payDayLabelsNoHeti).map(option => ({ label: t('invoice.'+payDayLabelsNoHeti[option]), value: option }))
    } else {
      payDayOptions = Object.keys(payDayLabels).map(option => ({ label: t('invoice.'+payDayLabels[option]), value: option }))
    }

    const checkTravelRowsVats = () => {
      if (values.travellingExpenceRows && values.travellingExpenceRows.length > 0) {
        values.travellingExpenceRows.forEach((row, i) => {
          setFieldValue(`travellingExpenceRows.${i}.vat`, formatFloat(highestTaxType))
        })
      }
    }

    const count = () => {
      //cLog('COUNTING------------------------------------------------')
      if (!exp) {
        setFieldValue('invoiceTotal', invoiceRowsTotal)
        setFieldValue('travelTotal', travellingExpenceRowsTotal)
        setFieldValue('tax', tax)
        setFieldValue('totalWithTax', totalWithTax)
        setFieldValue('totalAccounting', totalAccounting)
        setFieldValue('origTotalVatExcluded', origTotalVatExcluded)
        setFieldValue('salariesPayableAmount', salariesPayableAmount)
        setFieldValue('totalCorrections', totalCorrections)
        setFieldValue('totalCorrectionsVat', totalCorrectionsVat)
        setFieldValue('totalCorrectionsWithVat', totalCorrectionsWithVat)
        setFieldValue('origTotalVatExcludedEur', origTotalVatExcludedEur)
        setFieldValue('taxEur', taxEur)
        setFieldValue('totalWithTaxEur', totalWithTaxEur)
        setFieldValue('totalCorrectedEur', totalCorrectedEur)
        setFieldValue('totalCorrectedVatEur', totalCorrectedVatEur)
        setFieldValue('totalCorrectedWithVatEur', totalCorrectedWithVatEur)
        setFieldValue('expenceTotal', materialExpenceRowsTotal)
      } else {
        setFieldValue('expenceTotal', materialExpenceRowsTotal)
        setFieldValue('travelTotal', travellingExpenceRowsTotal)
        setFieldValue('totalWithTax', totalWithTax)
        setFieldValue('totalAccounting', totalAccounting)
        setFieldValue('salariesPayableAmount', salariesPayableAmount)
      }

      //cLog('MATERIAL EXPENCE ROWS TOTAL', materialExpenceRowsTotal)

      if (!values.hasCustomTravelVats) {
        checkTravelRowsVats()
      }
    }

    useEffect(() => {
      if (!isAdmin && hTaxType !== highestTaxType && values.materialExpenceRows && values.materialExpenceRows.length > 0) {
        values.materialExpenceRows.forEach((row, i) => {
          setFieldValue(`materialExpenceRows.${i}.expenceVat`, formatFloat(highestTaxType))
          const price = cleanFloat(row.price)
          const priceWithTax = roundNumber(price * (1 + highestTaxType / 100), 2)
          setFieldValue(`materialExpenceRows.${i}.priceWithTax`, priceWithTax)
          setFieldValue(`materialExpenceRows.${i}.tax`, roundNumber(priceWithTax - price, 2))
        })

        setHTaxType(highestTaxType)
      }
    }, [isAdmin, values])

    useEffect(() => {
      setNumberOfChanges(prevNumberOfChanges => prevNumberOfChanges + 1)
    }, [values])

    useEffect(() => {
      if (dirty) {
        count()
      }
    }, [values.invoiceRows, values.travellingExpenceRows, values.materialExpenceRows, values.correctionRows, values.accounts])

    const handleChangeExpansion = (panel) => async (event, isExpanded) => {
      cLog('PANEELI', panel)
      cLog('EXPANDED', expanded)
      let val = validate()
      val.then(result => {
        if (result) {
          if (isAdmin || result) {
            //cLog('AVATAAN PANEELIA', panel)
            //cLog('ERRORIT', errors)
            if (panel === expanded) {
              setExpanded('')
              return
            } else {
              setExpanded(panel)
              scrollTo(panel)
              if (values.id || worklist) {
                setPanelInitValidate(true)
              }
            }
          }
        }
      }, error => {
        cLog('CHANGE EXPANSION ERROR', error)
      })
    }

    const fetchClientsByUserId = async (id) => {
      try {
        const usersClients = await getClients('?user_id=' + id + '&rows=100')
        let allUsersClients = usersClients.clients
        if (usersClients.totalPages > 1) {
          for (let i = 0; i < usersClients.totalPages; i++) {
            const someClients = await getClients('?user_id=' + id + '&rows=100&page=' + i)
            allUsersClients = allUsersClients.concat(someClients.clients)
          }
        }
        dispatch(setClients(allUsersClients))
        //const allUsersClients = await getClientNames('?user_id=' + id)
        cLog('ALL USERS CLIENTS', allUsersClients)
      } catch (error) {
        cLog('ONGELMIA KÄYTTÄJÄN ASIAKKAIDEN HAKEMISESSA', error)
      }
    }

    const clientOptions = React.useMemo(() => {
      //cLog('CLIENT OPTIONEISTA CLIENTS', clients && clients.length > 0)
      let options = clients && clients.length > 0
        ? clients
          .map(client => {
            const label = client.name || ''
            return ({ label, value: client.id })
          })
        : []
      options.sort((a, b) => {
        var nameA = a.label.toUpperCase();
        var nameB = b.label.toUpperCase();
        return nameA < nameB
          ? -1
          : nameA > nameB
            ? 1
            : 0
      })
      return options//.concat({ label: t('invoice.createNewCustomer'), value: 'addNewClient' })
    }, [clients])
    //cLog('CLIENT OPTIONS', clientOptions)
    const currencyOptions = ['EUR', 'USD', 'SEK', 'GBP', 'NOK'].map(currency => ({ label: currency, value: currency }))
    const industryOptions =  Array.from(INDUSTRIES).map(([key]) => ({ label: t('user.'+key), value: key }))
    let statuses = !isAdmin ? [] : ['POISTETTU']
    if (!exp) {
      statuses.push('LUONNOS', 'TARKASTETTAVANA', 'LÄHETETTY', /*"MAKSETTU_LIIKAA", */'MAKSETTU', 'OSITTAIN_MAKSETTU', /*"PERINNÄSSÄ", */"LUOTTOTAPPIO", /*"ULOSOTOSSA", "MUISTUTETTU", */"HYVITETTY", "HYVITYSLASKU")
    } else {
      statuses.push('HYLÄTTY', 'LUONNOS', 'VALMIS', 'OSITTAIN_MAKSETTU', 'MAKSETTU')
    }

    const statusOptions = statuses.map(status => ({ label: t('invoice.'+status), value: status }))

    const deliveryOptions = ['POSTI', 'SÄHKÖPOSTI', 'VERKKOLASKU', 'EI_LÄHETETÄ'].map(option => ({ label: t('invoice.'+option.replace(/_/g, ' ')), value: option, isDisabled: (!isAdmin && option === 'POSTI' && !values.isPrivate) || (!isAdmin && option === 'EI_LÄHETETÄ') }))
    const vatStatusOptions = VATSTATUSES.map(status => ({ label: t('invoice.'+status.replace(/_/g, ' '), { keySeparator: '.', nsSeparator: false }), value: status }))
    const reminderOptions = [
      { label: t('invoice.AUTOMAATTINEN'), value: 'AUTOMAATTINEN' },
      { label: t('invoice.EI MUISTUTETA'), value: 'EI_MUISTUTETA', isDisabled: values.payDay === 'HETI' }
    ]

    const flattenErrors = (result, item) => {
      if (Array.isArray(item)) {
        for (let i = 0; i < item.length; i++) {
          flattenErrors(result, item[i]);
        }
      } else if (typeof item === 'object' && item !== null) {
        for (let key in item) {
          if (item.hasOwnProperty(key)) {
            flattenErrors(result, item[key]);
          }
        }
      } else {
        result.push(item);
      }

      return result
    }

    const notifyErrors = (erroris) => {
      const errors = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])))))
      const errorTypes = flattenErrors([], errors)

      cLog('ERRORI TYYPIT', errorTypes)
      errorTypes.reverse()
      errorTypes.filter((v,i,a)=>a.indexOf(v)===i)
        .filter(type => type)
        .forEach(type => {
          cLog('INVOICE FORM ERROR!!!', type)
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('invoice.deficientRows'),
          { variant: 'error' }
        )
      }
    }

    var validate = async () => {
      if (values.userId && !exp) {
        if (!values.client || !values.client.id) {
          /*enqueueSnackbar(
            t('invoice.chooseAnotherCustomer'),
            { variant: 'error' }
          )*/
        } else if (!checkClient(values.client, isAdmin)) {
          enqueueSnackbar(
            t('invoice.customerInfoMissing'),
            { variant: 'error' }
          )
        }
      }

      const erroris = await validateForm()
      cLog('ERRORIS', erroris)
      if (Object.keys(erroris).length === 0) {
        return true
      } else {
        //cLog('ERROREITA PIISAA', erroris)
        notifyErrors(erroris)
        return false
      }
    }

    const validateAllFields = (sendExpense = false) => {
      if (!validateAll(values, exp, isAdmin, oldStatus, values.oldInvoiceTravelType1, values.oldInvoiceTravelType2, sendExpense, values.oldInvoiceGoogleMaps)) {
        if ((worklist || values.worklistId) && values.invoiceRows.length > 0 && !values.invoiceRows[0].total) {
          setFieldError('invoiceRows.0', { key: t('invoice.missingPrice') })
          enqueueSnackbar(
            t('invoice.missingPrice'),
            { variant: 'error' }
          )
        }
        enqueueSnackbar(
          t('invoice.deficientRows'),
          { variant: 'error' }
        )
        return false
      }

      const generalErrorMessages = validateAll2(values, exp, isAdmin, worklist, hasNonZeroVats, sendExpense)
      if (generalErrorMessages.length > 0) {
        for (const messsage of generalErrorMessages) {
          enqueueSnackbar(
            messsage,
            { variant: 'error' }
          )
        }
        return false
      }
      return true
    }

    const withDestroyedRows = () => {
      const { invoiceRows, travellingExpenceRows, materialExpenceRows, payments, correctionRows, accounts } = oldInvoice
      const invoiceRowIds = (values.invoiceRows && values.invoiceRows.map(row => row.id)) || []
      const deletedInvoiceRows = (invoiceRows && invoiceRows
        .filter(row => !invoiceRowIds.includes(row.id))
        .map(row => ({ ...row, _destroy: true }))) || []
      let travelRowIds = null
      let deletedTravelRows = null
      let travelRows = null
      if (values.oldInvoiceGoogleMaps) {
        travelRowIds = values.travellingExpenceRows.map(row => row.id)
        deletedTravelRows = travellingExpenceRows
          .filter(row => !travelRowIds.includes(row.id))
          .map(row => ({ ...row, _destroy: true }))
      } else {
        travelRowIds = values.travellingExpenceRows.map(row => row.id)
        deletedTravelRows = travellingExpenceRows
          .filter(row => !travelRowIds.includes(row.id))
          .map(row => ({ ...row, _destroy: true }))
        travelRows = values.travellingExpenceRows
        for (const row of travelRows) {
          if (row.id) {
            const oldRow = travellingExpenceRows
              .filter(oRow => oRow.id === row.id)[0]
            const destinationIds = row.travelRowDestinations.map(destination => destination.id)
            const deletedDestinations = oldRow.travelRowDestinations
              .filter(destination => !destinationIds.includes(destination.id))
              .map(destination => ({ ...destination, _destroy: true }))
            row.travelRowDestinations = [...row.travelRowDestinations.concat(deletedDestinations)]
          }
        }
      }
      const expenceRowIds = values.materialExpenceRows.map(row => row.id)
      const deletedExpenceRows = materialExpenceRows
        .filter(row => !expenceRowIds.includes(row.id))
        .map(row => ({ ...row, _destroy: true }))
      const paymentRowIds = (values.payments && values.payments.map(row => row.id)) || []
      const deletedPaymentRows = values.payments && payments
        .filter(row => !paymentRowIds.includes(row.id))
        .map(row => ({ ...row, _destroy: true }))
      const correctionRowIds = (values.correctionRows && values.correctionRows.map(row => row.id)) || []
      const deletedCorrectionRows = values.correctionRows && correctionRows
      .filter(row => !correctionRowIds.includes(row.id))
      .map(row => ({ ...row, _destroy: true }))
      const accountRowIds = (values.accounts && values.accounts.map(row => row.id)) || []
      const deletedAccountRows = values.accounts && accounts
      .filter(row => !accountRowIds.includes(row.id))
      .map(row => ({ ...row, _destroy: true }))
      return {
        ...values,
        invoiceRows: values.invoiceRows ? [...values.invoiceRows.concat(deletedInvoiceRows)] : [],
        travellingExpenceRows: values.oldInvoiceGoogleMaps ? [...values.travellingExpenceRows.concat(deletedTravelRows)] : [...travelRows.concat(deletedTravelRows)],
        materialExpenceRows: [...values.materialExpenceRows.concat(deletedExpenceRows)],
        payments: values.payments ? [...values.payments.concat(deletedPaymentRows)] : [],
        correctionRows: values.correctionRows ? [...values.correctionRows.concat(deletedCorrectionRows)] : [],
        accounts: values.accounts ? [...values.accounts.concat(deletedAccountRows)] : []
      }
    }

    const saveAttachments = async (invoice) => {
      //cLog("SAVING INVOICE BODY ATTACHMENTS", values.attachments)
      for (const attachment of values.attachments) {
        //cLog('ATTACHMENT', attachment)
        if (!attachment.id) {
          try {
            const response = await saveAttachment({
              recordType: !exp ? 'Invoice' : 'Expence',
              recordId: invoice.id,
              file: attachment.file
            })
            cLog('ATTACHMENT SAVED', response)
            invoice.attachments = response.data.attachments
          } catch (error) {
            cLog('SAVE BODY ATTACHMENT ERROR', error)
            enqueueSnackbar(
              t('invoice.attachmentInvalid'),
              { variant: 'error' }
            )
            notifyOffice(isAdmin, enqueueSnackbar, error, false)
          }
        }
      }

      //cLog("SAVING MATERIAL EXPENCE ROW ATTACHMENTS", values.materialExpenceRows)
      for (let i1 = 0; i1 < values.materialExpenceRows.length; i1++) {
        for (let i2 = 0; i2 < values.materialExpenceRows[i1].attachments.length; i2++) {
          if (!values.materialExpenceRows[i1].attachments[i2].id) {
            try {
              const response = await saveAttachment({
                recordType: 'AttachmentDetail',
                recordId: invoice.materialExpenceRows[i1].attachments[i2].attachmentDetailId,
                file: values.materialExpenceRows[i1].attachments[i2].file
              })
              cLog('EXPENCE ROW ATTACHMENT SAVED', response)
              invoice.materialExpenceRows[i1].attachments[i2] = { ...invoice.materialExpenceRows[i1].attachments[i2], ...response.data.attachments[0] }
            } catch (error) {
              cLog('SAVE EXPENCE ROW ATTACHMENT ERROR', error)
              enqueueSnackbar(
              t('invoice.attachmentInvalid'),
                { variant: 'error' }
              )
              notifyOffice(isAdmin, enqueueSnackbar, error, false)
            }
          }
        }
      }

      return invoice
    }

    useEffect(() => {
      const initValidation = async () => {
        let val = validate()
        val.then(result => {
          if (result) {
            validateAllFields()
          }
        })
      }

      if (initValidate) {
        setInitValidate(false)
        if (isAdmin || values.status === 'LUONNOS') {
          initValidation()
        }
      }
    }, [initValidate, setInitValidate, isAdmin])

    useEffect(() => {
      if (panelInitValidate) {
        let row = expanded.split('.')[0]
        let id = expanded.split('.')[1]
        let el = row + 'Body' + id

        if (document.getElementById(el)) {
          setPanelInitValidate(false)
          validate()
        }
      }
    }, [panelInitValidate, expanded, setPanelInitValidate])

    const handleSaveForm = async (sendToCheck) => {
      setLoading(true)
      await setValidateSendExpense(false)
      let val = await validate()
      if (val && validateAllFields()) {
        if (sendToCheck) {
          if ((values.travellingExpenceRows && values.travellingExpenceRows.length > 0) || (values.materialExpenceRows && values.materialExpenceRows.filter(row => row.purpose === 'TRAVEL').length > 0)) {
            setOpenConfirmTravelExpensesDialog(true)
          } else {
            saveForm(sendToCheck)
          }
        } else {
          saveForm(sendToCheck)
        }
      } else {
        setLoading(false)
      }
    }

    const saveForm = async (sendToCheck, sendTravelExpensesConfirmed = false) => {
      cLog('SAVING / UPDATING INVOICE FORM', values)

      let newInitialValues
      let result

      try {
        let userAcceptedTravel = values.userAcceptedTravel
        if (sendTravelExpensesConfirmed || (!values.oldInvoiceGoogleMaps && isAdmin && ((values.travellingExpenceRows && values.travellingExpenceRows.length > 0) || (values.materialExpenceRows && values.materialExpenceRows.filter(row => row.purpose === 'TRAVEL').length > 0)))) {
          userAcceptedTravel = true
        }
        if (isAdmin && values.status === 'LUONNOS' && userAcceptedTravel) {
          userAcceptedTravel = false
        }

        if (!values.id) {                           ///// JOS UUSI LASKU
          if (!exp) {
            result = await saveInvoice({
              ...values,
              userId: !isAdmin ? currentUser.id : values.userId,
              status: sendToCheck ? 'TARKASTETTAVANA' : values.status,
              userAcceptedTravel: userAcceptedTravel
            })
            cLog('A NEW INVOICE SAVED', result)
          } else {
            result = await saveExpence({
              ...values,
              userId: !isAdmin ? currentUser.id : values.userId,
              status: sendToCheck ? 'VALMIS' : values.status
            })
            cLog('A NEW EXPENCE SAVED', result)
          }
        } else {                                              ///// JOS VANHA LASKU
          if (!exp) {
            result = await updateInvoice({
              ...withDestroyedRows(),
              status: sendToCheck ? 'TARKASTETTAVANA' : values.status,
              hasTravelRows: Boolean(values.travellingExpenceRows.length > 0),
              hasMaterialRows: Boolean(values.materialExpenceRows.length > 0),
              userAcceptedTravel: userAcceptedTravel
            })
            cLog('INVOICE UPDATED', result)
          } else {
            result = await updateExpence({
              ...withDestroyedRows(),
              status: sendToCheck ? 'VALMIS' : values.status
            })
            cLog('EXPENCE UPDATED', result)
          }
        }

        newInitialValues = await saveAttachments(result)
        //cLog('ATTACHMENTS SAVED')

        if (!exp) {
          if (sendToCheck) {
            enqueueSnackbar(
              t('invoice.invoiceSentForReview'),
              { variant: 'success' }
            )
          } else {
            if (!isAdmin) {
              enqueueSnackbar(
                t('invoice.draftSaved'),
                { variant: 'success' }
              )
            } else {
              enqueueSnackbar(
                t('invoice.invoiceSaved'),
                { variant: 'success' }
              )
            }
          }
        } else {
          if (sendToCheck) {
            enqueueSnackbar(
              t('invoice.expenseSentForReview'),
              { variant: 'success' }
            )
          } else {
            if (!isAdmin) {
              enqueueSnackbar(
                t('invoice.draftSaved'),
                { variant: 'success' }
              )
            } else {
              enqueueSnackbar(
                t('invoice.expenseSaved'),
                { variant: 'success' }
              )
            }
          }
        }

        cLog('NEW INITIAL VALUES', newInitialValues)
        resetForm(newInitialValues)
        setOldInvoice(newInitialValues)
        if (!isAdmin) {
          if (newInitialValues.status === 'LUONNOS') {
            history.push('/'+(newInitialValues.exp ? 'kulut' : 'laskut')+'/muokkaa/'+newInitialValues.id)
          } else {
            history.push('/?lasku_tarkastettavana')
          }
        } else {
          history.push('/'+(newInitialValues.exp ? 'kulut' : 'laskut')+'/muokkaa/'+newInitialValues.id)
        }
      } catch (error) {
        setLoading(false)
        cLog(error)
        cLog('POST / PUT INVOICE ERROR', (error && error.response && error.response.data) ? error.response.data.errors : error)
        enqueueSnackbar(
          t('invoice.invoiceSaveError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, false)
      }
    }

    const handleViewUser = () => {
      window.open('/kayttajat/' + values.userId)
    }

    const handleEditClientForm = () => {
      clientRef.current.resetForm({
        ...values.client,
        businessId: values.client.businessId || ''
      })
      setEditClientForm(true)
      setOpenClientModal(true)
    }

    const handleDeleteClient = async () => {
      try {
        const invoicesByClient = await getInvoices('?client_id=' + values.client.id)
        //cLog('LASKUT PER ASIAKAS', invoicesByClient.invoices)
        if (invoicesByClient.invoices && invoicesByClient.invoices.length > 0) {
          cLog('CLIENT HAS INVOICES - CANNOT DELETE CLIENT')
          enqueueSnackbar(
            t('invoice.deleteClientStart'+invoicesByClient.invoices.length)+ t('invoice.deleteClientFinish'),
            { variant: 'error' }
          )
          return
        } else {
          setOpenConfirmDeleteClientModal(true)
        }
      } catch (error) {
        cLog('CHECK CLIENTS INVOICES ERROR')
        enqueueSnackbar(
          t('invoice.deleteClientError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, false)
      }
    }

    const handleAgreeDeleteClientModal = async () => {
      try {
        setOpenConfirmDeleteClientModal(false)
        await deleteClient(values.client)
        if (!isAdmin) {
          dispatch(removeClient(values.client))
        }
        cLog(`CLIENT ${values.client.id} DELETED`)
        setFieldValue('client', null)
        enqueueSnackbar(
          `Asiakas ${values.client.id} poistettu `,
          { variant: 'success' }
        )
      } catch (error) {
        cLog('DELETE CLIENT ERROR', error)
        enqueueSnackbar(
          t('invoice.deleteClientError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, false)
      }
    }

    const handleCloseDeleteClientModal = () => {
      setOpenConfirmDeleteClientModal(false)
    }

    const handleBackButton = () => {
      history.push('/')
      window.scroll({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    }

    const removeRow = (row, i) => {
      //cLog('TÄMÄ POISTETAAN', `${row}.${i}`)
      const rowParts = (expanded && expanded.split('.')) || []
      const rowBody = rowParts[0]
      //cLog('ROW BODY ', rowBody, 'ROW ', row)
      const rowNumber = parseInt(rowParts[1])
      if (rowBody === row) {
        if (rowNumber > 0) {
          setExpanded(`${row}.${rowNumber - 1}`)
        } else {
          setExpanded('invoiceBody')
        }
      }
    }

    const addInvoiceRow = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          values.invoiceRows.push(INVOICE_ROW_TEMPLATE(values.dateEndWork))
          setExpanded(`invoiceRows.${values.invoiceRows.length - 1}`)
          window.scroll({
            top: 0,
            left: 100,
            behavior: 'smooth'
          });
        }
      }, error => {
        cLog('ADD INVOICE ROW ERROR', error)
      })
    }

    const addPaymentRow = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          if (!values.payments) {
            values.payments = []
          }
          cLog('INVOICE_PAYMENT_TEMPLATE(values', { ...INVOICE_PAYMENT_TEMPLATE(), txAmt: openBalance })
          values.payments.push({ ...INVOICE_PAYMENT_TEMPLATE(), txAmt: openBalance })
          setExpanded(`payments.${values.payments.length - 1}`)
        }
        window.scroll({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }, error => {
        cLog('ADD PAYMENT ROW ERROR', error)
      })
    }

    const addCorrectionRow = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          if (!values.correctionRows) {
            values.correctionRows = []
          }
          values.correctionRows.push(INVOICE_CORRECTION_ROW_TEMPLATE(values))
          setExpanded(`correctionRows.${values.correctionRows.length - 1}`)
        }
        window.scroll({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }, error => {
        cLog('ADD CORRECTION ROW ERROR', error)
      })
    }

    const addAccountRow = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          if (!values.accounts) {
            values.accounts = []
          }
          values.accounts.push(INVOICE_ACCOUNT_TEMPLATE(values))
          setExpanded(`accounts.${values.accounts.length - 1}`)
        }
        window.scroll({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }, error => {
        cLog('ADD ACCOUNT ROW ERROR', error)
      })
    }

    const addTravelRow = () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          if (values.oldInvoiceGoogleMaps) {
            values.travellingExpenceRows.push(TRAVELLING_EXPENCES_ROW_TEMPLATE(values))
          } else {
            values.travellingExpenceRows.push(TRAVELLING_EXPENCES_ROW_GOOGLE_MAPS_TEMPLATE(values))
          }
          setExpanded(`travellingRows.${values.travellingExpenceRows.length - 1}`)
          if (!exp) {
            setFieldValue(`travellingExpenceRows.${values.travellingExpenceRows.length - 1}.vat`, formatFloat(highestTaxType))
          }
        }
        window.scroll({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }, error => {
        cLog('ADD TRAVEL ROW ERROR', error)
      })

    }

    const addMaterialRow = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          values.materialExpenceRows.push(MATERIAL_EXPENCES_ROW_TEMPLATE(values))
          setFieldValue(`materialExpenceRows.${values.materialExpenceRows.length - 1}.expenceVat`, formatFloat(highestTaxType))
          setExpanded(`materialRows.${values.materialExpenceRows.length - 1}`)
        }
        window.scroll({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }, error => {
        cLog('ADD MATERIAL ROW ERROR', error)
      }
      )
    }

    const addInfo = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewInvoice && result) || !isNewInvoice) {
          setShowInfoTextArea(true)
          setExpanded('info')
        }
        window.scroll({
          top: 0,
          left: 100,
          behavior: 'smooth'
        });
      }, error => {
        cLog('ADD INFO ERROR', error)
      })
    }

    React.useImperativeHandle(ref, () => ({
      setField: (name, value) => {
        setFieldValue(name, value)
      },
      getValues: () => values,
      resetForm: (template) => resetForm(template),
      getUserId: () => values.userId,
      fetchClientsByUserId: (id) => fetchClientsByUserId(id),
      getPayDay: () => values.payDay
    }))

    const InstantSalaryInfo = t('invoice.hetipalkkaInfo')
    const InstantSalaryConditions = () => <ul>
    {InstantSalaryInfo.split(';').map((row, i) => <li key={i}>{row}</li>)}
      </ul>

    let invoiceBodyLabel = `${(!values.id && t('invoice.new')) || ''} ${exp ? t('invoice.newExpense') : t('invoice.newInvoice') } ${!isMobile ? t('invoice.basicInfo') : ''}`.trim()

    const invoiceBody = () =>
      <>
        <Hanuri>
          <HanuriTitle
            id={`invoiceBody`}
            open={handleChangeExpansion(`invoiceBody`)}
            label={invoiceBodyLabel.charAt(0).toUpperCase() + invoiceBodyLabel.slice(1)}
            done={checkInvoiceBodyErrors(values, exp, isAdmin)}
            error={invoiceBodyHasErrors && !checkInvoiceBodyErrors(values, exp, isAdmin)}
            expanded={expanded === `invoiceBody`}
          />

          <HanuriBody expanded={expanded === `invoiceBody`}>

            <p>{t('user.mandatoryFields')}</p>
            <Grid container spacing={4}>
              {isAdmin &&
                <Grid item xs={12} sm={exp ? 6 : 12}>
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                    <Field
                      name="userId"
                      id='userId'
                      label={values.userId ? t('invoice.biller') : t('invoice.chooseBiller')}
                      component={SelectAsync}
                      //isSearchable={true}
                      placeholder={t('invoice.typeToSearch')}
                      defaultValue={userOptions}
                      isDisabled={edit}
                      style={{ flexGrow: 1, width: "100%"}}
                      onChange={async (e) => {
                        setFieldValue('userId', e.value)
                        if (!exp) {
                          const thisUser = getUser(e.value)
                          thisUser.then(data => {
                            fetchClientsByUserId(e.value)
                            setUserData(data)
                            setFieldValue('userFirstName', data.firstName)
                            setFieldValue('userLastName', data.lastName)
                          }, error => {
                            cLog('FETCH USER CLIENTS ERROR', error)
                          })
                        }
                      }}
                      validate={() => !values.userId && ({ key: t('invoice.billerMissing') })}
                      error={errors.userId && !values.userId}
                    />

                    {values.userId &&
                      <Tip title={t('worklist.view')}>
                        <IconButton style={{marginLeft: '0.5rem'}} onClick={handleViewUser}>
                          <VisibilityOutlinedIcon/>
                        </IconButton>
                      </Tip>}
                  </Box>
                </Grid>}

              {((!isAdmin && !exp) || (isAdmin && values.userId && !exp)) &&
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="flex-end">
                    <Field
                      name="client"
                      id='client'
                      label={values.client
                        ? t('invoice.customer')+' *' + (!checkClient(values.client, isAdmin) ? t('invoice.customerMissingInfo') : '')
                        : t('invoice.chooseCustomer')+' *'}
                      options={clientOptions}
                      component={DropDown}
                      isSearchable={!isMobile}
                      placeholder={t('invoice.pick')}
                      info={values.client ? t('invoice.hasClientTip') : t('invoice.doesntHaveClientTip')}
                      style={{ flexGrow: 1, width: "100%"}}
                      onChange={async (e) => {
                        const chosenClientId = e.value
                        cLog('VALITTU CLIENT ID', chosenClientId)
                        try {
                          const promise = getClient(chosenClientId)
                          promise.then(chosenClient => {
                            setFieldValue('client', chosenClient)
                            cLog('CHOSEN CLIENT', chosenClient)
                            setFieldValue('deliveryMethod', chosenClient.deliveryMethod)
                            setFieldValue('reminderHandling', chosenClient.reminderHandling)
                            setFieldValue('einvoiceOperator', chosenClient.einvoiceOperator)
                            setFieldValue('einvoiceAddress', chosenClient.einvoiceAddress)
                            let dateDue = new Date()
                            dateDue.setDate(dateDue.getDate() + chosenClient.paymentTerms)
                            setFieldValue('dateDue', dateDue)
                          })
                        } catch (error) {
                          cLog('GET CLIENT ERROR', error)
                        }
                      }}
                      validate={() => (!values.client || (values.client && !values.client.id)) && ({ key: t('invoice.customerMissing') })}
                      error={errors.client && (!values.client || (values.client && !values.client.id))}
                    />

                    {values.client && values.client.id &&
                      <>
                        <Tip title={t('invoice.modifyCustomer')}>
                          <IconButton style={{marginLeft: '0.5rem'}} onClick={handleEditClientForm}>
                            <EditOutlinedIcon/>
                          </IconButton>
                        </Tip>
                        {isAdmin &&
                          <Tip title={t('invoice.deleteCustomer')}>
                          <IconButton style={{marginLeft: '0.5rem'}} onClick={handleDeleteClient}>
                            <DeleteIcon/>
                          </IconButton>
                        </Tip>}
                      </>}

                    <Tip title={t('invoice.createNewCustomer')}>
                      <IconButton style={{marginLeft: '0.5rem'}} onClick={() => {
                        setOpenClientModal(true)
                        clientRef.current.setField('userId', !isAdmin ? currentUser.id : values.userId)
                      }}>
                        <AddIcon/>
                      </IconButton>
                    </Tip>
                  </Box>
                </Grid>}

              <Grid item xs={12} sm={6}>
                <Field
                  name="date"
                  id="date"
                  label={exp ? t('invoice.expenseDate') : t('invoice.invoiceDate')}
                  required
                  component={DPicker}
                  info={t('invoice.dateTip')}
                  // component={(props) => <Input label={exp ? t('invoice.expenseDate') : t('invoice.invoiceDate')} type="date" {...props} />}
                  selected={values.date ? new Date(values.date) : ''}
                  disabled={exp && !isAdmin}
                  onChange={async (date) => {
                    if (date && values.currency !== 'EUR') {
                      setFieldValue('hasTravelRows', false)
                      setFieldValue('hasMaterialRows', false)
                      try {
                        const currecyRates = await getCurrencyRates(date)
                        const exchangeRate = cleanFloat(currecyRates[values.currency.toLowerCase()]) / 10000000
                        setFieldValue('exchangeRate', exchangeRate)
                      } catch (error) {
                        cLog('CURRENCY RATES ERROR', error)
                      }
                    } else {
                      setFieldValue('exchangeRate', '')
                    }
                    setFieldValue('date', date ? formatDate(date) : '')
                  }}
                  validate={() => validateInvoiceDate(values, exp, isAdmin)}
                  error={errors && errors.date && validateInvoiceDate(values, exp, isAdmin)}
                />
              </Grid>

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="dateDue"
                    id="dateDue"
                    label={t('invoice.dateDue')}
                    required
                    component={DPicker}
                    info={t('invoice.dateDueTip')}
                    selected={values.dateDue ? new Date(values.dateDue) : ''}
                    onChange={(date) => setFieldValue('dateDue', date ? formatDate(date) : '')}
                    validate={() => validateDateDue(values)}
                    error={errors && errors.dateDue && validateDateDue(values)}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="dateStartWork"
                    id="dateStartWork"
                    label={t('invoice.dateStartWork')}
                    required
                    component={DPicker}
                    selected={values.dateStartWork ? new Date(values.dateStartWork) : ''}
                    onChange={(date) => setFieldValue('dateStartWork', date ? formatDate(date) : '')}
                    validate={() => validateDateStartWork(values)}
                    error={errors && errors.dateStartWork && validateDateStartWork(values)}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="dateEndWork"
                    id="dateEndWork"
                    label={t('invoice.dateEndWork')}
                    required
                    component={DPicker}
                    selected={values.dateEndWork ? new Date(values.dateEndWork) : ''}
                    onChange={(date) => {
                      setFieldValue('dateEndWork', date ? formatDate(date) : '')
                      const vat24 = new Date() < vat24ChangeDate() || (date && new Date(date) < vat24ChangeDate())
                      // vat will change, set and calculate values in rows
                      if (vat24 !== values.vat24) {
                        const vat = vat24 ? 24 : 25.5
                        for(var i = 0; i < values.invoiceRows.length; i++) {
                          if (values.invoiceRows[i].vat === '24' || values.invoiceRows[i].vat === '25,5') {
                            const priceTotal = roundNumber(cleanFloat(values.invoiceRows[i].quantity) * cleanFloat(values.invoiceRows[i].price), 2)
                            const total = roundNumber(priceTotal * (1 + vat / 100), 2)
                            setFieldValue(`invoiceRows.${i}.priceWithTax`, roundNumber(cleanFloat(values.invoiceRows[i].price) * (1 + vat / 100), 2))
                            setFieldValue(`invoiceRows.${i}.tax`, roundNumber(total - priceTotal, 2))
                            setFieldValue(`invoiceRows.${i}.total`, total)
                            setFieldValue(`invoiceRows.${i}.vat`, vat24 ? '24' : '25,5')
                          }
                        }
                        for(i = 0; i < values.travellingExpenceRows.length; i++) {
                          if (values.travellingExpenceRows[i].vat === '24' || values.travellingExpenceRows[i].vat === '25,5') {
                            setFieldValue(`travellingExpenceRows.${i}.vat`, vat24 ? '24' : '25,5')
                          }
                        }
                        for(i = 0; i < values.materialExpenceRows.length; i++) {
                          if (values.materialExpenceRows[i].expenceVat === '24' || values.materialExpenceRows[i].expenceVat === '25,5') {
                            const price = cleanFloat(values.materialExpenceRows[i].price)
                            const priceWithTax = roundNumber(price * (1 + vat / 100), 2)
                            setFieldValue(`materialExpenceRows.${i}.priceWithTax`, priceWithTax)
                            setFieldValue(`materialExpenceRows.${i}.tax`, roundNumber(priceWithTax - price, 2))
                            setFieldValue(`materialExpenceRows.${i}.expenceVat`, vat24 ? '24' : '25,5')
                          }
                        }
                        for(i = 0; i < values.correctionRows.length; i++) {
                          if (values.correctionRows[i].vat === '24' || values.correctionRows[i].vat === '25,5') {
                            const amountTotal = roundNumber(cleanFloat(values.correctionRows[i].quantity) * cleanFloat(values.correctionRows[i].amount), 2)
                            const total = roundNumber(amountTotal * (1 + vat / 100), 2)
                            setFieldValue(`correctionRows.${i}.amountWithTax`, roundNumber(cleanFloat(values.correctionRows[i].amount) * (1 + vat / 100), 2))
                            setFieldValue(`correctionRows.${i}.tax`, roundNumber(total - amountTotal, 2))
                            setFieldValue(`correctionRows.${i}.total`, total)
                            setFieldValue(`correctionRows.${i}.vat`, vat24 ? '24' : '25,5')
                          }
                        }
                        setFieldValue('vat24', vat24)
                      }
                    }}
                    validate={() => validateDateEndWork(values)}
                    error={errors && errors.dateEndWork && validateDateEndWork(values)}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="refText"
                    id="refText"
                    label={t('invoice.refText')}
                    type="text"
                    component={Input}
                    value={values.refText || ''}
                    onChange={(e) => {
                      setFieldValue('refText', e.target.value)
                    }}
                    info={t('invoice.refTextTip')}
                    validate={() => validateTextArea(values.refText, t('invoice.refText'), 70, false)}
                    error={errors && errors.refText && validateTextArea(values.refText, t('invoice.refText'), 70, false)}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="currency"
                    id="currency"
                    options={currencyOptions}
                    isSearchable={!isMobile}
                    label={t('invoice.currency')}
                    onChange={async (e) => {
                      if (e.value !== 'EUR') {
                        setFieldValue('hasTravelRows', false)
                        setFieldValue('travellingExpenceRows', [])
                        setFieldValue('hasMaterialRows', false)
                        setFieldValue('materialExpenceRows', [])
                        let d = new Date()
                        if (values.date) {
                          d = values.date
                        }
                        try {
                          const currecyRates = await getCurrencyRates(d)
                          const exchangeRate = cleanFloat(currecyRates[e.value.toLowerCase()]) / 10000000
                          setFieldValue('exchangeRate', exchangeRate)
                        } catch (error) {
                          cLog('CURRENCY RATES ERROR', error)
                        }
                      } else {
                        setFieldValue('exchangeRate', '')
                      }
                      setFieldValue('currency', e.value)
                    }}
                    component={DropDown}
                    value={currencyOptions[0]}
                  />
                </Grid>}

              {!exp && values.currency !== 'EUR' &&
                <Grid item xs={12} sm={6}>
                </Grid>}
              {!exp && values.currency !== 'EUR' &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="exchangeRate"
                    id='exchangeRate'
                    label={t('invoice.exchangeRate') + ' *'}
                    type="text"
                    component={Input}
                    disabled={!isAdmin}
                    value={values.exchangeRate || ''}
                    onChange={(e) => {
                      setFieldValue('exchangeRate', e.target.value)
                    }}
                    validate={() => isAdmin && values.currency !== 'EUR' && validateFloatGreaterThan(values.exchangeRate, 0, 999999.9999999, t('invoice.exchangeRate'), 7)}
                    error={errors && errors.exchangeRate && isAdmin && values.currency !== 'EUR' && validateFloatGreaterThan(values.exchangeRate, 0, 999999.9999999, t('invoice.exchangeRate'), 7)}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={values.industry !== 'Muu' ? 6 : 4}>
                  <Field
                    name="industry"
                    id='industry'
                    label={t('invoice.industry') + ' *'}
                    value={values.industry}
                    placeholder={t('invoice.pick')}
                    info={t('invoice.industryTip')}
                    onChange={(e) => {
                      setFieldValue('industry', e.value)
                      if (e.value !== 'Muu') {
                        setFieldValue('customIndustry', '')
                      }
                    }}
                    options={industryOptions}
                    isSearchable={!isMobile}
                    component={DropDown}
                    validate={() => !values.industry && { key: t('invoice.industryMissing')}}
                    error={errors && errors.industry && !values.industry}
                  />
                </Grid>}

              {!exp && values.industry === 'Muu' &&
                <Grid item xs={12} sm={values.industry !== 'Muu' ? 6 : 4}>
                  <Field
                    name="customIndustry"
                    id='customIndustry'
                    label={t('invoice.customIndustry')}
                    type="text"
                    component={Input}
                    value={values.customIndustry || ''}
                    onChange={(e) => {
                      setFieldValue('customIndustry', e.target.value)
                    }}
                    validate={() => validateCustomIndustry(values)}
                    error={errors && errors.customIndustry && validateCustomIndustry(values)}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={values.industry !== 'Muu' ? 6 : 4}>
                  <Field
                    name="marketingName"
                    id="marketingName"
                    label={t('invoice.marketingName')}
                    type="text"
                    component={Input}
                    value={values.marketingName || ''}
                    onChange={(e) => {
                      setFieldValue('marketingName', e.target.value)
                    }}
                    validate={() => validateTextArea(values.marketingName, t('invoice.marketingName'), 70, false)}
                    error={errors && errors.marketingName && validateTextArea(values.marketingName, t('invoice.marketingName'), 70, false)}
                    info={t('user.marketingNameInfo')}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="deliveryMethod"
                    id='deliveryMethod'
                    label={t('invoice.deliveryMethod')}
                    info={t('customers.deliveryMethodTip')}
                    value={values.deliveryMethod}
                    onChange={(e) => {
                      setFieldValue('deliveryMethod', e.value)
                      setFieldValue('client', {
                        ...values.client,
                        deliveryMethod: e.value
                      })
                    }}
                    options={deliveryOptions}
                    isSearchable={!isMobile}
                    component={DropDown}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="reminderHandling"
                    id='reminderHandling'
                    label={t('invoice.reminderHandling')}
                    info={t('customers.reminderHandlingTip')}
                    value={values.reminderHandling}
                    onChange={(e) => {
                      if (values.payDay === 'HETI') {
                        setFieldValue('reminderHandling', 'AUTOMAATTINEN')
                        setFieldValue('client', {
                          ...values.client,
                          reminderHandling: 'AUTOMAATTINEN'
                        })
                        //clientRef.current.setField('reminderHandling', 'AUTOMAATTINEN')
                      } else {
                        setFieldValue('reminderHandling', e.value)
                        setFieldValue('client', {
                          ...values.client,
                          reminderHandling: e.value
                        })
                        clientRef.current.setField('reminderHandling', e.value)
                      }
                    }}
                    options={reminderOptions}
                    isSearchable={!isMobile}
                    component={DropDown}
                    validate={() => validateReminderHandling(values.reminderHandling, values.payDay)}
                    error={errors && errors.reminderHandling && validateReminderHandling(values.reminderHandling, values.payDay)}
                  />
                </Grid>}

              {!exp && isAdmin &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="vatStatus"
                    id='vatStatus'
                    label={t('invoice.vatStatus')}
                    value={values.vatStatus}
                    // placeholder='Valitse'
                    onChange={(e) => setFieldValue('vatStatus', e.value)}
                    options={vatStatusOptions}
                    component={DropDown}
                  />
                </Grid>}

              {isAdmin &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="status"
                    id='status'
                    label={exp ? t('invoice.expenseStatus') : t('invoice.status')}
                    value={values.status}
                    placeholder={t('invoice.pick')}
                    onChange={(e) => setFieldValue('status', e.value)}
                    options={statusOptions}
                    component={DropDown}
                  />
                </Grid>}

              {!exp &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="payDay"
                    id="payDay"
                    options={payDayOptions}
                    isSearchable={!isMobile}
                    label={t('invoice.payDay')}
                    info={t('invoice.payDayTip')}
                    onChange={(e) => {
                      setFieldValue('payDay', e.value)
                      if (e.value === 'HETI') {
                        setFieldValue('reminderHandling', 'AUTOMAATTINEN')
                        setFieldValue('client', {
                          ...values.client,
                          reminderHandling: 'AUTOMAATTINEN'
                        })
                      }
                    }}
                    component={DropDown}
                    value={payDayOptions[1]}
                  />
                </Grid>}

              {!exp && isAdmin &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="reminderDate"
                    id="reminderDate"
                    label={t('invoice.reminderDate')}
                    component={DPicker}
                    selected={values.reminderDate ? new Date(values.reminderDate) : ''}
                    onChange={(date) => setFieldValue('reminderDate', date ? formatDate(date) : '')}
                    validate={() => validateDate(values.reminderDate, t('invoice.reminderDate'), values.reminderNotes, null, true)}
                    error={errors && errors.reminderDate && validateDate(values.reminderDate, t('invoice.reminderDate'), values.reminderNotes, null, true)}
                  />
                </Grid>}

              {!exp && isAdmin &&
                <Grid item xs={12} sm={12}>
                  <Field
                    name={`reminderNotes`}
                    id={`reminderNotes`}
                    label={t('invoice.reminderNotes')}
                    type="text"
                    component={TextArea}
                    value={values.reminderNotes || ''}
                    onChange={e => {
                      setFieldValue('reminderNotes', e.target.value)
                    }}
                    validate={() => validateTextArea(values.reminderNotes, t('invoice.reminderNotes'), 10000, values.reminderDate)}
                    error={errors && errors.reminderNotes && validateTextArea(values.reminderNotes, t('invoice.reminderNotes'), 10000, values.reminderDate)}
                  /></Grid>}

              {values.payDay === 'HETI' && !isAdmin && <InfoBar allUsers grid title={t('invoice.instantSalaryTermsTitle')}><InstantSalaryConditions /></InfoBar>}

              {values.payDay === 'PIKAMAKSU' && !isAdmin && currentUser.bic && currentUser.bic === 'NDEAFIHH' && <InfoBar allUsers grid message={t('invoice.expressPaymentTipNordea')} />}
              {values.payDay === 'PIKAMAKSU' && !isAdmin && (!currentUser.bic || currentUser.bic !== 'NDEAFIHH') && <InfoBar allUsers grid message={t('invoice.expressPaymentTip')} />}

              {values.worklistId &&
                <Grid item xs={12} sm={12}>
                  <Field
                    name={`additionalInformation`}
                    id={`additionalInformation`}
                    label={t('invoice.workList')}
                    disabled={!isAdmin}
                    type="text"
                    component={TextArea}
                    value={values.additionalInformation || ''}
                    onChange={e => {
                      setFieldValue('additionalInformation', e.target.value)
                    }}
                    validate={() => validateTextArea(values.additionalInformation, t('invoice.workList'), 10000, false)}
                    error={errors && errors.additionalInformation && validateTextArea(values.additionalInformation, t('invoice.workList'), 10000, false)}
                  /></Grid>}

              {isAdmin &&
                <Grid item xs={12} sm={12}>
                  <Field
                    name="internalNotes"
                    id="internalNotes"
                    label={t('invoice.internalNotes')}
                    component={TextArea}
                    value={values.internalNotes || ''}
                    onChange={(e) => {
                      setFieldValue('internalNotes', e.target.value)
                    }}
                    validate={() => isAdmin && validateTextArea(values.internalNotes, t('invoice.internalNotes'), 10000, false)}
                    error={errors && errors.internalNotes && isAdmin && validateTextArea(values.internalNotes, t('invoice.internalNotes'), 10000, false)}
                  />
                </Grid>}

              {isAdmin && <Grid item xs={12} sm={12}>
                <Field
                  name="adminInfo"
                  id="adminInfo"
                  type="text"
                  label={t('invoice.adminInfo')}
                  component={TextArea}
                  value={values.adminInfo || ''}
                  onChange={(e) => {
                    setFieldValue('adminInfo', e.target.value)
                  }}
                  placeholder={t('invoice.adminInfoTip')}
                  validate={() => isAdmin && validateTextArea(values.adminInfo, t('invoice.adminInfo'), 10000, false)}
                  error={errors && errors.adminInfo && isAdmin && validateTextArea(values.adminInfo, t('invoice.adminInfo'), 10000, false)}
                />
              </Grid>}

              {!exp && <Grid item xs={12}>
                <LabelWithInfo
                  label={t('invoice.yelObligation')+' *'}
                  info={t('invoice.yelSelectionTip')}
                  justify={false}
                />
                <RadioGroup>
                  <Field
                    name="yel"
                    label={t('invoice.yelTrue')}
                    onChange={(e) => {
                      e.target.checked ? setFieldValue('yel', true) : setFieldValue('yel', false)
                      setFieldTouched('yel', true)
                    }}
                    component={OdealRadio}
                    checked={values.yel === true}
                    validate={() => (values.yel === undefined || values.yel === null) && { key: t('invoice.noYelSelected') }}
                    error={errors && errors.yel && (!touched.yel ? 'error' : '')}
                  />
                  <Field
                    name="yel"
                    label={t('invoice.yelFalse')}
                    onChange={(e) => {
                      e.target.checked ? setFieldValue('yel', false) : setFieldValue('yel', true)
                      setFieldTouched('yel', true)
                    }}
                    component={OdealRadio}
                    checked={values.yel === false}
                    validate={() => (values.yel === undefined || values.yel === null) && { key: t('invoice.noYelSelected') }}
                    error={errors && errors.yel && (!touched.yel ? 'error' : '')}
                  />
                </RadioGroup>
              </Grid>}

              {!exp && values.currency === 'EUR' &&
                <Grid item sm={12} md={12}>
                  <FormControlLabel
                    control={<OdealCheckbox
                      checked={values.hasTravelRows}
                      onChange={() => {
                        if (values.hasTravelRows) {
                          setFieldValue('travellingExpenceRows', [])
                        }
                        setFieldValue('hasTravelRows', !values.hasTravelRows)
                      }}
                      name="hasTravelRows" />}
                    label={t('invoice.hasTravelRows')}
                  />
                </Grid>}

              {!exp && isAdmin && values.hasTravelRows &&
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<OdealCheckbox
                      checked={values.hasCustomTravelVats}
                      onChange={() => setFieldValue('hasCustomTravelVats', !values.hasCustomTravelVats)}
                      name="hasCustomTravelVats" />}
                    label={t('invoice.hasCustomTravelVats')}
                  />
                </Grid>}

              {!exp && values.hasTravelRows && !isAdmin &&
                <InfoBar allUsers grid message={t('invoice.travellingRowsTip')} />
              }

              {!exp && values.currency === 'EUR' &&
                <Grid item sm={12} md={12}>
                  <FormControlLabel
                    control={<OdealCheckbox
                      checked={values.hasMaterialRows}
                      onChange={() => {
                        if (values.hasMaterialRows) {
                          setFieldValue('materialExpenceRows', [])
                        }
                        setFieldValue('hasMaterialRows', !values.hasMaterialRows)
                      }}
                      name="hasMaterialRows" />}
                    label={t('invoice.hasMaterialRows')}
                  />
                </Grid>}

              {!exp && values.hasMaterialRows && !isAdmin &&
                <InfoBar allUsers grid message={t('invoice.materialRowsTip')} />
              }

            </Grid>

          </HanuriBody>
        </Hanuri>
        <Alert
          open={openConfirmDeleteClientModal}
          setOpen={setOpenConfirmDeleteClientModal}
          handleAgree={handleAgreeDeleteClientModal}
          handleClose={handleCloseDeleteClientModal}
          alertMessage={{ title: t('invoice.customerDeleteWarningTitle')+ (values.client && values.client.name) + '?', body: t('invoice.customerDeleteWarningBody') }}
          confirmButtonText={t('customers.remove')}
          confirmButtonColor="error"
        />
      </>

    const invoiceRow = () => values.invoiceRows && values.invoiceRows.length > 0 &&
      <InvoiceRow
        invoiceRows={values.invoiceRows}
        setFieldValue={setFieldValue}
        expanded={expanded}
        handleChangeExpansion={handleChangeExpansion}
        removeRow={removeRow}
        values={values}
        isAdmin={isAdmin}
        vat24={values.vat24}
      />

    const travellingExpenceRow = () => values.travellingExpenceRows && values.travellingExpenceRows.length > 0 &&
      isLoaded ?
        <TravellingExpenceRow
          travellingExpenceRows={values.travellingExpenceRows}
          setFieldValue={setFieldValue}
          expanded={expanded}
          handleChangeExpansion={handleChangeExpansion}
          removeRow={removeRow}
          isAdmin={isAdmin}
          exp={exp}
          allowNegative={values.creditNote || (values.creditNoteInvoicesData && values.creditNoteInvoicesData.length > 0) || values.status === 'LUOTTOTAPPIO'}
          oldInvoiceTravelType1={values.oldInvoiceTravelType1}
          oldInvoiceTravelType2={values.oldInvoiceTravelType2}
          oldInvoiceGoogleMaps={values.oldInvoiceGoogleMaps}
          vat24={values.vat24}
          touched={touched}
          setFieldTouched={setFieldTouched}
          validate={validate}
          panelInitValidate={panelInitValidate}
          setPanelInitValidate={setPanelInitValidate}
          showKmInfoDialog={showKmInfoDialog}
          setShowKmInfoDialog={setShowKmInfoDialog}
          setFieldError={setFieldError}
        />
      : <></>

    const materialExpenceRow = () => values.materialExpenceRows && values.materialExpenceRows.length > 0 &&
      <MaterialExpenceRow
        materialExpenceRows={values.materialExpenceRows}
        setFieldValue={setFieldValue}
        expanded={expanded}
        handleChangeExpansion={handleChangeExpansion}
        removeRow={removeRow}
        isAdmin={isAdmin}
        validateSendExpense={validateSendExpense}
        oldInvoiceTravelType1={values.oldInvoiceTravelType1}
        oldInvoiceTravelType2={values.oldInvoiceTravelType2}
        vat24={values.vat24}
        exp={exp}
      />

    const correctionRow = () => values.correctionRows && values.correctionRows.length > 0 &&
      <CorrectionRow
        correctionRows={values.correctionRows}
        setFieldValue={setFieldValue}
        expanded={expanded}
        handleChangeExpansion={handleChangeExpansion}
        removeRow={removeRow}
        vat24={values.vat24}
      />

    const paymentRow = () => values.payments && values.payments.length > 0 &&
      <PaymentRow
        payments={values.payments}
        setFieldValue={setFieldValue}
        expanded={expanded}
        handleChangeExpansion={handleChangeExpansion}
        removeRow={removeRow}
      />

    const accountRow = () => values.accounts && values.accounts.length > 0 &&
      <AccountRow
        accounts={values.accounts}
        setFieldValue={setFieldValue}
        expanded={expanded}
        handleChangeExpansion={handleChangeExpansion}
        removeRow={removeRow}
        values={values}
        exp={exp}
      />

    const infoRow = () => showInfoTextArea &&
      <div id='infoRow'>
        <Hanuri>
          <HanuriTitle
            expanded={expanded}
            id={`info`}
            open={handleChangeExpansion(`info`)}
            remove={() => {
              setFieldValue('info', '')
              setShowInfoTextArea(false)
            }}
            label={t('invoice.info')+t('invoice.onlyForOdeal')}
            done={values.info}
          />
          <HanuriBody expanded={expanded === `info`}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Field
                  name="info"
                  id="info"
                  type="text"
                  component={TextArea}
                  value={values.info || ''}
                  onChange={(e) => {
                    setFieldValue('info', e.target.value)
                  }}
                  validate={() => validateTextArea(values.info, t('invoice.info'), 10000, false)}
                  error={errors && errors.info && validateTextArea(values.info, t('invoice.info'), 10000, false)}
                />
              </Grid>
            </Grid>
          </HanuriBody>
        </Hanuri>
      </div>

    return (
      <div>
        {!isMobile
          ? <div className={classes.root}>
            {!view
              ? <Grid container spacing={8}>
                <Grid item sm={12} md={6}>          {/* COL 1 */}
                  <InvoiceView
                    exp={exp}
                    view={view}
                    edit={edit}
                    recycle={recycle}
                    create={create}
                    invoice={values}
                    setFieldValue={setFieldValue}
                    handleSaveForm={handleSaveForm}
                    handleBackButton={handleBackButton}
                    setExpanded={setExpanded}
                    validate={validate}
                    isLoading={isLoading}
                    setLoading={setLoading}
                    setValidateSendExpense={setValidateSendExpense}
                    oldInvoiceTravelType1={values.oldInvoiceTravelType1}
                    fetchClientsByUserId={fetchClientsByUserId}
                    userData={userData}
                    setUserData={setUserData}
                    validateAllFields={validateAllFields}
                    resetForm={resetForm}
                    setOldInvoice={setOldInvoice}
                  />
                </Grid>
                <Grid item sm={12} md={6}>            {/* COL 2 */}

                  <form id='invoiceBody'>
                    {invoiceBody()}
                    {invoiceRow()}
                    {travellingExpenceRow()}
                    {materialExpenceRow()}
                    {infoRow()}
                    {correctionRow()}
                    {paymentRow()}
                    {accountRow()}
                  </form>

                  <ButtonRow
                    exp={exp}
                    values={values}
                    setFieldValue={setFieldValue}
                    showInfoTextArea={showInfoTextArea}
                    isAdmin={isAdmin}
                    addInvoiceRow={addInvoiceRow}
                    addTravelRow={addTravelRow}
                    addMaterialRow={addMaterialRow}
                    addInfo={addInfo}
                    addPaymentRow={addPaymentRow}
                    addCorrectionRow={addCorrectionRow}
                    addAccountRow={addAccountRow}
                    hasTravelRows={values.hasTravelRows}
                    hasMaterialRows={values.hasMaterialRows}
                  />
                </Grid>
              </Grid>
              : <InvoiceView
                  exp={exp}
                  view={view}
                  invoice={values}
                  setFieldValue={setFieldValue}
                  handleSaveForm={handleSaveForm}
                  handleBackButton={handleBackButton}
                  setExpanded={setExpanded}
                  validate={validate}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  setValidateSendExpense={setValidateSendExpense}
                  oldInvoiceTravelType1={values.oldInvoiceTravelType1}
                  fetchClientsByUserId={fetchClientsByUserId}
                  userData={userData}
                  setUserData={setUserData}
                  validateAllFields={validateAllFields}
                  resetForm={resetForm}
                  setOldInvoice={setOldInvoice}
                />}
          </div>
          : <div className={classes.rootSm}>
            {!view
              ? <>
                {!showMobileSummary && <form>
                  {invoiceBody()}
                  {invoiceRow()}
                  {travellingExpenceRow()}
                  {materialExpenceRow()}
                  {paymentRow()}
                  {infoRow()}
                </form>}
                {showMobileSummary && <InvoiceView
                  exp={exp}
                  view={view}
                  invoice={values}
                  setFieldValue={setFieldValue}
                  handleSaveForm={handleSaveForm}
                  handleBackButton={handleBackButton}
                  setExpanded={setExpanded}
                  validate={validate}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  setValidateSendExpense={setValidateSendExpense}
                  oldInvoiceTravelType1={values.oldInvoiceTravelType1}
                  fetchClientsByUserId={fetchClientsByUserId}
                  userData={userData}
                  setUserData={setUserData}
                  validateAllFields={validateAllFields}
                  resetForm={resetForm}
                  setOldInvoice={setOldInvoice}
                />}
                {!showMobileSummary && <ButtonRow
                  exp={exp}
                  values={values}
                  setFieldValue={setFieldValue}
                  showInfoTextArea={showInfoTextArea}
                  isAdmin={isAdmin}
                  addInvoiceRow={addInvoiceRow}
                  addTravelRow={addTravelRow}
                  addMaterialRow={addMaterialRow}
                  addPaymentRow={addPaymentRow}
                  addInfo={addInfo}
                  hasTravelRows={values.hasTravelRows}
                  hasMaterialRows={values.hasMaterialRows}
                />}
                <BottomNavigation
                  values={values}
                  showMobileSummary={showMobileSummary}
                  setShowMobileSummary={setShowMobileSummary}
                  handleSaveForm={handleSaveForm}
                  handleBackButton={handleBackButton}
                  isLoading={isLoading}
                />
              </>
              : <>
                <InvoiceView
                  exp={exp}
                  view={view}
                  invoice={values}
                  setFieldValue={setFieldValue}
                  handleSaveForm={handleSaveForm}
                  handleBackButton={handleBackButton}
                  setExpanded={setExpanded}
                  validate={validate}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  setValidateSendExpense={setValidateSendExpense}
                  oldInvoiceTravelType1={values.oldInvoiceTravelType1}
                  fetchClientsByUserId={fetchClientsByUserId}
                  userData={userData}
                  setUserData={setUserData}
                  validateAllFields={validateAllFields}
                  resetForm={resetForm}
                  setOldInvoice={setOldInvoice}
                />
                <BottomNavigation
                  values={values}
                  view
                  showMobileSummary={showMobileSummary}
                  setShowMobileSummary={setShowMobileSummary}
                  handleSaveForm={handleSaveForm}
                  handleBackButton={handleBackButton}
                  isLoading={isLoading}
                />
              </>}
          </div>}
        <RouterPrompt
          when={values.id ? numberOfChanges > 5 : numberOfChanges > 1}
          title={t('invoice.leavingPageWarningTitle')}
          body={t('invoice.leavingPageWarningBody')}
          onOK={() => true}
        />
        <ConfirmTravelExpensesDialog open={openConfirmTravelExpensesDialog} setOpen={setOpenConfirmTravelExpensesDialog} saveForm={saveForm} setLoading={setLoading} />
      </div>
    )
  }
)

export default withSnackbar(InvoiceForm)
