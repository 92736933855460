import React from "react";
import { Card, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
    titledCard: {
        boxSizing: 'border-box',
        borderRadius: theme.borders.radius.base,

        '& > h2': {
            margin: theme.spacing(4),
        },

        '&.transparent': {
            background: 'transparent',
            boxShadow: "none",
        },
        '&.outlined': {
            background: 'transparent',
            border: `2px solid ${theme.palette[props.color].main}`,

            '& > h2': {
                color: theme.palette[props.color].main,
            },
        },
        '&.contained': {
            background: theme.palette[props.color].main,
            color: theme.palette[props.color].contrastText,

            '& > h2': {
                color: theme.palette[props.color].contrastText,
            },
        },
        '&.fill-width': {
            width: '100%',
        },
        '&.fill-height': {
            height: '100%',
        },
    }
}))();

const TitledCard = (props) => {
    const styleProps = {
        color: props.color || "primary",
    };
    const classes = useStyles(styleProps);
    const variant = props.variant || "base";
    const elevation = props.elevation || 0;
    let className = `${classes.titledCard} ${variant}${props.fillWidth ? " fill-width" : ""}${props.fillHeight ? " fill-height" : ""}`;
    if (props.className !== undefined && props.className.length > 0)
        className = className.concat(" ", props.className);

    return (
        <Card className={className} elevation={elevation} style={props.style}>
            {props.title && <Typography variant="h5" component="h2">{props.title}</Typography>}
            {props.children}
        </Card>
    );
}

export default TitledCard;
