import React from "react"
import { cLog } from "../utils"
import { useTranslation } from 'react-i18next'
import { useStateValue } from "../state"
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { requestNewEmailConfirmation } from '../service'
import Alert from '@material-ui/lab/Alert'
import { COLORS } from '../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    //marginBottom: '-64px',
    padding: '10px',
    background: theme.palette.white.main,
  },
  alert: {
    width: '100%'
  },
  infoButton: {
    color: 'white',
    margin: '0em 1.8em',
    '&:hover': {
      color: COLORS.tertiary,
      backgroundColor: COLORS.primary
    },
    backgroundColor: COLORS.primary
  }
}))

export default function Notifications({ enqueueSnackbar }) {
  const { t } = useTranslation()
  const [{ currentUser }] = useStateValue()
  const classes = useStyles()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  const handleRequestNewEmailConfirmation = () => {
    cLog('REQUEST NEW EMAIL CONFIRMATION')
    try {
      const response = requestNewEmailConfirmation(currentUser.id)
      cLog('REQUEST NEW EMAIL CONFIRMATION RESPONSE', response)
      enqueueSnackbar(
        t('user.newConfirmEmailSent'),
        { variant: 'success' }
      )
    } catch (error) {
      cLog('REQUEST NEW EMAIL CONFIRMATION ERROR', error.response.data.errors)
      enqueueSnackbar(
        t('user.newConfirmEmailError'),
        { variant: 'error' }
      )
    }
  }

  return (
    <>
      {currentUser && !isAdmin && currentUser.unconfirmedEmail && <div className={classes.root}>
        <Alert variant="filled" severity="info">
          <Grid container className={classes.alert}>
            {t('user.emailNotConfirmed', { unconfirmedEmail: currentUser.unconfirmedEmail })}
            <Button type='button' onClick={handleRequestNewEmailConfirmation} className={classes.infoButton}>
              {t('user.askForNewConfirmation')}
            </Button>
          </Grid>
        </Alert>
      </div>}
      {currentUser && isAdmin && new Date('2025-05-01').withoutTime() <= new Date().withoutTime() && <div className={classes.root}>
        <Alert variant="filled" severity="info">
          <Grid container className={classes.alert}>
            Sivuston sertifikaatti vanhenee tiistai 17. kesäkuuta 2025 klo 17.30.04!<br/>
            Muista myös support.odeal.fi
          </Grid>
        </Alert>
      </div>}
    </>
  )
}