import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0, 8, 0),
    borderTop: `2px solid ${theme.palette.grey.main}`,

    // Footer items
    "& > div": {
      display: "block",
      "& > *": {
        display: "block",
      },
      "& > a": {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.light,
        },
      },
    },
  },
}))

const Footer = ({ landingScreen }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} className={classes.footer}>
      <Grid item xs={12} sm={4}>{/* COL 1 */}
        <span><b>Odeal Oy</b></span>
        <span>Niittyläntie 5</span>
        <span>00620 Helsinki</span>
      </Grid>
      <Grid item xs={12} sm={4}>{/* COL 2 */}
        <span>{t('frontPage.customerServiceOpen')}</span>
        <a href="tel:0800 0 4848">0800 0 4848</a>
        <a href="mailto:info@odeal.fi" target='_blank' rel="noopener noreferrer">info@odeal.fi</a>
      </Grid>
      <Grid item xs={12} sm={4}>{/* COL 3 */}
        <Link to='/terms'>{t('frontPage.terms')}</Link>
        <Link to='/tietosuoja-ja-rekisteriselosteet'>{t('frontPage.privacy')}</Link>
        <Link to='/contact-info'>{t('frontPage.contact')}</Link>
      </Grid>
    </Grid>
  );
}

export default Footer
