import React, { useState, useEffect } from 'react'
import { Field } from 'formik'
import { Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { formatTimeFI, formatDatetime } from '../../utils'
import Input from '../../components/Input'
import SelectAsync from '../../components/SelectAsync'
import DropDown from '../../components/DropDown'
import Button from '../../components/Button'
import { getClientNames, getUser, saveWorklist, updateWorklist } from '../../service'
import { useStateValue, setClients, addWorklist, reviseWorklist } from "../../state"
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import WorklistRow, { WORKLIST_ROW_TEMPLATE } from './WorklistRow'
import { validateName, checkWorklistBodyErrors, validateAll } from './worklistValidations'
import { cleanFloat } from '../../utils/invoiceUtils'
import { scrollTo } from '../../utils'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(2, 2, 0, 0),
    },
  }
}))

const WorklistForm = React.forwardRef(
  (props, ref) => {
    const {
      handleSubmit,
      handleChange,
      values,
      setFieldValue,
      validateForm,
      errors,
      edit,
      enqueueSnackbar,
      worklist,
      userData,
      setUserData
    } = props

    const { t } = useTranslation()
    const [{ clients, currentUser, }, dispatch] = useStateValue()
    const [isLoading, setLoading] = useState(false)
    let history = useHistory()
    const classes = useStyles()
    const isAdmin = currentUser && currentUser.role === 'ADMIN'
    const [expanded, setExpanded] = useState('worklistBody')
    const [showHistory, setShowHistory] = useState()
    const isNewWorklist = !values.id || values.status === 'LUONNOS'
    const worklistBodyHasErrors = (errors && Object.keys(errors).length > 0) || false
    cLog('WORKLIST FORM VALUES', values)

    useEffect(() => {
      if (values.worklistRows.length > 0) {
        const totalHoursArray = values.worklistRows.map(row => cleanFloat(row.hours))
        //cLog('TOTAL HOURS ARRAY', totalHoursArray)
        const totalHours = totalHoursArray.reduce((a, b) => cleanFloat(a) + cleanFloat(b))
        //cLog('TOTAL HOURS', totalHours)
        setFieldValue('totalHours', totalHours)
      } else {
        setFieldValue('totalHours', 0)
      }
    }, [values.worklistRows, setFieldValue])

    let userOptions = []
    if (userData) {
      userOptions = [{ label: `${userData.firstName} ${userData.lastName} (${userData.email})`.trim(), value: userData.id }]
    } else if (values.userId) {
      userOptions = [{ label: `${values.userFirstName} ${values.userLastName} (${values.userEmail})`.trim(), value: values.userId }]
    }
    const clientOptions = React.useMemo(() => {
      //cLog('CLIENT OPTIONEISTA CLIENTS', clients && clients.length > 0)
      let options = clients && clients.length > 0
        ? clients
          .map(client => {
            const label = client.name || ''
            return ({ label, value: client.id })
          })
        : []
      options.sort((a, b) => {
        var nameA = a.label.toUpperCase()
        var nameB = b.label.toUpperCase()
        return nameA < nameB
          ? -1
          : nameA > nameB
            ? 1
            : 0
      })
      return options
    }, [clients])

    const handleChangeExpansion = (panel) => async (event, isExpanded) => {
      let val = validate()
      val.then(result => {
        if (isAdmin || result) {
          if (panel === expanded) {
            setExpanded('')
            return
          } else {
            setExpanded(panel)
            scrollTo(panel)
          }
        }
      }, error => {
        cLog('CHANGE EXPANSION ERROR', error)
      })
    }

    const addRow = async () => {
      let val = validate()
      val.then(result => {
        if ((isNewWorklist && result) || !isNewWorklist) {
          values.worklistRows.push(WORKLIST_ROW_TEMPLATE)
          setExpanded(`worklistRows.${values.worklistRows.length - 1}`)
          scrollTo(`worklistRows.${values.worklistRows.length - 1}`)
        }
      }, error => {
        cLog('ADD WORKLIST ROW ERROR', error)
      })
    }

    const removeRow = (row, i) => {
      //cLog('TÄMÄ POISTETAAN', `${row}.${i}`)
      const rowParts = (expanded && expanded.split('.')) || []
      const rowBody = rowParts[0]
      //cLog('ROW BODY ', rowBody, 'ROW ', row)
      const rowNumber = parseInt(rowParts[1])
      if (rowBody === row) {
        if (rowNumber > 0) {
          setExpanded(`${row}.${rowNumber - 1}`)
        } else {
          setExpanded('worklistBody')
        }
      }
    }

    const notifyOffice = (error) => {
      if (isAdmin) {
        if (error && error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.forEach(message => {
            enqueueSnackbar(
              message,
              { variant: 'error' }
            )
          })
        }
      }
    }

    const validate = async () => {
      const erroris = await validateForm()
      cLog('erroriserroriserroris', erroris)
      if (Object.keys(erroris).length > 0) {
        notifyErrors(erroris)
        return false
      }

      return true
    }

    cLog('WORKLIST FORM VALUES', values)

    const notifyErrors = (erroris) => {
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))
      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const withDestroyedRows = () => {
      //cLog('EDITFORM', editForm)
      const { worklistRows } = worklist
      const worklistRowIds = (values.worklistRows && values.worklistRows.map(row => row.id)) || []
      const deletedWorklistRows = (worklistRows && worklistRows
        .filter(row => !worklistRowIds.includes(row.id))
        .map(row => ({ ...row, _destroy: true }))) || []
      //cLog('DELETED ROWS', deletedWorklistRows)
      return {
        ...values,
        worklistRows: values.worklistRows ? [...values.worklistRows.concat(deletedWorklistRows)] : []
        /*worklistRows: worklistRows ? [...values.worklistRows
          .map(row => ({
            ...row,
            hours: cleanFloat(row.hours)
          }))
          .concat(deletedWorklistRows)] : []*/
      }
    }

    const handleSubmitForm = async () => {
      const erroris = await validateForm()
      if (Object.keys(erroris).length > 0) {
        notifyErrors(erroris)
      } else {
        if (values.worklistRows.length === 0) {
          enqueueSnackbar(
            t('worklist.noRows'),
            { variant: 'error' }
          )
          return false
        }

        if (!validateAll(values, isAdmin)) {
          enqueueSnackbar(
            t('invoice.deficientRows'),
            { variant: 'error' }
          )
          return false
        }

        setLoading(true)
        if (edit) {
          cLog('UPDATING WORKLIST', { ...withDestroyedRows() })
          const request = updateWorklist({ ...withDestroyedRows() })
          request.then(response => {
              dispatch(reviseWorklist(response))
              enqueueSnackbar(
                t('worklist.listUpdated'),
                { variant: 'success' }
              )
              setLoading(false)
              history.push('/tyolistat')
            },
            error => {
              setLoading(false)
              cLog('SAVE WORKLIST ERROR:', error)
              if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0] === 'Already billed') {
                enqueueSnackbar(
                  t('worklist.troubleSavingBilled'),
                  { variant: 'error' }
                )
              } else {
                enqueueSnackbar(
                  t('worklist.troubleSaving'),
                  { variant: 'error' }
                )
                notifyOffice(error)
              }
            }
          )
        } else {
          cLog('SAVING NEW WORKLIST', values)
          const newWorklist = saveWorklist({
            ...values,
            worklistRows: values.worklistRows.map(row => ({
              ...row,
              hours: cleanFloat(row.hours)
            })),
            userId: (!isAdmin ? currentUser.id : values.userId)
          })
          newWorklist.then(response => {
              dispatch(addWorklist(newWorklist))
              enqueueSnackbar(
                t('worklist.listSaved'),
                { variant: 'success' }
              )
              setLoading(false)
              history.push('/tyolistat')
            },
            error => {
              setLoading(false)
              cLog('SAVE WORKLIST ERROR:', error)
              enqueueSnackbar(
                t('worklist.troubleSaving'),
                { variant: 'error' }
              )
              notifyOffice(error)
            }
          )
        }
      }
    }

    const History = () => {
      let collection = [];
      values.updateHistory.map((row, i) => {
        let name, filtered, joined;
        let changes = [];

        for (const [key, value] of Object.entries(row.changes)) {
          if (!['id', 'worklist_id'].includes(key)) {
            let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
            let value0 = value[0]
            let value1 = value[1]

            if (k === 'date') {
              if (value0) {
                value0 = formatTimeFI(value0)
              }
              if (value1) {
                value1 = formatTimeFI(value1)
              }
            } else if (k === 'status') {
              if (value0) {
                value0 = t('worklist.'+value0)
              }
              if (value1) {
                value1 = t('worklist.'+value1)
              }
            }

            if (value[0] || value[1]) {
              if (value0 === 'true' || value0 === true) {
                value0 = t('taxcard.true')
              } else if (value0 === 'false'|| value0 === false) {
                value0 = t('taxcard.false')
              } else if (!value0) {
                value0 = t('user.noValue')
              }

              if (value1 === 'true' || value1 === true) {
                value1 = t('taxcard.true')
              } else if (value1 === 'false' || value1 === false) {
                value1 = t('taxcard.false')
              } else if (!value1) {
                value1 = t('user.noValue')
              }

              if (row.event === 'create') {
                changes.push(t('worklist.'+k) + ': ' + value1)
              } else if (row.event === 'destroy') {
                changes.push(t('worklist.'+k) + ': ' + value0)
              } else {
                changes.push(t('worklist.'+k) + ': ' + value0 + ' -> ' + value1)
              }
            }
          }
        }

        if (changes.length) {
          if (row.firstName && row.lastName) {
            name = row.firstName + ' ' + row.lastName;
          } else if (row.firstName && !row.lastName) {
            name = row.firstName;
          } else if (!row.firstName && row.lastName) {
            name = row.lastName
          } else {
            name = 'Odealin järjestelmä'
          }

          let rowText = ''
          if (row.itemType !== "ActiveStorage::Attachment") {
            filtered = changes.filter(function (f) {
              return f != null;
            });
            joined = filtered.join(', ');

            if (row.itemType === "WorklistRow") {
              rowText = t('worklist.worklistRow') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
            }
          } else {
            rowText = t('invoice.attachment') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event)
            joined = ''
          }

          collection.push(<TableRow key={i}>
            <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{rowText + joined}</TableCell>
          </TableRow>)
        }

        return null
      })

      return (<div style={{ paddingBottom: '20px' }}>
        <br /><br />
        {t('worklist.historyTitle')}
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('customers.date')}</TableCell>
                <TableCell>{t('customers.user')}</TableCell>
                <TableCell>{t('customers.events')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collection}
            </TableBody>
          </Table>
        </div>
      </div>
      )
    }

    const fetchClientsByUserId = async (id) => {
      try {
        const usersClients = await getClientNames('?user_id=' + id)
        cLog('ALL USERS CLIENTS', usersClients)
        dispatch(setClients(usersClients))
      } catch (error) {
        cLog('ONGELMIA KÄYTTÄJÄN ASIAKKAIDEN HAKEMISESSA', error)
      }
    }

    return (
      <div>
        <form id="worklistForm" onSubmit={handleSubmit}>
          <div>
            <Hanuri>
              <HanuriTitle
                id={`worklistBody`}
                open={handleChangeExpansion(`worklistBody`)}
                label={t('worklist.basicInformation')}
                done={checkWorklistBodyErrors(values, isAdmin)}
                error={worklistBodyHasErrors && !checkWorklistBodyErrors(values, isAdmin)}
                expanded={expanded === `worklistBody`}
              />
              <HanuriBody expanded={expanded === `worklistBody`}>

                <p>{t('user.mandatoryFields')}</p>
                <Grid container spacing={4}>
                  {isAdmin && <Grid item xs={12} sm={3}>
                    <Field
                      name="userId"
                      id='userId'
                      label={values.userId ? t('invoice.biller') : t('invoice.chooseBiller')}
                      options={userOptions}
                      component={SelectAsync}
                      placeholder={t('invoice.typeToSearch')}
                      defaultValue={userOptions}
                      isDisabled={edit}
                      onChange={async (e) => {
                        setFieldValue('userId', e.value)
                        const thisUser = getUser(e.value)
                        thisUser.then(data => {
                          setUserData(data)
                          fetchClientsByUserId(e.value)
                        }, error => {
                          cLog('FETCH USER ERROR', error)
                        })
                      }}
                      validate={() => !values.userId && ({ key: t('invoice.billerMissing') })}
                      error={errors && errors.userId && !values.userId}
                    />
                  </Grid>}
                  {(!isAdmin || values.userId) && <Grid item xs={12} sm={!isAdmin ? 4 : 3}>
                    <Field
                      name="clientId"
                      id='clientId'
                      label={values.client
                        ? t('invoice.customer')+' *'
                        : t('invoice.chooseCustomer')+' *'}
                      options={clientOptions}
                      component={DropDown}
                      isDisabled={values.status !== 'LUONNOS'}
                      placeholder={t('invoice.pick')}
                      onChange={async (e) => {
                        const chosenClientId = e.value
                        setFieldValue('clientId', chosenClientId)
                      }}
                      validate={() => (!values.clientId && { key: t('invoice.customerMissing') })}
                      error={errors && errors.clientId && !values.clientId}
                    />
                  </Grid>}
                  <Grid item xs={12} sm={!isAdmin ? 4 : 3}>
                    <Field
                      name="name"
                      id='name'
                      type='text'
                      label={t('worklist.name')+' *'}
                      placeholder={t('worklist.name')}
                      value={values.name}
                      onChange={handleChange}
                      component={Input}
                      disabled={values.status !== 'LUONNOS'}
                      validate={() => validateName(values.name)}
                      error={errors.name && validateName(values.name)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={!isAdmin ? 4 : 3}>
                    <Field
                      name="totalHours"
                      id='totalHours'
                      type='text'
                      label={t('worklist.totalHours')}
                      placeholder={0}
                      value={values.totalHours}
                      component={Input}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </HanuriBody>
            </Hanuri>
          </div>
          {values.worklistRows &&
            <WorklistRow
              worklistRows={values.worklistRows}
              setFieldValue={setFieldValue}
              expanded={expanded}
              handleChangeExpansion={handleChangeExpansion}
              removeRow={removeRow}
              values={values}
            />}
          <div className={classes.buttonContainer}>
            {values.status === 'LUONNOS' && <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              onClick={handleSubmitForm}
            >
              {t('user.save')}
            </Button>}
            <Button
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={() => history.go(-1)}
            >
              {t('user.cancel')}
            </Button>
            {values.status === 'LUONNOS' && <Button
              disabled={isLoading}
              variant="contained"
              color="grey"
              onClick={() => addRow()}
            >
              {t('worklist.addRow')}
            </Button>}
            {isAdmin && values.id &&
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowHistory(!showHistory)}
              >
                {showHistory ? t('invoice.hideHistory') : t('invoice.showHistory')}
              </Button>}
          </div>
        </form>
        {isAdmin && showHistory && <div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <History />
            </Grid>
          </Grid>
        </div>}
      </div>
    )
  })

export default withSnackbar(WorklistForm)
