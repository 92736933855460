import React, { useState } from 'react'
import Input from './Input'
import { Box } from "@material-ui/core"
import Tip from './Tip'
import IconButton from "./IconButton"
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
//import { cLog } from '../utils'
import { validateFloatBetween, validateIntBetween } from '../screens/Invoice/validationSchemas'
import { Grid } from '@material-ui/core'
import { Field, FieldArray } from 'formik'
import { totalTravellingExpenceRow, totalTravellingExpenceRowKm } from '../utils/invoiceUtils'
import { useTranslation } from 'react-i18next'
import AutocompleteInput from "./AutocompleteInput"
import Alert from './Alert'

const TravelDestinations = ({ setFieldValue, arrayHelpers, travellingExpenceRows, isAdmin, i, handleAddDestination, handleDeleteDestination, allowNegative, calculateDistance,showKmInfoDialog, setShowKmInfoDialog, setFieldError }) => {
  const { t } = useTranslation()
  const [openKmInfoDialog, setOpenKmInfoDialog] = useState(false)

  return (
     <FieldArray
      name={`travellingExpenceRows.${i}.travelRowDestinations`}
      validateOnChange={false}
      validateOnBlur={false}
      render={arrHelpers => {
        return (
          <>            
            {travellingExpenceRows[i].travelRowDestinations.map((row2, i2) => {
              const errors2 = arrayHelpers.form.errors.travellingExpenceRows && arrayHelpers.form.errors.travellingExpenceRows[i] && arrayHelpers.form.errors.travellingExpenceRows[i].travelRowDestinations
                    ? arrayHelpers.form.errors.travellingExpenceRows[i].travelRowDestinations[i2]
                    : {}

              return (
                <Grid item xs={12} sm={12} key={`travelRowDestinations.${i}.${i2}`}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <AutocompleteInput
                        setFieldValue={setFieldValue}
                        i2={i2}
                        travellingExpenceRows={travellingExpenceRows}
                        arrayHelpers={arrayHelpers}
                        i={i}
                        row2={row2}
                        calculateDistance={calculateDistance}
                        setFieldError={setFieldError}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box display="flex" flexDirection="row" alignItems="flex-end">
                        <Field
                          name={`travellingExpenceRows.${i}.travelRowDestinations.${i2}.km`}
                          id={`travellingExpenceRows.${i}.travelRowDestinations.${i2}.km`}
                          label={!isAdmin ? t('invoice.km') : t('invoice.kilometer') + ` (${travellingExpenceRows[i].travelRowDestinations[i2].kmGoogle || 0})`}
                          labelcolor={isAdmin && travellingExpenceRows[i].travelRowDestinations[i2].km && travellingExpenceRows[i].travelRowDestinations[i2].kmGoogle && travellingExpenceRows[i].travelRowDestinations[i2].km !== travellingExpenceRows[i].travelRowDestinations[i2].kmGoogle ? 'error' : ''}
                          type='text'
                          component={Input}
                          value={i2 === 0 ? '0' : (travellingExpenceRows[i].travelRowDestinations[i2].km || '')}
                          onChange={(e) => {
                            setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.km`, e.target.value)
                            const trow = { ...travellingExpenceRows[i], destinations: travellingExpenceRows[i].travelRowDestinations.map((item, idx) => idx === i2 ? item.km = e.target.value : item) }
                            setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
                            setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
                          }}
                          onBlur={() => {
                            if (!isAdmin && !showKmInfoDialog && parseFloat(travellingExpenceRows[i].travelRowDestinations[i2].km.toString().replace(",", ".")) > travellingExpenceRows[i].travelRowDestinations[i2].kmGoogle) {
                              setShowKmInfoDialog(true)
                              setOpenKmInfoDialog(true)
                            }
                          }}
                          disabled={i2 === 0}
                          validate={() => (i2 === 0 && validateIntBetween(row2.km, 0, 0, t('invoice.km'), false)) || (i2 > 0 && validateFloatBetween(row2.km, (allowNegative ? -9999 : 0), 9999, t('invoice.km'), false, 1))}
                          error={errors2 && errors2.km && ((i2 === 0 && validateIntBetween(row2.km, 0, 0, t('invoice.km'), false)) || (i2 > 0 && validateFloatBetween(row2.km, (allowNegative ? -9999 : 0), 9999, t('invoice.km'), false, 1)))}
                        />
                        <Tip title={t('invoice.addDestinationTip')}>
                          <IconButton style={{marginLeft: '0.5rem'}} onClick={() => handleAddDestination(i, i2, arrayHelpers)}>
                            <AddIcon/>
                          </IconButton>
                        </Tip>
                        <Tip title={t('invoice.deleteDestinationTip')}>
                          <IconButton style={{marginLeft: '0.5rem'}} onClick={() => handleDeleteDestination(i, i2, arrayHelpers)}>
                            <DeleteIcon/>
                          </IconButton>
                        </Tip>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
            <Alert
              open={openKmInfoDialog}
              setOpen={setOpenKmInfoDialog}
              handleClose={() => { setOpenKmInfoDialog(false) }}
              handleAgree={() => { setOpenKmInfoDialog(false) }}
              alertMessage={{ title: t('invoice.destinationKmDifferentTitle'), body: t('invoice.destinationKmDifferentBody') }}
              hideClose={true}
            />
          </>
        )
      }}
    />
  )
}

export default TravelDestinations
