import React, { useState } from 'react'
import { Field } from 'formik'
import { MaterialUiCheckBox } from '../../components/MaterialUiCheckBox'
import Button from '../../components/Button'
import { Radio, Grid, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { purple } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import TextArea from '../../components/TextArea'
import { formatDate } from '../../utils'
import { cLog } from '../../utils'
import { saveReference } from '../../service'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  }
}))

export const MaterialRadio = withStyles({
  root: {
    color: purple['A700']
  },
  checked: {}
})(props => <Radio color="default" {...props} />);

const ReferenceForm = React.forwardRef(
  (props, ref) => {
    const {
      values,
      setFieldValue,
      validateForm,
      errors,
      enqueueSnackbar,
      fetchData
    } = props

    const { t } = useTranslation()
    const classes = useStyles()
    const [isLoading, setLoading] = useState(false)

    const validateDateStartWork = (value) => {
      if (!value || String(value).length === 0) {
        return { key: t('reference.startDateMissing') }
      }
      if (new Date(value).withoutTime() > new Date().withoutTime()) {
        return { key: t('reference.startDateInFuture') }
      }
    }

    const validateDateEndWork = (value, dateStartWork) => {
      if (!value || String(value).length === 0) {
        return { key: t('reference.endDateMissing') }
      }
      if (new Date(value).withoutTime() > new Date().withoutTime()) {
        return { key: t('reference.endDateInFuture') }
      }
      if (new Date(value).withoutTime() < new Date(dateStartWork).withoutTime()) {
        return { key: t('reference.endDateBeforeStartDate') }
      }
    }

    const notifyErrors = erroris => {
      cLog('NOTIFY REFERENCE FORM ERRORS', erroris)
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))

      cLog('ERRORI TYYPIT', errorTypes)

      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const handleSubmitForm = async () => {
      const erroris = await validateForm()
      cLog('CLIENT FORM ERRORS', erroris)
      /* if (Object.keys(erroris).length > 0) { notifyErrors(erroris) }  //// TÄMÄ TESTAUSTA VARTEN
      return */
      if (Object.keys(erroris).length === 0) {
        let generalErrorMessage;

        if (!values.isWorkReference && !values.isSalaryReference) {
          generalErrorMessage = t('reference.noReferenceError')
        }

        if (generalErrorMessage) {
          enqueueSnackbar(
            generalErrorMessage,
            { variant: 'error' }
          )
          return
        }

        setLoading(true)
        const newReference = saveReference({ ...values, status: 'TARKASTETTAVANA' })
        newReference.then(response => {
          cLog('TODISTUSPYYNTÖ LÄHETETTY ONNISTUNEESTI', newReference)
          enqueueSnackbar(
            t('reference.referenceSent'),
            { variant: 'success' }
          )
          setLoading(false)
          fetchData()
        }, error => {
          setLoading(false)
          cLog('VAIKEUKSIA TODISTUSPYYNNÖN LÄHETTÄMISESSÄ', error)
          enqueueSnackbar(
            t('reference.problemsSending'),
            { variant: 'error' }
          )
        })
      } else {
        cLog('ERROREITA PIISAA', erroris)
        notifyErrors(erroris)
      }
    }

    return (
      <div className={classes.root}>
        <form>
          <div>
            {t('reference.processTitle1')}<b>{t('reference.processTitle2')}</b><br/><br/>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}><b>{t('reference.desiredReport')}</b></Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name="isWorkReference"
                  id='isWorkReference'
                  component={MaterialUiCheckBox}
                  checked={values.isWorkReference}
                  onChange={() => setFieldValue('isWorkReference', !values.isWorkReference)}
                />
                <span>{t('reference.workReport')}</span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name="isSalaryReference"
                  id='isSalaryReference'
                  component={MaterialUiCheckBox}
                  checked={values.isSalaryReference}
                  onChange={() => setFieldValue('isSalaryReference', !values.isSalaryReference)}
                />
                <span>{t('reference.salaryReport')}</span>
              </Grid>
              <Grid item xs={12} sm={12}><b>{t('reference.deliveryMethod')}</b></Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name="sendByEmail"
                  id='sendByEmail'
                  component={MaterialRadio}
                  checked={values.sendByEmail}
                  onChange={() => setFieldValue('sendByEmail', !values.sendByEmail)}
                />
                <span>{t('reference.byEmail')}</span>
              </Grid>
              <Grid item xs={12} sm={12}><b>{t('reference.desiredPeriod')}</b></Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name="lastSeason"
                  id='lastSeason'
                  component={MaterialRadio}
                  checked={!values.customPeriod}
                  onChange={() => setFieldValue('customPeriod', !values.customPeriod)}
                />
                <span>{t('reference.lastSalaryPeriod')}</span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Field
                  name="customPeriod"
                  id='customPeriod'
                  component={MaterialRadio}
                  checked={values.customPeriod}
                  onChange={() => setFieldValue('customPeriod', !values.customPeriod)}
                />
                <span>{t('reference.selectedPeriod')}</span>
              </Grid>
              {values.customPeriod && <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={2}>
                  <Field
                    name="dateStartWork"
                    id="dateStartWork"
                    label={t('reference.startDate')}
                    component={DPicker}
                    selected={values.dateStartWork ? new Date(values.dateStartWork) : ''}
                    onChange={(date) => setFieldValue('dateStartWork', date ? formatDate(date) : '')}
                    validate={() => validateDateStartWork(values.dateStartWork)}
                    error={errors && errors.dateStartWork && validateDateStartWork(values.dateStartWork)}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <Field
                    name="dateEndWork"
                    id="dateEndWork"
                    label={t('reference.endDate')}
                    component={DPicker}
                    selected={values.dateEndWork ? new Date(values.dateEndWork) : ''}
                    onChange={(date) => setFieldValue('dateEndWork', date ? formatDate(date) : '')}
                    validate={() => validateDateEndWork(values.dateEndWork, values.dateStartWork)}
                    error={errors && errors.dateEndWork && validateDateEndWork(values.dateEndWork, values.dateStartWork)}
                  />
                </Grid>
              </Grid>}
              <Grid item xs={12} sm={12}><b>{t('reference.explanations')} {`${values.explanations.length}/${5000}`}</b></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Field
                  name='explanations'
                  id='explanations'
                  type="text"
                  component={TextArea}
                  placeholder={t('reference.explanationsFieldText')}
                  value={values.explanations}
                  onChange={(e) => {
                    setFieldValue('explanations', e.target.value)
                  }}
                  error={errors && errors.explanations && (values.explanations && values.explanations.length > 5000)}
                  validate={() => values.explanations && values.explanations.length > 5000 && { key: t('reference.explanationError') }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitForm}
                >
                  {isLoading ? <CircularProgress size={20} /> : t('reference.order')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </div>
    )
  }
)

export default withSnackbar(ReferenceForm)
