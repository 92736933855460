import React, { useState, useEffect, useImperativeHandle } from 'react'
import { Field, } from 'formik'
import { CLIENT_FORM_TEMPLATE, EINVOICE_OPERATORS, PAYMENT_TERMS_OPTIONS } from '../../utils/invoiceUtils'
import BusinessSelect from '../../components/BusinessSelect'
import { MaterialUiCheckBox } from '../../components/MaterialUiCheckBox'
import Input from '../../components/Input'
import SelectAsync from '../../components/SelectAsync'
import DropDown from '../../components/DropDown'
import Button from '../../components/Button'
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { formatDatetime } from '../../utils'
import { saveClient, updateClient } from '../../service'
import { addClient, reviseClient } from '../../state'
import { useStateValue } from "../../state"
import { useHistory } from 'react-router-dom'
import { withSnackbar } from 'notistack'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { COUNTRIES } from '../../utils/countries'
import { LANGUAGES } from '../../utils/languages'
import { validateUser, validateName, validateAddress1, validateAddress2, validatePostalCode, validateCity, validateEmail, validatePhone, validateEinvoiceOperator, validateEinvoiceAddress, validateBusinessId, validateContactPerson, validateInternalNotes } from './clientValidations'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import TextArea from '../../components/TextArea'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  }
}))

const ClientForm = React.forwardRef(
  (props, ref) => {
    const {
      invoiceRef,
      values,
      setFieldValue,
      editClientForm,
      setEditClientForm,
      handleChange,
      resetForm,
      dirty,
      errors,
      validateForm,
      isFromClient,
      openClientModal,
      setOpenClientModal,
      modalForm,
      enqueueSnackbar,
      invoiceId,
      userData
    } = props

    const { t } = useTranslation()
    const [clientNameOption, setClientNameOption] = useState({ label: values.name, value: values.name })
    const [addressOptions, setAddressOptions] = useState([])
    const [fullAddresses, setFullAddresses] = useState([])
    const [isSaving, setSaving] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [{ currentUser }, dispatch] = useStateValue()
    let history = useHistory()
    const classes = useStyles()
    const isAdmin = currentUser && currentUser.role === 'ADMIN'
    const [showHistory, setShowHistory] = useState()

    let userOptions = []
    if (userData) {
      userOptions = [{ label: `${userData.firstName} ${userData.lastName} (${userData.email})`.trim(), value: userData.id }]
    } else if (values.userId) {
      userOptions = [{ label: `${values.userFirstName} ${values.userLastName} (${values.userEmail})`.trim(), value: values.userId }]
    }
    const languageOptions = Object.keys(LANGUAGES).map(lang => ({ label: t('customers.'+lang), value: LANGUAGES[lang] }))
    const countryOptions = Object.keys(COUNTRIES).map(country => ({ label: t('countries.'+COUNTRIES[country]), value: COUNTRIES[country] }))
    const deliveryOptions = ['POSTI', 'SÄHKÖPOSTI', 'VERKKOLASKU', 'EI_LÄHETETÄ'].map(option => ({ label: t('invoice.'+option.replace(/_/g, ' ')), value: option, isDisabled: (!isAdmin && option === 'POSTI' && !values.isPrivate) || (!isAdmin && option === 'EI_LÄHETETÄ') }))
    const reminderOptions = ['AUTOMAATTINEN', 'EI_MUISTUTETA'].map(option => ({ label: t('invoice.'+option.replace(/_/g, ' ')), value: option }))
    const eInvoiceOptions = EINVOICE_OPERATORS.map(operator => ({ label: operator, value: operator }))
    const paymentTermsOptions = PAYMENT_TERMS_OPTIONS.map(option => ({ label: option, value: option }))

    const isFinland = values.country === 'FINLAND'
    values.invoiceId = parseInt(invoiceId)
    
    cLog('CLIENT FORM VALUES', values)
    //cLog('CLIENT FORM ERRORS', errors)

    useEffect(() => {
      if (dirty && values.isPrivate) {
        setFieldValue('contactPerson', '')
      }
    }, [dirty, values.isPrivate, setFieldValue])

    useEffect(() => {
      if (dirty && !values.isPrivate) {
        if (fullAddresses.length > 0) {
          //cLog('ADDRESS OPTIONS', addressOptions, fullAddresses)
          const thisAddress = fullAddresses.find(option => option.street === values.address1)
          if (thisAddress) {
            setFieldValue('city', thisAddress.city)
            setFieldValue('postalCode', thisAddress.postCode)
            setFieldValue('address2', '')
          }
        }
      }
    }, [dirty, values.isPrivate, fullAddresses, values.address1, setFieldValue])

    useEffect(() => {
      // onko tarpeeton, dropdownit tyhjiä kun aloittaa editoinnin?
      if (dirty) {
        setFullAddresses([])
        setAddressOptions([])
        if (!values.isPrivate && values.name) {
          setClientNameOption({ label: values.name, value: values.name })
        } else {
          setClientNameOption([])
        }
      }
    }, [dirty, values.isPrivate, values.name, values.businessId])

    useEffect(() => {
      if (isSaving && errors) {
        validateForm()
      }
    }, [errors, isSaving, validateForm])

    useImperativeHandle(ref, () => ({
      setField: (name, value) => {
        setFieldValue(name, value)
      },
      sayHello: () => alert('Clientistä  hellouta'),
      getValues: () => values,
      resetForm: template => {
        resetForm({ values: { ...template } })
      }
    }))

    const notifyOffice = (error) => {
      if (isAdmin) {
        if (error && error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.forEach(message => {
            enqueueSnackbar(
              message,
              { variant: 'error' }
            )
          })
        }
      }
    }

    const notifyErrors = erroris => {
      cLog('NOTIFY CLIENT FORM ERRORS', erroris)
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))

      cLog('ERRORI TYYPIT', errorTypes)

      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const handleSubmitForm = async () => {
      setSaving(true)

      const erroris = await validateForm()
      cLog('CLIENT FORM ERRORS', erroris)
      /* if (Object.keys(erroris).length > 0) { notifyErrors(erroris) }  //// TÄMÄ TESTAUSTA VARTEN
      return */
      if (Object.keys(erroris).length === 0) {
        setSaving(false)
        let freshClient;
        try {
          setLoading(true)
          if (!editClientForm || !values.id) {                  ///// JOS UUSI TAI EI PÄIVITYS
            cLog('SAVING NEW CLIENT', values)
            freshClient = await saveClient({
              ...values,
              userId: isFromClient ? (!isAdmin ? currentUser.id : values.userId) : invoiceRef.current.getUserId()
            })
            cLog('FRESH NEW CLIENT', freshClient)
            enqueueSnackbar(
              t('customers.newSaved'),
              { variant: 'success' }
            )
            dispatch(addClient(freshClient))
          } else {                                                ///// JOS PÄIVITYS
            cLog('PÄIVITETÄÄN CLIENT')
            freshClient = await updateClient(values)
            cLog('FRESH UPDATED CLIENT', freshClient)
            enqueueSnackbar(
              t('customers.customerUpdated'),
              { variant: 'success' }
            )
            dispatch(reviseClient(freshClient))
          }
          
          if (isFromClient) {
            history.push('/asiakkaat')
          } else {
            setLoading(false)
            invoiceRef.current.setField('client', freshClient)
            invoiceRef.current.setField('deliveryMethod', freshClient.deliveryMethod)
            if (invoiceRef.current.getPayDay() !== 'HETI') {
              invoiceRef.current.setField('reminderHandling', freshClient.reminderHandling)
            }
            invoiceRef.current.setField('einvoiceOperator', freshClient.einvoiceOperator)
            invoiceRef.current.setField('einvoiceAddress', freshClient.einvoiceAddress)
            if (!invoiceRef.current.getValues().dateDue) {
              let dateDue = new Date(invoiceRef.current.getValues().date)
              dateDue.setDate(dateDue.getDate() + parseInt(freshClient.paymentTerms))
              invoiceRef.current.setField('dateDue', dateDue)
            }
            resetForm({ values: CLIENT_FORM_TEMPLATE })
            setOpenClientModal(false)
            setEditClientForm(false)
          }
        } catch (error) {
          setLoading(false)
          cLog('SAVE CLIENT ERROR:', error)
          enqueueSnackbar(
            t('customers.troubleSaving'),
            { variant: 'error' }
          )
          notifyOffice(error)
        }
      } else {
        cLog('ERROREITA PIISAA', erroris)
        notifyErrors(erroris)
      }
    }

    const handleCancel = () => {
      if (isFromClient) {
        history.go(-1)
      } else {
        if (editClientForm) {
          setEditClientForm(false)
        } else {
          invoiceRef.current.setField('client', null)
        }
        //cLog('RESET CLIENT FORM')
        resetForm({ values: CLIENT_FORM_TEMPLATE })
        setOpenClientModal(false)
        window.scroll({
          top: 100,
          left: 100,
          behavior: 'smooth'
        });
      }
    }

    const History = () => {
      let collection = [];
      values.updateHistory.map((row, i) => {
        let name, filtered, joined;
        let changes = [];

        for (const [key, value] of Object.entries(row.changes)) {
          if (!['id'].includes(key)) {
            let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
            let value0 = value[0]
            let value1 = value[1]

            if (value[0] || value[1]) {
              if (k === 'address1') {
                k = 'address'
              } else if (k === 'address2') {
                k = 'specifier'
              } else if (k === 'einvoiceOperator') {
                k = 'eInvoiceOperator'
              } else if (k === 'einvoiceAddress') {
                k = 'eInvoiceAddress'
              } else if (k === 'hasCustomBusinessId') {
                k = 'hasCustomBusinessId'
              } else if (k === 'reminderHandling') {
                if (value0) {
                  value0 = t('invoice.'+value0.replace(/_/g, ' '))
                }
                if (value1) {
                  value1 = t('invoice.'+value1.replace(/_/g, ' '))
                }
              } else if (k === 'country') {
                if (value0) {
                  value0 = t('countries.'+value0)
                }
                if (value1) {
                  value1 = t('countries.'+value1)
                }
              } else if (k === 'language') {
                if (value0) {
                  value0 = t('user.'+value0)
                }
                if (value1) {
                  value1 = t('user.'+value1)
                }
              } else if (k === 'deliveryMethod') {
                if (value0) {
                  value0 = t('invoice.'+value0)
                }
                if (value1) {
                  value1 = t('invoice.'+value1)
                }
              }

              if (value0 === 'true' || value0 === true) {
                value0 = t('taxcard.true')
              } else if (value0 === 'false'|| value0 === false) {
                value0 = t('taxcard.false')
              } else if (!value0) {
                value0 = t('user.noValue')
              }

              if (value1 === 'true' || value1 === true) {
                value1 = t('taxcard.true')
              } else if (value1 === 'false' || value1 === false) {
                value1 = t('taxcard.false')
              } else if (!value1) {
                value1 = t('user.noValue')
              }

              if (k === 'internalNotes') {
                if (row.event === 'create') {
                  changes.push(t('invoice.'+k) + ': ' + value1)
                } else if (row.event === 'destroy') {
                  changes.push(t('invoice.'+k) + ': ' + value0)
                } else {
                  changes.push(t('invoice.'+k) + ': ' + value0 + ' -> ' + value1)
                }
              } else {
                if (row.event === 'create') {
                  changes.push(t('customers.'+k) + ': ' + value1)
                } else if (row.event === 'destroy') {
                  changes.push(t('customers.'+k) + ': ' + value0)
                } else {
                  changes.push(t('customers.'+k) + ': ' + value0 + ' -> ' + value1)
                }
              }
            }
          }
        }

        if (changes.length) {
          if (row.firstName && row.lastName) {
            name = row.firstName + ' ' + row.lastName;
          } else if (row.firstName && !row.lastName) {
            name = row.firstName;
          } else if (!row.firstName && row.lastName) {
            name = row.lastName
          } else {
            name = 'Odealin järjestelmä'
          }

          let rowText = ''
          if (row.itemType !== "ActiveStorage::Attachment") {
            filtered = changes.filter(function (f) {
              return f != null;
            });
            joined = filtered.join(', ');

            if (row.itemType === "Client" && row.event === 'create') {
              rowText = t('customers.client') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
            }
          }

          collection.push(<TableRow key={i}>
            <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{rowText + joined}</TableCell>
          </TableRow>)
        }

        return null
      })

      return (<div style={{ paddingBottom: '20px' }}>
        <br /><br />
        {t('customers.historyTitle')}
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('customers.date')}</TableCell>
                <TableCell>{t('customers.user')}</TableCell>
                <TableCell>{t('customers.events')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collection}
            </TableBody>
          </Table>
        </div>
      </div>
      )
    }

    const formBody = () => (
      <div>
        <form>
          <div className={classes.formBody}>
            {/* <p>Hae asiakasta YTJ-tietopalvelusta nimellä tai Y-tunnuksella</p> */}
            {/*    <p>Jos asiakkaalla ei ole Y-tunnusta, valitse YKSITYINEN</p> */}
            <p>{t('user.mandatoryFields')}</p>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <MaterialUiCheckBox
                  name='isPrivate'
                  onChange={() => {
                    if (values.isPrivate) {
                      setFieldValue('deliveryMethod', 'VERKKOLASKU')
                    }
                    setFieldValue('isPrivate', !values.isPrivate)
                    setFieldValue('hasCustomBusinessId', true)
                  }}
                  checked={values.isPrivate || false}
                />
                <span style={{ paddingTop: '1px', marginLeft: '5px' }} >{t('customers.private')}</span> </Grid>
              {!values.isPrivate && isFinland && <Grid item xs={12} sm={6}>
                <MaterialUiCheckBox
                  name='hasCustomBusinessId'
                  onChange={() => setFieldValue('hasCustomBusinessId', !values.hasCustomBusinessId)}
                  checked={!values.hasCustomBusinessId}
                />
                <span style={{ paddingTop: '1px', marginLeft: '5px' }} >{t('customers.searchFromYtj')}</span>
              </Grid>}
              {!values.isPrivate && isFinland && <Grid item xs={12} sm={6}>
                <MaterialUiCheckBox
                  name='hasCustomBusinessId'
                  onChange={() => setFieldValue('hasCustomBusinessId', !values.hasCustomBusinessId)}
                  checked={values.hasCustomBusinessId}
                />
                <span style={{ paddingTop: '1px', marginLeft: '5px' }} >{t('customers.inputManually')}</span>
              </Grid>}
              {isAdmin && <Grid item xs={12} sm={6}>
                <Field
                  name="userId"
                  id="userId"
                  label={t('customers.biller')}
                  component={SelectAsync}
                  placeholder={t('invoice.typeToSearch')}
                  defaultValue={userOptions}
                  onChange={(e => setFieldValue('userId', e.value))}
                  isDisabled={userData}
                  validate={() => validateUser(values.userId)}
                  error={errors && errors.userId && validateUser(values.userId)}
                />
              </Grid>}
            </Grid>

            {!values.isPrivate ?
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  {values.hasCustomBusinessId
                    ?
                    <Field
                      name="name"
                      id="name"
                      type="text"
                      component={Input}
                      value={values.name || ''}
                      onChange={handleChange}
                      label={t('customers.customerName')+' *'}
                      placeholder={t('customers.customerName')}
                      validate={() => validateName(values.name)}
                      error={errors && errors.name && validateName(values.name)}
                    />
                    :
                    <Field
                      name="name"
                      id='name'
                      component={BusinessSelect}
                      clientNameOption={clientNameOption}
                      setClientNameOption={setClientNameOption}
                      setAddressOptions={setAddressOptions}
                      setFullAddresses={setFullAddresses}
                      placeholder={t('customers.customerName')}
                      label={t('customers.customerName')}
                      info={t('customers.nameTip')}
                      setFieldValue={setFieldValue}
                      validate={() => validateName(values.name, true)}
                      error={errors && errors.name && validateName(values.name)}
                    />
                  }
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="businessId"
                    id="businessId"
                    type="text"
                    component={Input}
                    disabled={!values.hasCustomBusinessId}
                    value={values.businessId || ''}
                    onChange={handleChange}
                    placeholder={t('customers.businessId')}
                    label={t('customers.businessId')+' *'}
                    validate={() => !values.isPrivate && validateBusinessId(values.businessId, (values.country === 'FINLAND'))}
                    error={errors && errors.businessId && !values.isPrivate && validateBusinessId(values.businessId, (values.country === 'FINLAND'))}
                  />
                </Grid>
              </Grid>
              :
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <Field
                    name="name"
                    id="name"
                    type="text"
                    component={Input}
                    value={values.name || ''}
                    onChange={handleChange}
                    label={t('customers.name')+' *'}
                    validate={() => validateName(values.name)}
                    error={errors && errors.name && validateName(values.name)}
                  />
                </Grid>
              </Grid>}
            <Grid container spacing={4}>
              {addressOptions.length < 2 || values.isPrivate
                ?
                <Grid item xs={12} sm={6}>
                  <Field
                    name="address1"
                    id="address1"
                    type="text"
                    component={Input}
                    onChange={handleChange}
                    value={values.address1 || ''}
                    label={t('customers.address')+' *'}
                    placeholder={t('customers.address')}
                    validate={() => validateAddress1(values.address1, values.name)}
                    error={errors && errors.address1 && validateAddress1(values.address1, values.name)}
                  />
                </Grid>
                :
                <Grid item xs={12} sm={6}>
                  <Field
                    name="address1"
                    id="address1"
                    options={addressOptions}
                    onChange={(e => setFieldValue('address1', e.value))}
                    component={DropDown}
                    label={t('customers.address1')+' *'}
                    error={errors && errors.address1 && !values.address1}
                  />
                </Grid>}
              <Grid item xs={12} sm={6}>
                <Field
                  name="address2"
                  id="address2"
                  type="text"
                  component={Input}
                  onChange={handleChange}
                  value={values.address2 || ''}
                  label={t('customers.address2')}
                  placeholder={t('customers.address2')}
                  validate={() => validateAddress2(values.address2)}
                  error={errors && errors.address2 && validateAddress2(values.address2)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="postalCode"
                  id="postalCode"
                  type="text"
                  component={Input}
                  value={values.postalCode || ''}
                  label={t('customers.postalCode')+' *'}
                  placeholder={t('customers.postalCode')}
                  onChange={(e) => {
                    if (String(e.target.value).trim().length < 21) {
                      setFieldValue('postalCode', e.target.value)
                    }
                  }}
                  validate={() => validatePostalCode(values.postalCode, values.name)}
                  error={errors && errors.postalCode && validatePostalCode(values.postalCode, values.name)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="city"
                  id="city"
                  type="text"
                  component={Input}
                  onChange={handleChange}
                  value={values.city || ''}
                  label={t('customers.city')+' *'}
                  placeholder={t('customers.city')}
                  validate={() => validateCity(values.city, values.name)}
                  error={errors && errors.city && validateCity(values.city, values.name)}

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  id="country"
                  options={countryOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                  onChange={(e) => {
                    setFieldValue('country', e.value)
                    if (e.value !== 'FINLAND') {
                      setFieldValue('hasCustomBusinessId', true)
                    } else {
                      setFieldValue('hasCustomBusinessId', false)
                    }
                  }}
                  label={t('customers.country')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="email"
                  id="email"
                  type="text"
                  component={Input}
                  onChange={handleChange}
                  value={values.email || ''}
                  label={t('customers.email') + (values.deliveryMethod === 'SÄHKÖPOSTI' ? ' *' : '')}
                  placeholder={t('customers.email')}
                  validate={() => validateEmail(values.email, values.deliveryMethod)}
                  error={errors && errors.email && validateEmail(values.email, values.deliveryMethod)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="phone"
                  id="phone"
                  type="text"
                  component={Input}
                  onChange={handleChange}
                  value={values.phone || ''}
                  label={t('customers.phoneNumber')}
                  placeholder={t('customers.phoneNumber')}
                  validate={() => validatePhone(values.phone)}
                  error={errors && errors.phone && validatePhone(values.phone)}
                />
              </Grid>
              {!values.isPrivate && <Grid item xs={12} sm={6}>
                <Field
                  name="contactPerson"
                  id="contactPerson"
                  type="text"
                  component={Input}
                  onChange={handleChange}
                  value={values.contactPerson || ''}
                  label={t('customers.contactPerson')}
                  placeholder={t('customers.contactPerson')}
                  validate={() => validateContactPerson(values.contactPerson)}
                  error={errors && errors.contactPerson && validateContactPerson(values.contactPerson)}
                />
              </Grid>}
              <Grid item xs={12} sm={6}>
                <Field
                  name="language"
                  id="language"
                  info={t('customers.languageTip')}
                  options={languageOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                  onChange={(e) => setFieldValue('language', e.value)}
                  label={t('customers.language')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="deliveryMethod"
                  id='deliveryMethod'
                  label={t('customers.deliveryMethod')}
                  info={t('customers.deliveryMethodTip')}
                  value={values.deliveryMethod || ''}
                  onChange={(e) => setFieldValue('deliveryMethod', e.value)}
                  options={deliveryOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                />
              </Grid>
              {values.deliveryMethod === 'VERKKOLASKU' && (isAdmin || (values.einvoiceOperator && values.einvoiceAddress)) &&
                <>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="einvoiceOperator"
                      id='einvoiceOperator'
                      label={t('customers.eInvoiceOperator')}
                      value={values.einvoiceOperator || ''}
                      onChange={(e) => setFieldValue('einvoiceOperator', e.value)}
                      options={eInvoiceOptions}
                      isSearchable={!isMobile}
                      placeholder={t('invoice.pick')}
                      component={DropDown}
                      validate={() => (validateEinvoiceOperator(values.einvoiceOperator, values.deliveryMethod, isAdmin))}
                      error={errors && errors.einvoiceOperator && validateEinvoiceOperator(values.einvoiceOperator, values.deliveryMethod, isAdmin)}
                      isDisabled={!isAdmin}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="einvoiceAddress"
                      id='einvoiceAddress'
                      label={t('customers.eInvoiceAddress')}
                      info={t('customers.eInvoiceAddressTip')}
                      value={values.einvoiceAddress || ''}
                      onChange={(e) => setFieldValue('einvoiceAddress', e.target.value)}
                      placeholder={t('customers.eInvoiceAddress')}
                      component={Input}
                      validate={() => (validateEinvoiceAddress(values.einvoiceAddress, values.deliveryMethod, isAdmin))}
                      error={errors && errors.einvoiceAddress && validateEinvoiceAddress(values.einvoiceAddress, values.deliveryMethod, isAdmin)}
                      disabled={!isAdmin}
                    />
                  </Grid>
                </>}
              <Grid item xs={12} sm={6}>
                <Field
                  name="reminderHandling"
                  id='reminderHandling'
                  label={t('customers.reminderHandling')}
                  info={t('customers.reminderHandlingTip')}
                  value={values.reminderHandling || ''}
                  onChange={(e) => setFieldValue('reminderHandling', e.value)}
                  options={reminderOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="paymentTerms"
                  id='paymentTerms'
                  label={t('customers.paymentTerms')}
                  info={t('customers.paymentTermsTip')}
                  value={values.paymentTerms || ''}
                  onChange={(e) => setFieldValue('paymentTerms', e.value)}
                  options={paymentTermsOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                  placeholder={t('invoice.pick')}
                  menuPlacement='top'
                />
              </Grid>
              {isAdmin &&
                <Grid item xs={12} sm={6}>
                  <Field
                    name="internalNotes"
                    id="internalNotes"
                    label={t('invoice.internalNotes')}
                    component={TextArea}
                    value={values.internalNotes || ''}
                    onChange={(e) => {
                      if (String(e.target.value).trim().length <= 10000 || String(e.target.value).trim().length < values.internalNotes.length) {
                        setFieldValue('internalNotes', e.target.value)
                      }
                    }}
                    error={errors && errors.internalNotes && isAdmin && validateInternalNotes(values.internalNotes)}
                    validate={() => (isAdmin && validateInternalNotes(values.internalNotes))}
                  />
                </Grid>}

              {isFromClient && <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={handleSubmitForm}
                >
                  {t('customers.save')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancel}
                >
                  {t('customers.cancel')}
                </Button>
                {isFromClient && isAdmin && values.id && values.updateHistory && 
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowHistory(!showHistory)}
                  >
                    {showHistory ? t('invoice.hideHistory') : t('invoice.showHistory')}
                  </Button>}
              </Grid>}
            </Grid>
          </div>
        </form>

        {isFromClient && isAdmin && showHistory && <div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <History />
            </Grid>
          </Grid>
        </div>}
      </div>
    )

    return (
      <>
        {modalForm
          ? <Dialog
            fullWidth
            maxWidth='md'
            scroll='paper'
            open={openClientModal}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                setOpenClientModal(false)
              }
            }}
            disableEscapeKeyDown={true}
            aria-labelledby="client-dialog-title"
            aria-describedby="client-dialog-description"
          >
            <DialogTitle id="client-dialog-title">{editClientForm ? t('customers.modifyCustomer') : t('customers.addNewCustomer')}</DialogTitle>
            <DialogContent dividers={true} id="client-dialog-description">
              {formBody()}
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                onClick={handleCancel}
                color="secondary"
                disabled={isLoading}
              >
                {t('customers.cancel')}
              </Button>
              <Button
                variant="text"
                onClick={handleSubmitForm}
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={20} /> : t('customers.save')}
              </Button>
            </DialogActions>
          </Dialog>
          : formBody()}
      </>
    );
  })

export default withSnackbar(ClientForm)
