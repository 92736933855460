import React, { useState, useEffect } from 'react'
import Button from '../../components/Button'
import Dropzone from '../../components/Dropzone'
import { withSnackbar } from 'notistack'
import { saveAttachment, getUser } from '../../service'
import { useStateValue, setCurrentUser } from "../../state"
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"
import Alert from '../../components/Alert'

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '640px',

    '& > *': {
      marginBottom: theme.spacing(6),
      '& > p': {
        margin: 0,
        paddingBottom: theme.spacing(1),
      },
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  buttonContainer: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  thumbnail: {
    maxWidth: '100%',
  },
}))

const INIT_FORM = {
  dateStart: + new Date().getFullYear() + '-02-01',
}

const TaxCard = ({ enqueueSnackbar }) => {
  const { t } = useTranslation();
  const [taxCardFile, setTaxCardFile] = useState(null)
  const [form, setForm] = useState(INIT_FORM)
  const [user, setUser] = useState(null)
  const [{ currentUser }, dispatch] = useStateValue()
  const [isLoading, setLoading] = useState(false)
  const [openConfirmSendTaxcardDialog, setOpenConfirmSendTaxcardDialog] = useState(false)
  let { id } = useParams();
  const classes = useStyles();
  let history = useHistory();
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  let isImage = false
  if (taxCardFile && taxCardFile.filename) {
    //cLog('PÄÄTE', taxCardFile.filename.split('.')[1])
    isImage = ['jpg', 'jpeg', 'JPG', 'tif', 'tiff', 'png', 'bmp', 'gif',].includes(taxCardFile.filename.split('.')[1])
  }
  ///const isImage = taxCardFile && taxCardFile.fileName && ['jpg', 'jpeg', 'tif', 'tiff', 'png', 'bmp', 'gif',].includes(taxCardFile.fileName.split('.')[1])
  //cLog('IS IMAGE', isImage)

  useEffect(() => {
    const fetchData = async () => {
      const thisUser = getUser(id)
      thisUser.then(userData => {
        setUser(userData)
        dispatch(setCurrentUser(userData))
      }, error => {
        history.push('/')
      })
    }

    if (!isAdmin) {
      fetchData()
    }
  }, [id, isAdmin, dispatch, history])

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  cLog('USERI VEROKORTISSA', user)

  const handleSaveTaxCard = async () => {
    cLog('HANDLE SAVE TAX CARD', taxCardFile)

    if (!taxCardFile) {
      enqueueSnackbar(
        t('taxcard.fileIsMissing'),
        { variant: 'error' }
      )
      return
    }
    if (!form.dateStart || String(form.dateStart).length === 0) {
      enqueueSnackbar(
        t('taxcard.dateStartIsMissing'),
        { variant: 'error' }
      )
      return
    }

    const formYear = parseInt(form.dateStart.split('-')[0])
    const currentYear = new Date().getFullYear()
    if (Math.abs(formYear - currentYear) >= 2) {
      enqueueSnackbar(
        t('taxcard.dateStartIsInvalid'),
        { variant: 'error' }
      )
      return
    } else if (formYear !== currentYear) {
      setOpenConfirmSendTaxcardDialog(true)
    } else {
      sendTaxCard()
    }
  }

  const sendTaxCard = async () => {
    try {
      setLoading(true)
      setOpenConfirmSendTaxcardDialog(false)

      const newTaxCard = {
        recordType: 'Taxcard',
        recordId: id,
        file: taxCardFile.file,
        dateStart: form.dateStart
      }

      cLog('SAVE ATTACHMENT', newTaxCard)
      await saveAttachment(newTaxCard)

      setLoading(false)
      enqueueSnackbar(
        t('taxcard.sent'),
        { variant: 'success' }
      )
      setTimeout(() => history.push('/kayttajat/' + id), 1500)
    } catch (error) {
      setLoading(false)
      cLog('SEND TAX CARD ERROR', error)
      enqueueSnackbar(
        t('taxcard.problemsSending'),
        { variant: 'error' }
      )
    }
  }

  const handleConfirmSendTaxcardDialog = () => {
    setOpenConfirmSendTaxcardDialog(false)
  }

  return (isAdmin || user) && (
    <LayoutContainer>
      <Header
        title={t('taxcard.loadTaxCard')}
      />
      <p>{t('taxcard.checkInfo')}</p>
      <div className={classes.rowContainer}>

        <div>
          <DPicker
            name="dateStart"
            id="dateStart"
            label={t('taxcard.validFrom')}
            selected={form.dateStart ? new Date(form.dateStart) : ''}
            onChange={(date) => {
              setForm({ ...form, dateStart: date ? formatDate(date) : '' })
            }}
          />
        </div>

        <div>
          <p>{t('taxcard.uploadText2')}</p>
          <Dropzone
            setFile={setTaxCardFile}
            style={{ width: '100%'/* , height: '100px'  */ }}
            tip="taxcard"
          />
        </div>

        {taxCardFile &&
        <div>
          <a target="_blank" href={taxCardFile.url} rel="noopener noreferrer">
            {isImage
              ? <img src={taxCardFile.url} className={classes.thumbnail} alt="VEROKORTTI"/>
              : taxCardFile.filename
            }
          </a>
        </div>}

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSaveTaxCard}
          >
            {isLoading ? <CircularProgress size={28} color='inherit' /> : t('taxcard.send')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={() => {
              setTaxCardFile(null)
              setForm(INIT_FORM)
              history.go(-1)
            }}
          >
            {t('taxcard.cancel')}
          </Button>
        </div>

      </div>
      <Alert
        open={openConfirmSendTaxcardDialog}
        setOpen={setOpenConfirmSendTaxcardDialog}
        handleAgree={sendTaxCard}
        handleClose={handleConfirmSendTaxcardDialog}
        alertMessage={{ title: t('taxcard.confirmDialogTitle'), body: t('taxcard.confirmDialogBody') }}
        confirmButtonText={t('taxcard.send')}
        isLoading={isLoading}
      />
    </LayoutContainer>
  )
}

export default withSnackbar(TaxCard)
