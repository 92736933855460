import React, { useEffect } from 'react'
import Footer from '../../screens/Footer'
import { useTranslation } from 'react-i18next';
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'

const ContactInfo = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <LayoutContainer>
            <div>
                <Header title={t('contact.contactInfo')} />
                <h3><strong>Odeal Oy</strong></h3>
                <div>
                    <div><strong>{t('contact.address')}</strong>Niittyläntie 5, 00620 Helsinki</div>
                    <div><strong>{t('contact.customerService')}</strong>0800 0 4848</div>
                    <div><strong>{t('contact.customerService')}</strong>info@odeal.fi</div>
                </div>
                <div>
                {t('contact.officeInfo').split(';').map((row, i) => <div key={i}>{row}</div>)}
                </div>
                <h3><strong>{t('contact.billingInfo')}</strong></h3>
                {t('contact.billingContent').split(';').map((row, i) => <p key={i}>{row}</p>)}

                {t('contact.eInvoiceOperator').split(';').map((row, i) => <p key={i}><strong>{row}</strong></p>)}
            </div>
            <Footer />
        </LayoutContainer>
    )
}

export default ContactInfo
