import React, { useState, useEffect } from 'react'
import { Field } from 'formik'
import { Grid, FormControlLabel, Table, TableHead, TableBody, TableRow, TableCell, useMediaQuery } from '@material-ui/core'
import { formatTimeFI, formatDatetime } from '../../utils'
import Input from '../../components/Input'
import DropDown from '../../components/DropDown'
import Button from '../../components/Button'
import { saveUser, updateUser } from '../../service'
import { useStateValue, setCurrentUser } from "../../state"
import { withSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { INDUSTRIES } from '../../utils/invoiceUtils'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { COUNTRIES } from '../../utils/countries'
import { LANGUAGES } from '../../utils/languages'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import TaxcardRow from './TaxcardRow'
import OdealCheckbox from '../../components/OdealCheckbox'
import TextArea from '../../components/TextArea'
import Header from "../../components/Header"
import { validateFirstName, validateLastName, validateSsn, validateAddress1, validatePostalCode, validateCity, validateEmail, validatePhone, validateBankaccount, validateBIC, validateMarketingName, validateCommission, validateIndustry, validateCustomIndustry, validateYel, validateDistraintNotes, validateNoInstantSalaryNotes, validateOdealAccount, checkUser } from './userValidations'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(4),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  }
}))

const UserForm = React.forwardRef(
  (props, ref) => {
    const {
      handleSubmit,
      handleChange,
      values,
      setFieldValue,
      validateForm,
      errors,
      edit,
      enqueueSnackbar,
      dirty,
      setUser
    } = props

    const { t } = useTranslation()
    const [{ currentUser }, dispatch] = useStateValue()
    const [isLoading, setLoading] = useState(false)
    let history = useHistory()
    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isAdmin = currentUser && currentUser.role === 'ADMIN'
    const [expanded, setExpanded] = useState('invoiceBody')
    const [showHistory, setShowHistory] = useState()

    useEffect(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, [])

    const handleChangeExpansion = (panel) => async (event, isExpanded) => {
      const erroris = await validateForm()
      if (Object.keys(erroris).length > 0) {
        notifyErrors(erroris)
      } else {
        if (panel === expanded) {
          setExpanded('')
          return
        } else {
          setExpanded(panel)
        }
      }
    }

    const removeRow = (row, i) => {
      //cLog('TÄMÄ POISTETAAN', `${row}.${i}`)
      const rowParts = (expanded && expanded.split('.')) || []
      const rowBody = rowParts[0]
      //cLog('ROW BODY ', rowBody, 'ROW ', row)
      const rowNumber = parseInt(rowParts[1])
      if (rowBody === row) {
        if (rowNumber > 0) {
          setExpanded(`${row}.${rowNumber - 1}`)
        } else {
          setExpanded('invoiceBody')
        }
      }
    }

    const taxcardRow = () => values.taxcards &&
    <TaxcardRow
      taxcards={values.taxcards}
      setFieldValue={setFieldValue}
      expanded={expanded}
      handleChangeExpansion={handleChangeExpansion}
      removeRow={removeRow}
      values={values}
      dirty={dirty}
    />

    const notifyOffice = (error) => {
      if (isAdmin) {
        if (error && error.response && error.response.data && error.response.data.errors) {
          error.response.data.errors.forEach(message => {
            enqueueSnackbar(
              message,
              { variant: 'error' }
            )
          })
        }
      }
    }

    cLog('USER FORM VALUES', values)
    //cLog('USER FORM CURRENT USER', currentUser)
    //cLog('USER FORM USERS', users)
    checkUser(values, isAdmin)
    //cLog('ERRORS', errors)

    const languageOptions = Object.keys(LANGUAGES)
      .filter(key => ['Suomi', 'Englanti'].includes(key))
      .map(lang => ({ label: t('customers.'+lang), value: LANGUAGES[lang] }))
    const countryOptions = Object.keys(COUNTRIES).map(country => ({ label: t('countries.'+COUNTRIES[country]), value: COUNTRIES[country] }))
    const nationalities = [
      'Espanja',
      'Norja',
      'Ruotsi',
      'Saksa',
      'Suomi',
      'Venäjä',
      'Viro',
      'Muu'
    ]
    const citizenshipOptions = nationalities.map(nationality => ({ label: t('user.'+nationality), value: nationality }))
    const roleOptions = ['LASKUTTAJA', 'ADMIN', 'PASSIIVINEN']
      .map(role => ({ label: t('user.'+role), value: role }))
    const yelOptions = ['EI_VELVOLLINEN', 'VELVOLLINEN']
      .map(yel => ({ label: t('user.'+yel), value: yel }))
    //const industryOptions = INDUSTRIES.map(industry => ({ label: t('user.'+industry), value: industry }))

    const industryOptions =  Array.from(INDUSTRIES).map(([key]) => ({ label: t('user.'+key), value: key }))

    const notifyErrors = (erroris) => {
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))
      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const handleSubmitForm = async () => {
      setLoading(true)
      const erroris = await validateForm()
      if (Object.keys(erroris).length > 0) {
        notifyErrors(erroris)
        setLoading(false)
      } else {
        if (edit) {
          try {
            const updatedUser = await updateUser(values)
            setUser(updatedUser)
            cLog('USER UPDATED', updatedUser)

            if (values.id === currentUser.id) {
              dispatch(setCurrentUser(updatedUser))
            }

            enqueueSnackbar(
              t('user.userUpdated'),
              { variant: 'success' }
            )
            //setLoading(false)
            history.push('/kayttajat/' + values.id)
          } catch (error) {
            const errorMessages = error.response.data.errors
            if (errorMessages.includes("Email has already been taken")) {
              enqueueSnackbar(
                t('user.emailTaken'),
                { variant: 'error' }
              )
            } else if (errorMessages.includes("Ssn is already in use")) {
              enqueueSnackbar(
                t('user.ssnTaken'),
                { variant: 'error' }
              )
            } else {
              enqueueSnackbar(
                t('user.problemsUpdating'),
                { variant: 'error' }
              )
              notifyOffice(error)
            }
            cLog('USER UPDATE ERROR', errorMessages)
            setLoading(false)
          }
        } else {
          cLog('SAVING NEW USER', values)
          const newUser = saveUser(values)
          newUser.then(response => {
            cLog('A NEW USER ADDED', newUser)
            enqueueSnackbar(
            t('user.userCreated'),
              { variant: 'success' }
            )
            setLoading(false)
            history.push('/kayttajat/' + response.id)
          },
          error => {
            setLoading(false)
            enqueueSnackbar(
              t('user.problemsCreating'),
              { variant: 'error' }
            )
            notifyOffice(error)
          })
        }
      }
    }

    const isBICneeded = values.bankAccount && values.bankAccount.length > 1 && values.bankAccount.slice(0, 2).toLowerCase() !== 'fi'

    const History = () => {
      let collection = [];
      values.updateHistory.map((row, i) => {
        let name, filtered, joined;
        let changes = [];

        for (const [key, value] of Object.entries(row.changes)) {
          if (!['id', 'last_sign_in_at', 'sign_in_count', 'reset_password_sent_at', 'confirmation_sent_at', 'user_id', 'attachment_id'].includes(key)) {
            let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
            let value0 = value[0]
            let value1 = value[1]

            if (k === 'currentSignInAt' || k === 'confirmedAt') {
              if (value0) {
                value0 = formatDatetime(value0)
              }
              if (value1) {
                value1 = formatDatetime(value1)
              }
            } else if (k === 'dateStart') {
              if (value0) {
                value0 = formatTimeFI(value0)
              }
              if (value1) {
                value1 = formatTimeFI(value1)
              }
            } else if (k === 'address1') {
              k = 'address'
            } else if (k === 'country') {
              if (value0) {
                value0 = t('countries.'+value0)
              }
              if (value1) {
                value1 = t('countries.'+value1)
              }
            } else if (k === 'language' || k === 'citizenship' || k === 'yel') {
              if (value0) {
                value0 = t('user.'+value0)
              }
              if (value1) {
                value1 = t('user.'+value1)
              }
            }

            if (value[0] || value[1]) {
              if (value0 === 'true' || value0 === true) {
                value0 = t('taxcard.true')
              } else if (value0 === 'false'|| value0 === false) {
                value0 = t('taxcard.false')
              } else if (!value0) {
                value0 = t('user.noValue')
              }

              if (value1 === 'true' || value1 === true) {
                value1 = t('taxcard.true')
              } else if (value1 === 'false' || value1 === false) {
                value1 = t('taxcard.false')
              } else if (!value1) {
                value1 = t('user.noValue')
              }

              if (row.event === 'create') {
                changes.push(t('user.'+k) + ': ' + value1)
              } else if (row.event === 'destroy') {
                changes.push(t('user.'+k) + ': ' + value0)
              } else {
                changes.push(t('user.'+k) + ': ' + value0 + ' -> ' + value1)
              }
            }
          }
        }

        if (changes.length) {
          if (row.firstName && row.lastName) {
            name = row.firstName + ' ' + row.lastName;
          } else if (row.firstName && !row.lastName) {
            name = row.firstName;
          } else if (!row.firstName && row.lastName) {
            name = row.lastName
          } else {
            name = 'Odealin järjestelmä'
          }

          let rowText = ''
          if (row.itemType !== "ActiveStorage::Attachment") {
            filtered = changes.filter(function (f) {
              return f != null;
            });
            joined = filtered.join(', ');

            if (row.itemType === 'Taxcard') {
              rowText = t('user.taxcard') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
            } else if (row.itemType === 'UserNote') {
              rowText = t('user.userNote') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
            } else if (row.itemType === 'Reference') {
              rowText = t('user.reference') + ' id ' + row.itemId + ' ' + t('invoice.'+row.event) + ': '
            } else if (row.itemType === "User" && row.event === 'create') {
              rowText = t('user.user') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
            }
          }

          collection.push(<TableRow key={i}>
            <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
            <TableCell>{name}</TableCell>
            <TableCell>{rowText + joined}</TableCell>
          </TableRow>)
        }

        return null
      })

      return (<div style={{ paddingBottom: '20px' }}>
        <br /><br />
        {t('user.historyTitle')}
        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('customers.date')}</TableCell>
                <TableCell>{t('customers.user')}</TableCell>
                <TableCell>{t('customers.events')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collection}
            </TableBody>
          </Table>
        </div>
      </div>
      )
    }

    return (
      <>
        <Header
          title={edit ? (currentUser.id === values.id ? t('user.editOwnInformation') : t('user.editUser')) : t('users.addNewUser')}
        />
        
        <p>{t('user.mandatoryFields')}</p>
        <form onSubmit={handleSubmit}>
          <div id='formTop' styles={{ padding: '15px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="firstName"
                  id='firstName'
                  type='text'
                  label={t('user.firstName')+' *'}
                  placeholder={t('user.firstName')}
                  value={values.firstName || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.firstName && validateFirstName(values.firstName)}
                  validate={() => validateFirstName(values.firstName)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="lastName"
                  id='lastName'
                  type='text'
                  label={t('user.lastName')+' *'}
                  placeholder={t('user.lastName')}
                  value={values.lastName || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.lastName && validateLastName(values.lastName)}
                  validate={() => validateLastName(values.lastName)}
                />
              </Grid>
              {isAdmin && <Grid item xs={12} sm={6}>
                <Field
                  name="ssn"
                  id='ssn'
                  type='text'
                  label={t('user.ssn')}
                  placeholder={t('user.ssn')}
                  value={values.ssn || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.ssn && validateSsn(values.ssn)}
                  validate={() => validateSsn(values.ssn)}
                />
              </Grid>}
              <Grid item xs={12} sm={6}>
                <Field
                  name="address1"
                  id='address1'
                  type='text'
                  label={t('user.address')+(!isAdmin ? ' *' : '')}
                  placeholder={t('user.address')}
                  value={values.address1 || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.address1 && validateAddress1(values.address1, isAdmin)}
                  validate={() => validateAddress1(values.address1, isAdmin)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="postalCode"
                  id='postalCode'
                  type='text'
                  label={t('user.postalCode')+(!isAdmin ? ' *' : '')}
                  placeholder={t('user.postalCode')}
                  value={values.postalCode || ''}
                  onChange={(e) => {
                    setFieldValue('postalCode', e.target.value)
                  }}
                  component={Input}
                  error={errors && errors.postalCode && validatePostalCode(values.postalCode, isAdmin)}
                  validate={() => validatePostalCode(values.postalCode, isAdmin)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="city"
                  id='city'
                  type='text'
                  label={t('user.city')+(!isAdmin ? ' *' : '')}
                  placeholder={t('user.city')}
                  value={values.city || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.city && validateCity(values.city, isAdmin)}
                  validate={() => validateCity(values.city, isAdmin)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="country"
                  id="country"
                  label={t('user.country')}
                  options={countryOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                  onChange={(e) => setFieldValue('country', e.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="email"
                  id='email'
                  type='email'
                  label={t('user.email')+' *'}
                  //disabled={isAdmin && currentUser.id !== values.id}
                  value={values.email}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.email && validateEmail(values.email)}
                  validate={() => validateEmail(values.email)}
                  info={t('user.emailInfo')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="phone"
                  id='phone'
                  type='text'
                  label={t('user.phone')+(!isAdmin ? ' *' : '')}
                  placeholder={t('user.phone')}
                  info={t('user.phoneTip')}
                  value={values.phone || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.phone && validatePhone(values.phone, isAdmin)}
                  validate={() => validatePhone(values.phone, isAdmin)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="language"
                  id='language'
                  label={t('user.language')}
                  value={values.language}
                  onChange={(e) => setFieldValue('language', e.value)}
                  options={languageOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                  info={t('user.languageInfo')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="citizenship"
                  id='citizenship'
                  label={t('user.citizenship')}
                  value={values.citizenship}
                  onChange={(e) => setFieldValue('citizenship', e.value)}
                  options={citizenshipOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="bankAccount"
                  id='bankAccount'
                  type='text'
                  label={t('user.bankAccount')+(!isAdmin ? ' *' : '')}
                  placeholder={t('user.bankAccount')}
                  value={values.bankAccount || ''}
                  onChange={(e) => {
                    setFieldValue('bankAccount', e.target.value)
                  }}
                  component={Input}
                  error={errors && errors.bankAccount && validateBankaccount(values.bankAccount, isAdmin)}
                  validate={() => validateBankaccount(values.bankAccount, isAdmin)}
                />
              </Grid>
              {isBICneeded && <Grid item xs={12} sm={6}>
                <Field
                  name="bic"
                  id='bic'
                  type='text'
                  label={t('user.bic')+(!isAdmin ? ' *' : '')}
                  placeholder={t('user.bic')}
                  value={values.bic || ''}
                  onChange={(e) => {
                    setFieldValue('bic', e.target.value)
                  }}
                  component={Input}
                  error={errors && errors.bic && validateBIC(values.bic, isAdmin)}
                  validate={() => validateBIC(values.bic, isAdmin)}
                />
              </Grid>}
              <Grid item xs={12} sm={values.industry === 'Muu' ? 6 : 6}>
                <Field
                  name="industry"
                  id='industry'
                  label={t('user.industry')+(!isAdmin ? ' *' : '')}
                  defaultValue={industryOptions.find(option => option.value === values.industry)}
                  placeholder={t('user.industry')}
                  info={t('user.industryTip')}
                  onChange={(e) => {
                    setFieldValue('industry', e.value)
                    if (e.value !== 'Muu') {
                      setFieldValue('customIndustry', '')
                    }
                  }}
                  options={industryOptions}
                  component={DropDown}
                  isSearchable={!isMobile}
                  error={errors && errors.industry && validateIndustry(values.industry, isAdmin)}
                  validate={() => validateIndustry(values.industry, isAdmin)}
                />
              </Grid>
              {values.industry === 'Muu' && <Grid item xs={12} sm={values.industry === 'Muu' ? 6 : 6}>
                <Field
                  name="customIndustry"
                  id='customIndustry'
                  label={t('user.customIndustry')+(!isAdmin ? ' *' : '')}
                  //value={values.customIndustry}
                  defaultValue={values.customIndustry}
                  placeholder={t('user.customIndustry')}
                  //info={INFO.invoice.industry}
                  onChange={(e) => setFieldValue('customIndustry', e.target.value)}
                  component={Input}
                  error={errors && errors.customIndustry && validateCustomIndustry(values.customIndustry, isAdmin)}
                  validate={() => validateCustomIndustry(values.customIndustry, isAdmin)}
                />
              </Grid>}
              <Grid item xs={12} sm={values.industry === 'Muu' ? 6 : 6}>
                <Field
                  name="marketingName"
                  id='marketingName'
                  type='text'
                  label={t('user.marketingName')}
                  placeholder={t('user.marketingName')}
                  value={values.marketingName || ''}
                  onChange={handleChange}
                  component={Input}
                  error={errors && errors.marketingName && validateMarketingName(values.marketingName)}
                  validate={() => validateMarketingName(values.marketingName)}
                  info={t('user.marketingNameInfo')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="yel"
                  id='yel'
                  label={t('user.yel')+(!isAdmin ? ' *' : '')}
                  info={t('user.yelTip')}
                  value={values.yel}
                  placeholder={t('invoice.pick')}
                  onChange={(e) => setFieldValue('yel', e.value)}
                  options={yelOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                  error={errors && errors.yel && validateYel(values.yel, isAdmin)}
                  validate={() => validateYel(values.yel, isAdmin)}
                />
              </Grid>
              {isAdmin && <Grid item xs={12} sm={6}>
                <Field
                  name="commission"
                  id='commission'
                  type='text'
                  label={t('user.commission')}
                  value={values.commission}
                  onChange={(e) => {
                    setFieldValue('commission', e.target.value)
                  }}
                  component={Input}
                  error={errors && errors.commission && validateCommission(values.commission)}
                  validate={() => isAdmin && validateCommission(values.commission)}
                />
              </Grid>}
              {isAdmin && values.id !== currentUser.id && <Grid item xs={12} sm={6}>
                <Field
                  name="role"
                  id='role'
                  label={t('user.role')}
                  value={values.role}
                  onChange={(e) => setFieldValue('role', e.value)}
                  options={roleOptions}
                  isSearchable={!isMobile}
                  component={DropDown}
                />
              </Grid>}
              {isAdmin && <Grid item xs={12} sm={6}>
                <Field
                  name="odealAccount"
                  id='odealAccount'
                  type='text'
                  label={t('user.odealAccount') + ' *'}
                  value={values.odealAccount || '0'}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFieldValue('odealAccount', e.target.value)
                    } else {
                      setFieldValue('odealAccount', 0)
                    }
                  }}
                  component={Input}
                  error={errors && errors.odealAccount && validateOdealAccount(values.odealAccount, t('user.odealAccount'), true, 2)}
                  validate={() => isAdmin && validateOdealAccount(values.odealAccount, t('user.odealAccount'), true, 2)}
                />
              </Grid>}
              {isAdmin && <Grid item xs={12} sm={6}>
                <Field
                  name="notAccounted"
                  id='notAccounted'
                  type='text'
                  label={t('user.notAccounted') + ' *'}
                  value={values.notAccounted || '0'}
                  onChange={(e) => {
                    if (e.target.value) {
                      setFieldValue('notAccounted', e.target.value)
                    } else {
                      setFieldValue('notAccounted', 0)
                    }
                  }}
                  component={Input}
                  error={errors && errors.notAccounted && validateOdealAccount(values.notAccounted, t('user.notAccounted'), true, 2)}
                  validate={() => isAdmin && validateOdealAccount(values.notAccounted, t('user.notAccounted'), true, 2)}
                />
              </Grid>}
            </Grid>
            {isAdmin && <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<OdealCheckbox
                    checked={values.distraint}
                    onChange={() => setFieldValue('distraint', !values.distraint)}
                    name="distraint" />}
                  label={t('user.distraint')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {values.distraint && <Field
                  name="distraintNotes"
                  id="distraintNotes"
                  type="text"
                  label={t('user.distraintNotes')}
                  component={TextArea}
                  value={values.distraintNotes || ''}
                  onChange={(e) => {
                    setFieldValue('distraintNotes', e.target.value)
                  }}
                  error={errors && errors.distraintNotes && validateDistraintNotes(values.distraintNotes)}
                  validate={() => validateDistraintNotes(values.distraintNotes)}
                />}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<OdealCheckbox
                    checked={values.noInstantSalary}
                    onChange={() => setFieldValue('noInstantSalary', !values.noInstantSalary)}
                    name="noInstantSalary" />}
                  label={t('user.noInstantSalary')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {values.noInstantSalary && <Field
                  name="noInstantSalaryNotes"
                  id="noInstantSalaryNotes"
                  type="text"
                  label={t('user.noInstantSalaryNotes')}
                  component={TextArea}
                  value={values.noInstantSalaryNotes || ''}
                  onChange={(e) => {
                    setFieldValue('noInstantSalaryNotes', e.target.value)
                  }}
                  error={errors && errors.noInstantSalaryNotes && validateNoInstantSalaryNotes(values.noInstantSalaryNotes)}
                  validate={() => validateNoInstantSalaryNotes(values.noInstantSalaryNotes)}
                />}
              </Grid>
              {/* LISÄTÄÄN INTERNAL NOTES */}
            </Grid>}
            {isAdmin && values.taxcards[0] && taxcardRow()}
            <div className={classes.buttonContainer}>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                onClick={handleSubmitForm}
              >
                {t('user.save')}
              </Button>
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={() => history.go(-1)}
              >
                {t('user.cancel')}
              </Button>
              {isAdmin &&
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowHistory(!showHistory)}
                >
                  {showHistory ? t('invoice.hideHistory') : t('invoice.showHistory')}
                </Button>}
            </div>
          </div>
        </form>
        {isAdmin && showHistory && <div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <History />
            </Grid>
          </Grid>
        </div>}
      </>
    )
  })

export default withSnackbar(UserForm)
