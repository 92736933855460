import React, { useState } from 'react'
import { saveEloLink } from '../service'
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { cLog } from '../utils'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: '800px',
    '& > h2': {
      color: theme.palette["primary"].main,
    },
  }
}))

export default function YelDialog({ open, setOpenYelDialog, enqueueSnackbar }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)

  const insuranceOnClick = async () => {
    setLoading(true)
    const request = saveEloLink({ language: i18n.language })
    request.then(response => {
      cLog('ELO LINK URL', response.url)
      setLoading(false)
      return window.open(response.url, '_blank')
    }, error => {
      cLog('ELO LINK ERROR', error)
      enqueueSnackbar(
        t('frontPage.eloLinkError'),
        { variant: 'error' }
      )
      setLoading(false)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpenYelDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="yel-dialog-title"
      aria-describedby="yel-dialog-description"
    >
      <DialogTitle id="yel-dialog-title">{t('frontPage.insuranceShortcut')}</DialogTitle>
      <DialogContent dividers={true} id="yel-dialog-description">
        <Button
          variant="contained"
          color="primary"
          onClick={() => insuranceOnClick()}
          disabled={isLoading}
        >
          {t('elo.button')}
        </Button>
        <p>{t('elo.1')}</p>
        <div className={classes.cardContainer}>
          <Typography variant="h5" component="h2">{t('elo.2')}</Typography>
          <div className={classes.cardContentContainer}>
            <p>{t('elo.3')}</p>
            <p>{t('elo.4')}</p>
            <Link to={`/info?panel=5a`}>{t('elo.link')}</Link>
            <p>{t('elo.5')}</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="secondary"
          onClick={() => setOpenYelDialog(false)}
        >
          {t('invoice.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
