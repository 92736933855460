import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStateValue, setClients } from "../../state"
import { Formik } from 'formik'
import WorklistForm from './WorklistForm'
import { getUser, getClientNames, getWorklist } from '../../service'
import { useHistory, useParams } from 'react-router-dom'
import { cLog } from '../../utils'
import LayoutContainer from "../../components/LayoutContainer"
import Header from "../../components/Header"

const WORKLIST_FORM_TEMPLATE = {
  userId: '',
  clientId: '',
  name: '',
  status: 'LUONNOS',
  totalHours: 0,
  worklistRows: []
}

const Worklist = ({ edit }) => {
  const { t } = useTranslation()
  const [{ currentUser }, dispatch] = useStateValue()
  let { id } = useParams()
  const [worklist, setWorklist] = useState(null)
  let history = useHistory()
  const [userData, setUserData] = useState(null)
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  useEffect(() => {
    const fetchData = async () => {
      if (edit) {
        try {
          const thisWorklist = await getWorklist(id)
          setWorklist(thisWorklist)

          if (isAdmin) {
            try {
              const user = await getUser(thisWorklist.userId)
              setUserData(user)
            } catch (error) {
              cLog('ONGELMIA KÄYTTÄJÄN HAKEMISESSA', error)
            }
          }

          try {
            let usersClients

            if (isAdmin) {
              usersClients = await getClientNames('?user_id=' + thisWorklist.userId)
            } else {
              usersClients = await getClientNames()
            }
            cLog('ALL USERS CLIENTS', usersClients)
            dispatch(setClients(usersClients))
          } catch (error) {
            cLog('ONGELMIA KÄYTTÄJÄN ASIAKKAIDEN HAKEMISESSA', error)
          }
        } catch (error) {
          cLog('ONGELMIA TYÖLISTAN LATAAMISESSA', error)
          history.push('/')
        }
      } else if (!isAdmin) {
        try {
          const usersClients = await getClientNames()
          cLog('ALL USERS CLIENTS', usersClients)
          dispatch(setClients(usersClients))
        } catch (error) {
          cLog('ONGELMIA KÄYTTÄJÄN ASIAKKAIDEN HAKEMISESSA', error)
        }
      }
    }

    fetchData()
  }, [edit, id, currentUser.id, dispatch, history, isAdmin])

  return (edit && worklist) || !edit ? (
    <LayoutContainer>
      <Header
        title={edit ? (worklist.status === 'LUONNOS' ? t('worklist.editWorklist') : t('invoice.workList')) : t('worklist.addNewWorklist')}
      />
      <Formik
        initialValues={edit ? worklist : { ...WORKLIST_FORM_TEMPLATE, userId: !isAdmin ? currentUser.id : '' }}
        validateOnChange={false}
        validateOnBlur={false}
        component={props =>
          <WorklistForm
            {...props}
            edit={edit}
            worklist={worklist}
            userData={userData}
            setUserData={setUserData}
          />}
      />
    </LayoutContainer>
  ) : null
}

export default Worklist
