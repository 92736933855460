import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Label } from './Label'
import InfoPop from './InfoPop'

const useStyles = makeStyles((theme) => ({
  root: {
    "& label": {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 0
    }
  },
}))

const LabelWithInfo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Label>
        <div style={{ display: 'flex', justifyContent: !props.justify ? 'inherit' : 'space-between' }}>
          <span style={{ marginRight: !props.justify ? '10px' : '0px' }}>{props.label}</span>
          <InfoPop info={props.info} />
        </div>
      </Label>
    </div>
  )
}

export default LabelWithInfo
