import React from 'react'
import { Field, FieldArray } from 'formik'
import { checkPaymentRowErrors, validateTextArea, validateDate, validateFloat } from './validationSchemas'
import Input from '../../components/Input'
import { Grid } from '@material-ui/core'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
//import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
 
const PaymentRow = ({
  payments,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow
}) => {
  const { t } = useTranslation()

  return (
    <FieldArray
      name="payments"
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        return (
          <>
            {payments.map((row, i) => {
              const errors = arrayHelpers.form.errors.payments ? arrayHelpers.form.errors.payments[i] : {}
              const paymentRowHasErrors = errors ? Object.keys(errors).length > 0 : false

              //cLog('PAYMENT ROW ERRORS', errors)

              return (
                <div id={`pRows${i}`} key={i}>
                  <Hanuri>
                    <HanuriTitle
                      expanded={expanded === `payments.${i}`}
                      id={`payments.${i}`}
                      open={handleChangeExpansion(`payments.${i}`)}
                      remove={() => {
                        removeRow('payments', i)
                        arrayHelpers.remove(i)
                      }}
                      label={t('invoice.paymentRow')+' '+(i+1)}
                      done={checkPaymentRowErrors(row)}
                      error={paymentRowHasErrors && !checkPaymentRowErrors(row)}
                    />
                    <HanuriBody expanded={expanded === `payments.${i}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Field
                            name={`payments.${i}.notes`}
                            id={`payments.${i}.notes`}
                            label={t('invoice.notes')}
                            type="text"
                            component={Input}
                            value={payments[i].notes || ''}
                            onChange={(e) => { setFieldValue(`payments.${i}.notes`, e.target.value) }}
                            validate={() => validateTextArea(row.notes, t('invoice.notes'), 10000, false)}
                            error={errors && errors.notes && validateTextArea(row.notes, t('invoice.notes'), 10000, false)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`payments.${i}.txAmt`}
                            id={`payments.${i}.txAmt`}
                            label={t('invoice.sum')+' *'}
                            type="text"
                            component={Input}
                            value={payments[i].txAmt || ''}
                            onChange={(e) => { setFieldValue(`payments.${i}.txAmt`, e.target.value) }}
                            validate={() => validateFloat(row.txAmt, t('invoice.sum'), false)}
                            error={errors && errors.txAmt && validateFloat(row.txAmt, t('invoice.sum'), false)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field
                            name={`payments.${i}.valDt`}
                            id={`payments.${i}.valDt`}
                            label={t('invoice.valDt')+' *'}
                            component={DPicker}
                            selected={payments[i].valDt ? new Date(payments[i].valDt) : ''}
                            onChange={(date) => setFieldValue(`payments.${i}.valDt`, date ? formatDate(date) : '')}
                            validate={() => validateDate(row.valDt, t('invoice.valDt'), true)}
                            error={errors && errors.valDt && validateDate(row.valDt, t('invoice.valDt'), true)}
                          />
                        </Grid>
                      </Grid>
                    </HanuriBody>
                  </Hanuri>
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}

export default PaymentRow 
