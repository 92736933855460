import React from "react";
import { Button as MaterialButton } from "@material-ui/core";
import { makeStyles, darken, alpha } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
  customButton: {
    "&.MuiButton-text": {
      color: theme.palette[props.color].main,
    },
    "&.MuiButton-contained": {
      background: theme.palette[props.color].main,
      color: theme.palette[props.color].contrastText,
      "&:hover": {
        background: darken(theme.palette[props.color].main, 0.2),
      },
    },
    "&.MuiButton-outlined": {
      color: theme.palette[props.color].main,
      borderColor: theme.palette[props.color].main,
    },
  },
  customInput: {
    position: 'relative !important',
    background: `${theme.palette.white.main} !important`,
    color: `${theme.palette.white.contrastText} !important`,
    border: `${theme.borders.thin(theme.palette.grey.dark)} !important`,
    borderRadius: `${theme.borders.radius.small} !important`,
    width: '100% !important',
    outline: 'none !important',
    padding: `${theme.spacing(2, 3)} !important`,
    fontSize: "1rem !important",
    minHeight: "46px !important",
    boxShadow: 'none !important',
    transition: 'border-color 0.125s, box-shadow 0.125s !important',

    '&:hover': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.9)} 0 0 0 1px !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.5) !important',
    },

    '&.error': {
      borderColor: `${theme.palette.error.main} !important`,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.error.main, 0.9)} 0 0 0 1px !important`,
      },
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}))();

const Button = React.forwardRef(
  (props, ref) => {
    const classes = useStyles({ color: props.color || "primary" });
    const className = `${classes.customButton} ${props.className ? classes[props.className] : ""}`;
    return <MaterialButton variant="outlined" { ...props } color={undefined} className={className} />;
  }
)

export default Button;
