import React from "react";
import { useTranslation } from 'react-i18next'
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(32, 4, 16, 4),
    color: theme.palette.secondary.main,
    textAlign: 'center',

    '& em': {
      textDecoration: 'none',
      fontStyle: 'normal',
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(24, 4, 16, 4),
    },
  }
}));

const LandingScreenHeader = ({ currentUserName }) => {
  const { t } = useTranslation()
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h2" component="h1">{t('frontPage.greetingStart')}<em>{currentUserName}!</em></Typography>
      <Typography variant="h4" component="h2">{t('frontPage.greetingFinish')}</Typography>
    </div>
  );
};

export default LandingScreenHeader;
