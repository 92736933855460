import request from './request'
import { deserializeValtticards, deserializeValtticard } from '../utils/deserializer'

export const getValtticards = async (query) => {
  const { data: valtticards } = await request('GET', '/valtticards' + (query || ''))
  return deserializeValtticards(valtticards)
}

export const getValtticard = async (id) => {
  const { data: valtticard } = await request('GET', '/valtticards/' + id)
  return deserializeValtticard(valtticard)
}

export const saveValtticard = async () => {
  const { data: newValtticard } = await request('POST', '/valtticards')
  return newValtticard
}

export const updateValtticard = async (data) => {
  delete data.updateHistory
  const { data: updatedValtticard } = await request('PUT', `/valtticards/${data.id}`, data)
  return updatedValtticard
}

export const deleteValtticard = async (id) => await request('DELETE', `/valtticards/${id}`)
