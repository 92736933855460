
export const setClients = (payload) => {
  return {
    type: "SET_CLIENTS",
    payload
  }
}

export const addClient = (payload) => {
  return {
    type: "ADD_CLIENT",
    payload
  }
}

export const reviseClient = (payload) => {
  return {
    type: "REVISE_CLIENT",
    payload
  }
}

export const removeClient = (payload) => {
  return {
    type: "REMOVE_CLIENT",
    payload
  }
}

export const setInvoices = (payload) => {
  return {
    type: "SET_INVOICES",
    payload
  }
}

export const addInvoice = (payload) => {
  return {
    type: "ADD_INVOICE",
    payload
  }
}

export const reviseInvoice = (payload) => {
  return {
    type: "REVISE_INVOICE",
    payload
  }
}

export const removeInvoice = (payload) => {
  return {
    type: "REMOVE_INVOICE",
    payload
  }
}

export const setSelected1 = (payload) => {
  return {
    type: "SET_SELECTED1",
    payload
  }
}

export const setSelected2 = (payload) => {
  return {
    type: "SET_SELECTED2",
    payload
  }
}

export const setSelected3 = (payload) => {
  return {
    type: "SET_SELECTED3",
    payload
  }
}

export const setSelected4 = (payload) => {
  return {
    type: "SET_SELECTED4",
    payload
  }
}

export const setSelected5 = (payload) => {
  return {
    type: "SET_SELECTED5",
    payload
  }
}

export const setSortKeyAnnouncements = (payload) => {
  return {
    type: "SET_SORT_KEY_ANNOUNCEMENTS",
    payload
  }
}

export const setSortKeyClients = (payload) => {
  return {
    type: "SET_SORT_KEY_CLIENTS",
    payload
  }
}

export const setSortKeyInvoices = (payload) => {
  return {
    type: "SET_SORT_KEY_INVOICES",
    payload
  }
}

export const setSortKeySalaries = (payload) => {
  return {
    type: "SET_SORT_KEY_SALARIES",
    payload
  }
}

export const setSortKeyTaxcards = (payload) => {
  return {
    type: "SET_SORT_KEY_TAXCARDS",
    payload
  }
}

export const setSortKeyUserNotes = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_NOTES",
    payload
  }
}

export const setSortKeyUsers = (payload) => {
  return {
    type: "SET_SORT_KEY_USERS",
    payload
  }
}

export const setSortKeyUserView1 = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_VIEW1",
    payload
  }
}

export const setSortKeyUserView2 = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_VIEW2",
    payload
  }
}

export const setSortKeyUserView3 = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_VIEW3",
    payload
  }
}

export const setSortKeyUserView4 = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_VIEW4",
    payload
  }
}

export const setSortKeyUserView5 = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_VIEW5",
    payload
  }
}

export const setSortKeyUserView6 = (payload) => {
  return {
    type: "SET_SORT_KEY_USER_VIEW6",
    payload
  }
}

export const setSortKeyWorklists = (payload) => {
  return {
    type: "SET_SORT_KEY_WORKLISTS",
    payload
  }
}

export const setSortOrderAnnouncements = (payload) => {
  return {
    type: "SET_SORT_ORDER_ANNOUNCEMENTS",
    payload
  }
}

export const setSortOrderClients = (payload) => {
  return {
    type: "SET_SORT_ORDER_CLIENTS",
    payload
  }
}

export const setSortOrderInvoices = (payload) => {
  return {
    type: "SET_SORT_ORDER_INVOICES",
    payload
  }
}

export const setSortOrderSalaries = (payload) => {
  return {
    type: "SET_SORT_ORDER_SALARIES",
    payload
  }
}

export const setSortOrderTaxcards = (payload) => {
  return {
    type: "SET_SORT_ORDER_TAXCARDS",
    payload
  }
}

export const setSortOrderUserNotes = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_NOTES",
    payload
  }
}

export const setSortOrderUsers = (payload) => {
  return {
    type: "SET_SORT_ORDER_USERS",
    payload
  }
}

export const setSortOrderUserView1 = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_VIEW1",
    payload
  }
}

export const setSortOrderUserView2 = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_VIEW2",
    payload
  }
}

export const setSortOrderUserView3 = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_VIEW3",
    payload
  }
}

export const setSortOrderUserView4 = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_VIEW4",
    payload
  }
}

export const setSortOrderUserView5 = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_VIEW5",
    payload
  }
}

export const setSortOrderUserView6 = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_VIEW6",
    payload
  }
}

export const setSortOrderWorklists = (payload) => {
  return {
    type: "SET_SORT_ORDER_USER_WORKLISTS",
    payload
  }
}

export const setExpences = (payload) => {
  return {
    type: "SET_EXPENCES",
    payload
  }
}

export const addExpence = (payload) => {
  return {
    type: "ADD_EXPENCE",
    payload
  }
}

export const reviseExpence = (payload) => {
  return {
    type: "REVISE_EXPENCE",
    payload
  }
}

export const removeExpence = (payload) => {
  return {
    type: "REMOVE_EXPENCE",
    payload
  }
}

export const setWorklists = (payload) => {
  return {
    type: "SET_WORKLISTS",
    payload
  }
}

export const addWorklist = (payload) => {
  return {
    type: "ADD_WORKLIST",
    payload
  }
}

export const reviseWorklist = (payload) => {
  return {
    type: "REVISE_WORKLIST",
    payload
  }
}

export const removeWorklist = (payload) => {
  return {
    type: "REMOVE_WORKLIST",
    payload
  }
}

export const setSalaries = (payload) => {
  return {
    type: "SET_SALARIES",
    payload
  }
}

export const addSalary = (payload) => {
  return {
    type: "ADD_SALARY",
    payload
  }
}

export const setTaxcards = (payload) => {
  return {
    type: "SET_TAXCARDS",
    payload
  }
}

export const setUserNotes = (payload) => {
  return {
    type: "SET_USER_NOTES",
    payload
  }
}

export const setUsersIndex = (payload) => {
  return {
    type: "SET_USERS_INDEX",
    payload
  }
}

export const addUser = (payload) => {
  return {
    type: "ADD_USER",
    payload
  }
}

export const setCurrentUser = (payload) => {
  return {
    type: "SET_CURRENT_USER",
    payload
  }
}


export const reviseCurrentUser = (payload) => {
  return {
    type: "REVISE_CURRENT_USER",
    payload
  }
}

export const removeUser = (payload) => {
  return {
    type: "REMOVE_USER",
    payload
  }
}

export const setAnnouncements = (payload) => {
  return {
    type: "SET_ANNOUNCEMENTS",
    payload
  }
}

export const addAnnouncement = (payload) => {
  return {
    type: "ADD_ANNOUNCEMENT",
    payload
  }
}

export const reviseAnnouncement = (payload) => {
  return {
    type: "REVISE_ANNOUNCEMENT",
    payload
  }
}

export const removeAnnouncement = (payload) => {
  return {
    type: "REMOVE_ANNOUNCEMENT",
    payload
  }
}

export const setMessages = (payload) => {
  return {
    type: "SET_MESSAGES",
    payload
  }
}

export const addMessage = (payload) => {
  return {
    type: "ADD_MESSAGE",
    payload
  }
}

export const reviseMessage = (payload) => {
  return {
    type: "REVISE_MESSAGE",
    payload
  }
}

export const removeMessage = (payload) => {
  return {
    type: "REMOVE_MESSAGE",
    payload
  }
}

export const setReferences = (payload) => {
  return {
    type: "SET_REFERENCES",
    payload
  }
}

export const addReference = (payload) => {
  return {
    type: "ADD_REFERENCE",
    payload
  }
}

export const reviseReference = (payload) => {
  return {
    type: "REVISE_REFERENCE",
    payload
  }
}

export const removeReference = (payload) => {
  return {
    type: "REMOVE_REFERENCE",
    payload
  }
}

export const setSortKeyReferences = (payload) => {
  return {
    type: "SET_SORT_KEY_REFERENCES",
    payload
  }
}

export const setSortOrderReferences = (payload) => {
  return {
    type: "SET_SORT_ORDER_REFERENCES",
    payload
  }
}

export const setInvoiceFilter = (payload) => {
  return {
    type: "SET_INVOICE_FILTER",
    payload
  }
}

export const setDiscountCodes = (payload) => {
  return {
    type: "SET_DISCOUNT_CODES",
    payload
  }
}

export const setSortKeyDiscountCodes = (payload) => {
  return {
    type: "SET_SORT_KEY_DISCOUNT_CODES",
    payload
  }
}

export const setSortOrderDiscountCodes = (payload) => {
  return {
    type: "SET_SORT_ORDER_DISCOUNT_CODES",
    payload
  }
}

export const setPromoCodes = (payload) => {
  return {
    type: "SET_PROMO_CODES",
    payload
  }
}

export const setSortKeyPromoCodes = (payload) => {
  return {
    type: "SET_SORT_KEY_PROMO_CODES",
    payload
  }
}

export const setSortOrderPromoCodes = (payload) => {
  return {
    type: "SET_SORT_ORDER_PROMO_CODES",
    payload
  }
}

export const setValtticards = (payload) => {
  return {
    type: "SET_VALTTICARDS",
    payload
  }
}

export const addValtticard = (payload) => {
  return {
    type: "ADD_VALTTICARD",
    payload
  }
}

export const reviseValtticard = (payload) => {
  return {
    type: "REVISE_VALTTICARD",
    payload
  }
}

export const removeValtticard = (payload) => {
  return {
    type: "REMOVE_VALTTICARD",
    payload
  }
}

export const setSortKeyValtticards = (payload) => {
  return {
    type: "SET_SORT_KEY_VALTTICARDS",
    payload
  }
}

export const setSortOrderValtticards = (payload) => {
  return {
    type: "SET_SORT_ORDER_VALTTICARDS",
    payload
  }
}
