import React, { useEffect } from 'react'
import { withSnackbar } from 'notistack'
import { authCode, setAuthCode } from '../../service'
import { Formik } from 'formik'
import SignUpForm from './SignUpForm'
import AuthLayoutContainer from './AuthLayoutContainer'

const INIT_FORM = {
  ssn: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirmation: '',
  discountCode: ''
}

const SignUp = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: 'smooth'
    })
  }, [])

  return (
    <AuthLayoutContainer>
      <Formik
        initialValues={INIT_FORM}
        validateOnChange={false}
        validateOnBlur={false}
        component={props =>
          <SignUpForm
            {...props}
            authCode={authCode}
            setAuthCode={setAuthCode}
          />}
      />
    </AuthLayoutContainer>
  )
}

export default withSnackbar(SignUp)
