import React, { useState, useEffect } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from '../../styles'
import { withSnackbar } from 'notistack'
import { authToken, removeAuthToken, confirmNewEmail } from '../../service'
import { cLog } from '../../utils'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthLayoutContainer from './AuthLayoutContainer'
import AuthForm from './AuthForm'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.primary
  },
  link: {
    cursor: 'pointer',
    padding: '10px'
  }
}))

const ConfirmNewEmail = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const classes = useStyles()
  let history = useHistory()

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: 'smooth'
    })
  }, [])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      const response = confirmNewEmail({ confirmEmailToken: authToken })
      response.then(data => {
        cLog('CONFIRM EMAIL RESPONSE', response)
        removeAuthToken()
        enqueueSnackbar(
          t('auth.emailConfirmed'),
          { variant: 'success' }
        )
        setLoading(false)
        history.push('/signIn')
      }, error => {
        setLoading(false)
        cLog('CONFIRM EMAIL ERROR', error)
        enqueueSnackbar(
          t('auth.emailConfirmationError'),
          { variant: 'error' }
        )
      })
    } catch (error) {
      setLoading(false)
      cLog('CONFIRM EMAIL ERROR', error)
      enqueueSnackbar(
        t('auth.emailConfirmationError'),
        { variant: 'error' }
      )
    }
  }

  return (
    <AuthLayoutContainer>
        <AuthForm title={t('auth.newEmailConfirmation')} onSubmit={handleSubmit}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress color='inherit' /> : t('auth.confirm')}
          </Button>
        </AuthForm>
    </AuthLayoutContainer>
  )
}

export default withSnackbar(ConfirmNewEmail)
