import React, { useState, useEffect } from 'react'
import { Button, TextField, Link, Box, Grid, CircularProgress, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStateValue, setCurrentUser } from "../../state"
import { useHistory } from 'react-router-dom'
import { COLORS } from '../../styles'
import { withSnackbar } from 'notistack'
import { cLog } from '../../utils'
import { isEmail } from '../../utils/invoiceUtils'
import OdealCheckbox from '../../components/OdealCheckbox'
import { useTranslation } from 'react-i18next'
import { removeAuthToken, signUp, discount, email, removeDiscountAndEmail, getUser, setUserData, removeExternalFields, authCode, setAuthCode } from '../../service'
import { getAuthData } from '../../service/userService'
import { oidcSettings } from '../../oidcsettings'
import PasswordStrengthBar from 'react-password-strength-bar'
import InfoPop from '../../components/InfoPop'
import styled from 'styled-components'
import AuthForm from './AuthForm'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.primary
  },
  link: {
    cursor: 'pointer',
    padding: '10px'
  }
}))

const ResponsiveDiv = styled.div`
  width: 100%;
  margin-top: 7px;
  @media only screen and (max-width: 720px) {
    width: 100%;
}`

const SignUpForm = React.forwardRef(
  (props, ref) => {
    const {
      handleSubmit,
      values,
      setFieldValue,
      enqueueSnackbar,
    } = props

    const { t } = useTranslation()
    // eslint-disable-next-line no-empty-pattern
    const [{}, dispatch] = useStateValue()
    const [isLoading, setLoading] = useState(false)
    const [gdpr, setGdpr] = useState(false)
    const classes = useStyles()
    const [showDiscount, setShowDiscount] = useState(false)
    const [discountExplanation, setDiscountExplanation] = useState(false)
    const [errors, setErrors] = useState({})
    const isProductionMode = process.env.REACT_APP_CUSTOM_NODE_ENV === 'PRODUCTION'
    const [disableDiscount, setDisableDiscount] = useState(false)
    let history = useHistory()

    useEffect(() => {
      if (discount) {
        setShowDiscount(true)
        setDiscountExplanation(true)
        setFieldValue('discountCode', discount)
      }

      if (email) {
        setFieldValue('email', email)
      }

      if (authCode) {
        setFieldValue('email', window.localStorage.getItem('signUpEmail') || '')
        setFieldValue('phone', window.localStorage.getItem('signUpPhone') || '')
        setFieldValue('password', window.localStorage.getItem('signUpPassword') || '')
        setFieldValue('passwordConfirmation', window.localStorage.getItem('signUpPasswordConfirmation') || '')
        const code = window.localStorage.getItem('signUpDiscountCode')
        if (code && code.length > 0) {
          setFieldValue('discountCode', code)
          setShowDiscount(true)
        }
        if (window.localStorage.getItem('signUpDiscountExplanation')) {
          setDisableDiscount(true)
          setDiscountExplanation(true)
        }

        window.localStorage.removeItem('signUpEmail')
        window.localStorage.removeItem('signUpPhone')
        window.localStorage.removeItem('signUpPassword')
        window.localStorage.removeItem('signUpPasswordConfirmation')
        window.localStorage.removeItem('signUpDiscountCode')
        window.localStorage.removeItem('signUpDiscountExplanation')

        const promise = getAuthData(authCode)
        promise.then(data => {
          cLog('AUTH DATA VASTAUS', data)
          setFieldValue('ssn', data.ssn)
          setFieldValue('firstName', data.firstName)
          setFieldValue('lastName', data.lastName)
        }, error => {
          cLog('AUTH DATA ERROR', error)
          enqueueSnackbar(
            t('auth.authError'),
            { variant: 'error' }
          )
          setAuthCode(null)
        })
      }
    }, [setFieldValue, enqueueSnackbar, t])

    const notifyErrors = (erroris) => {
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))
      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const handleSubmitForm = async () => {
      let e = {}

      if (!String(values.email).trim()) {
        e.email = { key: t('user.email')+t('user.missing') }
      } else if (!isEmail(values.email)) {
        e.email = { key: t('user.wrongEmail') }
      } else if (values.email && values.email.length > 70) {
        e.email = { key: t('user.email')+t('user.tooLong') }  ///// TÄHÄN VIELÄ REGEXIÄ
      }

      if (!String(values.phone).trim()) {
        e.phone = { key: t('user.phone')+t('user.missing') }
      } else if (values.phone.length < 6) {
        e.phone = { key: t('user.phoneTooShort') }
      } else if (values.phone.length > 26) {
        e.phone = { key: t('user.phone')+t('user.tooLong') }
      } else if ((values.phone.charAt(0) !== '+') || isNaN(values.phone.slice(1)) || (values.phone[1] && values.phone[1] === 0)) {
        e.phone = { key: t('user.wrongPhone') }
      }

      if (!String(values.password).trim() || values.password.length < 6) {
        e.password = { key: t('auth.shortPassword') }
      }

      if (!String(values.passwordConfirmation).trim()) {
        e.passwordConfirmation = { key: t('auth.shortPassword') }
      } else if (values.password && values.password !== values.passwordConfirmation) {
        e.passwordConfirmation = { key: t('auth.passwordMismatch') }
      }

      if (Object.keys(e).length !== 0) {
        cLog('FORMISSA VIKAA')
        notifyErrors(e)
        setErrors(e)

        return false
      }

      setErrors(e)

      if (!values.ssn) {
        window.localStorage.setItem('signUpEmail', values.email)
        window.localStorage.setItem('signUpPhone', values.phone)
        window.localStorage.setItem('signUpPassword', values.password)
        window.localStorage.setItem('signUpPasswordConfirmation', values.passwordConfirmation)
        if (values.discountCode) {
          window.localStorage.setItem('signUpDiscountCode', values.discountCode)
        }

        if (discount) {
          window.localStorage.setItem('signUpDiscountExplanation', true)
        }

        return window.location.replace(`${isProductionMode ? oidcSettings.authority : oidcSettings.authority_dev}/authorization?response_type=${oidcSettings.response_type}&scope=${oidcSettings.scope}&client_id=${isProductionMode ? oidcSettings.client_id : oidcSettings.client_id_dev}&redirect_uri=${isProductionMode ? oidcSettings.redirect_uri : oidcSettings.redirect_uri_dev}&state=${oidcSettings.state_sign_up}`)
      }

      if (!gdpr) {
        enqueueSnackbar(
          t('auth.noGdpr'),
          { variant: 'error' }
        )
        return
      }

      try {
        setLoading(true)
        const request = signUp({
          firstName: values.firstName,
          lastName: values.lastName,
          ssn: values.ssn,
          phone: values.phone,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          discountCode: values.discountCode,
          gdpr: gdpr,
          email: values.email
        })

        request.then(response => {
          cLog('SIGN UP RESPONSE', response)

          const loggedCurrentUser = getUser(response)
          loggedCurrentUser.then(userData => {
            setUserData(userData)
            dispatch(setCurrentUser(userData));
            cLog('LOGGED CURRENT USER', userData)
            setLoading(false)
          }, error => {
            cLog('LOGIN ERROR', error)
            enqueueSnackbar(
              t('auth.signInError'),
              { variant: 'error' }
            )
            setLoading(false)
          })

          enqueueSnackbar(
            t('auth.registerFinished'),
            { variant: 'success' }
          )
          removeDiscountAndEmail()
          removeAuthToken()
          removeExternalFields()
        }).catch(error => {
          setLoading(false)
          if (error.response.data.errors[0] === "Discount code is invalid") {
            cLog('SIGN UP ERROR', error.response.data.errors[0])
            enqueueSnackbar(
              t('auth.discountCodeInvalid'),
              { variant: 'error' }
            )
            let e = errors
            e.discountCode = { key: t('auth.discountCodeInvalid') }
            setErrors(e)
          } else if (error.response.data.errors[0] === "Email has already been taken") {
            cLog('SIGN UP ERROR', error.response.data.errors[0])
            enqueueSnackbar(
              t('auth.emailTaken'),
              { variant: 'error' }
            )
          } else if (error.response.data.errors[0] === "Ssn is already in use") {
            cLog('SIGN UP ERROR', error.response.data.errors[0])
            enqueueSnackbar(
              t('auth.ssnAuthError'),
              { variant: 'error' }
            )
          } else {
            cLog('SIGN UP ERROR', error)
            enqueueSnackbar(
              t('auth.registerProblems'),
              { variant: 'error' }
            )
          }
        })
      } catch (error) {
        setLoading(false)
        if (error.response.data.errors[0] === "Discount code is invalid") {
          cLog('SIGN UP ERROR', error.response.data.errors[0])
          enqueueSnackbar(
            t('auth.discountCodeInvalid'),
            { variant: 'error' }
          )
          let e = errors
          e.discountCode = { key: t('auth.discountCodeInvalid') }
          setErrors(e)
        } else if (error.response.data.errors[0] === "Email has already been taken") {
          cLog('SIGN UP ERROR', error.response.data.errors[0])
          enqueueSnackbar(
            t('auth.emailTaken'),
            { variant: 'error' }
          )
        } else {
          cLog('SIGN UP ERROR', error)
          enqueueSnackbar(
            t('auth.registerProblems'),
            { variant: 'error' }
          )
        }
      }
    }

    return (
      <AuthForm
        title={t('auth.registerToOdeal')}
        onSubmit={handleSubmit}
      >
        {values.ssn && <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="firstName"
          name="firstName"
          label= {t('auth.firstName')+' *'}
          value={values.firstName}
          disabled={true}
        />}
        {values.ssn && <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="lastName"
          name="lastName"
          label= {t('auth.lastName')+' *'}
          value={values.lastName}
          disabled={true}
        />}
        {values.ssn && <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="ssn"
          name="ssn"
          label= {t('auth.ssn')+' *'}
          value={values.ssn}
          disabled={true}
        />}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          name="email"
          label={t('auth.email')+' *'}
          autoComplete="off"
          value={values.email}
          autoFocus={discount && discount.length === 0}
          onChange={(e) => {
            setFieldValue('email', e.target.value)
          }}
          error={errors && Object.keys(errors).includes('email')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="phone"
          name="phone"
          label={t('auth.phone')+' *'}
          autoComplete="off"
          value={values.phone}
          autoFocus={discount && discount.length > 0}
          onChange={(e) => {
            setFieldValue('phone', e.target.value)
          }}
          error={errors && Object.keys(errors).includes('phone')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="password"
          name="password"
          label= {t('auth.password')+' *'}
          autoComplete="off"
          value={values.password}
          type="password"
          onChange={(e) => {
            setFieldValue('password', e.target.value)
          }}
          error={errors && Object.keys(errors).includes('password')}
        />
        <ResponsiveDiv>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PasswordStrengthBar
              style={{ width: '90%' }}
              password={values.password}
              minLength={6}
              shortScoreWord={t('auth.tooShort')}
              scoreWords={[t('auth.bad'),t('auth.weak'),t('auth.average'),t('auth.good'),t('auth.strong')]}
            />
            <InfoPop info={t('auth.passwordTip')} />
          </div>
        </ResponsiveDiv>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="passwordConfirmation"
          name="passwordConfirmation"
          label= {t('auth.confirmPassword')+' *'}
          autoComplete="off"
          value={values.passwordConfirmation}
          type="password"
          onChange={(e) => {
            setFieldValue('passwordConfirmation', e.target.value)
          }}
          error={errors && Object.keys(errors).includes('passwordConfirmation')}
        />
        {showDiscount
          ? <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="discountCode"
              name="discountCode"
              label= {t('auth.discountCode')}
              disabled={(discount && discount.length > 0) || disableDiscount}
              value={values.discountCode}
              onChange={(e) => {
                setFieldValue('discountCode', e.target.value)
              }}
              error={errors && Object.keys(errors).includes('discountCode')}
            />
          : <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer'}}><Link onClick={() => setShowDiscount(true)} variant="body2">{t('auth.haveDiscount')}</Link></div>
        }
        {discountExplanation ? <div style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>{t('auth.discountApplied')}</div> : null}
        {values.ssn && <FormControlLabel
          control={<OdealCheckbox
            checked={gdpr}
            onChange={() => setGdpr(!gdpr)}
            id="gdpr"
            name="gdpr" />}
          label={<div>{t('auth.copyRightStart')} <a href='https://odeal.fi/tietosuoja-ja-rekisteriseloste/' target="_blank" rel="noopener noreferrer">{t('auth.copyRightEnd')}</a></div>}
        />}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isLoading}
          onClick={handleSubmitForm}
        >
          {isLoading ? <CircularProgress size={28} color='inherit' /> : (values.ssn ? t('auth.registerButton') : t('auth.continueAuth'))}
        </Button>
        <Box mt={2}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link className={classes.link} onClick={() => history.push('/signIn')} variant="body2">
                {t('auth.registeredAlready')}
               </Link>
            </Grid>
          </Grid>
        </Box>

      </AuthForm>
    )
  }
)

export default withSnackbar(SignUpForm)
