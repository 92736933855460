import React from 'react'
import Tip from '../../components/Tip'
import Button from '../../components/Button'
import InvoiceDropZone from '../../components/InvoiceDropzone'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'

const iconSize = '24px'
const iconStylesRow = { width: iconSize, height: iconSize, marginRight: '0.5rem' }
const useStyles = makeStyles((theme) => ({
  buttonRowContainerSm: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: theme.spacing(2, 0),

    "& > *": {
      margin: theme.spacing(2, 4, 0, 0),
    },
  },
  buttonRowIcon: {
    width: iconSize,
    height: iconSize,
    marginRight: theme.spacing(2),
  },
}))

const ButtonRow = ({ exp, values, setFieldValue, showInfoTextArea, isAdmin, addInvoiceRow, addTravelRow, addMaterialRow, addInfo, hasTravelRows, hasMaterialRows, attachment, addPaymentRow, addCorrectionRow, addAccountRow
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.buttonRowContainerSm}>
      {!attachment && !exp &&
      <Tip title={t('invoice.add')+t('invoice.addInvoiceRow')} placement="bottom">
        <Button id="LASKURIVI" variant="contained" color="grey" onClick={() => addInvoiceRow()}>
          <AddIcon className={classes.buttonRowIcon}/>
          {t('invoice.addInvoiceRow')}
        </Button>
      </Tip>}
      {!attachment && (exp || hasTravelRows) &&
        <Tip title={t('invoice.add')+t('invoice.addTravelRow')} placement="bottom">
          <Button id="MATKARIVI" variant="contained" color="grey" onClick={() => addTravelRow()}>
            <AddIcon className={classes.buttonRowIcon}/>
            {t('invoice.addTravelRow')}
          </Button>
        </Tip>}
      {!attachment && (exp || hasMaterialRows) &&
        <Tip title={t('invoice.add')+t('invoice.addExpenseRow')} placement="bottom">
          <Button id="KULURIVI" variant="contained" color="grey" onClick={() => addMaterialRow()}>
            <AddIcon className={classes.buttonRowIcon}/>
            {t('invoice.addExpenseRow')}
          </Button>
        </Tip>}
      <InvoiceDropZone
        iconStyles={iconStylesRow}
        variant="contained"
        color="grey"
        values={values}
        setFieldValue={setFieldValue}
      />
      {!attachment &&
        <Tip title={t('invoice.addAdditionalInfoTip')} placement="bottom">
          <Button id="LISATIETOJA" variant="contained" color="grey" onClick={addInfo}>
            <AddIcon className={classes.buttonRowIcon}/>
            {t('invoice.addAdditionalInfo')}
          </Button>
        </Tip>}
      {!exp && isAdmin && values.id && !['LUONNOS', 'TARKASTETTAVANA', 'POISTETTU'].includes(values.status) &&
        <Tip title={t('invoice.addCorrectionRowTip')} placement="bottom">
          <Button id="KORJAUS" variant="contained" color="grey" onClick={() => addCorrectionRow()}>
            <AddIcon className={classes.buttonRowIcon}/>
            {t('invoice.addCorrectionRow')}
          </Button>
        </Tip>}
      {!exp && isAdmin && values.id &&
        <Tip title={t('invoice.addPaymentRowTip')} placement="bottom">
          <Button id="MAKSUSUORITUS" variant="contained" color="grey" onClick={() => addPaymentRow()}>
            <AddIcon className={classes.buttonRowIcon}/>
            {t('invoice.addPaymentRow')}
          </Button>
        </Tip>}
      {isAdmin && values.id &&
        <Tip title={t('invoice.addAccountRowTip')} placement="bottom">
          <Button id="TILITYS" variant="contained" color="grey" onClick={() => addAccountRow()}>
            <AddIcon className={classes.buttonRowIcon}/>
            {t('invoice.addAccountRow')}
          </Button>
        </Tip>}
    </div>
  );
}


export default ButtonRow
