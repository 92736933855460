import React, { useState } from 'react'
import { Button, TextField, Link, Grid, Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { authToken, removeAuthToken, setNewPassword } from '../../service'
import { COLORS } from '../../styles'
import { withSnackbar } from 'notistack'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PasswordStrengthBar from 'react-password-strength-bar'
import InfoPop from '../../components/InfoPop'
import styled from 'styled-components'
import AuthLayoutContainer from './AuthLayoutContainer'
import AuthForm from './AuthForm'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.primary
  },
  link: {
    cursor: 'pointer',
    padding: '10px'
  }
}))

const ResponsiveDiv = styled.div`
  width: 100%;
  margin-top: 7px;
  @media only screen and (max-width: 720px) {
    width: 100%;
}`

const INIT_FORM = {
  password: '',
  passwordConfirmation: ''
}

const SetNewPassword = ({ enqueueSnackbar }) => {
  const classes = useStyles()
  const [form, setForm] = useState(INIT_FORM)
  const [isLoading, setLoading] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()
  const [errors, setErrors] = useState({})

  //cLog('AUTH TOKEN', authToken)

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    if (!errorTypes[0]) {
      enqueueSnackbar(
        t('auth.fillMissingFields'),
        { variant: 'error' }
      )
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let err = {}

    //cLog('AUTH TOKEN', authToken)

    if (!form.password || form.password.length < 6) {
      err.password = { key: t('auth.shortPassword') }
    }

    if (!form.passwordConfirmation) {
      err.passwordConfirmation = { key: t('auth.shortPassword') }
    } else if (form.password && form.password !== form.passwordConfirmation) {
      err.passwordConfirmation = { key: t('auth.passwordMismatch') }
    }

    if (Object.keys(err).length !== 0) {
      cLog('FORMISSA VIKAA')
      notifyErrors(err)
      setErrors(err)

      return false
    }

    setErrors(err)

    try {
      setLoading(true)
      const response = setNewPassword({ password: form.password, passwordConfirmation: form.passwordConfirmation, resetPasswordToken: authToken })
      response.then(data => {
        cLog('SET NEW PASSWORD RESPONSE', response)
        enqueueSnackbar(
          t('auth.passwordChanged'),
          { variant: 'success' }
        )
        removeAuthToken()
        setLoading(false)
        history.push('/signIn')
      }, error => {
        cLog('SET NEW PASSWORD ERROR', error)
        enqueueSnackbar(
          t('auth.passwordChangedError'),
          { variant: 'error' }
        )
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      cLog('SET NEW PASSWORD ERROR', error)
      enqueueSnackbar(
        t('auth.passwordChangedError'),
        { variant: 'error' }
      )
    }
  }

  return (
    <AuthLayoutContainer>
      <AuthForm title={t('auth.setNewPassword')} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="password"
          name="password"
          label= {t('auth.password')+' *'}
          autoComplete="off"
          value={form.password}
          type="password"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
          error={errors && Object.keys(errors).includes('password')}
        />
        <ResponsiveDiv>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <PasswordStrengthBar
              style={{ width: '90%' }}
              password={form.password}
              minLength={6}
              shortScoreWord={t('auth.tooShort')}
              scoreWords={[t('auth.bad'),t('auth.weak'),t('auth.average'),t('auth.good'),t('auth.strong')]}
            />
            <InfoPop info={t('auth.passwordTip')} />
          </div>
        </ResponsiveDiv>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="passwordConfirmation"
          name="passwordConfirmation"
          label= {t('auth.confirmPassword')+' *'}
          autoComplete="off"
          value={form.passwordConfirmation}
          type="password"
          onChange={(e) => setForm({ ...form, passwordConfirmation: e.target.value })}
          error={errors && Object.keys(errors).includes('passwordConfirmation')}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={28} color='inherit' /> : t('auth.confirm')}
        </Button>
        <Box mt={2}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link className={classes.link} onClick={() => history.push('/forgottenPassword')} variant="body2">
                {t('auth.newForgotPassword')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </AuthForm>
    </AuthLayoutContainer>
  )
}

export default withSnackbar(SetNewPassword)
