import React from 'react'
import AsyncSelect from 'react-select/async'
import InfoPop from './InfoPop'
import request from '../service/request'
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next'
import { REACT_SELECT_STYLES_CONTROL, REACT_SELECT_STYLES_OPTION } from '../styles'

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    width: '100%',
    textAlign: 'left',
  },
  customInputLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(1),
  },
}));

const SelectAsync = (props) => {
  const { t } = useTranslation()
  const theme = useTheme();
  const classes = useStyles();

  const mapOptionsToValues = (options) => {
    return options.map(option => ({
      value: option.id,
      label: `${option.firstName} ${option.lastName} (${option.email})`.trim()
    }));
  }

  const loadOptions = (inputValue, callback) => {
    if (!inputValue) {
      return callback([])
    }

    return request('GET', `/user_names?q=${inputValue}`).then(response =>  {
      callback(mapOptionsToValues(response.data))
    })
  }

  return (
    <div className={classes.customInputContainer}>
      {props.label &&
        <label htmlFor={props.id} className={classes.customInputLabel}>
        <span>{props.label} {props.required && "*"}</span>
          {props.info && <InfoPop info={props.info}/>}
        </label>
      }
      <AsyncSelect
        {...props}
        cacheOptions
        defaultOptions
        ref={props.innerRef}
        isMulti={props.isMulti}
        menuPortalTarget={document.body}
        loadOptions={loadOptions}
        noOptionsMessage={() => t('invoice.noOptionsMessage')}
        styles={{
        option: (provided, state) => REACT_SELECT_STYLES_OPTION(provided, state, theme, props),
        control: (provided, state) => REACT_SELECT_STYLES_CONTROL(provided, state, theme, props),
          menuPortal: base => ({ ...base, zIndex: 9999 })
        }}
      />
    </div>
  )
}

export default SelectAsync
