import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useStateValue } from "../../state"
import Button from '../../components/Button'
import { useHistory } from 'react-router-dom'
import { APIdomain } from '../../service'
import DropDown from '../../components/DropDown'
import { formatTimeFI } from '../../utils'
import { withSnackbar } from 'notistack'
//import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import TitledCard from "../../components/TitledCard"
import KeyValueList from "../../components/KeyValueList"

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    '& > *': {
      margin: theme.spacing(2),
      flexGrow: 1,
    },
  },
  titledCardFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}))

const UserBox2 = ({ user, enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [{ currentUser }] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const taxcard = user.taxcards[0] ? user.taxcards[0].attachments[0] : null
  const attachmentUrl = taxcard ? APIdomain + taxcard.url : ''
  const classes = useStyles()
  let history = useHistory()
  //cLog('TAX CARD', taxcard)
  
  const handleLoadTaxCard = () => {
    history.push('/verokortti/' + user.id)
  }

  let listItems = [
    { key: t('user.industry'), value: user.industry === 'Muu' ? user.customIndustry : user.industry ? t('user.'+user.industry) : '-' },
    { key: t('user.language'), value: t('user.'+user.language) },
    {
      isVisible: taxcard != null,
      key: t('user.taxCard'),
      value: <a target="_blank" rel="noopener noreferrer" href={attachmentUrl}>{`${taxcard && taxcard.filename.slice(0, 20)} ${taxcard && taxcard.dateStart ? formatTimeFI(taxcard.dateStart) : ''}`}</a>
    },
    {
      isVisible: isAdmin && user.taxcards && user.taxcards.length > 1,
      key: t('user.oldTaxCards'),
      value: <DropDown
        style={{minWidth: '180px'}}
        options={user.taxcards.map((taxcard) => ({ label: taxcard.attachments[0].filename + (taxcard.dateStart ? ' ' + formatTimeFI(taxcard.dateStart) : ''), value: APIdomain + taxcard.attachments[0].url }))}
        onChange={(e) => {
          if (e.value !== 'Verokortti on ladattu') {
            window.open(e.value)
          }
        }}
        placeholder={t('invoice.pick')}
      />
    },

    { key: t('user.bankAccountNumber'), value: user.bankAccount },
    { key: t('user.marketingName'), value: user.marketingName || "-" }
  ];

  if (isAdmin) {
    let discountCode = ''
    if (user.discountCode) {
      if (user.discountCode.insurance) {
        discountCode = t('user.insurance') + ' ' + user.discountCode.insurance + ' %'
      }
      if (user.discountCode.companyNotes) {
        if (discountCode.length > 0) {
          discountCode += ', '
        }
        discountCode += user.discountCode.companyNotes
      }
    }

    listItems.push({ key: t('user.discountCode'), value: discountCode || "-" })
  }

  return (
    <TitledCard variant="outlined" color="primary" title={t('user.additionalInfo')} fillWidth fillHeight className={classes.titledCardFlexColumn}>
      <KeyValueList id="user-additional-info-list" items={listItems} />
      <div className={classes.row}><Button variant="outlined" color="primary" onClick={handleLoadTaxCard}>{taxcard ? t('user.uploadNewTaxCard') : t('user.uploadTaxCard')}</Button></div>
    </TitledCard>
  );
}

export default withSnackbar(UserBox2)
