import React from "react";
import { makeStyles, darken } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 0),
    margin: theme.spacing(5, 1, 6, 1),
    borderBottom: theme.borders.thin(darken(theme.palette.grey.main, 0.06)),

    '&.collapsed-xs': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        padding: theme.spacing(0, 0, 3, 0),
      },
    }
  },
  headerActions: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",

    "& > *": {
      margin: theme.spacing(0, 1, 0, 1),
    },
    "& > *:last-child": {
      marginRight: 0,
    },

    '&.collapsed-xs': {
      [theme.breakpoints.down('xs')]: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        marginTop: 0,

        "& > *": {
          margin: theme.spacing(2, 1),
          flexGrow: 1,
        },
      },
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();

  return (
    <div id="header" className={`${classes.header}${props.noCollapse ? "" : " collapsed-xs"}`}>
      <Typography variant="h4" component="h1" color="secondary">{props.title}</Typography>
      <div id="header-actions" className={`${classes.headerActions}${props.noCollapse ? "" : " collapsed-xs"}`}>
        { props.actions }
      </div>
    </div>
  );
}

export default Header;
