import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import { getPromoCodes } from '../service'
import PromoCard from './PromoCard'
import Button from './Button'

function PromoDialog({ open, setOpenPromoDialog, enqueueSnackbar, isAdmin }) {
  const { t } = useTranslation()
  const [promoCodes, setPromoCodes] = useState([])

  useEffect(() => {
    if (open) {
      try {
        let queryString = ''
        if (isAdmin) {
          queryString = '?sort_key=updated_at&sort_order=DESC'
        }
        const request = getPromoCodes(queryString)
        request.then(response => {
          //cLog('PROMOKOODIT', response.promoCodes)
          setPromoCodes(response.promoCodes)
        }, error => {
          cLog('ETUJEN LATAAMISESSA OLI ONGELMIA', error)
        })
      } catch (error) {
        cLog('ETUJEN LATAAMISESSA OLI ONGELMIA', error)
      }
    }
  }, [open, isAdmin])

  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpenPromoDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="promo-dialog-title"
      aria-describedby="promo-dialog-description"
    >
      <DialogTitle id="promo-dialog-title">{t('frontPage.promoCodes')}</DialogTitle>
      <DialogContent dividers={true} id="promo-dialog-description">
        <Typography style={{textAlign: 'center'}}>
          {t('promocodes.intro')}
        </Typography>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography variant="caption">
            {t('promocodes.changes')}
          </Typography>
        </div>
        <Grid container spacing={2}>
          {promoCodes.length > 0
            ? promoCodes.map((item, i) => (
                <Grid key={i} style={{padding: '10px', height: '35vh'}} item sm={6} xs={12}>
                  <PromoCard item={item} />
                </Grid>
              ))
            : t('promocodes.noPromotions')}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpenPromoDialog(false);
          }}
          color="secondary"
        >
          {t('frontPage.cancelButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(PromoDialog)
