import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { ClickAwayListener } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px 10px 2px 0px'
    },
    typography: {
        padding: theme.spacing(2),
    },
    link: {
        color: 'blue',
        '&:hover': {
            cursor: 'pointer'
        }, 
    },
    HETIrow:{
        marginLeft:'.8%'
    }
}));

export default function InfoPopper({ info, anchorEl, handleClose,  }) {
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        disablePortal={false}
        placement={'top'}
    >
        <ClickAwayListener onClickAway={handleClose}>
        <Paper>
        <div style={{ padding: '2px 5px 2px 5px', fontSize: '1.5em' }} key={'typography'} className={classes.typography}>{info}</div>
        </Paper>
        </ClickAwayListener>
    </Popper>
    );
}