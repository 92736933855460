import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { getBackgroundJob, APIdomain } from '../service'
import { cLog, camelToSnakeCase} from '../utils'
import { useTranslation } from 'react-i18next'
import request from '../service/request'

function DownloadDialog({ open, setOpenDownloadDialog, enqueueSnackbar, type, filter, sortKey, sortOrder }) {
  const { t, i18n } = useTranslation()
  const [done, setDone] = useState(0)
  const [total, setTotal] = useState(0)
  const [init, setInit] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      let query = '?download=true&locale=' + i18n.language
      query += '&sort_key=' + sortKey
      query += '&sort_order=' + sortOrder
      Object.keys(filter).forEach(key => {
        if (!['columnsOptions'].includes(key)) {
          if (filter[key].length > 0) {
            if (Array.isArray(filter[key])) {
              query += `&${camelToSnakeCase(key)}=${filter[key].join(',')}`
            } else {
              query += `&${camelToSnakeCase(key)}=${filter[key]}`
            }
          }
          if (typeof filter[key] == 'boolean' && filter[key]) {
            query += `&${camelToSnakeCase(key)}=${filter[key]}`
          }
        }
      })
      return await request('GET', '/' + type + query)
    }

    if (open && init) {
      setInit(false)
      const data = fetchData()
      data.then(response => {
        setTotal(response.data.total)

        const interval = setInterval(function() {
          let request = getBackgroundJob(response.data.jobId)
          request.then(response => {
            if (response.data.done !== done) {
              setDone(response.data.done)

              if (response.data.attachments && response.data.attachments.length > 0) {
                const link = document.createElement('a')
                link.href = APIdomain + response.data.attachments[0].url
                document.body.append(link)
                link.click()
                link.remove()
                setTimeout(() => URL.revokeObjectURL(link.href), 7000)
                clearInterval(interval)
                setOpenDownloadDialog(false)
              }
            }

            if (response.data.total === -1) {
              cLog('DOWNLOAD DATA ERROR1')
              enqueueSnackbar(
                t('auth.problemsLoadingData'),
                { variant: 'error' }
              )
              clearInterval(interval)
              setOpenDownloadDialog(false)
            }
          }, error => {
            cLog('DOWNLOAD DATA ERROR2', error)
            enqueueSnackbar(
              t('auth.problemsLoadingData'),
              { variant: 'error' }
            )
            clearInterval(interval)
            setOpenDownloadDialog(false)
          })
        }, 1000)
      }, error => {
        cLog('DOWNLOAD DATA ERROR3', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        setOpenDownloadDialog(false)
      })
    }
  }, [open, init, enqueueSnackbar, i18n.language, setOpenDownloadDialog, t, done, filter, sortKey, sortOrder, type])

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpenDownloadDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="download-dialog-title"
      aria-describedby="download-dialog-description"
    >
      <DialogTitle id="download-dialog-title">{t('invoice.downloadDialogTitle')}</DialogTitle>
      <DialogContent dividers={true} id="download-dialog-description">
        {t('invoice.downloadDialogDone')} {done} / {total} {t('invoice.downloadDialogDoneRows')}
      </DialogContent>
    </Dialog>
  )
}

export default withSnackbar(DownloadDialog)
