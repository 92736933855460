import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery } from '../../service'
import { useStateValue, setSalaries, setSortKeySalaries, setSortOrderSalaries } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import DownloadOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import { withSnackbar } from 'notistack'
import { APIdomain } from '../../service'
import '../../styles/styles.css'
import { getSalariesPayableAmount } from '../../service'
import Button from '../../components/Button'
import { Box, Grid, Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
  salariesPayableAmount: {
    border: 'none',
    borderRadius: theme.borders.radius.base,
    marginBottom: theme.spacing(4),
  },
}))

let selectedColumns  = []

const INIT_FILTER = {
  id: '',
  userId: [],
  createdAt: '',
  columnsOptions: []
}

const Salaries = ({ enqueueSnackbar }) => {
  const { t, i18n } = useTranslation()
  const [expanded, setExpanded] = useState('salariesPayableAmount')
  const [isLoading, setLoading] = useState(false)
  const [{ salaries, currentUser, sortKeySalaries, sortOrderSalaries }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [forceFetchData, setForceFetchData] = useState(true)
  const [salariesPayable, setSalariesPayable] = useState()
  const [salariesPayableDate, setSalariesPayableDate] = useState(new Date())
  const classes = useStyles()
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: (isAdmin ? 100 : 20),
    totalPages: 0,
    totalRows: 0
  })
  let allColumns = []
  if (isAdmin) {
    allColumns = ['id', 'worker', 'createdAt', 'actions']
    selectedColumns = JSON.parse(window.localStorage.getItem('salariesSelectedColumns')) || allColumns
  } else {
    allColumns = ['id', 'createdAt', 'actions']
    selectedColumns = allColumns
  }
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('salary.'+option),
      value: option
    }
    if (['createdAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({...INIT_FILTER, columnsOptions: selectedColumns})

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      const attachmentUrl = row.attachments && row.attachments.length > 0 ? APIdomain + row.attachments[0].url : ''

      return (
        <span style={{whiteSpace: "nowrap"}}>
          {attachmentUrl && <Tooltip title={t('taxcard.download')}>
            <DownloadOutlinedIcon
              className="actionIcon"
              onClick={() => {
                window.open(attachmentUrl, '_blank', 'noopener,noreferrer')
              }}
            />
          </Tooltip>}
        </span>
      )
    }

    const fetchData = async () => {
      let query = ''
      if (selectedColumns.includes('actions')) {
        query += '&attachments=true'
      }
      const response = getQuery(pagination, setPagination, sortKeySalaries, sortOrderSalaries, filter, selectedColumns, 'salaries', query)
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          return row
        })
        dispatch(setSalaries(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setSalaries([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeySalaries, sortOrderSalaries, filter, pagination, t, enqueueSnackbar])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])


  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.salaries') + coda
  }

  const handleFetchSalariesPayableAmount = () => {
    setLoading(true)
    const salariesPayableAmount = getSalariesPayableAmount({ date: salariesPayableDate })
    salariesPayableAmount.then(response => {
      cLog('SALARIES PAYABLES RESPONSE', response)
      const { totalRows, totalAmount, invoices } = response.data
      setSalariesPayable({ totalRows: totalRows, totalAmount: totalAmount, invoices: invoices })
      setLoading(false)
    }, error => {
      cLog('FETCHING DATA ERROR', error)
      setSalariesPayable(null)
      setLoading(false)
    })
  }

  const downloadData = async () => {
    setLoading(true)
    const data = getSalariesPayableAmount({ date: salariesPayableDate, download: 'true', locale: i18n.language })
    data.then(response => {
      const blob = new Blob([response.data], {type: "text/csv"})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${t('salary.salary')}.csv`
      document.body.append(link)
      link.click()
      link.remove()
      setTimeout(() => URL.revokeObjectURL(link.href), 7000)
      setLoading(false)
    }, error => {
      cLog('SENDING EXPENSE ERROR', error)
      enqueueSnackbar(
        t('salary.downloadProblem'),
        { variant: 'error' }
      )
      setLoading(false)
    })
  }

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.salaries')}
      />
      {isAdmin &&
        <Accordion className={classes.salariesPayableAmount} id='salariesPayableAmount' expanded={expanded === 'salariesPayableAmount'} onChange={handleChangeExpansion('salariesPayableAmount')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="salariesPayableAmount-content"
            id="salariesPayableAmount-header"
          >
            {t('invoice.salariesPayableAmount')}
          </AccordionSummary>
          <AccordionDetails style={{display: 'block'}}>
            <Grid container spacing={4} className={classes.gridContainer}>
              <Grid item xs={12} md={4}>
                <DPicker
                  name="salariesPayableDate"
                  id="salariesPayableDate"
                  label={t('user.date')}
                  selected={salariesPayableDate ? new Date(salariesPayableDate) : ''}
                  onChange={async (date) => {
                    setSalariesPayableDate(date ? formatDate(date) : '')
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={4}>
              {isLoading
                ? <CircularProgress size={25} style={{ marginTop: '2.6rem' }} />
                : <>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{marginRight: '1rem'}}
                        disabled={isLoading}
                        onClick={() => { handleFetchSalariesPayableAmount() }}
                      >
                        {t('taxcard.fetch')}
                      </Button>
                      <Button
                        color="primary"
                        disabled={isLoading}
                        onClick={() => { downloadData() }}
                      >
                        {t('taxcard.download')}
                      </Button>
                  </>
              }
            </Box>
            {salariesPayable && <Box mt={4}>
              <p>{t('salary.salariesPayableTotals', { totalRows: salariesPayable.totalRows, totalAmount: salariesPayable.totalAmount })}</p>
              <p>{salariesPayable.invoices.map((inv, i) => <span key={i}><a href={`/laskut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.invoiceNr}</a>{i === salariesPayable.invoices.length - 1 ? '' : ', '}</span>)}</p>
            </Box>}
          </AccordionDetails>
        </Accordion>}
      {isAdmin && <DatatableFilters
        inputs={[
          { field: 'id', label: t('invoice.salaryData'), type: 'text' },
          { field: 'userId', label: t('invoice.billers'), type: 'userId' },
          { field: 'createdAt', label: t('customers.createdAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />}
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'salariesSelectedColumns'}
        sortKey={sortKeySalaries}
        setSortKey={setSortKeySalaries}
        sortOrder={sortOrderSalaries}
        setSortOrder={setSortOrderSalaries}
        isAdmin={isAdmin}
      />
      <Datatable
        data={salaries}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeySalaries}
        setSortKey={setSortKeySalaries}
        sortOrder={sortOrderSalaries}
        setSortOrder={setSortOrderSalaries}
        isAdmin={isAdmin}
      />
    </LayoutContainer>
  )
}

export default withSnackbar(Salaries)
