import React from 'react'
import { useStateValue } from "../state"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography } from '@material-ui/core'
import { camelToSnakeCase, formatDatetime, formatTimeFI } from '../utils'
//import { cLog } from '../utils'
import { makeStyles } from '@material-ui/core/styles'
import { responsiveTableStyles } from '../styles'
import { cleanFloat } from '../utils/invoiceUtils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'calc(100vh - 160px)',
      overflowX: 'hidden',
    },
  },
  table: {
    ...responsiveTableStyles,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  }
}))

const Datatable = ({ data, fetchData, isLoading, tableTitle, selectedColumns, columnsOptions, sortKey, setSortKey, sortOrder, setSortOrder, isAdmin }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue()
  const classes = useStyles()
  const { t } = useTranslation()

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar>
        <Typography variant="h6" component="div">
          {tableTitle}
        </Typography>
      </Toolbar>
    )
  }

  const EnhancedTableHead = () => {
    const createSortHandler = (value) => (event) => {
      let key = camelToSnakeCase(value)

      if (!['actions'].includes(key)) {
        const order = sortKey === key ? (sortOrder === 'ASC' ? 'DESC' : 'ASC') : sortOrder
        dispatch(setSortKey(key))
        dispatch(setSortOrder(order))
        fetchData()
      }
    }

    const headCells = columnsOptions.filter(option => selectedColumns.includes(option.value))

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.value}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={sortKey === camelToSnakeCase(headCell.value) && !['actions'].includes(camelToSnakeCase(headCell.value)) ? sortOrder.toLowerCase() : false}
              style={headCell.style}
            >
              {!['actions'].includes(camelToSnakeCase(headCell.value))
                ? <TableSortLabel
                    active={sortKey === camelToSnakeCase(headCell.value)}
                    direction={sortOrder.toLowerCase()}
                    onClick={createSortHandler(headCell.value)}
                  >
                    {headCell.label}
                    {sortKey === camelToSnakeCase(headCell.value) ? (
                      <span className={classes.visuallyHidden}>
                        {sortOrder === 'DESC' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                : <div>
                    {headCell.label}
                  </div>
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  return (
    <>
      {tableTitle && <EnhancedTableToolbar />}
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          size={'small'}
          aria-labelledby={`${tableTitle}Table`}
          aria-label={`${tableTitle}Table`}
        >
          <EnhancedTableHead />
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  {columnsOptions.filter(row => selectedColumns.includes(row.value)).map((col, idx) => {
                    if (col.value !== 'actions') {
                      if (col.date) {
                        return <TableCell key={idx}>{row[col.value] && formatTimeFI(row[col.value])}</TableCell>
                      } else if (col.dateTime) {
                        return <TableCell key={idx}>{row[col.value] && formatDatetime(row[col.value])}</TableCell>
                      } else if (col.numeric) {
                        let val = row[col.value] && cleanFloat(row[col.value])
                        if (val && col.decimals) {
                          val = val.toFixed(col.decimals)
                        }
                        return <TableCell key={idx} align={'right'}>{val}</TableCell>
                      } else if (col.totalAddress) {
                        return <TableCell key={idx} style={{whiteSpace: "pre-line"}}>{row[col.value]}</TableCell>
                      } else if (typeof row[col.value] == 'boolean') {
                        return <TableCell key={idx}>{row[col.value] ? t('user.Kyllä') : t('user.Ei')}</TableCell>
                      } else {
                        return <TableCell key={idx}>{row[col.value]}</TableCell>
                      }
                    } else if (row[col.value]) {
                      return <TableCell key={idx}>{row[col.value]}</TableCell>
                    } else {
                      return <TableCell key={idx}></TableCell>
                    }
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Datatable
