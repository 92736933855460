import request from './request'
import { deserializeExpence, deserializeExpences } from '../utils/deserializer'
import { serializeExpence } from '../utils/serializer'
import { cLog } from '../utils'

export const getExpences = async (query) => {
  const { data: expences } = await request('GET', '/expences' + (query || ''))
  //cLog('KAIKKI KULULASKUT', expences)
  return deserializeExpences(expences)
}

export const getExpence = async (id) => {
  const { data: expence } = await request('GET', '/expences/' + id)
  //cLog('RAAKA EXPENCE', expence)
  const deserializedExpence = deserializeExpence(expence)
  //cLog('PARSITTU EXPENCE', deserializedExpence)
  return deserializedExpence
}

export const sendExpenseRequest = async (data) => {
  delete data.updateHistory
  const { data: updatedExpence } = await request('PUT', `/expences/${data.id}/send_expence`)
  return deserializeExpence(updatedExpence)
}

export const saveExpence = async (data) => {
  delete data.updateHistory
  const { data: newExpence } = await request('POST', '/expences', serializeExpence(data))
  return deserializeExpence(newExpence)
}

export const updateExpence = async (data) => {
  delete data.updateHistory
  const { data: updatedExpence } = await request('PUT', `/expences/${data.id}`, serializeExpence(data))
  return deserializeExpence(updatedExpence)
}

export const deleteExpence = async (data) => await request('DELETE', `/expences/${data.id}`)

export const printExpencePdf = async (data) => {
  delete data.updateHistory
  cLog('PRINT PDF DATA', data)
  const response = await request('PUT', `/expences/${data.id}/print_pdf`, data)
  return response
}

export const getAllowances = async (data) => {
  cLog('UPDATING ALLOWANCES', data)
  const response = await request('GET', '/allowances/' + data)
  return response
}
