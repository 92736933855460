import React from 'react'
import { FONTS, REACT_SELECT_STYLES_OPTION, muiTheme } from '../styles'
import Select, { components } from 'react-select'
import { useTheme } from "@material-ui/core/styles";
const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }) => {
  let childInput = null
  React.Children.map(children, child => {
    if (child && child.key === null) {
      childInput = child
    }
  })

  return (
    <ValueContainer {...props}>
      {childInput && String(childInput.props.value).trim().length === 0 && <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>}
      {childInput}
    </ValueContainer>
  )
}

const CustomOption = ({ children, ...props }) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const customStyles = (theme) => ({
  container: base => ({
    ...base,
    display: 'inline-block',
    minWidth:'214px',
  }),
  option: (provided, state) => REACT_SELECT_STYLES_OPTION(provided, state, theme),
  control: base => ({
    ...base,
    background: muiTheme.palette.white.main,
    border: muiTheme.borders.thin(muiTheme.palette.grey.dark),
    borderRadius: muiTheme.borders.radius.small,
    boxShadow: 'none',
    fontFamily: 'sans-serif',
    fontSize: FONTS.FONT_SIZE1,
    minHeight: '32px',
    height: '32px',
    transition: 'border 0.125s',
    cursor: 'pointer',

    '&:hover': {
      border: muiTheme.borders.thin(muiTheme.palette.primary.main),
    }
  }),
  valueContainer: base => ({
    ...base,
    fontSize: '14px',
    overflow: 'visible',
    height: '25px',
    padding: '0 4px',
    top: '-2px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#000000',
    height: '32px',
    // padding: 0,
    paddingTop: '8px',
    paddingRight: '3px',
    '&:hover': {
      color: '#000000'
    },
    '& > svg': {
      width: '14px',
      height: '14px',
    }
  })
})

const DropdownCheckbox = (props) => {
  const theme = useTheme();

  return (
    <Select
      {...props}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      isMulti={true}
      isClearable={false}
      components={{ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, Option: CustomOption}}
      placeholder={props.placeholder}
      styles={customStyles(theme)}
    />
  )
}

export default DropdownCheckbox
