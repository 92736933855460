import React from "react";
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import ShortcutButton from "../../components/ShortcutButton";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InviteUserIcon from "@material-ui/icons/PersonAdd"
import AddCustomerIcon from "@material-ui/icons/AccountCircle"
import UserDataIcon from "@material-ui/icons/Home"
import NewInvoiceIcon from "@material-ui/icons/PostAdd"
import StarsIcon from "@material-ui/icons/Stars"
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useStateValue } from "../../state"
import InfoPopper from '../../components/InfoPopper'

const useStyles = makeStyles((theme) => ({
  landingShortcuts: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    padding: theme.spacing(4, 0),

    "& > *": {
      flexGrow: 1,
    },

    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(-2, -2),
      "& > *": {
        margin: `${theme.spacing(2, 2)} !important`,
      },
    }
  },
  errorIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    width: '2rem !important',
    height: '2rem !important',
    color: theme.palette.error.main,

    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.error.light,
    },
  }
}));

const sizes = { xs: 12, sm: 12,  md: 26, lg: 26, xl: 26 };

const LandingShortcuts = (props) => {
  const { t } = useTranslation()
  let history = useHistory()
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [{ currentUser }] = useStateValue()
  const [anchorMissingInfo, setAnchorMissingInfo] = React.useState(null)

  const missingInfoText = <div>{t('user.userHasMissingInformation')}<a href={'/kayttajat/muokkaa/'+currentUser.id}>{t('user.userHasMissingInformationButton')}</a></div>

  const handleMissingInfoOpen = (event) => {
    event.stopPropagation()
    setAnchorMissingInfo(anchorMissingInfo ? null : event.currentTarget);
  }

  const handleMissingInfoClose = () => {
    setAnchorMissingInfo(null)
  }

  return (
    <div className={classes.landingShortcuts}>
      <InfoPopper info={missingInfoText} anchorEl={anchorMissingInfo} handleClose={handleMissingInfoClose}/>
      <ShortcutButton
        onClick={() => history.push("/laskut/uusi")}
        size={sizes}
        inline={isMobile}
        variant={isMobile && "outlined"}
      >
        <NewInvoiceIcon/>
        <span>{t("frontPage.newInvoice")}</span>
      </ShortcutButton>
      <ShortcutButton
        onClick={() => history.push("/asiakkaat/uusi")}
        size={sizes}
        inline={isMobile}
        variant={isMobile && "outlined"}
      >
        <AddCustomerIcon/>
        <span>{t("frontPage.addCustomer")}</span>
      </ShortcutButton>
      <ShortcutButton
        onClick={() => history.push("/kayttajat/" + props.currentUser.id)}
        size={sizes}
        inline={isMobile}
        variant={isMobile && "outlined"}
      >
        {currentUser.missingInfo && <ErrorOutlineIcon onClick={handleMissingInfoOpen} className={classes.errorIcon}/>}
        <UserDataIcon/>
        <span>{t("frontPage.userInfo")}</span>
      </ShortcutButton>
      <ShortcutButton
        onClick={() => props.setOpenInviteUserDialog(true)}
        size={sizes}
        inline={isMobile}
        variant={isMobile && "outlined"}
      >
        <InviteUserIcon/>
        <span>{t("frontPage.inviteUser")}</span>
      </ShortcutButton>
      <ShortcutButton
        onClick={() => props.setOpenPromoDialog(true)}
        size={sizes}
        inline={isMobile}
        variant={isMobile && "outlined"}
      >
        <StarsIcon/>
        <span>{t("frontPage.promoCodes")}</span>
      </ShortcutButton>
    </div>
  );
};

export default LandingShortcuts;
