import React, { useState, useEffect } from 'react'
import {  Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import Input from './Input'
import { useTranslation } from 'react-i18next'
import { cLog, roundNumber } from '../utils'
import { updateInvoice } from '../service'
import DPicker from './DPicker' 
import { formatDate } from '../utils'
import { Field } from 'formik'
import { withSnackbar } from 'notistack'
import { validateTextArea, validateDate, validateFloatGreaterThan } from '../screens/Invoice/validationSchemas'
import Button from './Button'

const InvoicePaymentDialog = React.forwardRef(
  (props, ref) => {
    const {
      resetForm,
      values,
      setFieldValue,
      validateForm,
      errors,
      enqueueSnackbar,
      open,
      setOpenInvoicePaymentDialog,
      paymentInvoice,
      isLoading,
      setLoading,
      fetchData,
      sortKey,
      sortOrder
    } = props

    const { t } = useTranslation()
    const [invoiceStatus, setInvoiceStatus] = useState("MAKSETTU")

    useEffect(() => {
      setFieldValue('txAmt', paymentInvoice ? roundNumber(paymentInvoice.totalCorrectedWithVatEur - paymentInvoice.totalPaymentsWithTax, 2) : '')
    }, [paymentInvoice, setFieldValue])

    const handleChangetxAmt = e => {
      const changedValue = e.target.value

      if (changedValue < paymentInvoice.totalCorrectedWithVatEur - paymentInvoice.totalPaymentsWithTax) {
        setInvoiceStatus("OSITTAIN_MAKSETTU")
      } else if (changedValue >= paymentInvoice.totalCorrectedWithVatEur - paymentInvoice.totalPaymentsWithTax) {
        setInvoiceStatus("MAKSETTU")
      }

      setFieldValue('txAmt', changedValue)
    }

    const notifyErrors = (erroris) => {
      const errorTypes = Array.from(new Set(
        [].concat(...[].concat(...Object.keys(erroris)
          .map((invoiceType) => erroris[invoiceType])
          .map((fields) => Array.isArray(fields)
            ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
            : fields.key
          )
        ))
      ))
      errorTypes.reverse()
      errorTypes
        .filter(type => type)
        .forEach(type => {
          enqueueSnackbar(
            type,
            { variant: 'error' }
          )
        })
      if (!errorTypes[0]) {
        enqueueSnackbar(
          t('auth.fillMissingFields'),
          { variant: 'error' }
        )
      }
    }

    const handleSavePayment = async () => {
      setLoading(true)
      const errori = await validateForm()
      if (Object.keys(errori).length > 0) {
        notifyErrors(errori)
        setLoading(false)
      } else {
        const request = updateInvoice({ id: paymentInvoice.id, payments: [values] })

        request.then(response => {
          cLog('INVOICE PAYMENT', response)
          setLoading(false)
          enqueueSnackbar(
            t('invoice.paymentAdded'),
            { variant: 'success' }
          )
          setOpenInvoicePaymentDialog(false)
          resetForm()
          fetchData(sortKey, sortOrder)
        }).catch(error => {
          cLog('INVOICE PAYMENT ERROR', error)
          setLoading(false)
          enqueueSnackbar(
            t('invoice.paymentError'),
            { variant: 'error' }
          )
        })
      }
    }

    return (
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setLoading(false)
            setOpenInvoicePaymentDialog(false)
            resetForm()
          }
        }}
        disableEscapeKeyDown={true}
        aria-labelledby="invoice-payment-dialog-title"
        aria-describedby="invoice-payment-dialog-description"
        className={'dialog-overflow'}
      >
        <DialogTitle id="invoice-payment-dialog-title">{t('invoice.setAsPaid')}{paymentInvoice && paymentInvoice.invoiceNr}</DialogTitle>
        <DialogContent dividers={true} id="invoice-payment-dialog-description">
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Field
                  name="notes"
                  id='notes'
                  label={t('invoice.notes')}
                  type='text'
                  component={Input}
                  value={values.notes}
                  onChange={(e) => { setFieldValue('notes', e.target.value) }}
                  validate={() => validateTextArea(values.notes, t('invoice.notes'), 10000, false)}
                  error={errors && values.notes && errors.notes && validateTextArea(values.notes, t('invoice.notes'), 10000, false)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="txAmt"
                  id='txAmt'
                  label={t('invoice.sum')+' *'}
                  type="text"
                  component={Input}
                  value={values.txAmt || '0'}
                  onChange={handleChangetxAmt}
                  validate={() => validateFloatGreaterThan(values.txAmt, 0, 9999999.99, t('invoice.sum'), 2)}
                  error={errors && errors.txAmt && validateFloatGreaterThan(values.txAmt, 0, 9999999.99, t('invoice.sum'), 2)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="valDt"
                  id="valDt"
                  label={t('invoice.valDt')+' *'}
                  component={DPicker}
                  selected={values.valDt ? new Date(values.valDt) : ''}
                  onChange={(date) => setFieldValue('valDt', date ? formatDate(date) : '')}
                  validate={() => validateDate(values.valDt, t('invoice.valDt'), true)}
                  error={errors && errors.valDt && validateDate(values.valDt, t('invoice.valDt'), true)}
                />
              </Grid>
              {invoiceStatus && <Grid item xs={12} sm={12}><h4>{t('invoice.statusWillChange')}{t('invoice.'+invoiceStatus)}.</h4> </Grid>}            
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            disabled={isLoading}
            onClick={() => {
              setLoading(false)
              setOpenInvoicePaymentDialog(false)
              resetForm()
            }}
          >
            {t('user.cancel')}
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={isLoading}
            onClick={handleSavePayment}
          >
            {isLoading ? <CircularProgress size={20} /> : t('invoice.save')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default withSnackbar(InvoicePaymentDialog)
