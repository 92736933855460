import { cleanFloat } from './invoiceUtils'
import { cLog } from '.'

const client = (invoice) => {
  if (!invoice.client) {
    return {}
  }

  const { id, userId, name, address1, address2, postalCode, city, state, country, email, phone, isPrivate, contactPerson, businessId, language } = invoice.client
  return ({
    clientId: id,
    userId,
    name,
    address1,
    address2,
    postalCode,
    city,
    state,
    country: country || 'FINLAND',
    email,
    phone,
    isPrivate,
    contactPerson,
    businessId,
    language: language || 'FINNISH',
    deliveryMethod: invoice.deliveryMethod || 'VERKKOLASKU', 
    reminderHandling: invoice.reminderHandling || 'AUTOMAATTINEN'
  })
}

const serializeTravellingExpenceRow = (row) => {
  let serializedTravellingExpenceRow = {
    ...row,
    km: cleanFloat(row.km),
    dailyAllowances: cleanFloat(row.dailyAllowances),
    total: cleanFloat(row.total),
    specialIndustryOvernight: row.travelType === 'ERITYISALA' && row.specialIndustryOvernight !== null ? (row.specialIndustryOvernight === 'true' ? true : false) : '',
    specialIndustryPrimaryPlace: row.travelType === 'ERITYISALA' && row.specialIndustryPrimaryPlace !== null ? (row.specialIndustryPrimaryPlace === 'true' ? true : false) : '',
    specialIndustryDining: row.travelType === 'ERITYISALA' && row.specialIndustryDining !== null ? (row.specialIndustryDining === 'true' ? true : false) : '',
    mobileWorkPrimaryPlace: row.travelType === 'LIIKKUVA_TYO' && row.mobileWorkPrimaryPlace !== null ? (row.mobileWorkPrimaryPlace === 'true' ? true : false) : '',
    daAmount: cleanFloat(row.daAmount),
    ptdaAmount: cleanFloat(row.ptdaAmount),
    meAmount: cleanFloat(row.meAmount),
    mealExpences: row.travelType === 'ERITYISALA' ? row.mealExpences : 0,
    purposeNotes: row.purpose === 'OTHER' ? row.purposeNotes : null,
    travelRowDestinationsAttributes: row.travelRowDestinations && row.travelRowDestinations.length > 0
      && row.travelRowDestinations.map(dest => ({
      ...dest,
      km: dest.km ? cleanFloat(dest.km) : 0,
      kmGoogle: dest.kmGoogle ? cleanFloat(dest.kmGoogle) : 0
    }))
  }

  delete serializedTravellingExpenceRow.allowances
  delete serializedTravellingExpenceRow.travelRowDestinations
  delete serializedTravellingExpenceRow.overlapData

  return serializedTravellingExpenceRow
}

const serializeMaterialExpenceRow = (row) => {
  let serializedMaterialExpenceRow = {
    ...row,
    sum: cleanFloat(row.sum),
    price: cleanFloat(row.price),
    tax: cleanFloat(row.tax),
    priceWithTax: cleanFloat(row.priceWithTax),
    attachmentDetailsAttributes: row.attachments && row.attachments.length > 0
      && row.attachments.map(attachment => {
      let attachmentDetail = {
        ...attachment,
        id: attachment.attachmentDetailId
      }

      return attachmentDetail
    })
  }

  return serializedMaterialExpenceRow
}

export const serializeInvoice = (invoice) => {
  //cLog('SERIALISOIDAAN INVOICE', invoice)
  const { totalWithTax, tax, expenceTotal, travelTotal, invoiceTotal, invoiceRows, travellingExpenceRows, materialExpenceRows, accounts, payments, correctionRows, exchangeRate
  } = invoice

  let serializedInvoice = {
    ...invoice,
    ...client(invoice),
    exchangeRate: cleanFloat(exchangeRate),
    totalWithTax: cleanFloat(totalWithTax),
    tax: cleanFloat(tax),
    expenceTotal: cleanFloat(expenceTotal),
    travelTotal: cleanFloat(travelTotal),
    invoiceTotal: cleanFloat(invoiceTotal),
    invoiceRows: invoiceRows && invoiceRows.length > 0
      && invoiceRows.map(row => ({
        ...row,
        quantity: cleanFloat(row.quantity),
        price: cleanFloat(row.price),
        priceWithTax: cleanFloat(row.priceWithTax),
        tax: cleanFloat(row.tax),
        total: cleanFloat(row.total)
      })),
    accounts: accounts && accounts.length > 0
      && accounts.map(row => ({
        ...row,
        amount: cleanFloat(row.amount)
      })),
    correctionRows: correctionRows && correctionRows.length > 0
      && correctionRows.map(row => ({
        ...row,
        amount: cleanFloat(row.amount),
        tax: cleanFloat(row.tax),
        amountWithTax: cleanFloat(row.amountWithTax),
        quantity: cleanFloat(row.quantity),
        total: cleanFloat(row.total)
      })),
    payments: payments && payments.length > 0
      && payments.map(row => ({
        ...row,
        txAmt: cleanFloat(row.txAmt)
      })),
    travellingExpenceRows: travellingExpenceRows && travellingExpenceRows.length > 0
      && travellingExpenceRows.map(row => serializeTravellingExpenceRow(row)),
    materialExpenceRows: materialExpenceRows && materialExpenceRows.length > 0
        && materialExpenceRows.map(row => serializeMaterialExpenceRow(row))
  }

  delete serializedInvoice.thisUser
  delete serializedInvoice.createdAt
  delete serializedInvoice.updatedAt
  delete serializedInvoice.procountorId
  delete serializedInvoice.inviterDiscountGiven
  delete serializedInvoice.statusMaksettuDate
  delete serializedInvoice.totalPaymentsWithTax
  delete serializedInvoice.statusOsittainMaksettuDate
  delete serializedInvoice.statusTarkastettavanaDate
  delete serializedInvoice.refCode
  delete serializedInvoice.accountingStatus
  delete serializedInvoice.accountingStatusOsittainTilitettyDate
  delete serializedInvoice.accountingStatusTilitettyDate
  delete serializedInvoice.totalAccounting
  delete serializedInvoice.origTotalVatExcluded
  delete serializedInvoice.salariesPayableAmount
  delete serializedInvoice.statusLahetettyDate
  delete serializedInvoice.statusMaksettuLiikaaDate
  delete serializedInvoice.statusPoistettuDate
  delete serializedInvoice.statusPerinnassaDate
  delete serializedInvoice.statusUlosotossaDate
  delete serializedInvoice.statusLuottotappioDate
  delete serializedInvoice.statusMuistutettuDate
  delete serializedInvoice.statusHyvitettyDate
  delete serializedInvoice.consolidatedInvoice
  delete serializedInvoice.statusHyvityslaskuDate
  delete serializedInvoice.totalCorrections
  delete serializedInvoice.totalCorrectionsVat
  delete serializedInvoice.totalCorrectionsWithVat
  delete serializedInvoice.origTotalVatExcludedEur
  delete serializedInvoice.taxEur
  delete serializedInvoice.totalWithTaxEur
  delete serializedInvoice.totalCorrectedEur
  delete serializedInvoice.totalCorrectedVatEur
  delete serializedInvoice.totalCorrectedWithVatEur
  delete serializedInvoice.creditNote
  delete serializedInvoice.id
  delete serializedInvoice.expenceNr
  delete serializedInvoice.invoiceNr
  delete serializedInvoice.name
  delete serializedInvoice.client
  delete serializedInvoice.address1
  delete serializedInvoice.address2
  delete serializedInvoice.postalCode
  delete serializedInvoice.city
  delete serializedInvoice.country
  delete serializedInvoice.email
  delete serializedInvoice.phone
  delete serializedInvoice.einvoiceOperator
  delete serializedInvoice.einvoiceAddress
  delete serializedInvoice.isPrivate
  delete serializedInvoice.businessId
  delete serializedInvoice.contactPerson
  delete serializedInvoice.language
  delete serializedInvoice.userEmail
  delete serializedInvoice.userFirstName
  delete serializedInvoice.userLastName
  delete serializedInvoice.updateHistory
  delete serializedInvoice.vat24

  cLog('SERIALISOITU INVOICE', serializedInvoice)
  return serializedInvoice
}

export const serializeExpence = (expence) => {
  //cLog('SERIALISOIDAAN EXPENCE', expence)

  const { exp, ...rest } = expence
  let serializedExpence = {
    ...rest,
    travelTotal: cleanFloat(expence.travelTotal),
    expenceTotal: cleanFloat(expence.expenceTotal),
    totalWithTax: cleanFloat(expence.totalWithTax),
    materialExpenceRows: expence.materialExpenceRows && expence.materialExpenceRows.length > 0
        && expence.materialExpenceRows.map(row => serializeMaterialExpenceRow(row)),
    accounts: expence.accounts && expence.accounts.length > 0
      && expence.accounts.map(row => ({
        ...row,
        amount: cleanFloat(row.amount)
      })),
    travellingExpenceRows: expence.travellingExpenceRows && expence.travellingExpenceRows.length > 0
        && expence.travellingExpenceRows.map(row => serializeTravellingExpenceRow(row))
  }

  delete serializedExpence.thisUser
  delete serializedExpence.id
  delete serializedExpence.expenceNr
  delete serializedExpence.createdAt
  delete serializedExpence.updatedAt
  delete serializedExpence.procountorId
  delete serializedExpence.refCode
  delete serializedExpence.totalAccounting
  delete serializedExpence.salariesPayableAmount
  delete serializedExpence.statusValmisDate
  delete serializedExpence.statusOsittainMaksettuDate
  delete serializedExpence.statusMaksettuDate
  delete serializedExpence.statusHylattyDate
  delete serializedExpence.statusPoistettuDate
  delete serializedExpence.sentDate
  delete serializedExpence.vat24
  //cLog('SERIALISOITU EXPENCE', serializedExpence)
  return serializedExpence
}

export const serializeWorklist = (worklist) => {
  //cLog('SERIALISOIDAAN WORKLIST', worklist)

  let serializedWorklist = {
    ...worklist,
    totalHours: cleanFloat(worklist.totalHours),
    worklistRows: worklist.worklistRows && worklist.worklistRows.length > 0
      && worklist.worklistRows.map(row => ({
        ...row,
        hours: cleanFloat(row.hours)
      }))
  }

  delete serializedWorklist.clientName
  delete serializedWorklist.id
  delete serializedWorklist.createdAt
  delete serializedWorklist.updatedAt
  delete serializedWorklist.userFirstName
  delete serializedWorklist.userLastName
  //cLog('SERIALISOITU WORKLIST', serializedWorklist)
  return serializedWorklist
}

export const serializeDiscountCode = (discountCode) => {
  delete discountCode.id
  delete discountCode.createdAt
  delete discountCode.updatedAt
  delete discountCode.discountsUsed
  if (discountCode.inviterType === 'YRITYS') {
    discountCode.code = ''
    discountCode.signUpBonus = ''
  } else if (discountCode.inviterType === 'KAMPANJA') {
    discountCode.businessId = ''
    discountCode.companyName = ''
  } else {
    discountCode.businessId = ''
    discountCode.companyName = ''
    discountCode.companyNotes = ''
    discountCode.insurance = ''
  }
  if (discountCode.commission) {
    discountCode.commission = cleanFloat(discountCode.commission)
  }
  if (discountCode.insurance) {
    discountCode.insurance = cleanFloat(discountCode.insurance)
  }
  if (discountCode.signUpBonus) {
    discountCode.signUpBonus = cleanFloat(discountCode.signUpBonus)
  }

  return discountCode
}

export const serializeUser = (user) => {
  const { commission, odealAccount, notAccounted } = user

  let serializedUser = {
    ...user,
    commission: cleanFloat(commission),
    odealAccount: cleanFloat(odealAccount),
    notAccounted: cleanFloat(notAccounted),
    taxcards: user.taxcards && user.taxcards.length > 0
      && user.taxcards.map(row => {
        if (row.base) {
          row.base = cleanFloat(row.base)
        }
        if (row.additional) {
          row.additional = cleanFloat(row.additional)
        }
        if (row.annualIncomeLimit) {
          row.annualIncomeLimit = cleanFloat(row.annualIncomeLimit)
        }
        return row
      })
  }
  delete serializedUser.createdAt
  delete serializedUser.updatedAt
  delete serializedUser.id
  delete serializedUser.updateHistory

  //cLog('SERIALISOITU USER', serializedUser)
  return serializedUser
}
