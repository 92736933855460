import React, { useState } from 'react'
import Input from './Input'
import { Autocomplete } from "@react-google-maps/api"
//import { cLog } from '../utils'
import { validateTextArea } from '../screens/Invoice/validationSchemas'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { totalTravellingExpenceRow, totalTravellingExpenceRowKm, cleanFloat } from '../utils/invoiceUtils'
import { getUserLocation } from '../service'
import { withSnackbar } from 'notistack'

let googleMaps = false

const AutocompleteInput = ({ setFieldValue, i2, travellingExpenceRows, arrayHelpers, i, row2, calculateDistance, setFieldError, enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [searchResults, setSearchResults] = useState(null)
  const [dirty, setDirty] = useState(false)

  const errors2 = arrayHelpers.form.errors.travellingExpenceRows && arrayHelpers.form.errors.travellingExpenceRows[i] && arrayHelpers.form.errors.travellingExpenceRows[i].travelRowDestinations
          ? arrayHelpers.form.errors.travellingExpenceRows[i].travelRowDestinations[i2]
          : {}

  function findKeyInNestedJSON(data, key) {
    if (typeof data === 'object') {
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
      for (let prop in data) {
        if (data.hasOwnProperty(prop)) {
          const result = findKeyInNestedJSON(data[prop], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }
    
    return undefined;
  }

  const onLoad = async (autocomplete) => {
    if (autocomplete) {
      let lat = cleanFloat(window.localStorage.getItem('userLocationLat'))
      let lng = cleanFloat(window.localStorage.getItem('userLocationLng'))
      if (!lat || !lng) {
        const location = await getUserLocation()
        if (location && location.lat && location.lng) {
          lat = location.lat
          lng = location.lng
        }
      }

      if (lat && lng) {
        const circle = new window.google.maps.Circle({
          center: { lat: lat, lng: lng },
          radius: 10000
        });
          
        autocomplete.setOptions({
          fields: ['formatted_address', 'geometry'],
          bounds: circle.getBounds()
        });
      } else {
        autocomplete.setOptions({
          fields: ['formatted_address', 'geometry']
        });
      }
    }
    setSearchResults(autocomplete)
  }

  const handlePlaceChanged = (i2) => {
    if (searchResults != null) {
      googleMaps = true

      const pred = searchResults['gm_bindings_']
      const keyToFind = 'formattedPrediction';
      const result = findKeyInNestedJSON(pred, keyToFind);

      if (result) {
        setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`, result)
        setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.verified`, true)

        if (i2 !== 0) {
          calculateDistance(travellingExpenceRows[i].travelRowDestinations[i2 - 1].destination, result, i2, travellingExpenceRows[i])
        }
        if (i2 !== travellingExpenceRows[i].travelRowDestinations.length - 1) {
          calculateDistance(result, travellingExpenceRows[i].travelRowDestinations[i2 + 1].destination, i2 + 1, travellingExpenceRows[i])
        }
      }
    } else {
      alert("ERROR!");
    }
  }

  return (
    <Autocomplete onPlaceChanged={() => handlePlaceChanged(i2)} onLoad={onLoad}>
      <Field
        name={`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`}
        id={`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`}
        label={i2 === 0 ? t('invoice.origin')+' *' : t('invoice.destination')+` ${i2} *`}
        type='text'
        component={Input}
        value={travellingExpenceRows[i].travelRowDestinations[i2].destination || ''}
        onChange={(e) => {
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`, e.target.value)
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.km`, i2 === 0 ? 0 : '')
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.kmGoogle`, 0)
          let trow = { ...travellingExpenceRows[i], destinations: travellingExpenceRows[i].travelRowDestinations.map((item, idx) => idx === i2 ? item.km = 0 : item) }
          if (i2 !== travellingExpenceRows[i].travelRowDestinations.length - 1) {
            setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2 + 1}.km`, i2 === 0 ? 0 : '')
            setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2 + 1}.kmGoogle`, 0)
            trow = { ...trow, destinations: trow.travelRowDestinations.map((item, idx) => idx === i2 + 1 ? item.km = 0 : item) }
          }
          setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
          setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
          setDirty(true)
          googleMaps = false
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.verified`, false)
        }}
        onPaste={(e) => {
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`, e.target.value)
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.km`, i2 === 0 ? 0 : '')
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.kmGoogle`, 0)
          let trow = { ...travellingExpenceRows[i], destinations: travellingExpenceRows[i].travelRowDestinations.map((item, idx) => idx === i2 ? item.km = 0 : item) }
          if (i2 !== travellingExpenceRows[i].travelRowDestinations.length - 1) {
            setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2 + 1}.km`, i2 === 0 ? 0 : '')
            setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2 + 1}.kmGoogle`, 0)
            trow = { ...trow, destinations: trow.travelRowDestinations.map((item, idx) => idx === i2 + 1 ? item.km = 0 : item) }
          }
          setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
          setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
          setDirty(true)
          googleMaps = false
          setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.verified`, false)
        }}
        validate={() => validateTextArea(row2.destination, t('invoice.destination'), 5000, true)}
        error={errors2 && errors2.destination && validateTextArea(row2.destination, t('invoice.destination'), 5000, true)}
        onBlur={() => {
          if (dirty) {
            setTimeout(() => {
              if (!googleMaps) {
                setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`, '')
                setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.km`, i2 === 0 ? 0 : '')
                setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.kmGoogle`, 0)
                let trow = { ...travellingExpenceRows[i], destinations: travellingExpenceRows[i].travelRowDestinations.map((item, idx) => idx === i2 ? item.km = 0 : item) }
                if (i2 !== travellingExpenceRows[i].travelRowDestinations.length - 1) {
                  setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2 + 1}.km`, i2 === 0 ? 0 : '')
                  setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${i2 + 1}.kmGoogle`, 0)
                  trow = { ...trow, destinations: trow.travelRowDestinations.map((item, idx) => idx === i2 + 1 ? item.km = 0 : item) }
                }
                setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
                setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
                setFieldError(`travellingExpenceRows.${i}.travelRowDestinations.${i2}.destination`, t('invoice.missing', { field: t('invoice.destination') }))
                enqueueSnackbar(
                  t('invoice.selectDestination'),
                  { variant: 'error' }
                )
              }
            }, 100)
          }
        }}
      />
    </Autocomplete>
  )
}

export default withSnackbar(AutocompleteInput)
