import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(4),
  },
  icon: {
    margin: 0,
    padding: 0,
    width: '22px',
    height: '22px',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  link: {
    color: theme.palette.info.main,
    '&:hover': {
      cursor: 'pointer'
    },
  },
  lessMargin: {
    marginTop: '9px'
  }
}));

export default function PopInfo({ info, className }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (info) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <HelpOutlineIcon
        aria-describedby={id}
        onClick={handleClick}
        className={`${classes.icon} ${className ? classes.lessMargin : ""}`}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {/*  <Typography className={classes.typography}>{info}</Typography> */}
        <div className={classes.typography}>
          {info && info.split(';').map((row, i) => {
            return (
              <div style={{ padding: '2px 5px 2px 5px' }} key={i} className={classes.typography}>{
                row.includes('</Link>')
                  ? row.split('</Link>').map((r1, i2) => {
                      let r2 = r1.split('<Link to="')
                      if (r2.length > 1) {
                        let r3 = r2[1].split('">')

                        return (<Trans key={i2}>{r2[0]}<a href={r3[0]} target="_blank" rel="noopener noreferrer">{r3[1]}</a></Trans>)
                      } else {
                        if (r2[0].length > 0) {
                          let r3 = r2[0].split('">')

                          return (<Trans><a href={r3[0]} target="_blank" rel="noopener noreferrer">{r3[1]}</a></Trans>)
                        }
                      }

                      return null
                    })
                  : (row.length > 0 ? row : <br/>)
              }</div>
            )
          })}
        </div>
      </Popover>
    </>
  );
}
