import React, { useRef, useState, useEffect } from 'react'
import { useStateValue, setClients } from "../../state"
import { getInvoice, getExpence, getUser, getWorklist, getClientNames, getClient } from '../../service'
import { CLIENT_FORM_TEMPLATE, INITIAL_VALUES, worklistToString, INVOICE_ROW_TEMPLATE, vat24ChangeDate } from '../../utils/invoiceUtils'
import { getDateNow } from '../../utils'
import { Formik } from 'formik'
import { useParams } from "react-router-dom"
import ClientForm from '../Clients/ClientForm'
import InvoiceForm from './InvoiceForm'
import { cLog } from '../../utils'
import { useHistory } from 'react-router-dom'
import LayoutContainer from "../../components/LayoutContainer"

const Invoice = ({ exp, expense, edit, recycle, view, worklist, create }) => {
  const [{ currentUser }, dispatch] = useStateValue()
  const [editClientForm, setEditClientForm] = useState(false)
  const [openClientModal, setOpenClientModal] = useState(false)
  const [oldInvoice, setOldInvoice] = useState(null)
  const [thisWorklist, setThisWorklist] = useState(null)
  const [client, setClient] = useState(null)
  const [init, setInit] = useState(false)
  const [userData, setUserData] = useState(null)
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [discountCodeOptions] = useState([])
  let history = useHistory()
  const placesLibrary = ['places']
  const [initValidate, setInitValidate] = useState(false)

  let { id, worklistId, userId, clientId } = useParams()

  const clientRef = useRef()
  const invoiceRef = useRef()

  //cLog('CURRENT USER', currentUser)
  //cLog('USE PARAMS', useParams())

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isAdmin) {
          const updatedCurrentUser = await getUser(currentUser.id)
          setUserData(updatedCurrentUser)
        }

        if (worklist) { ///// JOS TYÖLISTA
          const gettingWorklist = await getWorklist(worklistId)
          setThisWorklist(gettingWorklist)
          cLog('THIS WORK LIST', gettingWorklist)
          const thisWorklistClient = await getClient(gettingWorklist.clientId)
          cLog('THIS WORK CLIENT', thisWorklistClient)
          setClient(thisWorklistClient)
          setInitValidate(true)

          if (!isAdmin) {
            const clients = await getClientNames()
            cLog('LASKUTTAJAN OMAT CLIENTIT', clients)
            dispatch(setClients(clients))
          } else {
            const thisUser = await getUser(gettingWorklist.userId)
            setUserData(thisUser)
            const clients = await getClientNames('?user_id=' + gettingWorklist.userId)
            cLog('ALL USERS CLIENTS', clients)
            dispatch(setClients(clients))
          }
        } else {
          if (!id) {                                               ///// JOS UUSI
            if (!isAdmin) {
              const clients = await getClientNames()
              cLog('LASKUTTAJAN OMAT CLIENTIT', clients)
              dispatch(setClients(clients))
              if (create) {
                const thisCreateClient = await getClient(clientId)
                setClient(thisCreateClient)
              }
            } else {
              if (create) {                                         //// JOS TOIMISTO JA LUODAAN ASIAKKAASTA KÄSIN
                const thisUser = await getUser(userId)
                setUserData(thisUser)
                const clients = await getClientNames('?user_id=' + userId)
                cLog('ALL USERS CLIENTS', clients)
                dispatch(setClients(clients))
                const thisCreateClient = await getClient(clientId)
                setClient(thisCreateClient)
              }
            }
          } else {                                              ///// JOS VANHA
            if (!exp) {                                                       ////TAVALLINEN LASKU
              const invoiceById = await getInvoice(id)
              //cLog('GET INVOICE BY ID', invoiceById)
              invoiceById.client.email = invoiceById.email
              invoiceById.client.phone = invoiceById.phone
              invoiceById.client.isPrivate = Boolean(invoiceById.isPrivate)
              invoiceById.client.contactPerson = invoiceById.contactPerson
              invoiceById.client.businessId = invoiceById.businessId
              invoiceById.client.language = invoiceById.language
              invoiceById.client.deliveryMethod = invoiceById.deliveryMethod
              invoiceById.client.einvoiceOperator = invoiceById.einvoiceOperator
              invoiceById.client.einvoiceAddress = invoiceById.einvoiceAddress
              invoiceById.client.reminderHandling = invoiceById.reminderHandling
              invoiceById.client.hasCustomBusinessId = Boolean(invoiceById.hasCustomBusinessId)

              if (!isAdmin) {                             //// JOS LASKUTTAJA
                if (edit && invoiceById.status !== 'LUONNOS') {
                  history.push('/')
                }
                if (edit || recycle) {
                  const clients = await getClientNames()
                  cLog('LASKUTTAJAN OMAT CLIENTIT', clients)
                  dispatch(setClients(clients))
                }
                setOldInvoice(invoiceById)
                setInitValidate(true)
              } else {                                    ///// JOS TOIMISTO
                if (edit || recycle) {
                  const thisUser = await getUser(invoiceById.userId)
                  setUserData(thisUser)
                  cLog('THIS USER', thisUser)
                  const clients = await getClientNames('?user_id=' + invoiceById.userId)
                  cLog('ALL USERS CLIENTS', clients)
                  dispatch(setClients(clients))
                  setOldInvoice(invoiceById)
                  setInitValidate(true)
                } else {                        ///// JOS VIEW
                  setOldInvoice(invoiceById)
                  setInitValidate(true)
                }
              }
            } else {                                                           ///// KULULASKU
              if (!isAdmin && edit) {
                history.push('/')
              }
              const expenceById = await getExpence(id)
              cLog('GET EXPENCE BY ID', expenceById)
              setOldInvoice(expenceById)
              setInitValidate(true)
              if (isAdmin && edit) {
                const thisUser = await getUser(expenceById.userId)
                setUserData(thisUser)
              }
            }
          }
        }
        setInit(true)
      } catch (error) {
        cLog('INVOICE FETCH DATA ERROR', error)
        history.push('/')
      }
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    fetchData()
  }, [create, currentUser, dispatch, edit, exp, id, isAdmin, recycle, userId, worklist, worklistId, clientId, history])

  //cLog('GET INVOICES', invoices)
  //cLog('GET CLIENTS', clients)
  //cLog('MIKÄ TYYPPI: exp:', exp, 'edit:', edit, 'recycle:', recycle, 'view:', view, 'id:', id)

  const initialValues = () => {
    if (view) {
      return oldInvoice
    } else if (edit) {
      if (['LUONNOS', 'TARKASTETTAVANA'].includes(oldInvoice.status) && userData && userData.noInstantSalary) {
        return {
          ...oldInvoice,
          payDay: 'KUN_MAKSETTU'
        }
      } else {
        return oldInvoice
      }
    } else if (recycle) {
      cLog('OLD INVOICE', oldInvoice)
      let date = new Date(oldInvoice.date)
      let dateDue = new Date(oldInvoice.dateDue)
      let newDateDue = new Date()
      newDateDue.setDate(newDateDue.getDate() + (Math.floor((Math.abs(dateDue-date))/(1000*60*60*24))))
      let yel = undefined
      if (userData) {
        if (userData.yel === 'VELVOLLINEN') {
          yel = true
        } else if (userData.yel === 'EI_VELVOLLINEN') {
          yel = false
        }
      }

      let travellingExpenceRows = []
      if (oldInvoice.travellingExpenceRows && oldInvoice.travellingExpenceRows.length > 0) {
        if (oldInvoice.oldInvoiceGoogleMaps) {
          travellingExpenceRows = oldInvoice.travellingExpenceRows.map(row => {
            const { id, ...rest } = row
            rest.laskutaComId = undefined
            rest.consolidatedInvoiceRowId = undefined
            rest.travelRowDestinations = []
            rest.travelRowDestinations.push({
              destination: row.route,
              km: 0,
              km_google: 0,
              order: 0
            })
            rest.travelRowDestinations.push({
              destination: '',
              km: row.km,
              km_google: 0,
              order: 1
            })
            return rest
          })
        } else {
          travellingExpenceRows = oldInvoice.travellingExpenceRows.map(row => {
            const { id, ...rest } = row
            rest.laskutaComId = undefined
            rest.consolidatedInvoiceRowId = undefined
            rest.travelRowDestinations = rest.travelRowDestinations.map(destination => {
              const { id, ...rest2 } = destination
              return rest2
            })
            return rest
          })
        }
      }

      return {
        ...oldInvoice,
        date: getDateNow(),
        dateDue: !exp ? newDateDue.toISOString().split('T')[0] : undefined,
        dateStartWork: !exp ? '' : undefined,
        dateEndWork: !exp ? '' : undefined,
        invoiceNr: '',
        expenceNr: '',
        id: undefined,
        status: 'LUONNOS',
        attachments: [], ///oldInvoice.attachments || [],
        worklists: !exp ? [] : undefined,
        worklistId: undefined,
        additionalInformation: !exp ? '' : undefined,
        consolidatedInvoice: false,
        consolidatedInvoiceId: undefined,
        ciData: undefined,
        consolidatedInvoiceData: undefined,
        invoiceRows: (oldInvoice.invoiceRows && oldInvoice.invoiceRows.length > 0
          && oldInvoice.invoiceRows.map(row => {
            const { id, ...rest } = row
            return {
              ...rest,
              laskutaComId: undefined,
              consolidatedInvoiceRowId: undefined
            }
          })) || [],
        travellingExpenceRows: travellingExpenceRows,
        materialExpenceRows: (oldInvoice.materialExpenceRows && oldInvoice.materialExpenceRows.length > 0
          && oldInvoice.materialExpenceRows.map(row => {
            const { id, ...rest } = row
            return {
              ...rest,
              attachments: [],
              laskutaComId: undefined,
              consolidatedInvoiceRowId: undefined,
              originaRowId: row.id
            }
          })) || [],
        travelTotal: (!expense ? oldInvoice.travelTotal : 0),
        accounts: [],
        totalAccounting: 0,
        correctionRows: [],
        totalCorrections: 0,
        payments: [],
        totalPaymentsWithTax: 0,
        // TODO muut uudet kentät myös 0?
        exchangeRate: undefined,
        internalNotes: '',
        yel: yel,
        creditNote: false,
        originalInvoiceId: undefined,
        originalInvoiceData: undefined,
        creditNoteInvoicesData: undefined,
        originalExpenceId: ((exp && isAdmin) ? oldInvoice.id : undefined),
        originalExpenceData: ((exp && isAdmin) ? { id: oldInvoice.id, expenceNr: oldInvoice.expenceNr } : undefined),
        copyExpencesData: undefined,
        refCode: '',
        statusValmisDate: '',
        statusOsittainMaksettuDate: '',
        statusMaksettuDate: '',
        statusHylattyDate: '',
        statusPoistettuDate: '',
        statusTarkastettavanaDate: '',
        statusLahetettyDate: '',
        statusMaksettuLiikaaDate: '',
        statusPerinnassaDate: '',
        statusUlosotossaDate: '',
        statusLuottotappioDate: '',
        statusMuistutettuDate: '',
        statusHyvitettyDate: '',
        statusHyvityslaskuDate: '',
        sentDate: '',
        accountingStatusOsittainTilitettyDate: '',
        accountingStatusTilitettyDate: '',
        updateHistory: undefined,
        salaryData: undefined,
        expenceData: undefined,
        userAcceptedTravel: undefined,
        reminderDate: '',
        reminderNotes: '',
        oldInvoiceTravelType1: false,
        oldInvoiceTravelType2: false,
        oldInvoiceGoogleMaps: false,
        vat24: (new Date() < vat24ChangeDate())
      }
    } else {
      let yel = undefined
      if (!exp && userData) {
        if (userData.yel === 'VELVOLLINEN') {
          yel = true
        } else if (userData.yel === 'EI_VELVOLLINEN') {
          yel = false
        }
      }

      let dateDue = INITIAL_VALUES.dateDue
      if (create) {
        dateDue = new Date()
        dateDue.setDate(dateDue.getDate() + client.paymentTerms)
      }

      if (worklist) {
        const dateArray = thisWorklist.worklistRows.map(row => row.date)
        dateArray.sort((a, b) =>
          new Date(a) > new Date(b)
            ? 1
            : new Date(a) < new Date(b)
              ? -1
              : 0
        )
        //cLog('SORTED DATE ARRAY', dateArray)
        //const worklistsClient = clients.find(client => client.id == thisWorklist.clientId)
        //cLog('WORK LISTS CLIENT', worklistClient)
        let dateEndWork = dateArray[dateArray.length - 1]
        return {
          ...INITIAL_VALUES(exp),
          userId: !isAdmin ? currentUser.id : thisWorklist.userId,
          dateDue: dateDue,
          worklistId: thisWorklist.id,
          clientId: thisWorklist.clientId,
          client: client,
          dateStartWork: dateArray[0],
          dateEndWork: dateEndWork,
          industry: !isAdmin && currentUser.industry,
          customIndustry: !isAdmin && currentUser.customIndustry,
          additionalInformation: worklistToString(thisWorklist),
          marketingName: userData ? userData.marketingName : '',
          einvoiceOperator: client.einvoiceOperator || '',
          einvoiceAddress: client.einvoiceAddress || '',
          reminderHandling: client.reminderHandling || 'AUTOMAATTINEN',
          invoiceRows: [{
            ...INVOICE_ROW_TEMPLATE(dateEndWork),
            content: 'TYÖLISTA',
            quantity: thisWorklist.totalHours,
            unit: 'HOUR'
          }],
          yel: yel
        }
      } else {   ///// CREATE TAI UUSI LASKU
        return {
          ...INITIAL_VALUES(exp),
          userId: !isAdmin ? currentUser.id : (userData ? userData.id : undefined),
          dateDue: !exp ? dateDue : undefined,
          client: !exp && create ? client : undefined,
          industry: !exp ? (!isAdmin ? currentUser.industry : (userData ? userData.industry : undefined)) : undefined,
          customIndustry: !exp ? (!isAdmin ? currentUser.customIndustry : (userData ? userData.customIndustry : undefined)) : undefined,
          marketingName: !exp ? (!isAdmin ? currentUser.marketingName : (userData ? userData.marketingName : '')) : undefined,
          deliveryMethod: !exp ? (create ? ((client && client.deliveryMethod) || 'VERKKOLASKU') : 'VERKKOLASKU') : undefined,
          einvoiceOperator: !exp ? (create ? ((client && client.einvoiceOperator) || '') : '') : undefined,
          einvoiceAddress: !exp ? (create ? ((client && client.einvoiceAddress) || '') : '') : undefined,
          reminderHandling: !exp ? (create ? ((client && client.reminderHandling) || 'AUTOMAATTINEN') : 'AUTOMAATTINEN') : undefined,
          yel: yel
        }
      }
    }
  }

  return init ? <LayoutContainer>
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      //validationSchema={invoiceSchema}
      enableReinitialize={true}
      initialValues={initialValues()}
      component={props =>
        <InvoiceForm
          {...props}
          exp={exp}
          edit={edit}
          view={view}
          recycle={recycle}
          create={create}
          worklist={worklist}
          editClientForm={editClientForm}
          setEditClientForm={setEditClientForm}
          ref={invoiceRef}
          clientRef={clientRef}
          openClientModal={openClientModal}
          setOpenClientModal={setOpenClientModal}
          oldInvoice={oldInvoice}
          setOldInvoice={setOldInvoice}
          discountCodeOptions={discountCodeOptions}
          userData={userData}
          setUserData={setUserData}
          placesLibrary={placesLibrary}
          initValidate={initValidate}
          setInitValidate={setInitValidate}
        />}
    />
    {!exp && !view && <div style={{ display: !openClientModal && 'none' }}>
      <Formik
        initialValues={CLIENT_FORM_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
        component={props =>
          <ClientForm
            {...props}
            editClientForm={editClientForm}
            setEditClientForm={setEditClientForm}
            ref={clientRef}
            invoiceRef={invoiceRef}
            openClientModal={openClientModal}
            setOpenClientModal={setOpenClientModal}
            modalForm
            userData={userData}
            invoiceId={recycle ? undefined : id}
          />}
      />
    </div>}
  </LayoutContainer>
    : null
}

export default Invoice
