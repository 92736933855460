import { cLog } from '../utils'

cLog('MODE', process.env.REACT_APP_CUSTOM_NODE_ENV)
cLog('API_ENDPOINT', process.env.REACT_APP_API_ENDPOINT)
export const APIdomain = process.env.REACT_APP_API_ENDPOINT   
export const apiBaseUrlAPI = APIdomain + '/api/v1'
export const apiBaseUrlFront = 'http://89.250.56.72:3001/'  //// ONKO SAMA KUIN frontUrl? 
export const frontUrl = process.env.REACT_APP_CUSTOM_NODE_ENV !== 'PRODUCTION'
    ? 'https://dev-laskuta.odeal.fi'
    : 'https://laskuta.odeal.fi' // tarpeeton?
export const frontVersion = '2024-08-21' // TODO muista muuttaa päivityksen yhteydessä
