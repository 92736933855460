import axios from 'axios'
import { apiBaseUrlAPI, frontVersion } from './apiBaseUrl'
import { token } from './authService'
import { cLog } from '../utils'

export const updateFrequencyMs = 600 * 1000
 
const request = async (method, path, data) => {
  const url = encodeURI(apiBaseUrlAPI + path)
  cLog('REQUEST', method, url)

  const response = await axios({
    method,
    url,
    data,
    timeout: 0,
    headers: {
      'Authorization': token || undefined,
      "Content-Type": "application/json",
      'Client-Version': frontVersion
    }
  })
  //cLog('REQUEST RESPONSE', response)
  return response
}
 
export default request
