import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { cLog } from '../../utils'
import { Field } from "formik"
import { withSnackbar } from 'notistack'
import { savePromoCode, updatePromoCode, deletePromoCode } from '../../service'
import Dropzone from '../../components/Dropzone'
import { saveAttachment } from '../../service'
import DPicker from '../../components/DPicker'
import styled from 'styled-components'
import { FONTS } from '../../styles'
import { formatDate, formatDatetime, formatTimeFI } from '../../utils'
import { validateTextArea, validateUrl, validateDate } from '../Invoice/validationSchemas'
import TextArea from '../../components/TextArea'
import Alert from '../../components/Alert'

const ResponsiveDiv = styled.div`
  width: 100%;
  margin-top: 7px;
  @media only screen and (max-width: 720px) {
    width: 100%;
}`

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
}))

const PromoDialogOffice = ({
  resetForm,
  handleChange,
  values,
  setFieldValue,
  errors,
  validateForm,
  enqueueSnackbar,
  open,
  setOpen,
  edit,
  setValues,
  setEdit,
  fetchData
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [openConfirmDeletePromoCodeDialog, setOpenConfirmDeletePromoCodeDialog] = useState(false)
  const [attachmentFile, setAttachmentFile] = useState(null)
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    attachmentFile && setFieldValue('attachments', attachmentFile)
    attachmentFile && setFileName(attachmentFile.filename)
  }, [attachmentFile, setFieldValue])

  useEffect(() => {
    if (edit) {
      setValues(edit)
    }
  }, [edit, setValues])

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    //cLog('ERRORI TYYPIT', errorTypes)
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        //cLog('INVOICE FORM ERROR!!!', type)
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    /*if (!errorTypes[0]) {
      enqueueSnackbar(
        t('invoice.deficientRows'),
        { variant: 'error' }
      )
    }*/
  }

  var validate = async () => {
    const erroris = await validateForm()
    cLog('ERRORIS', erroris)
    if (Object.keys(erroris).length === 0) {
      return true
    } else {
      //cLog('ERROREITA PIISAA', erroris)
      notifyErrors(erroris)
      return false
    }
  }

  const handleSubmitForm = async () => {
    let val = validate()
    val.then(result => {
      if (result) {
        saveForm()
      }
    }, error => {
      cLog('SAVE FORM ERROR', error)
    })
  }

  const saveForm = async () => {
    try {
      setLoading(true)
      let sendResult
      if (values && values.id) {
        sendResult = await updatePromoCode(values)
      } else {
        sendResult = await savePromoCode(values)
      }
      cLog('PROMOKOODI VASTAUS', sendResult)

      if (attachmentFile) {
        const fileRequest = await saveAttachment({
          recordType: 'PromoCode',
          recordId: sendResult.id,
          file: values.attachments.file
        })
        cLog('PROMOKOODI LIITE', fileRequest)
      }

      resetForm(sendResult)
      handleClose()
      enqueueSnackbar(
        t('promocodes.codeSent'),
        { variant: 'success' }
      )
      fetchData()
    } catch (error) {
      setLoading(false)
      cLog('PROMOKOODI ERROR', error)
      enqueueSnackbar(
        t('promocodes.codeSendError'),
        { variant: 'error' }
      )
    }
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setLoading(false)
      setOpenConfirmDeletePromoCodeDialog(false)
      setOpen(false)
      setFileName("")
      setEdit(null)
      resetForm()
    }
  }

  const handleAgreeDeletePromoCodeDialog = async () => {
    setLoading(true)
    const request = deletePromoCode(values)
    request.then(response => {
      cLog('PROMO CODE DELETED', response)
      enqueueSnackbar(
        t('promocodes.promoCodeDeleted'),
        { variant: 'success' }
      )
      handleClose()
      fetchData()
    }, error => {
      setLoading(false)
      setOpenConfirmDeletePromoCodeDialog(false)
      cLog('PROMO CODE DELETE ERROR', error)
      enqueueSnackbar(
        t('promocodes.problemsDeletingPromoCode'),
        { variant: 'error' }
      )
    })
  }

  const History = () => {
    let collection = [];
    values.updateHistory.map((row, i) => {
      let name, filtered, joined;
      let changes = [];

      for (const [key, value] of Object.entries(row.changes)) {
        if (key !== 'id') {
          let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
          let value0 = value[0]
          let value1 = value[1]

          if (['expires'].includes(k)) {
            if (value0) {
              value0 = formatTimeFI(value0)
            }
            if (value1) {
              value1 = formatTimeFI(value1)
            }
          }

          if (value[0] || value[1]) {
            if (value0 === 'true' || value0 === true) {
              value0 = t('taxcard.true')
            } else if (value0 === 'false'|| value0 === false) {
              value0 = t('taxcard.false')
            } else if (!value0) {
              value0 = t('user.noValue')
            }

            if (value1 === 'true' || value1 === true) {
              value1 = t('taxcard.true')
            } else if (value1 === 'false' || value1 === false) {
              value1 = t('taxcard.false')
            } else if (!value1) {
              value1 = t('user.noValue')
            }

            if (row.event === 'create') {
              changes.push(t('promocodes.'+k) + ': ' + value1)
            } else if (row.event === 'destroy') {
              changes.push(t('promocodes.'+k) + ': ' + value0)
            } else {
              changes.push(t('promocodes.'+k) + ': ' + value0 + ' -> ' + value1)
            }
          }
        }
      }

      if (changes.length) {
        if (row.firstName && row.lastName) {
          name = row.firstName + ' ' + row.lastName;
        } else if (row.firstName && !row.lastName) {
          name = row.firstName;
        } else if (!row.firstName && row.lastName) {
          name = row.lastName
        } else {
          name = 'Odealin järjestelmä'
        }

        filtered = changes.filter(function (f) {
          return f != null;
        });
        joined = filtered.join(', ');

        let rowText = ''
        if (row.event === 'create') {
          rowText = t('promocodes.promoCode') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
        }

        collection.push(<TableRow key={i}>
          <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{rowText + joined}</TableCell>
        </TableRow>)
      }

      return null
    })

    return (<div style={{ paddingBottom: '20px' }}>
      <br /><br />
      {t('promocodes.historyTitle')}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('customers.date')}</TableCell>
            <TableCell>{t('customers.user')}</TableCell>
            <TableCell>{t('customers.events')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection}
        </TableBody>
      </Table>
    </div>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby="promo-dialog-title"
      aria-describedby="promo-dialog-description"
    >
      <DialogTitle id="promo-dialog-title">{edit ? t('promocodes.editTitle') : t('promocodes.addNewPromoCode')}</DialogTitle>
      <DialogContent dividers={true} id="promo-dialog-description">
        <form>
          <Grid container spacing={4} className={classes.gridContainer}>
            <Grid item xs={12} sm={6}>
              <Field
                label={t('promocodes.companyName')+' *'}
                id="companyName"
                name="companyName"
                placeholder={t('promocodes.companyName')}
                component={Input}
                onChange={handleChange}
                value={values.companyName}
                validate={() => validateTextArea(values.companyName, t('promocodes.companyName'), 80, true)}
                error={errors && errors.companyName && validateTextArea(values.companyName, t('promocodes.companyName'), 80, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                label={t('promocodes.code')}
                id="code"
                name="code"
                placeholder={t('promocodes.code')}
                component={Input}
                onChange={handleChange}
                value={values.code}
                validate={() => validateTextArea(values.code, t('promocodes.code'), 20, false)}
                error={errors && errors.code && validateTextArea(values.code, t('promocodes.code'), 20, false)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                id="discount"
                name="discount"
                label={t('promocodes.discount')+' *'}
                placeholder={t('promocodes.discount')}
                component={Input}
                onChange={handleChange}
                value={values.discount}
                validate={() => validateTextArea(values.discount, t('promocodes.discount'), 25, true)}
                error={errors && errors.discount && validateTextArea(values.discount, t('promocodes.discount'), 25, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                id="url"
                name="url"
                label={t('promocodes.url')+' *'}
                placeholder={t('promocodes.url')}
                component={Input}
                onChange={handleChange}
                value={values.url}
                validate={() => validateUrl(values.url, t('promocodes.url'), 10, 60, true)}
                error={errors && errors.url && validateUrl(values.url, t('promocodes.url'), 10, 60, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                id="finDescription"
                name="finDescription"
                type="text"
                component={TextArea}
                label={t('promocodes.finDescription')+' *'}
                placeholder={t('promocodes.finDescription')}
                onChange={handleChange}
                value={values.finDescription}
                validate={() => validateTextArea(values.finDescription, t('promocodes.finDescription'), 5000, true)}
                error={errors && errors.finDescription && validateTextArea(values.finDescription, t('promocodes.finDescription'), 5000, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                id="enDescription"
                name="enDescription"
                type="text"
                component={TextArea}
                label={t('promocodes.enDescription')+' *'}
                placeholder={t('promocodes.enDescription')}
                onChange={handleChange}
                value={values.enDescription}
                validate={() => validateTextArea(values.enDescription, t('promocodes.enDescription'), 5000, true)}
                error={errors && errors.enDescription && validateTextArea(values.enDescription, t('promocodes.enDescription'), 5000, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ResponsiveDiv>
                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: FONTS.FONT_SIZE1, paddingBottom: '0.25rem' }}>
                  {fileName ? t('promocodes.attachments')+': ' + fileName : t('promocodes.attachments')}
                </div>
                <Field
                  id="attachments"
                  name="attachments"
                  placeholder={t('promocodes.attachments')}
                  component={Dropzone}
                  tip={"promo"}
                  onChange={handleChange}
                  value={values.attachments}
                  setFile={setAttachmentFile}
                  style={{height: '45px'}}
                />
              </ResponsiveDiv>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="expires"
                id="expires"
                label={t('promocodes.expires')}
                component={DPicker}
                selected={values.expires ? new Date(values.expires) : ''}
                onChange={(date) => setFieldValue('expires', date ? formatDate(date) : '')}
                validate={() => validateDate(values.expires, t('promocodes.expires'), false, new Date(), true)}
                error={errors && errors.expires && validateDate(values.expires, t('promocodes.expires'), false, new Date(), true)}
              />
            </Grid>
          </Grid>
        </form>
        {showHistory && edit && <Grid container spacing={0}>
          <Grid item xs={12}>
            <History />
          </Grid>
        </Grid>}
      </DialogContent>
      <DialogActions>
        {edit && <Button
          variant="text"
          onClick={() => setShowHistory(!showHistory)}
          color="secondary"
          disabled={isLoading}
        >
          {t('discountCode.history')}
        </Button>}
        {edit && <Button
          variant="text"
          onClick={() => { setOpenConfirmDeletePromoCodeDialog(true) }}
          color="error"
          disabled={isLoading}
        >
          {t('customers.remove')}
        </Button>}
        <Button
          variant="text"
          onClick={handleClose}
          color="secondary"
          disabled={isLoading}
        >
          {t('promocodes.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleSubmitForm}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('promocodes.send')}
        </Button>
      </DialogActions>
      <Alert
        open={openConfirmDeletePromoCodeDialog}
        setOpen={setOpenConfirmDeletePromoCodeDialog}
        handleAgree={handleAgreeDeletePromoCodeDialog}
        handleClose={() => { setOpenConfirmDeletePromoCodeDialog(false) }}
        alertMessage={{ title: t('promocodes.promoCodeDeleteWarningTitle'), body: t('user.userNoteDeleteWarningBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
        isLoading={isLoading}
      />
    </Dialog>
  )
}

export default withSnackbar(PromoDialogOffice)
