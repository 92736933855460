
export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CLIENTS":
      return {
        ...state,
        clients: action.payload
      };
    case "ADD_CLIENT":
      return {
        ...state,
        clients: state.clients.concat(action.payload)
      };
    case "REVISE_CLIENT":
      return {
        ...state,
        clients: state.clients.map(client => client.id === action.payload.id ? action.payload : client)
      }
    case "REMOVE_CLIENT":
      const remainingClients = state.clients.filter(client => client.id !== action.payload.id) 
      return {
        ...state,
        clients: remainingClients
      };
    case "SET_INVOICES":
      return {
        ...state,
        invoices: action.payload
      };
    case "ADD_INVOICE":
      return {
        ...state,
        invoices: state.invoices.concat(action.payload)
      };
    case "REVISE_INVOICE":
      return {
        ...state,
        invoices: state.invoices.map(invoice => invoice.id === action.payload.id ? action.payload : invoice)
      }
    case "REMOVE_INVOICE":
      const remainingInvoices = state.invoices.filter(invoice => invoice.id !== action.payload.id)
      return {
        ...state,
        invoices: remainingInvoices
      };
    case "SET_SELECTED1":
      return {
        ...state,
        selected1: action.payload
      };
    case "SET_SELECTED2":
      return {
        ...state,
        selected2: action.payload
      };
    case "SET_SELECTED3":
      return {
        ...state,
        selected3: action.payload
      };
    case "SET_SELECTED4":
      return {
        ...state,
        selected4: action.payload
      };
    case "SET_SELECTED5":
      return {
        ...state,
        selected5: action.payload
      };
    case "SET_SORT_KEY_ANNOUNCEMENTS":
      return {
        ...state,
        sortKeyAnnouncements: action.payload
      };
    case "SET_SORT_KEY_CLIENTS":
      return {
        ...state,
        sortKeyClients: action.payload
      };
    case "SET_SORT_KEY_INVOICES":
      return {
        ...state,
        sortKeyInvoices: action.payload
      };
    case "SET_SORT_KEY_SALARIES":
      return {
        ...state,
        sortKeySalaries: action.payload
      };
    case "SET_SORT_KEY_TAXCARDS":
      return {
        ...state,
        sortKeyTaxcards: action.payload
      };
    case "SET_SORT_KEY_USER_NOTES":
      return {
        ...state,
        sortKeyUserNotes: action.payload
      };
    case "SET_SORT_KEY_USERS":
      return {
        ...state,
        sortKeyUsers: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW1":
      return {
        ...state,
        sortKeyUserView1: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW2":
      return {
        ...state,
        sortKeyUserView2: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW3":
      return {
        ...state,
        sortKeyUserView3: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW4":
      return {
        ...state,
        sortKeyUserView4: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW5":
      return {
        ...state,
        sortKeyUserView5: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW6":
      return {
        ...state,
        sortKeyUserView6: action.payload
      };
    case "SET_SORT_KEY_USER_VIEW7":
      return {
        ...state,
        sortKeyUserView7: action.payload
      };
    case "SET_SORT_KEY_WORKLISTS":
      return {
        ...state,
        sortKeyWorklists: action.payload
      };
    case "SET_SORT_ORDER_CLIENTS":
      return {
        ...state,
        sortOrderClients: action.payload
      };
    case "SET_SORT_ORDER_INVOICES":
      return {
        ...state,
        sortOrderInvoices: action.payload
      };
    case "SET_SORT_ORDER_SALARIES":
      return {
        ...state,
        sortOrderSalaries: action.payload
      };
      case "SET_SORT_ORDER_TAXCARDS":
      return {
        ...state,
        sortOrderTaxcards: action.payload
      };
    case "SET_SORT_ORDER_USER_NOTES":
      return {
        ...state,
        sortOrderUserNotes: action.payload
      };
    case "SET_SORT_ORDER_USERS":
      return {
        ...state,
        sortOrderUsers: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW1":
      return {
        ...state,
        sortOrderUserView1: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW2":
      return {
        ...state,
        sortOrderUserView2: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW3":
      return {
        ...state,
        sortOrderUserView3: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW4":
      return {
        ...state,
        sortOrderUserView4: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW5":
      return {
        ...state,
        sortOrderUserView5: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW6":
      return {
        ...state,
        sortOrderUserView6: action.payload
      };
    case "SET_SORT_ORDER_USER_VIEW7":
      return {
        ...state,
        sortOrderUserView7: action.payload
      };
    case "SET_SORT_ORDER_USER_WORKLISTS":
      return {
        ...state,
        sortOrderWorklists: action.payload
      };
    case "SET_EXPENCES":
      return {
        ...state,
        expences: action.payload
      };
    case "ADD_EXPENCE":
      return {
        ...state,
        expences: state.expences.concat(action.payload)
      };
    case "REVISE_EXPENCE":
      return {
        ...state,
        expences: state.expences.map(expence => expence.id === action.payload.id ? action.payload : expence)
      }
    case "REMOVE_EXPENCE":
      const remainingExpences = state.expences.filter(expence => expence.id !== action.payload.id) 
      return {
        ...state,
        expences: remainingExpences
      };

    case "SET_WORKLISTS":
      return {
        ...state,
        worklists: action.payload
      };
    case "ADD_WORKLIST":
      return {
        ...state,
        worklists: state.worklists.concat(action.payload)
      };
    case "REVISE_WORKLIST":
      return {
        ...state,
        worklists: state.worklists.map(worklist => worklist.id === action.payload.id ? action.payload : worklist)
      }
    case "REMOVE_WORKLIST":
      const remainingWorklists = state.worklists.filter(list => list.id !== action.payload.id)
      return {
        ...state,
        worklists: remainingWorklists
      };

    case "SET_SALARIES":
      return {
        ...state,
        salaries: action.payload
      };
    case "ADD_SALARY":
      return {
        ...state,
        salaries: state.salaries.concat(action.payload)
      };
    case "REVISE_SALARY":
      return {
        ...state,
        salaries: state.salaries.map(salary => salary.id === action.payload.id ? action.payload : salary)
      }
    case "REMOVE_SALARY":
      const remainingSalaries = state.salaries.filter(salary => salary.id !== action.payload.id)
      return {
        ...state,
        salaries: remainingSalaries
      };
    case "SET_TAXCARDS":
      return {
        ...state,
        taxcards: action.payload
      };
    case "SET_USER_NOTES":
      return {
        ...state,
        userNotes: action.payload
      };
    case "SET_USERS_INDEX":
      return {
        ...state,
        usersIndex: action.payload
      };
    case "ADD_USER":
      return {
        ...state,
        users: state.users.concat(action.payload)
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload
      };
    case "REVISE_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload
      }
    case "REMOVE_USER":
      const remainingUsers = state.users.filter(user => user.id !== action.payload)
      return {
        ...state,
        users: remainingUsers
      };
    case "SET_ANNOUNCEMENTS":
      return {
        ...state,
        announcements: action.payload
      };
    case "ADD_ANNOUNCEMENT":
      return {
        ...state,
        announcements: state.announcements.concat(action.payload)
      };
    case "REVISE_ANNOUNCEMENT":
      return {
        ...state,
        announcements: state.announcements.map(announcement => announcement.id === action.payload.id ? action.payload : announcement)
      }
    case "REMOVE_ANNOUNCEMENT":
      const remainingAnnouncements = state.announcements.filter(announcement => announcement.id !== action.payload.id)
      return {
        ...state,
        announcements: remainingAnnouncements
      };

    case "SET_MESSAGES":
      return {
        ...state,
        messages: action.payload
      };
    case "ADD_MESSAGE":
      return {
        ...state,
        messages: state.messages.concat(action.payload)
      };
    case "REVISE_MESSAGE":
      return {
        ...state,
        messages: state.messages.map(message => message.id === action.payload.id ? action.payload : message)
      }
    case "REMOVE_MESSAGE":
      const remainingMessages = state.messages.filter(message => message.id !== action.payload.id)
      return {
        ...state,
        messages: remainingMessages
      };

    case "SET_REFERENCES":
      return {
        ...state,
        references: action.payload
      };
    case "ADD_REFERENCE":
      return {
        ...state,
        references: state.references.concat(action.payload)
      };
    case "REVISE_REFERENCE":
      return {
        ...state,
        references: state.references.map(reference => reference.id === action.payload.id ? action.payload : reference)
      }
    case "REMOVE_REFERENCE":
      const remainingReferences = state.references.filter(reference => reference.id !== action.payload.id)
      return {
        ...state,
        references: remainingReferences
      };
    case "SET_SORT_KEY_REFERENCES":
      return {
        ...state,
        sortKeyReferences: action.payload
      };
    case "SET_SORT_ORDER_REFERENCES":
      return {
        ...state,
        sortOrderReferences: action.payload
      };

    case "SET_INVOICE_FILTER":
      return {
        ...state,
        invoiceFilter: action.payload
      }
      
    case "SET_DISCOUNT_CODES":
      return {
        ...state,
        discountCodes: action.payload
      };
    case "SET_SORT_KEY_DISCOUNT_CODES":
      return {
        ...state,
        sortKeyDiscountCodes: action.payload
      };
    case "SET_SORT_ORDER_DISCOUNT_CODES":
      return {
        ...state,
        sortOrderDiscountCodes: action.payload
      };
    case "SET_PROMO_CODES":
      return {
        ...state,
        promoCodes: action.payload
      };
    case "SET_SORT_KEY_PROMO_CODES":
      return {
        ...state,
        sortKeyPromoCodes: action.payload
      };
    case "SET_SORT_ORDER_PROMO_CODES":
      return {
        ...state,
        sortOrderPromoCodes: action.payload
      };

    case "SET_VALTTICARDS":
      return {
        ...state,
        valtticards: action.payload
      };
    case "ADD_VALTTICARD":
      return {
        ...state,
        valtticards: state.valtticards.concat(action.payload)
      };
    case "REVISE_VALTTICARD":
      return {
        ...state,
        valtticards: state.valtticards.map(valtticard => valtticard.id === action.payload.id ? action.payload : valtticard)
      }
    case "REMOVE_VALTTICARD":
      const remainingValtticards = state.valtticards.filter(valtticard => valtticard.id !== action.payload.id)
      return {
        ...state,
        valtticards: remainingValtticards
      };
    case "SET_SORT_KEY_VALTTICARDS":
      return {
        ...state,
        sortKeyValtticards: action.payload
      };
    case "SET_SORT_ORDER_VALTTICARDS":
      return {
        ...state,
        sortOrderValtticards: action.payload
      };

    default:
      return state;
  }
};


