import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useStateValue, setInvoices, setExpences, setReferences, setSalaries, setSelected1, setSelected2, setSelected3, setSelected4, setSelected5, setSortKeyUserView1, setSortOrderUserView1, setSortKeyUserView2, setSortOrderUserView2, setSortKeyUserView3, setSortOrderUserView3, setSortKeyUserView4, setSortOrderUserView4, setSortKeyUserView5, setSortOrderUserView5, setSortKeyUserView6, setSortOrderUserView6 } from "../../state"
import Button from '../../components/Button'
import { useHistory } from 'react-router-dom'
import InvoiceTable from '../Invoice/InvoiceTable'
import SalaryTable from '../../screens/Salary/SalaryTable'
import SummaryBox from './SummaryBox'
import UserBox1 from './UserBox1'
import UserBox2 from './UserBox2'
import { withSnackbar } from 'notistack'
import { cLog, formatTimeFI } from '../../utils'
import { cleanFloat } from '../../utils/invoiceUtils'
import { Grid, FormControlLabel, Hidden, useMediaQuery, CircularProgress, Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import Pagination from '../../components/Pagination'
import { getInvoices, getExpences, getReferences, getSalaries, updateUserNote, deleteUser } from '../../service'
import OdealCheckbox from '../../components/OdealCheckbox'
import { MaterialUiCheckBox } from '../../components/MaterialUiCheckBox'
import EditIcon from '@material-ui/icons/Edit'
import { USER_NOTE_TEMPLATE } from './User'
import UserNoteDialog from '../../components/UserNoteDialog'
import Alert from '../../components/Alert'
import MoveUserDataDialog from '../../components/MoveUserDataDialog'
import PasswordDialog from '../../components/PasswordDialog'
import PasswordDialogOffice from '../../components/PasswordDialogOffice'
import InviteUserDialog from '../../components/InviteUserDialog'
import CsvDialog from '../../components/CsvDialog'
import DoneIcon from '@material-ui/icons/Done'
import Header from "../../components/Header"
import { newPaginationStyles } from '../../styles'
import Valtticards from "../Valtticards/Valtticards"
import References from "../References/References"
import moment from 'moment'
import InfoBar from '../../components/InfoBar'

const useStyles = makeStyles((theme) => ({
  body: {
  },
  invoiceTableContainer: {
    padding: theme.spacing(4, 0),
  },
  buttons: {
    padding: theme.spacing(2, 0),
    position: 'relative',
    '& > *': {
      margin: theme.spacing(2, 4, 2, 0),
    },
  },
  newPaginationRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(2, 1),
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    fontSize: '16px',
    ...newPaginationStyles,
  },
  newPaginationInfo: {
  },
  newPaginationDivider: {
    flexGrow: 1,
  },
  editNote: {
    color: 'blue',
    cursor: 'pointer',
    verticalAlign: 'middle',
    '&:hover': {
      opacity: '0.5'
    }
  },
  notesContainer: {
    padding: theme.spacing(8, 0),

    '& > ul': {
      margin: theme.spacing(4, 0),
      padding: 0,
      listStyle: 'none',

      '& > li': {
        padding: theme.spacing(2, 2),
        borderTop: theme.borders.thin('rgba(224, 224, 224, 1)'),

        '& > span': {
          display: 'block',
        },
        '& > span:first-child > *': {
          marginRight: theme.spacing(2),
        },
        '& > span:last-child': {
          whiteSpace: 'pre-line',
        },
      },
      '& > li:last-child': {
        borderBottom: theme.borders.thin('rgba(224, 224, 224, 1)'),
      },
    },
  },
}))

const INIT_FILTER1 = {
  showUnaccounted: false,
  hideDestroyed: false
}

const UserView = ({
  user,
  setUser,
  payList,
  enqueueSnackbar
}) => {
  const { t } = useTranslation()
  const [{ currentUser, invoices, expences, salaries, selected1, selected2, selected3, selected4, selected5, sortKeyUserView1, sortOrderUserView1, sortKeyUserView2, sortOrderUserView2, sortKeyUserView3, sortOrderUserView3, sortKeyUserView4, sortOrderUserView4, sortKeyUserView5, sortOrderUserView5, sortKeyUserView6, sortOrderUserView6, sortKeyUserView7, sortOrderUserView7 }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [isLoading, setLoading] = useState(false)
  const [payListInvoices, setPayListInvoices] = useState([])
  const [straigthSalaries, setStraigthSalaries] = useState([])
  const [unpaidExpences, setUnpaidExpences] = useState([])
  const classes = useStyles()
  let history = useHistory()
  const [showClosedNotes, setShowClosedNotes] = useState(false)
  const [userNotes, setUserNotes] = useState(user.userNotes ? user.userNotes.filter(note => note.status !== 'HOIDETTU') : [])
  const [forceFetchData6, setForceFetchData6] = useState(true)
  const [forceFetchData7, setForceFetchData7] = useState(true)
  const [forceFetchData8, setForceFetchData8] = useState(false)
  const [openUserNoteDialog, setOpenUserNoteDialog] = useState(false)
  const [editUserNote, setEditUserNote] = useState(null)
  const [openConfirmUpdateUserNoteModal, setOpenConfirmUpdateUserNoteModal] = useState(false)
  const [userNoteToUpdate, setUserNoteToUpdate] = useState(null)
  const isAdminCurrentUser = isAdmin && currentUser.id === user.id
  const [invoiceFilter1, setInvoiceFilter1] = useState(INIT_FILTER1)
  const [invoicePagination1, setInvoicePagination1] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 10,
    totalPages: 0,
    totalRows: 0
  })
  const [invoicePagination2, setInvoicePagination2] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 10,
    totalPages: 0,
    totalRows: 0
  })
  const [salaryPagination, setSalaryPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 10,
    totalPages: 0,
    totalRows: 0
  })
  const [referencePagination, setReferencePagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 10,
    totalPages: 0,
    totalRows: 0
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [openPasswordDialogOffice, setOpenPasswordDialogOffice] = useState(false)
  const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false)
  const [openCsvDialog, setOpenCsvDialog] = useState(false)
  const [openConfirmDeleteUserModal, setOpenConfirmDeleteUserModal] = useState(false)
  const [openMoveUserDataDialog, setOpenMoveUserDataDialog] = useState(false)

  const handleAgreeDeleteUserModal = async () => {
    try {
      setOpenConfirmDeleteUserModal(false)
      await deleteUser(user.id)
      cLog(`USER ${user.id} DELETED`)
      enqueueSnackbar(
        t('users.user')+`${user.firstName} ${user.lastName}`+t('users.deleted'),
        { variant: 'success' }
      )
      history.push('/')
    } catch (error) {
      cLog('DELETE CLIENT ERROR', error.response.data.errors)
      enqueueSnackbar(
        t('users.problemsDeletingUser'),
        { variant: 'error' }
      )
    }
  }

  let boksi = false
  if (user.companyNames) {
    for (var i = 0; i < user.companyNames.length; i++) {
      if (user.companyNames[i].toLowerCase().includes('boksi')) {
        boksi = true
        break
      }
    }
  }

  useEffect(() => {
    if (isAdmin && !isAdminCurrentUser) {
      if (!showClosedNotes) {
        setUserNotes(user.userNotes.filter(note => note.status !== 'HOIDETTU'))
      } else {
        setUserNotes(user.userNotes)
      }
    }
  }, [user, isAdmin, isAdminCurrentUser, dispatch, showClosedNotes])

  const fetchData1 = React.useCallback(async () => {
    //cLog('HAETAAN USERILLE DATAA-----------------------')
    try {
      setLoading(true)
      dispatch(setSelected1([]))
      let sKey = sortKeyUserView1
      if (sortKeyUserView1 === 'customer') {
        sKey = 'name'
      } else if (sortKeyUserView1 === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyUserView1 === 'belongs_to_consolidated_invoice') {
        sKey = 'consolidated_invoice_id'
      } else if (sortKeyUserView1 === 'belongs_to_credit_note') {
        sKey = 'credit_note_id'
      }
      //cLog('NYT EDITOIDAAN**********************************************', edit ? 'TRUE' : 'FALSE' )
      //cLog('NYT TÄYDENNETÄÄN*********************************************', taxcard ? 'TRUE' : 'FALSE')
      //cLog('NYT KATSOTAAN KÄYTTÄJÄTIETOJA****************************************************')
      let query = '?page=' + (invoicePagination1.page - 1)
      query += '&rows=' + invoicePagination1.rowsPerPage
      if (!['updater', 'work_period', 'time_frame', 'expence_type', 'commission'].includes(sKey)) {
        query += '&sort_key=' + sKey
      }
      query += '&sort_order=' + sortOrderUserView1
      query += '&user_id=' + user.id
      if (invoiceFilter1.showUnaccounted) {
        query += '&status=MAKSETTU&accounting_status=TILITTÄMÄTÖN,OSITTAIN_TILITETTY'
      } else if (!invoiceFilter1.hideDestroyed) {
        query += '&include_destroyed=true'
      }
      if (boksi) {
        query += '&boksi=true'
      }
      const allInvoices = await getInvoices(query)

      if (invoicePagination1.totalRows !== allInvoices.totalRows || invoicePagination1.totalPages !== allInvoices.totalPages || invoicePagination1.currentPage !== allInvoices.currentPage) {
        setInvoicePagination1({ ...invoicePagination1, totalRows: allInvoices.totalRows, totalPages: allInvoices.totalPages, currentPage: allInvoices.currentPage })
      }
      dispatch(setInvoices(allInvoices.invoices))
      setLoading(false)
    } catch (error) {
      cLog('ONGELMIA USERIN KÄYTTÄJÄSIVUN LATAAMISESSA1', error)
      setLoading(false)
    }
  }, [boksi, dispatch, invoicePagination1, sortKeyUserView1, sortOrderUserView1, user.id, invoiceFilter1])

  const fetchData2 = React.useCallback(async () => {
    //cLog('HAETAAN USERILLE DATAA-----------------------')
    try {
      setLoading(true)
      dispatch(setSelected2([]))
      let sKey = sortKeyUserView2
      if (sortKeyUserView2 === 'customer') {
        sKey = 'name'
      } else if (sortKeyUserView2 === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyUserView2 === 'belongs_to_consolidated_invoice') {
        sKey = 'consolidated_invoice_id'
      } else if (sortKeyUserView2 === 'belongs_to_credit_note') {
        sKey = 'credit_note_id'
      }
      let query = ''
      if (!['updater', 'work_period', 'time_frame', 'expence_type', 'commission'].includes(sKey)) {
        query += '&sort_key=' + sKey
      }
      if (boksi) {
        query += '&boksi=true'
      }
      //cLog('NYT EDITOIDAAN**********************************************', edit ? 'TRUE' : 'FALSE' )
      //cLog('NYT TÄYDENNETÄÄN*********************************************', taxcard ? 'TRUE' : 'FALSE')
      //cLog('NYT OLLAAN MAKSULISTALLA*******************************************************')
      let allPayListInvoices
      if (boksi) {
        allPayListInvoices = await getInvoices('?user_id=' + user.id + '&rows=100&pay_day=PIKAMAKSU,KUN_MAKSETTU&accounting_status=TILITTÄMÄTÖN,OSITTAIN_TILITETTY' + query + '&sort_order=' + sortOrderUserView2)
      } else {
        allPayListInvoices = await getInvoices('?user_id=' + user.id + '&rows=100&status=MAKSETTU&pay_day=PIKAMAKSU,KUN_MAKSETTU&accounting_status=TILITTÄMÄTÖN,OSITTAIN_TILITETTY' + query + '&sort_order=' + sortOrderUserView2)
      }
      setPayListInvoices(allPayListInvoices.invoices)
      setLoading(false)
    } catch (error) {
      cLog('ONGELMIA USERIN KÄYTTÄJÄSIVUN LATAAMISESSA2', error)
      setLoading(false)
    }
  }, [boksi, dispatch, sortKeyUserView2, sortOrderUserView2, user.id])

  const fetchData3 = React.useCallback(async () => {
    //cLog('HAETAAN USERILLE DATAA-----------------------')
    try {
      setLoading(true)
      dispatch(setSelected3([]))
      let sKey = sortKeyUserView3
      if (sortKeyUserView3 === 'customer') {
        sKey = 'name'
      } else if (sortKeyUserView3 === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyUserView3 === 'belongs_to_consolidated_invoice') {
        sKey = 'consolidated_invoice_id'
      } else if (sortKeyUserView3 === 'belongs_to_credit_note') {
        sKey = 'credit_note_id'
      }
      let query = ''
      if (!['updater', 'work_period', 'time_frame', 'expence_type', 'commission'].includes(sKey)) {
        query += '&sort_key=' + sKey
      }
      if (boksi) {
        query += '&boksi=true'
      }
      //cLog('NYT EDITOIDAAN**********************************************', edit ? 'TRUE' : 'FALSE' )
      //cLog('NYT TÄYDENNETÄÄN*********************************************', taxcard ? 'TRUE' : 'FALSE')
      //cLog('NYT OLLAAN MAKSULISTALLA*******************************************************')
      const allStraigthSalaries = await getInvoices('?user_id=' + user.id + '&rows=100&status=LÄHETETTY,OSITTAIN_MAKSETTU&pay_day=HETI&accounting_status=TILITTÄMÄTÖN' + query + '&sort_order=' + sortOrderUserView3)
      setStraigthSalaries(allStraigthSalaries.invoices)
      setLoading(false)
    } catch (error) {
      cLog('ONGELMIA USERIN KÄYTTÄJÄSIVUN LATAAMISESSA3', error)
      setLoading(false)
    }
  }, [boksi, dispatch, sortKeyUserView3, sortOrderUserView3, user.id])

  const fetchData4 = React.useCallback(async () => {
    //cLog('HAETAAN USERILLE DATAA-----------------------')
    try {
      setLoading(true)
      dispatch(setSelected4([]))
      let sKey = sortKeyUserView4
      if (sortKeyUserView4 === 'customer') {
        sKey = 'name'
      } else if (sortKeyUserView4 === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyUserView4 === 'belongs_to_consolidated_invoice') {
        sKey = 'consolidated_invoice_id'
      } else if (sortKeyUserView4 === 'belongs_to_credit_note') {
        sKey = 'credit_note_id'
      }
      let query = ''
      if (!['updater', 'work_period', 'time_frame', 'expence_type', 'commission'].includes(sKey)) {
        query += '&sort_key=' + sKey
      }
      if (boksi) {
        query += '&boksi=true'
      }
      //cLog('NYT EDITOIDAAN**********************************************', edit ? 'TRUE' : 'FALSE' )
      //cLog('NYT TÄYDENNETÄÄN*********************************************', taxcard ? 'TRUE' : 'FALSE')
      const allUnpaidExpences = await getExpences('?user_id=' + user.id + '&include_rows=true&rows=100&status=VALMIS,OSITTAIN_MAKSETTU' + query + '&sort_order=' + sortOrderUserView4)
      setUnpaidExpences(allUnpaidExpences.expences)
      setLoading(false)
    } catch (error) {
      cLog('ONGELMIA USERIN KÄYTTÄJÄSIVUN LATAAMISESSA4', error)
      setLoading(false)
    }
  }, [boksi, dispatch, sortKeyUserView4, sortOrderUserView4, user.id])

  const fetchData5 = React.useCallback(async () => {
    //cLog('HAETAAN USERILLE DATAA-----------------------')
    try {
      setLoading(true)
      dispatch(setSelected5([]))
      let sKey = sortKeyUserView5
      if (sortKeyUserView5 === 'customer') {
        sKey = 'name'
      } else if (sortKeyUserView5 === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyUserView5 === 'belongs_to_consolidated_invoice') {
        sKey = 'consolidated_invoice_id'
      } else if (sortKeyUserView5 === 'belongs_to_credit_note') {
        sKey = 'credit_note_id'
      }
      //cLog('NYT EDITOIDAAN**********************************************', edit ? 'TRUE' : 'FALSE' )
      //cLog('NYT TÄYDENNETÄÄN*********************************************', taxcard ? 'TRUE' : 'FALSE')
      let query = '?page=' + (invoicePagination2.page - 1)
      query += '&rows=' + invoicePagination2.rowsPerPage
      if (!['updater', 'work_period', 'time_frame', 'expence_type', 'commission'].includes(sKey)) {
        query += '&sort_key=' + sKey
      }
      query += '&sort_order=' + sortOrderUserView5
      query += '&user_id=' + user.id
      query += '&include_destroyed=true'
      if (boksi) {
        query += '&boksi=true'
      }
      const allExpences = await getExpences(query)
      if (invoicePagination2.totalRows !== allExpences.totalRows || invoicePagination2.totalPages !== allExpences.totalPages || invoicePagination2.currentPage !== allExpences.currentPage) {
        setInvoicePagination2({ ...invoicePagination2, totalRows: allExpences.totalRows, totalPages: allExpences.totalPages, currentPage: allExpences.currentPage })
      }
      dispatch(setExpences(allExpences.expences))
      setLoading(false)
    } catch (error) {
      cLog('ONGELMIA USERIN KÄYTTÄJÄSIVUN LATAAMISESSA5', error)
      setLoading(false)
    }
  }, [boksi, dispatch, invoicePagination2, sortKeyUserView5, sortOrderUserView5, user.id])

  const fetchData6 = React.useCallback(async () => {
    if (forceFetchData6) {
      setLoading(true)
      setForceFetchData6(false)

      let query = '?page=' + (salaryPagination.page - 1)
      query += '&rows=' + salaryPagination.rowsPerPage
      query += '&sort_key=' + sortKeyUserView6
      query += '&sort_order=' + sortOrderUserView6
      query += '&user_id=' + user.id

      //cLog('QUERY STRING', query)
      const allSalaries = getSalaries(query)
      allSalaries.then(response => {
        const { totalRows, totalPages, currentPage } = response
        setSalaryPagination({ ...salaryPagination, totalRows, totalPages, currentPage })
        dispatch(setSalaries(response.salaries))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        dispatch(setSalaries([]))
        setLoading(false)
      })
    }
  }, [dispatch, forceFetchData6, salaryPagination, sortKeyUserView6, sortOrderUserView6, user.id])

  const fetchData7 = React.useCallback(async () => {
    if (forceFetchData7) {
      setLoading(true)
      setForceFetchData7(false)

      let sKey = sortKeyUserView7
      if (sortKeyUserView7 === 'worker') {
        sKey = 'user_id'
      } else if (sortKeyUserView7 === 'delivery_method') {
        sKey = 'send_by_email'
      }

      let query = '?page=' + (referencePagination.page - 1)
      query += '&rows=' + referencePagination.rowsPerPage
      query += '&sort_key=' + sKey
      query += '&sort_order=' + sortOrderUserView7
      query += '&user_id=' + user.id

      //cLog('QUERY STRING', query)
      const allReferences = getReferences(query)
      allReferences.then(response => {
        const { totalRows, totalPages, currentPage } = response
        setReferencePagination({ ...referencePagination, totalRows, totalPages, currentPage })
        dispatch(setReferences(response.references))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        dispatch(setReferences([]))
        setLoading(false)
      })
    }
  }, [dispatch, forceFetchData7, referencePagination, sortKeyUserView7, sortOrderUserView7, user.id])

  const getData6 = () => {
    setForceFetchData6(!forceFetchData6)
  }

  const getData7 = () => {
    setForceFetchData7(!forceFetchData7)
  }

  const fetchDataAll = () => {
    setLoading(true)
    fetchData1()
    fetchData2()
    fetchData3()
    fetchData4()
    fetchData5()
    getData6()
    getData7()
    setForceFetchData8(true)
  }

  //cLog('USER', user)
  cLog('CUSER', currentUser.id, 'USER', user)
  //cLog('ONKO ADMININ OMA SIVU', isAdminCurrentUser)

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData1()
    }
  }, [fetchData1, isAdminCurrentUser, isAdmin])

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData2()
    }
  }, [fetchData2, isAdminCurrentUser, isAdmin])

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData3()
    }
  }, [fetchData3, isAdminCurrentUser, isAdmin])

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData4()
    }
  }, [fetchData4, isAdminCurrentUser, isAdmin])

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData5()
    }
  }, [fetchData5, isAdminCurrentUser, isAdmin])

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData6()
    }
  }, [fetchData6, isAdminCurrentUser, isAdmin])

  useEffect(() => {
    if (!isAdminCurrentUser && isAdmin) {
      fetchData7()
    }
  }, [fetchData7, isAdminCurrentUser, isAdmin])
  
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        fetchDataAll()
      }
    };

    if (isAdmin && !isAdminCurrentUser) {
      document.addEventListener('keyup', handleKeyPress)
    }

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [isAdmin, isAdminCurrentUser])

  const Notes = () => {
    const today = moment()
    let bday18 = null
    let bday68 = null
    if (user.ssn) {
      const bday = (user.ssn.charAt(6) === '-' ? '19' : '20') + user.ssn.substr(4,2) + '-' + user.ssn.substr(2,2) + '-' + user.ssn.substr(0,2)
      bday18 = moment(bday).add(18, 'years').endOf('month')
      bday68 = moment(bday).add(68, 'years').endOf('month')
    }

    return (
      <div className={classes.notesContainer}>
        <Typography variant="h6">{t('user.notes')}</Typography>
        {user.ssn && (today <= bday18 || today > bday68) ? <InfoBar allUsers message={t('user.notUnderYel')} /> : ''}
        <ul>
          {userNotes.map((note, i) => {
            return (
              <li key={i}>
                <span>
                  <span>{formatTimeFI(note.date)}</span>
                  <span>{note.status}</span>
                  <Tooltip title={t('customers.edit')}>
                    <EditIcon
                      className={classes.editNote}
                      onClick={() => {
                        setEditUserNote(note)
                        setOpenUserNoteDialog(true)
                      }}
                    />
                  </Tooltip>
                  {note.status !== 'HOIDETTU' && <Tooltip title={t('user.setClosed')}>
                    <DoneIcon
                      className={classes.editNote}
                      onClick={() => {
                        setUserNoteToUpdate(note.id)
                        setOpenConfirmUpdateUserNoteModal(true)
                      }}
                    />
                  </Tooltip>}
                </span>
                <span>
                  {note.notes}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const handleAgreeUpdateUserNoteModal = async () => {
    setLoading(true)
    const request = updateUserNote({ id: userNoteToUpdate, status: 'HOIDETTU' })
    request.then(response => {
      cLog('MUISTIINPANO PÄIVITETTY', response)
      setUserNoteToUpdate(null)
      enqueueSnackbar(
        t('user.noteSent'),
        { variant: 'success' }
      )
      setLoading(false)
      setOpenConfirmUpdateUserNoteModal(false)
      setUser(response)
    }, error => {
      setUserNoteToUpdate(null)
      setLoading(false)
      setOpenConfirmUpdateUserNoteModal(false)
      cLog('MUISTIINPANO PUT ERROR', error)
      enqueueSnackbar(
        t('user.noteError'),
        { variant: 'error' }
      )
    })
  }

  const handleCloseUpdateUserNoteModal = () => {
    setOpenConfirmUpdateUserNoteModal(false)
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  const handleEditUser = () => {
    history.push('/kayttajat/muokkaa/' + user.id)
  }

  //cLog('PAYLIST INVOICES', payListInvoices)
  const howMany = (rows) => {
    return (rows && ' ' + rows.length + ' KPL') || ''
  }

  const payListTaxfreeTotalArray = payListInvoices.map(invoice => cleanFloat(invoice.totalCorrectedEur))
  const payListTaxfreeTotal = payListTaxfreeTotalArray.length > 0 ? payListTaxfreeTotalArray.reduce((a, b) => a + b) : ''
  const payListDiscountsTotalArray = payListInvoices.map(invoice => cleanFloat(invoice.commission))
  const payListDiscountsTotal = payListDiscountsTotalArray.length > 0 ? Math.max(payListDiscountsTotalArray.reduce((a, b) => a + b), 15) : ''
  let payListCoda = ' yht. ' + (payListTaxfreeTotal ? (cleanFloat(payListTaxfreeTotal).toFixed(2) + ' EUR') : '') + ' ' + howMany(payListInvoices) + ':  '
  const payListInvoiceNumbers = payListInvoices.map(invoice => invoice.invoiceNr)
  payListInvoiceNumbers.sort((a, b) => a - b)
  payListInvoiceNumbers.forEach((number, i) => {
    const separator = ((payListInvoices.length === 2 && i !== 1) || i === payListInvoices.length - 2)
      ? ' ja '
      : i === payListInvoices.length - 1
        ? ''
        : ', '
    payListCoda += number + separator
  })

  const userGrid = () => <Grid container spacing={4}>
    <Grid item xs={12} md={6} lg={4}>{/* COL1 */}
      <UserBox1 user={user} setUser={setUser} isAdmin={isAdmin} />
    </Grid>
    <Grid item xs={12} md={6} lg={4}>{/* COL2 */}
      <UserBox2 user={user} />
    </Grid>
    {!isAdminCurrentUser && <Grid item xs={12} md={12} lg={4}>
      <SummaryBox userView user={user} setUser={setUser} fetchDataAll={fetchDataAll} />
    </Grid>}
  </Grid>

  const from1 = invoicePagination1.currentPage * invoicePagination1.rowsPerPage
  const to1 = invoicePagination1.currentPage === invoicePagination1.totalPages - 1
    ? from1 + invoicePagination1.totalRows % invoicePagination1.rowsPerPage
    : from1 + invoicePagination1.rowsPerPage
  let fromTo1 = ''
  if (invoicePagination1.totalRows) {
    fromTo1 = `${from1 + 1} - ${to1} / ${invoicePagination1.totalRows}`
  } else {
    fromTo1 = `0 - 0 / 0`
  }

  const mapSortValue = (sortKey) => {
    if (sortKey === 'customer') {
      return 'name'
    } else if (sortKey === 'worker') {
      return 'user_id'
    } else if (sortKey === 'belongs_to_consolidated_invoice') {
      return 'consolidated_invoice_id'
    } else if (sortKey === 'belongs_to_credit_note') {
      return 'credit_note_id'
    }

    return sortKey
  }

  const NewPagination1 = (props) => {
    const sortByLabel = {
      'invoice_nr': t('invoice.invoiceNr'),
      'created_at': t('invoice.createdAt'),
      'updated_at': t('invoice.updatedAt'),
      'date': t('invoice.date'),
      'date_due': t('invoice.dateDue'),
      'name': t('invoice.customer'),
      'user_id': t('invoice.worker'),
      'yel': t('invoice.yel'),
      'total_corrected_eur': t('invoice.totalCorrectedEur'),
      'pay_day': t('invoice.payDay'),
      'status': t('invoice.status'),
      'accounting_status': t('invoice.accountingStatus')
    }

    const sortOrderLabel = {
      'DESC': t('invoice.desc'),
      'ASC': t('invoice.asc')
    }

    const rowsPerPageArray = [10, 20, 50, 75, 100, 200, 300, 400, 500, 1000]

    return (
      <div className={classes.newPaginationRow}>
        <Pagination pagination={invoicePagination1} setPagination={setInvoicePagination1} getData={fetchData1} />
        <span className={classes.newPaginationInfo}>{fromTo1}</span>
        {!isMobile && <span className={classes.newPaginationInfo}>{t('invoice.rowsInPage')}</span>}
        {!isMobile && <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={invoicePagination1.rowsPerPage}
          onChange={(e) => {
            setInvoicePagination1({ ...invoicePagination1, page: 1, rowsPerPage: parseInt(e.target.value) })
            fetchData1()
          }}
          disabled={isLoading}
        >
          {rowsPerPageArray.map((row, i) => <option key={i}>{row}</option>)}
        </select>}
        <span className={classes.newPaginationInfo}>{t('invoice.sort')}</span>
        <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={mapSortValue(sortKeyUserView1)}
          onChange={(e) => {
            dispatch(setSortKeyUserView1(e.target.value))
            fetchData1()
          }}
          disabled={isLoading}
        >
          {Object.keys(sortByLabel).map((row, i) => <option value={row} key={i}>{sortByLabel[row]}</option>)}
        </select>
        <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={sortOrderUserView1}
          onChange={(e) => {
            dispatch(setSortOrderUserView1(e.target.value))
            fetchData1()
          }}
          disabled={isLoading}
        >
          {['DESC', 'ASC'].map((row, i) => <option value={row} key={i}>{sortOrderLabel[row]}</option>)}
        </select>
        <span>
          {isLoading
            ? <CircularProgress size={25} />
            : <button type='button' onClick={() => fetchData1()} disabled={isLoading}>{t('taxcard.fetch')}</button>}
        </span>
        <span className={classes.newPaginationDivider}></span>
        <span>
          <FormControlLabel
            control={<MaterialUiCheckBox
              checked={invoiceFilter1.showUnaccounted}
              onChange={() => {
                setInvoiceFilter1({ ...invoiceFilter1, showUnaccounted: !invoiceFilter1.showUnaccounted, hideDestroyed: false })
                //fetchData1()
              }}
              name="showUnaccounted"
            />}
            label={t('user.showUnaccounted')}
            disabled={isLoading}
          />
          <FormControlLabel
            control={<MaterialUiCheckBox
              checked={invoiceFilter1.hideDestroyed}
              onChange={() => {
                setInvoiceFilter1({ ...invoiceFilter1, showUnaccounted: false, hideDestroyed: !invoiceFilter1.hideDestroyed })
                //fetchData1()
              }}
              name="includeDestroyed"
            />}
            label={t('user.hideDestroyed')}
            disabled={isLoading}
          />
        </span>
      </div>
    )
  }

  const from2 = invoicePagination2.currentPage * invoicePagination2.rowsPerPage
  const to2 = invoicePagination2.currentPage === invoicePagination2.totalPages - 1
    ? from2 + invoicePagination2.totalRows % invoicePagination2.rowsPerPage
    : from2 + invoicePagination2.rowsPerPage
  let fromTo2 = ''
  if (invoicePagination2.totalRows) {
    fromTo2 = `${from2 + 1} - ${to2} / ${invoicePagination2.totalRows}`
  } else {
    fromTo2 = `0 - 0 / 0`
  }

  const NewPagination2 = (props) => {
    const sortByLabel = {
      'expence_nr': t('invoice.expenceNr'),
      'created_at': t('invoice.createdAt'),
      'updated_at': t('invoice.updatedAt'),
      'updater': t('invoice.updater'),
      'date': t('invoice.date'),
      'total_with_tax': t('invoice.totalWithTax'),
      'status': t('invoice.status')
    }

    const sortOrderLabel = {
      'DESC': t('invoice.desc'),
      'ASC': t('invoice.asc')
    }

    const rowsPerPageArray = [10, 20, 50, 75, 100, 200, 300, 400, 500, 1000]

    return (
      <div className={classes.newPaginationRow}>
        <Pagination pagination={invoicePagination2} setPagination={setInvoicePagination2} getData={fetchData2} />
        <span className={classes.newPaginationInfo}>{fromTo2}</span>
        {!isMobile && <span className={classes.newPaginationInfo}>{t('invoice.rowsInPage')}</span>}
        {!isMobile && <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={invoicePagination2.rowsPerPage}
          onChange={(e) => {
            setInvoicePagination2({ ...invoicePagination2, page: 1, rowsPerPage: parseInt(e.target.value) })
            fetchData2()
          }}
          disabled={isLoading}
        >
          {rowsPerPageArray.map((row, i) => <option key={i}>{row}</option>)}
        </select>}
        <span className={classes.newPaginationInfo}>{t('invoice.sort')}</span>
        <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={mapSortValue(sortKeyUserView5)}
          onChange={(e) => {
            dispatch(setSortKeyUserView5(e.target.value))
            fetchData2()
          }}
          disabled={isLoading}
        >
          {Object.keys(sortByLabel).map((row, i) => <option value={row} key={i}>{sortByLabel[row]}</option>)}
        </select>
        <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={sortOrderUserView5}
          onChange={(e) => {
            dispatch(setSortOrderUserView5(e.target.value))
            fetchData2()
          }}
          disabled={isLoading}
        >
          {['DESC', 'ASC'].map((row, i) => <option value={row} key={i}>{sortOrderLabel[row]}</option>)}
        </select>
        <span>
          {isLoading
            ? <CircularProgress size={25} />
            : <button type='button' onClick={() => fetchData5()} disabled={isLoading}>{t('taxcard.fetch')}</button>}
        </span>
      </div>
    )
  }

  const from3 = salaryPagination.currentPage * salaryPagination.rowsPerPage
  const to3 = salaryPagination.currentPage === salaryPagination.totalPages - 1
    ? from3 + salaryPagination.totalRows % salaryPagination.rowsPerPage
    : from3 + salaryPagination.rowsPerPage
  let fromTo3 = ''
  if (salaryPagination.totalRows) {
    fromTo3 = `${from3 + 1} - ${to3} / ${salaryPagination.totalRows}`
  } else {
    fromTo3 = `0 - 0 / 0`
  }

  const NewPagination3 = (props) => {
    const sortByLabel = {
      'id': t('salary.id'),
      'created_at': t('salary.createdAt')
    }

    const sortOrderLabel = {
      'DESC': t('invoice.desc'),
      'ASC': t('invoice.asc')
    }

    const rowsPerPageArray = [10, 20, 50, 75, 100, 200, 300, 400, 500, 1000]

    return (
      <div className={classes.newPaginationRow}>
        <Pagination pagination={salaryPagination} setPagination={setSalaryPagination} getData={getData6} />
        <span className={classes.newPaginationInfo}>{fromTo3}</span>
        {!isMobile && <span className={classes.newPaginationInfo}>{t('invoice.rowsInPage')}</span>}
        {!isMobile && <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={salaryPagination.rowsPerPage}
          onChange={(e) => {
            setSalaryPagination({ ...salaryPagination, page: 1, rowsPerPage: parseInt(e.target.value) })
            getData6()
          }}
          disabled={isLoading}
        >
          {rowsPerPageArray.map((row, i) => <option key={i}>{row}</option>)}
        </select>}
        <span className={classes.newPaginationInfo}>{t('invoice.sort')}</span>
        <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={mapSortValue(sortKeyUserView6)}
          onChange={(e) => {
            dispatch(setSortKeyUserView6(e.target.value))
            getData6()
          }}
          disabled={isLoading}
        >
          {Object.keys(sortByLabel).map((row, i) => <option value={row} key={i}>{sortByLabel[row]}</option>)}
        </select>
        <select
          style={{ height: '25px', borderRadius: '4px' }}
          value={sortOrderUserView6}
          onChange={(e) => {
            dispatch(setSortOrderUserView6(e.target.value))
            getData6()
          }}
          disabled={isLoading}
        >
          {['DESC', 'ASC'].map((row, i) => <option value={row} key={i}>{sortOrderLabel[row]}</option>)}
        </select>
        <span>
          {isLoading
            ? <CircularProgress size={25} />
            : <button type='button' onClick={() => getData6()} disabled={isLoading}>{t('taxcard.fetch')}</button>}
        </span>
      </div>
    )
  }

  return (user && (
    <>
      <Header
        title={user.id === currentUser.id ? t('user.myInfo') : t('user.userInfo')}
        actions={
          <>
            <Button variant="contained" color="primary" onClick={handleEditUser}>{t('user.edit')}</Button>
            {isAdmin && user.id !== currentUser.id &&
            <>
              <Button variant="outlined" color="primary" onClick={() => setOpenMoveUserDataDialog(true)}>{t('user.moveUserData')}</Button>
              <Button variant="contained" color="error" onClick={() => setOpenConfirmDeleteUserModal(true)}>{t('user.deleteUser')}</Button>
            </>}
          </>}
      />

      <div className={classes.body}>
        {userGrid()}
        {isAdmin && user.userNotes.length > 0 && user.userNotes.filter(note => note.status === 'HOIDETTU').length > 0 &&
          <Grid item sm={12} md={12}>
            <FormControlLabel
              control={<OdealCheckbox
                checked={showClosedNotes}
                onChange={() => {
                  setShowClosedNotes(!showClosedNotes)
                }}
                name="showClosedNotes" />}
              label={t('user.showClosedNotes')}
            />
          </Grid>}
        {isAdmin && userNotes && !isAdminCurrentUser && Notes()}
      </div>

      {!isAdminCurrentUser && isAdmin && <Hidden smDown>
        <div className={classes.buttons}>
          <Button
            variant="outlined"
            onClick={() => fetchDataAll()}
            disabled={isLoading}
          >
            {t('user.update')}
          </Button>
          <Button
            variant="outlined"
            onClick={() => history.push(payList ? '/kayttajat/' + user.id : '/kayttajat/maksulista/' + user.id)}
            disabled={isLoading}
          >
            {payList ? t('user.goToUserInfo') : t('user.goToPaymentList')}
          </Button>
          {isLoading && <CircularProgress size={25} style={{ margin: '0', position: 'absolute', top: '33%' }} />}
        </div>
        {!payList && <div className={classes.invoiceTableContainer}>
          <NewPagination1 />
          {invoices.length > 0
            ?
              <>
                <InvoiceTable
                  invoices={invoices}
                  tableTitle={t('user.invoices')}
                  fetchData={fetchData1}
                  selectedColumns={['invoiceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'dateDue', 'customer', 'workPeriod', 'commission', 'yel', 'totalCorrectedEur', 'payDay', 'status', 'accountingStatus', 'actions']}
                  selected={selected1}
                  setSelected={setSelected1}
                  sortKey={sortKeyUserView1}
                  setSortKey={setSortKeyUserView1}
                  sortOrder={sortOrderUserView1}
                  setSortOrder={setSortOrderUserView1}
                  user={user}
                />
              </>
            : isLoading
              ? <CircularProgress />
              : <p>{t('user.noInvoices')}</p>}
        </div>}
        {payList && <div className={classes.invoiceTableContainer}>
          {payListInvoices.length > 0
            ? <InvoiceTable
                payList
                invoices={payListInvoices}
                tableTitle={t('user.paymentList')+' '+payListCoda}
                commissions={payListDiscountsTotal}
                fetchData={fetchData2}
                selectedColumns={['invoiceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'dateDue', 'customer', 'workPeriod', 'commission', 'yel', 'totalCorrectedEur', 'payDay', 'status', 'accountingStatus', 'actions']}
                selected={selected2}
                setSelected={setSelected2}
                sortKey={sortKeyUserView2}
                setSortKey={setSortKeyUserView2}
                sortOrder={sortOrderUserView2}
                setSortOrder={setSortOrderUserView2}
                user={user}
              />
            : isLoading
              ? <CircularProgress />
              : <p>{t('user.emptyPaymentList')}</p>}
        </div>}
        {payList && <div className={classes.invoiceTableContainer}>
          {straigthSalaries.length > 0
            ? <InvoiceTable
                payList
                invoices={straigthSalaries}
                tableTitle={t('user.instantSalaries')+' '+howMany(straigthSalaries)}
                fetchData={fetchData3}
                selectedColumns={['invoiceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'dateDue', 'customer', 'workPeriod', 'commission', 'yel', 'totalCorrectedEur', 'payDay', 'status', 'accountingStatus', 'actions']}
                selected={selected3}
                setSelected={setSelected3}
                sortKey={sortKeyUserView3}
                setSortKey={setSortKeyUserView3}
                sortOrder={sortOrderUserView3}
                setSortOrder={setSortOrderUserView3}
                user={user}
              />
            : isLoading
              ? <CircularProgress />
              : <p>{t('user.emptyInstant')}</p>}
        </div>}
        {payList && <div className={classes.invoiceTableContainer}>
          {unpaidExpences.length > 0
            ? <InvoiceTable
                payList
                isExp
                invoices={unpaidExpences}
                tableTitle={t('user.openExpenses')+' '+howMany(unpaidExpences)}
                fetchData={fetchData4}
                selectedColumns={['expenceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'expenceType', 'timeFrame', 'totalWithTax', 'status', 'actions']}
                selected={selected4}
                setSelected={setSelected4}
                sortKey={sortKeyUserView4}
                setSortKey={setSortKeyUserView4}
                sortOrder={sortOrderUserView4}
                setSortOrder={setSortOrderUserView4}
                user={user}
              />
            : isLoading
              ? <CircularProgress />
              : <p>{t('user.emptyExpenses')}</p>}
        </div>}
        {!payList && <div className={classes.invoiceTableContainer}>
          {expences.length > 0
            ?
              <>
                <NewPagination2 />
                <InvoiceTable
                  isExp
                  invoices={expences}
                  tableTitle={t('user.expenses')}
                  fetchData={fetchData5}
                  selectedColumns={['expenceNr', 'createdAt', 'updatedAt', 'updater', 'date', 'expenceType', 'timeFrame', 'totalWithTax', 'status', 'actions']}
                  selected={selected5}
                  setSelected={setSelected5}
                  sortKey={sortKeyUserView5}
                  setSortKey={setSortKeyUserView5}
                  sortOrder={sortOrderUserView5}
                  setSortOrder={setSortOrderUserView5}
                  user={user}
                />
              </>
            : isLoading
              ? <CircularProgress />
              : <p>{t('user.noExpenses')}</p>}
        </div>}
        {!payList && <div className={classes.invoiceTableContainer}>
          {salaries.length > 0
            ?
              <>
                <NewPagination3 />
                <SalaryTable
                  salaries={salaries}
                  tableTitle={t('frontPage.salaries')}
                  fetchData={fetchData6}
                  selectedColumns={['id', 'createdAt', 'actions']}
                  sortKey={sortKeyUserView6}
                  setSortKey={setSortKeyUserView6}
                  sortOrder={sortOrderUserView6}
                  setSortOrder={setSortOrderUserView6}
                  setForceFetchData={setForceFetchData6}
                />
              </>
            : isLoading
                ? <CircularProgress />
                : <p>{t('user.noSalaries')}</p>}
        </div>}
        {!payList && <div className={classes.invoiceTableContainer}>
          <References
            userId={user.id}
            forceFetchDataUserView={forceFetchData7}
            setForceFetchDataUserView={setForceFetchData7}
           />
        </div>}
        {!payList && <div className={classes.invoiceTableContainer}>
          <Valtticards
            userId={user.id}
            forceFetchDataUserView={forceFetchData8}
            setForceFetchDataUserView={setForceFetchData8}
           />
        </div>}
        <Formik
          enableReinitialize
          initialValues={USER_NOTE_TEMPLATE}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formikProps) => <UserNoteDialog open={openUserNoteDialog} setOpenUserNoteDialog={setOpenUserNoteDialog} editUserNote={editUserNote} user={user} setUser={setUser} {...formikProps} />}
        </Formik>
        <Alert
          open={openConfirmUpdateUserNoteModal}
          setOpen={setOpenConfirmUpdateUserNoteModal}
          handleAgree={handleAgreeUpdateUserNoteModal}
          handleClose={handleCloseUpdateUserNoteModal}
          alertMessage={{ title: t('user.userNoteClosedWarningTitle') }}
        />
        <CsvDialog open={openCsvDialog} setOpenCsvDialog={setOpenCsvDialog} user={user} />
        <Alert
          open={openConfirmDeleteUserModal}
          setOpen={setOpenConfirmDeleteUserModal}
          handleAgree={handleAgreeDeleteUserModal}
          handleClose={() => setOpenConfirmDeleteUserModal(false) }
          alertMessage={{ title: t('users.confirmDeletionTitle') + user.firstName + ' ' + user.lastName + '?', body: t('users.confirmDeletionBody') }}
          confirmButtonText={t('customers.remove')}
          confirmButtonColor="error"
        />
        <Formik
          enableReinitialize
          initialValues={{ newId: '' }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formikProps) => <MoveUserDataDialog open={openMoveUserDataDialog} setOpenMoveUserDataDialog={setOpenMoveUserDataDialog} user={user} {...formikProps} />}
        </Formik>
      </Hidden>}

      {user.id === currentUser.id
         ? <PasswordDialog open={openPasswordDialog} setOpenPasswordDialog={setOpenPasswordDialog} />
         : <PasswordDialogOffice open={openPasswordDialogOffice} setOpenPasswordDialog={setOpenPasswordDialogOffice} user={user} />}
      <InviteUserDialog open={openInviteUserDialog} setOpenInviteUserDialog={setOpenInviteUserDialog} />
    </>
  )) || <CircularProgress />
}

export default withSnackbar(UserView)
