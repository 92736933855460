import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery, getAnnouncement, deleteAnnouncement } from '../../service'
import Button from '../../components/Button'
import { useStateValue, setAnnouncements, setSortKeyAnnouncements, setSortOrderAnnouncements } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'
import Datatable from '../../components/Datatable'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import Alert from '../../components/Alert'
import { withSnackbar } from 'notistack'
import parse from 'html-react-parser'
import AnnouncementDialog from './AnnouncementDialog'
import { Formik } from 'formik'

let selectedColumns  = []

const ANNOUNCEMENT_TEMPLATE = {
  id: null,
  titleFi: '',
  htmlFi: '',
  isPublished: true,
  titleEn: '',
  htmlEn: '',
}

const Announcements = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ announcements, sortKeyAnnouncements, sortOrderAnnouncements }, dispatch] = useStateValue()
  const [forceFetchData, setForceFetchData] = useState(true)
  const [selectedRow, setSelectedRow] = useState(null)
  const [language, setLanguage] = useState('fi')
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [openAnnouncementDialog, setOpenAnnouncementDialog] = useState(false)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })

  let allColumns = ['titleFi', 'titleEn', 'isPublished', 'createdAt', 'updatedAt', 'actions']
  selectedColumns = JSON.parse(window.localStorage.getItem('announcementsSelectedColumns')) || allColumns
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('announcements.'+option),
      value: option
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })

  useEffect(() => {
    const createActions = (row, userId) => {
      return (
        <span style={{whiteSpace: "nowrap"}}>
          <Tooltip title={t('customers.edit')}>
            <EditIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const request = getAnnouncement(row.id)
                request.then(response => {
                  setSelectedRow(response)
                  setOpenAnnouncementDialog(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>

          <Tooltip title={t('customers.remove')}>
            <DeleteOutlineIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getAnnouncement(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setOpenConfirmDeleteModal(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
          <Tooltip title={t('announcements.viewFinnish')}>
            <VisibilityOutlinedIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getAnnouncement(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setLanguage('fi')
                  setOpenInfoDialog(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
          <Tooltip title={t('announcements.viewEnglish')}>
            <VisibilityOutlinedIcon
              className="actionIcon"
              onClick={() => {
                setLoading(true)
                const response = getAnnouncement(row.id)
                response.then(data => {
                  setSelectedRow(data)
                  setLanguage('en')
                  setOpenInfoDialog(true)
                  setLoading(false)
                }, error => {
                  cLog('FETCHING DATA ERROR', error)
                  enqueueSnackbar(
                    t('auth.problemsLoadingData'),
                    { variant: 'error' }
                  )
                  setLoading(false)
                })
              }}
            />
          </Tooltip>
        </span>
      )
    }

    const fetchData = async () => {
      const response = getQuery(pagination, setPagination, sortKeyAnnouncements, sortOrderAnnouncements, {}, selectedColumns, 'announcements')
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          return row
        })
        dispatch(setAnnouncements(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setAnnouncements([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyAnnouncements, sortOrderAnnouncements, pagination, t, enqueueSnackbar])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.announcementsTitle') + coda
  }

  const handleAgreeDeleteModal = async () => {
    try {
      await deleteAnnouncement(selectedRow.id)
      cLog(`ANNOUNCEMENT ${selectedRow.id} DELETED`)
      enqueueSnackbar(
        t('announcements.announcementDeleted'),
        { variant: 'success' }
      )
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      setForceFetchData(true)
    } catch (error) {
      cLog('DELETE ANNOUNCEMENT ERROR', error.response.data.errors)
      setSelectedRow(null)
      setOpenConfirmDeleteModal(false)
      enqueueSnackbar(
        t('announcements.problemsDeletingAnnouncement'),
        { variant: 'error' }
      )
    }
  }

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.announcementsTitle')}
        actions={<Button variant="contained" color="primary" onClick={() => setOpenAnnouncementDialog(true)}>{t('announcements.addNewAnnouncement')}</Button>}
      />
      <Datatable
        data={announcements}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyAnnouncements}
        setSortKey={setSortKeyAnnouncements}
        sortOrder={sortOrderAnnouncements}
        setSortOrder={setSortOrderAnnouncements}
        isAdmin={true}
      />
      <Formik
        enableReinitialize
        initialValues={selectedRow ? selectedRow : ANNOUNCEMENT_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <AnnouncementDialog
          open={openAnnouncementDialog}
          setOpen={setOpenAnnouncementDialog}
          fetchData={() => setForceFetchData(true)}
          setAnnouncement={setSelectedRow}
          {...formikProps}
        />}
      </Formik>
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('announcements.confirmDeletionTitle'), body: t('announcements.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
        isLoading={isLoading}
      />
      <Alert
        open={openInfoDialog}
        setOpen={setOpenInfoDialog}
        handleClose={() => setOpenInfoDialog(false) }
        alertMessage={{ title: selectedRow ? (language === 'en' && selectedRow.titleEn ? selectedRow.titleEn : selectedRow.titleFi) : '', body: selectedRow ? parse(language === 'en' && selectedRow.htmlEn ? selectedRow.htmlEn : selectedRow.htmlFi) : '' }}
        hideClose={true}
        handleAgree={() => setOpenInfoDialog(false)}
      />
    </LayoutContainer>
  )
}

export default withSnackbar(Announcements)
