import React from 'react';
import Button from './Button'
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export default function Alert({ open, handleAgree, handleClose, alertMessage, isLoading, confirmButtonText, confirmButtonColor, hideClose = false }) {
  const { t } = useTranslation()

  return ( 
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{alertMessage.title}</DialogTitle>
      {alertMessage.body && <DialogContent dividers={true} id="alert-dialog-description">
        {alertMessage.body}
      </DialogContent>}
      <DialogActions>
        {!hideClose && <Button
          variant="text"
          onClick={handleClose}
          color="secondary"
        >
          {t('frontPage.cancel')}
        </Button>}
        {handleAgree && <Button
          variant="text"
          onClick={handleAgree}
          color={confirmButtonColor || "primary"}
        >
          {isLoading ? <CircularProgress size={20} /> : (confirmButtonText || t('frontPage.agree'))}
        </Button>}
      </DialogActions>
    </Dialog>
  )
}
