import moment from 'moment'
import { t } from "../i18n"

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const formatDatetime = (datetime) => moment(datetime).format('DD.MM.YYYY HH:mm')

export const formatDate = (datetime) => moment(datetime).format('YYYY-MM-DD')

export const formatTimeFI = (date) => new Intl.DateTimeFormat('fi-FI').format(new Date(date))
 
export const getTime = (date) => date.split('T')[1].split('.')[0]
//2020-07-15T13:43:45.726+03:00

export const getDateNow = () => new Date().toISOString().split('T')[0]

export const getYesterday = () => {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  //cLog('YESTERDAY', yesterday.toISOString().split('T')[0])
  return yesterday.toISOString().split('T')[0]
}

export const getDateISOString = (date) => new Date(date).toISOString().split('T')[0]

export const getDateIn2weeks = () => new Date(Date.now() + (12096e5 / 2)).toISOString().split('T')[0]

export const getDate4WeeksAgo = () => {
  let date = new Date();
  let timeInMillisec = date.getTime();	// the milliseconds elapsed since 01 January, 1970 00:00:00 UTC
  timeInMillisec -=  28 * 24 * 60 * 60 * 1000; 	// 14 days in milliseconds
  date.setTime(timeInMillisec);
  return date.toISOString().split('T')[0]
}

export const roundNumber = (num, scale) => {
  if(!("" + num).includes("e")) {
    const num2 = Math.abs(num)
    if (num < 0) {
      return -(Math.round(num2 + "e+" + scale)  + "e-" + scale);
    } else {
      return +(Math.round(num2 + "e+" + scale)  + "e-" + scale);
    }
  } else {
    const num2 = Math.abs(num)
    var arr = ("" + num2).split("e");
    var sig = ""
    if(+arr[1] + scale > 0) {
      sig = "+";
    }
    if (num < 0) {
      return -(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    } else {
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
  }
}

export const scrollTo = (id, block = 'center') => document.getElementById(id).scrollIntoView({ behavior: 'smooth', block });

export function random_rgba() {
  var o = Math.round, r = Math.random, s = 255;
  return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
}

export const UNITS = {
  PIECE: 'kpl',
  HOUR: 'h',
  LOT: 'erä',
  SERIES: 'sarja',
  KILOMETER: 'km',
  DAY: 'päivä(ä)',
  METER: 'm',
  SQUARE_METER: 'm2',
  BAG: 'pss',
  LITER: 'litra'
}

/*  const unitTable = {
      kpl: 'PIECE',
      h: 'HOUR',
      erä: 'LOT',
      sarja: 'SERIES',
      km: 'KILOMETER',
      'päivä(ä)': 'DAY',
      m: 'METER',
      m2: 'SQUARE_METER',
      pss: 'BAG',
      litra: 'LITER'
  } */


export const cLog = (a, b, c, d) => {
  if (process.env.REACT_APP_CUSTOM_NODE_ENV !== 'PRODUCTION') {
    console.log(a || '', b || '', c || '', d || '')
  }
}

export const notifyOffice = (isAdmin, enqueueSnackbar, error, translate) => {
  if (isAdmin && error) {
    if (error.response && error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach(message => {
        if (translate) {
          enqueueSnackbar(
            t('invoice.'+message),
            { variant: 'error' }
          )
        } else {
          enqueueSnackbar(
            message,
            { variant: 'error' }
          )
        }
      })
    } else if (typeof error === 'string' || error instanceof String) {
      if (translate) {
        enqueueSnackbar(
          t('invoice.'+error),
          { variant: 'error' }
        )
      } else {
        enqueueSnackbar(
          error,
          { variant: 'error' }
        )
      }
    }
  }
}

export const VALTTICARD_FORM_TEMPLATE = {
  id: '',
  userId: '',
  status: 'TILATTU',
  orderDate: '',
  validUntil: '',
  confirm: '',
  notes: '',
  lastInvoice: '',
  invoiceData: [],
  updateHistory: []
}
