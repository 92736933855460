import styled from 'styled-components'
import { FONTS } from '../styles'

export const Label = styled.label`
  // display: flex;
  // flex-direction: column;
  // color: black; // #777;
  // font-family: ${FONTS.FONT_FAMILY1};
  // font-size:${FONTS.FONT_SIZE1};
  // margin: 0.5em 0;
`;
