import React, { useState, useEffect } from 'react'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, FormControlLabel } from '@material-ui/core'
import { makeStyles, alpha } from "@material-ui/core/styles"
import { withSnackbar } from 'notistack'
import { saveAnnouncement, updateAnnouncement, deleteAnnouncement } from '../../service'
import { cLog, formatDatetime } from '../../utils'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import Alert from '../../components/Alert'
import { Field } from 'formik'
import { validateTextArea } from '../Invoice/validationSchemas'
import OdealCheckbox from '../../components/OdealCheckbox'

import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Input from '../../components/Input'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'

const useStyles = makeStyles((theme) => ({
  customInputContainer: {
    width: '100%',
  },
  customInputLabel: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(1),
  },
  customInput: {
    position: 'relative',
    background: theme.palette.white.main,
    color: theme.palette.white.contrastText,
    border: theme.borders.thin(theme.palette.grey.dark),
    borderRadius: theme.borders.radius.small,
    outline: 'none',
    padding: theme.spacing(2, 3),
    fontSize: "1rem",
    minHeight: "46px",
    boxShadow: 'none',
    transition: 'border-color 0.125s, box-shadow 0.125s',

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus-within': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.9)} 0 0 0 1px`,
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
    },

    '&.error': {
      borderColor: theme.palette.error.main,
      '&:focus-within': {
        boxShadow: `${alpha(theme.palette.error.main, 0.9)} 0 0 0 1px`,
      },
    },
  },
  editor: {
    border: '1px solid lightgrey',
    borderRadius: '4px',
    padding: theme.spacing(2, 3),
    overflowY: 'auto',
    maxHeight: '500px',
  },
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
}))

const AnnouncementDialog = ({
  resetForm,
  values,
  setFieldValue,
  validateForm,
  errors,
  enqueueSnackbar,
  open,
  setOpen,
  fetchData,
  setAnnouncement
}) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const classes = useStyles()
  const [expanded, setExpanded] = useState('finnish')
  const [editorStateFi, setEditorStateFi] = useState(null)
  const [editorStateEn, setEditorStateEn] = useState(null)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [init, setInit] = useState(true)
  cLog('INFO', values)

  useEffect(() => {
    if (open && init) {
      setInit(false)

      let contentBlock = htmlToDraft(values.htmlFi);
      let contentState
      let editorState
      if (contentBlock) {
        contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState = EditorState.createWithContent(contentState);
        setEditorStateFi(editorState)
      }
      if (values.htmlEn) {
        contentBlock = htmlToDraft(values.htmlEn);
        if (contentBlock) {
          contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          editorState = EditorState.createWithContent(contentState);
          setEditorStateEn(editorState)
        }
      }
    }
  }, [open, init, values.htmlFi, values.htmlEn])

  const handleChangeExpansion = (panel) => (event, isExpanded) => {
    panel === expanded ? setExpanded('') : setExpanded(panel)
  }

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    if (!errorTypes[0]) {
      enqueueSnackbar(
        t('auth.fillMissingFields'),
        { variant: 'error' }
      )
    }
  }

  const handleSaveAnnoucement = async () => {
    setLoading(true)
    const errori = await validateForm()
    if (Object.keys(errori).length > 0) {
      notifyErrors(errori)
      setLoading(false)
    } else {
      let htmlEn = formatHtml(values.htmlEn)
      if (htmlEn) {
        htmlEn = values.htmlEn
      }

      let request
      if (values.id) {
        request = updateAnnouncement({ ...values, htmlEn: htmlEn })
      } else {
        request = saveAnnouncement({ ...values, htmlEn: htmlEn })
      }
      request.then(response => {
        cLog('ANNOUNCEMENT SAVED', response)
        enqueueSnackbar(
          t('user.noteSent'),
          { variant: 'success' }
        )
        setLoading(false)
        setOpen(false)
        setInit(true)
        setExpanded('finnish')
        setAnnouncement(null)
        resetForm()
        fetchData()
      }, error => {
        setLoading(false)
        cLog('ANNOUNCEMENT POST ERROR', error)
        enqueueSnackbar(
          t('user.noteError'),
          { variant: 'error' }
        )
      })
    }
  }

  const formatHtml = (value) => {
    if (value) {
      let str = value

      let firstIndex = str.indexOf(">");    
      if (firstIndex !== -1) {
        str = str.substring(firstIndex + 1);
      }

      let lastIndex = str.lastIndexOf("<");
      if (lastIndex !== -1) {
        str = str.substring(0, lastIndex);
      }

      return str.trim();
    }

    return ''
  }

  const checkFiErrors = () => {
    if (validateTextArea(values.titleFi, t('announcements.title'), 250, true)
      || validateTextArea(formatHtml(values.htmlFi), t('announcements.html'), 10000, true)
    ) {
      return false
    }
    return true
  }

  const haveErrors = (errors && Object.keys(errors).length > 0) || false
  const checkEnErrors = () => {
    if (validateTextArea(values.titleEn, t('announcements.title'), 250, formatHtml(values.htmlEn))
      || validateTextArea(formatHtml(values.htmlEn), t('announcements.html'), 10000, values.titleEn)
    ) {
      return false
    }
    return true
  }

  const handleAgreeDeleteModal = async () => {
    setLoading(true)
    const request = deleteAnnouncement(values.id)
    request.then(response => {
      cLog(`ANNOUNCEMENT ${values.id} DELETED`)
      enqueueSnackbar(
        t('announcements.announcementDeleted'),
        { variant: 'success' }
      )
      setOpenConfirmDeleteModal(false)
      setLoading(false)
      setOpen(false)
      setInit(true)
      setExpanded('finnish')
      setAnnouncement(null)
      resetForm()
      fetchData()
    }, error => {
      cLog('DELETE ANNOUNCEMENT ERROR', error.response.data.errors)
      setLoading(false)
      setOpen(false)
      setInit(true)
      setExpanded('finnish')
      setAnnouncement(null)
      resetForm()
      enqueueSnackbar(
        t('announcements.problemsDeletingAnnouncement'),
        { variant: 'error' }
      )
    })
  }

  const History = () => {
    let collection = [];
    values && values.updateHistory && values.updateHistory.map((row, i) => {
      let name, filtered, joined;
      let changes = [];

      for (const [key, value] of Object.entries(row.changes)) {
        if (key !== 'user_id') {
          let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
          let value0 = value[0]
          let value1 = value[1]

          if (value[0] || value[1]) {
            if (value0 === 'true' || value0 === true) {
              value0 = t('taxcard.true')
            } else if (value0 === 'false'|| value0 === false) {
              value0 = t('taxcard.false')
            } else if (!value0) {
              value0 = t('user.noValue')
            }

            if (value1 === 'true' || value1 === true) {
              value1 = t('taxcard.true')
            } else if (value1 === 'false' || value1 === false) {
              value1 = t('taxcard.false')
            } else if (!value1) {
              value1 = t('user.noValue')
            }

            changes.push(t('announcements.'+k) + ': ' + value0 + ' -> ' + value1)
          }
        }
      }

      if (changes.length) {
        if (row.firstName && row.lastName) {
          name = row.firstName + ' ' + row.lastName;
        } else if (row.firstName && !row.lastName) {
          name = row.firstName;
        } else if (!row.firstName && row.lastName) {
          name = row.lastName
        } else {
          name = 'Odealin järjestelmä'
        }

        if (row.itemType !== "ActiveStorage::Attachment") {
          filtered = changes.filter(function (f) {
            return f != null;
          });
          joined = filtered.join(', ');
        }

        collection.push(<TableRow key={i}>
          <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{joined}</TableCell>
        </TableRow>)
      }

      return null
    })

    return (<div style={{ paddingBottom: '20px' }}>
      <br /><br />
      {t('user.historyTitleNotes')}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('customers.date')}</TableCell>
            <TableCell>{t('customers.user')}</TableCell>
            <TableCell>{t('customers.events')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection}
        </TableBody>
      </Table>
    </div>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setOpen(false)
          setInit(true)
          setExpanded('finnish')
          setAnnouncement(null)
          resetForm()
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="announcement-dialog-title"
      aria-describedby="announcement-dialog-description"
    >
      <DialogTitle id="announcement-dialog-title">{values.id ? t('announcements.edit') : t('announcements.new')}</DialogTitle>
      <DialogContent dividers={true} id="announcement-dialog-description">
        <h3>TÄRKEÄÄ: </h3>
        <p>Kun lisäät tekstiin linkkiä, käytä <span role="img" aria-label="link-icon">&#128279;</span> - toimintoa editorista. ÄLÄ copypasteta linkkiä suoraan muualta. Lisäksi on TÄRKEÄÄ, että valitset <span role="img" aria-label="link-icon">&#128279;</span> - valikosta Open link in new window. </p>
        <form>
          <Hanuri>
            <HanuriTitle
              expanded={expanded === 'finnish'}
              id={'finnish'}
              open={handleChangeExpansion('finnish')}
              label={t('announcements.finnish')}
              done={checkFiErrors()}
              error={haveErrors && !checkFiErrors()}
            />
            <HanuriBody expanded={expanded === 'finnish'}>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={12}>
                  <Field
                    name="titleFi"
                    id='titleFi'
                    label={t('announcements.title')+' *'}
                    type='text'
                    component={Input}
                    value={values.titleFi}
                    onChange={(e) => { setFieldValue('titleFi', e.target.value) }}
                    validate={() => validateTextArea(values.titleFi, t('announcements.title'), 250, true)}
                    error={errors && errors.titleFi && validateTextArea(values.titleFi, t('announcements.title'), 250, true)}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Field
                    name="htmlFi"
                    id='htmlFi'
                    type='text'
                    component={() => {
                      return (
                        <div className={classes.customInputContainer}>
                          <label htmlFor='htmlFi' className={classes.customInputLabel}>
                            <span>{t('announcements.html')+' *'}</span>
                          </label>
                          <Editor
                            wrapperClassName={classes.customInput}
                            editorClassName={classes.editor}
                            editorState={editorStateFi}
                            onEditorStateChange={(e) => {
                              setEditorStateFi(e)
                              setFieldValue('htmlFi', draftToHtml(convertToRaw(e.getCurrentContent())))
                            }}
                          />
                        </div>
                      )
                    }}
                    validate={() => validateTextArea(formatHtml(values.htmlFi), t('announcements.html'), 10000, true)}
                    error={errors && errors.htmlFi && validateTextArea(formatHtml(values.htmlFi), t('announcements.html'), 10000, true)}
                  />
                  
                </Grid>
              </Grid>
            </HanuriBody>
          </Hanuri>

          <Hanuri>
            <HanuriTitle
              expanded={expanded === 'english'}
              id={'english'}
              open={handleChangeExpansion('english')}
              label={t('announcements.english')}
              done={checkEnErrors()}
              error={haveErrors && !checkEnErrors()}
            />
            <HanuriBody expanded={expanded === 'english'}>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={12}>
                  <Field
                    name="titleEn"
                    id='titleEn'
                    label={t('announcements.title')+' *'}
                    type='text'
                    component={Input}
                    value={values.titleEn}
                    onChange={(e) => { setFieldValue('titleEn', e.target.value) }}
                    validate={() => validateTextArea(values.titleEn, t('announcements.title'), 250, formatHtml(values.htmlEn))}
                    error={errors && errors.titleEn && validateTextArea(values.titleEn, t('announcements.title'), 250, formatHtml(values.htmlEn))}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Field
                    name="htmlEn"
                    id='htmlEn'
                    type='text'
                    component={() => {
                      return (
                        <div className={classes.customInputContainer}>
                          <label htmlFor='htmlEn' className={classes.customInputLabel}>
                            <span>{t('announcements.html')+' *'}</span>
                          </label>
                          <Editor
                            wrapperClassName={classes.customInput}
                            editorClassName={classes.editor}
                            editorState={editorStateEn}
                            onEditorStateChange={(e) => {
                              setEditorStateEn(e)
                              setFieldValue('htmlEn', draftToHtml(convertToRaw(e.getCurrentContent())))
                            }}
                          />
                        </div>
                      )
                    }}
                    validate={() => validateTextArea(formatHtml(values.htmlEn), t('announcements.html'), 10000, values.titleEn)}
                    error={errors && errors.htmlEn && validateTextArea(formatHtml(values.htmlEn), t('announcements.html'), 10000, values.titleEn)}
                  />
                  
                </Grid>
              </Grid>
            </HanuriBody>
          </Hanuri>

          <FormControlLabel
            control={<OdealCheckbox
              checked={values.isPublished}
              onChange={() => {
                setFieldValue('isPublished', !values.isPublished)
              }}
              name="isPublished" />}
            label={t('announcements.isPublished')}
          />

          {showHistory && <Grid container spacing={0}>
            <Grid item xs={12}>
              <History />
            </Grid>
          </Grid>}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => setShowHistory(!showHistory)}
          color="secondary"
          disabled={isLoading}
        >
          {t('discountCode.history')}
        </Button>
        <Button
          variant="text"
          onClick={() => { setOpenConfirmDeleteModal(true) }}
          color="error"
          disabled={isLoading}
        >
          {t('customers.remove')}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            setLoading(false)
            setOpen(false)
            setInit(true)
            setExpanded('finnish')
            setAnnouncement(null)
            resetForm()
          }}
          color="secondary"
          disabled={isLoading}
        >
          {t('user.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleSaveAnnoucement}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('invoice.save')}
        </Button>
      </DialogActions>
      <Alert
        open={openConfirmDeleteModal}
        setOpen={setOpenConfirmDeleteModal}
        handleAgree={handleAgreeDeleteModal}
        handleClose={() => {
          setOpenConfirmDeleteModal(false)
        }}
        alertMessage={{ title: t('announcements.confirmDeletionTitle'), body: t('announcements.confirmDeletionBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
        isLoading={isLoading}
      />
    </Dialog>
  )
}

export default withSnackbar(AnnouncementDialog)
