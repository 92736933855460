// TODO poista jos tehdään userview uusiksi käyttämään datatable komponentteja
import React from 'react'
import { useStateValue } from "../../state"
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Tooltip } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { formatDatetime, camelToSnakeCase } from '../../utils'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DownloadOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import { printSalaryPdf } from '../../service'

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%',
    fontFamily: 'inherit',
    width: '300px'
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { tableTitle } = props

  return (
    <Toolbar
      className={clsx(classes.root, {})}
    >
      <div>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {tableTitle}
        </Typography>
      </div>
    </Toolbar>
  );
}

function EnhancedTableHead(props) {
  const { t } = useTranslation()
  const { isAdmin, classes, onRequestSort, sortKey, sortOrder } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headCells = []

  headCells.push({ id: 'id', label: t('salary.id') })
  if (isAdmin) {
    headCells.push({ id: 'worker', label: t('salary.worker') })
  }
  headCells.push({ id: 'createdAt', label: t('salary.createdAt') })
  headCells.push({ id: 'actions', label: t('salary.actions') })

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          props.selectedColumns.includes(headCell.id) && <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={sortKey === camelToSnakeCase(headCell.id) && !['actions'].includes(camelToSnakeCase(headCell.id)) ? sortOrder : false}
          >
            {!['actions'].includes(camelToSnakeCase(headCell.id))
              ? <TableSortLabel
                  active={(sortKey === camelToSnakeCase(headCell.id)) || (sortKey === 'user_id' && headCell.id === 'worker')}
                  direction={sortOrder}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {sortKey === camelToSnakeCase(headCell.id) ? (
                    <span className={classes.visuallyHidden}>
                      {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              : <div>
                  {headCell.label}
                </div>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'calc(100vh - 160px)',
      overflowX: 'hidden',
    },
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  spanLink: {
    cursor: 'pointer',
    color:' blue',
    textDecoration: 'underline'
  }
}))

const SalaryTable = ({ salaries, enqueueSnackbar, fetchData, tableTitle, selectedColumns, isAdmin, sortKey, setSortKey, sortOrder, setSortOrder, setLoading, setForceFetchData }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStateValue()
  const classes = useStyles()

  const rows = salaries.map(salary => {
    const userName = `${salary.userFirstName || 'N.'} ${salary.userLastName || 'N.'}`
    const worker = { id: salary.userId, userName }
    const { id, createdAt } = salary
    if (isAdmin) {
      return ({ id, worker, createdAt })
    } else {
      return ({ id, createdAt })
    }
  })

  const handleRequestSort = (event, property) => {
    let key = camelToSnakeCase(property)

    if (!['actions'].includes(key)) {
      let order = sortOrder
      if (sortKey === key || (sortKey === 'user_id' && key === 'worker')) {
        order = sortOrder === 'ASC' ? 'DESC' : 'ASC'
      }
      dispatch(setSortKey(key))
      dispatch(setSortOrder(order))
      fetchData(camelToSnakeCase(property), order)
      if (setForceFetchData) {
        // for user view
        setForceFetchData(true)
      }
    }
  }

  const handlePrintPdf = async (id) => {
    setLoading(true)
    const data = printSalaryPdf(id)
    data.then(response => {
      const blob = new Blob([response.data], {type: "application/pdf"})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = t('salary.salary')+' '+id
      document.body.append(link)
      link.click()
      link.remove()
      setTimeout(() => URL.revokeObjectURL(link.href), 7000)
      setLoading(false)
    }, error => {
      cLog('SENDING EXPENSE ERROR', error)
      enqueueSnackbar(
        t('invoice.pdfProblem'),
        { variant: 'error' }
      )
      setLoading(false)
    })
  }

  return (
    <>
      <EnhancedTableToolbar
        tableTitle={tableTitle}
        isAdmin={isAdmin}
      />
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'small'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            isAdmin={isAdmin}
            selectedColumns={selectedColumns}
            onRequestSort={handleRequestSort}
            sortKey={sortKey || 'updated_at'}
            sortOrder={sortOrder ? sortOrder.toLowerCase() : 'desc'}
          />
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  {isAdmin && selectedColumns.includes('id') && <TableCell>{row.id}</TableCell>}
                  {isAdmin && selectedColumns.includes('worker') && <TableCell>{(row.worker.id) ? <Link to={`/kayttajat/${row.worker.id}`} target='_blank'>{row.worker.userName}</Link> : row.worker.userName}</TableCell>}
                  {isAdmin && selectedColumns.includes('createdAt') && <TableCell>{row.createdAt && formatDatetime(row.createdAt)}</TableCell>}
                  {selectedColumns.includes('actions') && <TableCell>
                    <Tooltip title={t('taxcard.download')}>
                      <span className={classes.spanLink} onClick={() => handlePrintPdf(row.id)}>
                        <DownloadOutlinedIcon fontSize='large' />
                      </span>
                    </Tooltip>
                  </TableCell>}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default withSnackbar(SalaryTable)
