import React from "react";
import { useStateValue } from "../state"
import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    //padding: `64px 0 0 0`,
    overflowX: "hidden",
    minHeight: '100%',
    width: '100%',
  },
  contentContainer: {
    background: theme.palette.white.main,
    color: theme.palette.white.contrastText,
    padding: theme.spacing(8, 8, 16, 8),
    minHeight: 'calc(100vh - 64px)',
    borderRadius: 0,

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(8, 4),
    },

    margin: '0 auto',
    maxWidth: theme.breakpoints.values.max,

    '&.control-panel-width': {
      [theme.breakpoints.up("max")]: {
        minWidth: theme.breakpoints.values.max,
        maxWidth: '85vw',
      },
    },
  },
  noPadding: {
    minHeight: '0 !important',
    padding: '0 !important'
  }
}));

const LayoutContainer = (props) => {
  const [{ currentUser }] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const classes = useStyles();

  return (
    <div className={`${classes.layoutContainer}`}>
      <Paper className={`${classes.contentContainer}${isAdmin ? ' control-panel-width' : ''}${props.noPadding ? ' ' + classes.noPadding : ''}`}>
        {props.children}
      </Paper>
    </div>
  );
}

export default LayoutContainer;
