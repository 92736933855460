import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles, darken, alpha } from "@material-ui/core/styles";

const useStyles = (props) => makeStyles((theme) => ({
    iconButton: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
        color: theme.palette[props.color].main,
        margin: 0,
        borderRadius: theme.borders.radius.rounded,
        transition: `${theme.transitions.create(["color", "background"], {
          duration: theme.transitions.duration.shorter,
        })}`,

        [theme.breakpoints.up("xs")]: {
            padding: theme.spacing(props.size.xs * 0.3, props.size.xs * 0.4),
            fontSize: "0.8rem",
        },
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(props.size.sm * 0.3, props.size.sm * 0.4),
            fontSize: "0.9rem",
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(props.size.md * 0.3, props.size.md * 0.4),
            fontSize: "1rem",
        },
        [theme.breakpoints.up("lg")]: {
            padding: theme.spacing(props.size.lg * 0.3, props.size.lg * 0.4),
            fontSize: "1rem",
        },
        [theme.breakpoints.up("xl")]: {
            padding: theme.spacing(props.size.xl * 0.3, props.size.xl * 0.4),
            fontSize: "1rem",
        },

        "& > svg": {
            marginBottom: theme.spacing(1),
            transition: "transform 0.125s",

            [theme.breakpoints.up("xs")]: {
                width: theme.spacing(props.size.xs),
                height: theme.spacing(props.size.xs),
            },
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(props.size.sm),
                height: theme.spacing(props.size.sm),
            },
            [theme.breakpoints.up("md")]: {
                width: theme.spacing(props.size.md),
                height: theme.spacing(props.size.md),
            },
            [theme.breakpoints.up("lg")]: {
                width: theme.spacing(props.size.lg),
                height: theme.spacing(props.size.lg),
            },
            [theme.breakpoints.up("xl")]: {
                width: theme.spacing(props.size.xl),
                height: theme.spacing(props.size.xl),
            },
        },

        "&:hover,:active": {
            color: theme.palette[props.color].light,
            "& > svg": {
                transform: "scale(1.05)",
            },
        },

        "&.outlined": {
            border: theme.borders.base(theme.palette[props.color].main),
            "&:hover": {
                background: alpha(theme.palette[props.color].main, 0.075),
            },
        },
        "&.contained": {
            background: theme.palette[props.color].main,
            color: theme.palette[props.color].contrastText,
            "&:hover": {
                background: darken(theme.palette[props.color].main, 0.2),
            },
        },
        "&.fill-width": {
            width: "100%"
        },
        "&.fill-height": {
            height: "100%"
        },
        "&.inline": {
            padding: theme.spacing(1, 2),
            flexDirection: "row",
            "& > svg": {
                marginBottom: 0,
                marginRight: theme.spacing(2),
            },
        }
    }
}))();

const ShortcutButton = (props) => {
    const styleProps = {
        size: props.size || { xs: 2 },
        color: props.color || "primary"
    };
    const variant = props.variant || "text";
    const classes = useStyles(styleProps);
    const className = `${classes.iconButton} ${variant}${props.fillWidth ? " fill-width" : ""}${props.fillHeight ? " fill-height" : ""}${props.inline ? " inline" : ""} ${props.className || ""}`;

    return (
        <ButtonBase className={className} onClick={props.onClick} style={props.style}>
            {props.children}
        </ButtonBase>
    );
};

export default ShortcutButton;
