import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { BottomNavigation, BottomNavigationAction, CircularProgress } from '@material-ui/core'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ReplyOutlinedIcon from '@material-ui/icons/ReplyOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import SearchIcon from '@material-ui/icons/Search'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  bottomNavigationMenu: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,

    '& > *': {
      color: "inherit",
    },
  },
}));

export default function SimpleBottomNavigation(props) {
  const { values, showMobileSummary, view, isLoading, handleSaveForm, setShowMobileSummary, handleBackButton } = props
  const classes = useStyles()
  const { t } = useTranslation()
  let history = useHistory()

  const handleEditInvoice = () => {
    history.push(values.exp ? '/kulut/muokkaa/' + values.id : '/laskut/muokkaa/' + values.id)
  }

  const isLuonnos = values.status === 'LUONNOS' && !showMobileSummary && !view && !isLoading;

  return (
    <BottomNavigation
      showLabels
      className={classes.bottomNavigationMenu}
    >
      {isLuonnos && <BottomNavigationAction label={t('invoice.save')} icon={<SaveOutlinedIcon/>} onClick={() => handleSaveForm(false)}/>}
      {isLuonnos && <BottomNavigationAction label={t('taxcard.send')} icon={<MailOutlineIcon/>} onClick={() => handleSaveForm(true)}/>}
      {isLoading && <CircularProgress size={23} style={{margin:'3% 0% 0% 9%'}}/>}
      {!view &&
        <BottomNavigationAction
          label={showMobileSummary ? t('invoice.edit') : t('invoice.preview')}
          onClick={() => setShowMobileSummary(!showMobileSummary)}
          icon={showMobileSummary ?
            <DescriptionOutlinedIcon/> :
            <SearchIcon/>}
        />}
      {view && values.status === 'LUONNOS' && <BottomNavigationAction label={t('invoice.edit')} icon={<EditOutlinedIcon/>} onClick={handleEditInvoice}/>}
      <BottomNavigationAction label={t('invoice.cancel')} icon={<ReplyOutlinedIcon/>} onClick={handleBackButton}/>
    </BottomNavigation>
  );
}
