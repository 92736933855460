import request from './request'
import { deserializePromoCodes, deserializePromoCode } from '../utils/deserializer'
//import { cLog } from '../utils'

export const getPromoCodes = async (query) => {
  const { data: promoCodes } = await request('GET', '/promo_codes' + (query || ''))
  return deserializePromoCodes(promoCodes)
}

export const getPromoCode = async (id) => {
  const { data: promoCode } = await request('GET', '/promo_codes/' + id)
  return deserializePromoCode(promoCode)
}

export const savePromoCode = async (data) => {
  delete data.updateHistory
  const { data: newCode } = await request('POST', '/promo_codes', data)
  return newCode
}

export const updatePromoCode = async (data) => {
  delete data.updateHistory
  const { data: updatedCode } = await request('PUT', `/promo_codes/${data.id}`, data)
  return updatedCode
}

export const deletePromoCode = async (data) => await request('DELETE', `/promo_codes/${data.id}`)
