import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, useMediaQuery, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { cLog } from '../../utils'
import { Field } from "formik"
import { withSnackbar } from 'notistack'
import { saveDiscountCode, updateDiscountCode, deleteDiscountCode } from '../../service'
import DPicker from '../../components/DPicker'
import { formatDate, formatDatetime, formatTimeFI, notifyOffice } from '../../utils'
import { validateTextArea, validateDate, validateFloatBetween } from '../Invoice/validationSchemas'
import { validateBusinessId } from '../Clients/clientValidations'
import DropDown from '../../components/DropDown'
import { cleanFloat } from '../../utils/invoiceUtils'
import Alert from '../../components/Alert'

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
  },
}))

const DiscountCodeDialog = ({
  resetForm,
  handleChange,
  values,
  setFieldValue,
  errors,
  validateForm,
  enqueueSnackbar,
  open,
  setOpen,
  edit,
  setEdit,
  setValues,
  fetchData
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoading, setLoading] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [openConfirmDeleteDiscountCodeDialog, setOpenConfirmDeleteDiscountCodeDialog] = useState(false)
  //cLog('FORM VALUES', values)

  useEffect(() => {
    if (edit) {
      setValues(edit)
      setShowHistory(false)
    }
  }, [edit, setValues])

  var validate = async () => {
    const erroris = await validateForm()
    cLog('ERRORIS', erroris)
    if (Object.keys(erroris).length === 0) {
      return true
    } else {
      //cLog('ERROREITA PIISAA', erroris)
      notifyErrors(erroris)
      return false
    }
  }

  const handleSubmitForm = async () => {
    let val = validate()
    val.then(result => {
      if (result) {
        saveForm()
      }
    }, error => {
      cLog('SAVE FORM ERROR', error)
    })
  }

  const saveForm = async () => {
    try {
      setLoading(true)
      let sendResult
      if (values && values.id) {
        sendResult = await updateDiscountCode(values)
      } else {
        sendResult = await saveDiscountCode(values)
      }
      cLog('DISCOUNT CODE VASTAUS', sendResult)

      resetForm(sendResult)
      handleClose()
      enqueueSnackbar(
        t('discountCode.codeSent'),
        { variant: 'success' }
      )
      fetchData()
    } catch (error) {
      setLoading(false)
      cLog(error)
      cLog('POST / PUT DISCOUNT CODE ERROR', (error && error.response && error.response.data) ? error.response.data.errors : error)
      enqueueSnackbar(
        t('discountCode.codeSendError'),
        { variant: 'error' }
      )
      if (error && error.response && error.response.data && (error.response.data.errors[0] === 'Business count is not valid' || error.response.data.errors[0] === 'Code count is not valid')) {
        notifyOffice(false, enqueueSnackbar, t('discountCode.codeCountError'), false)
      } else {
        notifyOffice(false, enqueueSnackbar, error, false)
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setLoading(false)
      setOpenConfirmDeleteDiscountCodeDialog(false)
      setOpen(false)
      setEdit(null)
      resetForm()
    }
  }

  const notifyErrors = (erroris) => {
    const errorTypes = Array.from(new Set(
      [].concat(...[].concat(...Object.keys(erroris)
        .map((invoiceType) => erroris[invoiceType])
        .map((fields) => Array.isArray(fields)
          ? fields.map(field => field && Object.keys(field).map(key => field[key].key))
          : fields.key
        )
      ))
    ))
    //cLog('ERRORI TYYPIT', errorTypes)
    errorTypes.reverse()
    errorTypes
      .filter(type => type)
      .forEach(type => {
        //cLog('INVOICE FORM ERROR!!!', type)
        enqueueSnackbar(
          type,
          { variant: 'error' }
        )
      })
    /*if (!errorTypes[0]) {
      enqueueSnackbar(
        t('invoice.deficientRows'),
        { variant: 'error' }
      )
    }*/
  }

  const inviterTypeOptions = ['KAMPANJA', 'YRITYS'].map(option => ({ label: t('discountCode.'+option), value: option }))
  const activatedOptions = [true, false].map(option => ({ label: t('taxcard.'+option), value: option }))

  const validateCampaignFields = () => {
    if (values.inviterType === 'KAMPANJA' && !(cleanFloat(values.commission) > 0 || cleanFloat(values.signUpBonus) > 0 || cleanFloat(values.insurance) > 0 || String(values.companyNotes).trim())) {
      return ({ key: t('discountCode.campaignFieldsMissing') })
    }
  }

  const validateCompanyFields = () => {
    if (values.inviterType === 'YRITYS' && !(cleanFloat(values.commission) > 0 || cleanFloat(values.insurance) > 0 || String(values.companyNotes).trim())) {
      return ({ key: t('discountCode.companyFieldsMissing') })
    }
  }

  const handleAgreeDeleteDiscountCodeDialog = async () => {
    setLoading(true)
    const request = deleteDiscountCode(values.id)
    request.then(response => {
      cLog('DISCOUNT CODE DELETED', response)
      enqueueSnackbar(
        t('discountCode.discountCodeDeleted'),
        { variant: 'success' }
      )
      handleClose()
      fetchData()
    }, error => {
      setLoading(false)
      setOpenConfirmDeleteDiscountCodeDialog(false)
      cLog('DISCOUNT CODE DELETE ERROR', error)
      enqueueSnackbar(
        t('discountCode.discountCodeDeletedError'),
        { variant: 'error' }
      )
    })
  }

  const History = () => {
    let collection = [];
    values.updateHistory.map((row, i) => {
      let name, filtered, joined;
      let changes = [];

      for (const [key, value] of Object.entries(row.changes)) {
        if (key !== 'id') {
          let k = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
          let value0 = value[0]
          let value1 = value[1]

          if (['startDate', 'endDate'].includes(k)) {
            if (value0) {
              value0 = formatTimeFI(value0)
            }
            if (value1) {
              value1 = formatTimeFI(value1)
            }
          }

          if (value[0] || value[1]) {
            if (value0 === 'true' || value0 === true) {
              value0 = t('taxcard.true')
            } else if (value0 === 'false'|| value0 === false) {
              value0 = t('taxcard.false')
            } else if (!value0) {
              value0 = t('user.noValue')
            }

            if (value1 === 'true' || value1 === true) {
              value1 = t('taxcard.true')
            } else if (value1 === 'false' || value1 === false) {
              value1 = t('taxcard.false')
            } else if (!value1) {
              value1 = t('user.noValue')
            }

            if (row.event === 'create') {
              changes.push(t('discountCode.'+k) + ': ' + value1)
            } else if (row.event === 'destroy') {
              changes.push(t('discountCode.'+k) + ': ' + value0)
            } else {
              changes.push(t('discountCode.'+k) + ': ' + value0 + ' -> ' + value1)
            }
          }
        }
      }

      if (changes.length) {
        if (row.firstName && row.lastName) {
          name = row.firstName + ' ' + row.lastName;
        } else if (row.firstName && !row.lastName) {
          name = row.firstName;
        } else if (!row.firstName && row.lastName) {
          name = row.lastName
        } else {
          name = 'Odealin järjestelmä'
        }

        filtered = changes.filter(function (f) {
          return f != null;
        });
        joined = filtered.join(', ');

        let rowText = ''
        if (row.event === 'create') {
          rowText = t('discountCode.discountCode') + ' id ' + row.itemId + ' ' + t('invoice.created') + ': '
        }

        collection.push(<TableRow key={i}>
          <TableCell>{formatDatetime(row.updatedAt)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{rowText + joined}</TableCell>
        </TableRow>)
      }

      return null
    })

    return (<div style={{ paddingBottom: '20px' }}>
      <br /><br />
      {t('discountCode.historyTitle')}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('customers.date')}</TableCell>
            <TableCell>{t('customers.user')}</TableCell>
            <TableCell>{t('customers.events')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collection}
        </TableBody>
      </Table>
    </div>
    )
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby="discount-code-dialog-title"
      aria-describedby="discount-code-dialog-description"
    >
      <DialogTitle id="discount-code-dialog-title">{edit ? t('discountCode.editTitle') : t('discountCode.addNew')}</DialogTitle>
      <DialogContent dividers={true} id="discount-code-dialog-description">
        <form>
          <Grid container spacing={4} className={classes.gridContainer}>
            <Grid item xs={12} sm={6}>
              <Field
                name="inviterType"
                id='inviterType'
                label={t('discountCode.inviterType')}
                value={values.inviterType}
                onChange={(e) => {
                  setFieldValue('inviterType', e.value)
                }}
                options={inviterTypeOptions}
                isSearchable={!isMobile}
                component={DropDown}
              />
            </Grid>
            {values.inviterType !== 'YRITYS' && <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.code')+' *'}
                id="code"
                name="code"
                placeholder={t('discountCode.code')}
                component={Input}
                onChange={handleChange}
                value={values.code}
                validate={() => validateTextArea(values.code, t('discountCode.code'), 30, true, 3)}
                error={errors && errors.code && validateTextArea(values.code, t('discountCode.code'), 30, true, 3)}
              />
            </Grid>}
            <Grid item xs={12} sm={6}>
              <Field
                name="startDate"
                id="startDate"
                label={t('discountCode.startDate')}
                component={DPicker}
                selected={values.startDate ? new Date(values.startDate) : ''}
                isClearable
                onChange={(date) => setFieldValue('startDate', date ? formatDate(date) : '')}
                validate={() => validateDate(values.startDate, t('discountCode.startDate'), false, !values.id ? new Date() : null, true)}
                error={errors && errors.startDate && validateDate(values.startDate, t('discountCode.startDate'), false, !values.id ? new Date() : null, true)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="endDate"
                id="endDate"
                label={t('discountCode.endDate')}
                component={DPicker}
                selected={values.endDate ? new Date(values.endDate) : ''}
                onChange={(date) => setFieldValue('endDate', date ? formatDate(date) : '')}
                isClearable
                validate={() => validateDate(values.endDate, t('discountCode.endDate'), false, !values.id ? (values.startDate ? new Date(Math.max.apply(null, [new Date().withoutTime(), new Date(values.startDate).withoutTime()])).withoutTime() : new Date()) : values.startDate, true)}
                error={errors && errors.endDate && validateDate(values.endDate, t('discountCode.endDate'), false, !values.id ? (values.startDate ? new Date(Math.max.apply(null, [new Date().withoutTime(), new Date(values.startDate).withoutTime()])).withoutTime() : new Date()) : values.startDate, true)}
              />
            </Grid>
            {values.inviterType === 'YRITYS' && <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.businessId')+' *'}
                id="businessId"
                name="businessId"
                placeholder={t('discountCode.businessId')}
                component={Input}
                onChange={handleChange}
                value={values.businessId}
                validate={() => validateBusinessId(values.businessId, true)}
                error={errors && errors.businessId && validateBusinessId(values.businessId, true)}
              />
            </Grid>}
            {values.inviterType === 'YRITYS' && <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.companyName')+' *'}
                id="companyName"
                name="companyName"
                placeholder={t('discountCode.companyName')}
                component={Input}
                onChange={handleChange}
                value={values.companyName}
                validate={() => validateTextArea(values.companyName, t('discountCode.companyName'), 80, true)}
                error={errors && errors.companyName && validateTextArea(values.companyName, t('discountCode.companyName'), 80, true)}
              />
            </Grid>}
            <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.companyNotes')}
                id="companyNotes"
                name="companyNotes"
                placeholder={t('discountCode.companyNotes')}
                component={Input}
                onChange={handleChange}
                value={values.companyNotes}
                validate={() => validateTextArea(values.companyNotes, t('discountCode.companyNotes'), 200, false) || validateCampaignFields() || validateCompanyFields()}
                error={errors && errors.companyNotes && (validateTextArea(values.companyNotes, t('discountCode.companyNotes'), 200, false) || validateCampaignFields() || validateCompanyFields())}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.commission')}
                id="commission"
                name="commission"
                placeholder={t('discountCode.commission')}
                component={Input}
                onChange={handleChange}
                value={values.commission}
                validate={() => validateFloatBetween(values.commission, 0, 9.99, t('discountCode.commission'), false, 2) || validateCampaignFields() || validateCompanyFields()}
                error={errors && errors.commission && (validateFloatBetween(values.commission, 0, 9.99, t('discountCode.commission'), false, 2) || validateCampaignFields() || validateCompanyFields())}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.insurance')}
                id="insurance"
                name="insurance"
                placeholder={t('discountCode.insurance')}
                component={Input}
                onChange={handleChange}
                value={values.insurance}
                validate={() => validateFloatBetween(values.insurance, 0, 9.99, t('discountCode.insurance'), false, 2) || validateCampaignFields() || validateCompanyFields()}
                error={errors && errors.insurance && (validateFloatBetween(values.insurance, 0, 9.99, t('discountCode.insurance'), false, 2) || validateCampaignFields() || validateCompanyFields())}
              />
            </Grid>
            {values.inviterType !== 'YRITYS' && <Grid item xs={12} sm={6}>
              <Field
                label={t('discountCode.signUpBonus')}
                id="signUpBonus"
                name="signUpBonus"
                placeholder={t('discountCode.signUpBonus')}
                component={Input}
                onChange={handleChange}
                value={values.signUpBonus}
                validate={() => validateFloatBetween(values.signUpBonus, 0, 999.99, t('discountCode.signUpBonus'), false, 2) || validateCampaignFields()}
                error={errors && errors.signUpBonus && (validateFloatBetween(values.signUpBonus, 0, 999.99, t('discountCode.signUpBonus'), false, 2) || validateCampaignFields())}
              />
            </Grid>}
            <Grid item xs={12} sm={6}>
              <Field
                name="activated"
                id='activated'
                label={t('discountCode.activated')}
                value={values.activated}
                onChange={(e) => {
                  setFieldValue('activated', e.value)
                }}
                options={activatedOptions}
                isSearchable={!isMobile}
                component={DropDown}
                info={values.inviterType === 'YRITYS' ? t('discountCode.activatedCompanyTip') : t('discountCode.activatedTip')}
              />
            </Grid>
          </Grid>
        </form>
        {showHistory && edit && <Grid container spacing={0}>
          <Grid item xs={12}>
            <History />
          </Grid>
        </Grid>}
      </DialogContent>
      <DialogActions>
        {edit && <Button
          variant="text"
          onClick={() => setShowHistory(!showHistory)}
          color="secondary"
          disabled={isLoading}
        >
          {t('discountCode.history')}
        </Button>}
        {edit && <Button
          variant="text"
          onClick={() => { setOpenConfirmDeleteDiscountCodeDialog(true) }}
          color="error"
          disabled={isLoading}
        >
          {t('customers.remove')}
        </Button>}
        <Button
          variant="text"
          onClick={handleClose}
          color="secondary"
          disabled={isLoading}
        >
          {t('promocodes.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleSubmitForm}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('promocodes.send')}
        </Button>
      </DialogActions>
      <Alert
        open={openConfirmDeleteDiscountCodeDialog}
        setOpen={setOpenConfirmDeleteDiscountCodeDialog}
        handleAgree={handleAgreeDeleteDiscountCodeDialog}
        handleClose={() => { setOpenConfirmDeleteDiscountCodeDialog(false) }}
        alertMessage={{ title: t('discountCode.discountCodeDeleteWarningTitle'), body: t('user.userNoteDeleteWarningBody') }}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
        isLoading={isLoading}
      />
    </Dialog>
  )
}

export default withSnackbar(DiscountCodeDialog)
