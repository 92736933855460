import React from 'react'
import { makeStyles } from '@material-ui/core/styles' 
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  }
}))

export default function PaginationControlled({ pagination, setPagination, getData }) {
  const classes = useStyles() 
  const handleChange = (event, value) => {
    setPagination({ ...pagination, page: value })
    getData()
  }

  //cLog('PAGINATION BIG PAGE', page)

  return (
    <div className={classes.root}> 
      <Pagination count={pagination.totalPages} page={pagination.page} onChange={handleChange} variant="outlined" shape="rounded"/>
    </div>
  )
}
