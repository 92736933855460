import { getDateISOString, formatTimeFI } from '../../utils'
import { cleanFloat } from '../../utils/invoiceUtils'
//import { cLog } from '../../utils'
import { checkClient } from '../Clients/clientValidations'
import { t } from "../../i18n"

Date.prototype.withoutTime = function () {
  var d = new Date(this);
  d.setHours(0, 0, 0, 0);
  return d;
}

export const validateTextArea = (value, field, max, required, min = null) => {
  if (required && (!value || String(value).length === 0)) {
    return { key: t('invoice.missing', { field: field }) }
  }
  if (value && String(value).trim().length > max) {
    return { key: t('invoice.tooLong', { field: field, max: max }) }
  }
  if (value && min && String(value).trim().length < min) {
    return { key: t('invoice.tooShort', { field: field, min: min }) }
  }
}

export const validateDate = (value, field, required, minDate = null, allowFuture = false) => {
  if (required && (!value || String(value).length === 0)) {
    return { key: t('invoice.missing', { field: field }) }
  }
  if (value && !allowFuture && new Date(value).withoutTime() > new Date().withoutTime()) {
    return { key: t('invoice.dateInFuture', { field: field }) }
  }
  if (value && minDate && new Date(minDate).withoutTime() > new Date(value).withoutTime()) {
    return { key: t('invoice.minDateAfterDate', { field: field, minDate: formatTimeFI(minDate) }) }
  }
}

export const validateReturnDate = (value, departureDate) => {
  if (!value || String(value).length === 0) {
    return { key: t('invoice.missing', { field: t('invoice.returnDate') }) }
  }
  if (new Date(value).withoutTime() > new Date().withoutTime()) {
    return { key: t('invoice.dateInFuture', { field: t('invoice.returnDate') }) }
  }
  if (new Date(value).withoutTime() < new Date(departureDate).withoutTime()) {
    return { key: t('invoice.returnDateBeforeDeparture') }
  }
  if (new Date(value).getFullYear() !== new Date(departureDate).getFullYear()) {
    return { key: t('invoice.differentYears') }
  }
}

export const validateDepartureTime = (row) => {
  if (!row.departureTime || String(row.departureTime).length === 0) {
    return { key: t('invoice.missing', { field: t('invoice.departureTime') }) }
  }
}

export const validateReturnTime = (row) => {
  if (!row.returnTime || String(row.returnTime).length === 0) {
    return { key: t('invoice.missing', { field: t('invoice.returnTime') }) }
  }
  let dep = new Date(row.departureDate).setHours(row.departureTime.split(':')[0],row.departureTime.split(':')[1],0)
  let ret = new Date(row.returnDate).setHours(row.returnTime.split(':')[0],row.returnTime.split(':')[1],0)
  if (row.returnDate === row.departureDate && ret <= dep) {
    return { key: t('invoice.returnTimeBeforeDeparture') }
  }
}

export const validateFloat = (value, field, allowZero, decimals = 2) => {
  if (!String(value).trim()) {
    return { key: t('invoice.missing', { field: field }) }
  }
  const val = String(value).replace(',','.')
  const numberRegEx = new RegExp('^\\-?\\d*\\.?\\d{1,' + decimals + '}$')
  if (!numberRegEx.test(val) || val === '-') {
    return { key: t('invoice.notNumber', { field: field, decimals: decimals }) }
  }
  const float = parseFloat(val)
  if (float < -9999999.99) {
    return { key: t('invoice.tooLow', { field: field, min: -9999999.99 }) }
  }
  if (!allowZero && float === 0) {
    return { key: t('invoice.isZero', { field: field }) }
  }
  if (float > 9999999.99) {
    return { key: t('invoice.tooBig', { field: field, max: 9999999.99 }) }
  }
}

export const validateFloatGreaterThan = (value, min, max, field, decimals) => {
  if (!String(value).trim()) {
    return { key: t('invoice.missing', { field: field }) }
  }
  const val = String(value).replace(',','.')
  const numberRegEx = new RegExp('^\\-?\\d*\\.?\\d{1,' + decimals + '}$')
  if (!numberRegEx.test(val) || val === '-') {
    return { key: t('invoice.notNumber', { field: field, decimals: decimals }) }
  }
  const float = parseFloat(val)
  if (float <= min) {
    return { key: t('invoice.tooLowGreaterThan', { field: field, min: min }) }
  }
  if (float > max) {
    return { key: t('invoice.tooBig', { field: field, max: max }) }
  }
}

export const validateFloatBetween = (value, min, max, field, required, decimals) => {
  if (!required && !value) {
    return
  }
  if (required && !value) {
    return { key: t('invoice.missing', { field: field }) }
  }
  const val = String(value).replace(',','.')
  if (val) {
    if (decimals === 0) {
      const numberRegEx = new RegExp('^-?\\d*(\\.[0,5])?$')
      if (!numberRegEx.test(val) || val === '-') {
        return { key: t('invoice.notNumberPrecision', { field: field, precision: 0.5 }) }
      }
    } else {
      const numberRegEx = new RegExp('^\\-?\\d*\\.?\\d{1,' + decimals + '}$')
      if (!numberRegEx.test(val) || val === '-') {
        return { key: t('invoice.notNumber', { field: field, decimals: decimals }) }
      }
    }
  }
  const float = parseFloat(val)
  if (float < min) {
    return { key: t('invoice.tooLowEqualGreaterThan', { field: field, min: min }) }
  }
   if (float > max) {
    return { key: t('invoice.tooBigEqualSmallerThan', { field: field, max: max }) }
  }
}

export const validateIntBetween = (value, min, max, field, required) => {
  if (required && !value) {
    return { key: t('invoice.missing', { field: field }) }
  }
  const val = String(value)
  const numberRegEx = new RegExp('^-?\\d*$')
  if (!numberRegEx.test(val) || val === '-') {
    return { key: t('invoice.notNumberInt', { field: field }) }
  }
  const int = parseInt(val)
  if (int < min) {
    return { key: t('invoice.tooLowEqualGreaterThan', { field: field, min: min }) }
  }
   if (int > max) {
    return { key: t('invoice.tooBigEqualSmallerThan', { field: field, max: max }) }
  }
}

export const validateAcctSvcrRef = (value, noAcctSvcrRef) => {
  if (!String(value).trim() && !noAcctSvcrRef) {
    return { key: t('invoice.acctSvcrRefRequired') }
  }
  if (String(value).trim() && noAcctSvcrRef) {
    return { key: t('invoice.acctSvcrRefRequired2') }
  }
  if (value && String(value).trim().length > 100) {
    return { key: t('invoice.acctSvcrRefTooLong') }
  }
}

export const validateCustomIndustry = (values) => {
  if (values.industry === 'Muu') {
    if (!values.customIndustry) {
      return { key: t('invoice.missing', { field: t('invoice.customIndustry') }) }
    }
    if (values.customIndustry.length > 20) {
      return { key: t('invoice.tooLong', { field: t('invoice.customIndustry'), max: 20 }) }
    }
  }
}

export const checkInvoiceBodyErrors = (values, exp, isAdmin) => {
  if (exp || values.exp) {
    if (isAdmin && (
      !values.userId
      || validateInvoiceDate(values, exp, isAdmin)
    )) {
      return false
    }
  } else if (
    (isAdmin && !values.userId)
    || !values.client
    || !checkClient(values.client, isAdmin)
    || validateReminderHandling(values.reminderHandling, values.payDay)
    || validateInvoiceDate(values, exp, isAdmin)
    || validateDateDue(values)
    || validateDateStartWork(values)
    || validateDateEndWork(values)
    || validateTextArea(values.refText, t('invoice.refText'), 70, false)
    || (isAdmin && values.currency !== 'EUR' && validateFloatGreaterThan(values.exchangeRate, 0, 999999.9999999, t('invoice.exchangeRate'), 7))
    || !values.industry
    || validateCustomIndustry(values)
    || validateTextArea(values.marketingName, t('invoice.marketingName'), 70, false)
    || validateTextArea(values.additionalInformation, t('invoice.workList'), 10000, false)
    || (isAdmin && validateTextArea(values.internalNotes, t('invoice.internalNotes'), 10000, false))
    || (isAdmin && validateTextArea(values.adminInfo, t('invoice.adminInfo'), 10000, false))
    || (values.yel === undefined || values.yel === null)
    || validateTextArea(values.info, t('invoice.info'), 10000, false)
    || (isAdmin && validateDate(values.reminderDate, t('invoice.reminderDate'), values.reminderNotes, null, true))
    || (isAdmin && validateTextArea(values.reminderNotes, t('invoice.reminderNotes'), 10000, values.reminderDate))
    ) {
    return false
  }
  return true
}

export const checkInvoiceRowErrors = (row, isAdmin) => {
  if (validateTextArea(row.content, t('invoice.content'), 460, true)
    || (!isAdmin ? validateFloatGreaterThan(row.quantity, 0, 9999999.99, t('invoice.quantity'), 2) : validateFloat(row.quantity, t('invoice.quantity'), false))
    || (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false))
    || (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false))
  ) {
    return false
  }
  return true
}

export const validateDestinations = (row, isAdmin, allowNegative) => {
  if (!row.travelRowDestinations || row.travelRowDestinations.length < 2) {
    return { key: t('invoice.missing', { field: t('invoice.destination') }) }
  } else {
    let arr = []
    for (let i = 0; i < row.travelRowDestinations.length; i++) {
      if (!row.travelRowDestinations[i].verified) {
        return { key: t('invoice.missing', { field: t('invoice.destination') }) }
      }
      let result = validateTextArea(row.travelRowDestinations[i].destination, t('invoice.destination'), 5000, true)
      if (result && !arr.includes(result)) {
        arr.push(result)
      }
      if (i === 0) {
        result = validateIntBetween(row.travelRowDestinations[i].km, 0, 0, t('invoice.km'), false)
        if (result && !arr.includes(result)) {
          arr.push(result)
        }
      } else {
        result = validateFloatBetween(row.travelRowDestinations[i].km, (allowNegative ? -9999 : 0), 9999, t('invoice.km'), false, 1)
        if (result && !arr.includes(result)) {
          arr.push(result)
        }
      }
    }
    
    if (arr.length > 0) {
      return arr
    }
  }
}

export const checkTravelRowErrors = (row, isAdmin, allowNegative, oldInvoiceTravelType1, oldInvoiceTravelType2, oldInvoiceGoogleMaps) => {
  if (validateTravelType(oldInvoiceTravelType1, row.travelType)
    || validateSpecialIndustry1(row.travelType, row.specialIndustryOvernight)
    || validateSpecialIndustry2(row.travelType, row.specialIndustryPrimaryPlace)
    || validateSpecialIndustry3(row.travelType, row.specialIndustryDining, oldInvoiceTravelType2)
    || validateMobileWork(row.travelType, row.mobileWorkPrimaryPlace)
    || validateDate(row.departureDate, t('invoice.departureDate'), true, '2012-01-01')
    || validateReturnDate(row.returnDate, row.departureDate)
    || validateDepartureTime(row)
    || validateReturnTime(row)
    || (oldInvoiceTravelType2 && validateTextArea(row.purposeNotes, t('invoice.purposeNotes'), 5000, row.purpose === 'OTHER'))
    || (oldInvoiceGoogleMaps && (!isAdmin ? validateIntBetween(row.km, 0, 9999, t('invoice.km')) : validateFloatBetween(row.km, (allowNegative ? -9999 : 0), 9999, t('invoice.km'), false, 0)))
    || (oldInvoiceGoogleMaps && validateTextArea(row.route, t('invoice.route'), 5000, true))
    || (!oldInvoiceGoogleMaps && validateDestinations(row, isAdmin, allowNegative))
    || validateTextArea(row.registerNumber, t('invoice.registerNumber'), 10, false)
    || (!isAdmin || row.country === 'FINLAND' ? validateIntBetween(row.dailyAllowances, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.dailyAllowances')) : validateFloatBetween(row.dailyAllowances, (allowNegative ? -99 : 0), 99, t('invoice.dailyAllowances'), false, 0))
    || validateIntBetween(row.partTimeDailyAllowances, (allowNegative ? -99 : 0), 99, t('invoice.partTimeDailyAllowances'))
    || validateIntBetween(row.mealExpences, (allowNegative ? -99 : 0), 99, t('invoice.mealExpences'))
    || checkTravelRowErrors2(row)
    || (isAdmin && validateFloatGreaterThan(row.daAmount, 0, 999.99, t('invoice.daAmount'), 2))
    || (isAdmin && validateFloatGreaterThan(row.ptdaAmount, 0, 999.99, t('invoice.ptdaAmount'), 2))
    || (isAdmin && validateFloatGreaterThan(row.meAmount, 0, 999.99, t('invoice.meAmount'), 2))
  ) {
    return false
  }
  return true
}

export const checkTravelRowErrors2 = (row) => {
  if ((!row.km || String(row.km) === '0') && (!row.dailyAllowances || String(row.dailyAllowances) === '0') && (!row.partTimeDailyAllowances || String(row.partTimeDailyAllowances) === '0') && (!row.mealExpences || String(row.mealExpences) === '0')) {
    return { key: t('invoice.travelRowNoValue') }
  }
}

export const checkMaterialRowErrors = (row, isAdmin, oldInvoiceTravelType1) => {
  if (validateTextArea(row.explanation, t('invoice.explanation'), 460, true)
    || (oldInvoiceTravelType1 && (!isAdmin ? validateFloatGreaterThan(row.sum, 0, 9999999.99, t('invoice.sumWithVat'), 2) : validateFloat(row.sum, t('invoice.sumWithVat'), false)))
    || (!oldInvoiceTravelType1 && (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false)))
    || (!oldInvoiceTravelType1 && (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false)))
    || validateTextArea(row.buyLocation, t('invoice.buyLocation'), 50, false)
    || validateDate(row.buyDate, t('invoice.buyDate'), false)
  ) {
    return false
  }
  return true
}

export const checkMaterialRowSendErrors = (row, isAdmin, oldInvoiceTravelType1) => {
  if (validateTextArea(row.explanation, t('invoice.explanation'), 460, true)
    || (oldInvoiceTravelType1 && (!isAdmin ? validateFloatGreaterThan(row.sum, 0, 9999999.99, t('invoice.sumWithVat'), 2) : validateFloat(row.sum, t('invoice.sumWithVat'), false)))
    || (!oldInvoiceTravelType1 && (!isAdmin ? validateFloatGreaterThan(row.price, 0, 9999999.99, t('invoice.price'), 2) : validateFloat(row.price, t('invoice.price'), false)))
    || (!oldInvoiceTravelType1 && (!isAdmin ? validateFloatGreaterThan(row.priceWithTax, 0, 9999999.99, t('invoice.priceWithTax'), 2) : validateFloat(row.priceWithTax, t('invoice.priceWithTax'), false)))
    || validateTextArea(row.buyLocation, t('invoice.buyLocation'), 50, false)
    || validateDate(row.buyDate, t('invoice.buyDate'), false)
    || !row.account
    || !row.vat
  ) {
    return false
  }
  return true
}

export const checkCorrectionRowErrors = (row) => {
  if (validateTextArea(row.notes, t('invoice.notes'), 10000, true)
    || validateDate(row.date, t('invoice.date'), true)
    || validateFloat(row.quantity, t('invoice.quantity'), true, 2)
    || validateFloat(row.amount, t('invoice.sum'), true)
    || validateFloat(row.amountWithTax, t('invoice.amountWithTax'), true)
  ) {
    return false
  }
  return true
}

export const checkAccountRowErrors = (row, exp) => {
  if (validateDate(row.date, t('invoice.date'), true)
    || validateFloatGreaterThan(row.amount, 0, 9999999.99, t('invoice.sum'), 2)
    || (exp && !row.accountType)
    || validateTextArea(row.notes, t('invoice.notes'), 10000, false)
  ) {
    return false
  }
  return true
}

export const checkPaymentRowErrors = (row) => {
  if (validateTextArea(row.notes, t('invoice.notes'), 10000, false)
    || validateFloat(row.txAmt, t('invoice.sum'), false)
    || validateDate(row.valDt, t('invoice.valDt'), true)
    /*|| validateAcctSvcrRef(row.acctSvcrRef, row.noAcctSvcrRef)*/
  ) {
    return false
  }
  return true
}

export const addDays = (date, days) => {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}

export const validateInvoiceDate = (values, exp, isAdmin) => {
  if (exp && !isAdmin) {
    return
  }
  if (!values.date || String(values.date).length === 0) {
    return { key: t('invoice.missing', { field: t('invoice.date') }) }
  }
  if (exp && (new Date(values.date).withoutTime() > new Date().withoutTime())) {
    return { key: t('invoice.dateInFuture', { field: t('invoice.date') }) }
  }
  if (['LUONNOS', 'TARKASTETTAVANA'].includes(values.status)) {
    const today = new Date()
    if (isAdmin && new Date(values.date) > today) {
      return { key: t('invoice.latestAllowedDate', { date: formatTimeFI(today) }) }
    } else if (today.getDate() > 10) {
      const beginOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      if (new Date(values.date).withoutTime() < beginOfMonth.withoutTime()) {
        return { key: t('invoice.earliestAllowedDate', { date: formatTimeFI(beginOfMonth) }) }
      }
    } else {
      let beginOfPreviousMonth = new Date()
      if (today.getMonth() === 0) {
        beginOfPreviousMonth = new Date(today.getFullYear() - 1, 11, 1)
      } else {
        beginOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      }

      if (new Date(values.date) < beginOfPreviousMonth) {
        return { key: t('invoice.earliestAllowedDate', { date: formatTimeFI(beginOfPreviousMonth) }) }
      }
    }
  }
}

export const earliestDateDue = values => {
  const { dateDue } = values
  const dateWeekDay = new Date().getDay()
  //cLog('DATE WEEK DAY', dateWeekDay) 
  let delay = 2
  if (dateWeekDay > 3 && dateWeekDay < 7) {
    delay = 4
  }
  //cLog('DELAY', delay)
  const earliest = addDays(new Date(), delay)
  //cLog('EARLIEST', earliest) 
  const earliestDate = getDateISOString(earliest)
  //cLog('PÄIVÄYS', values.date, 'AIKAISIN ERÄPÄIVÄ', earliestDate)
  //cLog('DATEDUE', dateDue)
  return new Date(dateDue).withoutTime() >= new Date(earliestDate).withoutTime()
}

export const validateDateDue = (values) => {
  if (!values.dateDue || String(values.dateDue).length === 0) {
    return { key: t('invoice.missing', { field: t('invoice.dateDue') }) }
  }
  if (values.date) {
    if (new Date(values.date).withoutTime() > new Date(values.dateDue).withoutTime()) {
      return ({ key: t('invoice.dateAfterDateDue') })
    }
    if (!values.creditNote && ['LUONNOS', 'TARKASTETTAVANA'].includes(values.status) && !earliestDateDue(values)) {
      return ({ key: t('invoice.invalidDateDue') })
    }
  }
}

export const validateDateStartWork = values => {
  if (!values.dateStartWork || String(values.dateStartWork).length === 0) {
    return ({ key: t('invoice.dateStartWorkMissing') })
  }
  if (values.dateStartWork && values.dateEndWork && (new Date(values.dateStartWork).withoutTime() > new Date(values.dateEndWork).withoutTime())) {
    return ({ key: t('invoice.dateStartWorkAfterEnd') })
  }
  /* if (new Date(values.dateStartWork) > new Date(getDateNow())) {
    return ({ key: 'Työn aloituspäivä on tulevaisuudessa' })
  } */
}

export const validateDateEndWork = (values) => {
  if (!values.dateEndWork || String(values.dateEndWork).length === 0) {
    return ({ key: t('invoice.dateEndWorkMissing') })
  }
  // if (new Date(values.dateEndWork) < new Date(values.dateStartWork)) {
  //return ({ key: 'Työn loppumispäivä on ennen alkamispäivää' })
  //}  
  /* if (new Date(values.dateEndWork) > new Date(getDateNow())) {
    return ({ key: 'Työn loppumispäivä on tulevaisuudessa' })
  } */
}

export const validateTravelType = (oldInvoiceTravelType1, value) => {
  if (!oldInvoiceTravelType1 && !value) {
    return { key: t('invoice.noTravelTypeSelected') }
  }
}

export const validateSpecialIndustry1 = (travelType, value) => {
  if (travelType === 'ERITYISALA' && !value) {
    return { key: t('invoice.noSpecialIndustryOvernightSelected') }
  }
}

export const validateSpecialIndustry2 = (travelType, value) => {
  if (travelType === 'ERITYISALA' && !value) {
    return { key: t('invoice.noSecialIndustryPrimaryPlaceSelected') }
  }
}

export const validateSpecialIndustry3 = (travelType, value, oldInvoiceTravelType2) => {
  if (!oldInvoiceTravelType2 && travelType === 'ERITYISALA' && !value) {
    return { key: t('invoice.noSecialIndustryDiningSelected') }
  }
}

export const validateMobileWork = (travelType, value) => {
  if (travelType === 'LIIKKUVA_TYO' && !value) {
    return { key: t('invoice.noMobileWorkPrimaryPlaceSelected') }
  }
}

export const validateReminderHandling = (reminderHandling, payDay) => {
  if (String(reminderHandling).trim().length === 0 || (reminderHandling === 'EI_MUISTUTETA' && payDay === 'HETI')) {
    return { key: t('invoice.reminderHandlingError') }
  }
}

export const validateAll = (values, exp, isAdmin, oldStatus, oldInvoiceTravelType1, oldInvoiceTravelType2, validateSendExpense, oldInvoiceGoogleMaps) => {
  if (!checkInvoiceBodyErrors(values, exp, isAdmin)) {
    return false
  }

  if (!exp) {
    if (values.invoiceRows) {
      for (let i = 0; i < values.invoiceRows.length; i++) {
        if (!checkInvoiceRowErrors(values.invoiceRows[i], isAdmin)) {
          return false
        }
      }
    } else {
      return false
    }
  }

  if (values.travellingExpenceRows) {
    for (let i = 0; i < values.travellingExpenceRows.length; i++) {
      if (!checkTravelRowErrors(values.travellingExpenceRows[i], isAdmin, values.creditNote || (values.creditNoteInvoicesData && values.creditNoteInvoicesData.length > 0) || values.status === 'LUOTTOTAPPIO', oldInvoiceTravelType1, oldInvoiceTravelType2, oldInvoiceGoogleMaps)) {
        return false
      }
    }
  }

  if (values.materialExpenceRows) {
    for (let i = 0; i < values.materialExpenceRows.length; i++) {
      if (!checkMaterialRowErrors(values.materialExpenceRows[i], isAdmin, oldInvoiceTravelType1)) {
        return false
      }
    }
  }

  if (isAdmin) {
    if (values.materialExpenceRows && exp && validateSendExpense) {
      for (let i = 0; i < values.materialExpenceRows.length; i++) {
        if (!checkMaterialRowSendErrors(values.materialExpenceRows[i], isAdmin, oldInvoiceTravelType1)) {
          return false
        }
      }
    }

    if (values.correctionRows) {
      for (let i = 0; i < values.correctionRows.length; i++) {
        if (!checkCorrectionRowErrors(values.correctionRows[i])) {
          return false
        }
      }
    }

    if (values.accounts) {
      for (let i = 0; i < values.accounts.length; i++) {
        if (!checkAccountRowErrors(values.accounts[i], exp)) {
          return false
        }
      }
    }

    if (values.payments) {
      for (let i = 0; i < values.payments.length; i++) {
        if (!checkPaymentRowErrors(values.payments[i])) {
          return false
        }
      }
    }
  }

  return true
}

export const validateAll2 = (values, exp, isAdmin, worklist, hasNonZeroVats, validateSendExpense) => {
  let generalErrorMessages = []

  if (!exp) {
    if (values.invoiceRows.length === 0) {
      generalErrorMessages.push(t('invoice.invoiceNoRows'))
    } else if (!isAdmin && cleanFloat(values.invoiceTotal) <= 0) {
      generalErrorMessages.push(t('invoice.invoiceNegativeSum'))
    } else if (cleanFloat(values.totalWithTax) > 999999999.9999) {
      generalErrorMessages.push(t('invoice.invoiceTooBig'))
    } else if (!checkClient(values.client, isAdmin)) {
      generalErrorMessages.push(t('invoice.customerInfoMissing'))
    } else if ((worklist || values.worklistId) && values.invoiceRows.length > 0 && !values.invoiceRows[0].total) {
      generalErrorMessages.push(t('invoice.missingPrice'))
    } else if (isAdmin && values.vatStatus !== 'KOTIMAA' && hasNonZeroVats) {
      generalErrorMessages.push(t('invoice.invoiceVatError'))
    } else if (isAdmin && values.vatStatus === 'KOTIMAA' && cleanFloat(values.tax) === 0) {
      generalErrorMessages.push(t('invoice.invoiceVatError2'))
    } else if (isAdmin && values.accounts && values.accounts.length > 0 && cleanFloat(values.salariesPayableAmount) < 0) {
      generalErrorMessages.push(t('invoice.accountSumTooBig'))
    }
  } else {
    if (cleanFloat(values.travelTotal) + cleanFloat(values.expenceTotal) === 0) {
      generalErrorMessages.push(t('invoice.expenseNoValueError'))
    }
    if (validateSendExpense && values.materialExpenceRows && values.materialExpenceRows.length > 0) {
      const zeroAccountRows = values.materialExpenceRows.find(row => row.account === null)
      if (zeroAccountRows) {
        generalErrorMessages.push(t('invoice.expenseRowNoValueError'))
      }
      const zeroVatRows = values.materialExpenceRows.find(row => row.vat === null)
      if (zeroVatRows) {
        generalErrorMessages.push(t('invoice.expenseRowNoValueError'))
      }
    }
  }
  
  if (values.travellingExpenceRows && values.travellingExpenceRows.length > 0) {
    const zeroTravelRows = values.travellingExpenceRows.find(row => cleanFloat(row.total) === 0 || row.total === null || row.total === undefined)
    if (zeroTravelRows) {
      generalErrorMessages.push(t('invoice.travelingExpenseNoValueError'))
    }
  }

  return generalErrorMessages
}

export const validateUrl = (value, field, min, max, required) => {
  if (required && !String(value).trim()) {
    return { key: t('invoice.missing', { field: field }) }
  }
  if (value && String(value).trim().length < min) {
    return { key: t('invoice.tooShort', { field: field, min: min }) }
  }
  if (value && String(value).trim().length > max) {
    return { key: t('invoice.tooLong', { field: field, max: max }) }
  }
  if (value && !(value.startsWith("http://") || value.startsWith("https://"))) {
    return { key: t('invoice.urlWrongFormat', { field: field }) }
  }
}
