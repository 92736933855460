import React, { createContext, useContext, useReducer } from "react"

const initialState = {
  clients: [],
  discountCodes: [],
  invoices: [],
  expences: [],
  promoCodes: [],
  worklists: [],
  taxcards: [],
  userNotes: [],
  usersIndex: [],
  salaries: [],
  currentUser: null,
  announcements: [],
  messages: [],
  references: [],
  valtticards: [],
  selected1: [],
  selected2: [],
  selected3: [],
  selected4: [],
  selected5: [],
  sortKeyAnnouncements: 'updated_at',
  sortKeyClients: 'updated_at',
  sortKeyDiscountCodes: 'updated_at',
  sortKeyInvoices: 'updated_at',
  sortKeyPromoCodes: 'updated_at',
  sortKeySalaries: 'updated_at',
  sortKeyTaxcards: 'updated_at',
  sortKeyUserNotes: 'updated_at',
  sortKeyUsers: 'updated_at',
  sortKeyUserView1: 'created_at',
  sortKeyUserView2: 'invoice_nr',
  sortKeyUserView3: 'invoice_nr',
  sortKeyUserView4: 'expence_nr',
  sortKeyUserView5: 'created_at',
  sortKeyUserView6: 'created_at',
  sortKeyUserView7: 'updated_at',
  sortKeyWorklists: 'updated_at',
  sortKeyReferences: 'updated_at',
  sortKeyValtticards: 'updated_at',
  sortOrderAnnouncements: 'DESC',
  sortOrderClients: 'DESC',
  sortOrderDiscountCodes: 'DESC',
  sortOrderInvoices: 'DESC',
  sortOrderPromoCodes: 'DESC',
  sortOrderSalaries: 'DESC',
  sortOrderTaxcards: 'DESC',
  sortOrderUserNotes: 'DESC',
  sortOrderUsers: 'DESC',
  sortOrderUserView1: 'DESC',
  sortOrderUserView2: 'DESC',
  sortOrderUserView3: 'DESC',
  sortOrderUserView4: 'DESC',
  sortOrderUserView5: 'DESC',
  sortOrderUserView6: 'DESC',
  sortOrderUserView7: 'DESC',
  sortOrderWorklists: 'DESC',
  sortOrderReferences: 'DESC',
  sortOrderValtticards: 'DESC',
  invoiceFilter: {
    user: [],
    client: [],
    to: '',
    from: '',
    status: [],
    type: 'LASKUT',
    payDay: [],
    accountingStatus: []
  }
}

export const StateContext = createContext([
  initialState,
  () => initialState
])

export const StateProvider = ({
  reducer,
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={[state, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateValue = () => useContext(StateContext)
