import React from 'react'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: ' ',
        //padding: '.5% 2%',
        //borderRadius: '7px',
        display: 'flex',
        //justifyContent: 'center',
        flexDirection: 'row',
        /* [theme.breakpoints.up('sm')]: {

        }, */
        '& > *': {
            /* '&:hover': {
                opacity: '0.5',
            }, */
            margin: '0% 1%',
            color: 'blue'
        }
    },
    samePage: {
        cursor: 'pointer'
    },
    child: {
        margin: '2% 0% 0% 5%'
    },
    hover: {
        '&:hover': {
            opacity: '0.5',
        },
    }
})) 

const DoubleLink = ({ view, edit, to, children }) => {
    const classes = useStyles();

    return <div className={classes.root}>
        {/* <div   onClick={() => history.push('/' + to)} className={classes.samePage}>
            {edit && <EditIcon fontSize='large' />}
            {view && <PageviewIcon fontSize='large'  />}
        </div> */}
        <Link to={'/' + to} target='_blank' className={classes.hover}>
            {edit && <EditOutlinedIcon fontSize='large' />}
            {view && <PageviewOutlinedIcon fontSize='large' />}
        </Link>
        <div className={classes.child}>{children}</div>
    </div>

}


export default DoubleLink