import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import Button from './Button'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Tip from './Tip'
import { withSnackbar } from 'notistack';
import { acceptedFileTypes, acceptedPromoTypes, acceptedTaxcardFileTypes } from '../utils/acceptedFileTypes';
import { useTranslation } from 'react-i18next';

const Dropzone = ({ setFile, style, enqueueSnackbar, tip }) => {
  const { t } = useTranslation();
  const handleUploadFile = (files) => {
    const file = files[0]
    if (!tip && file && !acceptedFileTypes.includes(file.type)) {
      enqueueSnackbar(
        t('taxcard.wrongFile'),
        { variant: 'error' }
      )
      return;
    } else if (tip === "promo" && file && !acceptedPromoTypes.includes(file.type)) {
      enqueueSnackbar(
        t('promocodes.wrongFile'),
        { variant: 'error' }
      )
      return;
    } else if (tip === "taxcard" && file && !acceptedTaxcardFileTypes.includes(file.type)) {
      enqueueSnackbar(
        t('taxcard.wrongFile2'),
        { variant: 'error' }
      )
      return;
    }
    if (file && file.size > 10000000) {
      enqueueSnackbar(
        t('taxcard.fileTooBig'),
        { variant: 'error' }
      )
      return;
    }
    const { name, type } = file
    const newFile = {
      file,
      filename: name,
      url: URL.createObjectURL(file),
      contentType: type
    }

    setFile(newFile)
  }

  const onDrop = (acceptedFiles => handleUploadFile(acceptedFiles))
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  let tipText = t('taxcard.uploadText')
  if (tip === "promo") {
    tipText = t('promocodes.uploadText')
  } else if (tip === "taxcard") {
    tipText = t('taxcard.uploadText2')
  }

  return (
    <Tip title={tipText} placement="right">
      <Button aria-label={tipText} style={{ ...style, backgroundColor: isDragActive && 'hotpink' }} size="large" variant="outlined" color="primary" className="customInput" id='uploadedFile' fullWidth {...getRootProps()}>
        <input {...getInputProps()} />
        <AttachFileIcon />
      </Button>
    </Tip>
  )
}

export default withSnackbar(Dropzone)
