import React, { useRef, useEffect, useState } from "react"
import { useHistory } from "react-router"
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Button from './Button'
import { useTranslation } from 'react-i18next'

export default function RouterPrompt({ when, title, body }) {
  const history = useHistory()
  const { t } = useTranslation()
  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState("")
  const unblockRef = useRef()

  useEffect(() => {
    unblockRef.current = history.block((location, action) => {
      if (when && action !== 'POP') {
        setCurrentPath(location.pathname)
        setShowPrompt(true)
        return false
      }
      return true
    })

    return () => {
      unblockRef.current && unblockRef.current()
    }
  }, [history, when])

  function handleConfirm() {
    if (unblockRef) {
      unblockRef.current()
    }
    setShowPrompt(false)
    history.push(currentPath)
  }

  return (
    <Dialog
      open={showPrompt}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setShowPrompt(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="prompt-dialog-title"
      aria-describedby="prompt-dialog-description"
    >
      <DialogTitle id="prompt-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true} id="prompt-dialog-description">{body}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setShowPrompt(false)
          }}
          color="secondary"
        >
          {t('user.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleConfirm}
          color="error"
        >
          {t('frontPage.agree')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}