import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useStateValue } from "../../state"
import Button from '../../components/Button'
import PasswordDialog from '../../components/PasswordDialog'
import PasswordDialogOffice from '../../components/PasswordDialogOffice'
import { withSnackbar } from 'notistack'
import InviteUserDialog from '../../components/InviteUserDialog'
import { useTranslation } from 'react-i18next'
import UserNoteDialog from '../../components/UserNoteDialog'
import CsvDialog from '../../components/CsvDialog'
import { Formik } from "formik"
import { USER_NOTE_TEMPLATE } from './User'
import DoneIcon from '@material-ui/icons/Done'
import TitledCard from "../../components/TitledCard"
import KeyValueList from "../../components/KeyValueList"

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    '& > *': {
      margin: theme.spacing(2),
      flexGrow: 1,
    },
  },
  titledCardFlexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  doneIcon: {
    color: 'green'
  }
}))


const UserBox1 = ({ user, setUser, isAdmin, enqueueSnackbar }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [{ currentUser }] = useStateValue()
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [openPasswordDialogOffice, setOpenPasswordDialogOffice] = useState(false)
  const [openInviteUserDialog, setOpenInviteUserDialog] = useState(false)
  const [openUserNoteDialog, setOpenUserNoteDialog] = useState(false)
  const [openCsvDialog, setOpenCsvDialog] = useState(false)

  //let userId = user.id
  let links = null
  if (user.userData && user.userData.length > 0) {
    /*userId += ' ('
    for (let i = 0; i < user.userData.length; i++) {
      userId += `<a href="/kayttajat/${user.userData[i].id}"} target='_blank' rel="noopener noreferrer">${user.userData[i].id}</a>`
      if (i < user.userData.length - 1) {
        userId += ', '
      }
    }
    userId += ')'*/
    links = user.userData.map((u, i) => <span key={i}><a href={`/kayttajat/${u.id}`} target='_blank' rel="noopener noreferrer">{u.id}</a>{i === user.userData.length - 1 ? '' : ', '}</span>)
  }

  const listItems = [
    { isVisible: isAdmin, key: t('user.id'), value: user.id, links: links },
    { key: t('user.name'), value: `${user.firstName} ${user.lastName}` },
    { isVisible: isAdmin, key: t('auth.ssn'), value: <>{user.ssn} {user.selfAuthenticated && <DoneIcon className={classes.doneIcon}/>}</> },
    { key: t('user.address'), value: <>{user.address1}{user.address1 && <br/>}{user.postalCode} {user.city}</> },
    { key: t('user.country'), value: t('countries.'+user.country) },
    { key: t('user.phone'), value: <a href={`tel:${user.phone}`}>{user.phone}</a> },
    { key: t('user.email'), value: <a href={`mailto:${user.email}`}>{user.email}</a> },
  ];

  return (
    <>
      <TitledCard variant="outlined" color="primary" title={t('user.basicInfo')} fillWidth fillHeight className={classes.titledCardFlexColumn}>
        <KeyValueList id="user-basic-info-list" items={listItems} />
        <div className={classes.row}>
          {user.id === currentUser.id ?
            <>
              <Button variant="outlined" color="primary" onClick={() => setOpenPasswordDialog(true)}>{t('user.changePassword')}</Button>
              {isAdmin && <Button variant="outlined" color="primary" onClick={() => setOpenInviteUserDialog(true)}>{t('user.inviteUser')}</Button> }
              {isAdmin && <Button variant="outlined" color="primary" onClick={() => setOpenCsvDialog(true)}>{t('user.addCsv')}</Button> }
            </>
            :
            <>
              <Button variant="outlined" color="primary" onClick={() => setOpenPasswordDialogOffice(true)}>{t('user.createNewPasswordOffice')}</Button>
              <Button variant="outlined" color="primary" onClick={() => setOpenUserNoteDialog(true)}>{t('user.createNewNote')}</Button>
            </>
          }
        </div>
      </TitledCard>
      {user.id === currentUser.id
        ? <PasswordDialog open={openPasswordDialog} setOpenPasswordDialog={setOpenPasswordDialog} />
        : <PasswordDialogOffice open={openPasswordDialogOffice} setOpenPasswordDialog={setOpenPasswordDialogOffice} user={user} />}
      <InviteUserDialog open={openInviteUserDialog} setOpenInviteUserDialog={setOpenInviteUserDialog} />
      <Formik
        enableReinitialize
        initialValues={USER_NOTE_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <UserNoteDialog open={openUserNoteDialog} setOpenUserNoteDialog={setOpenUserNoteDialog} user={user} setUser={setUser} {...formikProps} />}
      </Formik>
      <CsvDialog open={openCsvDialog} setOpenCsvDialog={setOpenCsvDialog} user={user} />
    </>
  );
}

export default withSnackbar(UserBox1)
