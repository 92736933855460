import { jwtDecode } from 'jwt-decode'
import request from './request'
import { cLog } from '../utils'

export let authToken = null
export let discount = null
export let token = null
export let userId = null
export let email = null
export let firstName = null
export let lastName = null
export let ssn = null
export let authCode = null
const isDevelopment = process.env.NODE_ENV === 'development'

export const setAuthToken = newAuthToken => {
  authToken = newAuthToken
  cLog('AUTH TOKEN', authToken)
}

export const setDiscountAndEmail = (newDiscountCode, newEmail) => {
  discount = newDiscountCode
  email = newEmail
  cLog('DISCOUNT CODE', discount)
  cLog('EMAIL', email)
}

export const setAuthCode = (newAuthCode) => {
  authCode = newAuthCode
  cLog('AUTH CODE', authCode)
}

export const setExternalFields = (extEmail, extFirstName, extLastName) => {
  //save signup fields from odeal.fi register form
  email = extEmail
  firstName = extFirstName
  lastName = extLastName
  cLog('ODEAL.FI KENTÄT', email, firstName, lastName)
}

export const removeExternalFields = () => {
  email = null
  firstName = null
  lastName = null
}

export const removeDiscountAndEmail = () => {
  discount = null
  email = null
}

export const removeAuthToken = () => {
  authToken = null
}

export const setToken = newToken => {
  token = newToken
  //cLog('SETTING TOKEN', token)
  cLog('DECODED', jwtDecode(token))
  if (token) {
    const decoded = jwtDecode(token)
    userId = decoded.user_id
    //cLog('USER ID', userId)
  }
}

export const setUserData = (currentUser) => {
  const userData = { loggedUser: currentUser, token }
  //cLog('SET USER DATA', userData)
  window.localStorage.setItem('userData', JSON.stringify(userData))
}

export const getExpirationTimeLeft = () => {
  if (token) {
    const expiration = jwtDecode(token).exp * 1000
    return expiration - Date.now()
  } else {
    return 0
  }
}

export const getLoggedUser = () => {
  const userData = JSON.parse(window.localStorage.getItem('userData'))
  if (isDevelopment) {
    cLog('USER DATA ', userData)
  }
  if (userData) {
    if (userData.token) {
      const expiration = jwtDecode(userData.token).exp * 1000
      if (expiration > Date.now()) {
        cLog('VANHA TOKEN VOIMASSA ' + new Date(expiration) + ' ASTI')
        setToken(userData.token)
        return userData.loggedUser
      } else {
        cLog('TOKEN VANHENTUNUT')
        return null
      }
    }
    cLog('EI TOKENIA')
    return null
  } else {
    cLog('EI AIEMPAA KIRJAUTUMISTA SELAIMEN MUISTISSA')
  }
}


export const logOut = async () => {
  cLog('LOGGING OUT')
  try {
    const response = await request('POST', '/users/sign_out')
    window.localStorage.setItem('userData', null)
    token = null
    cLog('LOG OUT', response)
  } catch (error) {
    const err = error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0] ? error.response.data.errors[0] : error
    cLog('SIGNOUT ERROR', err)
    window.localStorage.setItem('userData', null)
    token = null
  }
}

export const signIn = async (data) => {
  //const { email, password } = data
  //cLog('SIGNING IN ' + email + " " + password) 
  const response = await request('POST', '/users/sign_in', data)
  if (response.data && response.data.auth_token) {
    setToken(response.data.auth_token)
    cLog('USER ID ' + userId + ' ON KIRJAUTUNUT SISÄÄN')
    return userId
  } else {
    cLog('SING IN NO TOKEN ERROR')
    return null
  }
}

export const signUp = async (data) => {
  //const { email, password } = data
  //cLog('SIGNING IN ' + email + " " + password) 
  const response = await request('POST', '/users/sign_up', data)
  if (response.data && response.data.auth_token) {
    setToken(response.data.auth_token)
    cLog('USER ID ' + userId + ' ON KIRJAUTUNUT SISÄÄN')
    return userId
  } else {
    cLog('SING IN NO TOKEN ERROR')
    return null
  }
}

export const confirmUserEmail = async (data, id) => await request('PUT', '/users/' + id + '/confirm_email', data)

export const resetPassword = async (data) => await request('POST', '/users/reset_password', data)

export const setNewPassword = async (data) => await request('POST', '/users/create_password', data)

export const updatePassword = async (data, id) => await request('PUT', '/users/' + id + '/update_password', data)

export const sendNewPassword = async (id) => await request('PUT', '/users/' + id + '/send_new_password')

export const confirmNewEmail = async (data) => await request('POST', '/users/confirm_new_email', data)

export const requestNewEmailConfirmation = async (id) => await request('POST', '/users/' + id + '/request_new_email_confirm')
