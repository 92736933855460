import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Radio, FormControlLabel } from '@material-ui/core'
import InfoPop from './InfoPop'

const useStyles = makeStyles((theme) => ({
  customRadio: {
    color: theme.palette.primary.main,
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  errorRadio: {
    color: theme.palette.error.main,
    "&.Mui-checked": {
      color: theme.palette.error.main,
    },
  },
  lessMargin: {
    marginRight: '10px',
  },
  lessPadding: {
    paddingBottom: 3,
    paddingTop: 3
  }
}));

const OdealRadio = (props) => {
  const classes = useStyles();

  return (
    <span style={{ display: 'inline-flex' }}>
      <FormControlLabel className={`${props.info ? classes.lessMargin : ""}`} value={props.value} control={<Radio className={`${props.error ? classes.errorRadio : classes.customRadio} ${props.className ? classes.lessPadding : ""}`} />} {...props} label={props.label} />
      {props.info ? <InfoPop info={props.info} className={'lessMargin'} /> : ''}
    </span>
  );
};

export default OdealRadio
