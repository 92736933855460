import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core'

const Tip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'black',//'rgba(0, 0, 0, 0.87)',
        // borderColor: COLORS.primary,
        //padding:'0% 2%',
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1],
        fontSize: 16,
        zIndex: 3001,
        //marginTop:'4%'
    },
}))(Tooltip);

export default Tip
