//import { cLog } from '../../utils'
import { t } from "../../i18n"

export const validateName = (value) => {
  if (!String(value).trim()) {
   return ({ key: t('worklist.name')+t('user.missing') })
  }
  if (value && String(value).trim().length > 100) {
    return { key: t('worklist.nameTooLong')}
  }
}

export const validateDate = (value) => {
  if (!value || String(value).length === 0) {
    return { key: t('worklist.dateMissing') }
  }
  if (new Date(value) > new Date()) {
    return { key: t('worklist.wrongdate') }
  }
}

export const validateTask = (value) => {
  if (!String(value).trim()) {
    return { key: t('worklist.taskMissing') }
  }
  if (value && String(value).trim().length > 255) {
    return {key: t('worklist.taskTooLong')}
  }
}

export const validateHours = (value) => {
  if (!String(value).trim()) {
    return { key: t('worklist.hoursMissing') }
  }
  const val = String(value).replace(',','.')
  const numberRegEx = new RegExp('^\\-?\\d*\\.?\\d{1,2}$')
  if (!numberRegEx.test(val) || val === '-') {
    return { key: t('worklist.hoursNotNumber') }
  }
  const float = parseFloat(val)
  if (float <= 0) {
    return { key: t('worklist.hoursTooLow') }
  }
}

export const checkWorklistRowErrors = (values) => {
  if (validateDate(values.date)
    || validateTask(values.task)
    || validateHours(values.hours)
  ) {
    return false
  }
  return true
}

export const checkWorklistBodyErrors = (values, isAdmin) => {
  if ((isAdmin && !values.userId)
    || !values.clientId
    || validateName(values.name)
    ) {
    return false
  }
  return true
}

export const validateAll = (values, isAdmin) => {
  let validWorklist = true
  let result = checkWorklistBodyErrors(values, isAdmin)
  if (!result) {
    validWorklist = false
  }

  if (values.worklistRows) {
    for (let i = 0; i < values.worklistRows.length; i++) {
      let result = checkWorklistRowErrors(values.worklistRows[i])
      if (!result) {
        validWorklist = false
        break
      }
    }
  } else {
    validWorklist = false
  }

  return validWorklist
}