import { alpha } from '@material-ui/core/styles'
import backGroundImageLight from './photos/Laskutacom_tausta_vaalea.jpg'
//import { cLog } from '../utils'

//const primary = red[500]; // #F44336
//const accent = purple['A200']; // #E040FB
//const accent = purple.A200; // #E040FB (alternative method)

const primary = 'rgb(129,36,227)'       //Odeal violetti
const secondary = 'black' //'#252525'         //musta/harmaa
const tertiary = '#f0ceee'    // vaalea rosa tai '#C465FF' etusivulta//

export const backgroundColor = '#F0FFFF'
export const fontFamily = '"Tajawal", sans-serif'
export const fontSize = '0.9em'
export const BUTTON_COLOR = primary
export const LIGHTER_BUTTON_COLOR = primary

export const BORDERCOLOR1 = primary
export const INPUT_BACKGROUND_COLOR1 = '#F0F8FF'
export const INPUT_BACKGROUND_COLOR2 = '#F0FFFF'
export const FONT_COLOR1 = primary
export const FONT_COLOR2 = primary

export const COLORS = {
    primary,
    secondary,
    tertiary,
    ROOT_BACKGROUND_COLOR: 'white',//'#F0FFFF',
    PAPER_BACKGROUND_COLOR: '#F0F8FF',//'#F0F8FF',
    APP_BAR_BACKGROUND_COLOR: '#FFFFFF',
    APP_BAR_LINK_COLOR: "#252525",
    BUTTON_COLOR: primary,
    //BUTTON_BACKGROUND_COLOR: tertiary,
    LIGHTER_BUTTON_COLOR: primary,
    BORDERCOLOR1: primary,
    BORDERCOLOR2: 'white',
    BORDERCOLOR3: 'red',
    // INPUT_BACKGROUND_COLOR1: 'white',//'#F0F8FF',
    //INPUT_BACKGROUND_COLOR2: '#F0FFFF',
    FONT_COLOR1: secondary,
    FONT_COLOR2: 'black',
    FONT_COLOR3: 'red',
    FONT_COLOR4: '#808080',
    STATUS_COLORS: {
        'LUONNOS': '#34abeb',
        'TARKASTETTAVANA': 'orange',
        'LÄHETETTY': 'green',
        'MAKSETTU': primary,
        'TILITETTY':'#eb34c9',
        'MUISTUTETTU': 'hotpink',
        'PERINNÄSSÄ': '#d1d124',
        'MYÖHÄSSÄ': 'red',
        'POISTETTU': 'red',
        'HETI': 'black',
        'VALMIS': 'green',
        'HYLÄTTY': 'red',
        'ALENNUSKOODI': primary,
        'OSITTAIN_MAKSETTU': '#007bff',
        'ULOSOTOSSA': 'red',
        'LUOTTOTAPPIO': '#828282',
        'MAKSETTU_LIIKAA': 'red',
        'HYVITETTY': 'red',
        'HYVITYSLASKU': 'red',
        'PIKAMAKSU': '#f071b6',
    }
}

export const FONTS = {
    FONT_FAMILY1: '"Tajawal", Helvetica, Arial, sans-serif',
    FONT_FAMILY2: '"Raleway", Helvetica, Arial, sans-serif',
    FONT_SIZE1: '16px'//'1.1em'
}

export const PAPER_ELEVATION = 5;

export const REACT_SELECT_STYLES_OPTION = (provided, state, theme, props) => ({
  ...provided,
  color: state.isSelected ? theme.palette.primary.contrastText : state.isDisabled ? theme.palette.grey.dark : theme.palette.white.contrastText,
  backgroundColor: state.isSelected ? theme.palette.primary.main : theme.palette.white.main,
  '&:hover':
  {
    backgroundColor: (!state.isDisabled && !state.isSelected) && alpha(theme.palette.primary.main, 0.2),
  }
})

export const REACT_SELECT_STYLES_CONTROL = (provided, state, theme, props) => ({
  ...provided,
  position: 'relative',
  background: theme.palette.white.main,
  color: theme.palette.white.contrastText,
  border: theme.borders.thin(props.error ? theme.palette.error.main : state.isFocused ? theme.palette.primary.main : theme.palette.grey.dark),
  borderRadius: theme.borders.radius.small,
  padding: theme.spacing(1),
  width: '100%',
  minHeight: '46px',
  outline: 'none',
  fontSize: '1rem',
  boxShadow: (state.isFocused || state.menuIsOpen) ? `${alpha(props.error ? theme.palette.error.main : theme.palette.primary.main, 0.9)} 0 0 0 1px` : 'none',
  transition: 'border-color 0.125s, box-shadow 0.125s',

  '&:hover': {
    borderColor: props.error ? theme.palette.error.main : theme.palette.primary.main,
  },
  '&:focus': {
    boxShadow: `${alpha(props.error ? theme.palette.error.main : theme.palette.primary.main, 0.9)} 0 0 0 1px`,
    borderColor: props.error ? theme.palette.error.main : theme.palette.primary.main,
  },
  '&::placeholder': {
    color: 'rgba(0, 0, 0, 0.5)',
  },
})

export const backGroundImage = {
    backgroundImage:  `url(${backGroundImageLight})`, //    "url('https://odeal.fi/wp-content/uploads/2018/08/Odeal_kampanja_1.jpg')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundBlendMode: 'normal'
}

export const muiTheme = {
    palette: {
        primary: {
            main: "#7620E0",
            light: "#ad56ff",
            dark: "#3b00ad",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#191919",
            light: "#3f3f3f",
            dark: "#000000",
            contrastText: "#FFFFFF",
        },
        grey: {
            main: "#F4F4F4",
            light: "#ffffff",
            dark: "#c1c1c1",
            contrastText: "#666666",
        },
        white: {
            main: "#FFFFFF",
            light: "#ffffff",
            dark: "#cccccc",
            contrastText: "#191919"
        },
        success: {
            main: "#32a852",
            light: "#6ada80",
            dark: "#007826",
            contrastText: "#FFFFFF"
        },
        error: {
            main: "#c43427",
            light: "#fd6751",
            dark: "#8c0000",
            contrastText: "#FFFFFF"
        },
        warning: {
            main: "#c94f03",
            light: "#ff7f39",
            dark: "#911e00",
            contrastText: "#FFFFFF"
        },
        info: {
            main: "#3263a8",
            light: "#6890da",
            dark: "#003a78",
            contrastText: "#FFFFFF"
        },
        base: {
            main: "#191919",
            light: "#3f3f3f",
            dark: "#000000",
            contrastText: "#FFFFFF",
        },
    },
    typography: {
        // fontFamily: FONTS.FONT_FAMILY1,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: 14,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 1024,
            lg: 1280,
            xl: 1400,
            max: 1720, // maximum content width for user layout (admin layout changes gutter styling once past this resolution)
        }
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    borders: {
        base: (color) => `2px solid ${color}`,
        thin: (color) => `1px solid ${color}`,
        radius: {
            small: '4px',
            base: '6px',
            rounded: '9px',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: '1rem',
                // fontWeight: 'bold',
                borderRadius: '6px',
                alignItems: 'center',
                justifyContent: 'center',
            },
            label: {
                // marginTop: '4px',
                display: 'flex',
                alignItems: 'flex-start',
                verticalAlign: 'middle',
            },
            contained: {
                textTransform: 'none',
            },
            outlined: {
                textTransform: 'none',
                borderWidth: '2px !important',
            },
        },
        MuiTableCell: {
            head: {
                fontWeight: 500,
                lineHeight: '1.5rem',
            },
        },
        MuiDialog: {
            paper: {
                '@media only screen and (max-width: 640px)': {
                    margin: '0.5rem'
                },
            },
        },
        MuiPopover: {
            paper: {
                '@media only screen and (min-width: 1025px)': {
                    maxWidth: '50%'
                },
                '@media only screen and (max-width: 1024px)': {
                    maxWidth: '70%'
                },
                '@media only screen and (max-width: 640px)': {
                    maxWidth: '90%'
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                width: 'fit-content',
            }
        }
    }
}

// Using this on MUI tables will transform them into a list of cards at mobile resolution
// NOTE: This is not ideal, all the numerous "data table" instances should be made into a single re-usable DataTable component
export const responsiveTableStyles = {
    // Row will become a card-like component
    '& > * > .MuiTableRow-root': {
        '@media only screen and (max-width: 640px)': {
            display: 'flex',
            flexDirection: 'column',
            margin: '0 0.2rem 1rem 0.2rem',
            padding: '1rem',
            borderRadius: '6px',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px', // Taken from MUI box shadows
        },

        // Table cell will act as a key-value-pair (uses a custom 'data-label' attribute to visualize the key)
        '& > .MuiTableCell-root': {
            '@media only screen and (max-width: 640px)': {
                borderBottom: 0,
                padding: '0.5rem 0 !important',
                textAlign: 'right',
                float: 'right',

                // 'Before'-pseudo-element will create the key if the defined custom html-attribute is given
                '&::before': {
                    content: 'attr(data-label)',
                    textAlign: 'left',
                    float: 'left',
                    fontWeight: 500,
                }
            },
        },
    },

    // Table header will be taken out entirely at mobile resolution
    '& > .MuiTableHead-root': {
        '@media only screen and (max-width: 640px)': {
            visibility: 'collapse',
            position: 'absolute',
            width: '0px',
            height: '0px',
        },
    },
};

// A tad bit hacky way of making the NewPagination instances look and behave similarly
// NOTE: This is not ideal, all the numerous NewPagination instances should be made into a single re-usable TablePagination component
export const newPaginationStyles = {
    fontWeight: 400,
    fontSize: '0.9rem',

    // MUI Pagination buttons
    '& > div > nav > ul > li > button': {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        border: muiTheme.borders.thin(muiTheme.palette.grey.dark),
        background: muiTheme.palette.white.main,
    },

    // Different control groups separated by spans
    '& > span': {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            margin: '0 0.25rem 0 0',
        }
    },

    // Select controls
    '& > select, & > * > select': {
        padding: '0 0.5rem',
        height: '32px !important',
        background: muiTheme.palette.white.main,
        border: muiTheme.borders.thin(muiTheme.palette.grey.dark),
        borderRadius: `${muiTheme.borders.radius.small} !important`,
        fontFamily: 'inherit',
        fontSize: 'inherit',
        cursor: 'pointer',
        transition: 'border 0.125s',

        '&:hover': {
            border: muiTheme.borders.thin(muiTheme.palette.primary.main),
        },
    },

    // Regular buttons
    '& > button, & > * > button': {
        padding: '0 1rem',
        height: '32px !important',
        background: muiTheme.palette.primary.main,
        color: muiTheme.palette.primary.contrastText,
        borderRadius: `${muiTheme.borders.radius.small} !important`,
        textAlign: 'center',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 500,
        border: 0,
        cursor: 'pointer',
        transition: 'background 0.125s',

        '&:hover': {
            background: muiTheme.palette.primary.dark,
        },
    },
};
