import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../../screens/Footer'
import { useTranslation } from 'react-i18next';
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'

const useStyles = makeStyles((theme) => ({
    container: {
        "& > *:nth-child(even)": {
            marginBottom: theme.spacing(4),
        },
    },
}))

const Terms = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <LayoutContainer>
            <Header title={t('terms.terms')}/>
            <div className={classes.container}>
                <div><strong>1 §</strong></div>
                <div>
                    {t('terms.1')}<br/><br/>{t('terms.1_2')}
                </div>
                <div><strong>2 §</strong></div>
                <div>
                    {t('terms.2')}
                </div>
                <div><strong>3 §</strong></div>
                <div>
                    {t('terms.3')}
                 </div>
                <div><strong>4 §</strong></div>
                <div>
                    {t('terms.4')}
                </div>
                <div><strong>5 §</strong></div>
                <div>
                    {t('terms.5')}
                 </div>
                <div><strong>6 §</strong></div>
                <div>
                    {t('terms.6')}
                 </div>
                <div><strong>7 §</strong></div>
                <div>
                    {t('terms.7')}
                 </div>
                <div><strong>8 §</strong></div>
                <div>
                    {t('terms.8')}
                </div>
                <div><strong>9 §</strong></div>
                <div>
                    {t('terms.9')}
                </div>
                <div><strong>10 §</strong></div>
                <div>
                    {t('terms.10')}<br/><br/>{t('terms.10_2')}<br/><br/>{t('terms.10_3')}
                </div>
                <div><strong>11 §</strong></div>
                <div>
                    {t('terms.11')}
                </div>
                <div><strong>12 §</strong></div>
                <div>
                    {t('terms.12')}
                </div>
                <div><strong>13 §</strong></div>
                <div>
                    {t('terms.13')}
                </div>
                <div><strong>14 §</strong></div>
                <div>
                    {t('terms.14')}<br/><br/>{t('terms.14_2')}<br/><br/>{t('terms.14_3')}
                </div>
            </div>
            <Footer className={classes.footer}/>
        </LayoutContainer>
    )
}

export default Terms
