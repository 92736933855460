import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //lng: (window.localStorage.getItem('i18nextLng') ?  window.localStorage.getItem('i18nextLng') : 'fi'),
    fallbackLng: 'fi',
    lng: 'fi', // default language
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;

const t = i18n.t.bind(i18n);
export { t };