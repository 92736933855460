import React from 'react'
import { Field, FieldArray } from 'formik'
import { timeRange, totalTravellingExpenceRow, totalTravellingExpenceRowKm, cleanFloat } from '../../utils/invoiceUtils'
import { checkTravelRowErrors, checkTravelRowErrors2, validateFloatBetween, validateDate, validateReturnDate, validateTextArea, validateDepartureTime, validateReturnTime, validateIntBetween, validateTravelType, validateSpecialIndustry1, validateSpecialIndustry2, validateSpecialIndustry3, validateMobileWork, validateFloatGreaterThan } from './validationSchemas'
import Input from '../../components/Input'
import DropDown from '../../components/DropDown'
import { Grid, useMediaQuery, RadioGroup } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Hanuri, HanuriTitle, HanuriBody } from '../../components/Hanuri'
//import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import DPicker from '../../components/DPicker'
import { formatDate } from '../../utils'
import { getAllowances } from '../../service/expenceService'
import { withSnackbar } from 'notistack'
import OdealRadio from '../../components/OdealRadio'
import { TRAVEL_ROW_DESTINATIONS_TEMPLATE } from '../../utils/invoiceUtils'
import { roundNumber } from '../../utils//index'
import TravelDestinations from '../../components/TravelDestinations'

const TravellingExpenceRow = ({ 
  travellingExpenceRows,
  setFieldValue,
  expanded,
  handleChangeExpansion,
  removeRow,
  isAdmin,
  exp,
  allowNegative,
  oldInvoiceTravelType1,
  oldInvoiceTravelType2,
  oldInvoiceGoogleMaps,
  vat24,
  touched,
  setFieldTouched,
  enqueueSnackbar,
  validate,
  panelInitValidate,
  setPanelInitValidate,
  showKmInfoDialog,
  setShowKmInfoDialog,
  setFieldError
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const timeOptions = React.useMemo(() => []
    .concat(...timeRange(24)
    .map(hour => timeRange(0, 60, 15)
      .map(min => {
        const time = `${hour}:${min}`
        return (
          { label: time, value: time }
        )
      })
    )), [])

  let allVats = ['25,5', '24', '14', '10', '0']
  if (!exp) {
    if (vat24) {
      allVats = ['24', '14', '10', '0']
    } else {
      allVats = ['25,5', '14', '10', '0']
    }
  }
  const vatOptions = allVats.map(vat => {
      return ({ label: vat + ' %', value: vat })
    })

  return (
    <FieldArray
      name="travellingExpenceRows"
      validateOnChange={false}
      validateOnBlur={false}
      render={arrayHelpers => {
        return (
          <>
            {travellingExpenceRows.map((row, i) => {
              let departureYear = new Date(travellingExpenceRows[i].departureDate).getFullYear()
              let returnYear = new Date(travellingExpenceRows[i].returnDate).getFullYear()
              let vehicleOptions = [{ label: t('invoice.auto'), value: 'auto' }], internationalAllowanceOptions = [{ label: t('countries.FINLAND'), value: 0 }]

              if (travellingExpenceRows[i].allowances) {
                vehicleOptions = Object.keys(travellingExpenceRows[i].allowances.vehicle).map(veh => ({ label: t('invoice.'+veh), value: veh }))
                internationalAllowanceOptions = Object.keys(travellingExpenceRows[i].allowances.dailyAllowance).map(country => ({ label: t('countries.'+country), value: country }))
              }

              const purposeOptions = [{ label: t('invoice.purposeWork'), value: 'WORK' }, { label: t('invoice.purposeOther'), value: 'OTHER' }]

              const errors = arrayHelpers.form.errors.travellingExpenceRows
                ? arrayHelpers.form.errors.travellingExpenceRows[i]
                : {}
              const touchedRow = touched.travellingExpenceRows
                ? touched.travellingExpenceRows[i]
                : {}
              const travelRowHasErrors = errors && Object.keys(errors) && Object.keys(errors).length > 0

              function calculateDistance(origin, destination, destIndex, travelRow) {
                if (origin && destination) {
                  let distanceMatrixService = new window.google.maps.DistanceMatrixService()

                  distanceMatrixService.getDistanceMatrix(
                    {
                      origins: [origin],
                      destinations: [destination],
                      travelMode: 'DRIVING',
                      unitSystem: window.google.maps.UnitSystem.METRIC,
                      avoidHighways: false,
                      avoidTolls: false,
                    },
                    function handleDistanceMatrixResponse(response, status) {
                      if (status === 'OK' && response.rows && response.rows[0].elements && response.rows[0].elements[0].status === 'OK') {
                        // Handle the Distance Matrix API response here
                        //cLog('Distance Matrix API response:', response);
                        const distance = response.rows[0].elements[0]
                        const km = roundNumber(cleanFloat(distance.distance.text.split(' ')[0]), 1)
                        setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${destIndex}.km`, km)
                        setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${destIndex}.kmGoogle`, km)
                        const trow = { ...travelRow, destinations: travelRow.travelRowDestinations.map((item, idx) => idx === destIndex ? item.km = km : item) }
                        setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
                        setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
                      } else {
                        //cLog('Distance Matrix API error:', response);
                        setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${destIndex}.km`, destIndex === 0 ? 0 : '')
                        setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.${destIndex}.kmGoogle`, 0)
                        const trow = { ...travelRow, destinations: travelRow.travelRowDestinations.map((item, idx) => idx === destIndex ? item.km = 0 : item) }
                        setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
                        setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
                      }
                    }
                  )
                }
              }

              function handleAddDestination(i, i2, arrayHelpers) {
                // add shown destination
                let updatedDestinations = travellingExpenceRows[i].travelRowDestinations.map((row) => (row.order <= i2 ? row : { ...row, order: row.order + 1 }))
                updatedDestinations.splice(i2 + 1, 0, { ...TRAVEL_ROW_DESTINATIONS_TEMPLATE, order: i2 + 1 })

                if (i2 !== updatedDestinations.length - 2) {
                  updatedDestinations[i2 + 2].km = 0
                  updatedDestinations[i2 + 2].kmGoogle = 0
                }
                const trow = { ...travellingExpenceRows[i], travelRowDestinations: updatedDestinations }
                arrayHelpers.replace(i, trow)
                setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
              }

              function handleDeleteDestination(i, i2, arrayHelpers) {
                // remove shown destination
                let updatedDestinations = travellingExpenceRows[i].travelRowDestinations.map((row) => (row.order <= i2 ? row : { ...row, order: row.order - 1 }))
                updatedDestinations.splice(i2, 1)

                // set new first destination km 0 if needed
                if (i2 === 0) {
                  updatedDestinations[0].km = 0
                  updatedDestinations[0].kmGoogle = 0
                }
                let trow = { ...travellingExpenceRows[i], travelRowDestinations: updatedDestinations }

                // add new destination if too few
                if (updatedDestinations.length < 2) {
                  // add shown destination 
                  updatedDestinations.splice(1, 0, { ...TRAVEL_ROW_DESTINATIONS_TEMPLATE, order: 1 })
                  trow = { ...travellingExpenceRows[i], travelRowDestinations: updatedDestinations }
                  arrayHelpers.replace(i, trow)
                }

                arrayHelpers.replace(i, trow)

                // update km
                if (i2 === 0) {
                  setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.0.km`, '')
                  setFieldValue(`travellingExpenceRows.${i}.travelRowDestinations.0.kmGoogle`, '')
                  setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
                } else if (i2 === updatedDestinations.length) {
                  setFieldValue(`travellingExpenceRows.${i}.km`, totalTravellingExpenceRowKm(trow))
                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow(trow))
                } else {
                  calculateDistance(trow.travelRowDestinations[i2 - 1].destination, trow.travelRowDestinations[i2].destination, i2, trow)
                }
              }

              return (
                <div id={`tRow${i}`} key={i}>
                  <Hanuri>
                    <HanuriTitle
                      expanded={expanded === `travellingRows.${i}`}
                      id={`travellingRows.${i}`}
                      open={handleChangeExpansion(`travellingRows.${i}`)}
                      remove={() => {
                        removeRow('travellingRows', i)
                        arrayHelpers.remove(i)
                      }}
                      label={t('invoice.travellingRow')+' '+(i+1)}
                      done={checkTravelRowErrors(row, isAdmin, allowNegative, oldInvoiceTravelType1, oldInvoiceTravelType2, oldInvoiceGoogleMaps)}
                      error={travelRowHasErrors && !checkTravelRowErrors(row, isAdmin, allowNegative, oldInvoiceTravelType1, oldInvoiceTravelType2, oldInvoiceGoogleMaps)}
                    />
                    <HanuriBody expanded={expanded === `travellingRows.${i}`}>
                      <Grid container spacing={2}>
                        {!oldInvoiceTravelType1 && <Grid item xs={12} sm={12}>
                          <label>{t('invoice.travelType')+' *'}</label>
                          <RadioGroup
                            value={row.travelType || ''}
                            onChange={(e) => {
                              setFieldValue(`travellingExpenceRows.${i}.travelType`, e.target.value)
                              setFieldTouched(`travellingExpenceRows.${i}.travelType`, true)
                              setFieldTouched(`travellingExpenceRows.${i}.specialIndustryOvernight`, false)
                              setFieldTouched(`travellingExpenceRows.${i}.specialIndustryPrimaryPlace`, false)
                              setFieldTouched(`travellingExpenceRows.${i}.specialIndustryDining`, false)
                              setFieldTouched(`travellingExpenceRows.${i}.mobileWorkPrimaryPlace`, false)
                            }}
                          >
                            <Field
                              name={`travellingExpenceRows.${i}.travelType`}
                              label={t('invoice.travelTypeGeneral')}
                              component={OdealRadio}
                              value='YLEINEN'
                              info={t('invoice.travelTypeGeneralInfo')}
                              validate={() => validateTravelType(oldInvoiceTravelType1, row.travelType)}
                              error={errors && errors.travelType && (!touchedRow || !touchedRow.travelType ? 'error' : '')}
                            />
                            <Field
                              name={`travellingExpenceRows.${i}.travelType`}
                              label={t('invoice.travelTypeSpecialIndustry')}
                              component={OdealRadio}
                              value='ERITYISALA'
                              info={t('invoice.travelTypeSpecialIndustryInfo')}
                              validate={() => validateTravelType(oldInvoiceTravelType1, row.travelType)}
                              error={errors && errors.travelType && (!touchedRow || !touchedRow.travelType ? 'error' : '')}
                            />
                            {row.travelType === 'ERITYISALA' && <Grid container spacing={0}>
                              <Grid item xs={1} sm={1}></Grid>
                              <Grid item xs={4} sm={4} style={{ paddingTop: '4px' }}>
                                {t('invoice.specialIndustryOvernight')}
                              </Grid>
                              <Grid item xs={7} sm={7}>
                                <RadioGroup
                                  row
                                  value={row.specialIndustryOvernight || ''}
                                  onChange={(e) => {
                                    setFieldValue(`travellingExpenceRows.${i}.specialIndustryOvernight`, e.target.value)
                                    setFieldTouched(`travellingExpenceRows.${i}.specialIndustryOvernight`, true)
                                  }}
                                >
                                  <Field
                                    name={`travellingExpenceRows.${i}.specialIndustryOvernight`}
                                    id={`travellingExpenceRows.${i}.specialIndustryOvernight`}
                                    label={t('taxcard.true')}
                                    component={OdealRadio}
                                    value={'true'}
                                    className={'lessPadding'}
                                    validate={() => validateSpecialIndustry1(row.travelType, row.specialIndustryOvernight)}
                                    error={errors && errors.specialIndustryOvernight && (!touchedRow || !touchedRow.specialIndustryOvernight ? 'error' : '')}
                                  />
                                  <Field
                                    name={`travellingExpenceRows.${i}.specialIndustryOvernight`}
                                    label={t('taxcard.false')}
                                    component={OdealRadio}
                                    value={'false'}
                                    className={'lessPadding'}
                                    validate={() => validateSpecialIndustry1(row.travelType, row.specialIndustryOvernight)}
                                    error={errors && errors.specialIndustryOvernight && (!touchedRow || !touchedRow.specialIndustryOvernight ? 'error' : '')}
                                  />
                                </RadioGroup>
                              </Grid>
                              <Grid item xs={1} sm={1}></Grid>
                              <Grid item xs={4} sm={4} style={{ paddingTop: '4px' }}>
                                {t('invoice.specialIndustryPrimaryPlace')}
                              </Grid>
                              <Grid item xs={7} sm={7}>
                                <RadioGroup
                                  row
                                  value={row.specialIndustryPrimaryPlace || ''}
                                  onChange={(e) => {
                                    setFieldValue(`travellingExpenceRows.${i}.specialIndustryPrimaryPlace`, e.target.value)
                                    setFieldTouched(`travellingExpenceRows.${i}.specialIndustryPrimaryPlace`, true)
                                  }}
                                >
                                  <Field
                                    name={`travellingExpenceRows.${i}.specialIndustryPrimaryPlace`}
                                    label={t('taxcard.true')}
                                    component={OdealRadio}
                                    value={'true'}
                                    className={'lessPadding'}
                                    validate={() => validateSpecialIndustry2(row.travelType, row.specialIndustryPrimaryPlace)}
                                    error={errors && errors.specialIndustryPrimaryPlace && (!touchedRow || !touchedRow.specialIndustryPrimaryPlace ? 'error' : '')}
                                  />
                                  <Field
                                    name={`travellingExpenceRows.${i}.specialIndustryPrimaryPlace`}
                                    label={t('taxcard.false')}
                                    component={OdealRadio}
                                    value={'false'}
                                    className={'lessPadding'}
                                    validate={() => validateSpecialIndustry2(row.travelType, row.specialIndustryPrimaryPlace)}
                                    error={errors && errors.specialIndustryPrimaryPlace && (!touchedRow || !touchedRow.specialIndustryPrimaryPlace ? 'error' : '')}
                                  />
                                </RadioGroup>
                              </Grid>
                              {!oldInvoiceTravelType2 && <>
                                <Grid item xs={1} sm={1}></Grid>
                                <Grid item xs={4} sm={4} style={{ paddingTop: '4px' }}>
                                  {t('invoice.specialIndustryDining')}
                                </Grid>
                                <Grid item xs={7} sm={7}>
                                  <RadioGroup
                                    row
                                    value={row.specialIndustryDining || ''}
                                    onChange={(e) => {
                                      setFieldValue(`travellingExpenceRows.${i}.specialIndustryDining`, e.target.value)
                                      setFieldTouched(`travellingExpenceRows.${i}.specialIndustryDining`, true)
                                    }}
                                  >
                                    <Field
                                      name={`travellingExpenceRows.${i}.specialIndustryDining`}
                                      label={t('taxcard.true')}
                                      component={OdealRadio}
                                      value={'true'}
                                      className={'lessPadding'}
                                      validate={() => validateSpecialIndustry3(row.travelType, row.specialIndustryDining)}
                                      error={errors && errors.specialIndustryDining && (!touchedRow || !touchedRow.specialIndustryDining ? 'error' : '')}
                                    />
                                    <Field
                                      name={`travellingExpenceRows.${i}.specialIndustryDining`}
                                      label={t('taxcard.false')}
                                      component={OdealRadio}
                                      value={'false'}
                                      className={'lessPadding'}
                                      validate={() => validateSpecialIndustry3(row.travelType, row.specialIndustryDining, oldInvoiceTravelType2)}
                                      error={errors && errors.specialIndustryDining && (!touchedRow || !touchedRow.specialIndustryDining ? 'error' : '')}
                                    />
                                  </RadioGroup>
                                </Grid>
                              </>}
                            </Grid>}
                            <Field
                              name={`travellingExpenceRows.${i}.travelType`}
                              label={t('invoice.travelTypeMobileWork')}
                              component={OdealRadio}
                              value='LIIKKUVA_TYO'
                              info={t('invoice.travelTypeMobileWorkInfo')}
                              validate={() => validateTravelType(oldInvoiceTravelType1, row.travelType)}
                              error={errors && errors.travelType && (!touchedRow || !touchedRow.travelType ? 'error' : '')}
                            />
                            {row.travelType === 'LIIKKUVA_TYO' && <Grid container spacing={0}>
                              <Grid item xs={1} sm={1}></Grid>
                              <Grid item xs={4} sm={4} style={{ paddingTop: '4px' }}>
                                {t('invoice.mobileWorkPrimaryPlace')}
                              </Grid>
                              <Grid item xs={7} sm={7}>
                                <RadioGroup
                                  row
                                  value={row.mobileWorkPrimaryPlace || ''}
                                  onChange={(e) => {
                                    setFieldValue(`travellingExpenceRows.${i}.mobileWorkPrimaryPlace`, e.target.value)
                                    setFieldTouched(`travellingExpenceRows.${i}.mobileWorkPrimaryPlace`, true)
                                  }}
                                >
                                  <Field
                                    name={`travellingExpenceRows.${i}.mobileWorkPrimaryPlace`}
                                    label={t('taxcard.true')}
                                    component={OdealRadio}
                                    value={'true'}
                                    className={'lessPadding'}
                                    validate={() => validateMobileWork(row.travelType, row.mobileWorkPrimaryPlace)}
                                    error={errors && errors.mobileWorkPrimaryPlace && (!touchedRow || !touchedRow.mobileWorkPrimaryPlace ? 'error' : '')}
                                  />
                                  <Field
                                    name={`travellingExpenceRows.${i}.mobileWorkPrimaryPlace`}
                                    label={t('taxcard.false')}
                                    component={OdealRadio}
                                    value={'false'}
                                    className={'lessPadding'}
                                    validate={() => validateMobileWork(row.travelType, row.mobileWorkPrimaryPlace)}
                                    error={errors && errors.mobileWorkPrimaryPlace && (!touchedRow || !touchedRow.mobileWorkPrimaryPlace ? 'error' : '')}
                                  />
                                </RadioGroup>
                              </Grid>
                            </Grid>}
                          </RadioGroup>
                        </Grid>}
                        {(row.travelType || oldInvoiceTravelType1) &&
                          <>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`travellingExpenceRows.${i}.departureDate`}
                                id={`travellingExpenceRows.${i}.departureDate`}
                                label={t('invoice.departureDate')+' *'}
                                component={DPicker}
                                selected={travellingExpenceRows[i].departureDate ? new Date(travellingExpenceRows[i].departureDate) : ''}
                                onChange={(date) => {
                                  if (date) {
                                    setFieldValue(`travellingExpenceRows.${i}.departureDate`, formatDate(date))
                                    if (date && new Date(date).withoutTime() >= new Date('2012-01-01').withoutTime()) {
                                      const departureYear = date.getFullYear()
                                      getAllowances(departureYear).then(response => {
                                        setFieldValue(`travellingExpenceRows.${i}.allowances`, response.data)
                                        setFieldValue(`travellingExpenceRows.${i}.mileage`, response.data.vehicle[row.vehicle])
                                        setFieldValue(`travellingExpenceRows.${i}.daAmount`, response.data.dailyAllowance[row.country])
                                        if (row.country === 'FINLAND') {
                                          setFieldValue(`travellingExpenceRows.${i}.ptdaAmount`, response.data.partTimeDailyAllowance)
                                        } else {
                                          setFieldValue(`travellingExpenceRows.${i}.ptdaAmount`, response.data.dailyAllowance[row.country] / 2)
                                        }
                                        setFieldValue(`travellingExpenceRows.${i}.meAmount`, response.data.mealExpence)
                                      },
                                      error => {
                                        //cLog('GET ALLOWANCES ERROR', error)
                                        setFieldValue(`travellingExpenceRows.${i}.allowances`, '')
                                        enqueueSnackbar(
                                          t('invoice.departureDateError'),
                                          { variant: 'error' }
                                        )
                                      })
                                    }
                                  } else {
                                    setFieldValue(`travellingExpenceRows.${i}.departureDate`, '')
                                    setFieldValue(`travellingExpenceRows.${i}.allowances`, '')
                                  }
                                }}
                                validate={() => validateDate(row.departureDate, t('invoice.departureDate'), true, '2012-01-01')}
                                error={errors && errors.departureDate && validateDate(row.departureDate, t('invoice.departureDate'), true, '2012-01-01')}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`travellingExpenceRows.${i}.returnDate`}
                                id={`travellingExpenceRows.${i}.returnDate`}
                                label={t('invoice.returnDate')+' *'}
                                info={t('invoice.returnDateTip')}
                                component={DPicker}
                                selected={travellingExpenceRows[i].returnDate ? new Date(travellingExpenceRows[i].returnDate) : ''}
                                onChange={(date) => setFieldValue(`travellingExpenceRows.${i}.returnDate`, date ? formatDate(date) : '')}
                                validate={() => validateReturnDate(row.returnDate, row.departureDate)}
                                error={errors && errors.returnDate && validateReturnDate(row.returnDate, row.departureDate)}
                              />
                            </Grid>
                          </>
                        }
                        {departureYear && returnYear ? 
                          <>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`travellingExpenceRows.${i}.departureTime`}
                                id={`travellingExpenceRows.${i}.departureTime`}
                                label={t('invoice.departureTime')+' *'}
                                component={DropDown}
                                options={timeOptions}
                                isSearchable={!isMobile}
                                placeholder={t('invoice.pick')}
                                onChange={(e) => setFieldValue(`travellingExpenceRows.${i}.departureTime`, e.value)}
                                validate={() => validateDepartureTime(row)}
                                error={errors && errors.returnTime && validateDepartureTime(row)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                name={`travellingExpenceRows.${i}.returnTime`}
                                id={`travellingExpenceRows.${i}.returnTime`}
                                label={t('invoice.returnTime')+' *'}
                                component={DropDown}
                                options={timeOptions}
                                isSearchable={!isMobile}
                                placeholder={t('invoice.pick')}
                                onChange={(e) => setFieldValue(`travellingExpenceRows.${i}.returnTime`, e.value)}
                                validate={() => validateReturnTime(row)}
                                error={errors && errors.returnTime && validateReturnTime(row)}
                              />
                            </Grid>
                            {!oldInvoiceTravelType2 && <Grid item xs={12} sm={4}>
                              <Field
                                name={`travellingExpenceRows.${i}.purpose`}
                                id={`travellingExpenceRows.${i}.purpose`}
                                label={t('invoice.purpose')}
                                type='text'
                                options={purposeOptions}
                                isSearchable={!isMobile}
                                defaultValue={purposeOptions ? purposeOptions[0] : ''}
                                component={DropDown}
                                placeholder={t('invoice.pick')}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.purpose`, e.value)
                                }}
                              />
                            </Grid>}
                            {!oldInvoiceTravelType2 && row.purpose === 'OTHER' && <Grid item xs={12} sm={8}>
                              <Field
                                name={`travellingExpenceRows.${i}.purposeNotes`}
                                id={`travellingExpenceRows.${i}.purposeNotes`}
                                label={t('invoice.purposeNotes')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].purposeNotes}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.purposeNotes`, e.target.value)
                                }}
                                validate={() => validateTextArea(row.purposeNotes, t('invoice.purposeNotes'), 5000, row.purpose === 'OTHER')}
                                error={errors && errors.purposeNotes && validateTextArea(row.purposeNotes, t('invoice.purposeNotes'), 5000, row.purpose === 'OTHER')}
                              />
                            </Grid>}
                            {oldInvoiceGoogleMaps
                              ?
                                <Grid item xs={12} sm={12}>
                                  <Field
                                    name={`travellingExpenceRows.${i}.route`}
                                    id={`travellingExpenceRows.${i}.route`}
                                    label={t('invoice.route')+' *'}
                                    type='text'
                                    component={Input}
                                    value={travellingExpenceRows[i].route || ''}
                                    onChange={(e) => {
                                      setFieldValue(`travellingExpenceRows.${i}.route`, e.target.value)
                                    }}
                                    validate={() => validateTextArea(row.route, t('invoice.route'), 5000, true)}
                                    error={errors && errors.route && validateTextArea(row.route, t('invoice.route'), 5000, true)}
                                  />
                                </Grid>
                              :
                                <>
                                  {row.travelRowDestinations && row.travelRowDestinations.length > 0 &&
                                    <TravelDestinations
                                      setFieldValue={setFieldValue}
                                      arrayHelpers={arrayHelpers}
                                      travellingExpenceRows={travellingExpenceRows}
                                      isAdmin={isAdmin}
                                      i={i}
                                      handleAddDestination={handleAddDestination}
                                      handleDeleteDestination={handleDeleteDestination}
                                      allowNegative={allowNegative}
                                      calculateDistance={calculateDistance}
                                      showKmInfoDialog={showKmInfoDialog}
                                      setShowKmInfoDialog={setShowKmInfoDialog}
                                      setFieldError={setFieldError}
                                    />
                                  }
                                </>
                            }
                            <Grid item xs={12} sm={isAdmin ? 3 : 4}>
                              <Field
                                name={`travellingExpenceRows.${i}.km`}
                                id={`travellingExpenceRows.${i}.km`}
                                label={t('invoice.km')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].km}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.km`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], km: e.target.value }))
                                }}
                                disabled={!oldInvoiceGoogleMaps}
                                validate={() => (oldInvoiceGoogleMaps && (!isAdmin ? validateIntBetween(row.km, 0, 9999, t('invoice.km'), false) : validateFloatBetween(row.km, (allowNegative ? -9999 : 0), 9999, t('invoice.km'), false, 0))) || checkTravelRowErrors2(row)}
                                error={errors && errors.km && ((oldInvoiceGoogleMaps && (!isAdmin ? validateIntBetween(row.km, 0, 9999, t('invoice.km'), false) : validateFloatBetween(row.km, (allowNegative ? -9999 : 0), 9999, t('invoice.km'), false, 0))) || checkTravelRowErrors2(row))}
                              />
                            </Grid>
                            <Grid item xs={12} sm={isAdmin ? 3 : 4}>
                              <Field
                                name={`travellingExpenceRows.${i}.vehicle`}
                                id={`travellingExpenceRows.${i}.vehicle`}
                                label={t('invoice.vehicle')}
                                type='text'
                                options={vehicleOptions}
                                isSearchable={!isMobile}
                                defaultValue={vehicleOptions ? vehicleOptions[0] : ''}
                                component={DropDown}
                                placeholder={t('invoice.pick')}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.vehicle`, e.value)
                                  setFieldValue(`travellingExpenceRows.${i}.mileage`, travellingExpenceRows[i].allowances.vehicle[e.value])
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], mileage: travellingExpenceRows[i].allowances.vehicle[e.value] }))
                                }}
                              />
                            </Grid>
                            {isAdmin && <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.mileage`}
                                id={`travellingExpenceRows.${i}.mileage`}
                                label={t('invoice.mileage')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].mileage}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.mileage`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], mileage: e.target.value }))
                                }}
                                validate={() => validateIntBetween(row.mileage, 0, 99, t('invoice.mileage'), parseInt(row.km) > 0)}
                                error={errors && errors.mileage && validateIntBetween(row.mileage, 0, 99, t('invoice.mileage'), row.km)}
                              />
                            </Grid>}
                            <Grid item xs={12} sm={isAdmin ? 3 : 4}>
                              <Field
                                name={`travellingExpenceRows.${i}.registerNumber`}
                                id={`travellingExpenceRows.${i}.registerNumber`}
                                label={t('invoice.registerNumber')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].registerNumber}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.registerNumber`, e.target.value)
                                }}
                                validate={() => validateTextArea(row.registerNumber, t('invoice.registerNumber'), 10, false)}
                                error={errors && errors.registerNumber && validateTextArea(row.registerNumber, t('invoice.registerNumber'), 10, false)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.dailyAllowances`}
                                id={`travellingExpenceRows.${i}.dailyAllowances`}
                                label={t('invoice.dailyAllowance')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].dailyAllowances}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.dailyAllowances`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({...travellingExpenceRows[i], dailyAllowances: e.target.value}))
                                }}
                                validate={() => (!isAdmin || row.country === 'FINLAND' ? validateIntBetween(row.dailyAllowances, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.dailyAllowances'), false) : validateFloatBetween(row.dailyAllowances, (allowNegative ? -99 : 0), 99, t('invoice.dailyAllowances'), false, 0)) || checkTravelRowErrors2(row)}
                                error={errors && errors.dailyAllowances && ((!isAdmin || row.country === 'FINLAND' ? validateIntBetween(row.dailyAllowances, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.dailyAllowances'), false) : validateFloatBetween(row.dailyAllowances, (allowNegative ? -99 : 0), 99, t('invoice.dailyAllowances'), false, 0)) || checkTravelRowErrors2(row))}
                              />
                            </Grid>
                            {isAdmin && <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.daAmount`}
                                id={`travellingExpenceRows.${i}.daAmount`}
                                label={t('invoice.daAmount')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].daAmount}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.daAmount`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], daAmount: e.target.value }))
                                }}
                                validate={() => validateFloatGreaterThan(row.daAmount, 0, 999.99, t('invoice.daAmount'), 2)}
                                error={errors && errors.daAmount && validateFloatGreaterThan(row.daAmount, 0, 999.99, t('invoice.daAmount'), 2)}
                              />
                            </Grid>}
                            <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.partTimeDailyAllowances`}
                                id={`travellingExpenceRows.${i}.partTimeDailyAllowances`}
                                label={t('invoice.dailyPartAllowance')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].partTimeDailyAllowances}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.partTimeDailyAllowances`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], partTimeDailyAllowances: e.target.value }))
                                }}
                                validate={() => validateIntBetween(row.partTimeDailyAllowances, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.partTimeDailyAllowances'), false) || checkTravelRowErrors2(row)}
                                error={errors && errors.partTimeDailyAllowances && (validateIntBetween(row.partTimeDailyAllowances, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.partTimeDailyAllowances'), false) || checkTravelRowErrors2(row))}
                              />
                            </Grid>
                            {isAdmin && <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.ptdaAmount`}
                                id={`travellingExpenceRows.${i}.ptdaAmount`}
                                label={t('invoice.ptdaAmount')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].ptdaAmount}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.ptdaAmount`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], ptdaAmount: e.target.value }))
                                }}
                                validate={() => validateFloatGreaterThan(row.ptdaAmount, 0, 999.99, t('invoice.ptdaAmount'), 2)}
                                error={errors && errors.ptdaAmount && validateFloatGreaterThan(row.ptdaAmount, 0, 999.99, t('invoice.ptdaAmount'), 2)}
                              />
                            </Grid>}
                            {row.travelType === 'ERITYISALA' && <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.mealExpences`}
                                id={`travellingExpenceRows.${i}.mealExpences`}
                                label={t('invoice.mealExpences')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].mealExpences}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.mealExpences`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], mealExpences: e.target.value }))
                                }}
                                validate={() => validateIntBetween(row.mealExpences, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.mealExpences'), false) || checkTravelRowErrors2(row)}
                                error={errors && errors.mealExpences && (validateIntBetween(row.mealExpences, (isAdmin && allowNegative ? -99 : 0), 99, t('invoice.mealExpences'), false) || checkTravelRowErrors2(row))}
                              />
                            </Grid>}
                            {isAdmin && row.travelType === 'ERITYISALA' && <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.meAmount`}
                                id={`travellingExpenceRows.${i}.meAmount`}
                                label={t('invoice.meAmount')}
                                type='text'
                                component={Input}
                                value={travellingExpenceRows[i].meAmount}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.meAmount`, e.target.value)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({...travellingExpenceRows[i], meAmount: e.target.value}))
                                }}
                                validate={() => validateFloatGreaterThan(row.meAmount, 0, 999.99, t('invoice.meAmount'), 2)}
                                error={errors && errors.meAmount && validateFloatGreaterThan(row.meAmount, 0, 999.99, t('invoice.meAmount'), 2)}
                              />
                            </Grid>}
                            <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.country`}
                                id={`travellingExpenceRows.${i}.country`}
                                label={t('invoice.country')+' *'}
                                options={internationalAllowanceOptions}
                                placeholder={t('invoice.pick')}
                                defaultValue={internationalAllowanceOptions ? internationalAllowanceOptions[0] : ''}
                                onChange={(e) => {
                                  setFieldValue(`travellingExpenceRows.${i}.country`, e.value)
                                  const daAmount = travellingExpenceRows[i].allowances.dailyAllowance[e.value]
                                  const meAmount = travellingExpenceRows[i].allowances.mealExpence

                                  setFieldValue(`travellingExpenceRows.${i}.daAmount`, daAmount)
                                  let ptdaAmount = null
                                  if (e.value === 'FINLAND') {
                                    const changedValue = parseInt(travellingExpenceRows[i].dailyAllowances)
                                    ptdaAmount = travellingExpenceRows[i].allowances.partTimeDailyAllowance
                                    setFieldValue(`travellingExpenceRows.${i}.dailyAllowances`, changedValue)
                                  } else {
                                    ptdaAmount = roundNumber(travellingExpenceRows[i].allowances.dailyAllowance[e.value] / 2, 2)
                                  }
                                  setFieldValue(`travellingExpenceRows.${i}.ptdaAmount`, ptdaAmount)
                                  setFieldValue(`travellingExpenceRows.${i}.meAmount`, meAmount)
                                  setFieldValue(`travellingExpenceRows.${i}.total`, totalTravellingExpenceRow({ ...travellingExpenceRows[i], daAmount: daAmount, ptdaAmount: ptdaAmount, meAmount: meAmount }))
                                }}
                                component={DropDown}
                                isSearchable={!isMobile}
                              />
                            </Grid>
                            {isAdmin && !exp && <Grid item xs={12} sm={3}>
                              <Field
                                name={`travellingExpenceRows.${i}.vat`}
                                id={`travellingExpenceRows.${i}.vat`}
                                label={t('invoice.vat')}
                                type='text'
                                options={vatOptions}
                                component={DropDown}
                                isSearchable={false}
                                onChange={(e) => setFieldValue(`travellingExpenceRows.${i}.vat`, e.value)}
                              />
                            </Grid>}
                          </>
                       : null}
                      </Grid>
                    </HanuriBody>
                  </Hanuri>
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}

export default withSnackbar(TravellingExpenceRow)
