export const acceptedFileTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png"/*,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.oasis.opendocument.text",
    "text/plain",
    "application/vnd.apple.numbers",
    "application/zip",
    "image/tiff",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/msword",
    "text/html",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/rtf",*/
]

export const acceptedPromoTypes = [
    "image/jpeg",
    "image/png"
]

export const acceptedTaxcardFileTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png"
]