import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import { useStateValue, setCurrentUser, setClients, setInvoices, setExpences, setReferences, setWorklists, setTaxcards } from "../state"
import { withSnackbar } from 'notistack'
import { cLog } from '../utils'
import { useTranslation } from 'react-i18next'
import { oidcSettings } from '../oidcsettings'
import { logOut } from '../service'
import Button from './Button'

function ConfirmAccountDialog({ open, setOpenConfirmAccountDialog, enqueueSnackbar }) {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const isProductionMode = process.env.REACT_APP_CUSTOM_NODE_ENV === 'PRODUCTION'
  const [{ currentUser }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'

  const clearState = () => {
    dispatch(setClients([]))
    dispatch(setInvoices([]))
    dispatch(setExpences([]))
    dispatch(setReferences([]))
    dispatch(setWorklists([]))
    if (isAdmin) {
      dispatch(setTaxcards([]))
    }
  }

  const handleConfirmAccount = async () => {
    return window.location.replace(`${isProductionMode ? oidcSettings.authority : oidcSettings.authority_dev}/authorization?response_type=${oidcSettings.response_type}&scope=${oidcSettings.scope}&client_id=${isProductionMode ? oidcSettings.client_id : oidcSettings.client_id_dev}&redirect_uri=${isProductionMode ? oidcSettings.redirect_uri : oidcSettings.redirect_uri_dev}&state=${oidcSettings.state_confirm_ssn}`)
  }

  const handleCloseAndLogout = async () => {
    cLog('LOGGING OUT!')
    clearState()
    setOpenConfirmAccountDialog(false)
    dispatch(setCurrentUser(null))
    logOut()
  }

  return (
    <Dialog
      scroll='paper'
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="confirm-account-dialog-title"
      aria-describedby="confirm-account-dialog-description"
    >
      <DialogTitle id="confirm-account-dialog-title">{t('auth.confirmAccountTitle')}</DialogTitle>
      <DialogContent dividers={true} id="confirm-account-dialog-description">
        {t('auth.confirmAccountLabel')}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleCloseAndLogout}
          color="secondary"
          disabled={isLoading}
        >
          {t('auth.logOut')}
        </Button>
        <Button
          variant="text"
          onClick={handleConfirmAccount}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('auth.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(ConfirmAccountDialog)
