import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core'
import CsvDropZone from './CsvDropzone'
import { withSnackbar } from 'notistack'
import DropDown from './DropDown'
import { useTranslation } from 'react-i18next'
import { csvUpload } from '../service'
import { cLog } from '../utils'
import Button from './Button'

const INIT_FORM = {
  fileType: '',
  file: null
}

const CsvDialog = ({ open, setOpenCsvDialog, enqueueSnackbar, values, setFieldValue, user }) => {
  const { t } = useTranslation()
  const [form, setForm] = useState(INIT_FORM)
  const [isLoading, setLoading] = useState(false)
  const fileTypeOptions = ['User', 'Invoice'].map(role => ({ label: t('user.'+role), value: role }))

  const handleSendCsv = async () => {
    if (!form.fileType) {
      enqueueSnackbar(
        t('user.noFileType'),
        { variant: 'error' }
      )
      return
    }

    if (!form.file) {
      enqueueSnackbar(
        t('user.noFile'),
        { variant: 'error' }
      )
      return
    }

    setLoading(true)
    const sendCsv = csvUpload(form)
    sendCsv.then(response => {
      cLog('CSV IMPORT', response)
      setForm(INIT_FORM)
      setLoading(false)
      setOpenCsvDialog(false)
      enqueueSnackbar(
        t('user.csvSent'),
        { variant: 'success' }
      )
    }, error => {
      setLoading(false)
      cLog('CSV IMPORT ERROR', error.response.data.errors)
      for(var i = 0; i < error.response.data.errors.length; i++) {
        enqueueSnackbar(
          error.response.data.errors[i],
          { variant: 'error' }
        )
      }
    })
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setLoading(false)
          setForm(INIT_FORM)
          setOpenCsvDialog(false)
        }
      }}
      disableEscapeKeyDown={true}
      aria-labelledby="csv-dialog-title"
      aria-describedby="csv-dialog-description"
    >
      <DialogTitle id="csv-dialog-title">{t('user.uploadCsv')}</DialogTitle>
      <DialogContent dividers={true} id="csv-dialog-description">
        <DropDown
          id='fileType'
          label={t('user.fileType')}
          placeholder={t('user.pick')}
          value={fileTypeOptions.find(option => option.value === form.fileType)}
          options={fileTypeOptions}
          onChange={(e) => {
            setForm({ ...form, fileType: e.value })
          }}
        />
        <br />
        <span>{t('user.csvDropzoneText')}</span>
        <CsvDropZone
          setForm={setForm}
          form={form}
          style={{ width: '100%', height: '100px' }}
        />
        {form.file && <span style={{ display: 'inline-block', marginTop: '15px' }}>{form.file.filename}</span>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setForm(INIT_FORM)
            setOpenCsvDialog(false)
          }}
          color="secondary"
          disabled={isLoading}
        >
         {t('user.cancel')}
        </Button>
        <Button
          variant="text"
          onClick={handleSendCsv}
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={20} /> : t('user.saveCsv')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(CsvDialog)
