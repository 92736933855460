import React, { useState } from 'react'
import { useStateValue, removeInvoice, reviseInvoice, reviseExpence, removeExpence } from "../../state"
import { deleteInvoice, updateInvoice, updateExpence, deleteExpence, getInvoice, getExpence, sendSalaryRequest, sendCreateConsolidatedInvoiceRequest, sendCreateCreditNoteRequest, sendExpenseRequest, printSalaryPdf, setInvoiceAccounted } from '../../service'
import { useHistory, Link } from "react-router-dom"
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Toolbar, Typography, Checkbox, Tooltip, IconButton, useMediaQuery } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ReplayIcon from '@material-ui/icons/Replay'
//import ReceiptIcon from '@material-ui/icons/Receipt'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import MergeIcon from '@material-ui/icons/CallMerge'
import { withSnackbar } from 'notistack'
import Alert from '../../components/Alert'
import { COLORS, responsiveTableStyles } from '../../styles'
import Button from '../../components/Button'
import { formatDatetime, formatTimeFI, camelToSnakeCase, roundNumber, notifyOffice } from '../../utils'
import DoubleLink from '../../components/DoubleLink'
import { cLog } from '../../utils'
import StatusCard from '../../components/StatusCard'
import { useTranslation } from 'react-i18next'
//import SalaryCalculatorOfficeDialog from '../../components/SalaryCalculatorOfficeDialog'
import InvoicePaymentDialog from '../../components/InvoicePaymentDialog'
//import { INDUSTRIES } from '../../utils/invoiceUtils'
import { Formik } from "formik"
import EuroIcon from '@material-ui/icons/Euro'
import parse from 'html-react-parser'

function EnhancedTableHead(props) {
  const { t } = useTranslation()
  const { onSelectAllClick, numSelected, rowCount, isExp, isAdmin, classes, onRequestSort, sortKey, sortOrder } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headCells = []

  if (!isExp) {
    if (isAdmin) {
      headCells.push(
        { id: 'invoiceNr', label: t('invoice.invoiceNr') },
        { id: 'createdAt', label: t('invoice.createdAt') },
        { id: 'updatedAt', label: t('invoice.updatedAt') },
        { id: 'updater', label: t('invoice.updater') },
        { id: 'date', label: t('invoice.date') },
        { id: 'dateDue', label: t('invoice.dateDue') },
        { id: 'customer', label: t('invoice.customer') },
        { id: 'totalAddress', label: t('invoice.totalAddress') },
        { id: 'email', label: t('invoice.email') },
        { id: 'phone', label: t('invoice.phone') },
        { id: 'isPrivate', label: t('invoice.isPrivate') },
        { id: 'businessId', label: t('invoice.businessId') },
        { id: 'contactPerson', label: t('invoice.contactPerson') },
        { id: 'deliveryMethod', label: t('invoice.deliveryMethod') },
        { id: 'reminderDate', label: t('invoice.reminderDate') },
        { id: 'reminderNotes', label: t('invoice.reminderNotes') },
        { id: 'reminderHandling', label: t('invoice.reminder') },
        { id: 'language', label: t('invoice.language') },
        { id: 'einvoiceOperator', label: t('invoice.einvoiceOperator') },
        { id: 'einvoiceAddress', label: t('invoice.einvoiceAddress') },
        { id: 'worker', label: t('invoice.worker') },
        { id: 'workPeriod', label: t('invoice.workPeriod') },
        { id: 'timeFrame', label: t('invoice.timeFrame') },
        { id: 'commission', numeric: true, label: t('invoice.commission') },
        { id: 'yel', label: t('invoice.yel') },
        { id: 'invoiceTotal', numeric: true, label: t('invoice.invoiceTotal') },
        { id: 'travelTotal', numeric: true, label: t('invoice.travelTotal') },
        { id: 'origTotalVatExcluded', numeric: true, label: t('invoice.origTotalVatExcluded') },
        { id: 'tax', numeric: true, label: t('invoice.tax') },
        { id: 'totalWithTax', numeric: true, label: t('invoice.totalWithTax') },
        { id: 'totalCorrections', numeric: true, label: t('invoice.totalCorrections') },
        { id: 'totalCorrectionsVat', numeric: true, label: t('invoice.totalCorrectionsVat') },
        { id: 'totalCorrectionsWithVat', numeric: true, label: t('invoice.totalCorrectionsWithVat') },
        { id: 'origTotalVatExcludedEur', numeric: true, label: t('invoice.origTotalVatExcludedEur') },
        { id: 'taxEur', numeric: true, label: t('invoice.taxEur') },
        { id: 'totalWithTaxEur', numeric: true, label: t('invoice.totalWithTaxEur') },
        { id: 'totalCorrectedEur', numeric: true, label: t('invoice.totalCorrectedEur') },
        { id: 'totalCorrectedVatEur', numeric: true, label: t('invoice.totalCorrectedVatEur') },
        { id: 'totalCorrectedWithVatEur', numeric: true, label: t('invoice.totalCorrectedWithVatEur') },
        { id: 'salariesPayableAmount', numeric: true, label: t('invoice.salariesPayableAmount') },
        { id: 'currency', label: t('invoice.currency') },
        { id: 'refText', label: t('invoice.refText') },
        { id: 'refCode', label: t('invoice.refCode') },
        { id: 'vatStatus', label: t('invoice.vatStatus') },
        { id: 'totalPaymentsWithTax', numeric: true, label: t('invoice.totalPaymentsWithTax') },
        { id: 'payDay', label: t('invoice.payDay') },
        { id: 'status', label: t('invoice.status') },
        { id: 'totalAccounting', numeric: true, label: t('invoice.totalAccounting') },
        { id: 'accountingStatus', label: t('invoice.accountingStatus') },
        { id: 'info', label: t('invoice.info') },
        { id: 'adminInfo', label: t('invoice.adminInfo') },
        { id: 'internalNotes', label: t('invoice.internalNotes') },
        { id: 'industry', label: t('invoice.industry') },
        { id: 'customIndustry', label: t('invoice.customIndustry') },
        { id: 'consolidatedInvoice', label: t('invoice.consolidatedInvoice') },
        { id: 'belongsToConsolidatedInvoice', label: t('invoice.belongsToConsolidatedInvoice') },
        { id: 'creditNote', label: t('invoice.creditNote') },
        { id: 'belongsToCreditNote', label: t('invoice.belongsToCreditNote') },
        { id: 'salaryData', label: t('salary.salary') },
        { id: 'statusTarkastettavanaDate', label: t('invoice.statusTarkastettavanaDate') },
        { id: 'statusLahetettyDate', label: t('invoice.statusLahetettyDate') },
        { id: 'statusOsittainMaksettuDate', label: t('invoice.statusOsittainMaksettuDate') },
        { id: 'statusMaksettuDate', label: t('invoice.statusMaksettuDate') },
        //{ id: 'statusMaksettuLiikaaDate', label: t('invoice.statusMaksettuLiikaaDate') },
        { id: 'statusPoistettuDate', label: t('invoice.statusPoistettuDate') },
        //{ id: 'statusPerinnassaDate', label: t('invoice.statusPerinnassaDate') },
        //{ id: 'statusUlosotossaDate', label: t('invoice.statusUlosotossaDate') },
        { id: 'statusLuottotappioDate', label: t('invoice.statusLuottotappioDate') },
        //{ id: 'statusMuistutettuDate', label: t('invoice.statusMuistutettuDate') },
        { id: 'statusHyvitettyDate', label: t('invoice.statusHyvitettyDate') },
        { id: 'statusHyvityslaskuDate', label: t('invoice.statusHyvityslaskuDate') },
        { id: 'accountingStatusOsittainTilitettyDate', label: t('invoice.accountingStatusOsittainTilitettyDate') },
        { id: 'accountingStatusTilitettyDate', label: t('invoice.accountingStatusTilitettyDate') },
        { id: 'actions', label: t('invoice.actions') }
      )
    } else {
      headCells.push(
        { id: 'invoiceNr', label: t('invoice.invoiceNr') },
        { id: 'date', label: t('invoice.date') },
        { id: 'dateDue', label: t('invoice.dateDue') },
        { id: 'customer', label: t('invoice.customer') },
        { id: 'totalCorrectedEur', numeric: true, label: t('invoice.totalWithoutVat') },
        { id: 'payDay', label: t('invoice.payDay') },
        { id: 'status', label: t('invoice.status') },
        { id: 'accountingStatus', label: t('invoice.accountingStatus') },
        { id: 'actions', label: t('invoice.actions') }
      )
    }
  } else {
    if (isAdmin) {
      headCells.push(
        { id: 'expenceNr', label: t('invoice.expenceNr') },
        { id: 'createdAt', label: t('invoice.createdAt') },
        { id: 'updatedAt', label: t('invoice.updatedAt') },
        { id: 'updater', label: t('invoice.updater') },
        { id: 'date', label: t('invoice.date') },
        { id: 'worker', label: t('invoice.worker') },
        { id: 'expenceType', label: t('invoice.expenceType') },
        { id: 'timeFrame', label: t('invoice.timeFrame') },
        { id: 'expenceTotal', numeric: true, label: t('invoice.expenceTotal') },
        { id: 'travelTotal', numeric: true, label: t('invoice.travelTotal') },
        { id: 'totalWithTax', numeric: true, label: t('invoice.totalWithTax') },
        { id: 'status', label: t('invoice.expenseStatus') },
        { id: 'info', label: t('invoice.info') },
        { id: 'adminInfo', label: t('invoice.adminInfo') },
        { id: 'internalNotes', label: t('invoice.internalNotes') },
        { id: 'refCode', label: t('invoice.refCode') },
        { id: 'isCopy', label: t('invoice.isCopy') },
        { id: 'totalAccounting', label: t('invoice.totalAccounting') },
        { id: 'salariesPayableAmount', numeric: true, label: t('invoice.salariesPayableAmount') },
        { id: 'statusValmisDate', label: t('invoice.statusValmisDate') },
        { id: 'statusOsittainMaksettuDate', label: t('invoice.statusOsittainMaksettuDate') },
        { id: 'statusMaksettuDate', label: t('invoice.statusMaksettuDate') },
        { id: 'statusHylattyDate', label: t('invoice.statusHylattyDate') },
        { id: 'statusPoistettuDate', label: t('invoice.statusPoistettuDate') },
        { id: 'sentDate', label: t('invoice.sentDate') },
        { id: 'actions', label: t('invoice.actions') }
      )
    } else {
      headCells.push(
        { id: 'expenceNr', label: t('invoice.expenceNr') },
        { id: 'date', label: t('invoice.date') },
        { id: 'totalWithTax', numeric: true, label: t('invoice.totalWithTax') },
        { id: 'status', label: t('invoice.status') },
        { id: 'actions', label: t('invoice.actions') }
      )
    }
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'choose all' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          props.selectedColumns.includes(headCell.id) && <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={sortKey === camelToSnakeCase(headCell.id) && !['work_period', 'time_frame', 'expence_type', 'commission', 'belongs_to_credit_note', 'salary_data', 'actions'].includes(camelToSnakeCase(headCell.id)) ? sortOrder : false}
          >
            {!['work_period', 'time_frame', 'expence_type', 'commission', 'belongs_to_credit_note', 'salary_data', 'actions'].includes(camelToSnakeCase(headCell.id))
              ? <TableSortLabel
                  active={(sortKey === camelToSnakeCase(headCell.id)) || (sortKey === 'name' && headCell.id === 'customer') || (sortKey === 'user_id' && headCell.id === 'worker') || (sortKey === 'consolidated_invoice_id' && headCell.id === 'belongsToConsolidatedInvoice')}
                  direction={sortOrder}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {sortKey === camelToSnakeCase(headCell.id) ? (
                    <span className={classes.visuallyHidden}>
                      {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              : <div>
                  {headCell.label}
                </div>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  sortKey: PropTypes.string.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: COLORS.primary,
        backgroundColor: 'white'
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
    fontFamily: 'inherit',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
}))

const EnhancedTableToolbar = (props) => {
  const { t } = useTranslation()
  const classes = useToolbarStyles()
  const { getInvoiceById, selected, numSelected, tableTitle, isAdmin, actionOptions, editOldInvoice, recycleInvoice, recycleExpense, deleteSelectedInvoices, createConsolidatedInvoice, commissions, /*setOpenSalaryCalculatorOfficeDialog, */payList, landingPage, checkSelected, createCreditNote, setAsAccounted, isExp } = props

  return (
    <Toolbar
      className={numSelected > 0 ? classes.highlight : ''}
    >
      {actionOptions.length > 0 && numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} {t('invoice.picked')}
        </Typography>
      ) : (
        <div>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {tableTitle}
          </Typography>
          {commissions ? <Typography>{t('invoice.commissionsTotal')} {roundNumber(commissions, 2).toString().replace('.', ',')} EUR</Typography> : null}
          </div>
        )}

      {actionOptions.length > 0 && numSelected > 0 ? (
        <div className={classes.actionBar}>
          <div style={{ display: 'flex' }}>
            {numSelected === 1 && (isAdmin || (selected && getInvoiceById(selected[0]).status === 'LUONNOS')) &&
              <Tooltip title={t('invoice.edit')}>
                <IconButton aria-label="edit" onClick={() => editOldInvoice()}>
                  <EditIcon />
                </IconButton>
              </Tooltip>}
            {numSelected === 1 && isAdmin && selected && getInvoiceById(selected[0]).status === 'LÄHETETTY' && !getInvoiceById(selected[0]).exp && getInvoiceById(selected[0]).creditNote === false && (getInvoiceById(selected[0]).creditNoteInvoicesData === undefined || getInvoiceById(selected[0]).creditNoteInvoicesData === null || getInvoiceById(selected[0]).creditNoteInvoicesData.length === 0) &&
              <Tooltip title={t('invoice.createCreditNoteTip')}>
                <IconButton aria-label="creditNote" onClick={() => createCreditNote()}>
                  <AccountBalanceIcon />
                </IconButton>
              </Tooltip>
            }
            {isAdmin && numSelected === 1 && selected && getInvoiceById(selected[0]).exp && !getInvoiceById(selected[0]).originalExpenceId &&
              <Tooltip title={t('invoice.copyAsExpenseTip')}>
                <IconButton aria-label="recycle" onClick={() => recycleExpense()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            }
            {isAdmin && numSelected === 1 &&
              <Tooltip title={t('invoice.copyAsBaseTip')}>
                <IconButton aria-label="recycle" onClick={() => recycleInvoice()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            }
            {(numSelected === 1 && (isAdmin || (selected && !selected.map(selection => getInvoiceById(selection)).find(invoice => invoice.status !== 'LUONNOS')))) &&
              <Tooltip title={t('invoice.remove')}>
                <IconButton aria-label="delete" onClick={() => deleteSelectedInvoices()}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            {/*isAdmin && selected && !selected.map(selection => getInvoiceById(selection)).find(invoice => invoice.status !== 'LUONNOS') && payList &&
              <Tooltip title={t('invoice.calculateSalary')}>
                <IconButton aria-label="calculateSalary" onClick={() => setOpenSalaryCalculatorOfficeDialog(true)}>
                  <ReceiptIcon />
                </IconButton>
              </Tooltip>*/}
            {isAdmin && landingPage && numSelected > 1 && selected && checkSelected(selected) &&
              <Tooltip title={t('invoice.createConsolidatedInvoice')}>
                <IconButton aria-label="createConsolidatedInvoice" onClick={() => createConsolidatedInvoice()}>
                  <MergeIcon />
                </IconButton>
              </Tooltip>
            }
            {!isExp && isAdmin && payList && numSelected > 1 &&
              <Tooltip title={t('user.setAsAccounted')}>
                <IconButton aria-label="setAsAccounted" onClick={() => setAsAccounted()}>
                  <EuroIcon />
                </IconButton>
              </Tooltip>}
          </div>
        </div>
      ) : (<div></div>
        )}
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'calc(100vh - 160px)',
      overflowX: 'hidden',
    },
  },
  table: {
    ...responsiveTableStyles,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  spanLink: {
    cursor: 'pointer',
    color:' blue',
    textDecoration: 'underline'
  }
}))

const InvoiceTable = ({ isExp, invoices, enqueueSnackbar, tableTitle, landingPage, checked, fetchData, children, payList, commissions, user, selectedColumns, selected, setSelected, sortKey, setSortKey, sortOrder, setSortOrder }) => {
  const { t } = useTranslation()
  const [{ currentUser }, dispatch] = useStateValue()
  const isAdmin = currentUser && currentUser.role === 'ADMIN'
  const [openConfirmDeleteInvoiceModal, setOpenConfirmDeleteInvoiceModal] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [actionSelectValue, setActionSelectValue] = useState(null)
  const [isLoading, setLoading] = useState(false)
  let history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  //const [openSalaryCalculatorOfficeDialog, setOpenSalaryCalculatorOfficeDialog] = useState(false)
  const [openInvoicePaymentDialog, setOpenInvoicePaymentDialog] = useState(false)
  const [paymentInvoice, setPaymentInvoice] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [openConfirmExpressPaymentModal, setOpenConfirmExpressPaymentModal] = useState(false)

  /*const SALARY_CALCULATOR_OFFICE_TEMPLATE = {
    taxPercentage: user && user.taxcards.length > 0 && user.taxcards[0].base ? user.taxcards[0].base : 0,
    accidentInsurancePercentage: INDUSTRIES.get(user && user.industry),
    serviceFeePercentage: (user ? user.commission : 0) * 1 / 100,
    healthInsurancePercentage: 1.340,
    distraint: 0,
    invoiceAmount: 0,
    noHealthInsurance: false,
    instantSalary: false,
    kmVatExcluded: 0,
    otherVatExcluded: 0,
    dailyAllowancesVatExcluded: 0,
    partTimeDailyAllowancesVatExcluded: 0,
    mealExpensesVatExcluded: 0,
    expensesVatExcluded: 0,
    kmWithVat: 0,
    otherWithVat: 0,
    dailyAllowancesWithVat: 0,
    partTimeDailyAllowancesWithVat: 0,
    mealExpensesWithVat: 0,
    multipleInvoices: false,
    consolidatedInvoice: false,
    industry: '',
    grossIncome: 0,
    travelsAndExpenses: 0,
    travelTotalWithTax: 0,
    withholding: 0,
    netIncome: 0,
    serviceFee: 0,
    accidentInsurance: 0,
    accounting: 0,
    netIncomeWithDistraint: 0,
    totalDeductions: 0,
    accountingWithDistraint: 0,
    instantSalaryFee: 0,
    totalInsurance: 0,
    healthInsurance: 0,
    setAsAccounted: false,
    invIds: []
  }*/

  const PAYMENT_TEMPLATE = {
    valDt: new Date().toISOString().split('T')[0],
    txAmt: '',
    notes: '',
    acctSvcrRef: '',
    noAcctSvcrRef: false
  }

  const rows = invoices.map(invoice => {
    const userName = `${invoice.userFirstName || 'N.'} ${invoice.userLastName || 'N.'}`
    const worker = { id: invoice.userId, userName }
    let timeFrame = t('invoice.noTravels')
    if (invoice.earliestDepartureDate && invoice.earliestDepartureDate) {
      timeFrame = (invoice.earliestDepartureDate ? formatTimeFI(invoice.earliestDepartureDate) : '?') + ' - ' + (invoice.latestReturnDate ? formatTimeFI(invoice.latestReturnDate) : '?')
    }
    if (!isExp) {
      const clientName = (invoice.client && invoice.client.name) || '-'
      let totalAddress = ''
      if (invoice.client) {
        totalAddress = invoice.client.address1 || ''
        if (invoice.client.address2 != null && invoice.client.address2.length > 0) {
          if (totalAddress != null && totalAddress.length > 0) {
            totalAddress += '\n'
          }
          totalAddress += invoice.client.address2
        }
        if (invoice.client.postalCode != null && invoice.client.postalCode.length > 0) {
          if (totalAddress != null && totalAddress.length > 0) {
            totalAddress += '\n'
          }
          totalAddress += invoice.client.postalCode
        }
        if (invoice.client.city != null && invoice.client.city.length > 0) {
          if (invoice.client.postalCode != null && invoice.client.postalCode.length > 0) {
            totalAddress += ' '
          } else if (totalAddress != null && totalAddress.length > 0) {
            totalAddress += '\n'
          }
          totalAddress += invoice.client.city
        }
        if (invoice.client.country != null && invoice.client.country !== 'FINLAND') {
          if (totalAddress != null && totalAddress.length > 0) {
            totalAddress += '\n'
          }
          totalAddress += t('countries.'+invoice.client.country)
        }
      }
      const workPeriod = (invoice.dateStartWork ? formatTimeFI(invoice.dateStartWork) : '?') + ' - ' + (invoice.dateEndWork ? formatTimeFI(invoice.dateEndWork) : '?')
      const { id, createdAt, updatedAt, status, invoiceNr, date, dateDue, refText, currency, info, payDay, accountingStatus, industry, customIndustry, internalNotes, adminInfo, email, phone, isPrivate, contactPerson, businessId, language, deliveryMethod, einvoiceOperator, einvoiceAddress, reminderDate, reminderNotes, reminderHandling, yel, refCode, totalAccounting, totalPaymentsWithTax, vatStatus, commission, exp, consolidatedInvoice, consolidatedInvoiceId, consolidatedInvoiceNr, creditNote, invoiceTotal, travelTotal, tax, totalWithTax, origTotalVatExcluded, totalCorrections, totalCorrectionsVat, totalCorrectionsWithVat, origTotalVatExcludedEur, taxEur, totalWithTaxEur, totalCorrectedEur, totalCorrectedVatEur, totalCorrectedWithVatEur, salariesPayableAmount, statusTarkastettavanaDate, statusLahetettyDate, statusOsittainMaksettuDate, statusMaksettuDate, statusMaksettuLiikaaDate, statusPoistettuDate, statusPerinnassaDate, statusUlosotossaDate, statusLuottotappioDate, statusMuistutettuDate, statusHyvitettyDate, statusHyvityslaskuDate, accountingStatusOsittainTilitettyDate, accountingStatusTilitettyDate, salaryData, updater, ciData, originalInvoiceId, originalInvoiceNr, creditNoteInvoicesData, userBic } = invoice

      if (isAdmin) {
        return ({ worker, clientName, totalAddress, updater, timeFrame, workPeriod, id, createdAt, updatedAt, status, invoiceNr, date, dateDue, refText, currency, info, payDay, accountingStatus, industry, customIndustry, internalNotes, adminInfo, email, phone, isPrivate, contactPerson, businessId, language, deliveryMethod, einvoiceOperator, einvoiceAddress, reminderDate, reminderNotes, reminderHandling, yel, refCode, totalAccounting, totalPaymentsWithTax, vatStatus, commission, exp, consolidatedInvoice, consolidatedInvoiceId, consolidatedInvoiceNr, creditNote, invoiceTotal, travelTotal, tax, totalWithTax, origTotalVatExcluded, totalCorrections, totalCorrectionsVat, totalCorrectionsWithVat, origTotalVatExcludedEur, taxEur, totalWithTaxEur, totalCorrectedEur, totalCorrectedVatEur, totalCorrectedWithVatEur, salariesPayableAmount, statusTarkastettavanaDate, statusLahetettyDate, statusOsittainMaksettuDate, statusMaksettuDate, statusMaksettuLiikaaDate, statusPoistettuDate, statusPerinnassaDate, statusUlosotossaDate, statusLuottotappioDate, statusMuistutettuDate, statusHyvitettyDate, statusHyvityslaskuDate, accountingStatusOsittainTilitettyDate, accountingStatusTilitettyDate, ciData, originalInvoiceId, originalInvoiceNr, creditNoteInvoicesData, salaryData, userBic })
      } else {
        return ({ clientName, id, status, invoiceNr, date, dateDue, payDay, accountingStatus, totalCorrectedEur, exp })
      }
    } else {
      const { id, adminInfo, createdAt, date, expenceNr, expenceTotal, info, internalNotes, status, totalWithTax, travelTotal, updatedAt, exp, refCode, originalExpenceId, originalExpenceNr, totalAccounting, salariesPayableAmount, statusValmisDate, statusOsittainMaksettuDate, statusMaksettuDate, statusHylattyDate, statusPoistettuDate, sentDate, updater, expenceType } = invoice

      if (isAdmin) {
        return ({ id, worker, adminInfo, createdAt, date, expenceNr, expenceTotal, info, internalNotes, status, totalWithTax, travelTotal, updatedAt, updater, timeFrame, expenceType, exp, refCode, originalExpenceId, originalExpenceNr, totalAccounting, salariesPayableAmount, statusValmisDate, statusOsittainMaksettuDate, statusMaksettuDate, statusHylattyDate, statusPoistettuDate, sentDate })
      } else {
        return ({ id, date, expenceNr, status, totalWithTax, exp })
      }
    }
  })

  const handleRequestSort = (event, property) => {
    let key = camelToSnakeCase(property)

    if (!['work_period', 'time_frame', 'expence_type', 'commission', 'belongs_to_credit_note', 'salary_data', 'actions'].includes(key)) {
      let order = sortOrder
      if (sortKey === key || (sortKey === 'name' && key === 'customer') || (sortKey === 'user_id' && key === 'worker') || (sortKey === 'consolidated_invoice_id' && key === 'belongs_to_consolidated_invoice')) {
        order = sortOrder === 'ASC' ? 'DESC' : 'ASC'
      }
      dispatch(setSortKey(key))
      dispatch(setSortOrder(order))
      fetchData(camelToSnakeCase(property), order)
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      dispatch(setSelected(newSelecteds))
      return;
    }
    dispatch(setSelected([]))
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    dispatch(setSelected(newSelected))
  }

  const checkSelected = (selected) => {
    let businessId = ''
    let currency = ''

    for (let id of selected) {
      const invoice = getInvoiceById(id)
      if (invoice) {
        if (currency === '') {
          currency = invoice.currency
        }
        if (businessId === '') {
          businessId = invoice.businessId
        }

        if ((invoice.status !== 'LUONNOS' && invoice.status !== 'TARKASTETTAVANA') || invoice.consolidatedInvoiceData != null || currency !== invoice.currency || businessId !== invoice.businessId || invoice.isPrivate || invoice.creditNote || invoice.creditNoteInvoicesData != null) {
          return false
        }
      }
    }

    return true
  }

  const getInvoiceById = (id) => invoices.find(invoice => invoice.id === id)

  const handleAgreeDeleteInvoiceModal = async () => {
    cLog('DELETE INVOICE ' + selected)
    setOpenConfirmDeleteInvoiceModal(false)
    try {
      selected.forEach(async (id) => {
        const invoice = getInvoiceById(id)
        if (!invoice.exp) {
          await deleteInvoice(invoice)
          if(invoice.consolidatedInvoice) {
            fetchData(sortKey, sortOrder)
          } else {
            dispatch(removeInvoice(invoice))
          }
        } else {
          await deleteExpence(invoice)
          dispatch(removeExpence(invoice))
        }
        cLog('INVOICE / EXPENCE DELETED', invoice)
        enqueueSnackbar(
          t('invoice.invoiceRemoved'),
          { variant: 'success' }
        )
      })
      dispatch(setSelected([]))
      setActionSelectValue(null)
    } catch (error) {
      cLog('DELETE INVOICE ERROR', error)
      enqueueSnackbar(
        t('invoice.invoiceRemovalError'),
        { variant: 'success' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  const handleCloseDeleteInvoiceModal = () => {
    setOpenConfirmDeleteInvoiceModal(false)
    setActionSelectValue(null)
  }

  const editOldInvoice = () => {
    editInvoice(getInvoiceById(selected[0]))
  }

  const editInvoice = invoice => {
    dispatch(setSelected([]))
    history.push(invoice.exp ? '/kulut/muokkaa/' + invoice.id : '/laskut/muokkaa/' + invoice.id)
  }

  const recycleInvoice = () => {
    dispatch(setSelected([]))
    history.push(`/${getInvoiceById(selected[0]).exp ? 'kulut' : 'laskut'}/kierrata/${selected[0]}`)
  }

  const recycleExpense = () => {
    dispatch(setSelected([]))
    window.open(`/kulut/kierrata_kulu/${selected[0]}`, '_blank').focus()
  }

  const acceptInvoiceButton = (row) => {
    cLog('TRYING TO ACCEPT INVOICE WITH BUTTON', getInvoiceById(row.id))
    window.open(`/${getInvoiceById(row.id).exp ? 'kulut' : 'laskut'}/muokkaa/${row.id}`)
    //history.push(`/${getInvoiceById(row.id).exp ? 'kulut' : 'laskut'}/muokkaa/${row.id}`)
  }

  const statusOrderInvoices = ['LUONNOS', 'TARKASTETTAVANA', 'LÄHETETTY', 'MAKSETTU'/* , 'TILITETTY' */]
  const statusOrderExpences = ['HYLÄTTY', 'LUONNOS', 'VALMIS', 'OSITTAIN_MAKSETTU', 'MAKSETTU']
  const buttonActionStatuses = ['TARKASTETTAVANA', 'LÄHETETTY', 'MAKSETTU', "OSITTAIN_MAKSETTU", "MAKSETTU_LIIKAA"]

  const changeStatus = async (invoice, newStatus) => {
    if (!isExp) {
      if (newStatus === 'down' && invoice.accountingStatus.includes('TILITETTY', 'OSITTAIN_TILITETTY')) {
        try {
          setLoading(true)
          const updatedInvoice = await updateInvoice({ ...invoice, accountingStatus: "TILITTÄMÄTÖN" })
          dispatch(reviseInvoice(updatedInvoice))
          setLoading(false)
          return
        } catch (error) {
          setLoading(false)
          cLog('VAIKEUKSIA LASKEA TILITETYN LASKUN STATUSTA', error)
          notifyOffice(isAdmin, enqueueSnackbar, error, false)
        }
      }
    }
    let index
    if (!isExp) {
      index = statusOrderInvoices.indexOf(invoice.status)
    } else {
      index = statusOrderExpences.indexOf(invoice.status)
    }
    let newIndex
    let topIndex = 3

    if (newStatus === 'up') {
      newIndex = topIndex
      if (index < topIndex) {
        newIndex = index + 1
      }
    } else if (newStatus === 'down') {
      newIndex = index
      if (index > 0) {
        newIndex = index - 1
      }
    } else {
      if (!isExp) {
        newIndex = statusOrderInvoices.indexOf(newStatus)
        cLog('CHANGING STATUS FROM ' + statusOrderInvoices[index] + ' TO ' + statusOrderInvoices[newIndex])
      } else {
        newIndex = statusOrderExpences.indexOf(newStatus)
        cLog('CHANGING STATUS FROM ' + statusOrderExpences[index] + ' TO ' + statusOrderExpences[newIndex])
      }
    }
    try {
      setLoading(true)
      if (!invoice.exp) {
        const updatedInvoice = await updateInvoice({ ...invoice, status: statusOrderInvoices[newIndex] })
        dispatch(reviseInvoice(updatedInvoice))
        if (landingPage) {
          fetchData(sortKey, sortOrder)
        }
        cLog('INVOICE STATUS CHANGED', updatedInvoice)
        enqueueSnackbar(
          t('invoice.invoiceStatusUpdated'),
          { variant: 'success' }
        )
      } else {
        const updatedExpence = await updateExpence({ ...invoice, status: statusOrderExpences[newIndex] })
        dispatch(reviseExpence(updatedExpence))
        cLog('EXPENCE STATUS CHANGED', updatedExpence)
        enqueueSnackbar(
          t('invoice.expenseStatusUpdated'),
          { variant: 'success' }
        )
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      cLog('VAIKEUKSIA MUUTTAA LASKUN STATUSTA', error)
      enqueueSnackbar(
        t('invoice.invoiceStatusUpdateError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  const changeSelectedStatus = async (newStatus) => {
    selected.forEach(async (id) => {
      if (!isExp) {
        const invoice = await getInvoice(id)
        changeStatus(invoice, newStatus)
      } else {
        const expence = await getExpence(id)
        changeStatus(expence, newStatus)
      }
    })
  }

  const downgradeToSketch = async () => {
    try {
      setLoading(true)
      selected.forEach(async (id) => {
        const invoice = await getInvoice(id)
        if (!invoice.exp) {
          const updatedInvoice = await updateInvoice({ ...invoice, status: 'LUONNOS' })
          dispatch(reviseInvoice(updatedInvoice))
          cLog('INVOICE DOWNGRADED', updatedInvoice)
        } else {
          const updatedExpence = await updateExpence({ ...invoice, status: 'LUONNOS' })
          dispatch(reviseExpence(updatedExpence))
          cLog('EXPENCE DOWNGRADED', updatedExpence)
        }
        enqueueSnackbar(
          t('invoice.invoiceUpdatedToDraft'),
          { variant: 'success' }
        )
      })
      setActionSelectValue(null)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      cLog('DOWNGRADE INVOICE ERROR', error.response.data.errors)
      enqueueSnackbar(
        t('invoice.invoiceUpdatedToDraftError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  let actions = []

  if (isAdmin) {
    actions.push('YLENNÄ')
    actions.push('ALENNA')
  }
  if (selected.length === 1) {
    const selectedInvoice = getInvoiceById(selected[0])
    const selectedInvoiceStatus = selectedInvoice ? selectedInvoice.status : ''

    if (isAdmin && selectedInvoiceStatus === 'TARKASTETTAVANA') {
      actions.push('PALAUTA LUONNOKSEKSI')
    }
    if (isAdmin && (selectedInvoiceStatus === 'LÄHETETTY' || selectedInvoiceStatus === 'OSITTAIN_MAKSETTU')) {
      actions.push('ASETA MAKSETUKSI')
    }
    if (isAdmin || selectedInvoiceStatus === 'LUONNOS') {
      actions.push('MUOKKAA')
    }
    actions.push('KOPIOI POHJAKSI')
  }
  const statuses = selected.map(id => getInvoiceById(id) && getInvoiceById(id).status)
  if (!isAdmin && !statuses.find(status => status !== 'LUONNOS')) {
    actions.push('POISTA')
  } else if (isAdmin) {
    actions.push('POISTA')
  }

  const actionOptions = actions
    .map(option => ({ label: option, value: option }))

  const buttonActions = (row) => {
    const { status } = row

    if ((row.clientName === "Day 2 Media Oy" || row.clientName === "Boksi.com Solutions Oy") && row.worker.userName.includes('boksi') && payList) {
      return t('invoice.paySalary')
    }

    if (checked === "MAKSETTU_LIIKAA") {
      return t('invoice.setPaid')
    }

    if ((row.accountingStatus === "TILITETTY" && row.status === 'MAKSETTU') || (row.status === 'MAKSETTU' && row.payDay === 'HETI')) {
      return ''
    }

    if (checked === 'HETIPALKAT' || checked === 'KAIKKI_LASKUT' || (!checked && landingPage)) {
      return ''
    }

    if (status === 'TARKASTETTAVANA' && landingPage && checked !== 'BOKSI_MAKSULISTA') {
      return t('invoice.setSent')
    } else if (landingPage && checked === 'BOKSI_MAKSULISTA') {
      return t('invoice.goToPaymentList')
    } else if (status === 'LÄHETETTY' || status === "OSITTAIN_MAKSETTU" || status === "MAKSETTU_LIIKAA") {
      if (checked !== 'HETIPALKKAPYYNNÖT' && landingPage) {
        return t('invoice.setPaid')
      } else {
        return ''
      }
    } else if (status === 'MAKSETTU') {
      if (landingPage && checked !== 'TILITTÄMÄTTÖMÄT') {
        return t('invoice.goToPaymentList')
      } else if (landingPage && checked === 'TILITTÄMÄTTÖMÄT') {
        if (row.payDay === 'NOSTETAAN_MYOHEMMIN') {
          return t('invoice.moveToPaymentList')
        } else {
          return ''
        }
      } else {
        if (row.payDay === 'NOSTETAAN_MYOHEMMIN') {
          return t('invoice.salaryRequest')
        } else {
          if (payList) {
            return t('invoice.paySalary')
          }
        }
      }
    }
  }

  const deleteSelectedInvoices = async () => {
    //cLog('SELECTED', selected)
    const chosenInvoiceNumbers = []
    for (let id of selected) {
      const selectedInvoice = await getInvoiceById(id)
      if (!isExp) {
        chosenInvoiceNumbers.push(selectedInvoice.invoiceNr)
      } else {
        chosenInvoiceNumbers.push(selectedInvoice.expenceNr)
      }
    }
    //cLog('POISTETTAVAT INVOICE NUMEROT', chosenInvoiceNumbers)
    const title = 'Haluatko varmasti poistaa lasku' + (selected.length > 1 ? 't ' : 'n ')
      + chosenInvoiceNumbers.join(', ') + '?'
    setAlertMessage({ title, body: 'Tapahtumaa ei voi perua.' })
    setOpenConfirmDeleteInvoiceModal(true)
  }

  const setAsAccounted = async () => {
    for (let id of selected) {
      const request = setInvoiceAccounted(id)

      request.then(response => {
        cLog('SET ACCOUNTED SUCCESS', response)
        enqueueSnackbar(
          t('invoice.salaryPaidNr', { nr: response.data.invoiceNr }),
          { variant: 'success' }
        )
        fetchData()
      }, error => {
        cLog('SET ACCOUNTED ERROR', error)
        enqueueSnackbar(
          t('invoice.setAccountedError', { nr: error.response.data.errors[0] }),
          { variant: 'error' }
        )
        fetchData()
      })
    }
  }

  const createConsolidatedInvoice = async () => {
    //cLog('SELECTED', selected)
    try {
      setLoading(true)
      const response = await sendCreateConsolidatedInvoiceRequest(selected.join(','))
      cLog('CREATE CONSOLIDATED INVOICE REQUEST SENT', response)
      enqueueSnackbar(
        t('invoice.consolidatedInvoiceCreated'),
        { variant: 'success' }
      )
      setLoading(false)
      history.push('/laskut/muokkaa/' + response.data.consolidatedInvoiceId)
    } catch (error) {
      setLoading(false)
      cLog('CREATE CONSOLIDATED INVOICE REQUEST ERROR', error)
      enqueueSnackbar(
        t('invoice.consolidatedInvoiceError'),
        { variant: 'error' }
      )
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach(message => {
          if (message.includes('Status is invalid')) {
            const m = message.replace('Invoice nr', t('invoice.invoiceNr')).replace('Invoice id', t('invoice.invoiceId')).replace('Status is invalid', t('invoice.statusInvalid'))
            notifyOffice(isAdmin, enqueueSnackbar, m, false)
          } else if (message.includes('Invoice already belongs to consolidated invoice')) {
            const m = message.replace('Invoice nr', t('invoice.invoiceNr')).replace('Invoice id', t('invoice.invoiceId')).replace('Invoice already belongs to consolidated invoice', t('invoice.alreadyBelongsToConsolidatedInvoice'))
            notifyOffice(isAdmin, enqueueSnackbar, m, false)
          } else if (message.includes('Client is not a company')) {
            const m = message.replace('Invoice nr', t('invoice.invoiceNr')).replace('Invoice id', t('invoice.invoiceId')).replace('Client is not a company', t('invoice.clientNotCompany'))
            notifyOffice(isAdmin, enqueueSnackbar, m, false)
          } else if (message.includes('Clients are different')) {
            const m = message.replace('Invoice nr', t('invoice.invoiceNr')).replace('Invoice id', t('invoice.invoiceId')).replace('Clients are different', t('invoice.clientsDifferent'))
            notifyOffice(isAdmin, enqueueSnackbar, m, false)
          } else if (message.includes('Currency is different')) {
            const m = message.replace('Invoice nr', t('invoice.invoiceNr')).replace('Invoice id', t('invoice.invoiceId')).replace('Currency is different', t('invoice.currenciesDifferent'))
            notifyOffice(isAdmin, enqueueSnackbar, m, false)
          } else {
            notifyOffice(isAdmin, enqueueSnackbar, message, false)
          }
        })
      }
    }
  }

  const createCreditNote = async () => {
    //cLog('SELECTED', selected)
    try {
      setLoading(true)
      const response = await sendCreateCreditNoteRequest(selected[0])
      cLog('CREATE CREDIT NOTE REQUEST SENT', response)
      enqueueSnackbar(
        t('invoice.createCreditNoteCreated'),
        { variant: 'success' }
      )
      setLoading(false)
      dispatch(setSelected([]))
      history.push('/laskut/muokkaa/' + response.data.id)
    } catch (error) {
      setLoading(false)
      cLog('CREATE CREDIT NOTE REQUEST ERROR', error)
      enqueueSnackbar(
        t('invoice.createCreditNoteError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  const handleChangeAction = (e) => {
    setActionSelectValue(e)
    if (e.value === 'POISTA') {
      deleteSelectedInvoices()
      return
    } else if (e.value === 'MUOKKAA') {
      editOldInvoice()
    } else if (e.value === 'KOPIOI POHJAKSI') {
      recycleInvoice()
      setActionSelectValue(null)
    } else if (e.value === 'PALAUTA LUONNOKSEKSI') {
      downgradeToSketch()
    } else if (e.value === 'ASETA MAKSETUKSI') {
      changeSelectedStatus('MAKSETTU')
    } else if (e.value === 'ALENNA') {
      changeSelectedStatus('down')
    } else if (e.value === 'YLENNÄ') {
      changeSelectedStatus('up')
    }
    setActionSelectValue(null)
    dispatch(setSelected([]))
  }

  const handleButtonAction = async (row) => {
    cLog('BUTTON ACTION', buttonActions(row))
    if (buttonActions(row) === t('invoice.setSent')) {
      acceptInvoiceButton(row)
    } else if (buttonActions(row) === t('invoice.setPaid') && row.payDay === 'HETI') {
      cLog('ASETETAAN HETIPALKKA MAKSETUKSI')
      setPaymentInvoice(row)
      setOpenInvoicePaymentDialog(true)
    } else if (buttonActions(row) === t('invoice.setPaid')) {
      cLog('ASETETAAN MAKSETUKSI')
      setPaymentInvoice(row)
      setOpenInvoicePaymentDialog(true)
    } else if (buttonActions(row) === t('invoice.moveToPaymentList')) {
      cLog('VIEDÄÄN MAKSULISTALLE')
      handleBringToPayList(row)
    } else if (buttonActions(row) === t('invoice.goToPaymentList')) {
      cLog('PALKANMAKSUUN')
      const invoice = getInvoiceById(row.id)
      window.open('/kayttajat/maksulista/' + invoice.userId)
      //history.push('/kayttajat/maksulista/' + invoice.userId)
    } else if (buttonActions(row) === t('invoice.paySalary')) {
      cLog('MAKSA PALKKA')
      setPaidSalary(row)
    } else if (buttonActions(row) === t('invoice.salaryRequest')) {
      cLog('NOSTA PALKKA')
      handleSalaryRequest(row, 'normal')
    } else {                                ///// OLETUKSENA YLÖSPÄIN
      if (!isExp) {
        changeStatus(await getInvoice(row.id), 'up')
      } else {
        changeStatus(await getExpence(row.id), 'up')
      }
    }
  }

  const handleBringToPayList = async (row) => {
    if (row.payDay === 'NOSTETAAN_MYOHEMMIN') {
      try {
        setLoading(true)
        const invoice = await getInvoice(row.id)
        const updatedInvoice = await updateInvoice({
          ...invoice, payDay: 'KUN_MAKSETTU'
        })
        dispatch(reviseInvoice(updatedInvoice))
        enqueueSnackbar(
          t('invoice.invoiceToPaymentList'),
          { variant: 'success' }
        )
        if (landingPage) {
          fetchData(sortKey, sortOrder)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        cLog('BRING TO PAYLIST ERROR', error)
        enqueueSnackbar(
          t('invoice.invoiceToPaymentListError'),
          { variant: 'error' }
        )
        notifyOffice(isAdmin, enqueueSnackbar, error, false)
      }
    }
  }

  const setPaidSalary = async (row) => {
    setLoading(true)
    cLog('SET SALARY TO PAID', row)
    let today = new Date().toISOString().split('T')[0]

    const request = updateInvoice({ id: row.id, accountingStatus: "TILITETTY", accounts: [{amount: row.salariesPayableAmount, date: today, notes: ''}] })

    request.then(response => {
      dispatch(reviseInvoice(response))
      cLog("SALARY PAID RESPONSE", response)
      enqueueSnackbar(
        t('invoice.salaryPaid'),
        { variant: 'success' }
      )
      fetchData(sortKey, sortOrder)
      setLoading(false)
    }, error => {
      cLog('SET SALARY PAID ERROR', error)
      setLoading(false)
      enqueueSnackbar(
        t('invoice.salaryPaidError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    })
  }

  const setPaidInstantSalary = async (row) => {
    setLoading(true)
    cLog('SET INSTANT SALARY TO PAID', row)
    let today = new Date().toISOString().split('T')[0]

    const request = updateInvoice({ id: row.id, accountingStatus: "TILITETTY", accounts: [{amount: row.salariesPayableAmount, date: today, notes: ''}] })

    request.then(response => {
      dispatch(reviseInvoice(response))
      cLog("INSTANT SALARY PAID RESPONSE", response)
      enqueueSnackbar(
        t('invoice.instantSalaryPaid'),
        { variant: 'success' }
      )
      fetchData(sortKey, sortOrder)
      setLoading(false)
    }, error => {
      cLog('SET INSTANT SALARY PAID ERROR', error)
      setLoading(false)
      enqueueSnackbar(
        t('invoice.instantSalaryPaidError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    })
  }

  const handlePayExpences = async (row) => {
    cLog('PAYING EXPENCES', row.id)
    /*try {
      setLoading(true)
      const expence = await getExpence(row.id)
      const updatedExpence = await updateExpence({ ...expence, status: 'MAKSETTU' })
      dispatch(reviseExpence(updatedExpence))
      enqueueSnackbar(
        t('invoice.expensePaid'),
        { variant: 'success' }
      )
      if (!landingPage) {
        fetchData(sortKey, sortOrder)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      cLog('PAY EXPENCES ERROR', error)
      enqueueSnackbar(
        t('invoice.expensePaidError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }*/
    const onFulfilled = async (response) => {
      const updatedExpence = await getExpence(response.data.id)
      dispatch(reviseExpence(updatedExpence))
      enqueueSnackbar(
        t('invoice.expensePaid'),
        { variant: 'success' }
      )
      if (!landingPage) {
        fetchData(sortKey, sortOrder)
      }
      setLoading(false)
    }

    const onRejected = (response) => {
      enqueueSnackbar(
        <div>{t('invoice.'+response.response.data.errors[0])}<br />{response.response.data.errors[1]}</div>,
        { variant: 'error' }
      )
      setLoading(false)
    }

    try {
      setLoading(true)
      const response = sendExpenseRequest(row)
      cLog('SENDING EXPENSE', response)
      response.then(onFulfilled, onRejected)
    } catch (error) {
      cLog('PAY EXPENCES ERROR', error)
      enqueueSnackbar(
        t('invoice.expensePaidError'),
        { variant: 'error' }
      )
      setLoading(false)
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  const handleRejectExpence = async (row) => {
    cLog('REJECTING EXPENCE', row)
    try {
      setLoading(true)
      const expence = await getExpence(row.id)
      const updatedExpence = await updateExpence({ ...expence, status: 'HYLÄTTY' })
      dispatch(reviseExpence(updatedExpence))
      enqueueSnackbar(
        t('invoice.expenseRejected'),
        { variant: 'success' }
      )
      if (!landingPage) {
        fetchData(sortKey, sortOrder)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      cLog('REJECT EXPENCES ERROR', error.response.data.errors)
      enqueueSnackbar(
        t('invoice.expenseRejectedError'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    }
  }

  const handleSalaryRequest = async (row, salaryType) => {
    cLog(!isAdmin ? 'LÄHETETÄÄN PALKKAPYYNTÖ LASKULLE' : 'NOSTETAAN PALKKA', row)
    setLoading(true)
    const request = sendSalaryRequest(row.id, salaryType)
    request.then(response => {
      cLog('SALARY REQUEST SENT', response)
      enqueueSnackbar(
        `${!isAdmin ?  t('invoice.salaryRequestSent') :  t('invoice.salaryWithdrawn')}`,
        { variant: 'success' }
      )
      setSelectedRow(null)
      fetchData(sortKey, sortOrder)
      setLoading(false)
    }, error => {
      setSelectedRow(null)
      setLoading(false)
      cLog('SEND SALARY REQUEST ERROR', error)
      enqueueSnackbar(
        `${!isAdmin ?  t('invoice.salaryRequestError') :  t('invoice.salaryWithdrawnError')}`,
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    })
  }

  const handlePrintSalaryPdf = async (id) => {
    setLoading(true)
    const data = printSalaryPdf(id)
    data.then(response => {
      const blob = new Blob([response.data], {type: "application/pdf"})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = t('salary.salary')+' '+id
      document.body.append(link)
      link.click()
      link.remove()
      setTimeout(() => URL.revokeObjectURL(link.href), 7000)
      setLoading(false)
    }, error => {
      setLoading(false)
      cLog('PRINT SALARY ERROR', error)
      enqueueSnackbar(
        t('invoice.pdfProblem'),
        { variant: 'error' }
      )
      notifyOffice(isAdmin, enqueueSnackbar, error, false)
    })
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const actionButtonStyles = () => ({ border: `solid 1px black`, marginBottom: '3px' })

  const ActionButtons = ({ row }) => {
    if (isAdmin) {
      if (!row.exp) {
        return (
          <TableCell>
            {(buttonActionStatuses.includes(row.status) /* 1 TOIMINTONAPPI */
              && buttonActions(row) &&
              <Button
                disabled={isLoading}
                style={actionButtonStyles()}
                className="uppercase"
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handleButtonAction(row)}
              >
                {buttonActions(row)}
              </Button>) || ''}

            {(checked === 'KAIKKI_LASKUT' || !checked) && landingPage && <DoubleLink edit to={`${row.exp ? 'kulut' : 'laskut'}/muokkaa/${row.id}`} />}

            {row.payDay === 'HETI' /* 2 TOIMINTONAPPI (HETIPALKAT) */
              && (checked || !landingPage) /////UUSI EHTO?????
              && row.accountingStatus === "TILITTÄMÄTÖN"
              && row.status !== 'LUONNOS'
              && row.status !== 'TARKASTETTAVANA'
              && row.status !== 'POISTETTU'
              && checked !== 'SUORITUKSET'
              && checked !== 'TILITTÄMÄTTÖMÄT'
              && checked !== 'KAIKKI_LASKUT'
              && <Button
                disabled={isLoading}
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  if (landingPage) {
                    cLog('PALKANMAKSUUN')
                    const invoice = getInvoiceById(row.id)
                    if (checked === 'HETIPALKKAPYYNNÖT') {
                      window.open('/kayttajat/' + invoice.userId)
                    } else {
                      window.open('/kayttajat/maksulista/' + invoice.userId)
                    }
                  } else {
                    setPaidInstantSalary(row)
                  }
                }}
                style={actionButtonStyles()}
                className="uppercase"
              >
                {landingPage
                  ? t('invoice.goToPaymentList')
                  : (row.payDay === 'HETI'
                    ? t('invoice.payInstantSalary')
                    : t('invoice.paySalary') )}
              </Button>}

            {!landingPage && ((row.status === 'LÄHETETTY' && ['KUN_MAKSETTU', 'NOSTETAAN_MYOHEMMIN'].includes(row.payDay)) || (row.status === 'MAKSETTU' && row.payDay === 'NOSTETAAN_MYOHEMMIN')) && row.accountingStatus !== "TILITETTY"
              && <Button
                disabled={isLoading}
                variant="outlined"
                color="primary"
                size="small"
                style={actionButtonStyles()}
                className="uppercase"
                onClick={() => {
                  setSelectedRow(row)
                  setOpenConfirmExpressPaymentModal(true)
                }}
              >
                {t('invoice.expressPaymentSalaryRequest')}
              </Button>}
          </TableCell>
        )
      } else {
        return (
          <TableCell>
            {((row.status === 'VALMIS' || row.status === 'OSITTAIN_MAKSETTU') && !landingPage && payList &&
              <Button
                disabled={isLoading}
                style={actionButtonStyles()}
                className="uppercase"
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => handlePayExpences(row)}
              >
                {t('invoice.pay')}
            </Button>) || ''}
            {!['LUONNOS', 'HYLÄTTY', 'MAKSETTU'].includes(row.status) && !landingPage && payList && <Button
              size="small"
              onClick={() => handleRejectExpence(row)}
              className="uppercase"
            >
              {t('invoice.reject')}
            </Button>}
            {landingPage && <DoubleLink edit to={`kulut/muokkaa/${row.id}`} target='_blank' />}
          </TableCell>
        )
      }
    } else {
      return row.status === 'LUONNOS'
        ? <TableCell>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => editInvoice(getInvoiceById(row.id))}
              className="uppercase"
            >
              {t('invoice.edit')}
            </Button>
          </TableCell>
        : <TableCell>
            {row.status === 'MAKSETTU' && row.payDay === 'NOSTETAAN_MYOHEMMIN' && row.accountingStatus !== "TILITETTY" && <Button
              disabled={isLoading}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => handleSalaryRequest(row, 'normal')}
              className="uppercase"
            >
              {t('invoice.sendSalaryRequest')}
           </Button>}
           {((row.status === 'LÄHETETTY' && ['KUN_MAKSETTU', 'NOSTETAAN_MYOHEMMIN'].includes(row.payDay)) || (row.status === 'MAKSETTU' && row.payDay === 'NOSTETAAN_MYOHEMMIN')) && row.accountingStatus !== "TILITETTY" && <Button
              disabled={isLoading}
              variant="outlined"
              color="primary"
              size="small"
              className="uppercase"
              onClick={() => {
                setSelectedRow(row)
                setOpenConfirmExpressPaymentModal(true)
              }}
            >
              {t('invoice.sendExpressPaymentSalaryRequest')}
            </Button>}
          </TableCell>
    }
  }

  return (
    <>
        <EnhancedTableToolbar
          actionOptions={actionOptions}
          isAdmin={isAdmin}
          numSelected={(selected && selected.length) || 0}
          handleChangeAction={handleChangeAction}
          actionSelectValue={actionSelectValue}
          tableTitle={tableTitle}
          editOldInvoice={editOldInvoice}
          recycleInvoice={recycleInvoice}
          recycleExpense={recycleExpense}
          deleteSelectedInvoices={deleteSelectedInvoices}
          createConsolidatedInvoice={createConsolidatedInvoice}
          createCreditNote={createCreditNote}
          getInvoiceById={getInvoiceById}
          selected={selected}
          isMobile={isMobile}
          commissions={commissions}
          //setOpenSalaryCalculatorOfficeDialog={setOpenSalaryCalculatorOfficeDialog}
          payList={payList}
          checkSelected={checkSelected}
          landingPage={landingPage}
          setAsAccounted={setAsAccounted}
          isExp={isExp}
        />
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={(selected && selected.length) || 0}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              isExp={isExp}
              checked={checked}
              landingPage={landingPage}
              isAdmin={isAdmin}
              selectedColumns={selectedColumns}
              onRequestSort={handleRequestSort}
              sortKey={sortKey || (!isAdmin ? (isExp ? 'expence_nr' : 'invoice_nr') : 'updated_at')}
              sortOrder={sortOrder ? sortOrder.toLowerCase() : 'desc'}
            />
            <TableBody>
              {rows
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {!isExp && (
                        <>
                          {(!isAdmin || selectedColumns.includes('invoiceNr')) && <TableCell data-label={t('invoice.invoiceNr')}>
                            {row.invoiceNr
                              ? (!isAdmin
                                ? <Link to={`/laskut/esikatsele/${row.id}`}>{row.invoiceNr}</Link>
                                : <Link to={`/laskut/esikatsele/${row.id}`} target='_blank'>{row.invoiceNr}</Link>)
                              : (!isAdmin
                                ? <Link to={`/laskut/esikatsele/${row.id}`}>id:{row.id}</Link>
                                : <Link to={`/laskut/esikatsele/${row.id}`} target='_blank'>id:{row.id}</Link>)
                            }
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('createdAt') && <TableCell data-label={t('invoice.createdAt')}>{row.createdAt && formatDatetime(row.createdAt)}</TableCell>}
                          {isAdmin && selectedColumns.includes('updatedAt') && <TableCell>{row.updatedAt && formatDatetime(row.updatedAt)}</TableCell>}
                          {isAdmin && selectedColumns.includes('updater') && <TableCell>{row.updater}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('date')) && <TableCell data-label={t('invoice.date')}>{row.date && formatTimeFI(row.date)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('dateDue')) && <TableCell data-label={t('invoice.dateDue')}>{row.dateDue && formatTimeFI(row.dateDue)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('customer')) && <TableCell data-label={t('invoice.customer')}>{row.clientName}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalAddress') && <TableCell style={{whiteSpace: "pre-line"}}>{row.totalAddress}</TableCell>}
                          {isAdmin && selectedColumns.includes('email') && <TableCell>{row.email}</TableCell>}
                          {isAdmin && selectedColumns.includes('phone') && <TableCell>{row.phone}</TableCell>}
                          {isAdmin && selectedColumns.includes('isPrivate') && <TableCell>{row.isPrivate !== undefined && row.isPrivate !== null && t('taxcard.'+row.isPrivate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('businessId') && <TableCell>{row.businessId}</TableCell>}
                          {isAdmin && selectedColumns.includes('contactPerson') && <TableCell>{row.contactPerson}</TableCell>}
                          {isAdmin && selectedColumns.includes('deliveryMethod') && <TableCell>{row.deliveryMethod && t('invoice.'+row.deliveryMethod)}</TableCell>}
                          {isAdmin && selectedColumns.includes('reminderDate') && <TableCell>{row.reminderDate && formatTimeFI(row.reminderDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('reminderNotes') && <TableCell>{row.reminderNotes}</TableCell>}
                          {isAdmin && selectedColumns.includes('reminderHandling') && <TableCell>{row.reminderHandling && t('invoice.'+row.reminderHandling.replace(/_/g, ' '))}</TableCell>}
                          {isAdmin && selectedColumns.includes('language') && <TableCell>{row.language && t('user.'+row.language)}</TableCell>}
                          {isAdmin && selectedColumns.includes('einvoiceOperator') && <TableCell>{row.einvoiceOperator}</TableCell>}
                          {isAdmin && selectedColumns.includes('einvoiceAddress') && <TableCell>{row.einvoiceAddress}</TableCell>}
                          {isAdmin && selectedColumns.includes('worker') && <TableCell>
                            {(row.worker.id) ? <Link to={`/kayttajat/${row.worker.id}`} target='_blank'>{row.worker.userName}</Link> : row.worker.userName}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('workPeriod') && <TableCell>{row.workPeriod}</TableCell>}
                          {isAdmin && selectedColumns.includes('timeFrame') && <TableCell>{row.timeFrame}</TableCell>}
                          {isAdmin && selectedColumns.includes('commission') && <TableCell align='right'>{row.commission && row.commission.toFixed(2) + " €"}</TableCell>}
                          {isAdmin && selectedColumns.includes('yel') && <TableCell>{row.yel !== undefined && row.yel !== null && t('taxcard.'+row.yel)}</TableCell>}
                          {isAdmin && selectedColumns.includes('invoiceTotal') && <TableCell align='right'>{row.invoiceTotal && parseFloat(row.invoiceTotal).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('travelTotal') && <TableCell align='right'>{row.travelTotal && parseFloat(row.travelTotal).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('origTotalVatExcluded') && <TableCell align='right'>{row.origTotalVatExcluded && parseFloat(row.origTotalVatExcluded).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('tax') && <TableCell align='right'>{row.tax && parseFloat(row.tax).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalWithTax') && <TableCell align='right'>{row.totalWithTax && parseFloat(row.totalWithTax).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalCorrections') && <TableCell align='right'>{row.totalCorrections && parseFloat(row.totalCorrections).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalCorrectionsVat') && <TableCell align='right'>{row.totalCorrectionsVat && parseFloat(row.totalCorrectionsVat).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalCorrectionsWithVat') && <TableCell align='right'>{row.totalCorrectionsWithVat && parseFloat(row.totalCorrectionsWithVat).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('origTotalVatExcludedEur') && <TableCell align='right'>{row.origTotalVatExcludedEur && parseFloat(row.origTotalVatExcludedEur).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('taxEur') && <TableCell align='right'>{row.taxEur && parseFloat(row.taxEur).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalWithTaxEur') && <TableCell align='right'>{row.totalWithTaxEur && parseFloat(row.totalWithTaxEur).toFixed(2)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('totalCorrectedEur')) && <TableCell align='right' data-label={row.totalCorrectedEur && t('invoice.totalWithoutVat')}>{parseFloat(row.totalCorrectedEur).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalCorrectedVatEur') && <TableCell align='right'>{row.totalCorrectedVatEur && parseFloat(row.totalCorrectedVatEur).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalCorrectedWithVatEur') && <TableCell align='right'>{row.totalCorrectedWithVatEur && parseFloat(row.totalCorrectedWithVatEur).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('salariesPayableAmount') && <TableCell align='right'>{row.salariesPayableAmount && parseFloat(row.salariesPayableAmount).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('currency') && <TableCell>{row.currency}</TableCell>}
                          {isAdmin && selectedColumns.includes('refText') && <TableCell>{row.refText}</TableCell>}
                          {isAdmin && selectedColumns.includes('refCode') && <TableCell>{row.refCode}</TableCell>}
                          {isAdmin && selectedColumns.includes('vatStatus') && <TableCell>{row.vatStatus && t('invoice.'+row.vatStatus.replace(/_/g, ' '), { keySeparator: '.', nsSeparator: false })}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalPaymentsWithTax') && <TableCell align='right'>{row.totalPaymentsWithTax && parseFloat(row.totalPaymentsWithTax).toFixed(2)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('payDay')) && <TableCell data-label={t('invoice.payDay')}>{row.payDay ? (row.payDay === 'HETI' || row.payDay === 'PIKAMAKSU' ? <StatusCard style={{ margin: 0, color: 'white', backgroundColor: COLORS.STATUS_COLORS[row.payDay]}} type='button'>{t('invoice.'+(row.payDay))}</StatusCard> : row.payDay && t('invoice.'+(row.payDay.replace('_', ' ')))) : ''}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('status')) && <TableCell data-label={t('invoice.status')}>{row.status && <StatusCard style={{ margin: '0', color: 'white', backgroundColor: COLORS.STATUS_COLORS[row.status]}} type='button'>{t('invoice.'+(row.status))}</StatusCard>}</TableCell>}
                          {isAdmin && selectedColumns.includes('totalAccounting') && <TableCell align='right'>{row.totalAccounting && parseFloat(row.totalAccounting).toFixed(2)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('accountingStatus')) && <TableCell data-label={t('invoice.accountingStatus')}>{row.accountingStatus ? (row.accountingStatus === "TILITETTY" ? <DoneIcon /> : <CloseIcon />) : ''}</TableCell>}
                          {isAdmin && selectedColumns.includes('info') && <TableCell>{row.info}</TableCell>}
                          {isAdmin && selectedColumns.includes('adminInfo') && <TableCell>{row.adminInfo}</TableCell>}
                          {isAdmin && selectedColumns.includes('internalNotes') && <TableCell>{row.internalNotes}</TableCell>}
                          {isAdmin && selectedColumns.includes('industry') && <TableCell>{row.industry}</TableCell>}
                          {isAdmin && selectedColumns.includes('customIndustry') && <TableCell>{row.customIndustry}</TableCell>}
                          {isAdmin && selectedColumns.includes('consolidatedInvoice') && <TableCell>{row.consolidatedInvoice !== undefined && row.consolidatedInvoice !== null &&
                            <>
                              {t('taxcard.'+row.consolidatedInvoice)}
                              {row.consolidatedInvoice && row.ciData && row.ciData.length > 0 && row.ciData.map((inv, i) => <span key={i}><a href={`/laskut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.invoiceNr || 'id:'+inv.id}</a>{i === row.ciData.length - 1 ? '' : ', '}</span>)}
                            </>}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('belongsToConsolidatedInvoice') && <TableCell>
                            {row.consolidatedInvoiceId ? <Link to={`/laskut/esikatsele/${row.consolidatedInvoiceId}`} target='_blank'>{row.consolidatedInvoiceNr || row.consolidatedInvoiceId}</Link> : ''}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('creditNote') && <TableCell>{row.creditNote !== undefined && row.creditNote !== null &&
                            <>
                              {t('taxcard.'+row.creditNote)}{row.creditNote ? ' ' : ''}
                              {row.creditNote && row.originalInvoiceId ? <Link to={`/laskut/esikatsele/${row.originalInvoiceId}`} target='_blank'>{row.originalInvoiceNr || row.originalInvoiceId}</Link> : ''}
                            </>}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('belongsToCreditNote') && <TableCell>
                            {row.creditNoteInvoicesData ? row.creditNoteInvoicesData.map((inv, i) => <span key={i}><a href={`/laskut/esikatsele/${inv.id}`} target='_blank' rel="noopener noreferrer">{inv.invoiceNr || 'id:'+inv.id}</a>{i === row.creditNoteInvoicesData.length - 1 ? '' : ', '}</span>) : ''}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('salaryData') && <TableCell>
                            {row.salaryData ? row.salaryData.map((salary, i) => <span key={i}><span className={classes.spanLink} onClick={() => handlePrintSalaryPdf(salary.id)}>{salary.id}</span>{i === row.salaryData.length - 1 ? '' : ', '}</span>) : ''}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('statusTarkastettavanaDate') && <TableCell>{row.statusTarkastettavanaDate && formatTimeFI(row.statusTarkastettavanaDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusLahetettyDate') && <TableCell>{row.statusLahetettyDate && formatTimeFI(row.statusLahetettyDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusOsittainMaksettuDate') && <TableCell>{row.statusOsittainMaksettuDate && formatTimeFI(row.statusOsittainMaksettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusMaksettuDate') && <TableCell>{row.statusMaksettuDate && formatTimeFI(row.statusMaksettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusMaksettuLiikaaDate') && <TableCell>{row.statusMaksettuLiikaaDate && formatTimeFI(row.statusMaksettuLiikaaDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusPoistettuDate') && <TableCell>{row.statusPoistettuDate && formatTimeFI(row.statusPoistettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusPerinnassaDate') && <TableCell>{row.statusPerinnassaDate && formatTimeFI(row.statusPerinnassaDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusUlosotossaDate') && <TableCell>{row.statusUlosotossaDate && formatTimeFI(row.statusUlosotossaDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusLuottotappioDate') && <TableCell>{row.statusLuottotappioDate && formatTimeFI(row.statusLuottotappioDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusMuistutettuDate') && <TableCell>{row.statusMuistutettuDate && formatTimeFI(row.statusMuistutettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusHyvitettyDate') && <TableCell>{row.statusHyvitettyDate && formatTimeFI(row.statusHyvitettyDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusHyvityslaskuDate') && <TableCell>{row.statusHyvityslaskuDate && formatTimeFI(row.statusHyvityslaskuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('accountingStatusOsittainTilitettyDate') && <TableCell>{row.accountingStatusOsittainTilitettyDate && formatTimeFI(row.accountingStatusOsittainTilitettyDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('accountingStatusTilitettyDate') && <TableCell>{row.accountingStatusTilitettyDate && formatTimeFI(row.accountingStatusTilitettyDate)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('actions')) && <ActionButtons row={row} />}
                        </>
                      )}
                      {isExp && (
                        <>
                          {(!isAdmin || selectedColumns.includes('expenceNr')) && <TableCell data-label={t('invoice.expenceNr')}>
                            {row.expenceNr
                              ? (!isAdmin
                                ? <Link to={`/kulut/esikatsele/${row.id}`}>{row.expenceNr}</Link>
                                : <Link to={`/kulut/esikatsele/${row.id}`} target='_blank'>{row.expenceNr}</Link>)
                              : (!isAdmin
                                ? <Link to={`/kulut/esikatsele/${row.id}`}>id:{row.id}</Link>
                                : <Link to={`/kulut/esikatsele/${row.id}`} target='_blank'>id:{row.id}</Link>)
                            }
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('createdAt') && <TableCell>{row.createdAt && formatDatetime(row.createdAt)}</TableCell>}
                          {isAdmin && selectedColumns.includes('updatedAt') && <TableCell>{row.updatedAt && formatDatetime(row.updatedAt)}</TableCell>}
                          {isAdmin && selectedColumns.includes('updater') && <TableCell>{row.updater}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('date')) && <TableCell data-label={t('invoice.date')}>{row.date && formatTimeFI(row.date)}</TableCell>}
                          {isAdmin && selectedColumns.includes('worker') && <TableCell>
                            {(row.worker.id) ? <Link to={`/kayttajat/${row.worker.id}`} target='_blank'>{row.worker.userName}</Link> : row.worker.userName}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('expenceType') && <TableCell>{row.expenceType && t('invoice.'+row.expenceType)}</TableCell>}
                          {isAdmin && selectedColumns.includes('timeFrame') && <TableCell>{row.timeFrame}</TableCell>}
                          {isAdmin && selectedColumns.includes('expenceTotal') && <TableCell align='right'>{row.expenceTotal && parseFloat(row.expenceTotal).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('travelTotal') && <TableCell align='right'>{row.travelTotal && parseFloat(row.travelTotal).toFixed(2)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('totalWithTax')) && <TableCell align='right' data-label={t('invoice.totalWithTax')}>{row.totalWithTax && parseFloat(row.totalWithTax).toFixed(2)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('status')) && <TableCell data-label={t('invoice.status')}>{row.status && <StatusCard style={{ margin: '0', color: 'white', backgroundColor: COLORS.STATUS_COLORS[row.status]}} type='button'>{t('invoice.'+row.status)}</StatusCard>}</TableCell>}
                          {isAdmin && selectedColumns.includes('info') && <TableCell>{row.info}</TableCell>}
                          {isAdmin && selectedColumns.includes('adminInfo') && <TableCell>{row.adminInfo}</TableCell>}
                          {isAdmin && selectedColumns.includes('internalNotes') && <TableCell>{row.internalNotes}</TableCell>}
                          {isAdmin && selectedColumns.includes('refCode') && <TableCell>{row.refCode}</TableCell>}
                          {isAdmin && selectedColumns.includes('isCopy') && <TableCell>
                            {row.originalExpenceId ? <Link to={`/kulut/esikatsele/${row.originalExpenceId}`} target='_blank'>{row.originalExpenceNr || row.originalExpenceId}</Link> : ''}
                          </TableCell>}
                          {isAdmin && selectedColumns.includes('totalAccounting') && <TableCell>{row.totalAccounting}</TableCell>}
                          {isAdmin && selectedColumns.includes('salariesPayableAmount') && <TableCell align='right'>{row.salariesPayableAmount && parseFloat(row.salariesPayableAmount).toFixed(2)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusValmisDate') && <TableCell>{row.statusValmisDate && formatTimeFI(row.statusValmisDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusOsittainMaksettuDate') && <TableCell>{row.statusOsittainMaksettuDate && formatTimeFI(row.statusOsittainMaksettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusMaksettuDate') && <TableCell>{row.statusMaksettuDate && formatTimeFI(row.statusMaksettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusHylattyDate') && <TableCell>{row.statusHylattyDate && formatTimeFI(row.statusHylattyDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('statusPoistettuDate') && <TableCell>{row.statusPoistettuDate && formatTimeFI(row.statusPoistettuDate)}</TableCell>}
                          {isAdmin && selectedColumns.includes('sentDate') && <TableCell>{row.sentDate && formatTimeFI(row.sentDate)}</TableCell>}
                          {(!isAdmin || selectedColumns.includes('actions')) && <ActionButtons row={row} />}
                        </>
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      <Alert
        open={openConfirmDeleteInvoiceModal}
        setOpen={setOpenConfirmDeleteInvoiceModal}
        handleAgree={handleAgreeDeleteInvoiceModal}
        handleClose={handleCloseDeleteInvoiceModal}
        alertMessage={alertMessage}
        confirmButtonText={t('customers.remove')}
        confirmButtonColor="error"
      />
      <Alert
        open={openConfirmExpressPaymentModal}
        setOpen={setOpenConfirmExpressPaymentModal}
        handleAgree={() => {
          handleSalaryRequest(selectedRow, 'pikamaksu')
          setOpenConfirmExpressPaymentModal(false)
        }}
        handleClose={() => {
          setOpenConfirmExpressPaymentModal(false)
          setSelectedRow(null)
        }}
        alertMessage={{ title: t('invoice.confirmExpressPaymentTitle'), body: parse((((!isAdmin && currentUser.bic && currentUser.bic === 'NDEAFIHH') || (isAdmin && selectedRow && selectedRow.userBic && selectedRow.userBic === 'NDEAFIHH')) ? t('invoice.expressPaymentTipNordea') : t('invoice.expressPaymentTip')) + (selectedRow && selectedRow.status === 'MAKSETTU' && selectedRow.payDay === 'NOSTETAAN_MYOHEMMIN' ? "<br><br>" + t('invoice.confirmExpressPaymentFooter') : ""))}}
      />
      {/*isAdmin && !landingPage && payList && <Formik
        initialValues={SALARY_CALCULATOR_OFFICE_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
          >
          {(formikProps) => <SalaryCalculatorOfficeDialog enqueueSnackbar={enqueueSnackbar} invoices={invoices} user={user} selected={selected} getInvoiceById={getInvoiceById} open={openSalaryCalculatorOfficeDialog} setOpenSalaryCalculatorOfficeDialog={setOpenSalaryCalculatorOfficeDialog} {...formikProps} />}
      </Formik>*/}
      {isAdmin && <Formik
        enableReinitialize
        initialValues={PAYMENT_TEMPLATE}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => <InvoicePaymentDialog open={openInvoicePaymentDialog} setOpenInvoicePaymentDialog={setOpenInvoicePaymentDialog} setPaymentInvoice={setPaymentInvoice} paymentInvoice={paymentInvoice} enqueueSnackbar={enqueueSnackbar} fetchData={fetchData} isLoading={isLoading} setLoading={setLoading} sortKey={sortKey} sortOrder={sortOrder} {...formikProps} />}
      </Formik>}
    </>
  )
}

export default withSnackbar(InvoiceTable)
