import React, { useState, useEffect } from 'react'
import { cLog } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getQuery } from '../../service'
import { useStateValue, setTaxcards, setSortKeyTaxcards, setSortOrderTaxcards } from "../../state"
import { Tooltip } from '@material-ui/core'
import LayoutContainer from '../../components/LayoutContainer'
import Header from '../../components/Header'
import DatatableFilters from '../../components/DatatableFilters'
import DatatablePagination from '../../components/DatatablePagination'
import Datatable from '../../components/Datatable'
import DownloadOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import { withSnackbar } from 'notistack'
import { APIdomain } from '../../service'
import '../../styles/styles.css'

let selectedColumns = []

const INIT_FILTER = {
  userId: [],
  dateStart: '',
  cardType: [],
  revisedCard: [],
  base: '',
  additional: '',
  annualIncomeLimit: '',
  comments: '',
  createdAt: '',
  updatedAt: '',
  columnsOptions: []
}

const Taxcards = ({ enqueueSnackbar }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [{ taxcards, sortKeyTaxcards, sortOrderTaxcards }, dispatch] = useStateValue()
  const [forceFetchData, setForceFetchData] = useState(true)
  const [pagination, setPagination] = useState({
    currentPage: 0,
    page: 1,
    rowsPerPage: 100,
    totalPages: 0,
    totalRows: 0
  })
  const allColumns = ['userId', 'dateStart', 'cardType', 'revisedCard', 'base', 'additional', 'annualIncomeLimit', 'comments', 'createdAt', 'updatedAt', 'updater', 'actions']
  selectedColumns = JSON.parse(window.localStorage.getItem('taxcardsSelectedColumns')) || allColumns
  const columnsOptions = allColumns.map(option => {
    let obj = {
      label: t('user.'+option),
      value: option
    }
    if (['dateStart'].includes(option)) {
      obj.date = true
    }
    if (['revisedCard'].includes(option)) {
      obj.boolean = true
    }
    if (['base', 'additional', 'annualIncomeLimit'].includes(option)) {
      obj.numeric = true
      obj.decimals = 2
    }
    if (['createdAt', 'updatedAt'].includes(option)) {
      obj.dateTime = true
    }
    return obj
  })
  const [filter, setFilter] = useState({ ...INIT_FILTER, columnsOptions: selectedColumns })

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }, [])

  useEffect(() => {
    const createActions = (row) => {
      const attachmentUrl = row.attachments && row.attachments.length > 0 ? APIdomain + row.attachments[0].url : ''

      return (
        <span style={{whiteSpace: "nowrap"}}>
          {attachmentUrl && <Tooltip title={t('taxcard.download')}>
            <DownloadOutlinedIcon
              className="actionIcon"
              onClick={() => {
                window.open(attachmentUrl, '_blank', 'noopener,noreferrer')
              }}
            />
          </Tooltip>}
        </span>
      )
    }

    const fetchData = async () => {
      let query = ''
      if (selectedColumns.includes('actions')) {
        query += '&attachments=true'
      }
      const response = getQuery(pagination, setPagination, sortKeyTaxcards, sortOrderTaxcards, filter, selectedColumns, 'taxcards', query)
      response.then(data => {
        const dataActions = data.map(row => {
          row.actions = createActions(row)
          return row
        })
        dispatch(setTaxcards(dataActions))
        setLoading(false)
      }, error => {
        cLog('FETCHING DATA ERROR', error)
        enqueueSnackbar(
          t('auth.problemsLoadingData'),
          { variant: 'error' }
        )
        dispatch(setTaxcards([]))
        setLoading(false)
      })
    }

    if (forceFetchData) {
      setLoading(true)
      setForceFetchData(false)
      fetchData()
    }
  }, [dispatch, forceFetchData, sortKeyTaxcards, sortOrderTaxcards, filter, pagination, t, enqueueSnackbar])

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        setPagination({ ...pagination, page: 1 })
        setForceFetchData(true)
      }
    };

    document.addEventListener('keyup', handleKeyPress)

    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }
  }, [pagination])

  const tableTitle = () => {
    const coda = !isLoading ? (' ' + pagination.totalRows + t('invoice.pieces')) : ' '
    return t('frontPage.taxcards') + coda
  }

  const cardTypeOptions = ['Yksi tuloraja', 'Lähdeverokortti'].map(option => ({ label: t('user.'+option), value: option }))
  const revisedCardOptions = ['true', 'false'].map(option => ({ label: t('taxcard.'+option), value: option }))

  return (
    <LayoutContainer>
      <Header
        title={t('frontPage.taxcards')}
      />
      <DatatableFilters
        inputs={[
          { field: 'userId', label: t('invoice.billers'), type: 'userId', },
          { field: 'dateStart', label: t('user.dateStart'), type: 'date' },
          { field: 'cardType', label: t('user.cardType'), type: 'select', options: cardTypeOptions },
          { field: 'revisedCard', label: t('user.revisedCard'), type: 'select', options: revisedCardOptions },
          { field: 'base', label: t('user.base'), type: 'text' },
          { field: 'additional', label: t('user.additional'), type: 'text' },
          { field: 'annualIncomeLimit', label: t('user.annualIncomeLimit'), type: 'text' },
          { field: 'comments', label: t('user.comments'), type: 'text' },
          { field: 'createdAt', label: t('userNotes.createdAt'), type: 'date' },
          { field: 'updatedAt', label: t('userNotes.updatedAt'), type: 'date' }
        ]}
        filter={filter}
        setFilter={setFilter}
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        initFilter={INIT_FILTER}
        isLoading={isLoading}
      />
      <DatatablePagination
        pagination={pagination}
        setPagination={setPagination}
        fetchData={() => setForceFetchData(true)}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        filter={filter}
        setFilter={setFilter}
        localStorage={'taxcardsSelectedColumns'}
        sortKey={sortKeyTaxcards}
        setSortKey={setSortKeyTaxcards}
        sortOrder={sortOrderTaxcards}
        setSortOrder={setSortOrderTaxcards}
        isAdmin={true}
        allowSelectColumns={true}
      />
      <Datatable
        data={taxcards}
        fetchData={() => setForceFetchData(true)}
        tableTitle={tableTitle()}
        selectedColumns={selectedColumns}
        columnsOptions={columnsOptions}
        sortKey={sortKeyTaxcards}
        setSortKey={setSortKeyTaxcards}
        sortOrder={sortOrderTaxcards}
        setSortOrder={setSortOrderTaxcards}
        isAdmin={true}
      />
    </LayoutContainer>
  )
}

export default withSnackbar(Taxcards)
